import React, { useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ckeditorConfiguration } from "../../util/basicdata";

type props = {
  setEditorData?: any;
  editorData?: any;
  fieldname?: string;
};

class Ceditor extends React.Component<props, {}> {
  render() {
    return (
      <CKEditor
        editor={ClassicEditor}
        name={this.props.fieldname ? this.props.fieldname : "about"}
        data={
          this.props.editorData !== undefined && this.props.editorData !== null
            ? this.props.editorData
            : ""
        }
        onChange={(event, editor) => this.props.setEditorData(editor.getData())}
        config={ckeditorConfiguration}
      ></CKEditor>
    );
  }
}

export default Ceditor;
