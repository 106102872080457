import {
  IonAlert,
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import {
  checkmarkCircleOutline,
  closeCircle,
  eyeOutline,
  searchOutline,
} from "ionicons/icons";
import React from "react";
import Pagination from "react-js-pagination";
import { HeaderNav } from "../../component";
import { Search, TableHeader } from "../../component";
import { StringMap } from "../../service";
import { TableDateStringMap } from "../../service/default";
import {
  GetApprovedContractorList,
  GetPendingContractorList,
  GetRejectContractorList,
  GetTrmlogsList,
  UpdatetokensData,
} from "./Service/api";

export class TrmDeikaa extends React.Component {
  state = {
    loading: false,
    message: "",
    toastStatus: false,
    toastColor: "danger",
    tableData: [],
    activepage: 1,
    perPage: 30,
    totalItemCount: 0,
    type: "pending",
    setShowAlert: false,
    deikaaid: "",
    review: "",
    actionid: "",
    ctrid: "",
    actiontitle: "",
    deikaaStatus: "",
    showToast: false,
    toastMessage: "",
    search: "",
    sorting: { field: "", order: "" },
    matches: window.matchMedia("(max-width: 2491px)").matches,
    headers: [
      { name: "No#", field: "id", sortable: false },
      { name: "Name", field: "fullName", sortable: false },
      { name: "Email", field: "email", sortable: false },
      { name: "Contact No.", field: "mobile", sortable: false },
      { name: "Action", field: "qualifier", sortable: false },
      { name: "", field: "action", sortable: false },
    ],
    trmheaders: [
      { name: "No#", field: "id", sortable: false },
      { name: "Name", field: "fullName", sortable: false },
      { name: "Email", field: "email", sortable: false },
      { name: "Contact No.", field: "mobile", sortable: false },
      { name: "Remarks", field: "updated_remarks", sortable: false },
      { name: "Created on", field: "created_on", sortable: false },
      { name: "updated by", field: "update_by", sortable: false },
      { name: "Action", field: "action_title", sortable: false },
      { name: "", field: "action_title", sortable: false },
    ],
  };

  // get pending tokens
  getPendingTableData() {
    this.setState({
      loading: true,
      tableData: [],
      type: "pending",
    });
    const request = {
      page: this.state.activepage,
      search_ctr: this.state.search ?? "",
    };
    GetPendingContractorList(request).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        const finalData = e.data.map((item) => ({
          ...item,
          fullName: `${item.firstname} ${item.lastname}` || "",
        }));
        this.setState({
          loading: false,
          tableData: finalData,
          type: "pending",
          perPage: e.itemsCountPerPage,
          totalItemCount: e.totalItemsCount,
        });
      } else {
        this.setState({
          loading: false,
          perPage: 0,
          totalItemCount: 0,
        });
      }
    });
  }

  // get approved tokens
  getApprovedTableData() {
    this.setState({
      loading: true,
      tableData: [],
      type: "approved",
    });
    const request = {
      page: this.state.activepage,
      search_ctr: this.state.search ?? "",
    };
    GetApprovedContractorList(request).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        const finalData = e.data.map((item) => ({
          ...item,
          fullName: `${item.firstname} ${item.lastname}` || "",
        }));
        this.setState({
          loading: false,
          tableData: finalData,
          perPage: e.itemsCountPerPage,
          totalItemCount: e.totalItemsCount,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  // get rejected tokens
  getRejectedTableData() {
    this.setState({
      loading: true,

      tableData: [],
      type: "reject",
    });
    const request = {
      page: this.state.activepage,
      search_ctr: this.state.search ?? "",
    };
    GetRejectContractorList(request).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        const finalData = e.data.map((item) => ({
          ...item,
          fullName: `${item.firstname} ${item.lastname}` || "",
        }));
        this.setState({
          loading: false,
          tableData: finalData,
          perPage: e.itemsCountPerPage,
          totalItemCount: e.totalItemsCount,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  // get trm logs

  getTrmLogsTableData() {
    this.setState({
      loading: true,
      tableData: [],

      type: "trmlog",
    });
    const request = {
      page: this.state.activepage,
      search_ctr: this.state.search ?? "",
    };
    GetTrmlogsList(request).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        const finalData = e.data.map((item) => ({
          ...item,
          fullName: `${item.firstname} ${item.lastname}` || "",
        }));
        this.setState({
          loading: false,
          tableData: finalData,
          perPage: e.itemsCountPerPage,
          totalItemCount: e.totalItemsCount,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentDidMount() {
    this.getPendingTableData();
    const handler = (e) => this.setState({ matches: e.matches });
    window
      .matchMedia("(max-width: 2491px)")
      .addEventListener("change", handler);
  }

  // approval and reject update
  approveReject(review: string) {
    this.setState({
      loading: true,
    });
    var postData = {
      diekaa_id: this.state.deikaaid,
      status: this.state.deikaaStatus,
      remark: review,
      action_id: this.state.actionid,
      action_title: this.state.actiontitle,
      ctr_id: this.state.ctrid,
    };
    UpdatetokensData(postData).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        this.setState({
          loading: false,
        });
        this.getPendingTableData();
        this.setState({
          review: "",
          deikaaid: "",
          deikaaStatus: "",
          setShowAlert: false,
          toastMessage: e.message,
          toastColor: "success",
          showToast: true,
        });
      } else {
        this.setState({
          toastMessage: e ? e.message : "Please try again",
          toastColor: "danger",
          showToast: true,
          loading: false,
        });
      }
    });
  }

  //   pagination click
  handlePageChange(e: any, typeData: string) {
    if (typeData === "pending") {
      this.getPendingTableData();
    } else if (typeData === "approved") {
      this.getApprovedTableData();
    } else if (typeData === "reject") {
      this.getRejectedTableData();
    } else {
      this.getTrmLogsTableData();
    }
  }

  // search
  handleSearch(typeData: string) {
    if (typeData === "pending") {
      this.getPendingTableData();
    } else if (typeData === "approved") {
      this.getApprovedTableData();
    } else if (typeData === "reject") {
      this.getRejectedTableData();
    } else {
      this.getTrmLogsTableData();
    }
  }

  checkStatus(value: any) {
    switch (value) {
      case "0":
        return (
          <IonChip color="warning">
            <IonLabel>Pending</IonLabel>
          </IonChip>
        );
      case "1":
        return (
          <IonChip color="success">
            <IonLabel>Approved</IonLabel>
          </IonChip>
        );
      case "2":
        return (
          <IonChip color="danger">
            <IonLabel>Rejected</IonLabel>
          </IonChip>
        );
      default:
        return "";
    }
  }

  render() {
    return (
      <IonPage>
        <HeaderNav />
        <IonContent>
          <IonLoading
            cssClass="my-custom-class"
            isOpen={this.state.loading}
            onDidDismiss={() => this.setState({ loading: false })}
          />
          <IonToast
            isOpen={this.state.showToast}
            onDidDismiss={() => this.setState({ showToast: false })}
            message={this.state.toastMessage}
            duration={3000}
            color={this.state.toastColor}
          />
          <IonAlert
            mode={"ios"}
            isOpen={this.state.setShowAlert}
            onDidDismiss={() => this.setState({ setShowAlert: false })}
            cssClass="custom-alert-review"
            header={"Review"}
            inputs={[
              {
                name: "review",
                type: "textarea",
                placeholder: "Say review",
              },
            ]}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {
                  this.setState({
                    review: "",
                    deikaaid: "",
                    deikaaStatus: "",
                    setShowAlert: false,
                  });
                },
              },
              {
                text: "Confirm",
                handler: (alertData) => {
                  this.approveReject(alertData.review);
                },
              },
            ]}
          />

          <div className={this.state.matches ? "container" : ""}>
            <h1>Deikaa Report </h1>
            <IonGrid className="center">
              <IonRow>
                <IonCol sizeLg="8" sizeXs="12">
                  <IonButton
                    className="remove-shadow"
                    onClick={(e) => {
                      this.setState({
                        search: "",
                        activepage: 1,
                      });
                      setTimeout(() => this.getPendingTableData(), 1 * 1000);
                    }}
                  >
                    Pending Report
                  </IonButton>
                  <IonButton
                    className="remove-shadow"
                    onClick={(e) => {
                      this.setState({
                        search: "",
                        activepage: 1,
                      });
                      setTimeout(() => this.getApprovedTableData(), 1 * 1000);
                    }}
                  >
                    Approved Report
                  </IonButton>
                  <IonButton
                    className="remove-shadow"
                    onClick={(e) => {
                      this.setState({
                        search: "",
                        activepage: 1,
                      });
                      setTimeout(() => this.getRejectedTableData(), 1 * 1000);
                    }}
                  >
                    Reject Report
                  </IonButton>
                  <IonButton
                    className="remove-shadow"
                    onClick={(e) => {
                      this.setState({
                        search: "",
                        activepage: 1,
                      });
                      setTimeout(() => this.getTrmLogsTableData(), 1 * 1000);
                    }}
                  >
                    Trm logs
                  </IonButton>
                </IonCol>
                <IonCol sizeLg="4" sizeXs="12" className="d-flex ">
                  <Search
                    value={this.state.search}
                    onSearch={(value) => {
                      this.setState({
                        search: value,
                        activepage: 1,
                      });
                    }}
                    onclickaction={(e: any) =>
                      this.handleSearch(this.state.type)
                    }
                  />
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12">
                  <IonCard className="ion-padding">
                    <div className="scrollit">
                    {this.state.type !== "trmlog" ? (
                      <table className="table table-bordered responsive">
                        <TableHeader
                          headers={this.state.headers}
                          onSorting={(field, order) => {
                            this.setState({
                              sorting: field,
                              order,
                            });
                          }}
                        />
                        <tbody>
                          {this.state.tableData.map((e: any, index: number) => (
                            <tr key={index}>
                              <th scope="row" key={index}>
                                {index + 1}
                              </th>
                              <td>{StringMap(e.fullName)}</td>
                              <td>{StringMap(e.email)}</td>
                              <td>{StringMap(e.mobile)}</td>
                              <td>{StringMap(e.qualifier)}</td>
                              <td>
                                <IonButton
                                  shape="round"
                                  fill="clear"
                                  href={"/publicview/" + e.public_id}
                                  target="_blank"
                                  className="remove-shadow custom-table-icon-btn"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="view the report"
                                >
                                  <IonIcon
                                    icon={eyeOutline}
                                    color="secondary"
                                    slot="icon-only"
                                  />
                                </IonButton>
                                {this.state.type === "pending" && (
                                  <>
                                    <IonButton
                                      onClick={() => {
                                        this.setState({
                                          setShowAlert: true,
                                          deikaaid: e.diekaa_id,
                                          actionid: e.action_id,
                                          actiontitle: e.action_title,
                                          ctrid: e.ctr_id,
                                          deikaaStatus: "1",
                                        });
                                      }}
                                      shape="round"
                                      fill="clear"
                                      className="remove-shadow custom-table-icon-btn"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Approve the report"
                                    >
                                      <IonIcon
                                        icon={checkmarkCircleOutline}
                                        color="warning"
                                        slot="icon-only"
                                      />
                                    </IonButton>
                                    <IonButton
                                      onClick={() => {
                                        this.setState({
                                          setShowAlert: true,
                                          deikaaid: e.diekaa_id,
                                          actionid: e.action_id,
                                          actiontitle: e.action_title,
                                          ctrid: e.ctr_id,
                                          deikaaStatus: "2",
                                        });
                                      }}
                                      shape="round"
                                      fill="clear"
                                      className="remove-shadow custom-table-icon-btn"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="bottom"
                                      title="Reject the report"
                                    >
                                      <IonIcon
                                        icon={closeCircle}
                                        color="danger"
                                        slot="icon-only"
                                      />
                                    </IonButton>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <table className="table table-bordered responsive">
                        <TableHeader
                          headers={this.state.trmheaders}
                          onSorting={(field, order) => {
                            this.setState({
                              sorting: field,
                              order,
                            });
                          }}
                        />
                        <tbody>
                          {this.state.tableData.map((e: any, index: number) => (
                            <tr key={index}>
                              <th scope="row" key={index}>
                                {index + 1}
                              </th>
                              <td>{StringMap(e.fullName)}</td>
                              <td>{StringMap(e.email)}</td>
                              <td>{StringMap(e.mobile)}</td>
                              <td>{StringMap(e.updated_remarks)}</td>
                              <td>{TableDateStringMap(e.created_on)}</td>
                              <td>
                                {e.update_by}-
                                {TableDateStringMap(e.updated_time)}
                              </td>
                              <td>{StringMap(e.qualifier)}</td>
                              <td>{this.checkStatus(e.status)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    </div>
                    <div className="text-center">
                      {this.state.totalItemCount === 0 && "No data found"}
                    </div>
                  </IonCard>
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12">
                  <Pagination
                    activePage={this.state.activepage}
                    itemsCountcontractorperPage={this.state.perPage}
                    totalItemsCount={this.state.totalItemCount}
                    pageRangeDisplayed={8}
                    onChange={(e) => {
                      this.setState({
                        activepage: e,
                        perPage: 0,
                        totalItemCount: 0,
                      });
                      setTimeout(
                        () => this.handlePageChange(e, this.state.type),
                        1 * 1000
                      );
                    }}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default React.memo(TrmDeikaa);
