import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import { LocationSearch } from "../../../../component";
import { apiCallBack, YearsList } from "../../../../service";
import {
  saveEducation,
  updateEducation,
  DeleteEducation,
} from "../../Service/api";

interface locationData {
  geo_location: string;
  geo_distance: string;
  latitude: any;
  longitude: any;
}
const fieldValue: locationData = {
  geo_location: "",
  geo_distance: "",
  latitude: "",
  longitude: "",
};

function EducationForm({
  initialValueData,
  closeModal,
  ctrid,
  seteducationData,
  updateMainPageEducationData,
  eventType,
  deleteeducationData,
  whenCall,
}: any) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const [uploading, setUploading] = React.useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert1, setShowAlert1] = React.useState(false);
  const [latlon, setLatLon] = React.useState<any>();

  const [locationname, setlocation] = useState<any>({
    geo_location: "",
    latitude: "",
    longitude: "",
  });

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  React.useEffect(() => {
    setlocation({
      geo_location: initialValueData.geo_location,
      latitude: "",
      longitude: "",
    });
    setValue("geo_location", initialValueData.geo_location);
    setValue("degree", initialValueData.degree);
    setValue("field_study", initialValueData.field_study);
    setValue("inst_name", initialValueData.inst_name);
    setValue("result_type", initialValueData.result_type ?? "CGPA");
    setValue("result", initialValueData.result ?? "0");
    setValue("end_year", initialValueData.end_year);
    setValue("start_year", initialValueData.start_year);
    setValue(
      "current_education",
      initialValueData.current_education === "1" ? true : false
    );
  }, [whenCall]);

  const saveEducationData = (values: any) => {
    setShowLoading(true);
    values.ctr_id = ctrid;
    values.current_education = values.current_education === true ? 1 : 0;
    values.start_year = values.start_year ? values.start_year : "";
    values.end_year = values.end_year ? values.end_year : "";
    values.latitude = locationname.latitude ?? "";
    values.longitude = locationname.longitude ?? "";
    values.geo_location = values.geo_location ?? "";

    if (eventType === "Add") {
      setUploading(true);
      saveEducation(values).then((e) => {
        if (apiCallBack(e)) {
          seteducationData(e.data);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
          setShowLoading(false);
          closeModal(false);
          setUploading(false);
        } else if (e.status === 201) {
          setShowLoading(false);
          const errorInfo = e.message;
          if (typeof errorInfo === "string") {
            setToastColor("danger");
            setToastMessage(e.message);
            setShowToast(true);
          } else {
            setToastColor("danger");
            for (const [key, value] of Object.entries(errorInfo)) {
              setToastMessage(`${value}`);
              setShowToast(true);
            }
            setShowLoading(false);
          }
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(e.message);
          setShowToast(false);
          setUploading(false);
        }
      });
    } else {
      setUploading(true);
      values.ctr_qid = initialValueData.ctr_qid;
      updateEducation(values).then((e) => {
        if (apiCallBack(e)) {
          seteducationData(e.data);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
          setUploading(false);
          setShowLoading(false);
          closeModal(false);
        } else if (e.status === 201) {
          setShowLoading(false);
          const errorInfo = e.message;
          if (typeof errorInfo === "string") {
            setToastColor("danger");
            setToastMessage(e.message);
            setShowToast(true);
          } else {
            setToastColor("danger");
            for (const [key, value] of Object.entries(errorInfo)) {
              setToastMessage(`${value}`);
              setShowToast(true);
            }
            setShowLoading(false);
          }
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(
            e !== undefined ? e.message : "please try again later"
          );
          setShowToast(false);
          setUploading(false);
        }
      });
    }
  };

  function selectLocation(e: any) {
    setValue("geo_location", e);
    setlocation({
      geo_location: e,
      latitude: latlon ? latlon.lat : "",
      longitude: latlon ? latlon.lng : "",
    });
  }

  function addressLatlon(e: any) {
    setLatLon(e);
  }

  const Deleteeducation = () => {
    var id = initialValueData.ctr_qid;
    if (id) {
      setShowLoading(true);
      DeleteEducation(id).then((e: any) => {
        if (e !== undefined && e.status === 200) {
          deleteeducationData(id);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
          setShowLoading(false);
          closeModal(false);
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(e.message);
          setShowToast(true);
        }
      });
    }
  };

  return (
    <>
      <IonAlert
        isOpen={showAlert1}
        mode={"ios"}
        header={"Are you sure?"}
        subHeader={"Continue to delete the Education."}
        message={"Confirm here"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert1(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert1(false);
              Deleteeducation();
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveEducationData)}>
            <IonRow>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Graduation/degree <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="degree"
                    rules={{
                      required: "Graduation/degree is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Master of Science, HSC, etc."
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("degree")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">Field of study</IonLabel>
                  <Controller
                    control={control}
                    name="field_study"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="IT, business, etc."
                      />
                    )}
                  />
                 
                </IonItem>
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    University/college/school{" "}
                    <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="inst_name"
                    rules={{
                      required: "Institution name is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="IT, business, etc."
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("inst_name")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="locationInput">
                  <IonLabel position="stacked">Location</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <LocationSearch
                        valueKey={value}
                        refresh={value}
                        onChangeValue={onChange}
                        onSelectValue={(e: any) => {
                          selectLocation(e);
                        }}
                        latLong={addressLatlon}
                      />
                    )}
                    name="geo_location"
                    control={control}
                  />
                </div>
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">Result</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        placeholder="Enter the result"
                      />
                    )}
                    control={control}
                    name="result"
                  />
                  <div className="custom-groupInput">
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          <option value="CGPA">CGPA</option>
                          <option value="Percent">Percent</option>
                          <option value="Grade">Grade</option>
                        </select>
                      )}
                      control={control}
                      name="result_type"
                    />
                  </div>
                </IonItem>
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">Start</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select value={value} onChange={onChange}>
                        <option value="">select year</option>
                        {YearsList(0, 80)}
                      </select>
                    )}
                    control={control}
                    rules={{
                      required: false,
                      validate: (value) => {
                        if (parseInt(value) > parseInt(watch("end_year"))) {
                          return "start year is less then end year";
                        }
                      },
                    }}
                    name="start_year"
                  />
                </IonItem>
                {showError("start_year")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">End</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select value={value} onChange={onChange}>
                        <option value="">select year</option>
                        {YearsList(0, 80)}
                      </select>
                    )}
                    control={control}
                    rules={{
                      required: false,
                      validate: (value) => {
                        if (parseInt(value) < parseInt(watch("start_year"))) {
                          return "End year is greater then start year";
                        }
                      },
                    }}
                    name="end_year"
                  />
                </IonItem>
                {showError("end_year")}
                {/* End date is greater then start date */}
                {/* <IonItem lines="none">
                  <label>Ongoing</label>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonToggle
                        checked={value}
                        value={value}
                        slot="end"
                        mode="md"
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue("current_education", e.detail.checked);
                          setValue("end_date", "");
                        }}
                      />
                    )}
                    control={control}
                    name="current_education"
                  />
                </IonItem> */}
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <hr />
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  {eventType !== "Add" ? (
                    <IonButton
                      shape="round"
                      fill="clear"
                      color="danger"
                      className="remove-shadow capitalizes"
                      type="button"
                      onClick={() => setShowAlert1(true)}
                    >
                      Delete Education
                    </IonButton>
                  ) : (
                    <div></div>
                  )}
                  <div>
                    {/* {eventType === "Add" && (
                      <IonButton
                        shape="round"
                        fill="outline"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="button"
                      >
                        Save & Add Another
                      </IonButton>
                    )} */}

                    <IonButton
                      shape="round"
                      fill="solid"
                      color="secondary"
                      className="remove-shadow capitalizes"
                      type="submit"
                    >
                      {uploading ? "Uploading" : "Save"}
                    </IonButton>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
}

export default EducationForm;
function setModal(arg0: boolean) {
  throw new Error("Function not implemented.");
}
