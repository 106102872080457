import {
  IonAlert,
  IonAvatar,
  IonBadge,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonToggle,
  IonPage,
  IonRow,
  IonToast,
  IonLoading,
  IonIcon,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router";
import React from "react";
// import { IoHeartOutline, IoHeart, IoChatbubblesOutline } from "react-icons/io5";
import { Tabs, Tab } from "react-bootstrap";
import {
  CardProfileImagewithoutEncypt,
  ChatMessageBox,
  HeaderNav,
  PaginationScreen,
} from "../../../component";
import { getIndexValue, StringMap } from "../../../service";
import {
  Contractor,
  CRMRole,
  FullDateStringMap,
  Jngreporter,
  Legal,
  NumberStringMap,
  Staff,
  TRM,
  Company,
  EXPStringMap,
  CRMRecuter,
} from "../../../service/default";
import {
  contfavjob,
  getAllJob,
  getContractorFavJobtype,
  getContractorJobtypes,
  GetMatchjobs,
  Updateinvitation,
} from "../Service/api";
import { ChatLayout } from "../..";
import { AuthContext } from "../../../context/authContext";
import useMediaQuery from "../../../hooks/mediaQuery";
import { chatbubble, heart, heartOutline } from "ionicons/icons";

const ListJob = ({ history }: any) => {
  const { auth } = React.useContext(AuthContext);
  const user = auth.data;
  const canditateJobCount = auth.canditateJobCount;
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  // const user = getCookie({ key: "user" }).value;
  const [tabkey, setTabKey] = React.useState(
    !user 
      ? "All" :
    user.user_type === Staff
      ? "All" :  user.user_type === CRMRecuter ? 'All'
      : user.user_type === CRMRole
      ? "All"
      : "Match"
  );
  const [alljobCount, setAlljob] = React.useState(0);
  const [matchCount, setMatch] = React.useState(0);
  const [appliedCount, setApplied] = React.useState(0);
  const [invitedCount, setInvited] = React.useState(0);
  const [ShortlistCount, setShortlist] = React.useState(0);
  const [HiredCount, setHiredCount] = React.useState(0);
  const [RejectedCount, setRejectedCount] = React.useState(0);
  const [favCount, setFav] = React.useState(0);

  const viewPage = (e: any, opt: any) => {
    if (e) {
      const name = e.com_id
        ? e.com_id
        : e.duration_type
        ? e.duration_type
        : ".";
      history.push("/Job/" + name.slice(0, 10), {
        unique_id: e.unique_id,
        workFromhomeType: opt,
        screenName: tabkey,
      });
    }
  };

  const tabPermission = {
    all: [Staff, CRMRole,CRMRecuter],
    match: [TRM, Contractor, Company, Legal, Jngreporter],
    invited: [Contractor, Company, Legal, Jngreporter],
    applied: [Contractor, Company, Legal, Jngreporter],
    shortlisted: [Contractor, Company, Legal, Jngreporter],
    hired: [Contractor, Company, Legal, Jngreporter],
    rejected: [Contractor, Company, Legal, Jngreporter],
    favourite: [Contractor, Company, Legal, Jngreporter],
  };

  const headersubtitle = (key) => {
    switch (key) {
      case "All":
        return "";
      case "Match":
        return "Match jobs";
      case "Invited":
        return "Invited jobs will be listed below";
      case "Applied":
        return "Applied jobs will be listed below";
      case "Shortlisted":
        return "You have been shortlisted for new jobs";
      case "Hired":
        return "Hired jobs will be listed below";
      case "Rejected":
        return "Rejected jobs will be listed below";
      case "Favourite":
        return "Favorite jobs";
      default:
        return "";
    }
  };

  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <div className={m4kQuery ? "container Jobs" : "Jobs"}>
          <div className="px-lg-4 py-3">
            <div className="header-section">
              <h1 className="mt-0">Jobs</h1>
            </div>
            <p className="text-gray">
              {user ? headersubtitle(tabkey) : "Login To Get Recommended Jobs"}
            </p>

            <Tabs
              id="controlled-tab-custom contractor"
              activeKey={tabkey}
              onSelect={(k: any) => setTabKey(k)}
              className="mb-3"
            >
              {tabPermission?.all.includes(user.user_type) && (
                <Tab
                  eventKey="All"
                  title={"All (" + canditateJobCount.all_job + ")"}
                >
                  {tabkey === "All" && (
                    <AllJob
                      ctrid={user && user.ctr_id}
                      detailPage={viewPage}
                      dataSet={(e: any) => {
                        if (e) {
                          setAlljob(e.totalItemsCount ?? "0");
                          setMatch(e.matching_jobs ?? "0");
                          setApplied(e.applied_jobs ?? "0");
                          setInvited(e.invited_jobs ?? "0");
                          setShortlist(e.shortlisted_jobs ?? "0");
                          setHiredCount(e.hired_jobs ?? "0");
                          setRejectedCount(e.rejected_jobs ?? "0");
                          setFav(e.favroite_jobs ?? "0");
                        }
                      }}
                    />
                  )}
                </Tab>
              )}
              {tabPermission?.match.includes(user.user_type) && (
                <Tab
                  eventKey="Match"
                  title={
                    "Match (" + canditateJobCount.matching_candidate_count + ")"
                  }
                >
                  {tabkey === "Match" && (
                    <MatchedJob
                      ctrid={user && user.ctr_id}
                      detailPage={viewPage}
                      dataSet={(e: any) => {
                        if (e) {
                          setMatch(e.matching_jobs ?? matchCount);
                          setApplied(e.applied_jobs ?? appliedCount);
                          setInvited(e.invited_jobs ?? invitedCount);
                          setShortlist(e.shortlisted_jobs ?? ShortlistCount);
                          setHiredCount(e.hired_jobs ?? HiredCount);
                          setRejectedCount(e.rejected_jobs ?? RejectedCount);
                          setFav(e.favroite_jobs ?? favCount);
                        }
                      }}
                    />
                  )}
                </Tab>
              )}
              {tabPermission?.invited.includes(user.user_type) && (
                <Tab
                  eventKey="Invited"
                  title={"Invited (" + canditateJobCount.invited_count + ")"}
                >
                  {tabkey === "Invited" && (
                    <InvitedJob
                      ctrid={user && user.ctr_id}
                      detailPage={viewPage}
                      dataSet={(e: any) => {
                        if (e) {
                          setMatch(e.matching_jobs ?? matchCount);
                          setApplied(e.applied_jobs ?? appliedCount);
                          setInvited(e.invited_jobs ?? invitedCount);
                          setShortlist(e.shortlisted_jobs ?? ShortlistCount);
                          setHiredCount(e.hired_jobs ?? HiredCount);
                          setRejectedCount(e.rejected_jobs ?? RejectedCount);
                          setFav(e.favroite_jobs ?? favCount);
                        }
                      }}
                    />
                  )}
                </Tab>
              )}
              {tabPermission?.applied.includes(user.user_type) && (
                <Tab
                  eventKey="Applied"
                  title={"Applied (" + canditateJobCount.applied_count + ")"}
                >
                  {tabkey === "Applied" && (
                    <AppliedJob
                      ctrid={user && user.ctr_id}
                      detailPage={viewPage}
                      dataSet={(e: any) => {
                        if (e) {
                          setMatch(e.matching_jobs ?? matchCount);
                          setApplied(e.applied_jobs ?? appliedCount);
                          setInvited(e.invited_jobs ?? invitedCount);
                          setShortlist(e.shortlisted_jobs ?? ShortlistCount);
                          setHiredCount(e.hired_jobs ?? HiredCount);
                          setRejectedCount(e.rejected_jobs ?? RejectedCount);
                          setFav(e.favroite_jobs ?? favCount);
                        }
                      }}
                    />
                  )}
                </Tab>
              )}
              {tabPermission?.shortlisted.includes(user.user_type) && (
                <Tab
                  eventKey="Shortlisted"
                  title={
                    "Shortlisted (" + canditateJobCount.shortlisted_count + ")"
                  }
                >
                  {tabkey === "Shortlisted" && (
                    <ShortlistedJob
                      ctrid={user && user.ctr_id}
                      detailPage={viewPage}
                      dataSet={(e: any) => {
                        if (e) {
                          setMatch(e.matching_jobs ?? matchCount);
                          setApplied(e.applied_jobs ?? appliedCount);
                          setInvited(e.invited_jobs ?? invitedCount);
                          setShortlist(e.shortlisted_jobs ?? ShortlistCount);
                          setHiredCount(e.hired_jobs);
                          setRejectedCount(e.rejected_jobs);
                          setFav(e.favroite_jobs ?? favCount);
                        }
                      }}
                    />
                  )}
                </Tab>
              )}
              {tabPermission?.hired.includes(user.user_type) && (
                <Tab
                  eventKey="Hired"
                  title={"Hired (" + canditateJobCount.hired_count + ")"}
                >
                  {tabkey === "Hired" && (
                    <HiredJob
                      ctrid={user && user.ctr_id}
                      detailPage={viewPage}
                      dataSet={(e: any) => {
                        if (e) {
                          setMatch(e.matching_jobs ?? matchCount ?? "0");
                          setApplied(e.applied_jobs ?? appliedCount ?? "0");
                          setInvited(e.invited_jobs ?? invitedCount ?? "0");
                          setShortlist(
                            e.shortlisted_jobs ?? ShortlistCount ?? "0"
                          );
                          setHiredCount(e.hired_jobs ?? "0");
                          setRejectedCount(e.rejected_jobs ?? "0");
                          setFav(e.favroite_jobs ?? favCount ?? "0");
                        }
                      }}
                    />
                  )}
                </Tab>
              )}
              {tabPermission?.rejected.includes(user.user_type) && (
                <Tab
                  eventKey="Rejected"
                  title={"Rejected (" + canditateJobCount.rejected_count + ")"}
                >
                  {tabkey === "Rejected" && (
                    <RejectedJob
                      ctrid={user && user.ctr_id}
                      detailPage={viewPage}
                      dataSet={(e: any) => {
                        if (e) {
                          setMatch(e.matching_jobs ?? matchCount ?? "0");
                          setApplied(e.applied_jobs ?? appliedCount ?? "0");
                          setInvited(e.invited_jobs ?? invitedCount ?? "0");
                          setShortlist(
                            e.shortlisted_jobs ?? ShortlistCount ?? "0"
                          );
                          setHiredCount(e.hired_jobs ?? "0");
                          setRejectedCount(e.rejected_jobs ?? "0");
                          setFav(e.favroite_jobs ?? favCount ?? "0");
                        }
                      }}
                    />
                  )}
                </Tab>
              )}
              {tabPermission?.favourite.includes(user.user_type) && (
                <Tab
                  eventKey="Favourite"
                  title={"Favourite (" + canditateJobCount.fav_count + ")"}
                >
                  {tabkey === "Favourite" && (
                    <FavouriteJob
                      ctrid={user && user.ctr_id}
                      detailPage={viewPage}
                      dataSet={(e) => {
                        if (e) {
                          setMatch(e.matching_jobs ?? matchCount ?? "0");
                          setApplied(e.applied_jobs ?? appliedCount ?? "0");
                          setInvited(e.invited_jobs ?? invitedCount ?? "0");
                          setShortlist(
                            e.shortlisted_jobs ?? ShortlistCount ?? "0"
                          );
                          setHiredCount(e.hired_jobs ?? "0");
                          setRejectedCount(e.rejected_jobs ?? "0");
                          setFav(e.favroite_jobs ?? favCount ?? "0");
                        }
                      }}
                    />
                  )}
                </Tab>
              )}
            </Tabs>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

// All job
const AllJob = ({ ctrid, detailPage, dataSet }: any) => {
  const matchparams = useParams();
  const { auth, setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const user = auth.data;
  // const user = getCookie({ key: "user" }).value;
  const usertype = user ? user.user_type : "";
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  //   search / sort / filter
  const [searchKey, setSearchKey] = React.useState("");
  const [sortBy, setSortBy] = React.useState("desc");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);

  const [tableData, setTableData] = React.useState([]);
  // const [totalJobcount, setJobCount] = React.useState(0);
  const [workfromhometype, setWorkfromhometype] = React.useState<any>([]);

  const searchData = (e) => {
    if (e) {
      getAll("1", sortBy, searchKey);
    }
  };
  React.useEffect(() => {
    let isMounted = true;
    setShowLoading(true);
    setSearchKey(matchparams["cname"] ?? "");
    try {
      getAll("1", sortBy, matchparams["cname"] ?? "");
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
        setTableData([]);
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);
  //   favour
  const postfav = (flag, uniqueid) => {
    const data = {
      unique_id: uniqueid,
      ctr_id: ctrid,
      flag: flag === 1 ? 0 : 1,
    };
    setShowLoading(true);
    contfavjob(data).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
        getAll(currentPage, sortBy, searchKey);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again later"
        );
        setShowToast(true);
      }
    });
  };

  // get All api call
  const getAll = (page: any, sortby: any, search: string) => {
    const result = {
      ctr_id: ctrid,
      page: page,
      order_by: sortby,
      search_by: search,
    };
    setShowLoading(true);
    getAllJob(result).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        const res = e.data;
        setTableData(res !== undefined ? res.result_data : []);
        addCount(res);
        // setJobCount(res.matching_jobs);
        setTotalPageItem(res !== undefined ? res.result_data.length : "0");
        setTotalCount(res.totalItemsCount);
        setWorkfromhometype(res.workfromhome_type);
        setShowLoading(false);
      } else {
        setTableData([]);
        // setJobCount(0);
        setTotalPageItem(0);
        setTotalCount(0);
        setShowLoading(false);
      }
    });
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      all_job: NumberStringMap(e.totalItemsCount),
      applied_count: NumberStringMap(e.applied_jobs),
      hired_count: NumberStringMap(e.hired_jobs),
      invited_count: NumberStringMap(e.invited_jobs),
      matching_candidate_count: NumberStringMap(e.matching_jobs),
      rejected_count: NumberStringMap(e.rejected_jobs),
      shortlisted_count: NumberStringMap(e.shortlisted_jobs),
      fav_count: NumberStringMap(e.favroite_jobs),
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="header-content-flex ion-justify-content-between ion-align-items-center filter-option custom-sort-section">
              {totalPageItem !== 0 ? (
                <IonLabel>
                  Showing{" "}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}
                  {" - "}
                  {currentPage === 1
                    ? totalPageItem
                    : (currentPage - 1) * perPage + totalPageItem > totalCount
                    ? totalCount
                    : (currentPage - 1) * perPage + totalPageItem}
                  {" of " + totalCount}
                </IonLabel>
              ) : (
                <div className="my-3">No jobs found</div>
              )}

              <div className="sort-input-section ion-align-items-center">
                <div className="search-input">
                  <IonInput
                    value={searchKey}
                    placeholder="Search jobs"
                    onIonChange={(e: any) => setSearchKey(e.detail.value)}
                  />

                  <div className="search-icon" onClick={(e) => searchData(e)}>
                    <img alt="" src="assets/image/company/search.svg" />
                  </div>
                </div>

                <div className="input-group sort-group ion-sort-input no-rightspace">
                  <IonLabel className="input-group-text">Sort:</IonLabel>
                  <IonSelect
                    interface="popover"
                    placeholder="Select"
                    className="sort-input"
                    value={sortBy}
                    onIonChange={(e) => {
                      setSortBy(e.detail.value);
                      getAll(currentPage, e.detail.value, searchKey);
                    }}
                  >
                    <IonSelectOption value="desc">Latest</IonSelectOption>
                    <IonSelectOption value="asc">Oldest</IonSelectOption>
                  </IonSelect>
                </div>
                {/* <div className="input-group sort-group">
                  <span className="input-group-text" id="basic-addon1">
                    Sort:
                  </span>
                  <select
                    className="form-select sort-input"
                    value={sortBy}
                    onChange={(e: any) => {
                      setSortBy(e.target.value);
                      getAll(currentPage, e.target.value, searchKey);
                    }}
                  >
                    <option value="desc">Latest</option>
                    <option value="asc">Oldest</option>
                  </select>
                </div> */}
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          {!showLoading
            ? tableData.map((cand: any, index: number) => (
                <IonCol sizeLg="12" sizeXs="12" key={index}>
                  <UserCard
                    cardData={cand}
                    page="All"
                    workfromhometypeOption={workfromhometype}
                    favicon={
                      usertype === TRM
                        ? false
                        : usertype === Staff || usertype === CRMRole
                        ? false
                        : true
                    }
                    tag={false}
                    chatIcon={false}
                    favclick={postfav}
                    jobdetail={(e) => detailPage(e, workfromhometype)}
                  />
                </IonCol>
              ))
            : "Loading ..."}
          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e: any) => {
                  setCurrentPage(e);
                  getAll(e, sortBy, searchKey);
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

// Matched job
const MatchedJob = ({ history, ctrid, detailPage, dataSet }: any) => {
  const { auth, setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const user = auth.data;
  // const user = getCookie({ key: "user" }).value;
  const location = useLocation<any>();
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);

  //   search / sort / filter
  const [searchKey, setSearchKey] = React.useState("");
  const [sortBy, setSortBy] = React.useState("best");

  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);

  const [tableData, setTableData] = React.useState([]);
  const [totalJobcount, setJobCount] = React.useState(0);
  const [workfromhometype, setWorkfromhometype] = React.useState<any>([]);

  const searchData = (e: any) => {
    if (e) {
      getMatched("1", sortBy);
    }
  };
  React.useEffect(() => {
    let isMounted = true;
    setShowLoading(true);
    try {
      getMatched("1", sortBy);
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
        setTableData([]);
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);
  //   favour
  const postfav = (flag, uniqueid) => {
    const data = {
      unique_id: uniqueid,
      ctr_id: ctrid,
      flag: flag === 1 ? 0 : 1,
    };
    setShowLoading(true);
    contfavjob(data).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
        getMatched(currentPage, sortBy);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again later"
        );
        setShowToast(true);
      }
    });
  };
  // get Matched api call
  const getMatched = (page: any, sortby: any) => {
    const statevalue =
      user && user.user_type === TRM
        ? location.state.ctrid
        : user.ctr_id ?? location.state.ctrid;
    const result = {
      ctr_id: statevalue,
      page: page,
      order_by: sortby,
      search_by: searchKey,
    };
    setShowLoading(true);
    GetMatchjobs(result).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setTableData(e.data !== undefined ? e.data : []);
        addCount(e);
        setJobCount(e.matching_jobs);
        setTotalPageItem(e.data !== undefined ? e.data.length : "0");
        setTotalCount(e.totalItemsCount);
        setWorkfromhometype(e.workfromhome_type);
        setShowLoading(false);
      } else {
        setTableData([]);
        setJobCount(0);
        dataSet(e);
        setTotalPageItem(0);
        setTotalCount(0);
        setShowLoading(false);
      }
    });
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_jobs),
      hired_count: NumberStringMap(e.hired_jobs),
      invited_count: NumberStringMap(e.invited_jobs),
      matching_candidate_count: NumberStringMap(e.matching_jobs),
      rejected_count: NumberStringMap(e.rejected_jobs),
      shortlisted_count: NumberStringMap(e.shortlisted_jobs),
      fav_count: NumberStringMap(e.favroite_jobs),
    });
  };
  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="header-content-flex ion-justify-content-between ion-align-items-center filter-option custom-sort-section">
              {totalJobcount !== 0 ? (
                <IonLabel>
                  Showing{" "}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}
                  {" - "}
                  {currentPage === 1
                    ? totalPageItem
                    : (currentPage - 1) * perPage + totalPageItem > totalCount
                    ? totalCount
                    : (currentPage - 1) * perPage + totalPageItem}
                  {" of " + totalCount}
                </IonLabel>
              ) : (
                <div className="my-3">No matching jobs</div>
              )}

              <div className="sort-input-section ion-align-items-center ">
                <div className="search-input pt-2">
                  <IonInput
                    value={searchKey}
                    placeholder="Search jobs"
                    onIonChange={(e: any) => setSearchKey(e.detail.value)}
                  />

                  <div className="search-icon" onClick={(e) => searchData(e)}>
                    <img alt="" src="assets/image/company/search.svg" />
                  </div>
                </div>
                <div className="input-group sort-group ion-sort-input no-rightspace">
                  <IonLabel className="input-group-text">Sort:</IonLabel>
                  <IonSelect
                    interface="popover"
                    placeholder="Select"
                    className="sort-input"
                    value={sortBy}
                    onIonChange={(e) => {
                      setSortBy(e.detail.value);
                    }}
                  >
                    <IonSelectOption value="best">Best Match</IonSelectOption>
                    {/* <IonSelectOption value="desc">Latest</IonSelectOption> */}
                  </IonSelect>
                </div>
                {/* <div className="input-group sort-group">
                  <span className="input-group-text" id="basic-addon1">
                    Sort:
                  </span>
                  <select
                    className="form-select sort-input"
                    value={sortBy}
                    onChange={(e: any) => {
                      setSortBy(e.target.value);
                    }}
                  >
                    <option value="best">Best</option>
                  </select>
                </div> */}
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          {!showLoading
            ? tableData.map((cand: any, index: number) => (
                <IonCol sizeLg="12" sizeXs="12" key={index}>
                  <UserCard
                    cardData={cand}
                    page="Match"
                    workfromhometypeOption={workfromhometype}
                    favicon={user && user.user_type === TRM ? false : true}
                    tag={false}
                    chatIcon={false}
                    favclick={postfav}
                    jobdetail={(e) => detailPage(e, workfromhometype)}
                  />
                </IonCol>
              ))
            : "Loading ..."}
          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e: any) => {
                  setCurrentPage(e);
                  getMatched(e, sortBy);
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

// Invited job
const InvitedJob = ({ history, ctrid, detailPage }: any) => {
  const { setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);
  const [workfromhometype, setWorkfromhometype] = React.useState<any>([]);

  React.useEffect(() => {
    let isMounted = true;
    setShowLoading(true);
    try {
      getInvite("1", "1");
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
        setTableData([]);
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const moreUpdate = (data, statusres) => {
    if (data) {
      const result = {
        ctr_id: ctrid,
        unique_id: data.unique_id,
        status: statusres,
        com_id: data.com_id,
      };
      setShowLoading(true);
      Updateinvitation(result).then((e: any) => {
        if (e !== undefined && e.status === 200) {
          getInvite(currentPage, "1");
          setShowLoading(false);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(
            e !== undefined ? e.message : "please try again later"
          );
          setShowToast(true);
        }
      });
    }
  };
  // get Invited api call
  const getInvite = (page: any, inviteStatus: any) => {
    setShowLoading(true);
    getContractorJobtypes(ctrid + "/" + page + "/Invited/" + inviteStatus).then(
      (e: any) => {
        if (e !== undefined && e.status === 200) {
          setTableData(e.data !== undefined ? e.data : []);
          addCount(e);
          setTotalPageItem(e.data !== undefined ? e.data.length : "0");
          setTotalCount(e.totalItemsCount);
          setWorkfromhometype(e.workfromhome_type);
          setShowLoading(false);
        } else {
          setTableData([]);
          setShowLoading(false);
        }
      }
    );
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_jobs),
      hired_count: NumberStringMap(e.hired_jobs),
      invited_count: NumberStringMap(e.invited_jobs),
      matching_candidate_count: NumberStringMap(e.matching_jobs),
      rejected_count: NumberStringMap(e.rejected_jobs),
      shortlisted_count: NumberStringMap(e.shortlisted_jobs),
      fav_count: NumberStringMap(e.favroite_jobs),
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="header-content-flex">
              {tableData.length > 0 ? (
                <div className="my-3">
                  Showing{" "}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}
                  {" - "}
                  {currentPage === 1
                    ? totalPageItem
                    : (currentPage - 1) * perPage + totalPageItem > totalCount
                    ? totalCount
                    : (currentPage - 1) * perPage + totalPageItem}
                  {" of " + totalCount}
                </div>
              ) : (
                <div className="my-3">No invited jobs found</div>
              )}
              <div className="sort-input-section">
                <IonItem lines="none" className="custome-item-color">
                  {checked ? (
                    <div>View invites</div>
                  ) : (
                    <div>View ignored invites</div>
                  )}
                  <IonToggle
                    slot="end"
                    mode="md"
                    checked={checked}
                    onIonChange={(e: any) => {
                      setChecked(e.detail.checked);
                      getInvite("1", e.detail.checked ? "2" : "1");
                    }}
                  />
                </IonItem>
              </div>
            </div>
          </IonCol>
          {!showLoading
            ? tableData.map((cand: any, index: number) => (
                <IonCol sizeLg="12" sizeXs="12" key={index}>
                  <UserCard
                    cardData={cand}
                    page="Invited"
                    workfromhometypeOption={workfromhometype}
                    favicon={false}
                    tag={true}
                    chatIcon={true}
                    moreIcon={true}
                    moreUpdateresult={moreUpdate}
                    invideCheck={checked}
                    jobdetail={(e) => detailPage(e, workfromhometype)}
                  />
                </IonCol>
              ))
            : "Loading ..."}

          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e: any) => {
                  setCurrentPage(e);
                  getInvite(e, checked ? "1" : "0");
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

// Applied job
const AppliedJob = ({ history, ctrid, detailPage }: any) => {
  const { setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);

  const [tableData, setTableData] = React.useState([]);
  const [workfromhometype, setWorkfromhometype] = React.useState<any>([]);
  React.useEffect(() => {
    let isMounted = true;
    setShowLoading(true);
    try {
      getApplied("1");
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
        setTableData([]);
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  // get Applied api call
  const getApplied = (page: any) => {
    setShowLoading(true);
    getContractorJobtypes(ctrid + "/" + page + "/Applied/1").then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setTableData(e.data !== undefined ? e.data : []);
        addCount(e);
        setTotalPageItem(e.data !== undefined ? e.data.length : "0");
        setTotalCount(e.totalItemsCount);
        setWorkfromhometype(e.workfromhome_type);
        setShowLoading(false);
      } else {
        setTableData([]);
        setShowLoading(false);
      }
    });
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_jobs),
      hired_count: NumberStringMap(e.hired_jobs),
      invited_count: NumberStringMap(e.invited_jobs),
      matching_candidate_count: NumberStringMap(e.matching_jobs),
      rejected_count: NumberStringMap(e.rejected_jobs),
      shortlisted_count: NumberStringMap(e.shortlisted_jobs),
      fav_count: NumberStringMap(e.favroite_jobs),
    });
  };
  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonGrid>
        <IonRow>
          {tableData.length > 0 && (
            <IonCol size="12">
              <div className="header-content-flex">
                <div className="my-3">
                  Showing{" "}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}
                  {" - "}
                  {currentPage === 1
                    ? totalPageItem
                    : (currentPage - 1) * perPage + totalPageItem > totalCount
                    ? totalCount
                    : (currentPage - 1) * perPage + totalPageItem}
                  {" of " + totalCount}
                </div>
              </div>
            </IonCol>
          )}
          {!showLoading
            ? tableData.map((cand: any, index: number) => (
                <IonCol sizeLg="12" sizeXs="12" key={index}>
                  <UserCard
                    cardData={cand}
                    page="Applied"
                    workfromhometypeOption={workfromhometype}
                    favicon={false}
                    tag={true}
                    chatIcon={true}
                    jobdetail={(e) => detailPage(e, workfromhometype)}
                  />
                </IonCol>
              ))
            : "Loading ..."}

          {!showLoading && tableData.length === 0 && (
            <IonCol size="12">No applied jobs found </IonCol>
          )}
          <IonCol size="12">
            {!showLoading && totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e: any) => {
                  setCurrentPage(e);
                  getApplied(e);
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

// Shortlisted job
const ShortlistedJob = ({ history, ctrid, detailPage }: any) => {
  const { setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);
  const [workfromhometype, setWorkfromhometype] = React.useState<any>([]);
  React.useEffect(() => {
    let isMounted = true;
    setShowLoading(true);
    try {
      getShortlisted("1");
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
        setTableData([]);
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  // get Shortlisted api call
  const getShortlisted = (page: any) => {
    setShowLoading(true);
    getContractorJobtypes(ctrid + "/" + page + "/Shortlisted/1").then(
      (e: any) => {
        if (e !== undefined && e.status === 200) {
          addCount(e);
          setTableData(e.data !== undefined ? e.data : []);
          setTotalPageItem(e.data !== undefined ? e.data.length : "0");
          setTotalCount(e.totalItemsCount);
          setWorkfromhometype(e.workfromhome_type);
          setShowLoading(false);
        } else {
          setTableData([]);
          setShowLoading(false);
        }
      }
    );
  };
  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_jobs),
      hired_count: NumberStringMap(e.hired_jobs),
      invited_count: NumberStringMap(e.invited_jobs),
      matching_candidate_count: NumberStringMap(e.matching_jobs),
      rejected_count: NumberStringMap(e.rejected_jobs),
      shortlisted_count: NumberStringMap(e.shortlisted_jobs),
      fav_count: NumberStringMap(e.favroite_jobs),
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonGrid>
        <IonRow>
          {tableData.length > 0 && (
            <IonCol size="12">
              <div className="header-content-flex">
                <div className="my-3">
                  Showing{" "}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}
                  {" - "}
                  {currentPage === 1
                    ? totalPageItem
                    : (currentPage - 1) * perPage + totalPageItem > totalCount
                    ? totalCount
                    : (currentPage - 1) * perPage + totalPageItem}
                  {" of " + totalCount}
                </div>
              </div>
            </IonCol>
          )}
          {!showLoading
            ? tableData.map((cand: any, index: number) => (
                <IonCol sizeLg="12" sizeXs="12" key={index}>
                  <UserCard
                    cardData={cand}
                    page="Shortlisted"
                    workfromhometypeOption={workfromhometype}
                    favicon={false}
                    tag={true}
                    chatIcon={true}
                    jobdetail={(e) => detailPage(e, workfromhometype)}
                  />
                </IonCol>
              ))
            : "Loading ..."}

          {!showLoading && tableData.length === 0 && (
            <IonCol size="12">No shortlisted job found </IonCol>
          )}
          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e: any) => {
                  setCurrentPage(e);
                  getShortlisted(e);
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

// favourite job
const FavouriteJob = ({ history, ctrid, detailPage, dataSet }: any) => {
  const { setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);
  const [workfromhometype, setWorkfromhometype] = React.useState<any>([]);
  React.useEffect(() => {
    let isMounted = true;
    setShowLoading(true);
    try {
      getFavourite("1");
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
        setTableData([]);
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  // get favourite api call
  const getFavourite = (page: any) => {
    setShowLoading(true);
    getContractorFavJobtype(ctrid + "/" + page).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setTableData(e.data !== undefined ? e.data : []);
        setTotalPageItem(e.data !== undefined ? e.data.length : "0");
        setTotalCount(e.totalItemsCount);
        addCount(e);
        setWorkfromhometype(e.workfromhome_type);
        setShowLoading(false);
      } else {
        setTableData([]);
        setShowLoading(false);
      }
    });
  };

  //   favour
  const postfav = (flag, uniqueid) => {
    const data = {
      unique_id: uniqueid,
      ctr_id: ctrid,
      flag: 0,
    };
    setShowLoading(true);
    contfavjob(data).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
        getFavourite(currentPage);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again later"
        );
        setShowToast(true);
      }
    });
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_jobs),
      hired_count: NumberStringMap(e.hired_jobs),
      invited_count: NumberStringMap(e.invited_jobs),
      matching_candidate_count: NumberStringMap(e.matching_jobs),
      rejected_count: NumberStringMap(e.rejected_jobs),
      shortlisted_count: NumberStringMap(e.shortlisted_jobs),
      fav_count: NumberStringMap(e.favroite_jobs),
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonGrid>
        <IonRow>
          {tableData.length > 0 && (
            <IonCol size="12">
              <div className="header-content-flex">
                <div className="my-3">
                  Showing{" "}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}
                  {" - "}
                  {currentPage === 1
                    ? totalPageItem
                    : (currentPage - 1) * perPage + totalPageItem > totalCount
                    ? totalCount
                    : (currentPage - 1) * perPage + totalPageItem}
                  {" of " + totalCount}
                </div>
              </div>
            </IonCol>
          )}
          {!showLoading
            ? tableData.map((cand: any, index: number) => (
                <IonCol sizeLg="12" sizeXs="12" key={index}>
                  <UserCard
                    cardData={cand}
                    page="Favourite"
                    workfromhometypeOption={workfromhometype}
                    favicon={true}
                    tag={true}
                    chatIcon={false}
                    favclick={postfav}
                    jobdetail={(e) => detailPage(e, workfromhometype)}
                  />
                </IonCol>
              ))
            : "Loading ..."}

          {!showLoading && tableData.length === 0 && (
            <IonCol size="12">No favourite jobs found </IonCol>
          )}
          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e: any) => {
                  setCurrentPage(e);
                  getFavourite(e);
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

// Hired job
const HiredJob = ({ history, ctrid, detailPage }: any) => {
  const { setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);
  const [workfromhometype, setWorkfromhometype] = React.useState<any>([]);
  React.useEffect(() => {
    let isMounted = true;
    setShowLoading(true);
    try {
      getHired("1");
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
        setTableData([]);
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  // get hired api call
  const getHired = (page: any) => {
    setShowLoading(true);
    getContractorJobtypes(ctrid + "/" + page + "/Hired/1").then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setTableData(e.data !== undefined ? e.data : []);
        addCount(e);
        setTotalPageItem(e.data !== undefined ? e.data.length : "0");
        setTotalCount(e.totalItemsCount);
        setWorkfromhometype(e.workfromhome_type);
        setShowLoading(false);
      } else {
        setTableData([]);
        setShowLoading(false);
      }
    });
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_jobs),
      hired_count: NumberStringMap(e.hired_jobs),
      invited_count: NumberStringMap(e.invited_jobs),
      matching_candidate_count: NumberStringMap(e.matching_jobs),
      rejected_count: NumberStringMap(e.rejected_jobs),
      shortlisted_count: NumberStringMap(e.shortlisted_jobs),
      fav_count: NumberStringMap(e.favroite_jobs),
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonGrid>
        <IonRow>
          {tableData.length > 0 && (
            <IonCol size="12">
              <div className="header-content-flex">
                <div className="my-3">
                  Showing{" "}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}
                  {" - "}
                  {currentPage === 1
                    ? totalPageItem
                    : (currentPage - 1) * perPage + totalPageItem > totalCount
                    ? totalCount
                    : (currentPage - 1) * perPage + totalPageItem}
                  {" of " + totalCount}
                </div>
              </div>
            </IonCol>
          )}
          {!showLoading
            ? tableData.map((cand: any, index: number) => (
                <IonCol sizeLg="12" sizeXs="12" key={index}>
                  <UserCard
                    cardData={cand}
                    page="Shortlisted"
                    workfromhometypeOption={workfromhometype}
                    favicon={false}
                    tag={true}
                    chatIcon={true}
                    jobdetail={(e) => detailPage(e, workfromhometype)}
                  />
                </IonCol>
              ))
            : "Loading ..."}

          {!showLoading && tableData.length === 0 && (
            <IonCol size="12">No hired job found </IonCol>
          )}
          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e: any) => {
                  setCurrentPage(e);
                  getHired(e);
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

// Rejected job
const RejectedJob = ({ history, ctrid, detailPage }: any) => {
  const { setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [tableData, setTableData] = React.useState([]);
  const [workfromhometype, setWorkfromhometype] = React.useState<any>([]);
  React.useEffect(() => {
    let isMounted = true;
    setShowLoading(true);
    try {
      getRejected("1");
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
        setTableData([]);
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  // get Rejected api call
  const getRejected = (page: any) => {
    setShowLoading(true);
    getContractorJobtypes(ctrid + "/" + page + "/Rejected/1").then((e: any) => {
      if (e !== undefined && e.status === 200) {
        addCount(e);
        setTableData(e.data !== undefined ? e.data : []);
        setTotalPageItem(e.data !== undefined ? e.data.length : "0");
        setTotalCount(e.totalItemsCount);
        setWorkfromhometype(e.workfromhome_type);
        setShowLoading(false);
      } else {
        setTableData([]);
        setShowLoading(false);
      }
    });
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_jobs),
      hired_count: NumberStringMap(e.hired_jobs),
      invited_count: NumberStringMap(e.invited_jobs),
      matching_candidate_count: NumberStringMap(e.matching_jobs),
      rejected_count: NumberStringMap(e.rejected_jobs),
      shortlisted_count: NumberStringMap(e.shortlisted_jobs),
      fav_count: NumberStringMap(e.favroite_jobs),
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonGrid>
        <IonRow>
          {tableData.length > 0 && (
            <IonCol size="12">
              <div className="header-content-flex">
                <div className="my-3">
                  Showing{" "}
                  {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}
                  {" - "}
                  {currentPage === 1
                    ? totalPageItem
                    : (currentPage - 1) * perPage + totalPageItem > totalCount
                    ? totalCount
                    : (currentPage - 1) * perPage + totalPageItem}
                  {" of " + totalCount}
                </div>
              </div>
            </IonCol>
          )}
          {!showLoading
            ? tableData.map((cand: any, index: number) => (
                <IonCol sizeLg="12" sizeXs="12" key={index}>
                  <UserCard
                    cardData={cand}
                    page="Shortlisted"
                    workfromhometypeOption={workfromhometype}
                    favicon={false}
                    tag={true}
                    chatIcon={true}
                    jobdetail={(e) => detailPage(e, workfromhometype)}
                  />
                </IonCol>
              ))
            : "Loading ..."}

          {!showLoading && tableData.length === 0 && (
            <IonCol size="12">No rejected job found </IonCol>
          )}
          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e: any) => {
                  setCurrentPage(e);
                  getRejected(e);
                }}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

// job card

const UserCard = ({
  jobdetail,
  cardData,
  page,
  tag,
  chatIcon = false,
  workfromhometypeOption,
  favicon,
  moreIcon,
  moreUpdateresult,
  invideCheck,
  favclick,
  router,
}: any) => {
  const { auth } = React.useContext(AuthContext);
  const user = auth.data;
  const usertype = user ? user.user_type : "";
  const history = useHistory();
  // const user = getCookie({ key: "user" }).value;
  const [showAlert, setShowAlert] = React.useState(false);
  // const [data, setData] = React.useState<any>();
  const [btntype, setBtntype] = React.useState<any>();
  const [chatBox, setChatBox] = React.useState(false);
  const [chatBoxData, setChatBoxData] = React.useState({});
  return (
    <div>
      <ChatMessageBox
        showModal={chatBox}
        setShowModal={() => setChatBox(false)}
      >
        <ChatLayout
          closeAction={(e: any) => setChatBox(false)}
          data={chatBoxData}
        ></ChatLayout>
      </ChatMessageBox>
      {/* <IonModal isOpen={chatBox} className="chat-model">
        <ChatLayout
          closeAction={(e: any) => setChatBox(false)}
          data={chatBoxData}
        ></ChatLayout>
      </IonModal> */}
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={"Continue to " + btntype + " the contractor?"}
        subHeader={"Confirm here"}
        message={""}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert(false);
              // setData(null);
              setBtntype(null);
            },
          },
        ]}
      />
      <IonCard className="ion-padding">
        {tag && cardData.closed === 1 && (
          <div className="heighlite-tag">
            <small>closed</small>
          </div>
        )}

        <div className="review-chip">
          {/* <div className="rating">
            <img alt=''src="assets/image/company/star.svg" />
            <span>4.2/5 (10 reviews)</span>
          </div> */}
          {moreIcon && (
            <>
              <div
                className="rating cursor"
                onClick={(e) => {
                  !invideCheck
                    ? moreUpdateresult(cardData, 2)
                    : moreUpdateresult(cardData, 1);
                }}
              >
                <span>{!invideCheck ? "Ignore" : "Undo"}</span>
              </div>

              <div
                className="red-content cursor"
                onClick={(e) => moreUpdateresult(cardData, 0)}
              >
                <span>Remove</span>
              </div>
            </>
          )}
          {chatIcon && [Contractor].includes(usertype) && (
            <IonIcon
              icon={chatbubble}
              size="small"
              mode="ios"
              color="orange"
              slot="icon-only"
              onClick={(e) => {
                setChatBoxData({
                  ctrdata: user.ctr_id ?? '',
                  unique_id: cardData.unique_id ?? '',
                  folder_key: cardData.folder_key ?? '',
                  profile_photo: cardData.profile_photo ?? '',
                  type:"noencrypt",
                });
                setChatBox(true);
              }}
            />
          )}

          {favicon  && CRMRecuter !== usertype &&  (
            <div className="fav">
              <IonIcon
                icon={cardData.favorite_status ? heart : heartOutline}
                size="small"
                mode="ios"
                color={cardData.favorite_status ? "danger" : " "}
                slot="icon-only"
                onClick={(e) =>
                  favclick(cardData.favorite_status, cardData.unique_id)
                }
              />
            </div>
          )}
        </div>

        <IonItem lines="none">
          <IonAvatar slot="start">
            <CardProfileImagewithoutEncypt
              folder_key={cardData.folder_key}
              profile_photo={cardData.profile_photo}
              name={""}
            />
          </IonAvatar>
          <IonLabel
            className="cursor ion-text-wrap"
            onClick={(e) => {
              if (!tag && cardData.closed !== 1) {
                jobdetail(cardData);
              }
            }}
          >
            <span>{StringMap(cardData.job_title)}</span>
            <div>
              <p className="m-0">
                {StringMap(cardData.work_location)}
                {getIndexValue(
                  cardData.wfh_type,
                  workfromhometypeOption,
                  "value",
                  "label"
                ) &&
                  " (" +
                    getIndexValue(
                      cardData.wfh_type,
                      workfromhometypeOption,
                      "value",
                      "label"
                    ) +
                    ")"}
              </p>
              <small className="text-gray">
                {" "}
                JID: {StringMap(cardData.com_pjid) + " "}| Posted by{" "}
              </small>{" "}
              <small>
                {" "}
                {StringMap(cardData.posted_by ?? cardData.company_name)}{" "}
              </small>{" "}
              <small className="text-gray">
                on {moment(cardData.created_on).format("lll")}
              </small>
            </div>
          </IonLabel>
          {[TRM, Staff, CRMRole, CRMRecuter].includes(usertype) && (
            <IonBadge
              slot="end"
              color="light"
              className="cursor"
              onClick={() => {
                history.push("/candidatesmatch", {
                  ...cardData,
                  job_id: cardData.com_pjid,
                  posted_by: StringMap(
                    cardData.posted_by ?? cardData.company_name
                  ),
                });
              }}
            >
              <small className="text-orange">Candidates</small>
            </IonBadge>
          )}
          <IonBadge slot="end" color="light">
            <small className="text-gray">
              {moment(cardData.created_on).startOf("hour").fromNow()}
            </small>
          </IonBadge>
        </IonItem>
        <div className="com-custom-grid pt-0">
          <div className=" com-col-section remove-p">
            <div>
              <img alt="" src="assets/image/company/date_range.svg" />
              <small>Engagement</small>
            </div>
            <label>
              {cardData.duration_type === "0" ? "Contract" : "Permanent"}
            </label>
          </div>
          <div className="com-col-section">
            <div>
              <img alt="" src="assets/image/company/business_center.svg" />
              <small>Exp.</small>
            </div>
            <label>{EXPStringMap(cardData.overall_exp) + " y"}</label>
          </div>
          <div className="com-col-section">
            <div>
              <img alt="" src="assets/image/company/monetization_on.svg" />
              <small>Budget</small>
            </div>
            <label>
            {"₹ " + cardData.payment}
              {cardData.duration_type === "0" ? " per hour" : " per year"}
            </label>
          </div>
          <div className="com-col-section">
            <div>
              <img alt="" src="assets/image/company/event_available.svg" />
              <small>Start</small>
            </div>
            <label>{FullDateStringMap(cardData.start_date)}</label>
          </div>
          {cardData.duration_type === "0" && (
            <div
              className="com-col-section"
              style={{ borderRightStyle: "none" }}
            >
              <div>
                <img alt="" src="assets/image/company/event_busy.svg" />
                <small>End</small>
              </div>
              <label>{FullDateStringMap(cardData.end_date)}</label>
            </div>
          )}
        </div>
      </IonCard>
    </div>
  );
};

export default React.memo(ListJob);
