import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { closeOutline, addOutline } from "ionicons/icons";
import React from "react";
import { LocationSearch } from "../../../../component";
import { saveLocation } from "../../Service/api";
import { apiCallBack } from "../../../../service";

interface location {
  relocate: string;
  preferred_locations: locationData[];
}

interface locationData {
  geo_location: string;
  geo_distance: string;
  latitude: any;
  longitude: any;
  mi: string;
}

const locationData = {
  geo_location: "",
  geo_distance: "",
  latitude: "",
  longitude: "",
  mi: "mi",
};

export default function LocationForm({
  relocateValue,
  locationValue,
  closeModal,
  setReffer,
  ctridData,
  modalSet,
}: any) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "preferred_locations",
  });
  const [showLoading, setShowLoading] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [uploading, setUploading] = React.useState(false);
  const [latlng, setLatLng] = React.useState({ lat: "", lng: "" });

  function ArrayFieldKeymap(e: any, i: number, key: string) {
    const updatedItems = fields.map((item: any, index: any) => {
      if (index === i) {
        return {
          ...item,
          [key]: e ?? "",
        };
      } else {
        return item;
      }
    });
    setValue("preferred_locations", updatedItems);
  }

  function selectLotLong(e: any, i: number) {
    const updatedItems = fields.map((item: any, index: any) => {
      if (index === i) {
        return {
          ...item,
          geo_location: e.des ?? "",
          longitude: e.lng ?? "",
          latitude: e.lat ?? "",
        };
      } else {
        return item;
      }
    });

    setValue("preferred_locations", updatedItems);
  }

  // preferredLocations setup
  const createNewItem = () => ({
    geo_location: "",
    geo_distance: "",
    latitude: "",
    longitude: "",
    mi: "mi",
  });

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  // submit form
  const saveLocations = (values: any) => {
    setShowLoading(true);
    values.ctr_id = ctridData;
    saveLocation(values).then((e: any) => {
      if (apiCallBack(e)) {
        setReffer(values);
        setToastColor("success");
        setToastMessage(e.message);
        setShowToast(true);
        setShowLoading(false);
        closeModal(false);
        setUploading(false);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(e.message);
        setShowToast(true);
        setUploading(false);
      }
    });
  };

  React.useEffect(() => {
    setValue("relocate", relocateValue);
    setValue("preferred_locations", locationValue);
  }, [modalSet]);

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveLocations)}>
            <IonRow>
              <IonCol sizeLg="9" sizeXs="12">
                <h4>Relocate to another US city/state?</h4>
              </IonCol>
              <IonCol sizeLg="3" sizeXs="12">
                <IonItem lines="none">
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        onBlur={onBlur}
                        onChange={(e: any) => {
                          setValue("relocate", e.target.value);
                        }}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    )}
                    control={control}
                    name="relocate"
                  />
                </IonItem>
                {showError("relocate")}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <hr />
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <h4>Commute preferences</h4>
                  <IonButton
                    fill="outline"
                    className="remove-shadow custom-circle-btn"
                    color="secondary"
                    shape="round"
                    slot="end"
                    onClick={() => append(createNewItem())}
                  >
                    <IonIcon icon={addOutline} slot="icon-only" />
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>

            {fields.map((lo: any, index: any) => (
              <IonRow key={lo.id}>
                <IonCol sizeLg="7" sizeXs="12">
                  <div className="locationInput custom-lable">
                    <IonLabel position="stacked">Address</IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <LocationSearch
                          valueKey={lo.geo_location}
                          onChangeValue={onChange}
                          onSelectValue={(e: any) => {}}
                          latLong={(e: any) => {
                            selectLotLong(e, index);
                          }}
                        />
                      )}
                      name="geo_location"
                      control={control}
                    />
                  </div>
                  {/* {showError("geo_location")} */}
                </IonCol>
                <IonCol sizeLg="4" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">Proximity</IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <IonInput
                          value={lo.geo_distance}
                          onIonChange={(e: any) =>
                            ArrayFieldKeymap(
                              e.detail.value,
                              index,
                              "geo_distance"
                            )
                          }
                          autocomplete="off"
                          type="number"
                          placeholder=""
                        />
                      )}
                      name={"geo_distance"}
                      control={control}
                      // rules={{ required: "Proximity is required" }}
                    />
                    <div className="custom-groupInput">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            value={lo.mi ?? "mi"}
                            onChange={(e: any) =>
                              ArrayFieldKeymap(e.target.value, index, "mi")
                            }
                            placeholder="Select"
                          >
                            <option value="">Select</option>
                            <option value="mi">mi</option>
                          </select>
                        )}
                        control={control}
                        name={"mi"}
                      />
                    </div>
                  </IonItem>
                  {/* {showError("geo_distance")} */}
                </IonCol>
                <IonCol sizeLg="1" sizeXs="12">
                  <IonItem lines="none">
                    <IonButton
                      style={{ marginTop: "4em" }}
                      slot="end"
                      fill="outline"
                      className="remove-shadow custom-circle-btn"
                      color="medium"
                      shape="round"
                      onClick={() => remove(index)}
                    >
                      <IonIcon icon={closeOutline} slot="icon-only" />
                    </IonButton>
                  </IonItem>
                </IonCol>
              </IonRow>
            ))}

            <IonCol sizeLg="12" sizeXs="12">
              <div className="text-right">
                <IonButton
                  shape="round"
                  fill="solid"
                  color="secondary"
                  className="remove-shadow capitalizes"
                  type="submit"
                >
                  {uploading ? "Uploading" : "Save"}
                </IonButton>
              </div>
            </IonCol>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
}
