import { getMethod, postMethod, Requests } from "../../../service";

export function getPracticeList(dta: any) {
  return getMethod(Requests.fetchGetPractice + dta);
}

export function Savepractice(data: any) {
  return postMethod(Requests.fetchUpdatePractices, data);
}

export function getCompanyTableList(dta: any) {
  return getMethod(Requests.fetchJngstaffgetCompanies);
}

export function fGetCompanycontract(dta: any) {
  return getMethod(Requests.fetchGetCompanycontract + dta);
}

export function fContractuploaddeletecontract(dta: any) {
  return getMethod(Requests.fetchContractuploaddeletecontract + dta);
}

export function getGrouplist(dta: any) {
  return getMethod(Requests.fetchGetgroup);
}

export function getContractorlist(data: any) {
  return postMethod(Requests.fetchGetcontractorlist, data);
}

export function fGetalljnglegaluser(dta: any) {
  return getMethod(Requests.fetchGetalljnglegaluser);
}

export function fCreatecontractor(data: any) {
  return postMethod(Requests.fetchCreatecontractor, data);
}

export function faddcompany(data: any) {
  return postMethod(Requests.fetchaddcompany, data);
}

export function createCrm(data) {
  return postMethod(Requests.fetchAddJngCrmrole, data);
}
export function updateCrm(data) {
  return postMethod(Requests.fetchUpdateJngCrmrole, data);
}

export function createRecruiter(data) {
  return postMethod(Requests.fetchAddJngRecruiterrole, data);
}
export function updateRecruiter(data) {
  return postMethod(Requests.fetchUpdateJngRecruiterrole, data);
}

export function getRemoveRecruiter(dta) {
  return getMethod(Requests.fetchDeleteJngRecruiterrole + dta);
}

export function getRemoveCRM(dta) {
  return getMethod(Requests.fetchDeleteJngCrmrole + dta);
}

export function getallCRM(dta) {
  return getMethod(Requests.fetchGetallJngCrmuser + dta);
}

export function getallRecruiteruser(dta) {
  return getMethod(Requests.fetchGetallRecruiteruser);
}



export function fAddjnglegalrole(data: any) {
  return postMethod(Requests.fetchAddjnglegalrole, data);
}

export function fUpdatejnglegalrole(data: any) {
  return postMethod(Requests.fetchUpdatejnglegalrole, data);
}

export function getCompanyLoglist(dta) {
  return postMethod(Requests.fetchGetComLogData, dta);
}

export function getContractorLoglist(dta) {
  return postMethod(Requests.fetchGetCtrLogData, dta);
}

