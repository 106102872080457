import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import React, { useEffect, useState } from "react";
import { savelanguages, Deletelanguages } from "../../Service/api";
import { CommonSection } from "../Section/Qualifications";
import { arrayKeymatch, arrayOptionNameData } from "../../../../service";
import useMediaQuery from "../../../../hooks/mediaQuery";

interface interface_language_structured {
  arr: any;
  title: string;
}

function LanguagesForm(
  {
    ctrid,
    closeModal,
    modelClick,
    languageDisp,
    languageData,
    setLanguageDataInMainPage,
    proficiencytypelist,
  },
  any
) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [disableSelect, setDisableSelect] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    reset({ proficiency: "", lang_name: [] });
  }, [modelClick]);

  const editValue = (data: any) => {
    setDisableSelect(true);
    setValue("lang_name", [
      { label: data.language_name, value: data.level_id },
    ]);
    proficiencytypelist.map((e: any) => {
      if (e.value === data.proficiency_id) {
        setValue("proficiency", data.proficiency_id);
      }
    });
  };

  const saveLanguage = (values: any) => {
    setShowLoading(true);
    values.ctr_id = ctrid;
    values.lang_name = arrayOptionNameData(values.lang_name, "label");
    savelanguages(values).then((value) => {
      if (value !== undefined && value.status === 200) {
        reset({ proficiency: "", lang_name: [] });
        setLanguageDataInMainPage(arrayKeymatch(value.data));
        setShowLoading(false);
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again"
        );
        setShowToast(false);
      }
    });
  };

  const removeLanguages = (sk: any) => {
    setShowLoading(true);
    Deletelanguages(sk.cls_id).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        reset({ proficiency: "", lang_name: [] });
        setLanguageDataInMainPage(arrayKeymatch(value.data));
        setShowLoading(false);
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again"
        );
        setShowToast(false);
      }
    });
  };

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  return (
    <div className={m4kQuery ? "container px-lg-4 px-md-4": "px-lg-4 px-md-4"}>
      <div className="custom-header-model">
        <IonGrid>
          <form onSubmit={handleSubmit(saveLanguage)}>
            <IonRow>
              <IonCol sizeLg="8" sizeXs="12">
                <div>
                  <IonLabel position="stacked">
                    Language Name <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="lang_name"
                    rules={{
                      required: "Language name is required",
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Typeahead
                        allowNew
                        id="custom-selections-input"
                        multiple
                        newSelectionPrefix="Add a new language: "
                        options={languageData}
                        onChange={onChange}
                        selected={value}
                        placeholder="Enter languages you speak"
                      />
                    )}
                  />
                </div>
                {showError("lang_name")}
              </IonCol>

              <IonCol sizeLg="3" sizeXs="10">
                <div>
                  <IonLabel position="stacked">
                    Proficiency <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="proficiency"
                    rules={{
                      required: "Proficiency is required",
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <select
                        onChange={onChange}
                        value={value}
                        placeholder="Proficiency"
                      >
                        <option value="">Select</option>
                        {proficiencytypelist.map((option: any, i: number) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
                {showError("proficiency")}
              </IonCol>

              <IonCol
                sizeLg="1"
                sizeXs="2"
                className="ion-justify-content-center"
              >
                <IonItem lines="none" className="custom-ion-item">
                  <IonButton
                    fill="outline"
                    className="remove-shadow custom-circle-btn"
                    color="secondary"
                    shape="round"
                    type="submit"
                  >
                    <IonIcon icon={addOutline} slot="icon-only" />
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </div>
      <div>
        <IonCard className="ion-padding">
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="12" sizeXs="12">
                <CommonSection
                  arrs={languageDisp}
                  displayType={"edit"}
                  removeData={removeLanguages}
                  editValues={editValue}
                  type={"Tools"}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>
      </div>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
    </div>
  );
}

export default LanguagesForm;
