import {
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToast,
} from "@ionic/react";
import React from "react";

import { Controller, useForm } from "react-hook-form";
import {
  CandidateUpdatestatus,
  GetmailData,
  deleteCandidates,
  getJobCanditate,
  sentmailData,
} from "../../Service/api";
import { AuthContext } from "../../../../context/authContext";
import { ChatLayout } from "../../..";
import {
  ChatMessageBox,
  PaginationScreen,
  CanditateUserCard,
  ModalBody,
  Ceditor,
} from "../../../../component";
import { JobInterface, jobResData } from "../../interface/canditateInterface";
import { CardSketch } from "../../../../component/company/canditateJobCard";
import { CRMRecuter, CRMRole, NumberStringMap } from "../../../../service/default";

// Shortlisted screen
const Shortlisted = ({ uniqueid, jobdetailData }) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const { auth, setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showLoading, setShowLoading] = React.useState(true);
  const [modelDismiss, setModelDismiss] = React.useState<boolean>(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [chatBoxData, setChatBoxData] = React.useState<any>();
  const [chatBox, setChatBox] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("best");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalCount, setTotalCount] = React.useState(0);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [cardData, setCardData] = React.useState<jobResData[]>();
  const user = auth.data;
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // remove candidate 

  const handleDelete = (e, data) => {
    const result = {
      ctr_id: data.ctr_id,
      // com_id: uniqueid.com_id,
      unique_id: uniqueid.unique_id,
      // job_status_type: "delete",
    };
    setShowLoading(true);
    deleteCandidates(result).then((e) => {
      if (e !== undefined && e.status === 200) {
        removeMatchingData(data.public_id, "reject");
        setToastColor("success");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setToastColor("warning");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };

  // reject - 3 and hire -2 function
  const rejectClick = (e, data) => {
    const result = {
      ctr_id: data.ctr_id,
      com_id: uniqueid.com_id,
      unique_id: uniqueid.unique_id,
      job_status_type: "Rejected",
    };
    setShowLoading(true);
    CandidateUpdatestatus(result).then((e) => {
      if (e !== undefined && e.status === 200) {
        removeMatchingData(data.public_id, "reject");
        setToastColor("success");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setToastColor("warning");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };

  // invite function
  const inviteapi = (data) => {
    const result = {
      ctr_id: data.ctr_id,
      com_id: uniqueid.com_id,
      unique_id: uniqueid.unique_id,
      job_status_type: "Invited",
    };
    setShowLoading(true);
    CandidateUpdatestatus(result).then((e) => {
      if (e !== undefined && e.status === 200) {
        removeMatchingData(data.public_id, "invite");
        setToastColor("success");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setToastColor("warning");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };
  // hired
  const hiredapi = (data) => {
    const result = {
      ctr_id: data.ctr_id,
      com_id: uniqueid.com_id,
      unique_id: uniqueid.unique_id,
      job_status_type: "Hired",
    };
    setShowLoading(true);
    CandidateUpdatestatus(result).then((e) => {
      if (e !== undefined && e.status === 200) {
        removeMatchingData(data.public_id, "hire");
        setToastColor("success");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setToastColor("warning");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };

  const removeMatchingData = (publicId, type) => {
    if (publicId && cardData) {
      const filterResult = cardData?.filter((id) => id.public_id !== publicId);
      addCount({
        applied_count: auth.canditateJobCount.applied_count,
        hired_count:
          type === "hire"
            ? auth.canditateJobCount.hired_count + 1
            : auth.canditateJobCount.hired_count,
        invited_count:
          type === "invite"
            ? auth.canditateJobCount.invited_count + 1
            : auth.canditateJobCount.invited_count,
        matching_candidate_count:
          auth.canditateJobCount.matching_candidate_count,
        rejected_count:
          type === "reject"
            ? auth.canditateJobCount.rejected_count + 1
            : auth.canditateJobCount.rejected_count,
        shortlisted_count: filterResult.length,
      });
      setCardData(filterResult);
      setTotalCount(filterResult.length);
      setTotalPageItem(filterResult.length);
    }
  };

  // get matching api call
  const getShortlisted = (page) => {
    getJobCanditate(uniqueid.unique_id + "/Shortlisted/" + page + "/desc").then(
      (e: JobInterface) => {
        if (e !== undefined && e.status === 200) {
          addCount(e);
          setCardData(e.data);
          setTotalPageItem(e.data ? e.data.length : 0);
          setTotalCount(e.totalItemsCount);
          setShowLoading(false);
        } else {
          setTotalCount(e.totalItemsCount);
          setTotalPageItem(0);
          addCount(e);
          setCardData([]);
          setShowLoading(false);
        }
      }
    );
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_count),
      hired_count: NumberStringMap(e.hired_count),
      invited_count: NumberStringMap(e.invited_count),
      matching_candidate_count:
         NumberStringMap(e. matching_count) ?? auth.canditateJobCount["matching_candidate_count"],
      rejected_count: NumberStringMap(e.rejected_count),
      shortlisted_count: NumberStringMap(e.shortlisted_count),
    });
  };

  // life cycle
  React.useEffect(() => {
    setShowLoading(true);
    let isMounted = true;
    try {
      getShortlisted("1");
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err && err.message);
          setShowToast(true);
          setCardData([]);
        }
      }
    } finally {
      // isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const getMailData = (comID, unique_id) => {
    if (comID) {
      const result = {
        com_id: comID ?? jobdetailData.com_id,
        unique_id: unique_id ?? jobdetailData.unique_id
      };
      setShowLoading(true);
      GetmailData(result).then((e) => {
        if (e !== undefined && e.status === 200) {
          const result = e.data;
          setValue("to", result.company_email);
          setValue("subject", result.company_subject);
          setValue("description", result.email_body);
          // setToastColor("success");
          // setToastMessage(e !== undefined && e.message);
          // setShowToast(true);
          setShowLoading(false);
        } else {
          setShowLoading(false);
          setToastColor("warning");
          setToastMessage(e !== undefined && e.message);
          setShowToast(true);
        }
      });
    } else {
      setShowLoading(false);
      setToastColor("warning");
      setToastMessage("Enter the contractor public url");
      setShowToast(true);
    }
  };

  const sentMail = (val) => {
    if (val) {
      const result = {
        to_mail: val.to,
        subject: val.subject,
        mail_body: val.description
      };
      setShowLoading(true);
      sentmailData(result).then((e) => {
        if (e !== undefined && e.status === 200) {
          setModelDismiss(false)
          setToastColor("success");
          setToastMessage("Mail send successfully");
        
          setShowToast(true);
          setShowLoading(false);
        } else {
          setShowLoading(false);
          setToastColor("warning");
          setToastMessage(e !== undefined && e.message);
          setShowToast(true);
        }
      });
    } else {
      setShowLoading(false);
      setToastColor("warning");
      setToastMessage("Something went wrong, plese try later");
      setShowToast(true);
    }
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      {/* <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      /> */}
      <IonGrid>
        <IonRow className="ion-justify-content-between ion-align-items-center">
          <IonCol>
            <div className="header-content-flex ion-justify-content-between ion-align-items-center filter-option custom-sort-section">
              <div>
                Showing{" "}
                {totalCount
                  ? currentPage === 1
                    ? 1
                    : (currentPage - 1) * perPage + 1
                  : 0}
                {" - "}
                {currentPage === 1
                  ? totalPageItem
                  : (currentPage - 1) * perPage + totalPageItem > totalCount
                  ? totalCount
                  : (currentPage - 1) * perPage + totalPageItem}
                {" of " + NumberStringMap(totalCount)}
              </div>
              <div className="d-flex ion-align-items-center ">
                {(user.user_type === CRMRecuter || user.user_type === CRMRole) && totalCount !== 0 && <IonChip
                  style={{
                    marginRight: "20px",
                    fontSize: "smaller",
                    textTransform: "capitalize",
                  }}
                  color={"orange"}
                  onClick={() => {
                    getMailData(jobdetailData.com_id, jobdetailData.unique_id);
                    setModelDismiss(true);
                  }}
                >
                  <IonLabel color="secondary" className="custum-gradiend">
                    Send Mail
                  </IonLabel>
                </IonChip>}
                <div className="input-group sort-group ion-sort-input no-rightspace">
                  <IonLabel className="input-group-text">Sort:</IonLabel>
                  <IonSelect
                    interface="popover"
                    placeholder="Select"
                    className="sort-input"
                    value={sortBy}
                    onIonChange={(e) => {
                      setSortBy(e.detail.value);
                    }}
                  >
                    <IonSelectOption value="best">Best Match</IonSelectOption>
                    {/* <IonSelectOption value="desc">Latest</IonSelectOption> */}
                  </IonSelect>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="pt-3">
          {!showLoading
            ? cardData &&
              cardData.map((cand, i: number) => (
                <IonCol sizeLg="6" sizeXs="12" key={i}>
                  <CanditateUserCard
                    reviewflag={true}
                    uniqueData={uniqueid.unique_id}
                    jobdetail={jobdetailData}
                    cardData={cand}
                    inviteBtnClick={inviteapi}
                    rejectBtnClick={ user.user_type === CRMRecuter ? handleDelete : rejectClick}
                    hireBtnClick={hiredapi}
                    type="1"
                    invitebtn={true}
                    hirebtn={true}
                    rejectbtn={true}
                    shortbtn={false}
                    chatbtn={true}
                    chatOpen={(e) => {
                      setChatBoxData(e);
                      setChatBox(true);
                    }}
                  />
                </IonCol>
              ))
            : Array.from({ length: 10 }, (_, i) => (
                <IonCol key={i} sizeLg="6" sizeXs="12">
                  <CardSketch />
                </IonCol>
              ))}
          {!showLoading && cardData && cardData.length === 0 && (
            <IonCol size="12">No data found </IonCol>
          )}
          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e) => {
                  setCurrentPage(e);
                  getShortlisted(e);
                }}
              />
            )}

            <ChatMessageBox
              showModal={chatBox}
              setShowModal={() => setChatBox(false)}
            >
              <ChatLayout
                closeAction={(e) => setChatBox(false)}
                data={chatBoxData}
              ></ChatLayout>
            </ChatMessageBox>
          </IonCol>
        </IonRow>
      </IonGrid>

      <ModalBody
        title="Send mail"
        subTitle=""
        required={true}
        isOpen={modelDismiss}
        onClose={() => setModelDismiss(false)}
      >
        <IonCard>
          <form onSubmit={handleSubmit(sentMail)}>
            <IonGrid>
              <IonRow>
                <IonCol sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      To <span className="required">*</span>
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <IonInput
                          onIonChange={onChange}
                          value={value}
                          placeholder=""
                        />
                      )}
                      control={control}
                      name="to"
                      rules={{
                        required: "To mail id is required",
                      }}
                    />
                  </IonItem>
                  {showError("to")}
                </IonCol>
                <IonCol sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Subject <span className="required">*</span>
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <IonInput
                          onIonChange={onChange}
                          value={value}
                          placeholder=""
                        />
                      )}
                      control={control}
                      name="subject"
                      rules={{
                        required: "Subject is required",
                      }}
                    />
                  </IonItem>
                  {showError("subject")}
                </IonCol>

                <IonCol sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Description <span className="required">*</span>
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Ceditor setEditorData={onChange} editorData={value} />
                      )}
                      control={control}
                      name="description"
                      rules={{
                        required: "Description is required",
                      }}
                    />
                  </IonItem>
                  {showError("description")}
                </IonCol>

                <IonCol sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                  <IonButton
                    shape="round"
                    fill="solid"
                    // disabled={canditateMessage !== "" ? false : true}
                    color={"secondary"}
                    className="remove-shadow capitalizes"
                    type="submit"
                  >
                    Send Mail
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCard>
      </ModalBody>
    </>
  );
};

export default React.memo(Shortlisted);
