import { IonPage, IonContent } from "@ionic/react";
import React, { useRef, useEffect } from "react";
import { HeaderNav } from "./component";
import { useLocation } from "react-router-dom";
import "./theme/style.css";
import "./theme/styleguide.css";
import { SlidesExample } from "./carousel";

const LandingPage: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const messageRef = useRef<HTMLDivElement>(null); // Explicitly define the type
  const location = useLocation();
  useEffect(() => {
    console.log(location.hash);
    if (location.hash === "#contactus" && messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  });
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play().catch(error => {
          // Handle autoplay error if needed
          console.error('Autoplay was prevented:', error);
        });
      }
    };

    const intervalId = setInterval(playVideo, 5000); // Change the delay (in milliseconds) between autoplay as needed

    return () => clearInterval(intervalId);
  }, []);
  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        {/* <!-- banner --> */}
        {/* <div className='header1 '>
              <div className='container d-flex justify-content-between align-items-center'>
                <a href='/' className='logo '>
                  <img src='assets/img/hirify.png' className='d-block mx-auto mx-sm-0' alt='' />
                </a>
                <div className='header-right d-none d-md-block'>
                  <a href='/listjob'>Jobs</a>
                  <a href='/list-candidates'>Candidates</a>
                  <a href='#contactus'>Contact Us</a>
                </div>

                <div className='dropdown d-block d-md-none'>
                  <button onClick={toggleMenu} className='dropbtn navbar-toggle '>
                    <span className='navbar-toggle-icon'>&#9776;</span>
                  </button>
                  <div className={`dropdown-content ${isOpen ? "active" : ""}`}>
                    <a href='/listjob'>Jobs</a>
                    <a href='/list-candidates'>Candidates</a>
                    <a href='#contactus'>Contact Us</a>
                  </div>
                </div>
              </div>
            </div> */}
        <div className='banner'>
          <SlidesExample />
          {/* <div id='carouselExampleCaptions' className='carousel slide carousel-dark' data-bs-ride='carousel'>
           
            <div className='carousel-indicators'>
              <button
                type='button'
                data-bs-target='#carouselExampleCaptions'
                data-bs-slide-to='0'
                className='active'
                aria-current='true'
                aria-label='Slide 1'
              ></button>
              <button
                type='button'
                data-bs-target='#carouselExampleCaptions'
                data-bs-slide-to='1'
                aria-label='Slide 2'
              ></button>
              <button
                type='button'
                data-bs-target='#carouselExampleCaptions'
                data-bs-slide-to='2'
                aria-label='Slide 3'
              ></button>
              <button
                type='button'
                data-bs-target='#carouselExampleCaptions'
                data-bs-slide-to='3'
                aria-label='Slide 4'
              ></button>
              <button
                type='button'
                data-bs-target='#carouselExampleCaptions'
                data-bs-slide-to='4'
                aria-label='Slide 5'
              ></button>
            </div>
            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <img src='assets/img/1. Home page banner.jpg' className='d-block w-100' alt='' />
                <div className='carousel-caption  text-center my-lg-5'>
                  <h1>Stay Project Ready!</h1>
                  <p>India's unique Staffing Platform for Talent on Demand.</p>
                  <a type='button'  href='/login' className='btn  btn-lg'>
                    LOGIN
                  </a>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='assets/img/2. Home page banner.jpg' className='d-block w-100' alt='...' />
                <div className='carousel-caption  text-center my-lg-5'>
                  <h1>Stay Project Ready!</h1>
                  <p>India's unique Staffing Platform for Talent on Demand.</p>
                  <a type='button' target='_new' href='/login' className='btn  btn-lg'>
                    LOGIN
                  </a>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='assets/img/3. Home page banner.jpg' className='d-block w-100' alt='...' />
                <div className='carousel-caption   text-center my-lg-5'>
                  <h1>Stay Project Ready!</h1>
                  <p>India's unique Staffing Platform for Talent on Demand.</p>
                  <a type='button' target='_new' href='/login' className='btn  btn-lg'>
                    LOGIN
                  </a>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='assets/img/4. Home page banner.jpg' className='d-block w-100' alt='...' />
                <div className='carousel-caption   text-center my-lg-5'>
                  <h1>Stay Project Ready!</h1>
                  <p>India's unique Staffing Platform for Talent on Demand.</p>
                  <a type='button' target='_new' href='/login' className='btn  btn-lg'>
                    LOGIN
                  </a>
                </div>
              </div>
              <div className='carousel-item'>
                <img src='assets/img/5. Home page banner.jpg' className='d-block w-100' alt='...' />
                <div className='carousel-caption   text-center my-lg-5'>
                  <h1>Stay Project Ready!</h1>
                  <p>India's unique Staffing Platform for Talent on Demand.</p>
                  <a type='button' target='_new' href='/login' className='btn btn-lg'>
                    LOGIN
                  </a>
                </div>
              </div>
            </div>
           
          </div> */}
        </div>
        {/* <!-- banner --> */}

        <div className='container py-3 px-3 py-lg-5'>
          <div className='text-center header pb-3 pb-lg-5'>
            <h1 className='section-heading'>
              AI is transforming; <br /> so is Recruiting
            </h1>
            <div className='separator-wrapper'>
              <div className='separator'></div>
            </div>
          </div>
          <div className='mx-auto w-full text-center'>
          <video   className='video-section'             
              controlsList='nodownload' ref={videoRef} src="assets/img/Hirify_animation.mp4" controls autoPlay muted/>
           
           
          </div>
          {/* <img
            src='assets/img/7. AI is transforming; so is Recruiting.jpg'
            className='d-block w-100 w-lg-75 mx-auto'
            alt='...'
          /> */}
        </div>

        <div className=' '>
          <div className='bg-yellow px-3 align-items-center'>
            <div className='text-center header py-3 py-lg-5'>
              <h1 className='section-heading'>
                {" "}
                Get ready for an unique Recruitment
                <br />
                Marketplace
              </h1>
              <div className='separator-wrapper'>
                <div className='separator1'></div>
              </div>
            </div>
            <div className='container'>
              <div className='row g-3 g-lg-5'>
                <div className='col-lg-6'>
                  <img
                    className='d-block w-100 mx-auto img-section'
                    src='assets/img/8. Get ready to an unique Recruitment Marketplace.jpg'
                  />
                </div>
                <div className='col-lg-6'>
                  <p className=' px-3'>
                    <strong>HIRIFY is an AI-powered recruitment platform envisions a future where:</strong>
                  </p>
                  <ul>
                    <li>
                      <strong> Intelligent automation streamlines processes:</strong> AI handles repetitive
                      tasks, freeing human expertise for strategic decision-making and candidate engagement.
                      Our intelligent algorithms analyze vast data sets to match candidates with suitable
                      opportunities based on skills, experience, and cultural fit, reducing bias and ensuring
                      a fairer selection process.
                    </li>
                    <li>
                      <strong>Data-driven insights empower informed choices:</strong> AI analyzes vast data
                      sets to <strong>uncover hidden talent and match candidates</strong> with suitable
                      opportunities based on skills, experience, and cultural fit.
                    </li>
                    <li>
                      <strong>Bias is minimized:</strong> AI-powered algorithms{" "}
                      <strong>reduce unconscious bias</strong> in the selection process, promoting{" "}
                      <strong>fairness and equal opportunity</strong> for all candidates.
                    </li>
                    <li>
                      <strong>Personalized experiences are fostered:</strong> AI personalizes the recruitment
                      journey for both <strong>candidates and employers</strong>, tailoring communication and
                      providing relevant information.
                    </li>
                    <li>
                      <strong>Human connection remains paramount:</strong> AI acts as a{" "}
                      <strong>collaborative tool to augment human judgment</strong> and{" "}
                      <strong>facilitate meaningful interactions</strong> throughout the recruitment process.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='row g-3 g-lg-5 py-lg-5'>
                <div className='col-lg-6 order-lg-2'>
                  <img className='d-block w-100 mx-auto img-section' src='assets/img/9. Our Mission.jpg' />
                </div>

                <div className='col-lg-6 order-lg-1'>
                  <div className='text-center header '>
                    <h1 className='section-heading'> Our Mission</h1>
                    <p className='pt-lg-3'>
                      {" "}
                      <strong>
                        At HIRIFY, we believe everyone deserves a fulfilling career and businesses deserve the
                        right talent to thrive. We are committed to:
                      </strong>
                    </p>
                  </div>
                  <ul>
                    <li>
                      <strong>Empowering job seekers</strong> to find meaningful employment opportunities.
                    </li>
                    <li>
                      <strong> Equipping employers </strong>with the tools to build high-performing teams.
                    </li>
                    <li>
                      <strong>Creating a sustainable recruitment ecosystem</strong> that benefits all
                      stakeholders.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='row g-3 g-lg-5 pb-lg-5'>
                <div className='col-lg-6'>
                  <img className='d-block w-100 mx-auto img-section' src='assets/img/10. Our Vision.jpg' />
                </div>
                <div className='col-lg-6'>
                  <div className='text-center header '>
                    <h1 className='section-heading'> Our Vision</h1>
                    <p className='pt-lg-3'>
                      Hirify's{" "}
                      <strong>
                        vision is to empower fulfilling careers and fueling business Growth through seamless
                        Talent connections.
                      </strong>
                    </p>
                  </div>
                  <ul>
                    <li>
                      Faster hiring cycles on staffing mode and reduced costs for businesses with transparent
                      markup rates.
                    </li>

                    <li>
                      Empowered job seekers with personalized career exploration and matching using AI
                      technology and support continuity on projects, with less lead time to search,
                      proactively.
                    </li>

                    <li>
                      {" "}
                      A more diverse and inclusive talent pool for organizations to pick from with most
                      relevant market intelligence data made available on the platform, on demand.
                    </li>

                    <li>
                      A human-centric experience that prioritizes transparency and trust, building the most
                      sought after 'Candidate Experience' platform.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        <div className=''>
          <div className='bg-dark px-3 align-items-center py-3'>
            <div className='text-center header py-3 py-lg-5'>
              <h1 className='section-heading text-yellow'> Experience the Joy of Hirify-cation!</h1>
              <div className='separator-wrapper'>
                <div className='separator1'></div>
              </div>
            </div>
            <div className='container pb-3 pb-lg-5'>
              <div className='row text-white text-center'>
                <div className='col-lg-4'>
                  <img className='d-block w-100 mx-auto ' src='assets/img/11. Candidate Experience.jpg' />
                  <h3 className='fw-bold py-3 text-yellow'>Candidate Experience</h3>
                  <p>
                    <strong>
                      {" "}
                      Hirify intents to provide a platform to improve the candidate's experience
                    </strong>
                    by making it simpler as a process to navigate recruitment process, track their interview
                    outcomes, and receive feedback, promptly by default.
                  </p>
                </div>

                <div className='col-lg-4'>
                  <img className='d-block w-100 mx-auto' src='assets/img/12. Mobile First Approach.jpg' />
                  <h3 className='fw-bold  py-3 text-yellow'>Mobile First Approach</h3>
                  <p>
                    <strong>Hirify enables entire Staffing process on a mobile app,</strong> wherein
                    recruiters and candidates get access to the recruitment platform from anywhere, real-time
                    basis, thereby providing a seamless experience to all the stakeholders.
                  </p>
                </div>
                <div className='col-lg-4'>
                  <img className='d-block w-100 mx-auto' src='assets/img/13. On demand Scheduling.jpg' />
                  <h3 className='fw-bold  py-3 text-yellow'>On demand Scheduling</h3>
                  <p>
                    <strong>
                      Hirify enables a simplified scheduling process for interviews and other assessments,
                    </strong>{" "}
                    making it moreaccessible to candidates to pick a time of their choice for the interview
                    and make themselves available for the time committed by them!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        <div className=' '>
          <div className='bg-yellow align-items-center px-3 py-3 py-lg-4'>
            <div className='text-center header py-3 py-lg-5'>
              <h1 className='section-heading'> Innovative Value Propositions</h1>
              <div className='separator-wrapper'>
                <div className='separator1'></div>
              </div>
            </div>
            <div className='container'>
              <div className='row g-3 g-lg-5 pb-lg-5'>
                <div className='col-lg-6'>
                  <img
                    className='d-block w-100 mx-auto img-section'
                    src='assets/img/14. AI led Assessment - Copy.jpg'
                  />
                </div>
                <div className='col-lg-6'>
                  <div className='text-center header '>
                    <h1 className='section-heading'>AI led Assessment</h1>
                    <p className='pt-lg-3'>
                      In a competitive job market,{" "}
                      <strong>
                        attracting and delighting top talent requires a comprehensive understanding of their
                        skills and potential.
                      </strong>
                      Traditional assessment methods can be time-consuming and subjective, making it
                      challenging to get a complete picture of each candidate.
                    </p>
                  </div>
                  <ul>
                    <li>
                      <strong>HIRIFY'S AI-powered platform empowers :</strong>
                    </li>
                    <li>
                      <strong>Streamline the assessment process:</strong> Save time and resources by using
                      automated skills tests and assessments.
                    </li>
                    <li>
                      <strong>Gain deeper insights:</strong> Leverage AI to analyze data objectively and
                      identify candidates with the right skills and cultural fit.
                    </li>
                    <li>
                      <strong>Deliver a positive candidate experience:</strong> Provide a smooth and engaging
                      assessment journey, showcasing your commitment to a fair and efficient hiring process.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='row g-3 g-lg-5 pb-lg-5'>
                <div className='col-lg-6 order-lg-2'>
                  <img
                    className='d-block w-100 mx-auto img-section'
                    src='assets/img/15. Connected People.jpg'
                  />
                </div>
                <div className='col-lg-6 order-lg-1'>
                  <div className='text-center header '>
                    <h1 className='section-heading'> Connected People</h1>
                    <p className='pt-lg-3'>
                      {" "}
                      <strong>
                        HIRIFY empowers you to tap into the power of your social network and unlock hidden
                        career opportunities.
                      </strong>
                    </p>
                  </div>
                  <ul>
                    <li>
                      <strong> Connect with industry professionals:</strong> Expand your network and gain
                      access to valuable career insights and recommendations.
                    </li>
                    <li>
                      <strong>Showcase your skills and experience:</strong> Share your profile with relevant
                      connections, increasing your visibility to potential employers.
                    </li>
                    <li>
                      <strong> Get referred for exciting opportunities:</strong> Leverage the strength of your
                      network to land your dream job.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='row g-3 g-lg-5 pb-lg-5'>
                <div className='col-lg-6'>
                  <img
                    className='d-block img-section w-100 mx-auto'
                    src='assets/img/16. Leveraging Connections.jpg'
                  />
                </div>
                <div className='col-lg-6'>
                  <div className='text-center header '>
                    <h1 className='section-heading'>Leveraging Connections</h1>
                    <p className='pt-lg-3'>
                      <strong>Boost your career prospects with powerful referrals on HIRIFY!</strong>
                    </p>
                    <p>
                      Hirify's innovative <strong>"myBuddy"</strong> feature lets you tap into your valuable
                      network,
                      <strong>turning your connections into powerful advocates for your career.</strong>
                    </p>
                  </div>
                  <ul>
                    <li>
                      <strong>Increase your visibility:</strong> Share your profile with trusted contacts,
                      expanding your reach to potential employers within HIRIFY.
                    </li>

                    <li>
                      <strong>Gain trusted recommendations:</strong> Secure valuable referrals that showcase
                      your skills and experience from a credible source.
                    </li>

                    <li>
                      <strong>Land your dream job faster:</strong> Referrals often lead to quicker hiring
                      decisions and a smoother interview process.
                    </li>
                  </ul>
                </div>
              </div>
              <div className='row g-3 g-lg-5  pb-lg-5'>
                <div className='col-lg-6 order-lg-2'>
                  <img
                    className='d-block w-100 mx-auto img-section'
                    src='assets/img/17. Enabling right Selections.jpg'
                  />
                </div>
                <div className='col-lg-6 order-lg-1'>
                  <div className='text-center header pt-lg-5'>
                    <h1 className='section-heading'> Enabling right Selections</h1>
                    <p className='pt-lg-3'>
                      {" "}
                      Recruiters need to balance the needs of both employers and candidates. They need to find
                      candidates who meet the employer's requirements, but they also need to find jobs that
                      are a good fit for the candidate's skills and experience. This can be difficult,
                      especially if the employer has unrealistic expectations or the candidate is picky about
                      the type of job they want.{" "}
                      <strong>
                        HIRIFY with it's AI enabled job search matches enables competency rating based
                        matching roles, thereby supporting right selections and predictive early joiners.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className='row g-3 g-lg-5  pb-lg-5'>
                <div className='col-lg-6'>
                  <img className='d-block w-100 mx-auto img-section' src='assets/img/18. BGV ready!.jpg' />
                </div>
                <div className='col-lg-6'>
                  <div className='text-center header '>
                    <h1 className='section-heading'>BGV ready!</h1>
                    <p className=''>
                      <strong>Showcase Your Skills Securely on HIRIFY:</strong>
                    </p>
                    <p>
                      <strong>
                        HIRIFY prioritizes your data privacy and security. We use the latest encryption
                        technology to safeguard your documents, ensuring they remain accessible only to
                        authorized personnel, only when you have a job offer rolled out via the platform.{" "}
                      </strong>
                    </p>
                  </div>
                  <ul>
                    <li>
                      <strong>Boost your profile's credibility:</strong> Upload your relevant documents, have
                      them background check verified (BGV) and make them available to employers to verify at
                      just before offer stage or post offer process.
                    </li>
                    <li>
                      <strong>Simplify the application process:</strong> Eliminate the need to resubmit
                      documents multiple times for different positions, saving you time and effort.
                    </li>
                    <li>
                      <strong>Enjoy a seamless experience:</strong> We handle your data responsibly, allowing
                      you to focus on your job search with confidence.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        <div className=' '>
          <div className='bg-white px-3 align-items-center'>
            <div className='text-center header  py-3 py-lg-5'>
              <h1 className='section-heading gold'> Our Core competencies</h1>
              <div className='separator-wrapper'>
                <div className='separator1'></div>
              </div>
            </div>
            <div className='container'>
              <div className='row g-3 g-lg-5  pb-lg-5'>
                <div className='col-lg-6'>
                  <img
                    className='d-block w-100 mx-auto img-section'
                    src='assets/img/19. Visionary Leadership.jpg'
                  />
                </div>
                <div className='col-lg-6'>
                  <div className='text-center header '>
                    <h1 className='section-heading'>Visionary Leadership</h1>
                    <p className=''>
                      Our leadership team boasts over 100 years of collective experience in talent
                      acquisition, Technology Engineering Services and HRTech Solutions.
                    </p>
                    <p className=''>
                      They've held prominent roles in diverse organizations, both large IT and non-IT,
                      navigating the recruitment landscape through its highs and lows.
                    </p>
                    <p>
                      These seasoned professionals have tackled complex challenges and made crucial decisions
                      throughout their careers, adapting to the ever-evolving dynamics of recruitment in both
                      corporate and consulting environments. Their expertise has been instrumental in building
                      successful organizations over the decades.
                    </p>
                    <p className=''>
                      <strong>HIRIFY</strong> is the culmination of their collective knowledge as
                      Practitioners, designed to address the concerns of all stakeholders in the recruitment
                      game.
                    </p>
                  </div>
                </div>
              </div>

              <div className='row g-3 g-lg-5  pb-lg-5'>
                <div className='col-lg-6 order-lg-2'>
                  <img className='d-block w-100 mx-auto img-section' src='assets/img/20. Our Ideation.jpg' />
                </div>
                <div className='col-lg-6 order-lg-1'>
                  <div className='text-center header '>
                    <h1 className='section-heading'>Our Ideation</h1>
                    <p className=''>
                      {" "}
                      <strong>Revolutionise hiring with Hirify</strong>
                    </p>
                    <p className=''>
                      <strong>HIRIFY</strong> isn't any typical tech crew. We are a diverse group of
                      individuals who have traversed the entire career spectrum, from aspiring candidates to
                      seasoned managers and leaders. This unique journey allows us to deeply understand the
                      evolving needs and aspirations of talent across generations,{" "}
                      <strong>from Gen-X to Gen-Z and Millennials.</strong>
                    </p>
                    <p className=''>
                      Leveraging our combined experience and passion for innovation, we are committed to
                      transforming the hiring landscape through the power of AI technology. We recognize the
                      challenges of attracting and engaging diverse talent in today's competitive market, and
                      we believe AI holds the key to creating a seamless and efficient recruitment process for
                      all.
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className='row g-3 g-lg-5  pb-lg-5'>
                <div className='col-lg-6'>
                  <img className='d-block w-100 mx-auto ' src='assets/img/logo.png' />
                </div>
                <div className='col-lg-6'>
                  <div className='text-center header '>
                    <h1 className='section-heading'>
                      Global Expertise, <br />
                      Tailored Solutions
                    </h1>
                    <p className='pt-lg-3'>
                      Headquartered in the United States with offices across India, Europe, Middle East, and
                      Singapore, <strong>IONIDEA is HIRIFY'S Technology Partner,</strong> a global IT
                      solutions provider serving diverse industries like education, government, telecom, and
                      finance with <strong>over 1500 employees across the globe,</strong> coming together with
                      <strong>over 200 passionate staffing professionals in India,</strong> dedicated to
                      empowering businesses across customers., operating on the platform.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/*  */}

        <div className=' '>
          <div className='bg-white px-3 align-items-center pb-lg-5'>
            <div className='text-center header py-3 pb-lg-5'>
              <h1 className='section-heading gold'> Leadership team, beyond collaboration</h1>
              <div className='separator-wrapper'>
                <div className='separator1'></div>
              </div>
            </div>
            <div className='container'>
              <div className='row g-3 g-lg-5 text-center justify-content-center  align-items-center '>
                <div className='col-lg-3'>
                  <img className='d-block w-100 mx-auto' src='assets/img/chethan-raj.png' />
                  <h4 className='py-3'>Chethan Raj</h4>
                  <p className='text-secondary'>Founder Partner</p>
                  <a
                    type='button'
                    target='_new'
                    href='https://www.linkedin.com/in/chethanraj/'
                    className='btn py-3 profile-button mx-auto '
                  >
                    Profile
                  </a>
                </div>
                <div className='col-lg-3'>
                  <img className='d-block w-100 mx-auto' src='assets/img/Kishan-Ananthram.png' />
                  <h4 className='py-3'>Kishan Ananthram</h4>
                  <p className='text-secondary'>Strategic Investor</p>
                  <a
                    type='button'
                    target='_new'
                    href='https://www.linkedin.com/in/kishanananthram/'
                    className='btn py-3 profile-button mx-auto '
                  >
                    Profile
                  </a>
                </div>
                <div className='col-lg-3'>
                  <img className='d-block w-100 mx-auto' src='assets/img/Brindala_Mallappa.png' />
                  <h4 className='py-3'>Brindala Mallappa</h4>
                  <p className='text-secondary'>Executive Sponsor</p>
                  <a
                    type='button'
                    target='_new'
                    href='https://www.linkedin.com/in/brindala/'
                    className='btn py-3 profile-button mx-auto '
                  >
                    Profile
                  </a>
                </div>
                <div className='col-lg-3'>
                  <img className='d-block w-100 mx-auto' src='assets/img/Sanjay_Shelvankar.png' />
                  <h4 className='py-3'>Sanjay Shelvankar</h4>
                  <p className='text-secondary'>Chief Strategist</p>
                  <a
                    type='button'
                    target='_new'
                    href='https://www.linkedin.com/in/sshelvankar/'
                    className='btn py-3 profile-button mx-auto '
                  >
                    Profile
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        <div className='row g-0 bg-yellow align-items-start justify-content-start' ref={messageRef}>
          <div className='col-lg-5 px-3 px-lg-5'>
            <div className='header px-lg-4  py-5'>
              <h1 className='section-heading text-start'> Contact Us</h1>
              <div className='pt-2'>
                <div className='separator'></div>
              </div>

              <h1 className='section-heading-1 text-start pt-3 pt-lg-4'>
                Better yet, speak to
                <br /> us in person!
              </h1>
              <p>We love interacting with candidates; so feel free to call us anytime.</p>

              <a
                type='button'
                target='_new'
                href='https://wa.me/919743411444'
                className='btn py-3  whatsapp-button mx-auto'
              >
                <img src='assets/img/WhatsApp.jpg' />
                &nbsp;&nbsp;Call on WhatsApp{" "}
              </a>
              <h1 className='section-heading-1 text-start pt-4'> Hirify.ai</h1>
              <p>
                Bengaluru, Karnataka, India. <br />
                +91 97430-11444 <br />{" "}
                <a href='mailto:info@hirify.ai' className='text-dark'>
                  info@hirify.ai
                </a>{" "}
              </p>

              <h1 className='section-heading-1 text-start pt-2'> Working Hours</h1>
              <h3>Round the clock!</h3>
            </div>
          </div>
          <div className='col-lg-7'>
            <div id='map-container'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.56659489736!2d77.46612698176193!3d12.95428023493942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1710762955088!5m2!1sen!2sin'
                loading='lazy'
              ></iframe>
            </div>
          </div>
        </div>
        <footer className='bg-dark  pt-2 text-white'>
          <div className='container-fluid text-center  py-2'>
            <p className='text-white pb-0 mb-0'>Copyright © 2024 Hirify.ai - All Rights Reserved.</p>
          </div>
        </footer>
      </IonContent>
    </IonPage>
  );
};

export default LandingPage;
