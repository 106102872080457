import React from "react";
import Moment from "react-moment";
import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import { addDefaultSrc, getCookie, SeverfilePaths } from "../../service";
import { AuthContext } from "../../context/authContext";

interface Props {
  chatmessage?: any;
  key?: any;
  name?: any;
  folderKey?: string | undefined;
  profile?: string | undefined;
}

const ChatList: React.FC<Props> = ({ chatmessage, folderKey, profile }) => {
  const { auth } = React.useContext(AuthContext);
  const currentUser = auth.data;

  // const currentUser = getCookie({ key: "user" }).value;
  const { message, datetime, send_by } = chatmessage;
  const calendarStrings = {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    lastWeek: "[last] dddd [at] LT",
    nextWeek: "dddd [at] LT",
    sameElse: "L",
  };
  const messageClass = send_by === currentUser.user_type ? "sent" : "received";

  return (
    <>
      <div className={` message ${messageClass}`}>
        {/* <IonIcon color="primary" icon={checkmarkDoneOutline} /> */}
        <img
          alt={currentUser.user_type}
          onError={addDefaultSrc}
          src={
            send_by === currentUser.user_type &&
            currentUser.profile_photo !== ""
              ? SeverfilePaths(currentUser.folder_key) +
                currentUser.profile_photo
              : folderKey
              ? folderKey + profile
              : "assets/icon/ic-account-circle.svg"
          }
        />{" "}
        <IonIcon color="dark" icon={checkmarkOutline} className="" />
        <span className="chat-message-time">
          <Moment calendar={calendarStrings}>{datetime}</Moment>
        </span>
        <div className="py-2">
          <p>{message}</p>
        </div>
      </div>
    </>
  );
};

export default React.memo(ChatList);
