import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import React, { useEffect, useRef, useState } from "react";
import { saveskills, deleteskills } from "../../Service/api";
import { CommonSectionSkill } from "../Section/Qualifications";
import {
  arrayKeymatch,
  arrayOptionNameData,
  getIndexValue,
  RemoveDuplicates,
} from "../../../../service";
import useMediaQuery from "../../../../hooks/mediaQuery";

function SkillsForm(
  {
    ctrid,
    closeModal,
    modelClick,
    skillDatas,
    sendUpdatedDataToMainPage,
    deleteStateValue,
    proficiencytypelist,
    ListofskillsOption,
  },
  any
) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [showToast, setShowToast] = useState(false);
  const [disableSelect, setDisableSelect] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);

  const [showAlert1, setShowAlert1] = React.useState(false);

  useEffect(() => {
    reset({ proficiency: "", skill_name: [] });
  }, [modelClick]);

  // edit skill
  const editValue = (data: any) => {
    setDisableSelect(true);
    setValue("skill_name", [{ label: data.skill_name, value: data.skill_id }]);
    proficiencytypelist.map((e: any) => {
      if (e.value === data.proficiency_id) {
        setValue("proficiency", data.proficiency_id);
      }
    });
  };

  // save skill
  const saveSkills = (values: any) => {
    setShowLoading(true);
    values.ctr_id = ctrid;
    values.skill_name = arrayOptionNameData(values.skill_name, "label");
    saveskills(values).then((value) => {
      if (value !== undefined && value.status === 200) {
        reset({ proficiency: [], skill_name: [] });
        sendUpdatedDataToMainPage(arrayKeymatch(value.data.skills));
        setToastColor("success");
        setToastMessage(value.message);
        setShowLoading(false);
        setShowToast(true);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again"
        );
        setShowToast(true);
      }
    });
  };

  // deltete the skill
  const removeSkill = (sk: any, datype) => {
    setShowLoading(true);
    deleteskills(sk.ctrs_id).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        deleteStateValue(arrayKeymatch(e.data.skills));
        setToastColor("success");
        setToastMessage(e.message);
        setShowLoading(false);
        setShowToast(true);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(e !== undefined ? e.message : "please try again");
        setShowToast(true);
      }
    });
  };

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  // const showValueCount = (_fieldName: any) => {
  //   const data = watch(_fieldName);
  //   return data ? data.length : "0";
  // };

  return (
    <div className={m4kQuery ? "container px-lg-4 px-md-4": "px-lg-4 px-md-4"}>
      <div className="custom-header-model">
        <IonGrid>
          <form onSubmit={handleSubmit(saveSkills)}>
            <IonRow>
              <IonCol sizeLg="8" sizeXs="12">
                <div>
                  <IonLabel position="stacked">
                    Skill Name <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="skill_name"
                    rules={{
                      required: "Skill Name is required",
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <Typeahead
                        allowNew
                        id="custom-selections-input"
                        multiple
                        newSelectionPrefix="Add a new skill: "
                        options={ListofskillsOption}
                        onChange={onChange}
                        selected={value}
                        placeholder="Skill Name"
                        selectHintOnEnter={true}
                        onKeyDown={(event) => {
                          if (event.key === "Enter" || event.key === "Tab") {
                            var backup: any[] = [];
                            backup = watch("skill_name");
                            setValue("skill_name", []);
                            if (event.target.value) {
                              watch("skill_name").map((e) => {
                                if (e.label !== event.target.value) {
                                  backup.push({
                                    label: event.target.value,
                                    value: getIndexValue(
                                      event.target.value,
                                      ListofskillsOption,
                                      "label",
                                      "value"
                                    ),
                                  });
                                }
                              });
                              if (watch("skill_name").length === 0) {
                                backup.push({
                                  label: event.target.value,
                                  value: getIndexValue(
                                    event.target.value,
                                    ListofskillsOption,
                                    "label",
                                    "value"
                                  ),
                                });
                              }
                            }

                            setValue(
                              "skill_name",
                              RemoveDuplicates(backup, "label")
                            );
                          }
                        }}
                      />
                    )}
                  />
                </div>
                {showError("skill_name")}
              </IonCol>

              <IonCol sizeLg="3" sizeXs="10">
                <div>
                  <IonLabel position="stacked">
                    Proficiency <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="proficiency"
                    rules={{
                      required: "Proficiency is required",
                    }}
                    render={({ field: { onChange, value, ref } }) => (
                      <select
                        onChange={onChange}
                        value={value}
                        placeholder="Proficiency"
                      >
                        <option value="">Select</option>
                        {proficiencytypelist.map((option: any, i: number) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                </div>
                {showError("proficiency")}
              </IonCol>

              <IonCol
                sizeLg="1"
                sizeXs="2"
                className="ion-justify-content-center"
              >
                <IonItem lines="none" className="custom-ion-item">
                  <IonButton
                    fill="outline"
                    className="remove-shadow custom-circle-btn"
                    color="secondary"
                    shape="round"
                    type="submit"
                  >
                    <IonIcon icon={addOutline} slot="icon-only" />
                  </IonButton>
                </IonItem>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </div>

      <div>
        <IonCard className="ion-padding">
          <IonGrid>
            {skillDatas !== undefined &&
              skillDatas.map((e: any, i: number) => (
                <IonRow key={i}>
                  <IonCol sizeLg="3" sizeXs="12">
                    <label className="capitalize">{e.title}</label>
                  </IonCol>
                  <IonCol sizeLg="9" sizeXs="12">
                    <CommonSectionSkill
                      removeData={removeSkill}
                      editValues={editValue}
                      arrs={arrayKeymatch(e.arr)}
                      type={"skills"}
                      catagory={e.title}
                    />
                    {i !== e.length - 1 && <hr />}
                  </IonCol>
                </IonRow>
              ))}
          </IonGrid>

          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={5000}
            color={toastColor}
          />
          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
          />
          <IonAlert
            isOpen={showAlert1}
            mode={"ios"}
            header={"Are you sure?"}
            subHeader={"Continue to delete the skill."}
            message={"Confirm here"}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "secondary",
                handler: (blah) => {
                  setShowAlert1(false);
                },
              },
              {
                text: "Okay",
                handler: () => {
                  setShowAlert1(false);
                },
              },
            ]}
          />
        </IonCard>
      </div>
    </div>
  );
}

export default SkillsForm;
