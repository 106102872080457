import { addDefaultSrc, SeverfilePaths, StringMap } from "../../service";
import { SeverfilePathswithoutEncrypt } from '../../service/axios';

type imageKey = {
  folder_key: string;
  profile_photo: string;
  name: string;
};

export const CardProfileImagewithoutEncypt = ({
  folder_key,
  profile_photo,
  name,
}: imageKey) => {
  try {
    return StringMap(folder_key) !== "NA" &&
      StringMap(profile_photo) !== "NA" ? (
      <img
        src={SeverfilePathswithoutEncrypt(folder_key) + profile_photo}
        loading="lazy"
        onError={addDefaultSrc}
        alt=""
      />
    ) : (
      <img
        src={profile_photo ?? "assets/image/user.svg"}
        loading="lazy"
        onError={addDefaultSrc}
        alt=""
      />
    );
  } catch (err) {
    return (
      <img
        src="assets/image/user.svg"
        loading="lazy"
        onError={addDefaultSrc}
        alt=""
      />
    );
  }
};

export const CardProfileImage = ({
  folder_key,
  profile_photo,
  name,
}: imageKey) => {
  try {
    return StringMap(folder_key) !== "NA" &&
      StringMap(profile_photo) !== "NA" ? (
      <img
        src={SeverfilePaths(folder_key) + profile_photo}
        loading="lazy"
        onError={addDefaultSrc}
        alt=""
      />
    ) : (
      <img
        src={profile_photo ?? "assets/image/user.svg"}
        loading="lazy"
        onError={addDefaultSrc}
        alt=""
      />
    );
  } catch (err) {
    return (
      <img
        src="assets/image/user.svg"
        loading="lazy"
        onError={addDefaultSrc}
        alt=""
      />
    );
  }
};
