import { saveAs } from "file-saver";
import { exportAgencyleadReport, exportContractor } from "../../service/allApi";
// function HandleCsvExport() {
// const csv = convertContactsToCSV(contacts);
//   const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
//   FileSaver.saveAs(csvData, 'data.csv');
// }

export function saveAsCSV(data: any) {
  //   let sampleJson: any = [
  //     { name: "ganesh", age: "24" },
  //     { name: "ramesh", age: "24" },
  //     { name: "suresh", age: "24" },
  //   ];
  const saveData = [];
  let a = document.createElement("a");
  a.setAttribute("style", "display:none;");
  document.body.appendChild(a);
  let csvData = ConvertToCSV(data);
  let blob = new Blob([csvData], { type: "text/csv" });
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = "sample.csv";
  a.click();
}

function ConvertToCSV(objArray: any) {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  let row = "";
  for (let index in objArray[0]) {
    //Now convert each value to string and comma-separated
    row += index + ",";
  }
  row = row.slice(0, -1);
  //append Label row with line break
  str += row + "\r\n";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line != "") line += ",";
      line += array[i][index];
    }
    str += line + "\r\n";
  }
  return str;
}

export function BrowserHandleExport(apiName: any, filename: any) {
  exportContractor(apiName).then((blob: any) => {
    saveAs(blob, filename);
  });
}

export function BrowserHandlepostExport(
  apiName: any,
  filename: any,
  data: any
) {
  exportAgencyleadReport(apiName, data).then((blob: any) => {
    // console.log(blob, "-----download---");
    saveAs(blob, filename);
  });
}
