import { IonToast } from "@ionic/react";

import moment from "moment";

// user Types

export const Contractor = "Contractor";
export const Company = "Company";
export const Staff = "Jngstaff";
export const TRM = "Talent Relationship Manager";
export const Legal = "Jnglegal";
export const Jngreporter = "Jngreporter";
export const CRMRole = "Customer Relationship Manager";
export const CRMRecuter = "Recruiter";

// helper function for display user image
export const addDefaultSrc = (ev: any) => {
  ev.target.src = "assets/image/user.svg";
};

export const sociallink = (ev: any) => {
  ev.target.src = "assets/image/linkicon/language.svg";
};

export const addDefaultjobSrc = (ev) => {
  ev.target.src = "assets/icon/hirify-round.png";
};

// export const addDefaultSrc = (ev) => {
//   ev.target.src = "assets/icon/ic-account-circle.svg";
// };
export const addDefaultcoverSrc = (ev) => {
  ev.target.src = "assets/icon/ic-add-photo.svg";
};

// url space

export const Urlspace = (value: any) => {
  return value.replace(/\s+/g, " ");
};
export const stringReplace = (value: any) => {
  const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
  return value.replace(regex, " ");
};

export const splitKey = (value: any, key: any) => {
  return value.split(key);
};

// helper function for converting html to string text
export function getText(html: any) {
  var divContainer = document.createElement("p");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

export const getIndex2 = (value, arr, prop) => {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i] === value) {
      return value;
    }
  }

  return; //to handle the case where the value doesn't exist
};

// helper function for null - undefined - ""
export function StringMap(params: any) {
  switch (params) {
    case undefined:
      return "NA";
    case "":
      return "NA";
    case "null null":
      return "NA";
    case null:
      return "NA";
    case "0000-00-00":
      return "NA";
    case "0000":
      return "NA";
    case 1:
      return "Yes";
    case 0:
      return "No";
    case "1":
      return "Yes";
    case "0":
      return "No";
    default:
      return params;
  }
}

export function EXPStringMap(params: any) {
  switch (params) {
    case undefined:
      return "NA";
    case "":
      return "NA";
    case "null null":
      return "NA";
    case null:
      return "NA";
    case "0000-00-00":
      return "NA";
    case "0000":
      return "NA";
    default:
      return params;
  }
}

export function ArrayStringMap(params: any) {
  switch (params) {
    case undefined:
      return "NA";
    case null:
      return "NA";
    case "":
      return "NA";
    case params.length > 0:
      return "NA";
    case params.length == 0:
      return "NA";
    case []:
      return "NA";
    default:
      return params;
  }
}

export function NumberStringMap(params: any) {
  switch (params) {
    case undefined:
      return "0";
    case "":
      return "0";
    case null:
      return "0";
    default:
      return params;
  }
}

export function DateStringMap(params: any) {
  switch (params) {
    case "0000-00-00":
      return "NA";
    case null:
      return "NA";
    case undefined:
      return "NA";
    case "":
      return "NA";
    default:
      return moment(params).format("MMM YYYY");
  }
}

export function TableDateStringMap(params: any) {
  switch (params) {
    case "0000-00-00":
      return "NA";
    case null:
      return "NA";
    case undefined:
      return "NA";
    case "":
      return "NA";
    default:
      return moment(params).format("lll");
  }
}
export function DateStringYearMap(params: any) {
  switch (params) {
    case "0000-00-00":
      return "NA";
    case null:
      return "NA";
    case undefined:
      return "NA";
    case "":
      return "NA";
    default:
      return moment(params).format("YYYY");
  }
}

export function FullDateStringMap(params: any) {
  switch (params) {
    case "0000-00-00":
      return "NA";
    case null:
      return "NA";
    case undefined:
      return "NA";
    case "":
      return "NA";
    default:
      return moment(params).format("MMM DD, YYYY");
  }
}

// string split
export const SplitString = (s: any, key: string) => {
  if (s && s !== undefined && key) return s.substring(0, s.indexOf(key));
};
export const SplitStringlat = (s: any, key: string) => {
  if (s && s !== undefined) {
    return s.charAt(s.length - 1);
  } else {
    return "0";
  }
};

// array key maping
export const arrayOptionData = (array, label, value) => {
  if (array !== undefined) {
    const pushData: { label: string; value: string | number }[] = [];
    array.map((s) => {
      if (s[value] !== null && s[value] !== undefined) {
        pushData.push({
          label: s[label],
          value: s[value],
        });
      }
    });
    return pushData;
  } else {
    return [];
  }
};
export const arraysearchTagOptionData = (array, label, value) => {
  if (array !== undefined) {
    const pushData: { name: string; id: string | number }[] = [];
    array.map((s) => {
      if (s[value] !== null) {
        pushData.push({
          name: s[label],
          id: s[value],
        });
      }
    });
    return pushData;
  } else {
    return [];
  }
};

export const arrayOptionDataEmpty = (array, label, value) => {
  if (array !== undefined) {
    const pushData: { label: string; value: string | number }[] = [];
    array.map((s) => {
      if (s[value] !== null) {
        pushData.push({
          label: s,
          value: "",
        });
      }
    });
    return pushData;
  } else {
    return [];
  }
};

export const arrayOptionDatavalueEmpty = (valuearray, mainarray, value) => {
  if (valuearray !== undefined) {
    const pushData: { label: string; value: string | number }[] = [];
    mainarray.map((s) => {
      if (s !== null && s !== "" && valuearray.includes(s.value)) {
        pushData.push({
          label: s.label,
          value: s.value,
        });
      }
    });
    return pushData;
  } else {
    return [];
  }
};

export const RemoveDuplicates = (array, prop) => {
  var dups: any[] = [];
  if (array !== undefined) {
    var arr = array.filter(function (el) {
      // If it is not a duplicate, return true
      if (dups.indexOf(el[prop]) === -1) {
        dups.push(el[prop]);
        return true;
      }

      return false;
    });
    return arr;
  }
};

export const arrayskillOptionData = (array, label, value) => {
  const pushData: {
    skill_name: string;
    experience: string;
    skill_id: string;
  }[] = [];
  if (array !== undefined) {
    array.map((s) => {
      pushData.push({
        skill_name: s.label,
        experience: "",
        skill_id: s.value,
      });
    });
    return pushData;
  } else {
    return [];
  }
};

// multiple key array maping

export const arrayOptionNameData = (array, label) => {
  if (array) {
    const pushData: any[] = [];
    array.map((s: any) => {
      pushData.push(s[label]);
    });
    return pushData;
  }
};

export const arrayOptionValueData = (array, label) => {
  if (array) {
    var pushData = "";
    array.map((s: any) => {
      pushData = s[label];
    });
    return pushData;
  }
};

export const arrayOptionTrueData = (value, array, prop, prop2) => {
  if (array) {
    const pushData = [] as any;
    for (var i = 0; i < array.length; i++) {
      if (array[i][prop] === value) {
        pushData.push(array[i][prop2]);
      }
    }
    return pushData;
  }
};

// key value array mapping for expect,intermedite,begineer
export const arrayKeymatch = (arr: any) => {
  // if (arr.length > 0 && arr !== undefined)
  if (arr !== undefined) {
    const pushData: { title: string; arr: any }[] = [];
    Object.entries(arr).forEach(([key, value]) => {
      pushData.push({ title: key, arr: value });
    });
    return pushData;
  }
  return [];
};

// feedback keymap

export const arrayfeedbackKeymatch = (
  arr: any,
  keyname1: string,
  keyname2: string,
  keyname3: string
) => {
  const pushData: any[] = [];
  // if (arr.length > 0 && arr !== undefined)
  if (arr !== undefined) {
    Object.entries(arr).forEach(([key, value]) => {
      pushData.push({ [keyname1]: key, [keyname2]: value, [keyname3]: "" });
    });
  }
  return pushData;
};

// Calculate array length

export const Arraylength = (arr: any) => {
  if (arr && arr.length > 0) {
    return arr.length;
  }
  return 0;
};

// api error handler
export const apiCallBack = (value: any) => {
  if (value && value !== undefined && value.status === 200) {
    return true;
  } else {
    return false;
  }
};

export const toFindDuplicates = (arr, prop, value) => {
  if (arr && prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] !== value[prop]) {
        return arr[i];
      }
    }
  }
};

// array index key value mapping
export const getIndexValue = (
  value: any,
  arr: any,
  prop: string,
  valuekey: string
) => {
  if (arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] == value) {
        return arr[i][valuekey];
      }
    }
    return ""; //to handle the case where the value doesn't exist
  }
  return null;
};

export const getIndexVisaTypeValue = (
  value: any,
  arr: any,
  prop: string,
  valuekey: string
) => {
  if (arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return arr[i][valuekey];
      }
    }
    return ""; //to handle the case where the value doesn't exist
  }
  return null;
};

export const getStringValue = (value: any, arr: any, prop: string) => {
  if (arr !== undefined && arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return arr[i][prop];
      }
    }
    return ""; //to handle the case where the value doesn't exist
  } else {
  }
};

export const getStringarrayValuematch = (
  value: any,
  arr: any,
  prop: string
) => {
  if (arr !== undefined && arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === value) {
        return true;
      } else {
        return false;
      }
    }
    return false; //to handle the case where the value doesn't exist
  } else {
  }
};

// deikaa key maping

export function FindDeikaaKey(arr: any, key: any, value: any) {
  if (arr) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][key] === value) {
        return arr[i];
      }
    }
    return []; //to handle the case where the value doesn't exist
  }
}

// concat two array

export const ConcatArray = (x: any, y: any) => {
  let d: any[] = [];
  x.concat(y).forEach((item: any) => {
    if (d.indexOf(item) === -1) d.push(item);
  });
  return d;
};

export const NotificationTost = ({
  isOpen,
  message,
  closeTost,
  toastColor,
}: any) => {
  return (
    <IonToast
      isOpen={isOpen}
      onDidDismiss={() => closeTost}
      message={message}
      duration={5000}
      color={toastColor}
    />
  );
};

// email masking

export function maskEmail(email) {
  if (email) {
    let splits = email.split("@");
    let splitdomine = splits[1].split(".");

    return (
      splits[0].substr(0, 2) +
      "xxxx" +
      "@" +
      splitdomine[0].substr(0, 2) +
      "xx." +
      splitdomine[1]
    );
  }
}

export function maskMobileno(value) {
  if (value) {
    return value.substr(0, 2) + "xxxxxx" + value.substr(value.length - 2, 2);
  }
}

export const YearsList = (minVal, maxVal) => {
  // const year = new Date().getFullYear();

  let minOffset = minVal ?? 0,
    maxOffset = maxVal ?? 80;
  let thisYear = new Date().getFullYear();
  let allYears: any[] = [];
  for (let x = 0; x <= maxOffset; x++) {
    allYears.push(thisYear - x);
  }

  const yearList = allYears.map((x) => {
    return <option key={x}>{x}</option>;
  });

  return yearList;

  // return Array.from(new Array(80), (v, i) => (
  //   <option key={i} value={year - i}>
  //     {year - i}
  //   </option>
  // ));
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};
