import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../../../context/authContext";
import ReactPlayer from "react-player";
import {
  Ceditor,
  CompanyEmailVerify,
  LocationSearch,
  ReadMoreRichText,
} from "../../../component";
import { decrypted, SeverfilePaths } from "../../../service/axios";
import { addDefaultcoverSrc, apiCallBack, fileUpload } from "../../../service";
import { cloudUploadOutline } from "ionicons/icons";
import { companyuserupdate } from "../Service/api";

export default function CompanyPersonalInfo({
  modalSet,
  profileData,
  closeModal,
  folderKey,
  com_id,
}) {
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [isValidVideo, setVideoValidity] = React.useState(false);
  const [showVideoAlert, setVideoAlert] = React.useState(false);
  const [locationLatLng, setLatLng] = React.useState({ lat: "0", lng: "0" });
  const [refreshlocation, setRefresh] = React.useState("no");
  const [locationImage, setLocationImage] = React.useState("");

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  //   video validation handiler

  function handleChange(event) {
    if (event) {
      var isValid = ReactPlayer.canPlay(event.detail.value);
      setVideoValidity(isValid);
      if (!isValid) {
      } else setVideoAlert(false);
    }
  }

  // photo upload
  const imageUpload = (e: any, type: string) => {
    const files = e.target.files;
    preventBrowserDefaults(e);
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setToastColor("danger");
        setToastMessage(errVal);
        setShowToast(true);
      }
      return false;
    }
    const imagename = files[0].name;
    fileUpload(files[0], folderKey).then((e) => {
      if (apiCallBack(e)) {
        if (type === "profile") {
          setValue("profile_photo", e.filename);
        } else if (type === "cover") {
          setValue("cover_photo", e.filename);
        } else {
          setLocationImage(e.filename);
        }
      } else {
        setToastColor("danger");
        setToastMessage("Image upload failed, please try again");
        setShowToast(true);
      }
    });
  };

  const saveProfile = (values) => {
    setShowLoading(true);
    if (values) {
      values.com_id = com_id;

      values.localtion_images = [
        {
          address: values.geo_location ?? "",
          latitude: locationLatLng.lat ?? "0",
          longitude: locationLatLng.lng ?? "0",
          image: locationImage ?? "",
          state: "",
          city: "",
          zip_code: "0",
        },
      ];
      companyuserupdate(values).then((value) => {
        if (value !== undefined && value.status === 200) {
          setToastColor("success");
          setToastMessage("Profile Updated Successfully");
          setShowToast(true);
          closeModal();
          setShowLoading(false);
        } else {
          setToastColor("danger");
          setToastMessage("Unable to Update Profile");
          setShowToast(true);
          setShowLoading(false);
        }
      });
    }
  };

  React.useEffect(() => {
    // if (!dataupdated) {
    if (profileData) {
      const comData = profileData;
      const weblink = comData.websites.map((e: any) => {
        let data = e;
        data.url = e.website_url;
        return data;
      });

      setLocationImage(
        comData.locationimages.length !== 0
          ? comData.locationimages[0].image ?? ""
          : ""
      );

      setValue("firstname", comData.firstname);
      setValue("lastname", comData.lastname ?? "");
      setValue("username", comData.username ?? "");
      setValue("video", comData.video);
      setValue(
        "email",
        decrypted(comData.email) ? decrypted(comData.email) : ""
      );
      setValue("country_code", comData.country_code ?? "");
      setValue(
        "mobile",
        decrypted(comData.mobile) ? decrypted(comData.mobile) : ""
      );
      setValue("alt_country_code", comData.alt_country_code ?? "");
      setValue(
        "alt_mobile",
        decrypted(comData.alt_mobile) ? decrypted(comData.alt_mobile) : ""
      );

      setValue(
        "geo_location",
        comData.locationimages.length !== 0
          ? comData.locationimages[0].address
          : ""
      );

      setValue(
        "linked_in_url",
        decrypted(comData.com_linked_in_url)
          ? decrypted(comData.com_linked_in_url)
          : ""
      );
      setValue(
        "facebook_url",
        decrypted(comData.com_facebook_url)
          ? decrypted(comData.com_facebook_url)
          : ""
      );
      setValue(
        "instagram_url",
        decrypted(comData.com_instagram_url)
          ? decrypted(comData.com_instagram_url)
          : ""
      );
      setValue(
        "twitter_url",
        decrypted(comData.com_twitter_url)
          ? decrypted(comData.com_twitter_url)
          : ""
      );
      setValue(
        "otherlink_url",
        decrypted(comData.com_otherlink_url)
          ? decrypted(comData.com_otherlink_url)
          : ""
      );
      setRefresh("yes");
      //   setDataupdated(true);
      // }
      // video alert
      // if (decrypted(comData.video) && comData.video) {
      //   var isValid = ReactPlayer.canPlay(decrypted(comData.video));
      //   setVideoValidity(isValid);
      //   if (!isValid) setVideoAlert(true);
      //   else setVideoAlert(false);
      // }
    }
  }, [modalSet]);

  return (
    <>
      <IonAlert
        isOpen={showVideoAlert}
        onDidDismiss={() => setVideoAlert(false)}
        header={"Error"}
        message={"Video link is not valid."}
        buttons={["OK"]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveProfile)}>
            <IonRow>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    First Name <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="firstname"
                    rules={{
                      required: "firstname is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Enter the firstname"
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("firstname")}
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Last Name <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="lastname"
                    rules={{
                      required: "Lastname is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Enter the lastname"
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("lastname")}
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    About <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="about"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Ceditor setEditorData={onChange} editorData={value} />
                    )}
                    defaultValue={profileData.about}
                  />
                </IonItem>
                {showError("about")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">Video Link</IonLabel>
                  <Controller
                    control={control}
                    name="video"
                    rules={{
                      pattern: {
                        value:
                          /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/g,
                        // /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
                        message: "Invalid video url",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        value={value}
                        placeholder="Enter Video Link of Youtube or Vimeo"
                      />
                    )}
                  />
                </IonItem>
                {showError("video")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Email <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "invalid email address",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        placeholder="Enter Email Address"
                      />
                    )}
                  />
                </IonItem>
                {showError("email")}
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <IonGrid>
                  <IonRow>
                    <IonCol sizeLg="12" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Mobile number with country code{" "}
                          <span className="required">*</span>
                        </IonLabel>
                        <IonGrid className="m-0">
                          <IonRow>
                            <IonCol size="3">
                              <Controller
                                control={control}
                                name="country_code"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <IonInput
                                    autocomplete="off"
                                    onBlur={onBlur}
                                    onIonChange={onChange}
                                    value={value}
                                    minlength={0}
                                    maxlength={10}
                                    placeholder="ex: +91"
                                  />
                                )}
                              />
                            </IonCol>
                            <IonCol>
                              <Controller
                                control={control}
                                name="mobile"
                                rules={{
                                  pattern: {
                                    value: /[0-9]/,
                                    message: "invalid mobile number",
                                  },
                                }}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <IonInput
                                    autocomplete="off"
                                    onBlur={onBlur}
                                    onIonChange={onChange}
                                    value={value}
                                    placeholder="Enter Mobile number"
                                  />
                                )}
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonItem>
                      {showError("country_code")}

                      {showError("mobile")}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <IonGrid>
                  <IonRow>
                    <IonCol sizeLg="12" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Office number with country code{" "}
                        </IonLabel>
                        <IonGrid className="m-0">
                          <IonRow>
                            <IonCol size="3">
                              <Controller
                                control={control}
                                name="alt_country_code"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <IonInput
                                    autocomplete="off"
                                    onBlur={onBlur}
                                    onIonChange={onChange}
                                    value={value}
                                    minlength={0}
                                    maxlength={10}
                                    placeholder="ex: +91"
                                  />
                                )}
                              />
                            </IonCol>
                            <IonCol>
                              <Controller
                                control={control}
                                name="alt_mobile"
                                rules={{
                                  pattern: {
                                    value: /[0-9]/,
                                    message: "invalid office number",
                                  },
                                }}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <IonInput
                                    autocomplete="off"
                                    onBlur={onBlur}
                                    onIonChange={onChange}
                                    value={value}
                                    placeholder="Enter Office Number"
                                  />
                                )}
                              />
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonItem>
                      {showError("alt_country_code")}
                      {showError("alt_mobile")}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <div >                
                  <div className="locationInput">
                  <IonLabel position="stacked">
                    Company Location and Image
                  </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <LocationSearch
                          valueKey={value ?? ""}
                          placeholder="Search place"
                          onChangeValue={onChange}
                          onSelectValue={(e: any) => {
                            setValue("geo_location", e);
                          }}
                          latLong={(e: any) => {
                            setLatLng(e);
                          }}
                          refresh={refreshlocation}
                        />
                      )}
                      name="geo_location"
                      control={control}
                    />
                    <div className="cursor" style={{ textAlign: "end" }}>
                      <label htmlFor="upload-button3">
                        <IonIcon
                          className="round-button i-color"
                          icon={cloudUploadOutline}
                        ></IonIcon>
                        upload image
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="upload-button3"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          imageUpload(e, "locationImg");
                        }}
                      />
                    </div>
                  </div>
                  <div className="previewimage">
                    <img
                      alt=""
                      onError={addDefaultcoverSrc}
                      src={SeverfilePaths(folderKey) + locationImage}
                      style={{ height: "80px", width: "100px" }}
                    />
                    {locationImage}
                  </div>
                </div>
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <div className="text-right">
                  <IonButton
                    slot="end"
                    shape="round"
                    fill="solid"
                    color="secondary"
                    className="remove-shadow capitalizes"
                    type="submit"
                  >
                    Save
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
}

// image validation

const preventBrowserDefaults = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const fileValidator = (files: any, config: any) => {
  const { allowedFileFormats, fileSizeMBLimit, filesLimit } = config;
  const { length } = files;
  const { size, type } = files[0];
  let err = "";
  let result = {
    isValidFile: false,
    errVal: err,
  };
  if (length === 0) {
    return result;
  } else if (length > filesLimit) {
    err =
      filesLimit > 1
        ? `Only ${filesLimit} files are allowed to upload`
        : `Only one file is allowed to upload`;
  } else if (!allowedFileFormats.includes(type)) {
    err = "File format must be either .jpg and .png";
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    err = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  result.errVal = err;
  return result;
};

const config = {
  allowedFileFormats: ["image/jpeg", "image/jpg", "image/png"],
  fileSizeMBLimit: 5,
  filesLimit: 1,
};
