import {
  IonButton,
  IonCol,
  IonIcon,
  IonGrid,
  IonItem,
  IonRow,
} from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import React from "react";
import { arrayKeymatch } from "../../../../../service";
import { CommonSectionSkill } from ".";
import { ModalBody } from "../../../../../component";
import { SkillsForm } from "../../Form";

interface props {
  skillData: any;
  proficiencytypelist: any;
  ctridData: any;
  ListofskillsOptionData: any;
}
export const Skills: React.FC<props> = ({
  ctridData,
  skillData,
  proficiencytypelist,
  ListofskillsOptionData,
}) => {
  const [modelOpen, setModal] = React.useState(false);
  const [localSkills, setLocalSkills] = React.useState<any>([]);
  const [needToUpdate, setNeedToUpdate] = React.useState(true);

  React.useEffect(() => {
    if (needToUpdate) {
      const skill: any[] = [];
      skillData.map((e: any, i: number) => {
        skill.push({
          title: e.title,
          arr: e.arr,
          count: arrayKeymatch(e.arr)
            .map((e: any) => {
              return e.arr.length;
            })
            .reduce((a, b) => a + b, 0),
        });
      });
      setLocalSkills(skill);
      setNeedToUpdate(false);
    }
  }, []);

  function sendUpdatedDataToMainPages(data: any) {
    if (data) {
      const skill: any[] = [];
      data.map((e: any, i: number) => {
        skill.push({
          title: e.title,
          arr: e.arr,
          count: arrayKeymatch(e.arr)
            .map((e: any) => {
              return e.arr.length;
            })
            .reduce((a, b) => a + b, 0),
        });
      });
      setLocalSkills(skill);
    }
  }

  function deleteSkill(data: any) {
    if (data) {
      const skill: any[] = [];
      data.map((e: any, i: number) => {
        skill.push({
          title: e.title,
          arr: e.arr,
          count: arrayKeymatch(e.arr)
            .map((e: any) => {
              return e.arr.length;
            })
            .reduce((a, b) => a + b, 0),
        });
      });
      setLocalSkills(skill);
    }
  }

  return (
    <div id="skills">
      {!needToUpdate ? (
        <>
          <ModalBody
            title={"Skills"}
            isOpen={modelOpen}
            onClose={() => setModal(false)}
          >
            <SkillsForm
              ctrid={ctridData}
              proficiencytypelist={proficiencytypelist}
              closeModal={() => setModal(false)}
              skillDatas={localSkills}
              modelClick={modelOpen}
              ListofskillsOption={ListofskillsOptionData}
              sendUpdatedDataToMainPage={sendUpdatedDataToMainPages}
              deleteStateValue={deleteSkill}
            />
          </ModalBody>

          <IonItem lines="none" className="custom-header-space ">
            <h4>Skills</h4>
            <IonButton
              slot="end"
              fill="outline"
              className="remove-shadow custom-circle-btn"
              color="medium"
              shape="round"
              onClick={() => {
                setModal(true);
              }}
            >
              <IonIcon icon={pencilOutline} slot="icon-only" />
            </IonButton>
          </IonItem>
          <IonGrid className="ion-no-padding">
            {localSkills !== undefined &&
              localSkills.map(
                (e: any, i: number) =>
                  e.count !== 0 && (
                    <IonRow key={i}>
                      <IonCol sizeLg="4" sizeXs="12">
                        <label className="capitalize">{e.title}</label>
                      </IonCol>
                      <IonCol sizeLg="8" sizeXs="12">
                        <CommonSectionSkill
                          arrs={arrayKeymatch(e.arr)}
                          type={"skills"}
                          screenType={"form"}
                        />

                        {i !== e.length - 1 && <hr />}
                      </IonCol>
                    </IonRow>
                  )
              )}
          </IonGrid>
        </>
      ) : null}
    </div>
  );
};

export default React.memo(Skills);
