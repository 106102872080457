import {
  IonPage,
  IonContent,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonToast,
  IonInput,
  IonText,
  IonItem,
  IonLabel,
  IonLoading,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { eye, eyeOffOutline } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import { AuthContext } from "../../../context/authContext";
import { RegisterApi, onSave } from "../../../service";
import {
  Company,
  Contractor,
  Jngreporter,
  Staff,
} from "../../../service/default";
import { encrypted } from "../../../service/axios";

interface Props extends RouteComponentProps<{ referral_code: "" }> {}

const Register: React.FC<Props> = ({ history, match }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" || "onBlur" });
  const [captcha, setCaptchaValue] = React.useState<boolean>(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showPassword, setPassword] = React.useState(false);
  const [showconPassword, setConPassword] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const Auth = React.useContext(AuthContext);
  const CheckUrl = document.URL.startsWith("https://apps.jobsngigs.com");
  function onCaptchaValidation(value) {
    setCaptchaValue(true);
  }

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  const RegisterUser = (values: any) => {
    values.referral_code = match.params.referral_code;
    if (!captcha && CheckUrl) {
      setToastColor("danger");
      setToastMessage("Please verify captcha to continue..");
      setShowToast(true);
      return;
    }
    values.firstname = encrypted(values.firstname);
    values.lastname = encrypted(values.lastname);
    values.password = encrypted(values.password);
    values.cfm_password = encrypted(values.cfm_password);
    setShowLoading(true);
    RegisterApi(values).then((e) => {
      if (e !== undefined && e.status === 200) {
        onSave({
          key: "userName",
          value: values.username,
        });

        if (e.data.user_type === Company) {
          Auth.setAuthResponse(true, e.data);
          setShowLoading(false);
          history.replace("/ProfileCompany");
        } else if (e.data.user_type === Staff) {
          Auth.setAuthResponse(true, e.data);
          setShowLoading(false);
          history.replace("/viewcompany");
        } else if (e.data.user_type === Jngreporter) {
          Auth.setAuthResponse(true, e.data);
          history.replace("/list-jobs");
        } else if (e.data.user_type === Contractor) {
          Auth.setAuthResponse(false, null);
          // onSave({ key: "routekey", value: true });
          history.replace("/login");
          //   setTimeout(() => {
          // setShowLoading(false);
          // Auth.setAuthResponse(false, null);
          // onSave({ key: "routekey", value: true });
          //   history.replace("/ContractorResume");
          //    }, 4000);
          // } else {
          //   setShowLoading(false);
        }
        setToastColor("success");
        setToastMessage(
          e.message ? e.message : "Account has been created successfully"
        );
        setShowToast(true);
      } else if (e !== undefined && e.status === 201) {
        setShowLoading(false);
        const errorInfo = e.data;
        if (typeof errorInfo === "string") {
          setToastColor("danger");
          setToastMessage(e.data);
          setShowToast(true);
        } else {
          setToastColor("danger");
          for (const [key, value] of Object.entries(errorInfo)) {
            setToastMessage(`${value}`);
            setShowToast(true);
          }
          setShowLoading(false);
        }
        // } else {
        //   setToastMessage("Something went wrong, please try again");
        // }
      } else {
        if (!e && e === undefined) {
          setToastColor("danger");
          setToastMessage("Unable to connect to server");
          setShowToast(true);
          return;
        } else {
          setToastColor("danger");
          setToastMessage(e.message);
          setShowToast(true);
        }
      }
      setShowLoading(false);
    });
  };

  const showMessage = () => {
    setToastColor("danger");
    setToastMessage("Account has been created successfully");
    setShowToast(true);
  };

  return (
    <IonPage>
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={2000}
          color={toastColor}
        />
        <div className="login-form registation">
          <div className="login-img">
          <a href="/" >
            <img src="assets/image/hirify.png" />
            </a>
          </div>
          <div className="card-section">
            <div className="login-curve-box"></div>
            <IonCard className="ion-padding">
              <IonGrid>
                <form onSubmit={handleSubmit(RegisterUser)}>
                  <IonRow>
                    <IonCol sizeLg="12" sizeXs="12">
                      <h4 className="header">Create a jobseeker account</h4>
                      <span>
                        Already have an account? <a href="/login">Sign in</a>
                      </span>

                      {/* <div className="space-bottom"></div> */}
                    </IonCol>
                    <IonCol sizeLg="12" sizeXs="12">
                      <IonGrid>
                        <IonRow className="custom-reg-content ion-align-items-center">
                          <IonCol className="vertical-align-center">
                            <h6 className="mb-0">Are you an employer?</h6>
                          </IonCol>

                          <IonCol className="ion-text-end">
                            <IonButton
                              shape="round"
                              fill="outline"
                              color="secondary"
                              className="remove-shadow capitalizes orange-outline-btn"
                              type="button"
                              href="https://apps.hirify.ai/login"
                              target="_blank"
                            >
                              Request an account
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCol>
                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          First name<span className="required">*</span>
                        </IonLabel>
                        <Controller
                          control={control}
                          name="firstname"
                          rules={{
                            required: "Firstname is required",
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              autocomplete="off"
                              onBlur={onBlur}
                              onIonChange={onChange}
                              value={value}
                              minlength={0}
                              maxlength={32}
                              placeholder="Enter the firstname"
                            />
                          )}
                        />
                      </IonItem>
                      {showError("firstname")}
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Last name<span className="required">*</span>
                        </IonLabel>
                        <Controller
                          control={control}
                          name="lastname"
                          rules={{
                            required: "Lastname is required",
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              autocomplete="off"
                              onBlur={onBlur}
                              onIonChange={onChange}
                              value={value}
                              minlength={0}
                              maxlength={32}
                              placeholder="Enter the lastname"
                            />
                          )}
                        />
                      </IonItem>
                      {showError("lastname")}
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Email<span className="required">*</span>
                        </IonLabel>
                        <Controller
                          control={control}
                          name="email"
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value:
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: "invalid email address",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              autocomplete="off"
                              onBlur={onBlur}
                              onIonChange={onChange}
                              value={value}
                              minlength={0}
                              maxlength={64}
                              placeholder="Enter the email"
                            />
                          )}
                        />
                      </IonItem>
                      {showError("email")}
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Choose a username<span className="required">*</span>
                        </IonLabel>
                        <Controller
                          control={control}
                          name="username"
                          rules={{
                            required: "Username is required",
                            pattern: {
                              value: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
                              message: "Invalid characters",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              autocomplete="off"
                              onBlur={onBlur}
                              onIonChange={onChange}
                              value={value}
                              minlength={0}
                              maxlength={32}
                              placeholder="Enter the username"
                            />
                          )}
                        />

                        {/* <IonText className="helperText right-content-2">
                        a-z, 0-9, hyphens (-), and underscore (_)
                      </IonText> */}
                      </IonItem>
                      {showError("username")}
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Password<span className="required">*</span>
                        </IonLabel>
                        <Controller
                          control={control}
                          name="password"
                          rules={{
                            required: "password is required",
                            pattern: {
                              value: /^[ A-Za-z0-9_@./#&+-]*$/,
                              message: "Invalid characters",
                            },
                            minLength: {
                              value: 6,
                              message:
                                "Password must have at least 6 characters",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <>
                              <IonInput
                                autocomplete="off"
                                type={showPassword ? "text" : "password"}
                                onBlur={onBlur}
                                onIonChange={onChange}
                                value={value}
                                minlength={6}
                                maxlength={64}
                                placeholder="Enter the password"
                              />
                              <IonButton
                                className="ion-no-padding customeye-btn"
                                fill="clear"
                                shape="round"
                                color="dark"
                                onClick={() => setPassword(!showPassword)}
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={showPassword ? eye : eyeOffOutline}
                                />
                              </IonButton>
                            </>
                          )}
                        />
                        <IonText className="helperText right-content-2">
                          6-64 alphanumeric/special characters
                        </IonText>
                      </IonItem>
                      {showError("password")}
                    </IonCol>
                    <IonCol>
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Confirm Password<span className="required">*</span>
                        </IonLabel>
                        <Controller
                          control={control}
                          name="cfm_password"
                          rules={{
                            required: "Confirm password is required",
                            pattern: {
                              value: /^[ A-Za-z0-9_@./#&+-]*$/,
                              message: "Incorrect characters",
                            },
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <>
                              <IonInput
                                autocomplete="off"
                                type={showconPassword ? "text" : "password"}
                                onBlur={onBlur}
                                onIonChange={onChange}
                                value={value}
                                minlength={6}
                                maxlength={64}
                                placeholder="Enter the confirm password"
                              />
                              <IonButton
                                className="ion-no-padding customeye-btn-login"
                                fill="clear"
                                shape="round"
                                color="dark"
                                onClick={() => setConPassword(!showconPassword)}
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={showconPassword ? eye : eyeOffOutline}
                                />
                              </IonButton>
                            </>
                          )}
                        />
                      </IonItem>
                      {showError("cfm_password") || (
                        <div className="errorMessage text-left">
                          {watch("password") !== watch("cfm_password") &&
                            "The passwords do not match"}
                        </div>
                      )}
                    </IonCol>

                    {CheckUrl && (
                      <IonCol sizeLg="12" sizeXs="12">
                        <div className="pannel-ReCAPTCHA">
                          <ReCAPTCHA
                            className="pannel-ReCAPTCHA2"
                            sitekey="6Lf4sJwaAAAAAIPAY3EPHjNHuHC41QZkmEGxKg5d"
                            onChange={onCaptchaValidation}
                          />
                        </div>
                      </IonCol>
                    )}
                    {/* <Divider /> */}

                    {/* <IonCol sizeLg="12" sizeXs="12">
                    <p className="text-left custom-p">
                      By creating an account, you accept the Hirify{" "}
                      <a target="_blank" href="/terms">
                        Terms of Use
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy
                      </a>
                    </p>
                  
                  </IonCol> */}

                    {/* <IonCol sizeLg="12" sizeXs="12" className="text-left">
                    <Form.Item name="newsletter" valuePropName="checked">
                      <Checkbox className="custom-p">
                        Subscribe to our Talenthunting newsletter.
                      </Checkbox>
                    </Form.Item>
                  </IonCol> */}
                    {/* <IonCol sizeLg="12" sizeXs="12" className="text-left">
                    <Form.Item name="join" valuePropName="checked">
                      <Checkbox className="custom-p">
                        Join Hirify' reward program by creating my Deikaa
                        account, subject to Deikaa's Terms of Use and Privacy
                        Policy. Visit{" "}
                        <a href="https://www.deikaa.com">deikaa.com</a> for more
                        information.
                      </Checkbox>
                    </Form.Item>
                  </IonCol> */}

                    <IonCol sizeLg="12" sizeXs="12">
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="submit"
                      >
                        {showLoading
                          ? "Loading..."
                          : "Create Jobseeker Account"}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </form>
              </IonGrid>
            </IonCard>
          </div>
          <IonGrid className="footer-content">
            <IonRow>
              <IonCol sizeLg="6" sizeXs="12">
                <ul className="ulelement text-left">
                  <li>Facing any difficulty?</li>
                  <li className="modify-color">
                    
                    <a
                      rel="noreferrer"
                      href="/#contactus"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <ul className="ulelement footer-rightSection ">
                  <li className="small-text smallfont">
                    © 2024–present, Hirify. All rights reserved.
                  </li>
                  <li className="smallfont">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://apps.hirify.ai/terms"
                    >
                      Terms of Use •
                    </a>{" "}
                    <a
                      rel="noreferrer"
                      href="https://apps.hirify.ai/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Register);
