import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
  IonToggle,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import React, { useState } from "react";
import { Ceditor, DatePick, LocationSearch } from "../../../../component";
import {
  organization,
  UpdateOrganization,
  DeleteOrganization,
} from "../../Service/api";
import { apiCallBack } from "../../../../service";

const CareerForm = ({
  empdata,
  initialValueData,
  closeModal,
  setorganizationData,
  eventType,
  dataset,
  deleteExperienceData,
  ctrId,
}: any) => {
  const [uploading, setUploading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const [show, setShow] = React.useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [empData, setEmp] = useState<any>(empdata);

  // delete
  const Deleteorganisation = () => {
    setShowLoading(true);
    var id = initialValueData.ctr_ehid;
    if (id) {
      setShowLoading(true);
      DeleteOrganization(id).then((e: any) => {
        if (e !== undefined && e.status === 200) {
          deleteExperienceData(id);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
          setShowLoading(false);
          closeModal(false);
        } else {
          setToastColor("danger");
          setToastMessage(e.message);
          setShowToast(true);
          setShowLoading(false);
        }
      });
    }
  };

  // setValue to the field

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  React.useEffect(() => {
    setValue("emt_id", initialValueData.emt_id);
    setValue("company_name", initialValueData.company_name);
    setValue("contractor_role", initialValueData.contractor_role);
    setValue("description", initialValueData.description);
    setValue("currently_working", initialValueData.currently_working);
    setValue(
      "start_date",
      initialValueData.start_date ? new Date(initialValueData.start_date) : ""
    );

    setValue(
      "end_date",
      initialValueData.end_date && !initialValueData.currently_working
        ? new Date(initialValueData.end_date)
        : ""
    );

    setValue("remote_location", initialValueData.remote_location);
    setValue("geo_location", initialValueData.geo_location);

    if (initialValueData.description !== "") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [dataset]);

  // save and update api
  const saveExperience = (values: any) => {
    setShowLoading(true);
    values.ctr_id = ctrId;

    values.description = show ? values.description : "";
    values.geo_location = values.geo_location ?? "";
    values.latitude = values.latitude ?? "";
    values.longitude = values.longitude ?? "";
    values.start_date = values.start_date ? values.start_date : "";
    values.end_date = values.end_date ? values.end_date : "";
    values.currently_working = values.currently_working === false ? "0" : "1";
    values.remote_location = values.remote_location === false ? "0" : "1";
    values.start_date =
      values.start_date === "Invalid date" ? "" : values.start_date;
    values.end_date = values.end_date === "Invalid date" ? "" : values.end_date;
    if (eventType === "Add") {
      organization(values).then((e) => {
        if (apiCallBack(e)) {
          setorganizationData(e.data);
          setUploading(false);
          setToastColor("success");
          setToastMessage("Organisation added successfully");
          setShowToast(true);
          setShowLoading(false);
          closeModal(false);
        } else {
          setUploading(false);
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(e !== undefined && e.message);
          setShowToast(false);
        }
      });
    } else {
      values.ctr_ehid = initialValueData.ctr_ehid;
      UpdateOrganization(values).then((e) => {
        if (apiCallBack(e)) {
          setorganizationData(e.data);
          setToastColor("success");
          setToastMessage("Organisation updated successfully");
          setShowToast(true);
          setShowLoading(false);
          closeModal(false);
          setUploading(false);
        } else {
          setUploading(false);
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(e !== undefined && e.message);
          setShowToast(false);
        }
      });
    }
  };

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={"Are you sure?"}
        subHeader={"Continue to delete the experience."}
        message={"Confirm here"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert(false);
              Deleteorganisation();
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveExperience)}>
            <IonRow>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Organization/client <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="company_name"
                    rules={{
                      required: "Organization/client is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Enter the Organization/client"
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("company_name")}
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">Role</IonLabel>
                  <Controller
                    control={control}
                    name="contractor_role"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Web Developer, Sr. Data Scientist, etc."
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("contractor_role")}
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <IonButton
                  fill="clear"
                  color="secondary"
                  type="button"
                  size="small"
                  className="remove-shadow capitalizes"
                  onClick={() => setShow(!show)}
                >
                  Add Role Description
                </IonButton>

                {show && (
                  <>
                    <IonItem lines="none">
                      <IonLabel position="stacked">Role Description</IonLabel>
                      <Controller
                        control={control}
                        name="description"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Ceditor
                            setEditorData={onChange}
                            editorData={value}
                          />
                        )}
                      />
                    </IonItem>
                  </>
                )}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">Start</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick
                        value={value}
                        ChangeEvent={onChange}
                        possition="bottom-start"
                      />
                    )}
                    control={control}
                    name="start_date"
                  />
                </div>
                {showError("start_date")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
              <div className="input-div">
                  <IonLabel position="stacked">End</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick
                        value={value}
                        ChangeEvent={(e) => {
                          setValue("end_date", e);
                          setValue("currently_working", false);
                        }}
                        minDate={watch("start_date")}
                        disabled={
                          watch("currently_working") === false ? false : true
                        }
                        possition="bottom-start"
                      />
                      // <input
                      //   onBlur={onBlur}
                      //   disabled={
                      //     watch("currently_working") === false ? false : true
                      //   }
                      //   value={value}
                      //   data-date-format="YYYY-MM"
                      //   type="date"
                      //   placeholder=""
                      //   onChange={(e: any) => {
                      //     setValue("end_date", e.target.value);
                      //     setValue("currently_working", false);
                      //   }}
                      // />
                    )}
                    control={control}
                    name="end_date"
                    // rules={{
                    //   value: {
                    //     value: (v) => v >= watch("start_date"),
                    //     message: "End month is greater then start month",
                    //   },
                    // }}
                  />
                </div>
                {showError("end_date")}
                <IonItem lines="none">
                  <label>Ongoing</label>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonToggle
                        checked={value}
                        value={value}
                        slot="end"
                        mode="md"
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue("currently_working", e.detail.checked);
                          setValue("end_date", "");
                        }}
                      />
                    )}
                    control={control}
                    name="currently_working"
                  />
                </IonItem>
              </IonCol>

              <IonCol sizeXs="12" sizeLg="6">
                <IonItem lines="none">
                  <IonLabel position="stacked">Engagement type</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        {empData.map((option: any, i: number) => (
                          <option key={i} value={option.emt_id}>
                            {option.emt_name}
                          </option>
                        ))}
                      </select>
                    )}
                    control={control}
                    name="emt_id"
                  />
                </IonItem>
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="locationInput">
                  <IonLabel position="stacked">Location</IonLabel>
                  <Controller
                    control={control}
                    name="geo_location"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <LocationSearch
                        valueKey={value}
                        onChangeValue={onChange}
                        onSelectValue={(e: any) => setValue("geo_location", e)}
                        refresh={value}
                        latLong={(e: any) => {
                          setValue("longitude", e.lat);
                          setValue("latitude", e.lng);
                        }}
                      />
                      // <IonInput
                      //   autocomplete="off"
                      //   readonly={
                      //     watch("remote_location") === false ? false : true
                      //   }
                      //   onBlur={onBlur}
                      //   onIonChange={onChange}
                      //   value={value}
                      //   placeholder="Enter the location"
                      // />
                    )}
                  />
                </div>
                <IonItem lines="none">
                  <label>Remote</label>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonToggle
                        checked={value}
                        value={value}
                        slot="end"
                        mode="md"
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          if (e.detail.checked) {
                            setValue("remote_location", e.detail.checked);
                            setValue("geo_location", "");
                          } else {
                            setValue("remote_location", e.detail.checked);
                            setValue(
                              "geo_location",
                              initialValueData.geo_location
                            );
                          }
                        }}
                      />
                    )}
                    control={control}
                    name="remote_location"
                  />
                </IonItem>
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  {eventType !== "Add" ? (
                    <IonButton
                      shape="round"
                      fill="clear"
                      color="danger"
                      className="remove-shadow capitalizes"
                      type="button"
                      onClick={() => setShowAlert(true)}
                    >
                      Delete experience
                    </IonButton>
                  ) : (
                    <div></div>
                  )}
                  <div>
                    {/* {eventType === "Add" && (
                      <IonButton
                        shape="round"
                        fill="outline"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="submit"
                      >
                        Save & Add Another
                      </IonButton>
                    )} */}

                    <IonButton
                      shape="round"
                      fill="solid"
                      color="secondary"
                      className="remove-shadow capitalizes"
                      type="submit"
                    >
                      {uploading ? "Uploading" : "Save"}
                    </IonButton>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
};

export default React.memo(CareerForm);
