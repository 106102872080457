import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonRange,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToast,
} from "@ionic/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "../../../../context/authContext";
import {
  CandidateUpdatestatus,
  getJobMatchingContractor,
} from "../../Service/api";
import { CanditateUserCard, LocationSearch } from "../../../../component";
import {
  jobResData,
  MatchJobInterface,
} from "../../interface/canditateInterface";
import { CardSketch } from "../../../../component/company/canditateJobCard";
import { NumberStringMap } from "../../../../service/default";
import { options } from "ionicons/icons";

// match screen
const Matches = ({ uniqueid, jobdetailData, pageLoad }) => {
  const { control, handleSubmit, setValue, watch } = useForm({
    mode: "all",
  });
  const [locations, setLocations] = React.useState({
    geo_location: "",
    miles: "",
  });
  const [showFilter, setShowFilter] = React.useState(false);
  const [locationRefresh, setLocationrefresh] = React.useState("no");
  const { auth, setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [getapioneCall, setGetApioneCall] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(pageLoad);
  const [showToast, setShowToast] = React.useState(false);
  const [stopLoad, setStopLoad] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [sortBy, setSortBy] = React.useState("best");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [filterByBM, setFilterByBM] = React.useState<any>("30");
  const [totalCount, setTotalCount] = React.useState(0);
  const [cardData, setCardData] = React.useState<jobResData[]>();
  const [slice, setSlice] = React.useState(10);

  // shortlisted api call
  const shortlist = (e, data) => {
    setShowLoading(true);
    const result = {
      ctr_id: data.ctr_id,
      com_id: uniqueid.com_id,
      unique_id: uniqueid.unique_id,
      job_status_type: "Shortlisted",
      // flag: e,
    };
    CandidateUpdatestatus(result).then((e) => {
      if (e !== undefined && e.status === 200) {
        removeMatchingData(data.public_id, "shortlist");
        setToastColor("success");
        setToastMessage("Applicant shortlisted");
        setShowToast(true);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setToastColor("warning");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };

  // reject - 3 and hire -2 function

  const rejectClick = (e, data) => {
    setShowLoading(true);
    const result = {
      ctr_id: data.ctr_id,
      com_id: uniqueid.com_id,
      unique_id: uniqueid.unique_id,
      job_status_type: "Rejected",
      // status: e,
    };
    CandidateUpdatestatus(result).then((e) => {
      if (e !== undefined && e.status === 200) {
        removeMatchingData(data.public_id, "reject");
        setToastColor("success");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };

  const removeMatchingData = (publicId, type) => {
    if (publicId && cardData) {
      const filterResult = cardData?.filter((id) => id.public_id !== publicId);
      addCount({
        applied_count: auth.canditateJobCount.applied_count,
        hired_count: auth.canditateJobCount.hired_count,
        invited_count: auth.canditateJobCount.invited_count,
        matching_candidate_count: filterResult.length,
        rejected_count:
          type === "reject"
            ? auth.canditateJobCount.rejected_count + 1
            : auth.canditateJobCount.rejected_count,
        shortlisted_count:
          type === "shortlist"
            ? auth.canditateJobCount.shortlisted_count + 1
            : auth.canditateJobCount.shortlisted_count,
      });
      setCardData(filterResult);
      setTotalCount(filterResult.length);
      setTotalPageItem(filterResult.length);
    }
  };

  const slideskillRange = (e) => {
    if (e) {
      // if (!getapioneCall) setShowLoading(true);
      // if (!getapioneCall)
      getMatching(currentPage, e, sortBy, locations);
      // setGetApioneCall(false);
    }
  };

  const searchForm = (value) => {
    if (value) {
      setLocations(value);
      getMatching(currentPage, filterByBM, sortBy, value);
    }
  };

  // get matching api call
  const getMatching = (page, filter, order, location) => {
    const request = {
      unique_id: uniqueid.unique_id,
      page: page,
      order: order,
      percentage: filter,
      geo_location: location.geo_location,
      miles: location.miles,
    };
    setShowLoading(true);
    setStopLoad(true);
    getJobMatchingContractor(request).then((e: MatchJobInterface) => {
      if (e !== undefined && e.status === 200) {
        addCount(e);
        setFilterByBM(e.skill_match_perc ?? "30");
        setCardData(e.data);
        setTotalPageItem(
          e.data !== undefined ? e.data.length : e.itemsCountPerPage
        );
        setShowLoading(false);
        setTotalCount(e.totalItemsCount);
      } else {
        setTotalCount(e.totalItemsCount);
        addCount(e);
        setCardData([]);
        setShowLoading(false);
        setStopLoad(false);
        setTotalPageItem(0);
      }
      setShowLoading(false);
      setStopLoad(false);
    });
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_count),
      hired_count: NumberStringMap(e.hired_count),
      invited_count: NumberStringMap(e.invited_count),
      matching_candidate_count: NumberStringMap(e.matching_candidate_count),
      rejected_count: NumberStringMap(e.rejected_count),
      shortlisted_count: NumberStringMap(e.shortlisted_count),
    });
  };

  const generateItems = ($event: CustomEvent<void>) => {
    if (cardData && cardData.length <= slice) {
      setTimeout(() => {
        setSlice(slice + 10);
        ($event.target as HTMLIonInfiniteScrollElement).complete();
      }, 2000);
    }
  };

  // life cycle
  React.useEffect(() => {
    setGetApioneCall(true);
    let isMounted = true;
    setLocationrefresh("yes");
    try {
      getMatching("1", "30", sortBy, locations);
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
          setCardData([]);
        }
      }
    } finally {
      // isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonGrid>
        <IonRow>
          {showFilter && (
            <IonCol sizeLg="12" sizeXs="12">
              <form onSubmit={handleSubmit(searchForm)}>
                <div className="locationInput custom-bg-transfram">
                  <IonRow class="ion-align-items-center">
                    <IonCol sizeLg="2" sizeXs="12">
                      Filter by locations
                    </IonCol>
                    <IonCol sizeLg="6" sizeXs="12">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <LocationSearch
                            valueKey={value}
                            onChangeValue={onChange}
                            placeholder="Locations"
                            onSelectValue={(e: any) => {
                              setValue("geo_location", e);
                            }}
                            latLong={(e: any) => {
                              setValue("latlng", e);
                            }}
                            refresh={locationRefresh}
                          />
                        )}
                        name="geo_location"
                        control={control}
                      />
                    </IonCol>
                    <IonCol sizeLg="3" sizeXs="12">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            value={value}
                            onIonChange={onChange}
                            autocomplete="off"
                            type="number"
                            placeholder="miles"
                          />
                        )}
                        name={"miles"}
                        control={control}
                      />
                    </IonCol>
                    <IonCol sizeLg="1" sizeXs="12">
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="submit"
                      >
                        Apply
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </div>
              </form>
            </IonCol>
          )}
          <IonCol size="12">
            <div className="header-content-flex ion-justify-content-between ion-align-items-center filter-option custom-sort-section">
              <IonLabel>
                Showing {totalPageItem ? 1 : 0}
                {/* {totalCount
                  ? currentPage === 1
                    ? 1
                    : (currentPage - 1) * perPage + 1
                  : 0} */}
                {" - "}
                {currentPage === 1
                  ? totalPageItem
                  : (currentPage - 1) * perPage + totalPageItem > totalCount
                  ? totalCount
                  : 0}
                {/* : (currentPage - 1) * perPage + totalPageItem}
                 {" of " + totalCount} */}
              </IonLabel>
              <div className="sort-input-section ion-align-items-center">
                <div className="range-filter">
                  <IonRange
                    no-padding
                    className={filterByBM < 50 ? "skillRange" : ""}
                    mode={"ios"}
                    min={1}
                    max={100}
                    dualKnobs={false}
                    pin={true}
                    ticks={false}
                    color={filterByBM < 50 ? "danger" : ""}
                    value={filterByBM}
                    debounce={1000}
                    onIonChange={(e) => {
                      if (e) {
                        setFilterByBM(e.detail.value as number);
                        slideskillRange(e.detail.value as number);
                      }
                    }}
                  >
                    <IonLabel slot="start">
                      {"Skill match >" + filterByBM + "%"}{" "}
                    </IonLabel>
                  </IonRange>
                </div>

                <div className="input-group sort-group ion-sort-input">
                  <IonLabel className="input-group-text">Sort:</IonLabel>
                  <IonSelect
                    interface="popover"
                    placeholder="Select"
                    className="sort-input"
                    value={sortBy}
                    onIonChange={(e) => {
                      setSortBy(e.detail.value);
                      getMatching(
                        currentPage,
                        filterByBM,
                        e.detail.value,
                        locations
                      );
                    }}
                  >
                    <IonSelectOption value="best">Best Match</IonSelectOption>
                    {/* <IonSelectOption value="desc">Latest</IonSelectOption> */}
                  </IonSelect>
                </div>
                <div className="filter-curser-icon">
                  <IonButton
                    className="roundButton"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <IonIcon icon={options} slot="icon-only"></IonIcon>
                  </IonButton>
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          {!showLoading
            ? cardData &&
              cardData.map((cand, i: number) => (
                <IonCol sizeLg="6" sizeXs="12" key={i}>
                  <CanditateUserCard
                    reviewflag={true}
                    jobdetail={jobdetailData}
                    cardData={cand}
                    shortBtnClick={(string, data) => shortlist(string, data)}
                    rejectBtnClick={(string, data) => rejectClick(string, data)}
                    type="1"
                    shortbtn={true}
                    rejectbtn={true}
                  />
                </IonCol>
              ))
            : stopLoad &&
              Array.from({ length: 10 }, (_, i) => (
                <IonCol key={i} sizeLg="6" sizeXs="12">
                  <CardSketch />
                </IonCol>
              ))}
          {!showLoading && cardData && cardData.length === 0 && (
            <IonCol size="12">No data found </IonCol>
          )}

          {/* <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e) => {
                  setCurrentPage(e);
                  getMatching(e, filterByBM, sortBy);
                }}
              />
            )}
          </IonCol> */}
          {/* <IonInfiniteScroll
            onIonInfinite={(ev: CustomEvent<void>) => {
              generateItems(ev);
            }}
          >
            <IonInfiniteScrollContent></IonInfiniteScrollContent>
          </IonInfiniteScroll> */}
        </IonRow>
      </IonGrid>
    </>
  );
};

export default React.memo(Matches);
