import {
  IonBadge,
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import moment from "moment";
import {
  CardProfileImage,
  HeaderNav,
  ModalBody,
  PaginationScreen,
} from "../../../component";
import { arrayOptionData } from "../../../service";
import { CRMRecuter, FullDateStringMap } from "../../../service/default";
import { deletecrm, GetCRMJobList } from "../service/api";
import PostJob from "./postJob";
import useMediaQuery from "../../../hooks/mediaQuery";
import { AuthContext } from "../../../context/authContext";

interface jobFormInterface {
  job_unique_id: string;
  company_name: string;
  job_title: string;
  job_description: string;
  total_experince: string;
  start_date: string;
  status: string;
  mandatory_skills: skillInterface[];
  additional_skills: skillInterface[];
}

const defaultjobForm: jobFormInterface = {
  job_unique_id: "",
  company_name: "",
  job_title: "",
  job_description: "",
  total_experince: "",
  start_date: "",
  status: "1",
  mandatory_skills: [],
  additional_skills: [],
};

interface skillInterface {
  label: string;
  value: string;
}

interface cardInterface {
  additional_skills: { skill_name: string; skill_id: string }[];
  company_name: string;
  created_by: string;
  created_on: string;
  job_description: string;
  job_title: string;
  job_unique_id: string;
  mandatory_skills: { skill_name: string; skill_id: string }[];
  start_date: string;
  status: string;
  total_experince: string;
}

// match screen
const CRMMatchesJobs = () => {
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [showToast, setShowToast] = React.useState(false);
  const [cardData, setCardData] = React.useState<cardInterface[]>();
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const [modelOpen, setModel] = React.useState(false);
  const [jngskills, setJngskills] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [editDatas, setEditDatas] =
    React.useState<jobFormInterface>(defaultjobForm);
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  const [formtype, setFormType] = React.useState("add");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);

  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);

  const getJobList = (page) => {
    setShowLoading(true);
    setCardData([]);
    setTotalCount(0);
    setTotalPageItem(0);
    GetCRMJobList(page).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setTotalPageItem(e.data !== undefined ? e.data.length : 0);
        // setJngskills();
        setTotalCount(e.totalItemsCount);
        setJngskills(
          e &&
            e.jng_skills !== undefined &&
            arrayOptionData(e.jng_skills, "skill_name", "skill_id")
        );
        const datas = e.data !== undefined && e.data;
        setCardData(datas !== undefined ? datas : undefined);
        setShowLoading(false);
      } else {
        setJngskills(
          e &&
            e.jng_skills !== undefined &&
            arrayOptionData(e.jng_skills, "skill_name", "skill_id")
        );
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };

  const editFormDetails = (data) => {
    if (data) {
      setEditDatas(data);
      setFormType("Update");
      setModel(true);
    }
  };

  const deleteDetail = (id) => {
    if (id) {
      setShowLoading(true);
      deletecrm(id).then((e) => {
        if (e !== undefined && e.status === 200) {
          setToastColor("success");
          setToastMessage(e.message);
          getJobList(currentPage);
          setShowLoading(false);
          setShowToast(true);
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(e !== undefined ? e.message : "please try again");
          setShowToast(true);
        }
      });
    }
  };

  // pagination
  const handlePageClick = (e) => {
    setCurrentPage(e);
    getJobList({ page: e });
  };

  // life cycle
  React.useEffect(() => {
    let isMounted = true;
    try {
      getJobList(1);
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      // isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
        <div className={m4kQuery ? "container Jobs " : "Jobs"}>
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="10" sizeSm="9" sizeXs="6">
                <h1 className="mt-0">CRM Jobs</h1>
              </IonCol>
              {userdata.user_type !== CRMRecuter && (
                <IonCol sizeLg="2" sizeSm="3" sizeXs="6">
                  <IonButton
                    type="button"
                    shape="round"
                    fill="solid"
                    className="next-btn remove-shadow capitalizes "
                    onClick={(e) => {
                      setEditDatas(defaultjobForm);
                      setFormType("add");
                      setModel(true);
                    }}
                  >
                    Post Job
                  </IonButton>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
          {!showLoading && (
            <>
              <IonGrid>
                <IonRow>
                  <IonCol size="12">
                    <div className="header-content-flex ion-justify-content-between ion-align-items-center filter-option custom-sort-section">
                      <IonLabel>
                        Showing{" "}
                        {totalCount !== 0
                          ? currentPage === 1
                            ? 1
                            : (currentPage - 1) * perPage + 1
                          : 0}
                        {" - "}
                        {currentPage === 1
                          ? totalPageItem
                          : (currentPage - 1) * perPage + totalPageItem >
                            totalCount
                          ? totalCount
                          : (currentPage - 1) * perPage + totalPageItem}
                        {" of " + totalCount}
                      </IonLabel>
                      <div className="sort-input-section ion-align-items-center">
                        {/* <div className="search-input">
            <IonInput
              value={searchKey}
              placeholder="Search open jobs"
              onIonChange={(e: any) => setSearchKey(e.detail.value)}
            />

            <div className="search-icon" onClick={() => searchData()}>
              <img src="assets/image/company/search.svg" />
            </div>
          </div> */}
                        {/* <div className="input-group sort-group">
            <span className="input-group-text" id="basic-addon1">
              Sort:
            </span>
            <select className="form-select sort-input">
              <option value="desc">Latest</option>
              <option value="asc">A-Z</option>
            </select>
          </div> */}
                        {/* <div
                    className="filter-icon"
                    onClick={(e) => {
                      setFiltermodelOpen(true);
                    }}
                  >
                    <img alt="" src="assets/image/company/tune.svg" />
                  </div> */}
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {/* list card */}
              <ListJobCardData
                cardData={cardData}
                editFormDetail={(data) => editFormDetails(data)}
                deleteDetail={deleteDetail}
              />
              {/* end card */}
              {/* pagination */}
              <div className="m-auto py-3">
                {totalCount > 30 && (
                  <PaginationScreen
                    activePageData={currentPage}
                    itemsCountPerPageData={perPage}
                    totalItemsCountData={totalCount}
                    pageRangeDisplayedData={8}
                    onChangeData={(e: any) => handlePageClick(e)}
                  />
                )}
              </div>
              {/* end pagination */}
              {totalCount === 0 && (
                <div className="nojob-section text-center">
                  <img
                    className="py-3"
                    alt="jng"
                    src="assets/image/job_backGround.svg"
                  />
                  <h1>No jobs to show!</h1>
                  <p>
                    List job opportunities with your organization for FREE or
                    engage our whiteglove services to hire the best talent out
                    there.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        <ModalBody
          title={formtype === "add" ? "Post a new job" : "Edit job"}
          isOpen={modelOpen}
          onClose={() => {
            getJobList(currentPage);
            setModel(false);
          }}
        >
          <PostJob
            id={""}
            skillsOption={jngskills}
            formType={formtype}
            closeModal={() => {
              getJobList(currentPage);
              setModel(false);
            }}
            editDatas={editDatas}
          />
        </ModalBody>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={toastColor}
        />
      </IonContent>
    </IonPage>
  );
};

export default React.memo(CRMMatchesJobs);

export const ListJobCardData = ({ cardData, editFormDetail, deleteDetail }) => {
  const history = useHistory();
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  // redirect to detail screen
  const detailPageClick = (data) => {
    if (data !== undefined) {
      history.push("/crm/matchcanditate", data);
    }
  };
  const editForm = (e, j) => {
    if (e) {
      j.start_date = j.start_date ? new Date(j.start_date) : "";
      j.mandatory_skills = arrayOptionData(
        j.mandatory_skills,
        "skill_name",
        "skill_id"
      );
      j.additional_skills = arrayOptionData(
        j.additional_skills,
        "skill_name",
        "skill_id"
      );
      editFormDetail(j);
    }
  };

  return (
    <>
      {cardData !== undefined &&
        cardData.length > 0 &&
        cardData.map((j, indx: number) => (
          <IonCard key={indx} className="mt-3 ion-padding cursor">
            <IonGrid>
              <IonRow>
                <IonCol sizeLg="12" sizeXs="12">
                  {userdata.user_type !== CRMRecuter && (
                    <>
                      <IonBadge
                        onClick={(e) => editForm(e, j)}
                        color="secondary"
                        className="card-edit-position"
                        style={{ position: "absolute", right: 0, top: "-12px" }}
                      >
                        edit
                      </IonBadge>
                      <IonBadge
                        onClick={(e) => deleteDetail(j.job_unique_id)}
                        color="primary"
                        className="card-edit-position"
                        style={{
                          position: "absolute",
                          right: "50px",
                          top: "-12px",
                        }}
                      >
                        delete
                      </IonBadge>
                    </>
                  )}
                  <div onClick={(e) => detailPageClick(j)}>
                    <h3 className="my-1">{j.job_title}</h3>

                    <small className="text-gray">{j.company_name} </small>
                    <small className="text-gray">
                      on {moment(j.created_on).format("lll")}
                    </small>
                  </div>

                  <div
                    className="com-custom-grid"
                    onClick={(e) => detailPageClick(j)}
                  >
                    <div className="com-col-section">
                      <div>
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/business_center.svg"
                          name="Exp"
                        />

                        <small>Exp.</small>
                      </div>
                      <label>{j.total_experince + " y"}</label>
                    </div>

                    <div className="com-col-section">
                      <div>
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/event_available.svg"
                          name="Start"
                        />

                        <small>Start</small>
                      </div>
                      <label>{FullDateStringMap(j.start_date)}</label>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        ))}
    </>
  );
};
