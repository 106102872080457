import cookie from "react-cookies";

export interface ICookie {
  key: string;
  value?: any;
}

export const onSave = (cookieData: ICookie) => {
  // const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 14);
  // expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 14);
  cookie.save(cookieData.key, cookieData.value, {
    path: "/",
  });
};

export const getCookie = (cookieData: ICookie): ICookie => {
  cookieData.value = cookie.load(cookieData.key);
  //patch for staff login to permit edit
  if (cookieData.value && cookieData.value.ctr_id === "") {
    if (getCtrIdForStaffLogin() !== "") {
      cookieData.value.ctr_id = getCtrIdForStaffLogin();
    }
  }

  return cookieData;
};

// check token
export const onCheck = () => {
  if (getCookie({ key: "token" }).value) {
    return true;
  }
  return false;
};

// result
export const userResult = () => {
  if (getCookie({ key: "user" }).value) {
    return getCookie({ key: "user" }).value;
  }
  return null;
};

export const onDelete = (cookieData: ICookie) => {
  return cookie.remove(cookieData.key, {
    path: "/",
  });
};

export const onCheckRemove = () => {
  onDelete({ key: "user" });
  onDelete({ key: "uid" });
  onDelete({ key: "token" });
  onDelete({ key: "userName" });
  onDelete({ key: "ctrid" });
  onDelete({ key: "userData" });
};

export const onCheckUserRemove = () => {
  // onDelete({ key: "user" });
  // onDelete({ key: "uid" });
  // onDelete({ key: "ctrid" });
  // onDelete({ key: "userData" });
};

export const getCtrIdForStaffLogin = () => {
  return getCookie({ key: "ctrid" }).value;
};
