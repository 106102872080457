import { IonCard, IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";
import { getCookie, addDefaultSrc, SeverfilePaths } from "../../service";
import { AuthContext } from "../../context/authContext";

const Navbar: React.FC = () => {
  const { auth, DeikaaFun } = React.useContext(AuthContext);
  const User = auth.data;
  const navBar = auth.navList;
  return (
    <div className='top-Nav' style={{ display: "contents" }}>
      <ul className='nav-ul'>
        {User ? (
          navBar.length > 0 &&
          navBar.map(
            (ty: any, index: number) =>
              ty.User === User.user_type &&
              ty.page.map((nav: any, i: number) =>
                nav.dropdown !== "true" ? (
                  <li key={i}>
                    <a key={i} className='custom-style-button remove-shadow capitalizes' href={nav.url}>
                      {nav.pageName}{" "}
                    </a>
                  </li>
                ) : nav.catMenu !== "profileimage" ? (
                  <li key={i}>
                    <a key={i} className='custom-style-button remove-shadow capitalizes'>
                      {nav.pageName}
                    </a>
                    <ul className='dropdown'>
                      {nav.childern.map((nav: any) => (
                        <li>
                          <a href={nav.childUrl}>{nav.childName}</a>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li key={i}>
                    <img
                      alt=''
                      style={{ maxWidth: "44px", borderRadius: 50 }}
                      onError={addDefaultSrc}
                      src={SeverfilePaths(User.folder_key) + User.profile_photo}
                    />
                    <ul className='dropdown profile'>
                      {nav.childern.map((nav: any, ind: number) =>
                        nav.childName === "user" ? (
                          User && (
                            <li key={ind}>
                              <a>
                                <IonLabel className='ion-text-wrap'>
                                  <h6>{User.firstname + " " + User.lastname}</h6>
                                  <p>{User.username}</p>
                                </IonLabel>
                              </a>
                            </li>
                          )
                        ) : (
                          <li key={ind}>
                            <a href={nav.childUrl}>{nav.childName}</a>
                          </li>
                        ),
                      )}
                    </ul>
                  </li>
                ),
              ),
          )
        ) : (
          <>
            <li>
              <a className="btn btn-default border-0" href={"/listjob"}>Jobs</a>
            </li>
            <li>
              <a className="btn btn-default border-0" href={"/list-candidates"}>Candidates</a>
            </li>
            <li>
              <a className="btn btn-default border-0" href={"/login"}>SignIn </a>
            </li>
          </>
        )}

        {/* <PopoverMenu
        events={popoverState}
        subMenu={currentSubPage}
        closeEvent={(e: any) => {
          setShowPopover({ showPopover: false, event: undefined });
        }}
      /> */}
      </ul>
    </div>
  );
};

export default Navbar;

const PopoverMenu = ({ events, closeEvent, subMenu }: any) => {
  const { auth } = React.useContext(AuthContext);
  const User = auth.data;
  // const User = getCookie({ key: "user" }).value;
  return (
    <div onMouseLeave={closeEvent}>
      {/* <IonPopover
        cssClass="custom-nav-popover"
        animated={true}
        showBackdrop={false}
        translucent={true}
        event={events.event}
        mode="ios"
        isOpen={events.showPopover}
        onDidDismiss={(e: any) =>
          closeEvent({ showPopover: false, event: undefined })
        }
      > */}
      <IonCard>
        <IonList lines='none' className='ion-padding'>
          {subMenu.map((nav: any) =>
            nav.childName === "user" ? (
              User && (
                <IonItem routerLink={nav.childUrl}>
                  <IonLabel className='ion-text-wrap'>
                    <h6>{User.firstname + " " + User.lastname}</h6>
                    <p>{User.username}</p>
                  </IonLabel>
                </IonItem>
              )
            ) : (
              <IonItem routerLink={nav.childUrl}>
                <IonLabel>{nav.childName}</IonLabel>
              </IonItem>
            ),
          )}
        </IonList>
      </IonCard>
      {/* </IonPopover> */}
    </div>
  );
};
