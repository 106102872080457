import React from "react";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonButton,
  IonItem,
  IonLoading,
  IonToast,
  IonCard,
  IonAlert,
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { Modal } from "../../component";
import Addtrmmodal from "../forms/Addtrmmodal";
import { getMethod } from "../../service";
import swal from "sweetalert";
import DataTableComponent from "../../component/DataTable/dataTable";

interface Props
  extends RouteComponentProps<{
    companyID?: string;
  }> {}

export interface ICompanyDetails {
  com_id: string;
  firstname: string;
  lastname: string;
  email: string;
  contract_name?: IContractName[];
}

export interface IContractName {
  contract_name: string;
}

class JngTrm extends React.Component {
  state = {
    userData: {
      role_id: "4",
    },
    getjngUserRole: [],
    showModal: false,
    showToast: false,
    toastMessage: "",
    toastColor: "danger",
    showLoading: false,
    comments: [],
    totalItems: 0,
    pageCount: 0,
    tableData: [],
    offset: 0,
    perPage: 10,
    currentPage: -1,
    search: "",
    docmodel: false,
    sorting: { field: "", order: "" },
    headerchange: "add",
    showAlert: false,
    recordToDelete: "",
    companyData: [],
    selectedFile: "",
    companyIData: "",
    companydocdata: [],
    trmtabledata: [],
    trmcomments: [],
    getUserRole: [],
    showHeaderType: true,
    showValue: [],
    errormsg: "",
    deletetrmid: "",
    deleteAlert: false,
  };

  column = [
    { name: "S.No", field: "id", sortable: false, value: "id" },
    {
      name: "Name",
      field: "fullName",
      sortable: false,
      value: "fullName",
      wordsplit: "",
      validate: "",
    },
    {
      name: "Email",
      field: "email",
      sortable: false,
      value: "email",
      wordsplit: "",
    },
    {
      name: "Action",
      field: "",
      sortable: false,
      value: "edit:delete",
      wordsplit: " ",
    },
  ];

  trmsetShowModals = () => {
    this.trmGetCU();
    this.setState({
      showModal: false,
    });
  };

  trmGetCU = () => {
    this.setState({
      showLoading: true,
    });
    setTimeout(() => {
      const FetchData = getMethod("/Getalltrmuser");
      FetchData.then((value: any) => {
       
        if (value !== undefined && value.status === 200) {
          const finalData = value.data.map(item => ({
            ...item,
            fullName: `${item.firstname} ${item.lastname}` || "",
          }))
          var slice = finalData.slice(this.state.offset, this.state.perPage);
          this.setState({
            showLoading: false,
            trmcomments: slice,
            trmtabledata: finalData,
            pageCount: finalData.length,
          });
        } else if (value.status === 201) {
          this.setState({
            showLoading: false,
            trmcomments: [],
            pageCount: 0,
          });
        }
      });
    }, 100);
  };

  trmGetCR = () => {
    setTimeout(() => {
      const FetchData = getMethod("Getjngroles/");
      FetchData.then((value: any) => {
        if (value.data !== undefined && value.status === 200) {
          this.setState({
            getUserRole: value.data,
          });
        } else {
        }
      });
    }, 100);
  };

  handlePageChange = (e) => {
    const selectedPage = e;
    const num = (selectedPage - 1) * this.state.perPage + 10;
    this.setState({
      currentPage: selectedPage,
    });
    const data = this.state.trmtabledata;
    const slice = data.slice(this.state.perPage * (selectedPage - 1), num);
    this.setState({
      trmcomments: slice,
    });
  };

  trmonSearch = (value) => {
    let computedlegalcomments = this.state.trmtabledata;
    if (value) {
      const computedComment = computedlegalcomments.filter(
        (comment: any) =>
          comment.fullName.toLowerCase().includes(value.toLowerCase()) ||
          comment.email.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({
        pageCount: computedComment.length,
        trmcomments: computedComment,
      });
    } else {
      this.setState({
        offset: 0,
        pageCount: 1,
      });
      var slice = computedlegalcomments.slice(
        this.state.offset,
        this.state.perPage
      );
      this.setState({
        pageCount: computedlegalcomments.length,
        trmcomments: slice,
      });
    }
  };

  trmedit = (data: any) => {
    this.setState({
      showModal: true,
      showHeaderType: false,
      showValue: data,
    });
  };

  trmsetHeaderTypes = () => {
    this.setState({
      showHeaderType: true,
    });
    this.trmGetCU();
  };

  trmremoveUser = (da) => {
    if (da) {
      this.setState({
        showLoading: true,
      });
      const FetchData = getMethod("/Deletetrmrole/" + da);
      FetchData.then((value: any) => {
        if (value !== undefined && value.status === 200) {
          this.setState({
            deletetrmid: "",
            showLoading: false,
          });
          this.trmGetCU();
        } else {
          this.setState({
            deletetrmid: "",
            showLoading: false,
            toastColor: "danger",
            toastMessage: value !== undefined ? value.message : 'please try again',
            showToast: true,
          });
        }
      });
    }
  };

  renderAction = (type, item) => {
    switch (type) {
      case "delete":
        this.setState({
          deletetrmid: item.uid,
          deleteAlert: true,
        });
        return;
      case "edit":
        this.trmedit(item);
        return;
      default:
        return;
    }
  };

  componentDidMount() {
    this.trmGetCU();
    this.trmGetCR();
  }
  render() {
    return (
      <>
        <IonToast
          isOpen={this.state.showToast}
          onDidDismiss={() =>
            this.setState({
              showToast: false,
            })
          }
          message={this.state.toastMessage}
          duration={5000}
          color={this.state.toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={this.state.showLoading}
          onDidDismiss={() =>
            this.setState({
              showLoading: false,
            })
          }
        />

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonItem lines="none">
                <h3>All Users</h3>

                <IonButton
                  className="custom-button btn-margin-left"
                  color="secondary capitalize"
                  shape="round"
                  fill="solid"
                  onClick={() =>
                    this.setState({
                      showModal: true,
                    })
                  }
                >
                  Create Users
                </IonButton>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonCard className="ion-padding">
                <DataTableComponent
                  tableheaders={this.column}
                  tableData={this.state.trmcomments}
                  currentPage={this.state.currentPage}
                  itemPerPage={this.state.perPage}
                  totalItem={this.state.pageCount}
                  searchInput={true}
                  searchFunction={(search) => this.trmonSearch(search)}
                  handleAction={(type, item) => {
                    this.renderAction(type, item);
                  }}
                  setCurrentPage={(page) => this.handlePageChange(page)}
                />
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <Modal showModal={this.state.showModal}>
          <div className="custom-modal">
            <Addtrmmodal
              setShowModal={this.trmsetShowModals}
              headerType={this.state.showHeaderType}
              companyUserRole={this.state.getUserRole}
              setHeaderType={this.trmsetHeaderTypes}
              editData={this.state.showValue}
            />
          </div>
        </Modal>
        <IonAlert
          isOpen={this.state.deleteAlert}
          mode={"ios"}
          header={"Continue to delete the Trm"}
          subHeader={"Confirm here"}
          message={""}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                this.setState({
                  deletetrmid: "",
                  deleteAlert: false,
                });
              },
            },
            {
              text: "Okay",
              handler: () => {
                this.trmremoveUser(this.state.deletetrmid);
                this.setState({
                  deleteAlert: false,
                });
              },
            },
          ]}
        />
      </>
    );
  }
}

export default React.memo(JngTrm);
