import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonRow,
} from "@ionic/react";
import moment from "moment";
import React from "react";
import { RouteComponentProps } from "react-router";
import { HeaderNav } from "../../../component";
import useMediaQuery from "../../../hooks/mediaQuery";
import { getIndexValue, StringMap } from "../../../service";
import { JobstatushistoriyData } from "../Service/api";

const Applicationview = (props: RouteComponentProps) => {
  const [showLoading, setshowLoading] = React.useState(false);
  const [locationData, setLocationData] = React.useState<any>(
    props.location.state
  );
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [appData, setAppData] = React.useState<any>();
  React.useEffect(() => {
    let isMounted = true;
    setshowLoading(true);
    try {
      getJobTimeline();
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
      }
    } finally {
      isMounted && setshowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const getJobTimeline = () => {
    setshowLoading(true);
    const result = {
      unique_id: locationData.unique_id,
      ctr_id: locationData.ctr_id,
      com_id: locationData.com_id,
    };
    JobstatushistoriyData(result)
      .then((value) => {
        if (value !== undefined && value.status === 200) {
          setAppData(value.data);
          setshowLoading(false);
          setToastColor("success");
          setToastMessage(value.message);
          setShowToast(true);
        } else {
          setshowLoading(false);
          setToastColor("danger");
          setToastMessage(
            value !== undefined ? value.message : "please try again later"
          );
          setShowToast(true);
        }
      })
      .catch((err) => {
        setshowLoading(false);
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
      });
  };

  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setshowLoading(false)}
        />
        <div
          className={m4kQuery ? "container Jobs px-4 py-3" : "Jobs px-4 py-3"}
        >
          <IonCard className="ion-padding">
            <div className="com-header-details">
              <h2>{StringMap(locationData.job_title)}</h2>
              <p className="m-0">
                {StringMap(locationData.work_location)}
                {getIndexValue(
                  locationData.wfh_type,
                  locationData.workFromhomeType,
                  "value",
                  "label"
                ) &&
                  " (" +
                    getIndexValue(
                      locationData.wfh_type,
                      locationData.workFromhomeType,
                      "value",
                      "label"
                    ) +
                    ")"}
              </p>
              <small className="text-gray">
                JID: {StringMap(locationData.com_id).slice(0, 10)} | Posted by{" "}
              </small>{" "}
              <small>
                {StringMap(locationData.company_firstname)}
                {StringMap(locationData.company_lastname)}
              </small>{" "}
              <small className="text-gray">
                on {moment(locationData.job_created_on).format("lll") + " |"}{" "}
                Department: <small>{StringMap(locationData.department)}</small>{" "}
                | Hiring Manager:{" "}
                <small>{StringMap(locationData.hiring_manager)}</small>
              </small>
            </div>
            <div className="custome-timeline">
              <ul className="timeline">
                {appData
                  .sort((a, b) => b.log_time - a.log_time)
                  .map((item: any, i: number) => (
                    <li key={i}>
                      <IonGrid className="ion-no-padding">
                        <IonRow>
                          <IonCol sizeXs="12" sizeLg="12">
                            <h5 className="mt-0">{item.job_status_type}</h5>
                            <p className="text-gray">
                              {moment(item.log_time).format("LL")}
                            </p>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </li>
                  ))}
              </ul>
            </div>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Applicationview);
