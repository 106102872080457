import { useParams } from "react-router";
import React from "react";
import {
  IonPage,
  IonItem,
  IonLabel,
  IonContent,
  IonLoading,
  IonToast,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonCard,
  IonAlert,
  IonAvatar,
  IonBadge,
} from "@ionic/react";
import { chatbubble, heart, heartOutline } from "ionicons/icons";
import { SeverfilePaths, StringMap } from "../../service";
import { ChatMessageBox, HeaderNav, PaginationScreen } from "../../component";
import {
  addDefaultjobSrc,
  Contractor,
  EXPStringMap,
  FullDateStringMap,
  getIndexValue,
} from "../../service/default";
import { GetMatchjobs } from "../Contractor/Service/api";
import moment from "moment";
import { ChatLayout } from "..";
import { AuthContext } from "../../context/authContext";
import useMediaQuery from "../../hooks/mediaQuery";
import { SeverfilePathswithoutEncrypt } from "../../service/axios";

const ContractorMatchJob = ({ history }: any) => {
  const match = useParams();
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);

  //   search / sort / filter
  const [searchKey, setSearchKey] = React.useState("");
  const [sortBy, setSortBy] = React.useState("desc");

  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);

  const [tableData, setTableData] = React.useState([]);
  const [totalJobcount, setJobCount] = React.useState(0);
  const [workfromhometype, setWorkfromhometype] = React.useState<any>([]);

  const searchData = (e: any) => {
    if (e) {
      getMatched("1", sortBy);
    }
  };
  React.useEffect(() => {
    let isMounted = true;
    setShowLoading(true);
    try {
      getMatched("1", sortBy);
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
        setTableData([]);
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  // get Matched api call
  const getMatched = (page: any, sortby: any) => {
    const result = {
      ctr_id: match["Jid"],
      page: page,
      order_by: sortby,
      search_by: searchKey,
    };
    setShowLoading(true);
    GetMatchjobs(result).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setTableData(e.data !== undefined ? e.data : []);
        setJobCount(e.matching_jobs);
        setTotalPageItem(e.data !== undefined ? e.data.length : "0");
        setTotalCount(e.totalItemsCount);
        setWorkfromhometype(e.workfromhome_type);
        setShowLoading(false);
      } else {
        setTableData([]);
        setTotalPageItem(0);
        setTotalCount(0);
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(e !== undefined ? e.message : "please try again");
        setShowToast(true);
      }
    });
  };

  const detailPage = (e: any, opt: any) => {
    if (e) {
      const name = e.com_id
        ? e.com_id
        : e.duration_type
        ? e.duration_type
        : ".";
      history.push("/Job/" + name.slice(0, 10), {
        unique_id: e.unique_id,
        workFromhomeType: opt,
        screenName: "modelscreen",
      });
    }
  };

  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <div className={m4kQuery ? "container mt-3" : "mt-3"}>
          <div className="px-lg-2">
            <h4>Matching jobs ({totalCount})</h4>
            <p className="py-0 text-gray">List Of Contractor Match Jobs </p>
          </div>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={5000}
            color={toastColor}
          />
          <IonGrid>
            <IonRow>
              {tableData.length > 0 && (
                <IonCol size="12">
                  <div className="header-content-flex">
                    <div className="my-2">
                      Showing{" "}
                      {currentPage === 1 ? 1 : (currentPage - 1) * perPage + 1}
                      {" - "}
                      {currentPage === 1
                        ? totalPageItem
                        : (currentPage - 1) * perPage + totalPageItem >
                          totalCount
                        ? totalCount
                        : (currentPage - 1) * perPage + totalPageItem}
                      {" of " + totalCount}
                    </div>
                  </div>
                </IonCol>
              )}
              {!showLoading
                ? tableData.map((cand: any, index: number) => (
                    <IonCol sizeLg="12" sizeXs="12" key={index}>
                      <UserCard
                        cardData={cand}
                        page="Match"
                        workfromhometypeOption={workfromhometype}
                        favicon={false}
                        tag={false}
                        chatIcon={false}
                        jobdetail={(e) => detailPage(e, workfromhometype)}
                      />
                    </IonCol>
                  ))
                : "Loading ..."}

              {!showLoading && tableData.length === 0 && (
                <IonCol size="12">No Matching job found </IonCol>
              )}
              <IonCol size="12">
                {totalCount > 30 && (
                  <PaginationScreen
                    activePageData={currentPage}
                    itemsCountPerPageData={perPage}
                    totalItemsCountData={totalCount}
                    pageRangeDisplayedData={8}
                    onChangeData={(e: any) => {
                      setCurrentPage(e);
                      getMatched(e, sortBy);
                    }}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
      </IonContent>
    </IonPage>
  );
};

// job card

const UserCard = ({
  jobdetail,
  cardData,
  tag,
  chatIcon = false,
  workfromhometypeOption,
  favicon,
  moreIcon,
  moreUpdateresult,
  invideCheck,
  favclick,
  router,
}: any) => {
  const { auth } = React.useContext(AuthContext);
  const user = auth.data;
  const usertype = user ? user.user_type : "";
  // const user = getCookie({ key: "user" }).value;
  const [showAlert, setShowAlert] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [btntype, setBtntype] = React.useState<any>();
  const [chatBox, setChatBox] = React.useState(false);
  const [chatBoxData, setChatBoxData] = React.useState({});
  return (
    <div>
      <ChatMessageBox
        showModal={chatBox}
        setShowModal={() => setChatBox(false)}
      >
        <ChatLayout
          closeAction={(e: any) => setChatBox(false)}
          data={chatBoxData}
        ></ChatLayout>
      </ChatMessageBox>
      {/* <IonModal isOpen={chatBox} className="chat-model">
        <ChatLayout
          closeAction={(e: any) => setChatBox(false)}
          data={chatBoxData}
        ></ChatLayout>
      </IonModal> */}
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={"Continue to " + btntype + " the contractor?"}
        subHeader={"Confirm here"}
        message={""}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert(false);
              setData(null);
              setBtntype(null);
            },
          },
        ]}
      />
      <IonCard className="ion-padding">
        {tag && cardData.closed === 1 && (
          <div className="heighlite-tag">
            <small>closed</small>
          </div>
        )}

        <div className="review-chip">
          {/* <div className="rating">
            <img src="assets/image/company/star.svg" />
            <span>4.2/5 (10 reviews)</span>
          </div> */}
          {moreIcon && (
            <>
              <div
                className="rating cursor"
                onClick={(e) => {
                  !invideCheck
                    ? moreUpdateresult(cardData, 2)
                    : moreUpdateresult(cardData, 1);
                }}
              >
                <span>{!invideCheck ? "Ignore" : "Undo"}</span>
              </div>

              <div
                className="red-content cursor"
                onClick={(e) => moreUpdateresult(cardData, 0)}
              >
                <span>Remove</span>
              </div>
            </>
          )}
          {chatIcon && [Contractor].includes(usertype) && (
            <IonIcon
              icon={chatbubble}
              size="small"
              mode="ios"
              color="orange"
              slot="icon-only"
              onClick={(e) => {
                setChatBoxData({
                  ctrdata: user.ctr_id,
                  unique_id: cardData.unique_id,
                  folder_key: cardData.folder_key,
                  profile_photo: cardData.profile_photo,
                  type: "notencrypt"
                });
                setChatBox(true);
              }}
            />
          )}

          {favicon && (
            <div className="fav">
              <IonIcon
                icon={cardData.favorite_status ? heart : heartOutline}
                size="small"
                mode="ios"
                color={cardData.favorite_status ? "danger" : " "}
                slot="icon-only"
                onClick={(e) =>
                  favclick(cardData.favorite_status, cardData.unique_id)
                }
              />
            </div>
          )}
        </div>

        <IonItem lines="none">
          <IonAvatar slot="start">
            <img
              alt=""
              onError={addDefaultjobSrc}
              src={
                SeverfilePathswithoutEncrypt(cardData.folder_key) +
                cardData.profile_photo
              }
            />
          </IonAvatar>
          <IonLabel
            className="cursor"
            onClick={(e) => {
              if (!tag && cardData.closed !== 1) {
                jobdetail(cardData);
              }
            }}
          >
            <span>{StringMap(cardData.job_title)}</span>
            <div>
              <p className="m-0">
                {StringMap(cardData.work_location)}
                {getIndexValue(
                  cardData.wfh_type,
                  workfromhometypeOption,
                  "value",
                  "label"
                ) &&
                  " (" +
                    getIndexValue(
                      cardData.wfh_type,
                      workfromhometypeOption,
                      "value",
                      "label"
                    ) +
                    ")"}
              </p>
              <small className="text-gray">
                {" "}
                JID: {StringMap(cardData.com_pjid) + " "}| Posted by{" "}
              </small>{" "}
              <small>
                {" "}
                {StringMap(cardData.posted_by ?? cardData.company_name)}{" "}
              </small>{" "}
              <small className="text-gray">
                on {moment(cardData.created_on).format("lll")}
              </small>
            </div>
          </IonLabel>
          <IonBadge slot="end" color="light">
            <small className="text-gray">
              {moment(cardData.created_on).startOf("hour").fromNow()}
            </small>
          </IonBadge>
        </IonItem>
        <div className="com-custom-grid pt-0">
          <div className=" com-col-section remove-p">
            <div>
              <img alt="" src="assets/image/company/date_range.svg" />
              <small>Engagement</small>
            </div>
            <label>
              {cardData.duration_type === "0" ? "Contract" : "Permanent"}
            </label>
          </div>
          <div className="com-col-section">
            <div>
              <img alt="" src="assets/image/company/business_center.svg" />
              <small>Exp.</small>
            </div>
            <label>{EXPStringMap(cardData.overall_exp) + " y"}</label>
          </div>
          <div className="com-col-section">
            <div>
              <img alt="" src="assets/image/company/monetization_on.svg" />
              <small>Budget</small>
            </div>
            <label>
              {"₹ " + cardData.payment}
              {cardData.duration_type === "0" ? " per hour" : " per year"}
            </label>
          </div>
          <div className="com-col-section">
            <div>
              <img alt="" src="assets/image/company/event_available.svg" />
              <small>Start</small>
            </div>
            <label>{FullDateStringMap(cardData.start_date)}</label>
          </div>
          {cardData.duration_type === "0" && (
            <div
              className="com-col-section"
              style={{ borderRightStyle: "none" }}
            >
              <div>
                <img alt="" src="assets/image/company/event_busy.svg" />
                <small>End</small>
              </div>
              <label>{FullDateStringMap(cardData.end_date)}</label>
            </div>
          )}
        </div>
      </IonCard>
    </div>
  );
};

export default React.memo(ContractorMatchJob);
