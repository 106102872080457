import React from "react";
import { IonContent, IonPage } from "@ionic/react";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router";
import { CompanyProfile, CompanyUser } from "..";
import { HeaderNav } from "../../component";
import CompanyListjob from "../staff/Staff_User/companyjobs";

export default function Company(Props) {
  const [value, setValue] = React.useState("info");
  const location = useParams();
  const handleChange = (event) => {
    setValue(event);
    // history.push("/Compamy/" + newValue);
  };
  return (
    <>
      <IonPage>
        <HeaderNav />
        <IonContent>
          <div className="main-container container">
            <div className="custom-container">
              <h1>Profile</h1>
            </div>

            <Tabs
              id="controlled-tab-custom"
              activeKey={value}
              onSelect={(k: any) => handleChange(k)}
              className="mb-3"
            >
              <Tab eventKey="info" title={"Company Info"}>
                {value === "info" && <CompanyProfile />}
              </Tab>
              <Tab eventKey="user" title={"Company User"}>
              {value === "user" &&  <CompanyUser props={location["companyID"]} />}
              </Tab>
              <Tab eventKey="jobs" title={"Company Jobs"}>
              {value === "jobs" && <CompanyListjob />}
              </Tab>
            </Tabs>
          </div>

          {/* <Footer /> */}
        </IonContent>
      </IonPage>
    </>
  );
}
