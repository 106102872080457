import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { RouteComponentProps } from "react-router";
import { Rating } from "react-simple-star-rating";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import { GetQuestionbyType, saveQuestion } from "../Service/api";
import { arrayfeedbackKeymatch, arrayKeymatch } from "../../../service";
import useMediaQuery from "../../../hooks/mediaQuery";

interface Props
  extends RouteComponentProps<{
    unique_id: any;
  }> {}
const ReferenceFeedback: React.FC<Props> = ({ history, match }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [Question, setQuestion] = React.useState<any>([]);
  const [showLoading, setshowLoading] = React.useState(false);
  const [update, setshowUpdate] = React.useState(false);
  const [captcha, setCaptchaValue] = React.useState<boolean>(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const CheckUrl = document.URL.startsWith("https://apps.jobsngigs.com");

  function onCaptchaValidation(value) {
    setCaptchaValue(true);
  }

  React.useEffect(() => {
    let isMounted = true;
    try {
      getquestion();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setshowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  function getquestion() {
    setshowUpdate(true);
    setshowLoading(true);
    GetQuestionbyType(match.params.unique_id).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        // const grouped = _.mapValues(_.groupBy(e.data, "question_group"));
        //   ,(clist) => clist.map((car) => _.omit(car, "question_group"))
        // );
        setQuestion(arrayKeymatch(e.data));
        setshowLoading(false);
        setshowUpdate(false);
      } else {
        setshowLoading(false);
      }
    });
  }

  function saveFeedback(data: any) {
    if (!captcha && CheckUrl) {
      setToastColor("danger");
      setToastMessage("Please verify captcha to continue..");
      setShowToast(true);
      return;
    }
    setshowLoading(true);
    const result = {
      unique_id: match.params.unique_id,
      response: arrayfeedbackKeymatch(
        data,
        "ctr_rqid",
        "rating_answer",
        "text_answer"
      ),
    };
    saveQuestion(result).then((r: any) => {
      if (!r) {
        setToastMessage("Unable to connect to server");
        setShowToast(true);
        setshowLoading(false);
        return;
      }
      if (r !== undefined && r.status === 200) {
        setToastColor("success");
        setToastMessage("Feedback send successfully");
        setShowToast(true);
        setshowLoading(false);
        history.replace("/login");
        reset(saveFeedback);
      } else {
        setToastColor("danger");
        setToastMessage(r.message);
        setShowToast(true);
        setshowLoading(false);
      }
    });
  }

  return (
    <IonPage>
      <IonContent>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setshowLoading(false)}
        />
        {!update ? (
          <div className={m4kQuery ? "feedback-form container": "feedback-form"}>
            <div className="feedback-img text-center">
              <img alt="" src="assets/image/hirify.png" />
              <h1>Reference Feedback</h1>
            </div>
            <IonGrid>
              <form onSubmit={handleSubmit(saveFeedback)}>
                <IonRow>
                  {Question !== undefined &&
                    Question.map((e: any, i: number) => (
                      <IonCol sizeLg="12" sizeXs="12" key={i} className="py-3">
                        <h4>{e.title}</h4>
                        <IonCard className="ion-padding">
                          {e.arr.map((child: any, i: number) => (
                            <Controller
                              control={control}
                              name={child.question_number}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <IonItem lines="none">
                                  <IonLabel className="ion-text-wrap">
                                    {child.question_number +
                                      ". " +
                                      child.question}
                                  </IonLabel>
                                  <Rating
                                    onClick={(e) =>
                                      setValue(child.question_number, e)
                                    }
                                    ratingValue={value} /* Rating Props */
                                  />
                                </IonItem>
                              )}
                              defaultValue={0}
                            />
                          ))}
                        </IonCard>
                      </IonCol>
                    ))}
                  {CheckUrl && (
                    <IonCol sizeLg="12" sizeXs="12" className="text-center">
                      <div className="pannel-ReCAPTCHA">
                        <ReCAPTCHA
                          className="pannel-ReCAPTCHA2"
                          sitekey="6Lf4sJwaAAAAAIPAY3EPHjNHuHC41QZkmEGxKg5d"
                          onChange={onCaptchaValidation}
                        />
                      </div>
                    </IonCol>
                  )}
                  <IonCol sizeLg="12" sizeXs="12" className="text-center">
                    <IonButton
                      shape="round"
                      fill="solid"
                      color="secondary"
                      className="remove-shadow capitalizes"
                      type="submit"
                    >
                      {showLoading ? "Loading..." : "Sent Feedback"}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </IonGrid>
          </div>
        ) : null}
 <IonGrid className={m4kQuery ? "container" : ""}>
          <IonRow>
            <IonCol sizeLg="6" sizeXs="12">
              <ul className="ulelement text-left">
                <li>Facing any difficulty?</li>
                <li className="modify-color">
                 
                  <a
                    href="/#contactus"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </IonCol>
            <IonCol sizeLg="6" sizeXs="12">
              <ul className="ulelement footer-rightSection text-right">
                <li className="small-text smallfont">
                  © 2024–present, Hirify. All rights reserved.
                </li>
                <li className="smallfont">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="/terms"
                  >
                    Terms of Use •
                  </a>{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(ReferenceFeedback);
