import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import React, { useEffect, useState } from "react";
import { savePractice } from "../../Service/api";
import { getIndexValue } from "../../../../service";
import useMediaQuery from "../../../../hooks/mediaQuery";

function PracticeForm(
  {
    ctrid,
    closeModal,
    modelClick,
    practiceDatas,
    sendUpdatedDataToMainPage,
    deleteStateValue,
    proficiencytypelist,
    ListofskillsOption,
  },
  any
) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert1, setShowAlert1] = React.useState(false);

  React.useEffect(() => {
    reset({ practice1: [], practice2: [] });
    setValue("practice1", [
      {
        value: getIndexValue(
          practiceDatas.practice1,
          ListofskillsOption,
          "label",
          "value"
        ),
        label: practiceDatas.practice1 ?? "",
      },
    ]);
    setValue("practice2", [
      {
        value: getIndexValue(
          practiceDatas.practice2,
          ListofskillsOption,
          "label",
          "value"
        ),
        label: practiceDatas.practice2 ?? "",
      },
    ]);
  }, [modelClick]);

  // edit skill

  // save skill
  const savePracticeFun = (values: any) => {
    setShowLoading(true);
    values.ctr_id = ctrid;
    values.practice1 = values.practice1[0].label ?? "";
    values.practice2 =
      values.practice2 && values.practice2.length !== 0
        ? values.practice2[0].label ?? ""
        : "";

    savePractice(values).then((value) => {
      if (value !== undefined && value.status === 200) {
        sendUpdatedDataToMainPage(values);
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
        setShowLoading(false);
        closeModal(false);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again"
        );
        setShowToast(true);
      }
    });
  };

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  // const showValueCount = (_fieldName: any) => {
  //   const data = watch(_fieldName);
  //   return data ? data.length : "0";
  // };

  return (
    <div className={m4kQuery ? "container px-lg-4 px-md-4": "px-lg-4 px-md-4"}>
      <div className="custom-header-model">
        <IonGrid>
          <form onSubmit={handleSubmit(savePracticeFun)}>
            <IonRow>
              <IonCol sizeLg="6" sizeXs="12">
                <div>
                  <IonLabel position="stacked">
                    Skill 1 <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    name="practice1"
                    rules={{
                      required: "Skill 1 is required",
                    }}
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Typeahead
                        allowNew
                        id="custom-selections-input"
                        newSelectionPrefix="Add a new skill: "
                        options={ListofskillsOption}
                        onChange={onChange}
                        selected={value}
                        placeholder="skill 1"
                        selectHintOnEnter={true}
                        onKeyDown={(event) => {
                          if (event.key === "Tab") {
                            setValue("practice1", [
                              {
                                value: getIndexValue(
                                  event.target.value,
                                  ListofskillsOption,
                                  "label",
                                  "value"
                                ),
                                label: event.target.value,
                              },
                            ]);
                          }
                        }}
                        onInputChange={(event, e) => {
                          if (event) {
                            setValue("practice1", [
                              {
                                value: getIndexValue(
                                  event,
                                  ListofskillsOption,
                                  "label",
                                  "value"
                                ),
                                label: event,
                              },
                            ]);
                          }
                        }}
                      />
                    )}
                  />
                </div>
                {showError("practice1")}
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <div>
                  <IonLabel position="stacked">Skill 2</IonLabel>
                  <Controller
                    control={control}
                    name="practice2"
                    render={({ field: { onChange, value, ref } }) => (
                      <Typeahead
                        allowNew
                        id="custom-selections-input"
                        newSelectionPrefix="Add a new skill: "
                        options={ListofskillsOption}
                        onChange={onChange}
                        selected={value}
                        placeholder="skill 2"
                        selectHintOnEnter={true}
                        onKeyDown={(event) => {
                          if (event.key === "Tab") {
                            setValue("practice2", [
                              {
                                value: getIndexValue(
                                  event.target.value,
                                  ListofskillsOption,
                                  "label",
                                  "value"
                                ),
                                label: event.target.value,
                              },
                            ]);
                          }
                        }}
                        onInputChange={(event, e) => {
                          if (event) {
                            setValue("practice2", [
                              {
                                value: getIndexValue(
                                  event,
                                  ListofskillsOption,
                                  "label",
                                  "value"
                                ),
                                label: event,
                              },
                            ]);
                          }
                        }}
                      />
                    )}
                  />
                </div>
                {showError("practice2")}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <div className="text-right">
                  <IonButton
                    fill="outline"
                    className="remove-shadow capitalizes"
                    color="secondary"
                    shape="round"
                    type="submit"
                  >
                    Save
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>

        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
        <IonAlert
          isOpen={showAlert1}
          mode={"ios"}
          header={"Are you sure?"}
          subHeader={"Continue to delete the Practice."}
          message={"Confirm here"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              cssClass: "secondary",
              handler: (blah) => {
                setShowAlert1(false);
              },
            },
            {
              text: "Okay",
              handler: () => {
                setShowAlert1(false);
              },
            },
          ]}
        />
      </div>
    </div>
  );
}

export default PracticeForm;
