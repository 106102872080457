import React from "react";

const ReadMore = ({ children, length }: any) => {
  const text = children;
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, (length ? length : 250)) : text}
      {text.length > (length ? length : 250) && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? " read more..." : " ...show less"}
        </span>
      )}
    </p>
  );
};

const ReadMoreRichText = ({ children, length, readmoreOption = true }: any) => {
  const [isReadMoreLength, setIsReadMoreLength] = React.useState(length);
  const [isReadMore, setIsReadMore] = React.useState(true);
  const text = children;

  // const toggleReadMore = () => {
  //   setIsReadMore(!isReadMore);
  // };

  function createMarkup() {
    return { __html: text && text.slice(0, isReadMoreLength) };
  }

  return (
    <>
      <div dangerouslySetInnerHTML={createMarkup()} />
      {text &&
        text.length > 250 &&
        (readmoreOption && isReadMore ? (
          <span
            onClick={() => {
              setIsReadMoreLength(text.length);
              setIsReadMore(!isReadMore);
            }}
            className="read-or-hide"
          >
            read more
          </span>
        ) : (
          readmoreOption && (
            <span
              onClick={() => {
                setIsReadMoreLength(length);
                setIsReadMore(!isReadMore);
              }}
              className="read-or-hide"
            >
              show less
            </span>
          )
        ))}
    </>
    // <p
    //   dangerouslySetInnerHTML={{
    //     __html: isReadMore ? text : children,
    //   }}
    //   className="text"
    // >
    //   {text.length > 250 && (
    //     <span onClick={toggleReadMore} className="read-or-hide">
    //       {isReadMore ? " read more..." : " ...show less"}
    //     </span>
    //   )}
    // </p>
  );
};

export { ReadMore, ReadMoreRichText };
