import {
  IonAlert,
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import {
  closeCircle,
  cloudUploadOutline,
  trash,
} from "ionicons/icons";
import React from "react";
import { saveAs } from "file-saver";
import {
  ModalBody,
} from "../../../component";
import { companyContractUpload, downloadFileWithGet } from "../../../service";
import {
  fContractuploaddeletecontract,
  fGetCompanycontract,
  getCompanyTableList,
} from "../Service/api";
import Viewcompany from "../../forms/viewcompanyform";
import { AuthContext } from "../../../context/authContext";
import { CRMRecuter, Staff } from "../../../service/default";
import DataTableComponent from "../../../component/DataTable/dataTable";

const CompanyTable: React.FC = () => {
  const { auth } = React.useContext(AuthContext);
  const CurrentUser = auth.data;
  const [search, setSearch] = React.useState("");
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showModal, setshowModal] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [totalItemCount, setTotalItemCount] = React.useState<number>(0);
  const [totalCountPerPage, setItemperPage] = React.useState<number>(30);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [tableData, setTableData] = React.useState([]);
  const [commands, setCommant] = React.useState<any>(null);
  const [headerchange, setHeaderchange] = React.useState("add");
  const [companydocdata, setCompanydocdata] = React.useState([]);
  const [docmodel, setDocmodel] = React.useState(false);
  const [companyIData, setcomid] = React.useState("");
  const [file, setfile] = React.useState<any>();
  const [showAlert, setAlert] = React.useState(false);

  const column = [
    { name: "S.No", field: "id", sortable: false, value: "id" },
    {
      name: "Name",
      field: "fullname",
      sortable: false,
      value: "fullname",
      wordsplit: "",
      validate: "",
    },
    {
      name: "Email",
      field: "email",
      sortable: false,
      value: "email",
      wordsplit: "",
    },
    {
      name: "Action",
      field: "",
      sortable: false,
      value: CurrentUser.user_type === CRMRecuter ? "edit:" : 'edit:search',
      wordsplit: " ",
    },
  ];

  const getData = () => {
    setSearch("");
    setShowLoading(true);
    getCompanyTableList("").then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setCurrentPage(1);
        setItemperPage(30);
        var slice = value.data.slice(0, totalCountPerPage);
        setTableData(value.data);
        setCommant(slice);
        setTotalItemCount(value.data.length);
        setShowLoading(false);
      } else {
        setTotalItemCount(0);
        setItemperPage(0);
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value.message);
        setShowToast(true);
      }
    });
  };

  // pagination
  const handlePageChange = (e) => {
    const selectedPage = e;
    const num = (selectedPage - 1) * 30 + 30;
    setCurrentPage(selectedPage);
    const data = tableData ? tableData : null;
    if (data) {
      const slice = data.slice(30 * (selectedPage - 1), num);
      setCommant(slice);
    } else {
      setCommant(null);
    }
  };

  //   search
  const onSearch = (value) => {
    let computedComments = tableData;
    if (value) {
      const computedComment = computedComments.filter(
        (comment: any) =>
          comment.fullname.toLowerCase().includes(value.toLowerCase()) ||
          comment.email.toLowerCase().includes(value.toLowerCase())
      );
      setCurrentPage(1);
      setCommant(computedComment);
    } else {
      var slice = computedComments.slice(0, 30);
      setCurrentPage(1);
      setCommant(slice);
    }
  };

  //   edit

  const edit = (da) => {
    setHeaderchange("edit");
    if (da) window.open("/Company/" + da.com_id, da.com_id, "_blank");
  };

  //   company detail
  const getCompanyDetails = (datacomid) => {
    fGetCompanycontract(datacomid).then((val: any) => {
      if (val !== undefined && val.status === 200) {
        setCompanydocdata(val.data);
        setcomid(datacomid);
        // this.setState({
        //   companydocdata: returnData.data,
        //   companyIData: datacomid,
        // });
      }
    });
  };

  //   download document

  const downloadDocument = (file: string) => {
    downloadFileWithGet(
      "Contractupload/download_contract/" + companyIData + "/" + file
    ).then((blob) => saveAs(blob, file));
  };

  const confirmDelete = (event, file) => {
    setfile(file);
    event.stopPropagation();
    setAlert(true);
  };

  //   update content

  const uploadContract = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setShowLoading(true);
      companyContractUpload(e.target.files[0], companyIData).then(
        (val: any) => {
          if (val !== undefined && val.status === 200) {
            getCompanyDetails(companyIData);
            setShowLoading(false);
            setToastColor("success");
            setToastMessage("Contract document uploaded successfully.");
            setShowToast(true);
          } else {
            setShowLoading(false);
            setToastColor("danger");
            setToastMessage("Unable to upload document, please try later.");
            setShowToast(true);
          }
        }
      );
    }
  };

  //   delete contract

  const deleteContract = (isAccepted: boolean) => {
    setAlert(false);
    if (!isAccepted) return;
    setShowLoading(true);
    fContractuploaddeletecontract(companyIData + "/" + file).then(
      (val: any) => {
        if (val !== undefined && val.status === 200) {
          getCompanyDetails(companyIData);
          setShowLoading(false);
          setToastColor("success");
          setToastMessage("Document Deleted Successfully.");
          setShowToast(true);
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(val !== undefined ? val.message : "please try again");
          setShowToast(true);
        }
      }
    );
  };

  const renderAction = (type, item) => {
    switch (type) {
      case "search":
        setDocmodel(true);
        getCompanyDetails(item.com_id);
        return;
      case "edit":
        edit(item);
        return;     
      default:
        return;
    }
  };

  React.useEffect(() => {
    let isMounted = true;
    try {
      getData();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  return (
    <div>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />

      {/* company table */}

      <IonGrid>
        <IonRow>
          <IonCol sizeLg="12" sizeXs="12" sizeMd="12">
            <h2>All Company</h2>
            {CurrentUser.user_type === Staff && (
              <IonButton
                className="custom-button btn-margin-left"
                color="secondary capitalize"
                shape="round"
                fill="solid"
                onClick={(e) => setshowModal(true)}
              >
                Create Company
              </IonButton>
            )}
          </IonCol>
          {/* <IonCol
                  sizeLg="6"
                  size="12"
                  className="d-flex flex-row-reverse"
                >
                  <Search
                    value={search}
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                    onclickaction={() => onSearch(search)}
                  />
                </IonCol> */}
          <IonCol size="12">
            <IonCard className="ion-padding">
              <DataTableComponent
                tableheaders={column}
                tableData={commands}
                currentPage={currentPage}
                itemPerPage={totalCountPerPage}
                totalItem={totalItemCount}
                searchInput={true}
                searchFunction={(search) => onSearch(search)}
                handleAction={(type, item) => {
                  renderAction(type, item);
                }}
                setCurrentPage={(page) => handlePageChange(page)}
              />
            </IonCard>          
          </IonCol>
        </IonRow>
      </IonGrid>

      {/* document  */}
      <IonModal isOpen={docmodel} className="my-custom-class">
        <IonPage>
          <IonContent>
            <IonGrid className="text-center">
              <IonIcon
                icon={closeCircle}
                onClick={(e) => setDocmodel(false)}
                style={{ float: "right", "margin-right": "1rem" }}
              />
              <h2 className="text-center" style={{ marginTop: "2rem" }}>
                Company Document
              </h2>
              {companydocdata.map((ele: any, i: number) => (
                <IonRow key={i}>
                  <IonCol
                    offsetLg="3"
                    sizeLg="6"
                    sizeXs="12"
                    style={{ "margin-top": "5rem" }}
                  >
                    <IonGrid className="content-box">
                      <IonRow>
                        <IonCol size="12">
                          <h5>{ele.firstname + " " + ele.lastname}</h5>
                        </IonCol>
                      </IonRow>
                      <IonRow className="text-start">
                        <IonCol size="6">
                          <p>
                            <b>Mobile:</b>
                            {ele.mobile}
                          </p>
                        </IonCol>
                        <IonCol>
                          <p>
                            <b>Email:</b> {ele.email}
                          </p>
                        </IonCol>
                      </IonRow>
                      {ele.contract_name && ele.contract_name.length > 0 && (
                        <IonRow>
                          <IonCol size="12" className="text-start">
                            {ele.contract_name.map(
                              (document, index: number) => (
                                <IonChip
                                  key={index}
                                  onClick={(e) =>
                                    downloadDocument(document.contract_name)
                                  }
                                >
                                  <IonLabel>
                                    Click to Download Document
                                  </IonLabel>
                                  <IonIcon
                                    color="danger"
                                    title="Delete document"
                                    icon={trash}
                                    onClick={(event) =>
                                      confirmDelete(
                                        event,
                                        document.contract_name
                                      )
                                    }
                                  />
                                </IonChip>
                              )
                            )}
                          </IonCol>
                        </IonRow>
                      )}
                      <IonRow>
                        <IonCol size="12">
                          <IonItemDivider></IonItemDivider>
                        </IonCol>
                        <IonCol size="12">
                          <IonButton
                            className="custom-button centered file-upload"
                            color="secondary"
                            shape="round"
                            fill="outline"
                          >
                            <label
                              htmlFor="upload-button1"
                              className="upload-button-label"
                            >
                              <IonIcon icon={cloudUploadOutline}></IonIcon>{" "}
                              &nbsp;Upload{" "}
                            </label>
                          </IonButton>

                          <input
                            type="file"
                            name="cover_photo"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            id="upload-button1"
                            style={{ display: "none" }}
                            onChange={(e) => uploadContract(e)}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </IonRow>
              ))}
            </IonGrid>
          </IonContent>
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
          />
          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => deleteContract(false)}
            header={"Confirm!"}
            message={"Are you sure to delete the document?"}
            buttons={[
              {
                text: "Cancel",
                role: "cancel",
                cssClass: "primary",
                handler: () => {
                  deleteContract(false);
                },
              },
              {
                text: "Delete",
                cssClass: "danger",
                handler: () => {
                  deleteContract(true);
                },
              },
            ]}
          />
        </IonPage>
      </IonModal>

      {/* create company form model */}

      <ModalBody
        title="Create Company"
        subTitle={""}
        isOpen={showModal}
        onClose={() => {
          setshowModal(false);
        }}
      >
        <Viewcompany
          setShowModal={() => {
            getData();
            setshowModal(false);
          }}
        />
      </ModalBody>
    </div>
  );
};
export default React.memo(CompanyTable);
