import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import useOnclickOutside from "react-cool-onclickoutside";
import React from "react";
export default function LocationSearch({
  valueKey,
  onChangeValue,
  onSelectValue,
  placeholder,
  latLong,
  refresh,
}: any) {
  const [dataupdated, setDataupdated] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  React.useEffect(() => {
    // if (!loaded) {
    //   const scriptTag = document.createElement("script");
    //   scriptTag.src =
    //     "https://maps.googleapis.com/maps/api/js?key=AIzaSyBUMTVC0fFwSxuTa7VY0Qa-_pUITEfRw2I&libraries=places";
    //   scriptTag.addEventListener("load", () => setLoaded(true));
    //   document.body.appendChild(scriptTag);
    // }
    if (!dataupdated) {
      clearSuggestions();
      setDataupdated(true);
    }
    setValue(valueKey, false);
  }, [refresh]);

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e: any) => {
    setValue(e.target.value);
    onChangeValue(e.target.value);
  };

  const handleSelect =
    ({ description }: any) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      // onSelectValue(description);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          latLong({ lat, lng, des: description });
          onSelectValue(description);
        })
        .catch((error) => {
          onSelectValue(description);
          latLong({ lat: "", lng: "", des: description });
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    // <Select
    //   showSearch
    //   value={value}
    //   placeholder={"Search"}
    //   defaultActiveFirstOption={false}
    //   showArrow={false}
    //   filterOption={false}
    //   onSelect={handleSelect}
    //   onChange={handleInput}
    //   notFoundContent={"Location not found"}
    // >
    <div ref={ref}>
      <input
        value={value}
        onChange={handleInput}
        disabled={false}
        placeholder={placeholder}
      />
      {status === "OK" && (
        <div style={{ position: "relative" }}>
          <ul className="locationSearch">{renderSuggestions()}</ul>
        </div>
      )}
    </div>
    // </Select>
  );
}
