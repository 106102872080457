import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import { alertCircleOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { getCookie, getMethod } from "../../service";

const CompanyEmailVerify = () => {
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  // const userdata = getCookie({ key: "user" }).value;
  const [isEmailVerified, setEmailVerified] = useState<boolean>(
    userdata?.mail_verified ?? false
  );

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);

  function resendEmail() {
    setShowLoading(true);
    const resend = getMethod("Resendmail");
    resend.then((returnData) => {
      setShowLoading(false);
      if (returnData !== undefined && returnData?.status === 200) {
        setToastColor("success");
        setToastMessage("We have sent an email for verification.");
        setShowToast(true);
      } else {
        setToastColor("danger");
        setToastMessage("Something went wrong, please try later.");
        setShowToast(true);
      }
    });
  }

  const checkStatus = () => {
    if (isEmailVerified) return;
    // setShowLoading(true);
    const resend = getMethod("Mailstatus")
      .then((returnData) => {
        // setShowLoading(false);
        if (returnData !== undefined && returnData?.data) {
          if (returnData.data.isVerified) setEmailVerified(true);
        } else {
          setEmailVerified(false);
        }
      })
      .catch(() => {
        setEmailVerified(false);
      });
  };

  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <>
      {!isEmailVerified && (
        <IonItem color="warning">
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="text-center">
                  <IonIcon icon={alertCircleOutline}></IonIcon> &nbsp;
                  <strong>Please verify your email:</strong>. We've sent
                  instructions to <i>{userdata?.email}</i>. Didnt receive
                  email?&nbsp;
                  <a href="javascript:return false;" onClick={resendEmail}>
                    <span>Resend</span>
                  </a>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      )}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
    </>
  );
};

export default CompanyEmailVerify;
