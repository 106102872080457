import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRange,
  IonRow,
  IonText,
  IonToggle,
} from "@ionic/react";
import { Controller, useFormContext } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import React from "react";
import { GetCities, Getcountry, GetState } from "../Service/api";
import { arrayOptionData } from "../../../service";
import { DatePick } from "../../../component";

export const MandatoryDetails: React.FC<any> = ({ next, OptionDatas }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext(); // retrieve all hook methods
  const [country, setcountry] = React.useState<any>([]);
  const getcountry = () => {
    Getcountry().then((ele) => {
      console.log(ele)
      setcountry(ele.data);
    });
  };

  React.useEffect(() => {
    getcountry();
  }, []);

  return (
    <div className="slide-main mandatory">
      <div className="form-content">
        <div className="">
          <p>Import profile details</p>
          <h1>2. Review mandatory details</h1>
          <p>
            Following details are required to consider you for jobs, so please
            ensure they're correct.
          </p>
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="12" sizeXs="12">
                <div className="" style={{ paddingBottom: "30px" }}>
                  {/* <div className="left-slider">
              <CustomAnchor PageLink={Link} />
            </div> */}
                  <div className="text-left">
                    <Personal
                      contaryList={country}
                      countryDate={"1"}
                      stateDate={
                        (OptionDatas !== undefined && OptionDatas.state) ?? ""
                      }
                    />
                    {/* <WorkStatus
                      workPermitOpt={
                        OptionDatas !== undefined &&
                        OptionDatas.work_permit_list
                      }
                      securityOption={
                        OptionDatas !== undefined &&
                        OptionDatas.security_clearance_list
                      }
                      visatypelistOption={
                        OptionDatas !== undefined && OptionDatas.visatypelist
                      }
                    /> */}
                    <EngagementPreference OptionList={OptionDatas} />
                    <ProfileSettings />
                  </div>
                </div>
                <div className="form-footer">
                  <IonButton
                    type="submit"
                    shape="round"
                    fill="solid"
                    className="next-btn remove-shadow capitalizes"
                    onClick={() =>
                      next([
                        "firstname",
                        "lastname",
                        "jobrole",
                        "country",
                        "city",
                        "visatype",
                        "is_contract_job",
                        "currency",
                        "contract_duration",
                        "contract_duration_type",
                        "contract_start_date",
                        "contract_telecommute",
                        "contract_telecommute_type",
                        "contract_compensation",
                        "contract_compensation_type",
                        "is_permanent_job",
                        "permanent_currency_type",
                        "permanent_duration",
                        "permanent_duration_type",
                        "permanent_start_date",
                        "permanent_telecommute",
                        "permanent_telecommute_type",
                        "permanent_compensation",
                        "permanent_compensation_type",
                      ])
                    }
                  >
                    Submit
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <small>
            Facing any difficulty?{" "}
            <a href="https://apps.hirify.ai/login">Get help</a>
          </small>
          <div className="divider-line"></div>
          <div className="bottom-section">
            <img alt="" src="assets/image/hirify.png" />
            <div className="footer-content">
              <small>© 2024–present, Hirify. All rights reserved.</small>

              <br />
              <small>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="/terms"
                >
                  Terms of Use •
                </a>{" "}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Link = [
  {
    title: "Personal",
    link: "/ContractorResume#personal",
    child: [],
  },
  {
    title: "US work status",
    link: "/ContractorResume#workstatus",
    child: [],
  },
  {
    title: "Engagement preferences",
    link: "/ContractorResume#engagement",
    child: [],
  },
  {
    title: "Profile settings",
    link: "/ContractorResume#profilesettings",
    child: [],
  },
];

const Personal = ({ contaryList, stateDate, countryDate }: any) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const [selectedStates, setStates] = React.useState<any>([]);
  const [Cities, setCities] = React.useState<any>([]);
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  const getState = (contryid: any) => {
    if (contryid !== undefined && contryid) {
      // let obj = contaryList.find(
      //   (st) => st.country_name.toLowerCase() === e.toLowerCase()
      // );
      // var contryid = obj !== undefined && obj.country_id;
      if (contryid) {
        let result = {
          country_id: contryid,
        };
        GetState(result).then((ele) => {
          if (ele !== undefined && ele.status === 200) {
            setStates(ele.data);
          }
        });
      }
    }
  };

  function GetCitiesData(e: any) {
    if (e !== undefined && e) {
      if (selectedStates !== undefined) {
        let obj = selectedStates.find(
          (st) => st.state_name.toLowerCase() === e.toLowerCase()
        );
        var citiesid = obj !== undefined && obj.state_id;
        if (citiesid) {
          let data = {
            state_id: citiesid,
          };

          GetCities(data).then((ele) => {
            if (ele !== undefined && ele.status === 200) {
              setCities(ele.data);
            }
          });
        }
      }
    }
  }

  React.useEffect(() => {
    getState(countryDate);

    if (stateDate) {
      setTimeout(() => {
        GetCitiesData(stateDate);
      }, 8000);
    }
  }, []);

  return (
    <section id="personal">
      <h3 className="header-h3">Personal</h3>
      <IonCard>
        <IonGrid className="ion-padding">
          <div className="custom-sub-header">
            <h4>Name & location</h4>
          </div>
          <IonRow>
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="12" sizeXs="12">
              <IonItem lines="none">
                <IonLabel position="stacked">
                  First Name <span className="required">*</span>
                </IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      autocomplete="off"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                      maxlength={32}
                      placeholder="Enter the first name"
                    />
                  )}
                  control={control}
                  name="firstname"
                  rules={{
                    required: "First name is required",
                    minLength: {
                      value: 2,
                      message: "Must be 2 chars long",
                    },
                    maxLength: {
                      value: 32,
                      message: "Less then 32 chars",
                    },
                  }}
                />
              </IonItem>
              {showError("firstname")}
            </IonCol>
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="12" sizeXs="12">
              <IonItem lines="none">
                <IonLabel position="stacked">
                  Last Name <span className="required">*</span>
                </IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      autocomplete="off"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                      maxlength={32}
                      placeholder="Enter the last name"
                    />
                  )}
                  control={control}
                  name="lastname"
                  rules={{
                    required: "Last name is required",
                    minLength: {
                      value: 1,
                      message: "Must be 1 chars long",
                    },
                    maxLength: {
                      value: 32,
                      message: "Less then 32 chars",
                    },
                  }}
                />
              </IonItem>
              {showError("lastname")}
            </IonCol>
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="12" sizeXs="12">
              <div className="locationInput">
                <IonLabel position="stacked">
                  Country <span className="required">*</span>
                </IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      allowNew
                      id="custom-selections-input"
                      newSelectionPrefix="Add a new country: "
                      options={arrayOptionData(
                        contaryList,
                        "country_name",
                        "country_name"
                      )}
                      onChange={(e) => {
                        setValue("country", e);
                      }}
                      onBlur={(e: any) => {
                        if (e && value.length > 0) {
                          getState(value[0].value);
                        }
                      }}
                      selected={value}
                      placeholder="country"
                    />
                  )}
                  control={control}
                  name="country"
                  rules={{
                    required: "Country is required",
                  }}
                />
              </div>
              {showError("country")}
            </IonCol>
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="12" sizeXs="12">
              <IonItem lines="none">
                <IonLabel position="stacked">Zip code</IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      autocomplete="off"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                      minlength={0}
                      maxlength={6}
                      placeholder="Enter the zip code"
                    />
                  )}
                  control={control}
                  name="zip"
                  rules={{
                    pattern: {
                      value: /[0-9]/,
                      message: "invalid zip code",
                    },
                    minLength: {
                      value: 4,
                      message: "Invalid zip code",
                    },
                  }}
                />
              </IonItem>
              {showError("zip")}
            </IonCol>
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="12" sizeXs="12">
              <div className="locationInput">
                <IonLabel position="stacked">
                  State <span className="required">*</span>
                </IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      allowNew
                      id="custom-selections-input"
                      newSelectionPrefix="Add a new state: "
                      options={arrayOptionData(
                        selectedStates,
                        "state_name",
                        "state_name"
                      )}
                      onChange={(e) => {
                        setValue("state", e);
                      }}
                      onBlur={(e: any) => {
                        if (e && value.length > 0) {
                          GetCitiesData(value[0].value);
                        }
                      }}
                      selected={value}
                      placeholder="state"
                    />
                  )}
                  control={control}
                  name="state"
                  rules={{
                    required: "State is required",
                  }}
                />
              </div>
              {showError("state")}
            </IonCol>
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="12" sizeXs="12">
              <div className="locationInput">
                <IonLabel position="stacked">
                  City <span className="required">*</span>
                </IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      allowNew
                      id="custom-selections-input"
                      newSelectionPrefix="Add a new city: "
                      options={arrayOptionData(
                        Cities,
                        "city_name",
                        "city_name"
                      )}
                      onChange={onChange}
                      selected={value}
                      placeholder="city"
                    />
                  )}
                  control={control}
                  name="city"
                  rules={{
                    required: "City is required",
                  }}
                />
              </div>
              {showError("city")}
            </IonCol>
            <IonCol sizeLg="12" sizeMd="12" sizeXl="12" sizeSm="12" sizeXs="12">
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol sizeLg="3" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        Country Code <span className="required">*</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonInput
                            autocomplete="off"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            // maxlength={10}
                            placeholder="ex: +91 xxx"
                          />
                          // <select
                          //   onBlur={onBlur}
                          //   onChange={onChange}
                          //   value={value}
                          //   placeholder="Select"
                          // >
                          //   <option value="">Select</option>
                          //   <option value="+91">+91</option>
                          // </select>
                        )}
                        control={control}
                        name="country_code"
                        rules={{
                          required: "Country Code is required",
                        }}
                      />
                    </IonItem>
                    {showError("country_code")}
                  </IonCol>
                  <IonCol sizeLg="9" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        Phone <span className="required">*</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonInput
                            autocomplete="off"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            maxlength={10}
                            placeholder=""
                          />
                        )}
                        control={control}
                        name="mobile"
                        rules={{
                          required: "Phone no is required",
                          pattern: {
                            value: /[0-9]/,
                            message: "invalid phone number",
                          },
                        }}
                      />
                    </IonItem>
                    {showError("mobile")}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
          <div className="divider-Innerline mx-3"></div>
          <div className="custom-sub-header">
            <h4>About</h4>
          </div>
          <IonRow>
            <IonCol sizeLg="12" sizeMd="12" sizeXl="12" sizeSm="12" sizeXs="12">
              <IonItem lines="none">
                <IonLabel position="stacked">
                  Job role<span className="required">*</span>
                </IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      autocomplete="off"
                      onBlur={onBlur}
                      onIonChange={onChange}
                      value={value}
                      maxlength={64}
                      placeholder="Graphic Designer, Web Developer, Data Scientist, etc."
                    />
                  )}
                  control={control}
                  name="jobrole"
                  rules={{
                    required: "Job role is required",
                    minLength: {
                      value: 3,
                      message: "Must be 3 chars long",
                    },
                    maxLength: {
                      value: 64,
                      message: "Less then 64 chars",
                    },
                  }}
                />
              </IonItem>
              {showError("jobrole")}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
    </section>
  );
};

// us work status

const WorkStatus = ({
  workPermitOpt,
  securityOption,
  visatypelistOption,
}: any) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };
  return (
    <section id="workstatus">
      <h3 className="header-h3">US work status</h3>
      <IonCard>
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol sizeLg="6" sizeXs="12">
              <h4>Authorization</h4>
            </IonCol>
            <IonCol sizeLg="6" sizeXs="12">
              <IonItem lines="none">
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <select
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      placeholder="Select"
                    >
                      <option value="">Select</option>
                      {/* <option value="3">Work visa</option> */}
                      {workPermitOpt !== undefined &&
                        workPermitOpt.map((option: any, i: number) => (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                    </select>
                  )}
                  control={control}
                  name="eligible_work_permit"
                />
              </IonItem>
            </IonCol>
            {watch("eligible_work_permit") === "3" && (
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked" className="label">
                    Work visa type <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        {visatypelistOption !== undefined &&
                          visatypelistOption.map((option: any, i: number) => (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </select>
                    )}
                    control={control}
                    name="visatype"
                    rules={{
                      required: "Visa type is required",
                    }}
                  />
                </IonItem>
                {showError("visatype")}
              </IonCol>
            )}
            {watch("eligible_work_permit") !== "4" && (
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">Security clearance</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        {securityOption !== undefined &&
                          securityOption.map((option: any, i: number) => (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </select>
                    )}
                    control={control}
                    name="security_clearance"
                  />
                </IonItem>
                <IonText className="helperText right-content-2">
                  Required by some jobs
                </IonText>

                {showError("security_clearance")}
              </IonCol>
            )}
          </IonRow>
        </IonGrid>
      </IonCard>
    </section>
  );
};

const EngagementPreference = ({ OptionList }: any) => {
  const [dataupdated, setDataupdated] = React.useState(false);
  const [contract, setContract] = React.useState(false);
  const [permanent, setPermanent] = React.useState(false);
  const [compensationOption, setCompensation] = React.useState([]);
  const [currencyOption, setCurrency] = React.useState([]);
  const [durationOption, setDuration] = React.useState([]);
  const [telecommuteOption, setTelecommute] = React.useState([]);
  const [ContctsDurationSlide, setContctsDurationSlide] = React.useState<any>();
  const [ContctsTelecomuteSlide, setContctsTelecomuteSlide] =
    React.useState<any>();
  const [permanentDurationSlide, setpermanentDurationSlide] =
    React.useState<any>();
  const [PermanentTelecomuteSlide, setPermanentTelecomuteSlide] =
    React.useState<any>();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useFormContext();
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  function SpecifyValues() {
    setContract(OptionList.is_contract_job !== "1" ? false : true);
    setPermanent(OptionList.is_permanent_job !== "1" ? false : true);
    setContctsDurationSlide(
      OptionList.contract_duration !== "0" &&
        OptionList.contract_duration_type === "2"
        ? "40"
        : OptionList.contract_duration ?? "40"
    );
    setContctsTelecomuteSlide(
      OptionList.contract_duration_type === "1"
        ? Number(OptionList.contract_telecommute)
        : OptionList.contract_duration_type === "3"
        ? Number(OptionList.contract_telecommute)
        : OptionList.contract_duration_type === "2"
        ? OptionList.contract_telecommute ?? "20"
        : "20"
    );
    setpermanentDurationSlide(
      OptionList.permanent_duration !== "0" &&
        OptionList.permanent_duration_type === "2"
        ? "40"
        : OptionList.permanent_duration ?? "40"
    );
    setPermanentTelecomuteSlide(
      OptionList.permanent_duration_type === "1"
        ? Number(OptionList.permanent_telecommute)
        : OptionList.permanent_duration_type === "3"
        ? Number(OptionList.permanent_telecommute)
        : OptionList.permanent_duration_type === "2"
        ? Number(OptionList.permanent_telecommute) ?? "20"
        : "20"
    );

    setCompensation(OptionList.compensation_type_list);
    setCurrency(OptionList.currency_type_list);
    setDuration(OptionList.duration_type_list);
    setTelecommute(OptionList.telecommute_type);
  }
  React.useEffect(() => {
    if (!dataupdated) {
      SpecifyValues();
      setDataupdated(true);
      setValue("contract_duration_type", "2");
      setValue("contract_telecommute_type", "1");
      setValue("contract_currency_type", "1");
      setValue(
        "contract_telecommute",
        OptionList.contract_telecommute !== "0"
          ? OptionList.contract_telecommute ?? "20"
          : "20"
      );
      setValue("contract_compensation_type", "1");
      setValue("permanent_currency_type", "1");
      setValue("permanent_duration_type", "2");
      setValue("permanent_telecommute_type", "1");
      setValue("permanent_compensation_type", "1");
    }
  }, []);

  return (
    <section id="engagement">
      <h3 className="header-h3">Engagement preferences</h3>
      <IonCard>
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol sizeLg="6" sizeXs="12">
              <h4>Available for contract jobs</h4>
            </IonCol>
            <IonCol sizeLg="6" sizeXs="12">
              <IonItem lines="none">
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonToggle
                      value={value}
                      checked={value}
                      mode="md"
                      slot="end"
                      onBlur={onBlur}
                      onIonChange={(e) => {
                        setValue("is_contract_job", e.detail.checked);
                        setContract(e.detail.checked);
                      }}
                    />
                  )}
                  control={control}
                  name="is_contract_job"
                />
              </IonItem>
            </IonCol>
          </IonRow>
          {contract && (
            <IonRow>
              {/* <IonCol sizeLg="6" sizeXs="12">
                  <h6>Total duration</h6>
                </IonCol> */}
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none" className="custom-align">
                  <h6>Total duration</h6>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        className="custom-select-fit mx-2"
                        onBlur={onBlur}
                        onChange={(e) => {
                          onChange(e);
                          let value = e.target.value;
                          setValue(
                            "contract_duration",
                            value == "1"
                              ? 8
                              : value == "2"
                              ? 40
                              : value == "3"
                              ? 176
                              : value
                          );
                          setValue("contract_telecommute", 0);
                        }}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        {durationOption !== undefined &&
                          durationOption.map((option: any, i: number) => (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </select>
                    )}
                    control={control}
                    name="contract_duration_type"
                  />
                  {ContctsDurationSlide}h
                </IonItem>
                {/* <IonText className="text-right">
                    {ContctsDurationSlide}h selected
                  </IonText> */}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12" className="pb-3">
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonRange
                      mode={"ios"}
                      min={0}
                      max={
                        watch("contract_duration_type") === "1"
                          ? 24
                          : watch("contract_duration_type") === "2"
                          ? 168
                          : 720
                      }
                      pin={true}
                      snaps={true}
                      value={value}
                      onBlur={onBlur}
                      onIonChange={(e) => {
                        setValue("contract_duration", e.detail.value as number);
                        setContctsDurationSlide(e.detail.value as number);
                      }}
                    >
                      <IonLabel slot="start">0</IonLabel>
                      <IonLabel slot="end">
                        {" "}
                        {watch("contract_duration_type") === "1"
                          ? 24
                          : watch("contract_duration_type") === "2"
                          ? 168
                          : 720}
                      </IonLabel>
                    </IonRange>
                  )}
                  control={control}
                  name="contract_duration"
                />
              </IonCol>
              {/* <IonCol sizeLg="6" sizeXs="12">
                  <h6>
                    Telecommute
                  </h6>
                </IonCol> */}
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none" className="custom-align">
                  <h6>Telecommute: {ContctsTelecomuteSlide}h </h6>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        className="custom-select-fit mx-2"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        {telecommuteOption !== undefined &&
                          telecommuteOption.map((option: any, i: number) => (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </select>
                    )}
                    control={control}
                    name="contract_telecommute_type"
                  />
                  {watch("contract_duration_type") == "3"
                    ? "Monthly"
                    : watch("contract_duration_type") == "2"
                    ? "Weekly"
                    : "Daily"}
                  {" " + ContctsDurationSlide}h
                </IonItem>
                {/* <IonText className="text-right">
                    {ContctsTelecomuteSlide}h of total duration
                  </IonText> */}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12" className="pb-3">
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonRange
                      style={{ paddingTop: "inherit" }}
                      mode={"ios"}
                      min={0}
                      max={
                        watch("contract_duration")
                        // watch("contract_duration_type") == "3"
                        //   ? Number(watch("contract_duration")) / 4
                        //   : watch("contract_duration_type") == "1"
                        //   ? Number(watch("contract_duration")) * 5
                        //   : Number(watch("contract_duration"))
                      }
                      pin={true}
                      snaps={true}
                      value={value}
                      onBlur={onBlur}
                      onIonChange={(e) => {
                        setValue(
                          "contract_telecommute",
                          e.detail.value as number
                        );
                        setContctsTelecomuteSlide(e.detail.value as number);
                      }}
                    >
                      <IonLabel slot="start">0</IonLabel>
                      <IonLabel slot="end">
                        {watch("contract_duration")}
                        {/* {watch("contract_duration_type") == "3"
                          ? Math.floor(Number(watch("contract_duration")) / 4)
                          : watch("contract_duration_type") == "1"
                          ? Math.floor(Number(watch("contract_duration")) * 5)
                          : Math.floor(Number(watch("contract_duration")))} */}
                      </IonLabel>
                    </IonRange>
                  )}
                  control={control}
                  name="contract_telecommute"
                />
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Desired compensation <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput
                        autocomplete="off"
                        type="number"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        placeholder="₹10"
                      />
                    )}
                    control={control}
                    name="contract_compensation"
                    rules={{ required: "Desired compensation is required" }}
                  />
                  <div className="custom-groupInput">
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          disabled
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          {compensationOption !== undefined &&
                            compensationOption.map((option: any, i: number) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                      )}
                      control={control}
                      name="contract_compensation_type"
                    />
                  </div>
                  <div className="currencyLabel">
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          {currencyOption !== undefined &&
                            currencyOption.map((option: any, i: number) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                      )}
                      control={control}
                      name="currency"
                    />
                  </div>
                </IonItem>
                {showError("contract_compensation")}
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">
                    Preferred start date<span className="required">*</span>
                  </IonLabel><br />
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick
                        value={value}
                        ChangeEvent={onChange}
                        possition="top-start"
                        yearpermit={50}
                      />
                    )}
                    control={control}
                    name="contract_start_date"
                    rules={{ required: "Preferred start date" }}
                  />
                </div>
                {showError("contract_start_date")}
              </IonCol>

              {/* <IonCol sizeLg="4" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">Engagement Type</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        <option value="W2">W2</option>
                        <option value="1099">1099</option>
                        <option value="C2C">C2C</option>
                      </select>
                    )}
                    control={control}
                    name="contract_engagement_type"
                  />
                </IonItem>
                {showError("contract_engagement_type")}
              </IonCol> */}
            </IonRow>
          )}
        </IonGrid>

        <div className="divider-Innerline mx-4"></div>

        {/* Available for permanent jobs */}

        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol sizeLg="6" sizeXs="12">
              <h4>Available for permanent jobs</h4>
            </IonCol>
            <IonCol sizeLg="6" sizeXs="12">
              <IonItem lines="none">
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonToggle
                      value={value}
                      checked={value}
                      mode="md"
                      slot="end"
                      onBlur={onBlur}
                      onIonChange={(e) => {
                        setValue("is_permanent_job", e.detail.checked);
                        setPermanent(e.detail.checked);
                      }}
                    />
                  )}
                  control={control}
                  name="is_permanent_job"
                />
              </IonItem>
            </IonCol>
          </IonRow>
          {permanent && (
            <IonRow>
              {/* <IonCol sizeLg="6" sizeXs="12">
                  <h6>Total duration</h6>
                </IonCol> */}
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none" className="custom-align">
                  <h6>Total duration</h6>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        className="custom-select-fit mx-2"
                        onBlur={onBlur}
                        onChange={(e) => {
                          onChange(e);
                          let value = e.target.value;
                          setValue(
                            "permanent_duration",
                            value == "1"
                              ? 8
                              : value == "2"
                              ? 40
                              : value == "3"
                              ? 176
                              : value
                          );
                          setValue("permanent_telecommute", 0);
                        }}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        {durationOption !== undefined &&
                          durationOption.map((option: any, i: number) => (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </select>
                    )}
                    control={control}
                    name="permanent_duration_type"
                  />
                  {permanentDurationSlide}h
                </IonItem>
                {/* <IonText className="text-right">
                    {permanentDurationSlide}h selected
                  </IonText> */}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12" className="pb-3">
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonRange
                      style={{ paddingTop: "inherit" }}
                      mode={"ios"}
                      min={0}
                      max={
                        watch("permanent_duration_type") === "1"
                          ? 24
                          : watch("permanent_duration_type") === "2"
                          ? 168
                          : 720
                      }
                      pin={true}
                      snaps={true}
                      value={value}
                      onBlur={onBlur}
                      onIonChange={(e) => {
                        setValue(
                          "permanent_duration",
                          e.detail.value as number
                        );
                        setpermanentDurationSlide(e.detail.value as number);
                      }}
                    >
                      <IonLabel slot="start">0</IonLabel>
                      <IonLabel slot="end">
                        {" "}
                        {watch("permanent_duration_type") === "1"
                          ? 24
                          : watch("permanent_duration_type") === "2"
                          ? 168
                          : 720}
                      </IonLabel>
                    </IonRange>
                  )}
                  control={control}
                  name="permanent_duration"
                />
              </IonCol>
              {/* <IonCol sizeLg="6" sizeXs="12">
                  <h6>Telecommute</h6>
                </IonCol> */}
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none" className="custom-align">
                  <h6>Telecommute: {PermanentTelecomuteSlide}h </h6>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <select
                        className="custom-select-fit mx-2"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        {telecommuteOption !== undefined &&
                          telecommuteOption.map((option: any, i: number) => (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                      </select>
                    )}
                    control={control}
                    name="permanent_telecommute_type"
                  />
                  {watch("permanent_duration_type") == "3"
                    ? "Monthly"
                    : watch("permanent_duration_type") == "2"
                    ? "Weekly"
                    : "Daily"}
                  {" " + permanentDurationSlide}h
                </IonItem>
                {/* <IonText className="text-right">
                    {PermanentTelecomuteSlide}h of total duration
                  </IonText> */}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12" className="pb-3">
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonRange
                      mode={"ios"}
                      min={0}
                      max={
                        watch("permanent_duration")
                        // watch("permanent_duration_type") == "3"
                        //   ? Number(watch("permanent_duration")) / 4
                        //   : watch("permanent_duration_type") == "1"
                        //   ? Number(watch("permanent_duration")) * 5
                        //   : Number(watch("permanent_duration"))
                      }
                      pin={true}
                      snaps={true}
                      value={value}
                      onBlur={onBlur}
                      onIonChange={(e) => {
                        setValue(
                          "permanent_telecommute",
                          e.detail.value as number
                        );
                        setPermanentTelecomuteSlide(e.detail.value as number);
                      }}
                    >
                      <IonLabel slot="start">0</IonLabel>
                      <IonLabel slot="end">
                        {watch("permanent_duration")}
                        {/* {watch("permanent_duration_type") == "3"
                          ? Math.floor(Number(watch("permanent_duration")) / 4)
                          : watch("permanent_duration_type") == "1"
                          ? Math.floor(Number(watch("permanent_duration")) * 5)
                          : Math.floor(Number(watch("permanent_duration")))} */}
                      </IonLabel>
                    </IonRange>
                  )}
                  control={control}
                  name="permanent_telecommute"
                />
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Desired compensation <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput
                        autocomplete="off"
                        type="number"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        placeholder="₹10"
                      />
                    )}
                    control={control}
                    name="permanent_compensation"
                    rules={{ required: "Desired compensation is required" }}
                  />
                  <div className="custom-groupInput">
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          disabled
                          placeholder="Select"
                        >
                          {/* <option value="">Select</option> */}
                          <option selected value="4">
                            Yearly
                          </option>
                          {/* {compensationOption !== undefined &&
                              compensationOption.map(
                                (option: any, i: number) => (
                                  <option key={i} value={option.value}>
                                    {option.label}
                                  </option>
                                )
                              )} */}
                        </select>
                      )}
                      control={control}
                      name="permanent_compensation_type"
                      defaultValue="4"
                    />
                  </div>
                  <div className="currencyLabel">
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          {currencyOption !== undefined &&
                            currencyOption.map((option: any, i: number) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                      )}
                      control={control}
                      name="permanent_currency_type"
                    />
                  </div>
                </IonItem>
                {showError("permanent_compensation")}
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">
                    Preferred start date<span className="required">*</span>
                  </IonLabel>
                  <br />
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick
                        value={value}
                        ChangeEvent={onChange}
                        possition="top-start"
                        yearpermit={50}
                      />
                    )}
                    control={control}
                    name="permanent_start_date"
                    rules={{ required: "Preferred start date" }}
                  />
                </div>
                {showError("permanent_start_date")}
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonCard>
    </section>
  );
};

// profile Setting
const ProfileSettings = () => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  return (
    <section id="profilesettings">
      <h3 className="header-h3">Profile Settings</h3>
      <IonCard>
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol sizeLg="12" sizeMd="12" sizeXl="12" sizeSm="12" sizeXs="12">
              <IonItem lines="none">
                <IonLabel position="stacked">
                  Email<span className="required">*</span>
                </IonLabel>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <IonInput
                      autocomplete="off"
                      onBlur={onBlur}
                      readonly={true}
                      onIonChange={onChange}
                      value={value}
                      maxlength={64}
                      placeholder="Enter the email address"
                    />
                  )}
                  control={control}
                  name="email"
                  rules={{
                    required: "Email-id is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address",
                    },
                  }}
                />
              </IonItem>
              {showError("email")}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
    </section>
  );
};
