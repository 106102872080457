import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRange,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonToast,
} from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";
import { CanditateUserCard, LocationSearch } from "../../../component";
import {
  CardSketch,
  CRMCanditateUserCard,
} from "../../../component/company/canditateJobCard";
import { AuthContext } from "../../../context/authContext";
import {
  arrayOptionData,
  arrayOptionNameData,
  getIndexValue,
  RemoveDuplicates,
} from "../../../service";
import {
  jobResData,
  MatchJobInterface,
} from "../../Company/interface/canditateInterface";
import { getCRMJobMatchingContractor } from "../../Company/Service/api";
import { listoflanguages } from "../../Contractor/Service/api";

// match screen
const Candidateslist = ({ uniqueid, jobdetailData }) => {
  const defaultValue = {
    latlng: {},
    geo_location: "",
    miles: 20,
    search_optional_skills: [],
    search_skills: [],
    previous_employer: "",
    experience: 0,
  };
  const { control, handleSubmit, setValue, watch, getValues } = useForm({
    mode: "all",
  });

  const [accordionValue, setAccordionValue] = React.useState<any>("");
  const { setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showLoading, setShowLoading] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [sortBy, setSortBy] = React.useState("best");
  const [currentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [filterByBM, setFilterByBM] = React.useState<any>("30");
  const [totalCount, setTotalCount] = React.useState(0);
  const [cardData, setCardData] = React.useState<jobResData[]>();
  const [ListofskillsOption, setListofskillsOption] = React.useState<any>([]);
  const [locationRefresh, setLocationrefresh] = React.useState("no");
  const slideskillRange = (e) => {
    if (e) {
      getMatching(currentPage, e, sortBy, getValues());
    }
  };

  // get matching api call
  const getMatching = (page, filter, order, data) => {
    const result = {
      page: page,
      unique_id: uniqueid,
      order_by: order,
      skill_best_match: filter,
      geo_location: data.geo_location,
      latlng: data.latlng,
      previous_employer: data.previous_employer,
      miles: data.miles,
      search_by_name: data.search_by_name,
      search_optional_skills: arrayOptionNameData(
        data.search_optional_skills,
        "label"
      ),
      search_skills: arrayOptionNameData(data.search_skills, "label"),
      experience: data.experience,
    };
    setShowLoading(true);
    getCRMJobMatchingContractor(result).then((e: MatchJobInterface) => {
      if (e !== undefined && e.status === 200) {
        addCount(e);
        setFilterByBM(e.skill_match_perc ?? "30");
        setCardData(e.data);
        setTotalPageItem(
          e.data !== undefined ? e.data.length : e.itemsCountPerPage
        );
        setShowLoading(false);
        setTotalCount(e.totalItemsCount ?? 0);
      } else {
        setTotalCount(e.totalItemsCount ?? 0);
        addCount(e);
        setCardData([]);
        setShowLoading(false);
      }
      setShowLoading(false);
    });
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: e.applied_count,
      hired_count: e.hired_count,
      invited_count: e.invited_count,
      matching_candidate_count: e.matching_candidate_count,
      rejected_count: e.rejected_count,
      shortlisted_count: e.shortlisted_count,
    });
  };

  // life cycle
  React.useEffect(() => {
    let isMounted = true;
    setLocationrefresh("yes");
    try {
      setValue(
        "search_optional_skills",
        arrayOptionData(
          jobdetailData.additional_skills,
          "skill_name",
          "skill_id"
        )
      );
      setValue(
        "search_skills",
        arrayOptionData(
          jobdetailData.mandatory_skills,
          "skill_name",
          "skill_id"
        )
      );
      setValue("experience", jobdetailData.total_experince);
      setValue("miles", 20);
      getMatching(1, "30", sortBy, getValues());
      listofskillToolLanguage();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
          setShowLoading(false);
          setCardData([]);
        }
      }
    } finally {
      // isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);


  // get skill list
  const listofskillToolLanguage = () => {
    listoflanguages().then((ele) => {
      if (ele !== undefined && ele.status === 200) {
        const skill = arrayOptionData(ele.jng_skills, "skill_name", "skill_id");

        setListofskillsOption(skill);
      }
    });
  };

  const searchForm = (value) => {
    if (value) {
      getMatching(currentPage, filterByBM, sortBy, value);
    }
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonAccordionGroup value={accordionValue} className="ion-no-padding">
        <IonAccordion
          value="advance"
          toggleIcon={chevronDownOutline}
          onClick={(e) => {
            setAccordionValue("advance");
          }}
        >
          <IonText slot="header" className="custom-text cursor">
            <IonLabel>Advance search filters</IonLabel>
          </IonText>
          <form onSubmit={handleSubmit(searchForm)} slot="content">
            <IonCard className="p-2 crm-filter">
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg="6" sizeXs="12">
                    <div>
                      <IonLabel position="stacked">Skills</IonLabel>
                      <Controller
                        control={control}
                        name="search_skills"
                        render={({ field: { onChange, value, ref } }) => (
                          <Typeahead
                            id="custom-selections-input"
                            multiple
                            options={ListofskillsOption}
                            onChange={onChange}
                            selected={value}
                            placeholder="Skill Name"
                            selectHintOnEnter={true}
                            onKeyDown={(event) => {
                              if (
                                event.key === "Enter" ||
                                event.key === "Tab"
                              ) {
                                var backup: any[] = [];
                                backup = watch("search_skills");
                                setValue("search_skills", []);
                                if (event.target.value) {
                                  watch("search_skills").map((e) => {
                                    if (e.label !== event.target.value) {
                                      backup.push({
                                        label: event.target.value,
                                        value: getIndexValue(
                                          event.target.value,
                                          ListofskillsOption,
                                          "label",
                                          "value"
                                        ),
                                      });
                                    }
                                  });
                                  if (watch("search_skills").length === 0) {
                                    backup.push({
                                      label: event.target.value,
                                      value: getIndexValue(
                                        event.target.value,
                                        ListofskillsOption,
                                        "label",
                                        "value"
                                      ),
                                    });
                                  }
                                }

                                setValue(
                                  "search_skills",
                                  RemoveDuplicates(backup, "label")
                                );
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                  </IonCol>
                  <IonCol sizeLg="6" sizeXs="12">
                    <div>
                      <IonLabel position="stacked">Optional Skills</IonLabel>
                      <Controller
                        control={control}
                        name="search_optional_skills"
                        render={({ field: { onChange, value, ref } }) => (
                          <Typeahead
                            id="custom-selections-input"
                            multiple
                            options={ListofskillsOption}
                            onChange={onChange}
                            selected={value}
                            placeholder="Skill Name"
                            selectHintOnEnter={true}
                            onKeyDown={(event) => {
                              if (
                                event.key === "Enter" ||
                                event.key === "Tab"
                              ) {
                                var backup: any[] = [];
                                backup = watch("search_optional_skills");
                                setValue("search_optional_skills", []);
                                if (event.target.value) {
                                  watch("search_optional_skills").map((e) => {
                                    if (e.label !== event.target.value) {
                                      backup.push({
                                        label: event.target.value,
                                        value: getIndexValue(
                                          event.target.value,
                                          ListofskillsOption,
                                          "label",
                                          "value"
                                        ),
                                      });
                                    }
                                  });
                                  if (
                                    watch("search_optional_skills").length === 0
                                  ) {
                                    backup.push({
                                      label: event.target.value,
                                      value: getIndexValue(
                                        event.target.value,
                                        ListofskillsOption,
                                        "label",
                                        "value"
                                      ),
                                    });
                                  }
                                }

                                setValue(
                                  "search_optional_skills",
                                  RemoveDuplicates(backup, "label")
                                );
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                  </IonCol>
                  <IonCol sizeLg="6" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        Minimum Experience(years)
                      </IonLabel>
                      <Controller
                        control={control}
                        name="experience"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            type="number"
                            onIonChange={onChange}
                            value={value}
                          />
                        )}
                      />
                    </IonItem>
                  </IonCol>

                  <IonCol sizeLg="6" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">Previous employer</IonLabel>
                      <Controller
                        control={control}
                        name="previous_employer"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => <IonInput onIonChange={onChange} value={value} />}
                      />
                    </IonItem>
                  </IonCol>

                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="locationInput">
                      <IonLabel position="stacked">Location</IonLabel>
                      <IonRow>
                        <IonCol sizeLg="8" sizeXs="12">
                          <Controller
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <LocationSearch
                                valueKey={value}
                                onChangeValue={onChange}
                                onSelectValue={(e: any) => {
                                  setValue("geo_location", e);
                                }}
                                latLong={(e: any) => {
                                  setValue("latlng", e);
                                }}
                                refresh={locationRefresh}
                              />
                            )}
                            name="geo_location"
                            control={control}
                          />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <Controller
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <IonInput
                                value={value}
                                onIonChange={onChange}
                                autocomplete="off"
                                type="number"
                                placeholder="miles"
                              />
                            )}
                            name={"miles"}
                            control={control}
                          />
                          {/* <div className="custom-groupInput">
                            <Controller
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <select
                                  value={"mi"}
                                  disabled={true}
                                  onChange={onChange}
                                  placeholder="Select"
                                >
                                  <option value="">Select</option>
                                  <option selected value="mi">
                                    mi
                                  </option>
                                </select>
                              )}
                              control={control}
                              name={"mi"}
                            />
                          </div> */}
                        </IonCol>
                      </IonRow>
                    </div>
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">Search text</IonLabel>
                      <Controller
                        control={control}
                        name="search_by_name"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => <IonInput onIonChange={onChange} value={value} />}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <div>
                <IonButton
                  shape="round"
                  fill="solid"
                  color="secondary"
                  className="remove-shadow capitalizes"
                  type="submit"
                >
                  Search
                </IonButton>
              </div>
            </IonCard>
          </form>
        </IonAccordion>
      </IonAccordionGroup>
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <div className="header-content-flex ion-justify-content-between ion-align-items-center filter-option custom-sort-section">
              <IonLabel>
                Showing {totalPageItem ? 1 : 0}
                {/* {totalCount
                  ? currentPage === 1
                    ? 1
                    : (currentPage - 1) * perPage + 1
                  : 0} */}
                {" - "}
                {currentPage === 1
                  ? totalPageItem
                  : (currentPage - 1) * perPage + totalPageItem > totalCount
                  ? totalCount
                  : (currentPage - 1) * perPage + totalPageItem}
                {/* {" of " + totalCount} */}
              </IonLabel>

              <div className="sort-input-section ion-align-items-center">
                {/* <div className="search-input">
                    <IonInput
                      value={searchKey}
                      placeholder="Search jobs"
                      onIonChange={(e) => setSearchKey(e.detail.value)}
                    />
    
                    <div className="search-icon" onClick={() => searchData()}>
                      <img src="assets/image/company/search.svg" />
                    </div>
                  </div> */}

                <div className="range-filter">
                  <IonRange
                    className={filterByBM < 50 ? "skillRange" : ""}
                    mode={"ios"}
                    min={1}
                    max={100}
                    dualKnobs={false}
                    pin={true}
                    ticks={false}
                    color={filterByBM < 50 ? "danger" : ""}
                    value={filterByBM}
                    debounce={1000}
                    onIonChange={(e) => {
                      setFilterByBM(e.detail.value as number);
                      slideskillRange(e.detail.value as number);
                    }}
                  >
                    <IonLabel slot="start">
                      {"Skill match >" + filterByBM + "%"}{" "}
                    </IonLabel>
                  </IonRange>
                </div>
                <div className="input-group sort-group ion-sort-input">
                  <IonLabel className="input-group-text">Sort:</IonLabel>
                  <IonSelect
                    interface="popover"
                    placeholder="Select"
                    className="sort-input"
                    value={sortBy}
                    onIonChange={(e) => {
                      setSortBy(e.detail.value);
                      getMatching(
                        currentPage,
                        filterByBM,
                        e.detail.value,
                        getValues()
                      );
                    }}
                  >
                    <IonSelectOption value="best">Best Match</IonSelectOption>
                    {/* <IonSelectOption value="desc">Latest</IonSelectOption> */}
                  </IonSelect>
                </div>
                {/* <div className="input-group sort-group">
                  <span className="input-group-text" id="basic-addon1">
                    Sort:
                  </span>
                  <select
                    className="form-select sort-input"
                    value={sortBy}
                    onChange={(e) => {
                      setSortBy(e.target.value);
                      getMatching(
                        currentPage,
                        filterByBM,
                        e.target.value,
                        getValues()
                      );
                    }}
                  >
                    
                    <option value="best">Best Match</option>
                  </select>
                </div> */}
                {/* <div
                    className="filter-icon"
                    // onClick={() => setFiltermodelOpen(true)}
                  >
                    <img src="assets/image/company/tune.svg" />
                  </div> */}
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow>
          {!showLoading
            ? cardData &&
              cardData.map((cand, i: number) => (
                <IonCol sizeLg="12" sizeXs="12" key={i}>
                  <CRMCanditateUserCard
                    reviewflag={false}
                    jobdetail={jobdetailData}
                    cardData={cand}
                    type="1"
                    shortbtn={false}
                    rejectbtn={false}
                  />
                </IonCol>
              ))
            : Array.from({ length: 10 }, (_, i) => (
                <IonCol key={i} sizeLg="6" sizeXs="12">
                  <CardSketch />
                </IonCol>
              ))}
          {!showLoading && cardData && cardData.length === 0 && (
            <IonCol size="12">No data found </IonCol>
          )}
          {/* <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e) => {
                  setCurrentPage(e);
                  getMatching(e, filterByBM, sortBy);
                }}
              />
            )}
          </IonCol> */}
        </IonRow>
      </IonGrid>
    </>
  );
};

export default React.memo(Candidateslist);
