import { Route, Redirect } from "react-router-dom";

export const PublicRoute = ({
  auth,
  component: Component,
  restricted,
  ...rest
}: any) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        !auth ? <Component {...props} /> : <Component {...props} />
      }
    />
  );
};

export const PrivateRoute = ({ auth, component: Component, ...rest }: any) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        auth ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
