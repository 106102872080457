import { getMethod, postMethod, Requests } from "../../../service";

// Get Contractor Profile Detail
export function savecontractordetailsData(data: any) {
  return postMethod(Requests.fetchCreatecontractor, data);
}

export function deikaaSaveadditional(data: any) {
  return postMethod(Requests.fetchDeikaasaveadditionalctrdata, data);
}

export function UpdatetokensData(data: any) {
  return postMethod(Requests.fetchUpdatetokens, data);
}

export function GetcontractorList(dta: any) {
  return getMethod(Requests.fetchGetcontractorlist + dta);
}

export function GetPendingContractorList(dta: any) {
  return postMethod(Requests.fetchGetallpendingtokens, dta);
}

export function GetApprovedContractorList(dta: any) {
  return postMethod(Requests.fetchGetapprovedtokendata, dta);
}

export function GetRejectContractorList(dta: any) {
  return postMethod(Requests.fetchGetrejectedtokendata, dta);
}

export function GetTrmlogsList(dta: any) {
  return postMethod(Requests.fetchGetTrmdata, dta);
}

export function GetCtradditionaldata(dta: any) {
  return getMethod(Requests.fetchGetctradditionaldata + dta);
}

export function getTRmGetcompanylist(dta: any) {
  return postMethod(Requests.fetchGetcompanylist, dta);
}

export function SearchDeikaaUser(data: any) {
  return postMethod(Requests.fetchSearchDeikaaUser, data);
}

export function getTRmGetAllDeikaaActivityList(dta: any) {
  return getMethod(Requests.fetchGetAllDeikaaActivityList);
}

export function AddtrmRemarks(data: any) {
  return postMethod(Requests.fetchAddtrmRemarks, data);
}

export function getGrouplist(dta: any) {
  return getMethod(Requests.fetchGetgroup);
}

export function getContractorlist(data: any) {
  return postMethod(Requests.fetchGetcontractorlist, data);
}



export function CtrProfileCompletion_flag(data: any) {
  return postMethod(Requests.fetchCtrProfileCompletionflag, data);
}

export function Getjobsleads(data: any) {
  return postMethod(Requests.fetchGetjobsleads, data);
}

export function getMatchJobDetail(dta: any) {
  return getMethod(Requests.fetchGetjobsleadsDetails + dta);
}

export function CompanyListForShortlistingData(dta: any) {
  return getMethod(Requests.fetchCompanyListForShortlisting + dta);
}

export function getRemoveContractor(dta: any) {
  return getMethod(Requests.fetchRemoveContractor + dta);
}

export function GetInterviewFeedback(dta: any) {
  return getMethod(Requests.fetchGetInterviewFeedback + dta);
}
export function UpdateInterviewFeedback(data: any) {
  return postMethod(Requests.fetchUpdateInterviewFeedback, data);
}
export function addInterviewFeedback(data: any) {
  return postMethod(Requests.fetchSaveInterviewFeedback, data);
}

export function GetTrmtouchPoint(dta: any) {
  return getMethod(Requests.fetchGetTrmtouchPoint + dta);
}
export function UpdateTrmTouchPoint(data: any) {
  return postMethod(Requests.fetchUpdateTrmTouchPoint, data);
}
export function SaveTrmTouchPoint(data: any) {
  return postMethod(Requests.fetchSaveTrmTouchPoint, data);
}




 