import {
  IonAlert,
  IonButton,
  IonCard,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRadioGroup,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import { FormProvider, Controller, useForm } from "react-hook-form";
import { AuthContext } from "../../../../context/authContext";
import { ValidateUrl } from "../../../../util/validationHelper";
import { Typeahead } from "react-bootstrap-typeahead";
import React, { useRef } from "react";
import {
  BackgroundreferenceData,
  getReferenceresult,
  saveReference,
} from "../../Service/api";
import {
  apiCallBack,
  arrayOptionData,
  arrayOptionNameData,
  fileUpload,
  getIndexValue,
  StringMap,
} from "../../../../service";

import { DatePick, ModalBody } from "../../../../component";
import RefeExperienceForm from "./referExperience";

interface referenceList {
  ctr_rid: string;
  ctr_roid: string;
  designation: string;
  enddate: string;
  organization_name: string;
  ref_relation: string;
  startdate: string;
  updated_on: string;
}
const listRef: referenceList = {
  ctr_rid: "",
  ctr_roid: "",
  designation: "",
  enddate: "",
  organization_name: "",
  ref_relation: "",
  startdate: "",
  updated_on: "",
};

function ReferenceForm({
  initialValueData,
  ctrid,
  closeModal,
  eventType,
  folderkey,
  deleteData,
  resetForm,
  setrestoreData,
}: any) {
  const methods = useForm({
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = methods;

  const [Ctrrid, setCtrrid] = React.useState<any>();
  const [refExpType, setRefExpType] = React.useState("Add");
  const [editOrganisation, setEditOrganisation] = React.useState<any>();
  const [filtermodelOpen, setFiltermodelOpen] = React.useState(false);
  const [nextbtn, setNextbtn] = React.useState(false);
  const { auth, setDeikaa, DeikaaFun } = React.useContext(AuthContext);
  const [uploading, setUploading] = React.useState(false);
  const [photoName, setPhotoName] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setshowLoading] = React.useState(false);
  const [showAlert1, setShowAlert1] = React.useState(false);
  // file
  let dragCounter = useRef(0);
  let [dragOverlay, setDragOverlay] = React.useState(false);
  const [error, setError] = React.useState<any>();
  const [showAlert, setShowAlert] = React.useState(false);
  const [showConfirm, setshowConfirm] = React.useState<any>();

  // fields
  const [orgSaveData, setOrgSaveData] = React.useState<any[]>([]);
  const [orgData, setOrgData] = React.useState<any[]>([]);
  const [skillData, setSkillData] = React.useState<any>([]);
  const [toolData, setToolData] = React.useState<any>([]);
  const currentDate = new Date();

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  const next = async (fields: any) => {
    const result = await trigger(fields);
    if (!result) return;
    saveReferenceData(getValues());

    // setNextbtn(true);
  };

  const editOrg = (data) => {
    if (data) {
      setRefExpType("update");
      setEditOrganisation(data);
      setFiltermodelOpen(true);
    }
  };

  const getReferalData = (ctrrid) => {
    setshowLoading(true);
    getReferenceresult(ctrrid)
      .then((result: any) => {
        if (result.status === 200 && result !== undefined) {
          setValue("firstname", result.data.firstname);
          setValue("lastname", result.data.lastname);
          setValue("email_id", result.data.email_id);
          setValue("linkedin_url", result.data.linkedin_url);
          setValue("country_code", result.data.country_code);
          setValue("mobile", result.data.mobile);
          setValue("office_country_code", result.data.office_country_code);
          setValue("office_no", result.data.office_no);
          setValue("time_for_contact", result.data.time_for_contact);
          setValue(
            "since_long_you_know",
            result.data.since_long_you_know
              ? new Date(result.data.since_long_you_know)
              : ""
          );
          setValue("ctr_roid", result.data.ctr_roid);
          setValue(
            "ref_skills_name",
            arrayOptionData(result.data.ref_skills, "skill_name", "skill_id")
          );
          setValue(
            "ref_tools_name",
            arrayOptionData(result.data.ref_tools, "tool_name", "tool_id")
          );
          setOrgSaveData(result.data.ref_organizations);

          // setOrgCheck(result.data.ctr_roid);
          // setSkillData(result.data.result[0].ctr_skills);
          // setToolData(result.data.result[0].ctr_tools);
          // const skillDa = Array();
          // result.data.ref_skills.map((sk: any) => {
          //   if (sk.skill_name !== null) {
          //     skillDa.push(sk.skill_name);
          //   }
          // });
          // setSkillData(skillDa);
          // setValue("skill", skillDa);
          // const toolDa = Array();
          // result.data.ref_tools.map((sk: any) => {
          //   if (sk.tool_name !== null) {
          //     toolDa.push(sk.tool_name);
          //   }
          // });
          // setToolData(toolDa);
          // setValue("tool", toolDa);
          setshowLoading(false);
        } else {
          setshowLoading(false);
        }
      })
      .catch((err) => {});
  };

  React.useEffect(() => {
    // setdisable(initialValueData.project_ongoing);
    // setPhotoName(initialValueData.photo);
    setNextbtn(false);
    if (eventType === "Add") {
      setCtrrid("");
      setOrgSaveData([]);
    } else {
      setCtrrid(initialValueData.ctr_rid);
      getReferalData(initialValueData.ctr_rid);
    }

    // setValue("photo", initialValueData.photo);
    // setValue("project_title", initialValueData.project_title);
    // setValue("role", initialValueData.role);
    // setValue("start_date", initialValueData.start_date);
    // setValue("end_date", initialValueData.end_date);
    // setValue("project_ongoing", initialValueData.project_ongoing);
    if (initialValueData.linkedin_url !== "") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [resetForm]);

  // upload file
  const handleFiles = (e) => {
    const files = e.target.files;
    preventBrowserDefaults(e);
    setDragOverlay(false);
    setError(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
        setPhotoName("");
      }
      return false;
    }
    setError("");
    setShowAlert(true);
    setshowConfirm(files);
  };

  const fileReader = (files: any) => {
    const fileData = files;
    const reader = new FileReader();
    if (showConfirm) {
      setshowLoading(true);
      fileUpload(fileData[0], folderkey).then((e) => {
        if (apiCallBack(e)) {
          setshowLoading(false);
          setPhotoName(e.filename);
          setValue("photo", fileData[0]);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
        } else {
          setshowLoading(false);
          setToastColor("danger");
          setValue("photo", "");
          setToastMessage(
            e.message !== undefined ? e.message : "Please try again"
          );
          setShowToast(true);
        }
      });
    } else {
      setError("");
      setPhotoName("");
    }
  };

  // const addSkillResult = () => {
  //   if (skillDatavalue !== "") {
  //     const result = [...skillData];
  //     result.push(skillDatavalue[0]);
  //     const result2 = result.filter((v, i) => {
  //       if (v !== undefined) return result.map((val) => val).indexOf(v) === i;
  //     });
  //     setValue("skill", result2);
  //     setSkillData(result2);
  //     setSkillDatavalue([]);
  //   }
  // };
  // const handleRemoveSkillItem = (e: any) => {
  //   setSkillData(skillData.filter((item) => item !== e));
  //   setValue(
  //     "skill",
  //     skillData.filter((item) => item !== e)
  //   );
  // };

  // const addToolResult = () => {
  //   if (toolDatavalue !== "") {
  //     const result = [...toolData];
  //     result.push(toolDatavalue[0]);
  //     const result2 = result.filter((v, i) => {
  //       if (v !== undefined) return result.map((val) => val).indexOf(v) === i;
  //     });
  //     setValue("tool", result2);
  //     setToolData(result2);
  //     setToolDatavalue([]);
  //   }
  // };

  // const handleRemoveToolItem = (e: any) => {
  //   setToolData(toolData.filter((item) => item !== e));
  //   setValue(
  //     "tool",
  //     toolData.filter((item) => item !== e)
  //   );
  // };

  // update api call
  const saveReferenceData = (values: any) => {
    setUploading(true);
    setshowLoading(true);
    values.ctr_id = ctrid;
    // values.photo = photoName;
    values.country_code = "+91";
    values.office_country_code = "+91";
    values.office_no = values.office_no ?? "";
    values.linkedin_url = values.linkedin_url ?? "";
    values.time_for_contact = values.time_for_contact ?? "";
    if (eventType !== "Add") {
      values.ctr_rid = Ctrrid;
    }
    saveReference(values).then((e: any) => {
      if (apiCallBack(e)) {
        const dd = e.data;
        setCtrrid(dd.ctr_rid);
        setrestoreData(dd.result);
        setshowLoading(false);
        setUploading(false);
        const returnOrganisation = dd.result.filter(
          (e) => e.ctr_rid === String(dd.ctr_rid)
        );
        if (returnOrganisation !== undefined) {
          setSkillData(
            arrayOptionData(
              returnOrganisation[0].ctr_skills,
              "skill_name",
              "skill_id"
            )
          );
          setToolData(
            arrayOptionData(
              returnOrganisation[0].ctr_tools,
              "tool_name",
              "tt_id"
            )
          );
          setOrgData(returnOrganisation[0].ref_organizations);
        }

        if (eventType === "Add") {
          if (dd.deika_tokens !== undefined && dd.deika_tokens !== null) {
            const newValue: any[] = [];
            auth.dekiaa.map((dk: any, index: any) => {
              if (dk.action_title === "REFERENCECOMPLETED") {
                const data = dd.deika_tokens;
                newValue.push(data);
              } else {
                newValue.push(dk);
              }
              if (dk.action_title !== "REFERENCECOMPLETED") {
                newValue.push(dd.deika_tokens);
              }
            });
            setDeikaa(newValue);
          }
        }

        setToastColor("success");
        setToastMessage("Reference information updated");
        setShowToast(true);
        setNextbtn(true);
        // closeModal(false);
      } else {
        setshowLoading(false);
        setToastColor("danger");
        setToastMessage(e.message);
        setShowToast(true);
        setUploading(false);
      }
    });
    // } else {
    //   values.ctr_rid = initialValueData.ctr_rid ?? Ctrrid;

    //   updateReference(values).then((e) => {
    //     if (apiCallBack(e)) {
    //       setrestoreData(e.data);
    //       setToastColor("success");
    //       setToastMessage("Reference updated");
    //       setShowToast(true);
    //       setUploading(false);
    //       closeModal(false);
    //     } else {
    //       setToastColor("danger");
    //       setToastMessage(e.message);
    //       setShowToast(true);
    //       setUploading(false);
    //     }
    //   });
    // }
  };

  const BackgroundData = (dat: any) => {
    setshowLoading(true);
    const result = {
      since_long_you_know: dat.since_long_you_know,
      ctr_rid: Ctrrid,
      organs: orgSaveData,
      ref_skills_name: arrayOptionNameData(dat.ref_skills_name, "label"),
      ref_tools_name: arrayOptionNameData(dat.ref_tools_name, "label"),
    };
    BackgroundreferenceData(result).then((e: any) => {
      if (apiCallBack(e)) {
        setToastColor("success");
        setToastMessage(e.message);
        setShowToast(true);
        setUploading(false);
        setshowLoading(false);
        closeModal(false);
      } else {
        setshowLoading(false);
        setToastColor("danger");
        setToastMessage(e !== undefined ? e.message : "please try again");
        setShowToast(true);
        setUploading(false);
      }
    });
  };

  const setOrgSaveDatas = (d: any, da: any) => {
    if (d) {
      setOrgSaveData([...orgSaveData, da]);
    } else {
      setOrgSaveData(
        orgSaveData.filter(
          (item: any) => item.organization_name !== da.organization_name
        )
      );
    }
  };

  const setorganizationDatas = (d: any) => {
    if (d) {
      setOrgData(orgData.concat(d));
    }
  };
  return (
    <>
      <IonAlert
        isOpen={showAlert1}
        mode={"ios"}
        header={"Are you sure?"}
        subHeader={"Continue to delete the Reference."}
        message={"Confirm here"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert1(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert1(false);
              deleteData(initialValueData.ctr_rid);
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={""}
        subHeader={"Continue uploading this file?"}
        message={"Confirm here"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
              setshowConfirm("");
              setError("");
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert(false);
              fileReader(showConfirm);
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(BackgroundData)}>
              {!nextbtn ? (
                <IonRow>
                  <IonCol sizeLg="12" sizeXs="12">
                    <small className="text-gray">STEP 1/2</small>
                    <h2 className="mt-0">Personal details</h2>
                  </IonCol>
                  <IonCol sizeLg="6" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        First name <span className="required">*</span>
                      </IonLabel>
                      <Controller
                        control={control}
                        name="firstname"
                        rules={{
                          required: "First name is required",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            autocomplete="off"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={0}
                            maxlength={32}
                            placeholder="Enter the first name"
                          />
                        )}
                      />
                      
                    </IonItem>
                    {showError("firstname")}
                  </IonCol>
                  <IonCol sizeLg="6" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        Last name <span className="required">*</span>
                      </IonLabel>
                      <Controller
                        control={control}
                        name="lastname"
                        rules={{
                          required: "Last name is required",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            autocomplete="off"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={0}
                            maxlength={32}
                            placeholder="Enter the last name"
                          />
                        )}
                      />

                    </IonItem>
                    {showError("lastname")}
                  </IonCol>
                  <IonCol sizeLg="6" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        Personal phone <span className="required">*</span>
                      </IonLabel>
                      <Controller
                        control={control}
                        name="mobile"
                        rules={{
                          required: "Personal Phone is required",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Invalid Personal phone number",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            autocomplete="off"
                            type="tel"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={0}
                            maxlength={10}
                            placeholder="Enter the Personal phone"
                          />
                        )}
                      />
                    
                    </IonItem>
                    {showError("mobile")}
                  </IonCol>
                  <IonCol sizeLg="6" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">Office phone</IonLabel>
                      <Controller
                        control={control}
                        name="office_no"
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Invalid Office phone number",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            autocomplete="off"
                            type="tel"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={0}
                            maxlength={10}
                            placeholder="Enter the Office phone"
                          />
                        )}
                      />
                      
                    </IonItem>
                    {showError("office_no")}
                  </IonCol>
                  <IonCol sizeLg="6" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        Email<span className="required">*</span>
                      </IonLabel>
                      <Controller
                        control={control}
                        name="email_id"
                        rules={{
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "invalid email address",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            autocomplete="off"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={0}
                            maxlength={64}
                            placeholder="Enter the email"
                          />
                        )}
                      />
                     
                    </IonItem>
                    {showError("email_id")}
                  </IonCol>

                  <IonCol sizeLg="6" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        Ideal time to contact
                      </IonLabel>
                      <Controller
                        control={control}
                        name="time_for_contact"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            autocomplete="off"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={0}
                            maxlength={160}
                            placeholder="List days & timeslots"
                          />
                        )}
                      />
                    
                    </IonItem>
                    {showError("time_for_contact")}
                  </IonCol>

                  <IonCol sizeLg="12" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">LinkedIn profile</IonLabel>
                      <Controller
                        control={control}
                        name="linkedin_url"
                        rules={{
                          pattern: {
                            value: ValidateUrl,
                            message: "Invalid linkedin profile",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            autocomplete="off"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            placeholder="Enter the linkedin profile"
                          />
                        )}
                      />
                    </IonItem>
                    {showError("linkedin_url")}
                  </IonCol>

                  {/*
                   */}

                  {/* <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Started working together...{" "}
                    <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <input
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        data-date-format="YYYY-MM"
                        type="date"
                        placeholder=""
                      />
                    )}
                    control={control}
                    name="start_date"
                    rules={{ required: "start month is required" }}
                  />
                </IonItem>
                {showError("start_date")}
              </IonCol> */}

                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="text-right">
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="button"
                        onClick={() => {
                          next(["firstname", "lastname", "mobile", "email_id"]);
                        }}
                      >
                        Save & Next
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              ) : (
                <IonRow>
                  <IonCol sizeLg="4" sizeXs="12">
                    <div className="input-div">
                      <IonLabel position="stacked">
                        1. Since when do you know {watch("firstname")}?
                        <span className="required">*</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <DatePick value={value} ChangeEvent={onChange} yearpermit={0} maxDate={new Date()}/>
                        )}
                        control={control}
                        name="since_long_you_know"
                        rules={{ required: "field is required" }}
                      />
                    </div>
                    {showError("since_long_you_know")}
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="footer-section-flex">
                      <span className="px-3">
                        2. Which organization did you work with{" "}
                        {watch("firstname")}?<span className="required">*</span>
                      </span>
                      <IonButton
                        shape="round"
                        fill="clear"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="button"
                        onClick={() => {
                          setRefExpType("Add");
                          setFiltermodelOpen(true);
                        }}
                      >
                        Add organisation
                      </IonButton>
                    </div>

                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <IonRadioGroup value={value} onIonChange={onChange}>
                          <IonGrid>
                            <IonRow>
                              {orgData &&
                                orgData !== undefined &&
                                orgData.map((ea: any, indx: number) => (
                                  <IonCol sizeXs="12" sizeLg="6" key={indx}>
                                    <IonItem lines="none">
                                      <IonLabel className="my-0 mx-2">
                                        <h4>
                                          {watch("firstname") +
                                            ": " +
                                            StringMap(ea.ref_relation)}
                                        </h4>
                                        <h4>
                                          You: {StringMap(ea.designation)}
                                        </h4>
                                        <small className="text-gray">
                                          {StringMap(ea.organization_name)}
                                        </small>
                                      </IonLabel>

                                      <IonCheckbox
                                        slot="start"
                                        checked={
                                          getIndexValue(
                                            ea.organization_name,
                                            orgSaveData,
                                            "organization_name",
                                            "organization_name"
                                          ) === ea.organization_name
                                            ? true
                                            : false
                                        }
                                        onIonChange={(e: any) =>
                                          setOrgSaveDatas(e.detail.checked, ea)
                                        }
                                      />
                                      <IonButton
                                        shape="round"
                                        fill="clear"
                                        color="secondary"
                                        className="remove-shadow capitalizes"
                                        type="button"
                                        onClick={(e) => editOrg(ea)}
                                      >
                                        Edit
                                      </IonButton>
                                    </IonItem>
                                  </IonCol>
                                ))}
                            </IonRow>
                          </IonGrid>
                        </IonRadioGroup>
                      )}
                      control={control}
                      name="ctrorg"
                    />

                    {showError("ctrorg")}
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <span className="px-3">
                      3. Which of your technical skills can {watch("firstname")}{" "}
                      verify? <span className="required">*</span>
                    </span>
                    <IonGrid className="">
                      <IonRow>
                        <IonCol sizeLg="6" sizeXs="12">
                          <Controller
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <div>
                                <Typeahead
                                  allowNew
                                  id="custom-selections-input"
                                  multiple
                                  options={skillData}
                                  onChange={onChange}
                                  // setSkillDatavalue(
                                  //   arrayOptionNameData(e, "label")
                                  // );
                                  newSelectionPrefix="Add a new skill: "
                                  selected={value}
                                  placeholder="Skill Name"
                                />
                                {/* <div
                                  className="plus-btn"
                                  onClick={(e) => {
                                    addSkillResult();
                                  }}
                                >
                                  <img src="assets/image/add.svg" />
                                </div> */}
                              </div>
                            )}
                            control={control}
                            name="ref_skills_name"
                            rules={{ required: "Skill is required" }}
                          />

                          {showError("ref_skills_name")}
                        </IonCol>
                        {/* <IonCol sizeLg="12" sizeXs="12">
                          {skillData.map((s: any, i: number) => (
                            <IonChip color="secondary" key={i}>
                              <IonLabel>{s}</IonLabel>
                              <IonIcon
                                icon={closeOutline}
                                onClick={(e) => handleRemoveSkillItem(s)}
                              />
                            </IonChip>
                          ))}
                        </IonCol> */}
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <span className="px-3">
                      4. Which of your processes and tools can{" "}
                      {watch("firstname")} verify?{" "}
                      <span className="required">*</span>
                    </span>
                    <IonGrid className="">
                      <IonRow>
                        <IonCol sizeLg="6" sizeXs="12">
                          <Controller
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <div>
                                <Typeahead
                                  allowNew
                                  id="custom-selections-input"
                                  multiple
                                  options={toolData}
                                  onChange={onChange}
                                  selected={value}
                                  newSelectionPrefix="Add a new tool: "
                                  placeholder="Enter tools/technologies"
                                />

                                {/* <div
                                  className="plus-btn"
                                  onClick={(e) => addToolResult()}
                                >
                                  <img src="assets/image/add.svg" />
                                </div> */}
                              </div>
                            )}
                            control={control}
                            name="ref_tools_name"
                          />
                          {showError("ref_tools_name")}
                        </IonCol>
                        {/* <IonCol sizeLg="12" sizeXs="12">
                          {toolData.map((s: any, i: number) => (
                            <IonChip color="secondary" key={i}>
                              <IonLabel>{s}</IonLabel>
                              <IonIcon
                                icon={closeOutline}
                                onClick={(e) => handleRemoveToolItem(s)}
                              />
                            </IonChip>
                          ))}
                        </IonCol> */}
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="footer-section-flex">
                      {eventType !== "Add" ? (
                        <IonButton
                          shape="round"
                          fill="clear"
                          color="danger"
                          className="remove-shadow capitalizes"
                          type="button"
                          onClick={() => setShowAlert1(true)}
                        >
                          Delete Reference
                        </IonButton>
                      ) : (
                        <div></div>
                      )}
                      <div>
                        <IonButton
                          shape="round"
                          fill="solid"
                          color="secondary"
                          className="remove-shadow capitalizes"
                          type="submit"
                        >
                          {uploading ? "Uploading" : "Save"}
                        </IonButton>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
              )}
            </form>
          </FormProvider>
        </IonGrid>
        <ModalBody
          title={refExpType + " experience "}
          subTitle=""
          isOpen={filtermodelOpen}
          onClose={() => setFiltermodelOpen(false)}
        >
          <RefeExperienceForm
            eventType={refExpType}
            initialValueData={editOrganisation}
            dataset={filtermodelOpen}
            setorganizationData={(e: any) => setorganizationDatas(e)}
            ctrrid={Ctrrid}
            closeModal={() => setFiltermodelOpen(false)}
          />
        </ModalBody>
      </IonCard>
    </>
  );
}

export default ReferenceForm;

// image upload

const preventBrowserDefaults = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const fileValidator = (files: any, config: any) => {
  const { allowedFileFormats, fileSizeMBLimit, filesLimit } = config;
  const { length } = files;
  const { size, type } = files[0];
  let err = "";
  let result = {
    isValidFile: false,
    errVal: err,
  };
  if (length === 0) {
    return result;
  } else if (length > filesLimit) {
    err =
      filesLimit > 1
        ? `Only ${filesLimit} files are allowed to upload`
        : `Only one file is allowed to upload`;
  } else if (!allowedFileFormats.includes(type)) {
    err = "File format must be either .jpg and .png";
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    err = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  result.errVal = err;
  return result;
};

const config = {
  allowedFileFormats: ["image/jpeg", "image/jpg", "image/png"],
  fileSizeMBLimit: 5,
  filesLimit: 1,
};
