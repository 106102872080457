import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
} from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import React from "react";
import { ModalBody, ReadMoreRichText } from "../../../../../component";
import { FindDeikaaKey, StringMap } from "../../../../../service";
import { AuthContext } from "../../../../../context/authContext";
import { DeikaaChip } from "../../../Deikaa/deikaa";
import { AboutForm } from "../../Form";
import { decrypted } from "../../../../../service/axios";

type props = {
  ctrdata: any;
  Userrole: string;
  Profileheadline: string;
  Summary: string;
  videourl: string;
};

const About: React.FC<props> = ({
  Userrole,
  Profileheadline,
  Summary,
  videourl,
  ctrdata,
}) => {
  const { auth } = React.useContext(AuthContext);
  const [modelOpen, setModal] = React.useState(false);
  const [role, setRole] = React.useState(decrypted(Userrole));
  const [headline, setHeadline] = React.useState(Profileheadline);
  const [summaryData, setSummary] = React.useState(Summary);
  const [video, setVideo] = React.useState(decrypted(videourl));

  function reloadData(data: any) {
    if (data) {
      setRole(data.jobrole);
      setHeadline(data.headline);
      setSummary(data.about);
      setVideo(data.video);
    }
  }

  React.useEffect(() => {
    if (auth.dekiaaFunvalue === "VIDEOUPLOAD") {
      setModal(true);
    } else {
      setModal(false);
    }
  }, [auth.dekiaaFunvalue]);

  return (
    <div id="about">
      <ModalBody
        title={"About"}
        isOpen={modelOpen}
        onClose={() => {
          setModal(false);
        }}
      >
        <AboutForm
          ctrid={ctrdata}
          modalSet={modelOpen}
          Userrole={role}
          Profileheadline={headline}
          Summary={summaryData}
          videourl={video}
          closeModal={() => setModal(false)}
          refreshData={reloadData}
        />
      </ModalBody>

      <IonItem lines="none" className="custom-header-space ">
        <h4>About</h4>
        <IonButton
          slot="end"
          fill="outline"
          className="remove-shadow custom-circle-btn"
          color="medium"
          shape="round"
          onClick={() => {
            setModal(true);
          }}
        >
          <IonIcon icon={pencilOutline} slot="icon-only" />
        </IonButton>
      </IonItem>

      <IonGrid>
        <IonRow>
          <IonCol sizeLg="4" sizeXs="12">
            <label>
              Job role <span className="required">*</span>
            </label>
          </IonCol>
          <IonCol sizeLg="8" sizeXs="12">
            <span>{StringMap(role)}</span>
            <hr />
          </IonCol>
          <IonCol sizeLg="4" sizeXs="12">
            <label>
              Profile heading <span className="required">*</span>
            </label>
          </IonCol>

          <IonCol sizeLg="8" sizeXs="12">
            <span>{StringMap(headline)}</span>
            <hr />
          </IonCol>
          <IonCol sizeLg="4" sizeXs="12">
            <label>Summary</label>
          </IonCol>
          <IonCol sizeLg="8" sizeXs="12">
            <span>
              <ReadMoreRichText length={250}>{summaryData}</ReadMoreRichText>

              {/* <ReadMore>{StringMap(getText(summaryData))}</ReadMore> */}
            </span>
            <hr />
          </IonCol>
          <IonCol sizeLg="4" sizeXs="12">
            <div>
              <label>Profile video</label>
            </div>
            <DeikaaChip
              data={FindDeikaaKey(auth.dekiaa, "action_title", "VIDEOUPLOAD")}
            />
          </IonCol>
          <IonCol sizeLg="8" sizeXs="12">
            {video ? (
              <a href={video} target="_blank">
                {video}
              </a>
            ) : (
              "NA"
            )}
            {/* 20 MB size limit; 30-60s ideal video length. */}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default React.memo(About);
