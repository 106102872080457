import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import React from "react";
import { AuthContext } from "../../../../../context/authContext";
import { DateStringMap, SeverfilePaths } from "../../../../../service";
import { Staff, TRM, Company } from "../../../../../service/default";
import { ReadMoreRichText } from "../../../../../component";
type props = {
  personalData: any;
  ctrId: string;
  folderKey: string;
};

const Career: React.FC<props> = ({ personalData, ctrId, folderKey }: any) => {
  return (
    <div id="career">
      <IonCard className="pad-style">
        <ExperienceSection
          orgData={personalData}
          ctrid={ctrId}
          folderkey={folderKey}
        />
      </IonCard>
    </div>
  );
};

export default React.memo(Career);

// experience Section
const ExperienceSection = ({ orgData, folterkey, ctrid }: any) => {
  const [expSlice, setexpslice] = React.useState(3);
  const [experienceData, setExperience] = React.useState([]);
  const [projectData, setProject] = React.useState([]);
  const [dataupdated, setDataupdated] = React.useState(false);

  // experience
  React.useEffect(() => {
    if (!dataupdated) {
      setExperience(orgData.organization);
      setProject(orgData.projects);
      // Getroleempid();
      setDataupdated(true);
    }
  }, []);

  // map organization based on project
  function filterArray(value, arr, key) {
    const pushData: any[] = [];
    if (arr !== undefined) {
      arr.map((e: any) => {
        if (e[key] === value) {
          pushData.push(e);
        }
      });
      return pushData;
    }
  }

  return (
    <>
      <IonItem lines="none" className="custom-header-space ">
        <h4>
          {experienceData?.length} Experience
          {experienceData?.length > 1 && "s"}
        </h4>
      </IonItem>
      {dataupdated && (
        <IonGrid className="ion-no-padding">
          {experienceData?.length > 0 &&
            experienceData
              .sort(
                (a: any, b: any) => b.currently_working - a.currently_working
              )
              .slice(0, expSlice)
              .map((item: any, index: number) => (
                <div key={index}>
                  <IonRow>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem
                        lines="none"
                        className="content-gray custom-label"
                      >
                        {DateStringMap(item.start_date) + " - "}
                        {item.currently_working !== "0" ? (
                          <span className="highlightContent"> present</span>
                        ) : (
                          DateStringMap(item.end_date)
                        )}
                      </IonItem>
                    </IonCol>

                    <IonCol sizeLg="8" sizeXs="12">
                      <IonItem lines="none" className="custom-subheader-space ">
                        <IonLabel>
                          <h6>
                            <b>{item.company_name}</b>
                          </h6>
                          <p>{item.geo_location}</p>
                          <p>{item.contractor_role}</p>
                        </IonLabel>
                      </IonItem>
                      <p>
                        <ReadMoreRichText length={150}>
                          {item.description}
                        </ReadMoreRichText>
                      </p>
                      <ProjectSection
                        ctrId={ctrid}
                        orgId={item.ctr_ehid}
                        folterKey={folterkey}
                        organizationData={experienceData}
                        proData={filterArray(
                          item.ctr_ehid,
                          projectData,
                          "ctr_ehid"
                        )}
                      />
                    </IonCol>
                  </IonRow>

                  {index !== experienceData.slice(0, expSlice).length - 1 && (
                    <hr />
                  )}
                </div>
              ))}
          {experienceData.length > 3 && <hr />}
          {experienceData.length > 3 && (
            <IonItem lines="none">
              {experienceData.length > 0 &&
              experienceData.length !== expSlice &&
              experienceData.length >= expSlice ? (
                <IonButton
                  slot="end"
                  fill="clear"
                  color="secondary"
                  type="button"
                  size="small"
                  className="remove-shadow lowercase"
                  onClick={() => setexpslice(expSlice + 3)}
                >
                  {experienceData.length - expSlice} more experience
                  {experienceData.length > 3 && "(s)"}
                </IonButton>
              ) : (
                experienceData.length > 3 &&
                experienceData.length <= expSlice && (
                  <IonButton
                    slot="end"
                    fill="clear"
                    color="secondary"
                    type="button"
                    size="small"
                    className="remove-shadow lowercase"
                    onClick={() => setexpslice(3)}
                  >
                    less experience{experienceData.length > 3 && "(s)"}
                  </IonButton>
                )
              )}
            </IonItem>
          )}
        </IonGrid>
      )}
    </>
  );
};

// Project Section
const ProjectSection = ({ proData, folterKey }: any) => {
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  const [proSlice, setproslice] = React.useState(1);
  const [projectData, setProject] = React.useState(proData);
  const [dataupdated, setDataupdated] = React.useState(false);

  React.useEffect(() => {
    if (!dataupdated) {
      setProject(proData);
      setDataupdated(true);
    }
  }, []);

  return (
    projectData &&
    projectData.length !== 0 && (
      <>
        <hr />
        <IonItem lines="none" className="custom-header-space ">
          <IonLabel>
            {projectData && projectData.length} Project
            {projectData.length >= 2 && "s"}
          </IonLabel>
        </IonItem>
        {dataupdated && (
          <IonGrid className="ion-no-padding">
            {projectData.length > 0 &&
              projectData
                .sort((a: any, b: any) => b.current_project - a.current_project)
                .slice(0, proSlice)
                .map((item: any, index: number) => (
                  <div key={index}>
                    <IonRow>
                      <IonCol sizeLg="12" sizeXs="12">
                        <IonItem
                          lines="none"
                          className="custom-subheader-space "
                        >
                          <IonLabel style={{ fontWeight: "bold" }}>
                            <h6>
                              <b>{item.project_name}</b>{" "}
                              <span>{" • " + item.role}</span>
                            </h6>
                            <p className="content-gray custom-label">
                              {DateStringMap(item.start_date) + " - "}
                              {item.current_project !== "0" && (
                                <span className="highlightContent">
                                  present
                                </span>
                              )}
                              {item.current_project !== "1" &&
                                DateStringMap(item.end_date)}
                            </p>
                            {userdata &&
                            (userdata.user_type === TRM ||
                              userdata.user_type === Company ||
                              userdata.user_type === Staff) ? (
                              <p>
                                Doc:-{" "}
                                <a
                                  rel="noreferrer"
                                  href={
                                    SeverfilePaths(folterKey) +
                                    item.project_docs
                                  }
                                  target="_blank"
                                >
                                  {item.project_docs}
                                </a>
                              </p>
                            ) : null}
                          </IonLabel>
                        </IonItem>
                        <ReadMoreRichText length={250}>
                          {item.description}
                        </ReadMoreRichText>
                      </IonCol>
                    </IonRow>
                    {index !== projectData.slice(0, proSlice).length - 1 && (
                      <hr />
                    )}
                  </div>
                ))}

            {projectData.length > 1 && projectData.length !== proSlice && (
              <IonItem lines="none">
                {projectData.length >= proSlice ? (
                  <IonButton
                    fill="clear"
                    slot="end"
                    color="secondary"
                    type="button"
                    size="small"
                    className="remove-shadow lowercase"
                    onClick={() => setproslice(proSlice + 3)}
                  >
                    {projectData.length - proSlice} more project
                    {projectData.length > 1 && "(s)"}
                  </IonButton>
                ) : (
                  projectData.length > 1 &&
                  projectData.length <= proSlice && (
                    <IonButton
                      fill="clear"
                      color="secondary"
                      type="button"
                      size="small"
                      className="remove-shadow lowercase"
                      onClick={() => setproslice(1)}
                    >
                      less project{projectData.length > 1 && "(s)"}
                    </IonButton>
                  )
                )}
              </IonItem>
            )}
          </IonGrid>
        )}
      </>
    )
  );
};
