import { IonButton, IonItem, IonIcon } from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import React from "react";
import { CommonSection } from ".";
import { ModalBody } from "../../../../../component";
import { ToolsForm } from "../../Form";

interface props {
  toolsData: any;
  proficiencytypelist: any;
  ctridData: any;
  listoftooloptionData: any;
}

export const Tools: React.FC<props> = ({
  ctridData,
  toolsData,
  proficiencytypelist,
  listoftooloptionData,
}) => {
  const [toolsDisp, setToolsDisp] = React.useState([]);
  const [firstRun, setFirstRun] = React.useState(true);
  const [modelOpen, setModal] = React.useState(false);

  React.useEffect(() => {
    if (firstRun) {
      setToolsDisp(toolsData);
      setFirstRun(false);
    }
  }, []);

  function setToolsDataInMainPage(tools: any) {
    setToolsDisp(tools);
  }

  return (
    <div id="tools">
      <ModalBody
        title={"Tools"}
        isOpen={modelOpen}
        onClose={() => setModal(false)}
      >
        <ToolsForm
          ctrid={ctridData}
          modelClick={modelOpen}
          Listoftools={listoftooloptionData}
          proficiencytypelist={proficiencytypelist}
          closeModal={() => setModal(false)}
          toolsDisp={toolsDisp}
          setToolsDataInMainPage={setToolsDataInMainPage}
        />
      </ModalBody>

      <IonItem lines="none" className="custom-header-space ">
        <h4>Tools</h4>
        <IonButton
          slot="end"
          fill="outline"
          className="remove-shadow custom-circle-btn"
          color="medium"
          shape="round"
          onClick={() => {
            setModal(true);
          }}
        >
          <IonIcon icon={pencilOutline} slot="icon-only" />
        </IonButton>
      </IonItem>

      {toolsDisp && <CommonSection arrs={toolsDisp} type={"skills"} />}
    </div>
  );
};

export default React.memo(Tools);
