import {
  IonButton,
  IonCol,
  IonIcon,
  IonGrid,
  IonItem,
  IonRow,
  IonToast,
} from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import React from "react";
import {
  arrayKeymatch,
  arrayOptionData,
  arrayOptionNameData,
  getIndexValue,
  StringMap,
} from "../../../../../service";
import { ModalBody } from "../../../../../component";
import PracticeForm from "../../Form/PracticeForm";
import { getcontractorpractice } from "../../../Service/api";

interface props {
  practiceData: any;
  proficiencytypelist: any;
  ctridData: any;
  ListofskillsOptionData: any;
}
export const Practice: React.FC<props> = ({
  ctridData,
  practiceData,
  proficiencytypelist,
  ListofskillsOptionData,
}) => {
  const [modelOpen, setModal] = React.useState(false);
  const [localSkills, setLocalSkills] = React.useState<any>([]);
  const [ListofpracticeOptionDatas, setListofpracticeOptionDatas] =
    React.useState<any>([]);
  const [needToUpdate, setNeedToUpdate] = React.useState(false);
  const [load, setLoad] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  React.useEffect(() => {
    let isMounted = true;
    setNeedToUpdate(true);
    try {
      getpracticeData(practiceData, "ids");
      setNeedToUpdate(false);
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
      }
    } finally {
      isMounted && setNeedToUpdate(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const getpracticeData = (data, type) => {
    getcontractorpractice("").then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setListofpracticeOptionDatas(e.data);
        if (type === "ids") {
          setLocalSkills({
            practice1:
              getIndexValue(
                data.practice1,
                e.data,
                "ctr_pa",
                "practice_name"
              ) ?? "",
            practice2:
              getIndexValue(
                data.practice2,
                e.data,
                "ctr_pa",
                "practice_name"
              ) ?? "",
          });
          setNeedToUpdate(false);
        }
      } else {
        setNeedToUpdate(false);
      }
    });
  };

  function sendUpdatedDataToMainPages(data: any) {
    if (data) {
      setLocalSkills({
        practice1: data.practice1 ?? "",
        practice2: data.practice2 ?? "",
      });
    }
  }

  return (
    <div id="skills">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      {!needToUpdate ? (
        <>
          <ModalBody
            title={"Marketable core skills"}
            isOpen={modelOpen}
            onClose={() => setModal(false)}
          >
            <PracticeForm
              ctrid={ctridData}
              proficiencytypelist={""}
              closeModal={() => setModal(false)}
              practiceDatas={localSkills}
              modelClick={modelOpen}
              ListofskillsOption={arrayOptionData(
                ListofpracticeOptionDatas,
                "practice_name",
                "ctr_pa"
              )}
              sendUpdatedDataToMainPage={sendUpdatedDataToMainPages}
              deleteStateValue={""}
            />
          </ModalBody>

          <IonItem lines="none" className="custom-header-space ">
            <h4>Marketable core skills</h4>
            <IonButton
              slot="end"
              fill="outline"
              className="remove-shadow custom-circle-btn"
              color="medium"
              shape="round"
              onClick={(e) => {
                setModal(true);
              }}
            >
              <IonIcon icon={pencilOutline} slot="icon-only" />
            </IonButton>
          </IonItem>
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol sizeLg="4" sizeXs="12">
                <label>Skill 1</label>
              </IonCol>
              <IonCol sizeLg="8" sizeXs="12">
                <span>{StringMap(localSkills.practice1)}</span>
                <hr />
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <label>Skill 2</label>
              </IonCol>

              <IonCol sizeLg="8" sizeXs="12">
                <span>{StringMap(localSkills.practice2)}</span>
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      ) : null}
    </div>
  );
};

export default React.memo(Practice);
