import React, { Suspense } from "react";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import { AnalyticsProvider } from "use-analytics";
import { getCookie } from "./service/Cookies";
import Routes from "./Routes/Routes";
import AuthContextProvider from "./context/authContext";
import { MobileMenu } from "./component";

const App: React.FC = () => {
  // const [loaded, setLoaded] = React.useState(false);
  // React.useEffect(() => {
  //   if (!loaded) {
  //     const scriptTag = document.createElement("script");
  //     scriptTag.src =
  //       "https://maps.googleapis.com/maps/api/js?key=AIzaSyBUMTVC0fFwSxuTa7VY0Qa-_pUITEfRw2I&libraries=places";
  //     scriptTag.addEventListener("load", () => setLoaded(true));
  //     document.body.appendChild(scriptTag);
  //   }
  // }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <IonApp className="JnG">
        <AnalyticsProvider instance={analytics}>
          <AuthContextProvider>
            <IonReactRouter>
              <MobileMenu />
              <IonRouterOutlet id="main">
                <Routes />
              </IonRouterOutlet>
            </IonReactRouter>
          </AuthContextProvider>
        </AnalyticsProvider>
      </IonApp>
    </Suspense>
  );
};

export default React.memo(App);
setupIonicReact({});
const plugin = {
  name: "custom-analytics",
  page: ({ payload }: any) => {
    const user = getCookie({ key: "user" }).value;

    payload.userId = user ? user.uid : "";
    return payload;
  },
  track: ({ payload }) => {},
};

const analytics = Analytics({
  app: "JnG",
  plugins: [
    plugin,
    googleAnalytics({
      trackingId: "G-LXX4MPWXG5",
    }),
  ],
});
