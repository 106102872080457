import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLoading,
  IonToast,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonFooter,
} from "@ionic/react";
import { send, close } from "ionicons/icons";
import React from "react";
import { Input } from "../../component";
import { getCookie, getMethod, postMethod, Requests, SeverfilePaths } from "../../service";
import ChatList from "./chatList";
import { CRMRole, Company } from "../../service/default";
import { SeverfilePathswithoutEncrypt } from "../../service/axios";

type props = {
  closeAction: Function;
  data: any;
};

class ChatLayout extends React.Component<props, {}> {
  messagesEndRef: any;
  state = {
    userdata: getCookie({ key: "user" }).value,
    formValue: "",
    matches: window.matchMedia("(max-width: 2491px)").matches,
    comname: "",
    conname: "",
    projname: "",
    showLoading: false,
    showToast: false,
    toastMessage: "",
    toastColor: "",
    messages: [],
    key: this.props.data,
  };

  // sent message
  sendMessage = () => {
    this.setState({
      showLoading: true,
    });
    const payload = {
      ctr_id: this.state.key.ctrdata,
      unique_id: this.state.key.unique_id,
      send_by: this.state.userdata.user_type == CRMRole ? Company : this.state.userdata.user_type,
      message: this.state.formValue,
    };
    if (this.state.formValue) {
      const postData = postMethod(Requests.fetchSavechat, payload);
      postData.then((value: any) => {
        if (value !== undefined && value.status === 200) {
          this.GetMessage();
          this.setState({ showLoading: false, formValue: "" });
        } else {
          this.setState({
            showLoading: false,
            toastMessage: value.message,
            toastColor: "danger",
            showToast: true,
          });
        }
      });
    }
  };

  // get message
  GetMessage = () => {
    this.setState({ showLoading: true });
    const FetchData = getMethod(
      Requests.fetchGetchat +
        this.state.key.unique_id +
        "/" +
        this.state.key.ctrdata
    );
    FetchData.then((value: any) => {
      if (value !== undefined && value.data) {
        this.setState({
          messages:  value.data,
          comname: value.com_name ?? "",
          conname: value.ctr_name ?? "",
          projname: value.project_name ??  "",
          showLoading: false,
        });
        this.scrollToBottom();
      } else {
        this.setState({
          comname: value.com_name,
          conname: value.ctr_name,
          projname: value.project_name,
          showLoading: false,
          // toastMessage: value.message,
          // toastColor: "danger",
          // showToast: true,
        });
      }
    });
  };

  scrollToBottom = () => {
    // if (!this.messagesEndRef || !this.messagesEndRef.current) return;
    // const list = document.getElementById("chatcommant");
    // return list?.scrollIntoView({ behavior: "smooth" });
    // this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    // return list && list.scrollToPoint(0, 99999);
    // this.messagesEndRef.current && this.messagesEndRef.current.scrollToBottom();
    // this.messagesEndRef.scrollIntoView({ behavior: "smooth" });/
  };

  componentDidMount() {
    this.GetMessage();
    this.scrollToBottom();
    const handler = (e) => this.setState({ matches: e.matches });
    window
      .matchMedia("(max-width: 2491px)")
      .addEventListener("change", handler);
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  render() {
    return (
      <>
        <IonToast
          isOpen={this.state.showToast}
          onDidDismiss={() => this.setState({ showToast: false })}
          message={this.state.toastMessage}
          duration={5000}
          color={this.state.toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={this.state.showLoading}
          onDidDismiss={() => this.setState({ showLoading: false })}
        />
        <IonHeader className={this.state.matches ? "container" : ""}>
          <IonToolbar color="light" className="px-2 py-3 chat-header-toolbar">
            <IonTitle className="chat-title ">
              {this.state.userdata.user_type === "Company"
                ? this.state.conname
                : this.state.userdata.user_type === "Contractor"
                ? this.state.comname
                : "Chat Message"}

              <p className="text-gray">
                For <span className="text-orange">{this.state.projname}</span>
              </p>
            </IonTitle>

            <IonButtons slot="end">
              
              <IonButton onClick={() => this.props.closeAction()}>
              <IonIcon slot="icon-only" icon={close} />
                
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding chat " id="chatcommant">
          <div className={this.state.matches ? "container" : ""}>
            <div className="msg_history">
              {this.state.messages &&
                this.state.messages.length > 0 &&
                this.state.messages.map((msg, i) => (
                  <ChatList key={i} chatmessage={msg} folderKey={this.state.key['type'] === "" ? SeverfilePaths(this.state.key['folder_key']) : SeverfilePathswithoutEncrypt(this.state.key['folder_key'])} profile={this.state.key['profile_photo']} />
                ))}
            </div>
          </div>
        </IonContent>
        <IonFooter color="light" className="no-border chat-foot container">
          <IonToolbar color="light" className="px-2 py-3 chat-footer-toolbar">
            <Input
              name="message"
              placeholder="Enter text"
              label=""
              type="text"
              required={false}
              value={this.state.formValue}
              onChange={(e) => this.setState({ formValue: e.target.value })}
            />

            {/* <IonButtons slot="end" color="secondary"> */}
            {/* <IonButton
              className="custom-button submit "
              color="secondary capitalize"
              shape="round"
              fill="solid"
              disabled={!this.state.formValue}
              onClick={() => this.sendMessage()}
            >
              Sent
            </IonButton> */}

            <IonButtons slot="end">
              <IonButton
                disabled={!this.state.formValue}
                onClick={(e) => this.sendMessage()}
              >
                <IonIcon slot="icon-only" icon={send} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </>
    );
  }
}

export default ({
  closeAction,
  data,
}: {
  closeAction: Function;
  data: any;
}) => <ChatLayout closeAction={closeAction} data={data}></ChatLayout>;
