import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { createOutline } from "ionicons/icons";
import Pagination from "react-js-pagination";
import { Modal, Search, TableHeader } from "../../component";
import Viewcompany from "./../forms/viewcompanyform";
import { getMethod } from "../../service";

// interface Props extends RouteComponentProps<{}> {}
const ViewCompany: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [comments, setComments] = useState<any>([]);
  const [pageCount, setPageCount] = useState<any>();
  const [tableData, settableData] = useState<any>([]);

  const [offset, setoffset] = useState(0);
  const [perPage] = useState(10);
  const [currentPage, setCurrentPage] = useState<any>(-1);
  const [search, setSearch] = useState("");

  const [sorting, setSorting] = useState({ field: "", order: "" });
  // const [headerchange, setheaderchange] = useState("add");
  const headers = [
    { name: "No#", field: "id", sortable: false },
    { name: "Name", field: "fullname", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Action", field: "action", sortable: false },
  ];

  React.useEffect(() => {
    let isMounted = true;
    try {
      getData();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const getData = () => {
    setShowLoading(true);
    const FetchData = getMethod("/Jngstaff/getCompanies");
    FetchData.then((value: any) => {
      if (value !== undefined && value.status === 200) {
        var slice = value.data.slice(offset, perPage);
        setShowLoading(false);
        setComments(slice);
        settableData(value.data);
        setPageCount(value.data.length);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value.message);
        setShowToast(true);
      }
    });
  };

  const handlePageClick = (e) => {
    const selectedPage = e;
    const num = (selectedPage - 1) * perPage + 10;
    setCurrentPage(selectedPage);
    const data = tableData;
    const slice = data.slice(perPage * (selectedPage - 1), num);
    setComments(slice);
  };

  function onSearch(value) {
    let computedComments = tableData;
    if (value) {
      const computedComment = computedComments.filter(
        (comment) =>
          comment.fullname.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase())
      );
      setPageCount(computedComment.length);
      setComments(computedComment);
    } else {
      setoffset(0);
      setPageCount(1);
      var slice = computedComments.slice(offset, perPage);
      setPageCount(computedComments.length);
      setComments(slice);
    }
  }

  const sortingData = () => {
    let computedComments = tableData;

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
      var slice = computedComments.slice(offset, perPage);
      setPageCount(computedComments.length);
      setComments(slice);
    }
  };

  const edit = (da) => {
    // setheaderchange("edit");

    if (da) {
      window.open("/Company/" + da.com_id, "_blank");
    }
  };

  const setShowModals = () => {
    getData();
    setShowModal(false);
  };
  return (
    <IonPage>
      {/*<HeaderNav /> */}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />

      <IonGrid className="center">
        <IonRow>
          <IonCol offsetLg="1" sizeLg="10" sizeXs="12" sizeSm="6" sizeMd="12">
            <IonGrid>
              <IonRow>
                <IonCol sizeLg="12" sizeXs="12" sizeMd="12">
                  <h2>All Company</h2>
                  <IonItem lines="none">
                    <IonButton
                      className="custom-button"
                      color="secondary capitalize"
                      shape="round"
                      fill="solid"
                      slot="end"
                      onClick={() => setShowModal(true)}
                    >
                      Create Company
                    </IonButton>
                    <IonButton
                      className="custom-button"
                      color="secondary capitalize"
                      shape="round"
                      fill="solid"
                      slot="end"
                      href="/company-legal-files"
                    >
                      View Contracts
                    </IonButton>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol sizeLg="6" size="12"></IonCol>
                <IonCol
                  sizeLg="6"
                  size="12"
                  className="d-flex flex-row-reverse"
                >
                  <Search
                    value={search}
                    onSearch={(value) => {
                      onSearch(value);
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </IonCol>
                <IonCol size="12">
                  <div className="con-table-scrolbar">
                    <table className="table table-bordered table-hover">
                      <TableHeader
                        headers={headers}
                        onSorting={(field, order) => {
                          sortingData();
                          setSorting({ field, order });
                        }}
                      />
                      <tbody>
                        {comments.map((comment, index) => (
                          <tr>
                            <th scope="row" key={index}>
                              {index + 1}
                            </th>
                            <td>{comment.fullname}</td>
                            <td>{comment.email}</td>
                            <td>
                              <IonButton
                                onClick={() => edit(comment)}
                                shape="round"
                                fill="clear"
                              >
                                <IonIcon icon={createOutline} />
                              </IonButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </IonCol>
                <IonCol size="12">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={pageCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageClick}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <Modal showModal={showModal}>
        <div className="custom-modal">
          <Viewcompany setShowModal={setShowModals} />
        </div>
      </Modal>
    </IonPage>
  );
};

export default React.memo(ViewCompany);
