import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import useMediaQuery from "../../hooks/mediaQuery";
import Navbar from "./NavBar";

export default function HeaderNav() {
  const mQuery = useMediaQuery("(min-width: 768px)");
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  return (
    <IonHeader className="custom-header ion-padding py-1 ion-xs-no-padding">
      {!mQuery ? (
        <IonToolbar>
          <IonTitle>
            <img alt="" className="header-icon" src="assets/image/hirify.png" />
          </IonTitle>
          <IonButtons slot="end">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      ) : (
        <div className={m4kQuery ? "container nav-web" : "nav-web"}>
          <IonTitle>
            <a href="/"><img alt="" className="header-icon" style={{width: '3rem;'}} src="assets/image/hirify.png" /></a>
          </IonTitle>
          <Navbar />
        </div>
      )}
    </IonHeader>
  );
}
