import { postMethod, getMethod, Requests } from "../../../service";
// contractor apis

// career
export function organization(dta: any) {
  return postMethod(Requests.fetchSaveorganizations, dta);
}

export function UpdateOrganization(dta: any) {
  return postMethod(Requests.fetchUpdateorganizations, dta);
}

export function getEngagementtype(dta: any) {
  return getMethod(Requests.fetchResumeworksampleget + dta);
}

export function DeleteOrganization(dta: any) {
  return getMethod(Requests.fetchResumeworksampledelete + dta);
}

// end career

// update reference
export function updateReference(dta: any) {
  return postMethod(Requests.fetchUpdatereference, dta);
}

// save reference
export function saveReference(dta: any) {
  return postMethod(Requests.fetchSavereference, dta);
}

// Backgroundreferencedata

export function BackgroundreferenceData(dta: any) {
  return postMethod(Requests.fetchBackgroundreferencedata, dta);
}

export function getReferenceresult(dta: any) {
  return getMethod(Requests.fetchReference + dta);
}

export function SavereferenceorgData(dta: any) {
  return postMethod(Requests.fetchSavereferenceorg, dta);
}

// delete reference
export function deleteReference(dta: any) {
  return getMethod(Requests.fetchResumeworksampledeletereference + dta);
}

// saveLink
export function saveLink(dta: any) {
  return postMethod(Requests.fetchSaveresumeworksample, dta);
}

export function getLinkdetails(dta: any) {
  return getMethod(Requests.fetchgetworkweblinks + dta);
}

// Locationpreference
export function saveLocation(dta: any) {
  return postMethod(Requests.fetchLocationpreference, dta);
}

export function getLocationdetails(dta: any) {
  return getMethod(Requests.fetchGetlocationpreference + dta);
}

// get workpreferenceinfo
export function getworkPreference(dta: any) {
  return getMethod(Requests.fetchGetworkpermitinfo + dta);
}

export function saveworkpermitinfos(dta: any) {
  return postMethod(Requests.fetchSaveworkpermitinfo, dta);
}

// engagement data

export function getGetengagementdetails(dta: any) {
  return getMethod(Requests.fetchGetengagementdetails + dta);
}

export function saveengagementdetails(dta: any) {
  return postMethod(Requests.fetchUpdateengagementdetails, dta);
}
// end contractor apis

// save contractor skills

export function saveskills(dta: any) {
  return postMethod(Requests.fetchSavectrskill, dta);
}

export function savePractice(dta: any) {
  return postMethod(Requests.fetchSaveCtrPractice, dta);
}

export function saveadditionalskills(dta: any) {
  return postMethod(Requests.fetchSavehighlightedskill, dta);
}

// getskillcount
export function getskillcount(dta: any) {
  return getMethod(Requests.fetchPersonalinfogetcontractorskills + dta);
}

export function getcontractorpractice(dta: any) {
  return getMethod(Requests.fetchcontractorGetPractice);
}
// save contractor tools

export function savetools(dta: any) {
  return postMethod(Requests.fetchSavectrtechnogy, dta);
}

// get contractor tools count
export function gettoolcount(dta: any) {
  return getMethod(Requests.fetchPersonalinfogetcontractortools + dta);
}

// personal section apis
export function Saveaboutinfo(dta: any) {
  return postMethod(Requests.fetchSaveaboutinfo, dta);
}
export function getaboutinfo(dta: any) {
  return getMethod(Requests.fetchGetAboutinfo + dta);
}

export function Getcountry() {
  return getMethod(Requests.fetchGetcountry);
}
export function GetState(dta: any) {
  return postMethod(Requests.fetchStates, dta);
}
export function GetCities(dta: any) {
  return postMethod(Requests.fetchCities, dta);
}
export function Savenameandlocation(dta: any) {
  return postMethod(Requests.fetchSaveNameLocation, dta);
}

// end personal section apis

// Qualification apis
export function savecertification(dta: any) {
  return postMethod(Requests.fetchSavecertification, dta);
}
export function updatecertification(dta: any) {
  return postMethod(Requests.fetchUpdatecertification, dta);
}
export function saveEducation(dta: any) {
  return postMethod(Requests.fetchSaveeducation, dta);
}

export function updateEducation(dta: any) {
  return postMethod(Requests.fetchUpdateeducation, dta);
}

export function getcertificationdata(dta: any) {
  return getMethod(Requests.fetchCertification + dta);
}

export function deleteskills(dta: any) {
  return getMethod(Requests.fetchPersonalinfodeletectrskill + dta);
}
export function deleteaditionalskills(dta: any) {
  return getMethod(Requests.fetchDeletehighlightedskill + dta);
}
export function deleteTools(dta: any) {
  return getMethod(Requests.fetchPersonalinfodeletectrtool + dta);
}
export function saveProject(dta: any) {
  return postMethod(Requests.fetchSaveproject, dta);
}
export function updateProject(dta: any) {
  return postMethod(Requests.fetchUpdateproject, dta);
}
export function Deletecertification(dta: any) {
  return getMethod(Requests.fetchdeletecertification + dta);
}
export function DeleteEducation(dta: any) {
  return getMethod(Requests.fetchdeleteeducation + dta);
}
export function DeleteProject(dta: any) {
  return getMethod(Requests.fetchdeleteproject + dta);
}
export function Languages(dta: any) {
  return getMethod(Requests.fetchgetcontractorlanguage + dta);
}
export function listoflanguages() {
  return getMethod(Requests.fetchgetskillstoolslangs);
}
export function savelanguages(dta: any) {
  return postMethod(Requests.fetchSavectrlang, dta);
}
export function Deletelanguages(dta: any) {
  return getMethod(Requests.fetchdeletectrlang + dta);
}

// end qualification api

// save quickprofile
export function saveQuickProfile(dta: any) {
  return postMethod(Requests.fetchSavequickprofile, dta);
}

export function getQuickProfileData(dta: any) {
  return getMethod(Requests.fetchQuickprofile);
}

export function getDeikaaProfileData(id: any, flag: any) {
  return getMethod(Requests.fetchPersonalinfooptin + id + "/" + flag);
}

// end quickprofile

// reward
export function getRewardData(id: any) {
  return getMethod(Requests.fetchDeikaarewards + id);
}
// end reward

// refe
export function ReferredtofriendsData(dta: any) {
  return postMethod(Requests.fetchReferredtofriends, dta);
}

// job apis
export function getContractorJobtypes(dta: any) {
  return getMethod(Requests.fetchGetContractorJobtypes + dta);
}

export function getContractorFavJobtype(dta: any) {
  return getMethod(Requests.fetchGetContractorFavJobtype + dta);
}

export function GetMatchjobs(dta: any) {
  return postMethod(Requests.fetchGetjobs, dta);
}

export function getAllJob(dta: any) {
  return postMethod(Requests.fetchGetalljobs, dta);
}

export function getpubJobDetail(dta: any) {
  return getMethod(Requests.fetchPublicjobpage + dta);
}

// fav
export function contfavjob(dta: any) {
  return postMethod(Requests.fetchAddfavouritejob, dta);
}
// Applyjobw
export function contApplyjob(dta: any) {
  return postMethod(Requests.fetchApplyjob, dta);
}

export function Updateinvitation(dta: any) {
  return postMethod(Requests.fetchUpdateinvitationrequest, dta);
}

export function JobstatushistoriyData(dta: any) {
  return postMethod(Requests.fetchJobstatushistoriy, dta);
}


export function getpublicjob(dta: any) {
  return postMethod(Requests.fetchPublicjoblist, dta);
}
export function getpublicCanditate(dta: any) {
  return postMethod(Requests.fetchpubliccanditatelist, dta);
}


export function getpublicGetAllResourceDetails(dta: any) {
  return postMethod(Requests.fetchpublicGetAllResourceDetails, dta);
}
