import React, { useRef, useEffect } from 'react';
import { IonSlides, IonSlide } from '@ionic/react';

const slideOpts = {
  initialSlide: 0,
  speed: 1000,
  autoplay: {
    delay: 3000, // Delay between slides in milliseconds (3 seconds in this example)
  },
};

export const SlidesExample: React.FC = () => {
  const slidesRef = useRef<HTMLIonSlidesElement>(null);

  useEffect(() => {
    const slidesElement = slidesRef.current;
    if (slidesElement) {
      slidesElement.startAutoplay(); // Start autoplay when component mounts
    }

    return () => {
      if (slidesElement) {
        slidesElement.stopAutoplay(); // Stop autoplay when component unmounts
      }
    };
  }, []); // Empty dependency array to only run the effect once

  return (
  
      <IonSlides pager={true} options={slideOpts} ref={slidesRef}>
        <IonSlide>
          <div className="slide-background" style={{ backgroundImage: "url('assets/img/1. Home page banner.jpg')" }}>
           <div className='slide-text'>
           <h1>Stay Project Ready!</h1>
          <p>India's unique Staffing Platform for Talent on Demand.</p>
          <a type='button' href='/login' className='btn  btn-lg'>
            LOGIN
          </a>
          </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div className="slide-background" style={{ backgroundImage: "url('assets/img/2. Home page banner.jpg')" }}>
          <div className='slide-text'>
           <h1>Stay Project Ready!</h1>
          <p>India's unique Staffing Platform for Talent on Demand.</p>
          <a type='button' href='/login' className='btn  btn-lg'>
            LOGIN
          </a>
          </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div className="slide-background" style={{ backgroundImage: "url('assets/img/3. Home page banner.jpg')" }}>
          <div className='slide-text'>
           <h1>Stay Project Ready!</h1>
          <p>India's unique Staffing Platform for Talent on Demand.</p>
          <a type='button' href='/login' className='btn  btn-lg'>
            LOGIN
          </a>
          </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div className="slide-background" style={{ backgroundImage: "url('assets/img/4. Home page banner.jpg')" }}>
          <div className='slide-text'>
           <h1>Stay Project Ready!</h1>
          <p>India's unique Staffing Platform for Talent on Demand.</p>
          <a type='button' href='/login' className='btn  btn-lg'>
            LOGIN
          </a>
          </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div className="slide-background" style={{ backgroundImage: "url('assets/img/5. Home page banner.jpg')" }}>
          <div className='slide-text'>
           <h1>Stay Project Ready!</h1>
          <p>India's unique Staffing Platform for Talent on Demand.</p>
          <a type='button' href='/login' className='btn  btn-lg'>
            LOGIN
          </a>
          </div>
          </div>
        </IonSlide>
      </IonSlides>
   
  );
};
