import axios from "axios";
import { getMethod, postMethod } from "./axios";
import { Requests } from "./requests";

// Get Contractor Profile Detail
export function GetContractorProfile(name: any) {
  return getMethod(Requests.fetchGetcontractor + name);
}

export function GetContractorProfileData(id: any) {
  return getMethod(Requests.fetchGetcontractorprofile + id);
}

export function Showinterest(formData: any) {
  return postMethod("Showinterest", formData);
}

//contractor profile upload
export function ContractProfUpload(formData: any) {
  return postMethod(Requests.fetchPostcontractorprofile, formData);
}

// Login
export function LoginApi(formData: any) {
  return postMethod(Requests.fetchLogin, formData);
}

// registation
export function RegisterApi(formData: any) {
  return postMethod(Requests.fetchregistation, formData);
}

// forgot
export function ForgotApi(formData: any) {
  return postMethod(Requests.fetchForgotPwd, formData);
}

// restpassword
export function resetPwd(formData: any) {
  return postMethod(Requests.fetchresetPwd, formData);
}

// change password
export function changePwd(formData: any) {
  return postMethod(Requests.fetchChangePwd, formData);
}
export function changeUsername(formData: any) {
  return postMethod(Requests.fetchUsername, formData);
}


// rating api

export function GetCtrRating(id: any) {
  return getMethod(Requests.fetchGetCtrRating + id);
}

export function SaveCtrRating(formData: any) {
  return postMethod(Requests.fetchSaveCtrRating, formData);
}

// notification unsubscripe

export function subscripeApi(formData: any) {
  return getMethod(Requests.fetchEmailNotificationType + formData);
}
const source = axios.CancelToken.source();
// contractor download
export const exportContractor = async (endpoint) => {
  return await axios
    .get(endpoint, { responseType: "blob", cancelToken: source.token })
    .then((res: any) => {
      return res !== undefined && res.data;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    });
};

export const exportAgencyleadReport = async (endpoint, data) => {
  return await axios
    .post(endpoint, data, { responseType: "blob", cancelToken: source.token })
    .then((res: any) => {
      return res !== undefined && res.data;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    });
};

// upload images and files

export const fileUpload = async (files: any, folderKey: string) => {
  const data = new FormData();
  data.append("files", files);
  data.append("folder_key", folderKey);
  return await axios
    .post(Requests.fetchprofilefile, data, {
      cancelToken: source.token,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res.data.message;
      }
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    });
};
