import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
  IonLoading,
  IonToast,
} from "@ionic/react";
import { closeCircleOutline, createOutline } from "ionicons/icons";
import React from "react";
import Pagination from "react-js-pagination";
import { RouteComponentProps } from "react-router";
import {
  CompanyEmailVerify,
  ModalBody,
  Search,
  TableHeader,
} from "../../../component";
import AddCompanyUser from "./form/addcompanyuser";
import { getCookie, getMethod } from "../../../service";
import swal from "sweetalert";

interface props
  extends RouteComponentProps<{
    companyID?: string;
  }> {}

class CompanyUser extends React.Component<{ props: any }> {
  state = {
    showModal: false,
    showToast: false,
    toastMessage: "",
    location: this.props.props,
    toastColor: "danger",
    showLoading: false,
    totalItems: 0,
    pageCount: 0,
    tableData: [],
    offset: 0,
    perPage: 10,
    currentPage: 1,
    comments: [],
    search: "",
    sorting: { field: "", order: "" },
    getUserRole: [],
    showHeaderType: true,
    showValue: [],
    errormsg: "",
    userdata: getCookie({ key: "user" }).value,
    matches: window.matchMedia("(max-width: 2491px)").matches,
  };
  headers = [
    { name: "Name", field: "fullName", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Role", field: "role", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  componentDidMount() {
    this.GetCU();
    this.GetCR();
    const handler = (e) => this.setState({ matches: e.matches });
    window
      .matchMedia("(max-width: 2491px)")
      .addEventListener("change", handler);
  }

  setShowModals = () => {
    this.GetCU();
    //this.setShowModal(false);
    this.setState({
      showModal: false,
    });
  };

  GetCU = () => {
    // setShowLoading(true);
    this.setState({
      showLoading: true,
    });
    setTimeout(() => {
      const userdata = getCookie({ key: "user" }).value;
      const comiddata =
        this.state.location !== "" ? this.state.location : userdata.com_id;
      const FetchData = getMethod("/Getcompanyusers/" + comiddata);
      FetchData.then((value: any) => {
        //setShowLoading(false);
        this.setState({
          showLoading: false,
        });
        if (value !== undefined && value.status === 200) {
          const finalData = value.data.map(item => ({
            ...item,
            fullName: `${item.firstname} ${item.lastname}` || "",
          }))
          var slice = finalData.slice(this.state.offset, this.state.perPage);
          // setShowLoading(false);
          this.setState({
            showLoading: false,
            comments: slice,
            tableData: finalData,
            pageCount: Math.ceil(finalData.length),
          });
        } else if (value.status === 201) {
          // setShowLoading(false);
          this.setState({
            showLoading: false,
            comments: [],
          });
        }
      });
    }, 100);
  };

  GetCR = () => {
    setTimeout(() => {
      const FetchData = getMethod("Companyuserroles");
      FetchData.then((value: any) => {
        if (value.data !== undefined && value.status === 200) {
          
          this.setState({
            getUserRole: value.data,
          });
        } else {
        }
      });
    }, 100);
  };

  handlePageChange = (e) => {
    const selectedPage = e;
    const num = (selectedPage - 1) * this.state.perPage + 10;
    //setCurrentPage(selectedPage);
    this.setState({
      currentPage: selectedPage,
    });
    const data = this.state.tableData;
    const slice = data.slice(this.state.perPage * (selectedPage - 1), num);
    //setComments(slice);
    this.setState({
      comments: slice,
    });
  };

  onSearch = (value) => {
    let computedComments = this.state.tableData;
    if (value) {
      const computedComment = computedComments.filter(
        (comment: any) =>
          comment.fullName
            .toLowerCase()
            .includes(this.state.search.toLowerCase()) ||
          comment.email.toLowerCase().includes(this.state.search.toLowerCase())
      );
      // setPageCount(computedComment.length);
      this.setState({
        pageCount: computedComment.length,
        comments: computedComment,
      });
    } else {
      // setoffset(0);
      this.setState({
        offset: 0,
        pageCount: 1,
      });
      var slice = computedComments.slice(this.state.offset, this.state.perPage);
      //setPageCount(computedComments.length);
      this.setState({
        pageCount: computedComments.length,
        comments: slice,
      });
    }
  };

  // const sortingData = () => {
  //   let computedComments = tableData;
  //   //Sorting comments
  //   if (sorting.field) {
  //     const reversed = sorting.order === "asc" ? 1 : -1;
  //     computedComments = computedComments.sort(
  //       (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  //     );
  //     var slice = computedComments.slice(offset, perPage);
  //     setPageCount(computedComments.length);
  //     setComments(slice);
  //   }
  // };

  edit = (data: any) => {
    this.setState({
      showModal: true,
      showHeaderType: false,
      showValue: data,
    });
  };

  setHeaderTypes = () => {
    //setHeaderType(true);
    this.setState({
      showHeaderType: true,
    });
    this.GetCU();
  };

  removeUser = (da) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      buttons: ["Cancel", "Ok"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (da) {
          //setShowLoading(true);
          this.setState({
            showLoading: true,
          });
          const FetchData = getMethod("Companyuser/delete_user/" + da.uid);
          FetchData.then((value: any) => {
            if (value.status === 200) {
              this.GetCU();
              this.setState({
                showLoading: true,
              });
            } else {
              this.setState({
                showLoading: false,
                toastColor: "danger",
                toastMessage: value.message,
                showToast: true,
              });
            }
          });
        }
        swal(" user has been deleted successfully!", {
          icon: "success",
        });
      }
    });
  };

  render() {
    return (
      <>
        <IonToast
          isOpen={this.state.showToast}
          onDidDismiss={() =>
            this.setState({
              showToast: true,
            })
          }
          message={this.state.toastMessage}
          duration={5000}
          color={this.state.toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={this.state.showLoading}
          onDidDismiss={() =>
            this.setState({
              showLoading: false,
            })
          }
        />
        {this.state.userdata && this.state.userdata.user_type === "Company" && (
          <CompanyEmailVerify />
        )}
        <div className={this.state.matches ? "container" : ""}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="screen-header">
                  <h3>All Users</h3>
                  <IonButton
                    color="secondary"
                    shape="round"
                    fill="solid"
                    className=" remove-shadow capitalizes"
                    onClick={() =>
                      this.setState({
                        showHeaderType: true,
                        showModal: true,
                        showValue: {
                          firstname: "",
                          lastname: "",
                          username: "",
                          email: "",
                          password: "",
                          role_id: "",
                          status: "",
                        },
                      })
                    }
                  >
                    Create Users
                  </IonButton>
                </div>
              </IonCol>
              <Search
                value={this.state.search}
                onSearch={(value) => {
                  this.onSearch(value);
                  this.setState({
                    search: value,
                    currentPage: 1,
                  });
                }}
              />
              <IonCol sizeLg="12" sizeXs="12">
                <div className="scrollit">
                  <table className="table table-bordered table-hover">
                    <TableHeader
                      headers={this.headers}
                      onSorting={(field, order) => {
                        //sortingData();
                        //setSorting({ field, order });
                        this.setState({
                          sorting: field,
                          order,
                        });
                      }}
                    />
                    {this.state.comments.length > 0 ? (
                      <tbody>
                        {this.state.comments.map((comment: any, index) => (
                          <tr>
                            <td>
                              {comment.fullName}
                            </td>
                            <td>{comment.email}</td>
                            <td>{comment.role_name}</td>

                            <td>
                              <div className="displaybtnresponsive">
                                <IonButton
                                  onClick={() => this.edit(comment)}
                                  shape="round"
                                  fill="clear"
                                >
                                  <IonIcon
                                    icon={createOutline}
                                    color="warning"
                                  />
                                </IonButton>

                                <IonButton
                                  shape="round"
                                  fill="clear"
                                  onClick={() => this.removeUser(comment)}
                                >
                                  <IonIcon
                                    icon={closeCircleOutline}
                                    color="danger"
                                  />
                                </IonButton>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody className="ion-center">
                        <tr>
                          <td className="absolute">No Data Found</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                {this.state.comments.length > 0 && (
                  <Pagination
                    activePage={this.state.currentPage}
                    itemsCountcontractorperPage={this.state.perPage}
                    totalItemsCount={this.state.pageCount}
                    pageRangeDisplayed={8}
                    onChange={this.handlePageChange}
                  />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <ModalBody
          title={this.state.showHeaderType ? "Create User" : "Update User"}
          isOpen={this.state.showModal}
          onClose={(e) => this.setState({ showModal: false })}
        >
          <div className="custom-modal">
            <AddCompanyUser
              userId={
                this.state.location !== ""
                  ? this.state.location
                  : this.state.userdata.com_id
              }
              formreset={this.state.showHeaderType ? "add" : "update"}
              setShowModal={this.setShowModals}
              headerType={this.state.showHeaderType}
              companyUserRole={this.state.getUserRole}
              setHeaderType={this.setHeaderTypes}
              editData={this.state.showValue}
            />
          </div>
        </ModalBody>
      </>
    );
  }
}

export default React.memo(CompanyUser);
