import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { AuthContext } from "../../context/authContext";
import { SeverfilePaths } from "../../service";
import { addDefaultSrc } from "../../service/default";

const MobileMenu = () => {
  const [currentSubMenu, setcurrentSubMenu] = React.useState("");
  const { auth } = React.useContext(AuthContext);
  const CurrentUser = auth.data;
  const history = useHistory();
  const navBar = auth.navList;
  return CurrentUser ? (
    <IonMenu side='start' swipe-gesture='false' contentId='main' type='overlay'>
      <IonHeader className='no-border'>
        <IonToolbar>
          <div className='mobile-menu-header d-flex m-auto'>
            {CurrentUser && (
              <img
                alt=''
                src={SeverfilePaths(CurrentUser.folder_key) + CurrentUser.profile_photo}
                onError={addDefaultSrc}
              />
            )}
            <div className='px-2 my-auto'>
              <span>
                {CurrentUser ? CurrentUser.firstname : "..."}
                <br /> {CurrentUser ? CurrentUser.email : "xxxx@xxx.com"}
              </span>
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonList lines='none'>
          {CurrentUser &&
            navBar.length > 0 &&
            navBar.map((ty: any, inx: number) =>
              ty.User === CurrentUser.user_type ? (
                <div key={ty.User}>
                  {ty.page.map((item: any, i: any) =>
                    item.dropdown === "false" ? (
                      <IonMenuToggle auto-hide='false' key={i}>
                        <IonItem button key={i} onClick={(e) => history.push(item.url)}>
                          <IonLabel>{item.pageName}</IonLabel>
                        </IonItem>
                      </IonMenuToggle>
                    ) : (
                      <>
                        <IonAccordionGroup key={i} value={currentSubMenu}>
                          <IonAccordion
                            value={item.pageName}
                            onClick={(e) => setcurrentSubMenu(item.pageName)}
                          >
                            <IonItem slot='header'>
                              <IonLabel>{item.pageName}</IonLabel>
                            </IonItem>
                            {item.dropdown === "true" &&
                              item.childern.map((child: any, ind: any) => (
                                <IonList slot='content' key={ind} className='ion-no-padding px-3'>
                                  <IonMenuToggle auto-hide='false' key={i}>
                                    <IonItem
                                      onClick={(e) => {
                                        history.push(child.childUrl);
                                      }}
                                    >
                                      <IonLabel>{child.childName}</IonLabel>
                                    </IonItem>
                                  </IonMenuToggle>
                                </IonList>
                              ))}
                          </IonAccordion>
                        </IonAccordionGroup>
                      </>
                    ),
                  )}
                </div>
              ) : null,
            )}

          {/* <IonItem
            button
            key="logout"
            routerLink="/login"
            routerDirection="none"
            onClick={() => {}}
          >
            <IonLabel> LogOut</IonLabel>
          </IonItem> */}
        </IonList>
      </IonContent>
    </IonMenu>
  ) : (
    <IonMenu side='start' swipe-gesture='false' contentId='main' type='overlay'>
      <IonHeader className='no-border'>
        <IonToolbar></IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <IonList lines='none'>
          <IonMenuToggle auto-hide='false'>
            <IonItem
              onClick={(e) => {
                history.push('/listjob');
              }}
            >
              <IonLabel>Jobs</IonLabel>
            </IonItem>
          </IonMenuToggle>
          <IonMenuToggle auto-hide='false'>
            <IonItem
              onClick={(e) => {
                history.push('/list-candidates');
              }}
            >
              <IonLabel>Candidates</IonLabel>
            </IonItem>
          </IonMenuToggle>
          
          <IonMenuToggle auto-hide='false'>
            <IonItem
              onClick={(e) => {
                history.push('/login');
              }}
            >
              <IonLabel>SignIn</IonLabel>
            </IonItem>
          </IonMenuToggle>
          {/* <IonItem
        button
        key="logout"
        routerLink="/login"
        routerDirection="none"
        onClick={() => {}}
      >
        <IonLabel> LogOut</IonLabel>
      </IonItem> */}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default MobileMenu;
