import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import { HeaderNav } from "../../../component";
import useMediaQuery from "../../../hooks/mediaQuery";
import { StringMap } from "../../../service";
import Candidateslist from "./canditatelist";

const MatchJobs = ({ location }) => {
  const history = useHistory();
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const jobDetaildata = location.state;
  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <div className={m4kQuery ? "container Jobs py-4" : "Jobs py-4"}>
          {jobDetaildata && (
            <IonGrid>
              <IonRow>
                <IonCol sizeLg="11" sizeXs="10">
                  <h1 className="detail-header m-0">Matching Candidates</h1>
                </IonCol>
                <IonCol sizeLg="1" sizeXs="1">
                  <IonButton
                    fill="outline"
                    className="roundButton"
                    color="danger"
                    shape="round"
                    onClick={(e) => {
                      history.goBack();
                    }}
                  >
                    <IonIcon icon={closeOutline} slot="icon-only" />
                  </IonButton>
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12">
                  <div className="com-header-details">
                    <h2>{StringMap(jobDetaildata.job_title)}</h2>
                    <small>{StringMap(jobDetaildata.company_name)}</small>{" "}
                    <small className="text-gray">
                      on {moment(jobDetaildata.created_on).format("lll")}{" "}
                    </small>
                  </div>
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12">
                  <Candidateslist
                    uniqueid={jobDetaildata.job_unique_id}
                    jobdetailData={jobDetaildata}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(MatchJobs);
