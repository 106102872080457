import { IonBadge, IonItem, IonLabel } from "@ionic/react";
import React, { InputHTMLAttributes, HtmlHTMLAttributes } from "react";

import "./Input.css";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  value?: any;
  accept?: string;
  id?: string;
  min?: number;
  max?: number;
  maxlength?: number;
  disabled?: boolean;
  ref?: any;
  handleChange?: Function;
}

const Input: React.FC<Props> = ({
  name,
  label,
  type,
  required,
  min,
  max,
  id,
  disabled,
  accept,
  maxlength,
  value,
  placeholder,
  ref,
  handleChange,
  ...rest
}) => {
  return (
    <>
      {type !== "checkbox" ? (
        <div className="input-block">
          {label ? (
            <IonItem lines="none">
              <label slot="start" htmlFor={name}>
                {label} {required && <span className="red">*</span>}
              </label>
              {max && (
                <label slot="end">
                  {value?.length}/ {max} characters
                </label>
              )}
            </IonItem>
          ) : (
            ""
          )}

          <input
            type={type}
            id={id ? id : name}
            name={name}
            value={value}
            accept={accept}
            disabled={disabled}
            minLength={min}
            maxLength={max ? max : maxlength}
            placeholder={placeholder}
            onChange={(e) => handleChange}
            {...rest}
          />
        </div>
      ) : (
        <div className="input-checkbox d-flex">
          <input
            type={type}
            id={name}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={(e) => handleChange}
            {...rest}
          />
          {label ? <label htmlFor={name}>{label}</label> : ""}
        </div>
      )}
    </>
  );
};

export default Input;
