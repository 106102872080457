import { IonIcon } from "@ionic/react";
import { chevronDownOutline, chevronUpOutline } from "ionicons/icons";
import React, { useState } from "react";

const TableHeader = ({ headers, onSorting }: any) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  return (
    <thead className="table-light">
      <tr>
        {headers.map(({ name, field, sortable }) => (
          <th
            key={name}
            onClick={() => (sortable ? onSortingChange(field) : null)}
          >
            {name}

            {sortingField && sortingField === field && (
              <IonIcon
                icon={
                  sortingOrder === "asc" ? chevronDownOutline : chevronUpOutline
                }
              />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
