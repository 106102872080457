import {
  IonButton,
  IonAlert,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
  IonToggle,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";

import moment from "moment";
import { CardProfileImage, Ceditor, DatePick } from "../../../../component";
import React, { useRef, useState } from "react";
import { fileUpload, apiCallBack } from "../../../../service";
import { DeleteProject, saveProject, updateProject } from "../../Service/api";

function ProjectForm({
  setuploading = false,
  setvalues,
  orgIds,
  initialValueData,
  organizationList,
  eventType,
  resetData,
  closeModal,
  ctrid,
  folderkeys,
  deleteProjectData,
}: any) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [uploading, setUploading] = React.useState(setuploading);
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setshowLoading] = React.useState(false);
  // file
  let dragCounter = useRef(0);
  let [dragOverlay, setDragOverlay] = React.useState(false);
  const [error, setError] = React.useState<any>();
  const [showAlert, setShowAlert] = React.useState(false);
  const [showConfirm, setshowConfirm] = React.useState<any>();

  // delete project
  const [showAlert1, setShowAlert1] = React.useState(false);

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  React.useEffect(() => {
    setValue("ctr_ehid", orgIds);
    setValue("project_name", initialValueData.project_name);
    setValue("current_project", initialValueData.current_project);
    setValue(
      "start_date",
      initialValueData.start_date ? new Date(initialValueData.start_date) : ""
    );
    setValue(
      "end_date",
      initialValueData.end_date && !initialValueData.current_project
        ? new Date(initialValueData.end_date)
        : ""
    );
    setValue("project_url", initialValueData.project_url);
    setValue("description", initialValueData.description);
    setValue("project_docs", initialValueData.project_docs);
    setValue("role", initialValueData.role);
    setShow(initialValueData.description ? true : false);
    setData(initialValueData.project_docs);
    // setProjectUrlLink(initialValueData.project_url);
    if (initialValueData.description !== "") {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [setvalues]);

  // save and update api
  const saveProjectData = (values: any) => {
    setshowLoading(true);
    values.ctr_id = ctrid;
    values.description = show !== false ? values.description : "";
    values.project_docs = data
      ? data
      : values.project_docs
      ? values.project_docs.name
      : "";
    values.start_date = values.start_date ? values.start_date : "";
    values.end_date = values.end_date ? values.end_date : "";
    values.current_project = values.current_project ? "1" : "0";
    setshowLoading(true);
    if (eventType === "Add") {
      saveProject(values).then((e) => {
        if (apiCallBack(e)) {
          resetData(e.data);
          setUploading(false);
          setshowLoading(false);
          setToastColor("success");
          setToastMessage(e !== undefined && e.message);
          setShowToast(true);
          closeModal(false);
        } else {
          setUploading(false);
          setshowLoading(false);
          setToastColor("danger");
          setToastMessage(e !== undefined && e.message);
          setShowToast(true);
        }
      });
    } else {
      values.ctr_pro_id = initialValueData.ctr_pro_id;
      updateProject(values).then((e) => {
        if (apiCallBack(e)) {
          resetData(e.data);
          setUploading(false);
          setshowLoading(false);
          setToastColor("success");
          setToastMessage(e !== undefined && e.message);
          setShowToast(true);
          closeModal(false);
        } else {
          setUploading(false);
          setshowLoading(false);
          setToastColor("danger");
          setToastMessage(e !== undefined && e.message);
          setShowToast(true);
        }
      });
    }
  };

  function deleteProject() {
    const id = initialValueData.ctr_pro_id;
    DeleteProject(id).then((e: any) => {
      setshowLoading(false);
      if (e !== undefined && e.status === 200) {
        deleteProjectData(id);
        setToastColor("success");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
        setshowLoading(false);
        closeModal(false);
      } else {
        setToastColor("success");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
        setshowLoading(false);
      }
    });
  }

  // file upload

  const handleFiles = (e) => {
    const files = e.target.files;
    preventBrowserDefaults(e);
    setDragOverlay(false);
    setError(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
        setData("");
      }
      return false;
    }
    setError("");
    setShowAlert(true);
    setshowConfirm(files);
  };

  const handleDrag = (e) => {
    preventBrowserDefaults(e);
  };
  const handleDragIn = (e) => {
    preventBrowserDefaults(e);
    dragCounter.current++;
  };
  const handleDragOut = (e) => {
    preventBrowserDefaults(e);
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragOverlay(false);
    }
  };
  const handleDrop = (e) => {
    const files = e.dataTransfer.files;
    preventBrowserDefaults(e);
    setDragOverlay(false);
    setError(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
        setData("");
      }
      return false;
    }
    setError("");
    setShowAlert(true);
    setshowConfirm(files);
  };

  const fileReader = (files: any) => {
    const fileData = files;
    const reader = new FileReader();
    if (showConfirm) {
      setshowLoading(true);
      fileUpload(fileData[0], folderkeys).then((e) => {
        if (apiCallBack(e)) {
          setData(e.filename);
          setshowLoading(false);
          setValue("project_docs", fileData[0]);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
        } else {
          setshowLoading(false);
          setToastColor("danger");
          setValue("project_docs", "");
          setToastMessage(
            e.message !== undefined ? e.message : "Please try again"
          );
          setShowToast(true);
        }
      });
    } else {
      setError("");
      setData("");
    }
  };

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={""}
        subHeader={"Continue uploading this file?"}
        message={"Confirm here"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
              setshowConfirm("");
              setError("");
              setData("");
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert(false);
              fileReader(showConfirm);
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showAlert1}
        mode={"ios"}
        header={"Are you sure?"}
        subHeader={"Continue to delete the project."}
        message={"Confirm here"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert1(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert1(false);
              deleteProject();
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveProjectData)}>
            <IonRow>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Organization/client <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="ctr_ehid"
                    rules={{
                      required: "Organization/client is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                        placeholder="Select"
                      >
                        <option value="">Select</option>
                        {organizationList !== undefined &&
                          organizationList.map((option: any, i: number) => (
                            <option key={i} value={option.ctr_ehid}>
                              {option.company_name}
                            </option>
                          ))}
                      </select>
                    )}
                  />
                  {/* <IonText className="custom-content right-content-2">
                    <IonButton
                      fill="outline"
                      className="remove-shadow custom-circle-btn"
                      color="medium"
                      shape="round"
                      onClick={() => {
                        setDisableOrganization(!disableOrganization);
                      }}
                    >
                      <IonIcon icon={pencilSharp} slot="icon-only" />
                    </IonButton>
                  </IonText> */}
                </IonItem>
                {showError("ctr_ehid")}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Project name <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="project_name"
                    rules={{
                      required: "Project name is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Enter the project name"
                      />
                    )}
                  />
                
                </IonItem>
                {showError("project_name")}
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Role <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="role"
                    rules={{
                      required: "Role is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Web Developer, Sr. Data Scientist, etc."
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("role")}
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <IonButton
                  fill="clear"
                  color="secondary"
                  type="button"
                  size="small"
                  className="remove-shadow capitalizes"
                  onClick={() => setShow(!show)}
                >
                  Add Role Description
                </IonButton>

                {show && (
                  <>
                    <IonItem lines="none">
                      <IonLabel position="stacked">Role Description</IonLabel>
                      <Controller
                        control={control}
                        name="description"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Ceditor
                            setEditorData={onChange}
                            editorData={value}
                          />
                        )}
                      />
                    </IonItem>
                  </>
                )}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">
                    Start <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick
                        value={value}
                        ChangeEvent={(e) => setValue("start_date", e)}
                      />
                    )}
                    control={control}
                    name="start_date"
                    rules={{ required: "Start month is required" }}
                  />
                </div>
                {showError("start_date")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">End</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick
                        value={value}
                        ChangeEvent={(e) => {
                          setValue("end_date", e);
                          setValue("current_project", false);
                        }}
                        minDate={watch("start_date")}
                        disabled={
                          watch("current_project") === false ? false : true
                        }
                      />
                      // <input
                      //   onBlur={onBlur}
                      //   onChange={(e: any) => {
                      //     setValue("end_date", e.target.value);
                      //     setValue("current_project", false);
                      //   }}
                      //   disabled={
                      //     watch("current_project") === false ? false : true
                      //   }
                      //   value={value}
                      //   type="date"
                      //   placeholder=""
                      // />
                    )}
                    control={control}
                    name="end_date"
                  />
                </div>
                {/* End date is greater then start date */}
                <IonItem lines="none">
                  <label>Ongoing</label>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonToggle
                        checked={value}
                        value={value}
                        slot="end"
                        mode="md"
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue("current_project", e.detail.checked);
                          setValue("end_date", "");
                        }}
                      />
                    )}
                    control={control}
                    name="current_project"
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <div className="custom-file">
                  <div className="upload-img">
                    <CardProfileImage
                      folder_key=""
                      profile_photo="assets/image/file_upload.svg"
                      name="drop"
                    />
                  </div>
                  <div
                    id="drop-area"
                    className="custom-upload"
                    onDragEnter={handleDragIn}
                    onDragLeave={handleDragOut}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  >
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="fileUpload"
                          accept="image/jpeg,image/jpg,image/png,application/pdf,"
                          onChange={(e) => {
                            handleFiles(e);
                          }}
                        />
                      )}
                      control={control}
                      name="project_docs"
                    />

                    <label className="custom-upload-label" htmlFor="fileUpload">
                      <div className="custom-upload-text">
                        <div>
                          <h6>UPLOAD ARTIFACTS</h6>
                          <p>
                            <IonText color="danger">{error && error}</IonText>
                            <IonText color="success">{data && data}</IonText>
                          </p>
                          <h4>
                            Drop files here or{" "}
                            <span className="custom-text">
                              browse your device
                            </span>
                          </h4>
                          <p>Support JPG/PNG/PDF upto 5 MB file</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                {/* <hr /> */}
              </IonCol>
              {/* <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <h6>Project links</h6>
                  <IonButton
                    slot="end"
                    fill="outline"
                    style={{
                      marginRight: "5px",
                    }}
                    className="remove-shadow custom-circle-btn"
                    color="primary"
                    shape="round"
                    onClick={() => {
                      setProjectUrlLink([
                        ...projectUrlLink,
                        { link_label: "Behance", project_link: "", icon: "" },
                      ]);
                    }}
                  >
                    <IonIcon icon={logoBehance} slot="icon-only" />
                  </IonButton>
                  <IonButton
                    slot="end"
                    fill="outline"
                    style={{
                      marginRight: "5px",
                    }}
                    className="remove-shadow custom-circle-btn"
                    color="dark"
                    shape="round"
                    onClick={() => {
                      setProjectUrlLink([
                        ...projectUrlLink,
                        {
                          link_label: "Project repository",
                          project_link: "",
                          icon: "",
                        },
                      ]);
                    }}
                  >
                    <IonIcon icon={logoGithub} slot="icon-only" />
                  </IonButton>
                  <IonButton
                    slot="end"
                    fill="outline"
                    className="remove-shadow custom-circle-btn pr-2"
                    color="danger"
                    style={{
                      marginRight: "5px",
                    }}
                    shape="round"
                    onClick={() => {
                      setProjectUrlLink([
                        ...projectUrlLink,
                        { link_label: "Youtube", project_link: "", icon: "" },
                      ]);
                    }}
                  >
                    <IonIcon icon={logoYoutube} slot="icon-only" />
                  </IonButton>
                  <IonButton
                    slot="end"
                    fill="outline"
                    className="remove-shadow custom-circle-btn"
                    color="secondary"
                    shape="round"
                    onClick={() => {
                      setProjectUrlLink([
                        ...projectUrlLink,
                        createprojectLink(),
                      ]);
                    }}
                  >
                    <IonIcon icon={addOutline} slot="icon-only" />
                  </IonButton>
                </IonItem>
              </IonCol> */}
              {/* <IonCol sizeLg="12" sizeXs="12">
                <IonGrid className="ion-no-padding">
                  {projectUrlLink &&
                    projectUrlLink.map((e: any, i: number) => (
                      <IonRow key={i}>
                        <IonCol sizeLg="6" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Label</IonLabel>
                            <IonInput
                              autocomplete="off"
                              onIonChange={(e) =>
                                onChangeProject(i, e.detail.value, "link_label")
                              }
                              value={e.link_label}
                              placeholder=""
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="6" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Url</IonLabel>
                            <IonInput
                              autocomplete="off"
                              type="url"
                              pattern="/((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/"
                              onIonChange={(e) =>
                                onChangeProject(
                                  i,
                                  e.detail.value,
                                  "project_link"
                                )
                              }
                              value={e.project_link}
                              placeholder=""
                            />
                            <IonButton
                              slot="end"
                              fill="clear"
                              style={{ marginTop: "35px", marginLeft: "15px" }}
                              className="ion-no-padding remove-shadow custom-circle-btn"
                              color="danger"
                              shape="round"
                              onClick={() => {
                                setProjectUrlLink(
                                  projectUrlLink.filter((item) => item !== e)
                                );
                              }}
                            >
                              <IonIcon icon={closeOutline} slot="icon-only" />
                            </IonButton>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    ))}
                </IonGrid>
              </IonCol> */}
              <IonCol sizeLg="12" sizeXs="12">
                <hr />
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  {eventType !== "Add" ? (
                    <IonButton
                      shape="round"
                      fill="clear"
                      color="danger"
                      className="remove-shadow capitalizes"
                      type="button"
                      onClick={() => setShowAlert1(true)}
                    >
                      Delete Project
                    </IonButton>
                  ) : (
                    <div></div>
                  )}
                  <div>
                    {/* {eventType === "Add" && (
                      <IonButton
                        shape="round"
                        fill="outline"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="button"
                      >
                        Save & Add Another
                      </IonButton>
                    )} */}

                    <IonButton
                      shape="round"
                      fill="solid"
                      color="secondary"
                      className="remove-shadow capitalizes"
                      type="submit"
                    >
                      {uploading ? "Uploading" : "Save"}
                    </IonButton>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
}

export default ProjectForm;

const SocialIcons = [
  {
    link_label: "Dribbble Profile",
    icon: "Dribbble.svg",
    project_link: "",
  },

  {
    link_label: "Youtube Profile",
    icon: "Youtube.svg",
    project_link: "",
  },
  {
    link_label: "Behance Profile",
    icon: "Behance.svg",
    project_link: "",
  },
  {
    link_label: "GitHub Profile",
    icon: "Github.svg",
    project_link: "",
  },
];

const preventBrowserDefaults = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const fileValidator = (files: any, config: any) => {
  const { allowedFileFormats, fileSizeMBLimit, filesLimit } = config;
  const { length } = files;
  const { size, type } = files[0];
  let err = "";
  let result = {
    isValidFile: false,
    errVal: err,
  };
  if (length === 0) {
    return result;
  } else if (length > filesLimit) {
    err =
      filesLimit > 1
        ? `Only ${filesLimit} files are allowed to upload`
        : `Only one file is allowed to upload`;
  } else if (!allowedFileFormats.includes(type)) {
    err = "File format must be either .jpg, .png and .pdf";
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    err = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  result.errVal = err;
  return result;
};

const config = {
  allowedFileFormats: [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ],
  fileSizeMBLimit: 5,
  filesLimit: 1,
};
