import React, { useState } from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonToast,
  IonLoading,
  IonCard,
  IonInput,
  IonLabel,
  IonText,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import { createRecruiter, updateRecruiter } from "../staff/Service/api";
import { eye, eyeOffOutline } from "ionicons/icons";
interface user {
  firstname: string;
  lastname: string;
  username: string;
  password: string;
  email: string;
  uid: string;
}

interface Props {
  id: string;
  setShowModal: (boolean) => void;
  formType: string;
  editData: user;
}

const CreateRecruiter: React.FC<Props> = ({
  id,
  setShowModal,
  formType,
  editData,
}) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [showPassword, setPassword] = React.useState(false);

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  function saveUserData(e) {
    setShowLoading(true);
    if (formType === "Add") {
      createRecruiter(e).then((value: any) => {
        if (value.status === 200) {
          setShowLoading(false);
          setToastColor("success");
          setToastMessage(value.message);
          setShowToast(true);
          setShowModal(false);
        } else if (value.status === 201) {
          setShowLoading(false);
          const errorInfo = value.message;
          if (typeof errorInfo === "string") {
            setToastColor("danger");
            setToastMessage(value.message);
            setShowToast(true);
          } else {
            setToastColor("danger");
            for (const [key, value] of Object.entries(errorInfo)) {
              setToastMessage(`${value}`);
              setShowToast(true);
            }
            setShowLoading(false);
          }
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setShowToast(true);
        }
      });
    } else {
      e.uid = editData.uid;
      updateRecruiter(e).then((value: any) => {
        if (value.status === 200) {
          setShowLoading(false);
          setToastColor("success");
          setToastMessage(value.message);
          setShowToast(true);
          setShowModal(false);
        } else if (value.status === 201) {
          setShowLoading(false);
          const errorInfo = value.message;
          if (typeof errorInfo === "string") {
            setToastColor("danger");
            setToastMessage(value.message);
            setShowToast(true);
          } else {
            setToastColor("danger");
            for (const [key, value] of Object.entries(errorInfo)) {
              setToastMessage(`${value}`);
              setShowToast(true);
            }
            setShowLoading(false);
          }
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setShowToast(true);
        }
      });
    }
  }

  React.useEffect(() => {
    if (formType) {
      setValue("firstname", editData.firstname);
      setValue("lastname", editData.lastname);
      setValue("email", editData.email);
      setValue("password", editData.password);
      setValue("username", editData.username);
      setValue("status", "1");
    }
  }, [formType]);

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveUserData)}>
            <IonRow>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    First name<span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="firstname"
                    rules={{
                      required: "Firstname is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={32}
                        placeholder="Enter the firstname"
                      />
                    )}
                  />
                </IonItem>
                {showError("firstname")}
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Last name<span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="lastname"
                    rules={{
                      required: "Lastname is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={32}
                        placeholder="Enter the lastname"
                      />
                    )}
                  />
                </IonItem>
                {showError("lastname")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Choose a username<span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="username"
                    rules={{
                      required: "Username is required",
                      pattern: {
                        value: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
                        message: "Invalid characters",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={32}
                        placeholder="Enter the username"
                      />
                    )}
                  />

                  {/* <IonText className="helperText right-content-2">
                        a-z, 0-9, hyphens (-), and underscore (_)
                      </IonText> */}
                </IonItem>
                {showError("username")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Email<span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="email"
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "invalid email address",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Enter the email"
                      />
                    )}
                  />
                </IonItem>
                {showError("email")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Password<span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="password"
                    rules={{
                      required: "password is required",
                      pattern: {
                        value: /^[ A-Za-z0-9_@./#&+-]*$/,
                        message: "Invalid characters",
                      },
                      minLength: {
                        value: 6,
                        message: "Password must have at least 6 characters",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <>
                        <IonInput
                          autocomplete="off"
                          type={showPassword ? "text" : "password"}
                          onBlur={onBlur}
                          onIonChange={onChange}
                          value={value}
                          minlength={6}
                          maxlength={64}
                          placeholder="Enter the password"
                        />
                        <IonButton
                          className="ion-no-padding customeye-btn"
                          fill="clear"
                          shape="round"
                          color="dark"
                          onClick={() => setPassword(!showPassword)}
                        >
                          <IonIcon
                            slot="icon-only"
                            icon={showPassword ? eye : eyeOffOutline}
                          />
                        </IonButton>
                      </>
                    )}
                  />
                  <IonText className="helperText right-content-2">
                    6-64 alphanumeric/special characters
                  </IonText>
                  <IonText className="custom-content right-content-2">
                    {showValueCount("password")}/64
                  </IonText>
                </IonItem>
                {showError("password")}
              </IonCol>
              <IonCol size="12">
                <IonButton
                  shape="round"
                  fill="solid"
                  color="secondary"
                  className="remove-shadow capitalizes"
                  type="submit"
                >
                  {formType === "Add" ? "Create Account" : "Save"}
                </IonButton>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
};
export default CreateRecruiter;
