import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import { SavereferenceorgData } from "../../Service/api";
import { apiCallBack } from "../../../../service";
import { DatePick } from "../../../../component";

const RefeExperienceForm = ({
  initialValueData,
  closeModal,
  setorganizationData,
  eventType,
  dataset,
  ctrrid,
}: any) => {
  const [uploading, setUploading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const [show, setShow] = React.useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };
  React.useEffect(() => {
    if (eventType !== "Add") {
      setValue("organization_name", initialValueData.organization_name ?? "");
      setValue("designation", initialValueData.designation ?? "");
      setValue("ref_relation", initialValueData.ref_relation ?? "");
      setValue(
        "startdate",
        initialValueData.startdate ? new Date(initialValueData.startdate) : ""
      );
      setValue(
        "enddate",
        initialValueData.enddate ? new Date(initialValueData.enddate) : ""
      );
    } else {
      setValue("organization_name", "");
      setValue("designation", "");
      setValue("ref_relation", "");
      setValue("startdate", "");
      setValue("enddate", "");
    }
  }, [dataset]);

  // save and update api
  const saveExperience = (values: any) => {
    setShowLoading(true);
    values.ctr_rid = ctrrid;
    if (eventType !== "Add") {
      values.ctr_roid = initialValueData.ctr_roid;
    }
    values.startdate =
      values.startdate !== "0000-00-00" ? values.startdate : "";
    values.enddate = values.enddate !== "0000-00-00" ? values.enddate : "";
    SavereferenceorgData(values).then((e) => {
      if (apiCallBack(e)) {
        setorganizationData(e.data.ctr_organizations);
        setUploading(false);
        setToastColor("success");
        setToastMessage("Organisation added successfully");
        setShowToast(true);
        setShowLoading(false);
        closeModal(false);
      } else {
        setUploading(false);
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(e !== undefined && e.message);
        setShowToast(false);
      }
    });
  };

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={"Are you sure?"}
        subHeader={"Continue to delete the experience."}
        message={"Confirm here"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert(false);
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveExperience)}>
            <IonRow>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Organization <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="organization_name"
                    rules={{
                      required: "Organization is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Enter the Organization"
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("organization_name")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    My role/designation <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="designation"
                    rules={{
                      required: "My role/designation is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Web Developer, Sr. Data Scientist, etc."
                      />
                    )}
                  />
                
                </IonItem>
                {showError("designation")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    He/she was my... <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="ref_relation"
                    rules={{
                      required: "Reference relation is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Manager, team-mate, client, etc."
                      />
                    )}
                  />
                
                </IonItem>
                {showError("ref_relation")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">Start</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick value={value} ChangeEvent={onChange} yearpermit={0} maxDate={new Date()}/>
                    )}
                    control={control}
                    name="startdate"
                  />
                </div>
                {showError("startdate")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">End</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick value={value} ChangeEvent={onChange} minDate={watch('startdate')} />
                    )}
                    control={control}
                    name="enddate"
                    // rules={{
                    //   value: {
                    //     value: (v) => v >= watch("start_date"),
                    //     message: "End month is greater then start month",
                    //   },
                    // }}
                  />
                </div>
                {showError("enddate")}
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  {/* {eventType !== "Add" ? (
                    <IonButton
                      shape="round"
                      fill="clear"
                      color="danger"
                      className="remove-shadow capitalizes"
                      type="button"
                      onClick={() => setShowAlert(true)}
                    >
                      Delete experience
                    </IonButton>
                  ) : (
                    <div></div>
                  )} */}
                  <div></div>
                  <div>
                    {/* {eventType === "Add" && (
                        <IonButton
                          shape="round"
                          fill="outline"
                          color="secondary"
                          className="remove-shadow capitalizes"
                          type="submit"
                        >
                          Save & Add Another
                        </IonButton>
                      )} */}

                    <IonButton
                      shape="round"
                      fill="solid"
                      color="secondary"
                      className="remove-shadow capitalizes"
                      type="submit"
                    >
                      {uploading ? "Uploading" : "Save"}
                    </IonButton>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
};

export default RefeExperienceForm;
