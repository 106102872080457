import {
  IonButton,
  IonContent,
  IonLoading,
  IonPage,
  IonToast,
} from "@ionic/react";
import React from "react";
import { BrowserHandleExport, HeaderNav } from "../../../component";
import { SendMailtoallContractor } from "../Service/api";

export default function MailScreen() {
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const sendWishes = (e) => {
    if (e) {
      setShowLoading(true);
      SendMailtoallContractor({}).then((value) => {
        if (value !== undefined && value.status === 200) {
          setShowLoading(false);
          setToastColor("success");
          setToastMessage(value.message);
          setShowToast(true);
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(
            (value !== undefined && value.message) ?? "please try again later"
          );
          setShowToast(true);
        }
      });
    }
  };

  const getMailstatus = (e) => {
    if (e) {
      BrowserHandleExport("Jngstaff/export_ctr_email_CSV", "Mail_status.csv");
    }
  };
  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
        <div className="container py-4">
          <IonButton
            shape="round"
            fill="outline"
            color="orange"
            className=" remove-shadow capitalizes"
            onClick={(e) => sendWishes(e)}
          >
            Send New Year Wishes
          </IonButton>

          <IonButton
            shape="round"
            fill="outline"
            color="orange"
            className=" remove-shadow capitalizes"
            onClick={(e) => getMailstatus(e)}
          >
            Get mail report
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
}
