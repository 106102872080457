import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import React, { lazy } from "react";
import { useAnalytics } from "use-analytics";
import { PublicRoute, PrivateRoute } from "./ProtectRoute";
import { AuthContext } from "../context/authContext";
// import MainLayout from "../screens/mainLayout";

import MailScreen from "../screens/Company/mail/mail";

// new
import {
  CompanyLegalFiles,
  StaffTools,
  StaffSkills,
  CompanyProjects,
  ViewCompany,
  ViewContractor,
  CompanyUsers,
  ProfileCompany,
  Company,
  CRMMatchesJobs,
  MatchJobs,
} from "../screens";
import ContractorMatchJob from "../screens/Trm/ContractorMatchJob";
import CoreSkills from "../screens/staff/CoreSkills";
import AdditionalData from "../screens/Trm/AdditionalData";
import LandingPage from "../LandingPage";

const Login = lazy(() => import("../screens/auth/Login/Login"));
const terms = lazy(() => import("../screens/terms"));
const PolicyLayout = lazy(() => import("../screens/policy"));
const ContractorProfileLayout = lazy(
  () => import("../screens/Contractor/ContractorProfile/ContractorProfileLayout"),
);
const Register = lazy(() => import("../screens/auth/Register/Register"));
const ResumeUpload = lazy(() => import("../screens/Contractor/QuickProfile"));
const ContractorPublicProfileLayout = lazy(
  () => import("../screens/Contractor/ContractorProfileView/ContractorPublicProfileLayout"),
);
const TrmLayout = lazy(() => import("../screens/Trm/trmLayout"));
const TrmDeikaa = lazy(() => import("../screens/Trm/trmDeikaa"));
const Reward = lazy(() => import("../screens/Contractor/Reward/reward"));
const ReferenceFeedback = lazy(() => import("../screens/Feedback/ReferenceFeedback/referenceFeedback"));
const ListJob = lazy(() => import("../screens/Contractor/Job/listJob"));
const ListMatchJob = lazy(() => import("../screens/Contractor/Job/matchjobs"));
const TrmListcompany = lazy(() => import("../screens/Trm/listcompany"));
const JobDetailsPage = lazy(() => import("../screens/Contractor/Job/JobDetail"));
const Applicationview = lazy(() => import("../screens/Contractor/Job/applicationview"));
const AddDeikaatoken = lazy(() => import("../screens/Trm/deikaatoken"));
const Candidates = lazy(() => import("../screens/Company/Jobs/CanditateList/Candidates"));

const MatchingCandidates = lazy(() => import("../screens/Contractor/Job/matchingCanditates"));
const Listjob = lazy(() => import("../screens/Company/Jobs/listjob"));
const JobDetailScreen = lazy(() => import("../screens/Company/Jobs/jobdetails"));
const AgencyMatchingJobs = lazy(() => import("../screens/Trm/AgencyMatchingJobs"));

const LoginModals = lazy(() => import("../screens/auth/Login/LoginModal"));
const Forgot = lazy(() => import("../screens/auth/Forgot/Forgot"));
const RestPasswords = lazy(() => import("../screens/auth/ResetPassword/resetPassword"));
const Changepwd = lazy(() => import("../screens/auth/Changepwd/changepwd"));
const ProfileSettings = lazy(
  () => import("../screens/Contractor/ContractorProfile/Section/ProfileSetting/ProfileSettings"),
);
const Emailform = lazy(() => import("../screens/staff/email"));
const Staff_users = lazy(() => import("../screens/staff/Staff_User/jng_staff"));
const Remark = lazy(() => import("../screens/Feedback/ReferenceFeedback/remark"));
const VerifyReference = lazy(() => import("../screens/Feedback/ReferenceFeedback/verifyreference"));
const Refer = lazy(() => import("../screens/Contractor/Refer/Refer"));
const EmailNotification = lazy(() => import("../screens/notifications/emailnotification"));
const CrmcompanyList = lazy(() => import("../screens/crm/job/companyTable"));

const PublicJobDetailScreen = lazy(() => import("../screens/publicpage/viewjob"));
const PublicListJob = lazy(() => import("../screens/publicpage/joblist"));
const PublicCanditateList = lazy(() => import("../screens/publicpage/contractorlist"));
const LogsTables = lazy(() => import("../screens/staff/logs/logs"));

function Routes() {
  const Auth = React.useContext(AuthContext);
  const location = useLocation();
  const analytics = useAnalytics();
  React.useEffect(() => {
    analytics.page();
  }, [location]);
  return (
    <Switch>
      {/* old */}
      {/* <PrivateRoute
        auth={Auth.auth.isLogin}
        path="/company-viewcontractor"
        component={CompanyViewContractor}
        exact="true"
      /> */}
      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/company-legal-files'
        component={CompanyLegalFiles}
        exact='true'
      />
      <PrivateRoute auth={Auth.auth.isLogin} path='/viewcontractor' component={ViewContractor} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/viewcompany' component={ViewCompany} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/staff_tools' component={StaffTools} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/company-users' component={CompanyUsers} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/staff_skills' component={StaffSkills} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/staff_users' component={Staff_users} exact='true' />
      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/company-projects'
        component={CompanyProjects}
        exact='true'
      />
      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/ContractorMatchJob/:Jid'
        component={ContractorMatchJob}
        exact='true'
      />
      <PrivateRoute auth={Auth.auth.isLogin} path='/coreskill' component={CoreSkills} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/logs' component={LogsTables} exact='true' />

      <PrivateRoute auth={Auth.auth.isLogin} path='/ProfileCompany' component={ProfileCompany} exact='true' />
      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/trm/companylist'
        component={TrmListcompany}
        exact='true'
      />

      <PrivateRoute auth={Auth.auth.isLogin} path='/Company/:companyID' component={Company} exact='true' />
      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/trm/additional-data'
        component={AdditionalData}
        exact='true'
      />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={false}
        path='/ProfileCompany/:companyID'
        component={ProfileCompany}
        exact='true'
      />
      {/* <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={false}
        path="/list-jobs"
        component={JobList}
        exact="true"
      /> */}

      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={true}
        path='/notification/:id/:usertype/:mailstatus'
        component={EmailNotification}
        exact='true'
      />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={true}
        path='/mail'
        component={Emailform}
        exact='true'
      />
      {/* new */}
      {/* <div className="container main-container"> */}
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={false}
        path='/reset_password/:id/:code'
        component={RestPasswords}
        exact='true'
      />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={false}
        path={"/publicview/:username"}
        component={ContractorPublicProfileLayout}
        exact='true'
      />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={false}
        path='/forgot-password'
        component={Forgot}
        exact='true'
      />

      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={false}
        path='/login-modal'
        component={LoginModals}
        exact='true'
      />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={false}
        path='/referencefeedback/:unique_id'
        component={ReferenceFeedback}
        exact='true'
      />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={false}
        path='/feedbackremark/:unique_id'
        component={Remark}
        exact='true'
      />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={false}
        path='/VerifyReference/:unique_id'
        component={VerifyReference}
        exact='true'
      />
      <PublicRoute auth={Auth.auth.isLogin} srestricted={true} path='/login' component={Login} exact />
      <PublicRoute auth={Auth.auth.isLogin} restricted={true} path='/Register' component={Register} exact />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={true}
        path='/invite/:referral_code'
        component={Register}
        exact
      />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={true}
        path='/job-detail/:id'
        component={JobDetailScreen}
        exact
      />

      <PrivateRoute auth={Auth.auth.isLogin} path='/joblist' component={ListJob} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/listmatchjob' component={ListMatchJob} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/trm/jobs' component={Listjob} exact='true' />

      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/agencymatchjob/:Jid'
        component={AgencyMatchingJobs}
        exact='true'
      />

      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/trm/deikaatoken'
        component={AddDeikaatoken}
        exact='true'
      />
      <PrivateRoute auth={Auth.auth.isLogin} path='/staff/sentmail' component={MailScreen} exact='true' />

      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/staff/deikaatoken'
        component={AddDeikaatoken}
        exact='true'
      />

      <PrivateRoute
        auth={Auth.auth.isLogin}
        restricted={true}
        path='/Job/:name'
        component={JobDetailsPage}
        exact
      />

      <PrivateRoute auth={Auth.auth.isLogin} path='/jobs' component={Listjob} exact='true' />

      <PrivateRoute auth={Auth.auth.isLogin} path='/list-jobs' component={ListJob} exact='true' />

      <PrivateRoute auth={Auth.auth.isLogin} path='/cjob/:name' component={JobDetailScreen} exact='true' />

      <PrivateRoute auth={Auth.auth.isLogin} path='/applicationTL' component={Applicationview} exact='true' />

      <PrivateRoute auth={Auth.auth.isLogin} path='/candidates/:type' component={Candidates} exact='true' />

      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/candidatesmatch'
        component={MatchingCandidates}
        exact='true'
      />

      <PrivateRoute auth={Auth.auth.isLogin} path='/refer' component={Refer} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/changePwd' component={Changepwd} exact='true' />
      <PrivateRoute auth={Auth.auth.isLogin} path='/trm' component={TrmLayout} exact />
      <PrivateRoute auth={Auth.auth.isLogin} path='/crm/contractor' component={TrmLayout} exact />

      <PrivateRoute auth={Auth.auth.isLogin} path='/recruiter/contractor' component={TrmLayout} exact />

      <PrivateRoute auth={Auth.auth.isLogin} path='/reward/:ctrid' component={Reward} exact />
      <PrivateRoute auth={Auth.auth.isLogin} path='/contractor-deikaa' component={TrmDeikaa} exact />
      <PrivateRoute auth={Auth.auth.isLogin} path='/profileSettings' component={ProfileSettings} exact />
      <PrivateRoute
        auth={Auth.auth.isLogin}
        path='/ContractorProfile'
        component={ContractorProfileLayout}
        exact
      />
      <PrivateRoute auth={Auth.auth.isLogin} path='/crm/jobs' component={CRMMatchesJobs} exact />

      <PrivateRoute auth={Auth.auth.isLogin} path='/recruiter/jobs' component={CRMMatchesJobs} exact />

      <PrivateRoute auth={Auth.auth.isLogin} path='/crm/matchcanditate' component={MatchJobs} exact />

      <PrivateRoute auth={Auth.auth.isLogin} path='/crm/companylist' component={CrmcompanyList} exact />

      <PrivateRoute auth={Auth.auth.isLogin} path='/recruiter/companylist' component={CrmcompanyList} exact />

      <PrivateRoute auth={Auth.auth.isLogin} path='/ContractorResume' component={ResumeUpload} exact />
      <PublicRoute auth={Auth.auth.isLogin} restricted={true} path='/' component={LandingPage} exact />
      <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={true}
        path='/privacy-policy'
        component={PolicyLayout}
        exact
      />
   
      <PublicRoute auth={Auth.auth.isLogin} restricted={true} path='/listjob' component={PublicListJob} exact />
      <PublicRoute auth={Auth.auth.isLogin} restricted={true} path='/terms' component={terms} exact />
      <PublicRoute auth={Auth.auth.isLogin} path='/list-candidates' component={PublicCanditateList} exact='true' />
     
        <PublicRoute
        auth={Auth.auth.isLogin}
        restricted={true}
        path='/viewjobs/:id'
        component={PublicJobDetailScreen}
        exact
      />
     
      <Redirect path='/' to='/login' />
      <Route path='*' render={() => "Not Found"} />
      {/* </div> */}
    </Switch>
  );
}

export default Routes;
