import React, { useEffect } from "react";
import { addOutline, pencilSharp } from "ionicons/icons";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import moment from "moment";
import { DeikaaChip } from "../../../Deikaa/deikaa";
import { AuthContext } from "../../../../../context/authContext";
import ReferenceForm from "../../Form/ReferenceForm";
import { deleteReference } from "../../../Service/api";
import {
  addDefaultSrc,
  FindDeikaaKey,
  formatPhoneNumber,
  SeverfilePaths,
} from "../../../../../service";
import { ModalBody } from "../../../../../component";

interface props {
  referenceData: any;
  ctrdata: string;
  filekeypath: string;
}

interface fields {
  firstname: string;
  lastname: string;
  email_id: string;
  project_title: string;
  role: string;
  photo: string;
  organization_name: string;
  country_code: string;
  mobile: string;
  linkedin_url: string;
  start_date: moment.Moment;
  end_date: moment.Moment;
  project_ongoing: boolean;
  ctr_id: string;
  ctr_rid: string;
  since_long_you_know: string;
  ctr_roid: string;
  ref_skills_name: any;
  ref_tools_name: any;
}

const initialValues: fields = {
  firstname: "",
  lastname: "",
  email_id: "",
  organization_name: "",
  project_title: "",
  role: "",
  photo: "",
  country_code: "",
  mobile: "",
  linkedin_url: "",
  start_date: moment(),
  end_date: moment(),
  project_ongoing: false,
  ctr_id: "",
  ctr_rid: " ",
  since_long_you_know: "",
  ctr_roid: "",
  ref_skills_name: [],
  ref_tools_name: [],
};

const References: React.FC<props> = ({
  referenceData,
  filekeypath,
  ctrdata,
}) => {
  const { auth } = React.useContext(AuthContext);
  const [referUser, setRefferUser] = React.useState<fields[]>([]);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [slice, setSlice] = React.useState(3);
  const [showLoading, setshowLoading] = React.useState(false);
  const [modelOpen, setModal] = React.useState(false);
  const [editValue, setEditValue] = React.useState<fields>(initialValues);
  const [eventtype, setEventtype] = React.useState("Add");
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");

  useEffect(() => {
    if (!dataupdated) {
      setRefferUser(referenceData);
      setDataupdated(true);
    }
  }, []);

  React.useEffect(() => {
    if (auth.dekiaaFunvalue === "REFERENCECOMPLETED") {
      setModal(true);
      setEventtype("Add");
    } else {
      setModal(false);
    }
  }, [auth.dekiaaFunvalue]);

  function collectionData(value: any) {
    setRefferUser(value);
  }

  function ReferenceDelete(data: any) {
    if (data) {
      setshowLoading(true);
      deleteReference(data).then((e: any) => {
        if (e !== undefined && e.status === 200) {
          setRefferUser(referUser.filter(({ ctr_rid }) => ctr_rid !== data));
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
          setModal(false);
          setshowLoading(false);
        } else {
          setToastColor("danger");
          setToastMessage(e.message);
          setShowToast(true);
        }
      });
    }
  }

  // edit option
  function editOption(e: any) {
    const data = e;
    setEditValue({
      ...data,
      start_date: data.start_date === "0000-00-00" ? "" : data.start_date,
      end_date: data.end_date === "0000-00-00" ? "" : data.end_date,
      project_ongoing: data.project_ongoing === "1" ? true : false,
    });
    setEventtype("Update");
    setModal(true);
  }

  return (
    <div id="references">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={3000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
        message={"Please Wait.."}
      />
      <ModalBody
        title={eventtype + " reference"}
        subTitle={
          "We will reach out to your reference for verification and to collect their reviews of you which will be published on your profile to add credibility."
        }
        isOpen={modelOpen}
        onClose={() => setModal(false)}
      >
        <ReferenceForm
          ctrid={ctrdata}
          resetForm={modelOpen}
          initialValueData={editValue}
          closeModal={() => setModal(false)}
          setrestoreData={collectionData}
          eventType={eventtype}
          folderkey={filekeypath}
          deleteData={ReferenceDelete}
        />
      </ModalBody>

      <IonItem lines="none" className="custom-header-space">
        <h4 className="mb-0 ">Reference{referUser.length > 1 && "s"}</h4>
        <span className="px-2">
          <DeikaaChip
            flag="reference"
            data={FindDeikaaKey(
              auth.dekiaa,
              "action_title",
              "REFERENCECOMPLETED"
            )}
          />
        </span>
        <IonButton
          slot="end"
          fill="outline"
          className="remove-shadow custom-circle-btn"
          color="secondary"
          shape="round"
          onClick={() => {
            setModal(true);
            setEventtype("Add");
            setEditValue(initialValues);
          }}
        >
          <IonIcon icon={addOutline} slot="icon-only" />
        </IonButton>
      </IonItem>

      <IonGrid className="ion-no-padding">
        {referUser &&
          referUser.slice(0, slice).map((e: any, i: number) => (
            <IonRow key={i}>
              <IonCol sizeLg="4" sizeXs="12">
                <IonItem lines="none">
                  <img
                    alt=""
                    className="small-profile"
                    onError={addDefaultSrc}
                    src={SeverfilePaths(filekeypath) + e.photo}
                  />
                  <span>
                    {" "}
                    {e.firstname} {e.lastname}
                  </span>
                </IonItem>
              </IonCol>
              <IonCol sizeLg="8" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel className="ion-text-wrap">
                    <h6>{e.organization_name}</h6>
                    <h6>{e.email_id}</h6>
                    <p>
                      {e.country_code} {formatPhoneNumber(e.mobile)}
                    </p>
                    <a href={e.linkedin_url} target="_blank">
                      {e.linkedin_url}
                    </a>
                  </IonLabel>
                  <IonButton
                    slot="end"
                    fill="outline"
                    className="remove-shadow custom-circle-btn"
                    color="medium"
                    shape="round"
                    onClick={() => {
                      editOption(e);
                    }}
                  >
                    <IonIcon icon={pencilSharp} slot="icon-only" />
                  </IonButton>
                </IonItem>

                {i !== referUser.length - 1 && <hr />}
              </IonCol>
            </IonRow>
          ))}
        <IonItem lines="none">
          {referUser.length > 0 &&
          referUser.length !== slice &&
          referUser.length >= slice ? (
            <IonButton
              slot="end"
              fill="clear"
              color="secondary"
              type="button"
              size="small"
              className="remove-shadow lowercase"
              onClick={() => setSlice(slice + 3)}
            >
              {referUser.length - slice} more reference(s)
            </IonButton>
          ) : (
            referUser.length !== 0 &&
            referUser.length > 3 && (
              <IonButton
                slot="end"
                fill="clear"
                color="secondary"
                type="button"
                size="small"
                className="remove-shadow lowercase"
                onClick={() => setSlice(3)}
              >
                less reference(s)
              </IonButton>
            )
          )}
        </IonItem>
      </IonGrid>
    </div>
  );
};

export default References;
