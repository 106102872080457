import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import { createOutline, trash } from "ionicons/icons";
import { useHistory } from "react-router";
import { formatPhoneNumber } from "../../../service";
import { getallCRM, getRemoveCRM } from "../Service/api";
import { ModalBody, TableHeader } from "../../../component";
import CreateCRM from "../../forms/createCRM";
import DataTableComponent from "../../../component/DataTable/dataTable";
// interface Props extends RouteComponentProps<{}> {}
interface user {
  firstname: string;
  lastname: string;
  username: string;
  password: string;
  email: string;
  uid: string;
}

const defaultUser: user = {
  firstname: "",
  lastname: "",
  username: "",
  password: "",
  email: "",
  uid: "",
};

const CrmTable: React.FC = () => {
  const history = useHistory();
  const [deleteCrmid, setDeleteCRMid] = useState("");
  const [modelOpen, setModal] = React.useState(false);
  const [showToast, setShowToast] = useState(false);
  const [editUser, setEditUser] = useState<user>(defaultUser);
  const [formtype, setFormType] = useState("Add");
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showAlert, setShowAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [crmdata, setCrmData] = useState<any>([]);

  const column = [
    { name: "S.No", field: "id", sortable: false, value: "id" },
    {
      name: "Name",
      field: "fullName",
      sortable: false,
      value: "fullName",
      wordsplit: "",
      validate: " ",
    },
    {
      name: "Email",
      field: "email",
      sortable: false,
      value: "email",
      wordsplit: "",
    },
    {
      name: "Action",
      field: "",
      sortable: false,
      value: "edit:delete",
      wordsplit: " ",
    },
  ];

  const getCRMData = () => {
    setCrmData([]);
    setShowLoading(true);
    // const result = {
    //   page: paginationdata,
    //   search_ctr: data.search_ctr ?? "",
    // };
    getallCRM("").then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        const finalData = value.data.map(item => ({
          ...item,
          fullName: `${item.firstname} ${item.lastname}` || "",
        }))
        setCrmData(finalData);
        // setcontractorperPage(value.itemsCountPerPage);
        // setcontractorpagecount(value.totalItemsCount);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value !== undefined && value.message);
        setShowToast(true);
      }
    });
  };

  const CRMDeleteApi = (da) => {
    if (da) {
      getRemoveCRM(da).then((value: any) => {
        if (value !== undefined && value.status === 200) {
          setShowLoading(false);
          getCRMData();
          setToastColor("success");
          setToastMessage(value !== undefined && value.message);
          setShowToast(true);
          setDeleteCRMid("");
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(value !== undefined && value.message);
          setShowToast(true);
        }
      });
    }
  };

  const renderAction = (type, item) => {
    switch (type) {
      case "delete":
        setDeleteCRMid(item.uid);
        setShowAlert(true);
        return;
      case "edit":
        setFormType("Update");
        setEditUser(item);
        setModal(true);
        return;
      default:
        return;
    }
  };

  React.useEffect(() => {
    let isMounted = true;
    try {
      getCRMData();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />

      <IonGrid className="center">
        <IonRow>
          <IonCol sizeLg="12" sizeXs="12">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h3>Customer Relationship Manager</h3>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol sizeLg="12" size="12">
                  <IonButton
                    className="custom-button btn-margin-left"
                    color="secondary capitalize"
                    shape="round"
                    fill="solid"
                    onClick={(e) => {
                      setEditUser(defaultUser);
                      setFormType("Add");
                      setModal(true);
                    }}
                  >
                    Create CRM
                  </IonButton>
                </IonCol>

                <IonCol size="12">
                  <IonCard className="ion-padding">
                    <DataTableComponent
                      tableheaders={column}
                      tableData={crmdata}
                      currentPage={1}
                      itemPerPage={30}
                      totalItem={crmdata.length}
                      handleAction={(type, item) => {
                        renderAction(type, item);
                      }}
                      setCurrentPage={(page) => {}}
                    />
                    {/* <div className="con-table-scrolbar table-responsive">
                      <table className="table table-bordered table-hover">
                        <TableHeader
                          headers={headers}
                          onSorting={(field, order) => {}}
                        />
                        <tbody>
                          {crmdata.map((comment: any, index: number) => (
                            <tr key={index}>
                              <th scope="row" key={index}>
                                {index + 1}
                              </th>
                              <td>
                                <p className="m-0">
                                  {comment.firstname} {comment.lastname}
                                </p>
                              </td>
                              <td>
                                <p className="m-0">{comment.email}</p>
                                <p className="m-0">
                                  {formatPhoneNumber(comment.mobile)}
                                </p>
                              </td>
                              <td>
                                <IonButton
                                  onClick={() => {
                                    setFormType("Update");
                                    setEditUser(comment);
                                    setModal(true);
                                  }}
                                  shape="round"
                                  fill="clear"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="update CRM details"
                                >
                                  <IonIcon
                                    icon={createOutline}
                                    color="warning"
                                  />
                                </IonButton>
                                <IonButton
                                  shape="round"
                                  fill="clear"
                                  target="_blank"
                                  className="remove-shadow custom-table-icon-btn"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="bottom"
                                  title="Remove CRM"
                                  onClick={(e) => {
                                    setDeleteCRMid(comment.uid);
                                    setShowAlert(true);
                                  }}
                                >
                                  <IonIcon
                                    icon={trash}
                                    color="danger"
                                    slot="icon-only"
                                  />
                                </IonButton>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div> */}
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <ModalBody
        isOpen={modelOpen}
        onClose={() => setModal(false)}
        title={formtype === "Add" ? "Create CRM" : "Update CRM"}
      >
        {modelOpen && (
          <>
            <CreateCRM
              id={""}
              editData={editUser}
              formType={formtype}
              setShowModal={(e) => {
                getCRMData();
                setModal(false);
              }}
            />
          </>
        )}
      </ModalBody>
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={"Continue to delete the CRM"}
        subHeader={"Confirm here"}
        message={""}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setDeleteCRMid("");
              setShowAlert(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              CRMDeleteApi(deleteCrmid);
              setShowAlert(false);
            },
          },
        ]}
      />
    </>
  );
};

export default React.memo(CrmTable);
