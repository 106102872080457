import { range } from "lodash";
import { Portal } from "react-overlays";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
type props = {
  value: string | Date;
  ChangeEvent: any;
  start?: string;
  end?: string;
  minDate?: string | Date;
  maxDate?: string | Date;
  disabled?: boolean;
  possition?: string;
  yearpermit?: number;
};
export const DatePick = (field: props) => {
  const currentYear = new Date().getFullYear();
  const year = new Date().getFullYear();
  const month = new Date().getMonth();
  const day = new Date().getDate();
  const maxYear = new Date(
    field.yearpermit === 18
      ? year - field.yearpermit
      : year + (field.yearpermit ?? 50),
    month,
    day
  );

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select
            value={date.getFullYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {range(
              field.yearpermit === 18
                ? currentYear - field.yearpermit
                : currentYear + (field.yearpermit ?? 50),
              1820 - 1
            ).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[date.getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      closeOnScroll={true}
      disabled={field.disabled}
      selected={
        String(field.value) !== "Invalid Date" ? field.value : new Date()
      }
      onChange={field.ChangeEvent}
      startDate={field.start}
      endDate={field.end}
      minDate={field.minDate}
      maxDate={field.maxDate ?? new Date(maxYear)}
      locale="en-US"
      placeholderText="mm/dd/yyyy"
      popperPlacement={field.possition ?? "bottom-start"}
      popperContainer={CalendarContainer}
    />
  );
};

const CalendarContainer = ({ children }) => {
  const el = document.getElementById("calendar-portal");

  return <Portal container={el}>{children}</Portal>;
};
