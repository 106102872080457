import React from "react";
import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";
import { CommonSectionSkill } from ".";
import { arrayKeymatch } from "../../../../../service";
interface props {
  skillData: any;
}
export const Skills: React.FC<props> = ({ skillData }) => {
  const [localSkills, setLocalSkills] = React.useState<any>([]);
  const [needToUpdate, setNeedToUpdate] = React.useState(true);
  React.useEffect(() => {
    if (needToUpdate) {
      const skill: any[] = [];
      skillData.map((e: any, i: number) => {
        skill.push({
          title: e.title,
          arr: e.arr,
          count: arrayKeymatch(e.arr)
            .map((e: any) => {
              return e.arr.length;
            })
            .reduce((a, b) => a + b, 0),
        });
      });
      setLocalSkills(skill);
      setNeedToUpdate(false);
    }
  }, []);

  return !needToUpdate ? (
    <div id="skills">
      <IonItem lines="none" className="custom-header-space ">
        <h4>Skills</h4>
      </IonItem>
      <IonGrid className="ion-no-padding">
        {localSkills !== undefined &&
          localSkills.map(
            (e: any, i: number) =>
              e.count !== 0 && (
                <IonRow key={i}>
                  <IonCol sizeLg="4" sizeXs="12">
                    <label className="capitalize">{e.title}</label>
                  </IonCol>
                  <IonCol sizeLg="8" sizeXs="12">
                    <CommonSectionSkill
                      arrs={arrayKeymatch(e.arr)}
                      type={"skills"}
                      screenType="none"
                    />
                    {i !== e.length - 1 && <hr />}
                  </IonCol>
                </IonRow>
              )
          )}
      </IonGrid>
    </div>
  ) : null;
};

export default Skills;
