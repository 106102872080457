import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import React from "react";
import {
  addDefaultSrc,
  GetContractorProfile,
  getCookie,
  onSave,
  SeverfilePath,
  StringMap,
} from "../../../service";
import { CustomAnchor, HeaderNav } from "../../../component";
import Career from "./Section/Career/Career";
import Qualification from "./Section/Qualifications";
import MoreDetail from "./Section/More";
import SummaryDetail from "./Section/Summary/summary";
import { decrypted, encrypted } from "../../../service/axios";
import { CRMRole, Staff, TRM } from "../../../service/default";
import { RouteComponentProps } from "react-router";

class ContractorPublicProfileLayout extends React.Component<
  RouteComponentProps
> {
  
  state = {
    username: this.props["match"].params['username'],
    mQuery: {
      matches: window.innerWidth > 768 ? true : false,
    },
    loading: false,
    userType: [CRMRole, Staff, TRM],
    user: getCookie({ key: "user" }).value,
    ctrid: "",
    imageUrl: "",
    imgSelect: "",
    public_id: "",
    profileData: null,
    folderkey: "",
    coverphoto: "",
    allow_search: "0",
    showToast: false,
    toastMessage: "",
    uppercase: false,
    matches: window.matchMedia("(max-width: 2491px)").matches,
  };

  componentDidUpdate() {
    // let mediaQuery = window.matchMedia("(min-width: 768px)");
    // mediaQuery.addListener();
    // this is the cleanup function to remove the listener
    // return () => mediaQuery.removeListener();
  }

  getDetail() {
    this.setState({
      loading: true,
    });
    const username = this.props["match"];
    const req = encrypted(username.params['username']);
    GetContractorProfile(req).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        this.setState({
          ctrid: value.data.ctr_id,
          public_id: value.data.public_id,
          folderkey: decrypted(value.data.folder_key),
          coverphoto: decrypted(value.data.cover_photo),
          allow_search: value.data.allow_search,
          imageUrl: decrypted(value.data.profile_photo),
          profileData: value.data,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
          profileData: null,
          toastMessage:
            value !== undefined
              ? value.message
              : "Unable to connect to server, please try later",
          showToast: true,
        });
      }
    });
  }

  updateChild(data: any) {
    if (data) {
      const Additionaldata = {
        title: "Additional data for contractor",
        contractorName: data.firstname + data.lastname,
        ctrid: data.ctr_id,
        required: this.state.user.user_type === CRMRole ? true : false,
        requestText: "",
        subTitle:
          "Contractor Name: " + StringMap(data.firstname + data.lastname),
      };
      onSave({ key: "additionaldata", value: Additionaldata });
      window.open("/trm/additional-data", "_blank");
      return;
    }
  }

  componentDidMount() {
    this.getDetail();
    const handler = (e) => this.setState({ matches: e.matches });
    window
      .matchMedia("(max-width: 2491px)")
      .addEventListener("change", handler);
  }

  render() {
    const { loading, imageUrl, userType, user, ctrid } = this.state;
    return (
      <IonPage>
        <HeaderNav />
        <IonContent>
          <IonLoading
            cssClass="my-custom-class"
            isOpen={loading}
            onDidDismiss={() => this.setState({ loading: false })}
            message={"Please Wait.."}
          />
          <IonToast
            isOpen={this.state.showToast}
            onDidDismiss={() => this.setState({ showToast: false })}
            message={this.state.toastMessage}
            duration={1000}
          />
          {this.state.profileData ? (
            <div
              className={
                this.state.matches
                  ? "container main-container profile-container"
                  : "main-container profile-container"
              }
            >
              {user && userType.includes(user.user_type) && (
                <IonGrid>
                  <IonRow>
                    <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                      <IonCard className="my-2 p-1 deikaa-chip-orange text-center">
                        <span className="chip-font">
                        Additional Data
                        </span>
                        <span
                          className="chip-font read-or-hide"
                          onClick={() => {
                            this.updateChild(this.state.profileData);
                          }}
                        >
                          {" "}
                          click here
                        </span>
                      </IonCard>
                    </IonCol>
                    <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                      <IonCard className="my-2 p-1 deikaa-chip-orange text-center">
                        <span className="chip-font">
                        Matching Jobs
                        </span>
                        <span
                          className="chip-font read-or-hide"
                          onClick={() => {
                            this.props.history.push("/listmatchjob", {
                              ctrid: ctrid,
                            });
                          }}
                        >
                          {" "}
                          click here
                        </span>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )}
              <div
                className="cover-img"
                style={{
                  backgroundImage: `url(${
                    this.state.folderkey && this.state.coverphoto
                      ? SeverfilePath +
                        this.state.folderkey +
                        "/" +
                        this.state.coverphoto
                      : "assets/image/Hirify-Pitch-logo.png"
                  })`,
                  backgroundSize: `${
                    this.state.folderkey && this.state.coverphoto
                      ? "cover"
                      : "40% 100%"
                  }`,
                }}
              ></div>
              <div className="wrapper">
                <div className="left-slider">
                  <div className="photo-left">
                    <div className="profile-img">
                      <img
                        src={
                          SeverfilePath + this.state.folderkey + "/" + imageUrl
                        }
                        alt="avatar"
                        onError={addDefaultSrc}
                        className="photo"
                      />
                    </div>
                  </div>
                  {!loading && (
                    <CustomAnchor
                      pathname={"/publicview"}
                      PageLink={Link}
                      deikaaActive={false}
                      username={"/" + this.state.username}
                    />
                  )}
                </div>
                <div className="right-content">
                  {!loading && (
                    <SummaryDetail
                      personalData={
                        this.state.profileData !== null &&
                        this.state.profileData
                      }
                    />
                  )}
                  {!loading && (
                    <Career
                      personalData={
                        this.state.profileData !== null &&
                        this.state.profileData
                      }
                      ctrId={this.state.ctrid}
                      folderKey={this.state.folderkey}
                    />
                  )}
                  {!loading && (
                    <Qualification
                      personalData={
                        this.state.profileData !== null &&
                        this.state.profileData
                      }
                    />
                  )}
                  {!loading && (
                    <MoreDetail
                      ctrid={this.state.ctrid}
                      personalData={
                        this.state.profileData !== null &&
                        this.state.profileData
                      }
                    />
                  )}
                  {/* <ProfileSettings /> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="container main-container profile-container text-center">
              {this.state.toastMessage}
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }
}
export default ContractorPublicProfileLayout;

const Link = [
  {
    title: "Career",
    link: "#summary",
    child: [
      { sub: "Summary", link: "#summary" },
      { sub: "Experience", link: "#career" },
    ],
  },
  {
    title: "Qualifications",
    link: "#qualifications",
    child: [
      { sub: "Skills", link: "#skills" },
      { sub: "Tools & technologies", link: "#tools" },
      { sub: "Education", link: "#education" },
      { sub: "Certifications", link: "#certifications" },
      { sub: "Spoken languages", link: "#language" },
    ],
  },
  {
    title: "More details",
    link: "#details",
    child: [
      { sub: "Preferences", link: "#preference" },
      { sub: "Other", link: "#others" },
      { sub: "References", link: "#references" },
    ],
  },
];
