import React, { useState, useEffect, createContext } from "react";
import { resInvitedTalent } from "../interface/InvitedTalentInterface";
import { onCheck, userResult } from "../service";
import { pages } from "../util/navlink";

interface authInt {
  isLogin: boolean;
  data: any;
  dekiaa: any;
  dekiaaFunvalue: string;
  allskill: any;
  alltool: any;
  alllang: any;
  highlightedsuggestedskill: any;
  navList: any;
  resInvitedTalent: resInvitedTalent | null;
  companyjob: {
    companyName: string;
    open_job_count: number;
    close_job_count: number;
    draft_job_count: number;
    total_count: number;
  };
  canditateJobCount: {
    applied_count: number;
    hired_count: number;
    invited_count: number;
    matching_candidate_count: number;
    rejected_count: number;
    shortlisted_count: number;
    fav_count?: number;
    all_job?: number;
  };
  additionalData: {
    title: string;
    ctrid: string;
    required?: boolean;
    requestText?: string;
    subTitle: string;
    contractorName: string;
  };
}
const authData: authInt = {
  isLogin: false,
  data: null,
  dekiaa: [],
  dekiaaFunvalue: "",
  allskill: [],
  alltool: [],
  alllang: [],
  highlightedsuggestedskill: [],
  navList: [],
  resInvitedTalent: null,
  companyjob: {
    companyName: '',
    open_job_count: 0,
    close_job_count: 0,
    draft_job_count: 0,
    total_count: 0,
  },
  canditateJobCount: {
    applied_count: 0,
    hired_count: 0,
    invited_count: 0,
    matching_candidate_count: 0,
    rejected_count: 0,
    shortlisted_count: 0,
    fav_count: 0,
    all_job: 0,
  },
  additionalData: {
    title: "",
    ctrid: "",
    required: false,
    requestText: "",
    subTitle: "",
    contractorName: "",
  },
};

export const AuthContext = createContext<any>({
  auth: {
    isLogin: false,
    data: null,
    dekiaa: null,
    dekiaaFun: null,
    allskill: [],
    alltool: [],
    alllang: [],
    highlightedsuggestedskill: [],
    navList: pages,
    resInvitedTalent: null,
    companyjob: {
      companyName: "",
      open_job_count: 0,
      close_job_count: 0,
      draft_job_count: 0,
      total_count: 0,
    },
    canditateJobCount: {
      applied_count: 0,
      hired_count: 0,
      invited_count: 0,
      matching_candidate_count: 0,
      rejected_count: 0,
      shortlisted_count: 0,
      fav_count: 0,
      all_job: 0,
    },
    additionalData: {
      title: "",
      ctrid: "",
      required: false,
      requestText: "",
      subTitle: "",
      contractorName: "",
    },
  },
});

function AuthContextProvider(props: any) {
  const [auth, setAuth] = useState<authInt>(authData);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // check authorised or not
    if (isVisible) {
      if (onCheck()) {
        setAuthResponse(true, userResult());
      } else {
        setAuthResponse(false, null);
      }
      setIsVisible(false);
    }
  }, []);

  function setDeikaa(data: any) {
    setAuth({ ...auth, dekiaa: data });
  }

  function DeikaaFun(data: any) {
    setAuth({ ...auth, dekiaaFunvalue: data });
  }

  function setAuthResponse(logindata: any, result: any) {
    setAuth({ ...auth, isLogin: logindata, data: result, navList: pages });
  }

  function setAuthCanditateMatchCount(canditateCount) {
    setAuth({ ...auth, canditateJobCount: canditateCount });
  }

  function OptionListfun(data: any) {
    setAuth({
      ...auth,
      allskill: data.allskills,
      alltool: data.alltools,
      alllang: data.alllang,
      highlightedsuggestedskill: data.allhighlightedsuggestedskill,
    });
  }

  function checkLoginUsername(key: string, value: string) {
    if (value) {
      if (auth.data.username === value) return true;
      if (auth.data.username !== value) return false;
    }
  }

  function setResponseData(res, type) {
    if (type === "InvitedTalent" && res) {
      setAuthCanditateMatchCount(res);
      setAuth({
        ...auth,
        resInvitedTalent: res,
      });
    }
  }
  function setAuthCompanyJobCount(jobCount) {
    setAuth({ ...auth, companyjob: jobCount });
  }

  function setAdditionalData(data) {
    setAuth({ ...auth, additionalData: data });
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuthResponse,
        setDeikaa,
        DeikaaFun,
        OptionListfun,
        checkLoginUsername,
        setAuthCanditateMatchCount,
        setResponseData,
        setAuthCompanyJobCount,
        setAdditionalData,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
