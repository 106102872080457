import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonIcon,
  IonCard,
} from "@ionic/react";

import React from "react";
import { ModalBody } from "../../../component";
import DataTableComponent from "../../../component/DataTable/dataTable";
import Addjnglegal from "../../forms/addjnglegaluser";
import { fGetalljnglegaluser } from "../Service/api";
import swal from "sweetalert";

const LegalTable: React.FC = () => {
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [modelOpen, setModal] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [userData, setUserData] = React.useState(false);
  const [userDataValue, setUserValue] = React.useState<any>();
  const [legaltableData, setLegaltableData] = React.useState<any>([]);
  const [legalcomments, setLegalcomments] = React.useState<any>([]);
  const [totalItemCount, setTotalItemCount] = React.useState<number>(0);
  const [totalCountPerPage, setItemperPage] = React.useState<number>(30);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const column = [
    { name: "S.No", field: "id", sortable: false, value: "id" },
    {
      name: "Name",
      field: "fullName",
      sortable: false,
      value: "fullName",
      wordsplit: "",
      validate: " ",
    },
    {
      name: "Email",
      field: "email",
      sortable: false,
      value: "email",
      wordsplit: "",
    },
    {
      name: "Action",
      field: "",
      sortable: false,
      value: "edit:",
      wordsplit: " ",
    },
  ];

  //   get api call
  const legalGetCU = () => {
    fGetalljnglegaluser("").then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setCurrentPage(1);
        setItemperPage(30);
        const finalData = value.data.map(item => ({
          ...item,
          fullName: `${item.firstname} ${item.lastname}` || "",
        }))
        var slice = finalData.slice(0, 30);
        setLegaltableData(finalData);
        setTotalItemCount(finalData.length);
        setLegalcomments(slice);
        setShowLoading(false);
      } else {
        setTotalItemCount(0);
        setItemperPage(0);
        setShowLoading(false);
      }
    });
  };

  //   search Table key
  const legalonSearch = (value) => {
    let computedlegalcomments = legaltableData ? legaltableData : null;
    if (value) {
      const computedComment = computedlegalcomments.filter(
        (comment: any) =>
          comment.fullName.toLowerCase().includes(value.toLowerCase()) ||
          comment.email.toLowerCase().includes(value.toLowerCase())
      );
      setLegalcomments(computedComment);
      setCurrentPage(1);
    } else {
      var slice = computedlegalcomments.slice(0, 30);
      setLegalcomments(slice);
      setCurrentPage(1);
    }
  };

  //   edit
  const legaledit = (data: any) => {
    if (data) {
      setUserValue({
        lastname: data.lastname,
        firstname: data.firstname,
        email: data.email,
        username: data.username,
        password: "",
        uid: data.uid,
        role_name: data.role_name,
      });
      setUserData(true);
      setUserValue(data);
      setShowModal(true);
    }
  };

  //   pagination
  const handlePageChange = (e) => {
    const selectedPage = e;
    const num = (selectedPage - 1) * totalCountPerPage + 20;
    setCurrentPage(e);
    const data = legaltableData;
    const slice = data.slice(totalCountPerPage * (selectedPage - 1), num);
    setLegalcomments(slice);
  };

  //   remove user

  // const legalremoveUser = (da) => {
  //   swal({
  //     title: "Are you sure?",
  //     text: "Once deleted, you will not be able to recover this user!",
  //     icon: "warning",
  //     buttons: ["Cancel", "Ok"],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       if (da) {
  //         this.setState({
  //           showLoading: true,
  //         });
  //         const FetchData = getMethod("/Deletejnglegalrole/" + da.uid);
  //         FetchData.then((value: any) => {
  //           if (value.status === 200) {
  //             this.legalGetCU();
  //             this.setState({
  //               showLoading: true,
  //             });
  //           } else {
  //             this.setState({
  //               showLoading: false,
  //               toastColor: "danger",
  //               toastMessage: value.message,
  //               showToast: true,
  //             });
  //           }
  //         });
  //       }
  //       swal(" user has been deleted successfully!", {
  //         icon: "success",
  //       });
  //     }
  //   });
  // };

  const renderAction = (type, item) => {
    switch (type) {
      case "delete":
        // legalremoveUser(item);
        return;
      case "edit":
        legaledit(item);
        return;
      default:
        return;
    }
  };

  React.useEffect(() => {
    setShowLoading(true);
    let isMounted = true;
    try {
      legalGetCU();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  return (
    <div>
      <IonGrid className="center">
        <IonRow>
          <IonCol sizeLg="12" sizeXs="12">
            <IonItem lines="none">
              <h3>All Users</h3>
              <IonButton
                className="custom-button btn-margin-left"
                color="secondary capitalize"
                shape="round"
                fill="solid"
                onClick={(e) => {
                  setUserData(false);
                  setUserValue({
                    lastname: "",
                    firstname: "",
                    email: "",
                    username: "",
                    password: "",
                  });
                  setShowModal(true);
                }}
              >
                Create Users
              </IonButton>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonCard className="ion-padding">
              <DataTableComponent
                tableheaders={column}
                tableData={legalcomments}
                currentPage={currentPage}
                itemPerPage={totalCountPerPage}
                totalItem={totalItemCount}
                searchInput={true}
                searchFunction={(search) => legalonSearch(search)}
                handleAction={(type, item) => {
                  renderAction(type, item);
                }}
                setCurrentPage={(page) => handlePageChange(page)}
              />
            </IonCard>
            {/* <div className="scrollit">
              <table className="table table-bordered table-hover">
                <TableHeader
                  headers={legalheaders}
                  onSorting={(field, order) => {}}
                />
                {legalcomments && legalcomments.length > 0 ? (
                  <tbody>
                    {legalcomments.map((comment: any, index: number) => (
                      <tr key={index}>
                        <td>
                          {comment.firstname} {comment.lastname}
                        </td>
                        <td>{comment.email}</td>

                        <td>
                          <div className="displaybtnresponsive">
                            <IonButton
                              onClick={(e) => legaledit(comment)}
                              shape="round"
                              fill="clear"
                            >
                              <IonIcon icon={createOutline} color="warning" />
                            </IonButton>

                            <IonButton
                              shape="round"
                              fill="clear"
                              onClick={(e) => legalremoveUser(comment)}
                            >
                              <IonIcon icon={trashOutline} color="danger" />
                            </IonButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody className="ion-center">
                    <tr>
                      <td className="absolute">No Data Found</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div> */}
          </IonCol>
        </IonRow>
      </IonGrid>

      <ModalBody
        title={userData ? "Update User" : "Add User"}
        subTitle={""}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <Addjnglegal
          setShowModal={() => {
            legalGetCU();
            setShowModal(false);
          }}
          headerType={userData}
          setHeaderType={() => {}}
          editData={userDataValue}
          formtype={userData ? "update" : "add"}
        />
      </ModalBody>
    </div>
  );
};

export default React.memo(LegalTable);
