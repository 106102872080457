import {
  IonCol,
  IonGrid,
  IonItem,
  IonRow,
} from "@ionic/react";
import React from "react";
import {
  StringMap,
} from "../../../../../service";

interface props {
  practiceData: any;
  proficiencytypelist: any;
  ctridData: any;
  ListofskillsOptionData: any;
}
export const Practice: React.FC<props> = ({
  ctridData,
  practiceData,
  proficiencytypelist,
  ListofskillsOptionData,
}) => {
  const [modelOpen, setModal] = React.useState(false);
  const [localSkills, setLocalSkills] = React.useState<any>([]);
  const [ListofpracticeOptionDatas, setListofpracticeOptionDatas] =
    React.useState<any>([]);
  const [needToUpdate, setNeedToUpdate] = React.useState(false);

  return (
    <div id="skills">
      {!needToUpdate ? (
        <>
          <IonItem lines="none" className="custom-header-space ">
            <h4>Core skills</h4>
          </IonItem>
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol sizeLg="4" sizeXs="12">
                <label>Skill 1</label>
              </IonCol>
              <IonCol sizeLg="8" sizeXs="12">
                <span>
                  {StringMap(practiceData[0].practice1)}
                  {/* {StringMap(
                    getIndexValue(
                      localSkills.practice1,
                      ListofpracticeOptionDatas,
                      "ctr_pa",
                      "practice_name"
                    )
                  )} */}
                </span>
                <hr />
              </IonCol>
              <IonCol sizeLg="4" sizeXs="12">
                <label>Skill 2</label>
              </IonCol>

              <IonCol sizeLg="8" sizeXs="12">
                {StringMap(practiceData[0].practice2)}
                {/* {StringMap(
                    getIndexValue(
                      localSkills.practice2,
                      ListofpracticeOptionDatas,
                      "ctr_pa",
                      "practice_name"
                    )
                  )} */}
              </IonCol>
            </IonRow>
          </IonGrid>
        </>
      ) : null}
    </div>
  );
};

export default React.memo(Practice);
