import {
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
} from "@ionic/react";
import {
  brushOutline,
  chevronForwardOutline,
  closeOutline,
} from "ionicons/icons";
import moment from "moment";
import React from "react";
import { RouteComponentProps, useParams } from "react-router";
import {
  CardProfileImage,
  HeaderNav,
  ModalBody,
  ReadMoreRichText,
} from "../../../component";
import { AuthContext } from "../../../context/authContext";
import useMediaQuery from "../../../hooks/mediaQuery";
import { getIndexValue } from "../../../service";
import {
  ArrayStringMap,
  Company,
  FullDateStringMap,
  NumberStringMap,
  Staff,
  EXPStringMap,
  StringMap,
  TRM,
} from "../../../service/default";
import { getJobDetail } from "../Service/api";
import InviteTalent from "./form/InviteTalent";
import JobForm from "./form/JobForm";

const JobDetailScreen = (props: RouteComponentProps) => {
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const { auth } = React.useContext(AuthContext);
  const user = auth.data;
  const [showLoading, setshowLoading] = React.useState(false);
  const [locationData] = React.useState<any>(props.location.state);
  const [screenName, setScreenName] = React.useState<any>("0");
  const [jobdetail, setJobDetail] = React.useState<any>();
  const [editValue, setEditValue] = React.useState<any>();
  const [showModal, setShowModal] = React.useState(false);
  const [showModalTalent, setShowModalTalent] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [workFromhomeType, setworkFromhomeType] = React.useState([]);
  const [workPermit, setworkPermit] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const matchId = useParams();
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");

  React.useEffect(() => {
    let isMounted = true;
    try {
      // setScreenName(locationData.screenName);
      getJobDetails();
      setScreenName(locationData.screenName);
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
          setJobDetail([]);
        }
      }
    } finally {
      // isMounted && setshowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const getJobDetails = () => {
    setshowLoading(true);
    const matchIds = matchId ? matchId["id"] : "";
    getJobDetail(
      locationData ? locationData.unique_id : matchIds ? matchIds : ""
    ).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setJobDetail(e.data);
        setworkFromhomeType(e.data.workFromhomeType);
        setworkPermit(e.data.workPermit);
        setshowLoading(false);
      } else {
        setshowLoading(false);
      }
    });
  };

  const editDetails = (e: any) => {
    if (e) {
      setShowModal(true);
      setEditValue(jobdetail);
    }
  };

  const candidates = (name: any, e: any) => {
    if (e) props.history.push("/candidates/" + name, e);
  };

  const UpdateState = (data) => {
    if (data && data.unique_id === jobdetail.unique_id) {
      const job = jobdetail;
      job.job_title = data.title;
      job.department = data.department;
      job.hiring_manager = data.hiring_manager;
      job.engagement_type = data.duration_type;
      job.start_date = data.start_date;
      job.end_date = data.end_date;
      job.overall_exp = data.overall_exp;
      job.payment = data.payment;
      job.pay_type = data.pay_type;
      job.wfh_type = data.wfh_type;
      job.work_location = data.work_location;
      job.description = data.description;
      job.mandatory_skills = data.mandatory_skills;
      job.additional_skills = data.additional_skills;
      job.Glove_team_manager = data.Glove_team_manager;
      job.openings = data.openings;
      job.posted_by = data.posted_by;
      job.US_work_authorization = workAuth(data.us_work_authrizations);
      setJobDetail((job) => ({
        ...jobdetail,
        ...job,
      }));
    }
  };
  const workAuth = (data) => {
    const work: any[] = [];
    if (data.length !== 0 && data) {
      data.map((e) => {
        work.push({ us_work_authrizations: e });
      });
    }
    return work;
  };
  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setshowLoading(false)}
        />
        <div className={m4kQuery ? "container" : "container Jobs px-4 py-3"}>
          {!showLoading && jobdetail ? (
            <>
              <IonGrid className="ion-padding">
                <IonRow>
                  <IonCol sizeLg="6" sizeXs="12">
                    <h1 className="detail-header m-0">Job details</h1>
                  </IonCol>
                  <IonCol sizeLg="6" sizeXs="12">
                    {user && (
                      <IonItem lines="none">
                        {user && user.user_type === Company ? (
                          <>
                            {screenName === "1" && (
                              <IonButton
                                slot="end"
                                fill="outline"
                                color="orange"
                                shape="round"
                                className="remove-shadow capitalizes"
                                type="button"
                                onClick={(e) => {
                                  setShowModalTalent(true);
                                }}
                              >
                                Invite Talent
                              </IonButton>
                            )}
                            {user &&
                              user.user_type === Staff &&
                              screenName !== "0" && (
                                <IonButton
                                  slot="end"
                                  fill="outline"
                                  className="remove-shadow custom-circle-btn mx-2"
                                  color="medium"
                                  shape="round"
                                  onClick={(e) => editDetails(e)}
                                >
                                  <IonIcon
                                    icon={brushOutline}
                                    slot="icon-only"
                                  />
                                </IonButton>
                              )}
                          </>
                        ) : null}
                        {user &&
                          user.user_type !== TRM &&
                          screenName !== "0" && (
                            <IonButton
                              slot="end"
                              fill="outline"
                              className="remove-shadow custom-circle-btn mx-2"
                              color="medium"
                              shape="round"
                              onClick={(e) => editDetails(e)}
                            >
                              <IonIcon icon={brushOutline} slot="icon-only" />
                            </IonButton>
                          )}
                        <IonButton
                          slot="end"
                          fill="outline"
                          className="remove-shadow custom-circle-btn"
                          color="danger"
                          shape="round"
                          onClick={(e) => {
                            user && user.user_type === Staff
                              ? props.history.replace(
                                  "/Company/" + locationData !== undefined
                                    ? locationData.comid
                                    : ""
                                )
                              : user && user.user_type !== TRM
                              ? props.history.replace(
                                  "/jobs",
                                  locationData !== undefined
                                    ? locationData.comid
                                    : ""
                                )
                              : props.history.goBack();
                          }}
                        >
                          <IonIcon icon={closeOutline} slot="icon-only" />
                        </IonButton>
                      </IonItem>
                    )}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="com-header-details">
                      <h2>{StringMap(jobdetail.job_title)}</h2>
                      <p className="m-0">
                        {StringMap(jobdetail.work_location)}
                        {user
                          ? getIndexValue(
                              jobdetail.wfh_type,
                              workFromhomeType,
                              "value",
                              "label"
                            ) &&
                            " (" +
                              getIndexValue(
                                jobdetail.wfh_type,
                                workFromhomeType,
                                "value",
                                "label"
                              ) +
                              ")"
                          : getIndexValue(
                              jobdetail.wfh_type,
                              workFromhomeType,
                              "value",
                              "label"
                            ) &&
                            " (" +
                              getIndexValue(
                                jobdetail.wfh_type,
                                workFromhomeType,
                                "value",
                                "label"
                              ) +
                              ")"}
                      </p>
                      <small className="text-gray">
                        JID: {StringMap(jobdetail.job_id)} | Posted by{" "}
                      </small>{" "}
                      <small>
                        {StringMap(jobdetail.posted_by ?? jobdetail.created_by)}
                      </small>{" "}
                      <small className="text-gray">
                        on{" "}
                        {moment(jobdetail.job_created_on).format("lll") + " |"}{" "}
                        Department:{" "}
                        <small>{StringMap(jobdetail.department)}</small>
                        {/* Hiring Manager:{" "}
                        <small>{StringMap(jobdetail.hiring_manager)}</small> */}
                      </small>
                    </div>
                  </IonCol>
                  {/* details */}
                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="com-custom-grid">
                      <div className=" com-col-section remove-p">
                        <div>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/date_range.svg"
                            name="Engagement"
                          />

                          <small>Engagement</small>
                        </div>
                        <label>
                          {jobdetail.engagement_type === "0"
                            ? "Contract"
                            : "Permanent"}
                        </label>
                      </div>
                      <div className="com-col-section">
                        <div>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/business_center.svg"
                            name="Exp"
                          />

                          <small>Exp.</small>
                        </div>
                        <label>{EXPStringMap(jobdetail.overall_exp) + " y"}</label>
                      </div>
                      <div className="com-col-section">
                        <div>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/monetization_on.svg"
                            name="Budget"
                          />

                          <small>Budget</small>
                        </div>
                        <label>
                          {/* {getIndexValue(
                            jobdetail.pay_type,
                            locationData.currencyType,
                            "value",
                            "symbol"
                          ) + */}
                          {"₹ " + StringMap(jobdetail.payment)}{" "}
                          {jobdetail.engagement_type === "0"
                            ? "per hour"
                            : "per year"}
                          {/* getIndexValue(
                              jobdetail.pay_duration,
                              locationData.paymentDurationType,
                              "value",
                              "label"
                            ) */}
                        </label>
                      </div>
                      <div className="com-col-section">
                        <div>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/event_available.svg"
                            name="Start"
                          />

                          <small>Start</small>
                        </div>
                        <label>{FullDateStringMap(jobdetail.start_date)}</label>
                      </div>
                      {jobdetail.duration_type === "0" && (
                        <div
                          className="com-col-section"
                          style={{ borderRightStyle: "none" }}
                        >
                          <div>
                            <CardProfileImage
                              folder_key=""
                              profile_photo="assets/image/company/event_busy.svg"
                              name="End"
                            />

                            <small>End</small>
                          </div>
                          <label>{FullDateStringMap(jobdetail.end_date)}</label>
                        </div>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
                {/* cards */}
                {screenName === "1" && (
                  <>
                    {user && (
                      <IonRow className="py-3">
                        {screenName !== "0" && (
                          <IonCol sizeLg="3" sizeMd="6" sizeXs="12">
                            <IonCard className="ion-padding">
                              <IonItem lines="none">
                                <CardProfileImage
                                  folder_key=""
                                  profile_photo="assets/image/company/extension.svg"
                                  name="matches"
                                />

                                <IonLabel>
                                  <div className="px-2">
                                    <b>
                                      {screenName === "1"
                                        ? NumberStringMap(
                                            jobdetail.matched_applicants
                                          )
                                        : "0"}{" "}
                                    </b>
                                    <span> matches</span>
                                    {/* <small className="text-orange">2 unseen</small> */}
                                  </div>
                                </IonLabel>
                                {user && (
                                  <IonIcon
                                    icon={chevronForwardOutline}
                                    slot="end"
                                    onClick={(e: any) =>
                                      candidates("1", {
                                        ...jobdetail,
                                        unique_id: locationData.unique_id,
                                        workFromhomeType: workFromhomeType,
                                        workPermit: workPermit,
                                        screenName: locationData.screenName,
                                        paymentDurationType:
                                          locationData.paymentDurationType,
                                        currencyType: locationData.currencyType,
                                        jngskills: locationData.jngskills,
                                        comid: locationData.comid,
                                      })
                                    }
                                  />
                                )}
                              </IonItem>
                            </IonCard>
                          </IonCol>
                        )}
                        <IonCol sizeLg="3" sizeMd="6" sizeXs="12">
                          <IonCard className="ion-padding">
                            <IonItem lines="none">
                              <CardProfileImage
                                folder_key=""
                                profile_photo="assets/image/company/groups.svg"
                                name="applicants"
                              />

                              <IonLabel>
                                <div className="px-2">
                                  <b>
                                    {screenName === "1"
                                      ? NumberStringMap(
                                          jobdetail.totalapplicants
                                        )
                                      : "0"}{" "}
                                  </b>
                                  <span> applicants</span>
                                  {/* <small className="text-orange">5 unseen</small> */}
                                </div>
                              </IonLabel>
                              {user && (
                                <IonIcon
                                  icon={chevronForwardOutline}
                                  slot="end"
                                  onClick={(e: any) =>
                                    candidates("2", {
                                      ...jobdetail,
                                      unique_id: locationData.unique_id,
                                      workFromhomeType: workFromhomeType,
                                      workPermit: workPermit,
                                      screenName: locationData.screenName,
                                      paymentDurationType:
                                        locationData.paymentDurationType,
                                      currencyType: locationData.currencyType,
                                      jngskills: locationData.jngskills,
                                      comid: locationData.comid,
                                    })
                                  }
                                />
                              )}
                            </IonItem>
                          </IonCard>
                        </IonCol>
                        <IonCol sizeLg="3" sizeMd="6" sizeXs="12">
                          <IonCard className="ion-padding ">
                            <IonItem lines="none">
                              <CardProfileImage
                                folder_key=""
                                profile_photo="assets/image/company/favorite_border.svg"
                                name="shortlisted"
                              />

                              <IonLabel>
                                <div className="px-2">
                                  <b>
                                    {screenName === "1"
                                      ? NumberStringMap(
                                          jobdetail.shortlisted_applicants
                                        )
                                      : "0"}
                                  </b>
                                  <span> shortlisted</span>
                                  {/* <small className="text-orange">2 unseen</small> */}
                                </div>
                              </IonLabel>
                              {user && (
                                <IonIcon
                                  icon={chevronForwardOutline}
                                  slot="end"
                                  onClick={(e: any) =>
                                    candidates("3", {
                                      ...jobdetail,
                                      unique_id: locationData.unique_id,
                                      workFromhomeType: workFromhomeType,
                                      workPermit: workPermit,
                                      screenName: locationData.screenName,
                                      paymentDurationType:
                                        locationData.paymentDurationType,
                                      currencyType: locationData.currencyType,
                                      jngskills: locationData.jngskills,
                                      comid: locationData.comid,
                                    })
                                  }
                                />
                              )}
                            </IonItem>
                          </IonCard>
                        </IonCol>
                        <IonCol sizeLg="3" sizeMd="6" sizeXs="12">
                          <IonCard className="ion-padding">
                            <IonItem lines="none">
                              <CardProfileImage
                                folder_key=""
                                profile_photo="assets/image/company/check_circle1.svg"
                                name="hired"
                              />

                              <IonLabel>
                                <div className="px-2">
                                  <b>
                                    {screenName === "1"
                                      ? NumberStringMap(
                                          jobdetail.hired_applicants
                                        )
                                      : "0" +
                                        "/" +
                                        NumberStringMap(
                                          jobdetail.openings
                                        )}{" "}
                                  </b>
                                  <span> hired</span>
                                  {/* <small className="text-orange"></small> */}
                                </div>
                              </IonLabel>
                              {user && (
                                <IonIcon
                                  icon={chevronForwardOutline}
                                  slot="end"
                                  onClick={(e: any) =>
                                    candidates("5", {
                                      ...jobdetail,
                                      unique_id: locationData.unique_id,
                                      workFromhomeType: workFromhomeType,
                                      workPermit: workPermit,
                                      screenName: locationData.screenName,
                                      paymentDurationType:
                                        locationData.paymentDurationType,
                                      currencyType: locationData.currencyType,
                                      jngskills: locationData.jngskills,
                                      comid: locationData.comid,
                                    })
                                  }
                                />
                              )}
                            </IonItem>
                          </IonCard>
                        </IonCol>
                      </IonRow>
                    )}
                  </>
                )}
                <IonRow>
                  <IonCol sizeLg="12" sizeMd="12" sizeXs="12">
                    <IonCard className="ion-padding px-lg-4">
                      <h3 className="pb-4">Job description</h3>
                      <ReadMoreRichText length={650}>
                        {jobdetail.description}
                      </ReadMoreRichText>
                      <IonGrid className="ion-no-padding py-2">
                        {jobdetail.mandatory_skills.length !== 0 && <hr />}
                        {jobdetail.mandatory_skills.length !== 0 && (
                          <IonRow>
                            <IonCol sizeLg="4" sizeMd="12" sizeXs="12">
                              <label>Mandatory skills</label>
                            </IonCol>
                            <IonCol sizeLg="8" sizeMd="12" sizeXs="12">
                              {ArrayStringMap(jobdetail.mandatory_skills) !==
                              "NA"
                                ? jobdetail.mandatory_skills.map(
                                    (m: any, inx: number) => (
                                      <IonChip color="orangeblack" key={inx}>
                                        <IonLabel>{m.skill_name}</IonLabel>
                                      </IonChip>
                                    )
                                  )
                                : "NA"}
                            </IonCol>
                          </IonRow>
                        )}
                        {jobdetail.additional_skills.length !== 0 && <hr />}
                        {jobdetail.additional_skills.length !== 0 && (
                          <IonRow>
                            <IonCol sizeLg="4" sizeMd="12" sizeXs="12">
                              <label>Additional skills</label>
                            </IonCol>
                            <IonCol sizeLg="8" sizeMd="12" sizeXs="12">
                              {ArrayStringMap(jobdetail.additional_skills) !==
                              "NA"
                                ? jobdetail.additional_skills.map(
                                    (m: any, inx: number) => (
                                      <IonChip color="orangeblack" key={inx}>
                                        <IonLabel>{m.skill_name}</IonLabel>
                                      </IonChip>
                                    )
                                  )
                                : "NA"}
                            </IonCol>
                          </IonRow>
                        )}
                        <hr />
                        {/* <IonRow>
                          <IonCol sizeLg="4" sizeMd="12" sizeXs="12">
                            <label>US work authorization(s) required</label>
                          </IonCol>
                          <IonCol sizeLg="8" sizeMd="12" sizeXs="12">
                            {user &&
                            ArrayStringMap(jobdetail.US_work_authorization) !==
                              "NA"
                              ? jobdetail.US_work_authorization.map(
                                  (work: any, inx: number) => (
                                    <span key={inx}>
                                      {getIndexValue(
                                        work.us_work_authrizations,
                                        locationData !== undefined
                                          ? locationData.workPermit
                                          : workPermit,
                                        "value",
                                        "label"
                                      )}
                                      {jobdetail.US_work_authorization.length -
                                        1 !==
                                        inx && " / "}
                                    </span>
                                    // getIndexVisaTypeValue
                                  )
                                )
                              : ArrayStringMap(
                                  jobdetail.US_work_authorization
                                ) !== "NA" &&
                                jobdetail.US_work_authorization.map(
                                  (work: any, inx: number) => (
                                    <span key={inx}>
                                      {getIndexValue(
                                        work.us_work_authrizations,
                                        locationData !== undefined
                                          ? locationData.workPermit
                                          : workPermit,
                                        "value",
                                        "label"
                                      )}
                                      {jobdetail.US_work_authorization.length -
                                        1 !==
                                        inx && " / "}
                                    </span>
                                  )
                                )}
                          </IonCol>
                        </IonRow> */}
                      </IonGrid>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {/* edit job */}
              <ModalBody
                title={"Edit job"}
                isOpen={showModal}
                onClose={() => {
                  setShowModal(false);
                }}
              >
                <JobForm
                  workhomeOption={workFromhomeType}
                  paymentDurationOption={
                    locationData !== undefined
                      ? locationData.paymentDurationType
                      : []
                  }
                  workPermitOption={
                    locationData !== undefined ? locationData.workPermit : []
                  }
                  currencyOption={
                    locationData !== undefined ? locationData.currencyType : []
                  }
                  comid={jobdetail.com_id}
                  skillsOption={
                    locationData !== undefined ? locationData.jngskills : []
                  }
                  uniqueid={
                    locationData !== undefined ? locationData.unique_id : ""
                  }
                  formType={"edit"}
                  screen={
                    locationData !== undefined ? locationData.screenName : "0"
                  }
                  editDatas={editValue}
                  pageName="detailpage"
                  updateState={(data) => UpdateState(data)}
                  buttonAction={true}
                  closeModal={() => {
                    setShowModal(false);
                    // getJobDetails();
                  }}
                />
              </ModalBody>
              {/* invite talent */}
              <ModalBody
                title="Invite talent"
                subTitle="The talent will receive an email with invitation to apply to the job."
                required={true}
                isOpen={showModalTalent}
                onClose={() => setShowModalTalent(false)}
              >
                <InviteTalent
                  uniqueid={
                    locationData !== undefined ? locationData.unique_id : ""
                  }
                  closeModal={() => setShowModalTalent(false)}
                />
              </ModalBody>
            </>
          ) : !!showLoading && !jobdetail ? (
            <div className="m-auto py-3">
              <h4> {showLoading ? "" : "No data found"}</h4>
            </div>
          ) : (
            "loading..."
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(JobDetailScreen);
