import {
  IonCard,
  IonChip,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
} from "@ionic/react";
import React from "react";
import { useLocation } from "react-router";
import { AuthContext } from "../../context/authContext";
import { DeikaaChip } from "../../screens";
import { FindDeikaaKey } from "../../service";

const CustomAnchor = ({ pathname, username, PageLink, deikaaActive }: any) => {
  const { auth } = React.useContext(AuthContext);
  const deikaaData = auth.dekiaa;
  const location = useLocation();
  return (
    <IonCard>
      <IonItemGroup className="side-groupNav">
        {deikaaActive && (
          <div className="text-center">
            <DeikaaChip
              flag="PHOTOUPLOAD"
              data={FindDeikaaKey(deikaaData, "action_title", "PHOTOUPLOAD")}
            />
          </div>
        )}
        {PageLink &&
          PageLink.map((m: any, i: number) => (
            <IonList lines="none" className="bottom-line" key={i}>
              <IonItem
                key={m.link}
                href={pathname + username + m.link}
                target="_self"
                className={
                  pathname + username + m.link ==
                  pathname + username + location.hash
                    ? "side-nav-header active"
                    : "side-nav-header"
                }
              >
                <IonLabel className="label">{m.title}</IonLabel>
              </IonItem>

              {m.child.map((s: any, j: number) => (
                <IonItem
                  key={s.link}
                  href={pathname + username + s.link}
                  target="_self"
                  className={
                    pathname + username + s.link ==
                    pathname + username + location.hash
                      ? "side-nav-subheader active"
                      : "side-nav-subheader"
                  }
                >
                  <IonLabel className="label"> {s.sub}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          ))}
      </IonItemGroup>
    </IonCard>
  );
};

export default CustomAnchor;
