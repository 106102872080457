import React from "react";
import { IonItem } from "@ionic/react";
import { CommonSection } from ".";
interface props {
  toolsData: any;
}
export const Tools: React.FC<props> = ({ toolsData }) => {
  const [tool, settool] = React.useState<any>();

  React.useEffect(() => {
    settool(tool);
  }, []);
  return (
    <div id="tools">
      <IonItem lines="none" className="custom-header-space ">
        <h4>Tools</h4>
      </IonItem>
      <CommonSection arrs={toolsData} type={"skills"} />
    </div>
  );
};

export default React.memo(Tools);
