import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
  IonToggle,
} from "@ionic/react";
import { briefcaseOutline, calendarOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { StringMap, stringReplace } from "../../service";
import { GetCtrRating, SaveCtrRating } from "../../service/allApi";
import { CustomRangeSlider } from "../customRangeSlider/customRangeSlider";

const ratingType = [
  {
    label: "Communication",
    value: 0,
    inputType: "slider",
  },
  {
    label: "Team_Player",
    value: 0,
    inputType: "slider",
  },
  {
    label: "Recommended_by_others",
    value: 0,
    inputType: "slider",
  },
  {
    label: "Responsiveness",
    value: 0,
    inputType: "slider",
  },
  {
    label: "Wait_time_for_new_assignment",
    value: 0,
    inputType: "slider",
  },
  {
    label: "Owns_a_house",
    value: false,
    inputType: "toggle",
  },
  {
    label: "Staying_with_Family",
    value: false,
    inputType: "toggle",
  },
  {
    label: "Kids_Studying",
    value: false,
    inputType: "toggle",
  },
];

const finalRatingType = [
  {
    label: "Communication",
    value: 0,
  },
  {
    label: "Team_Player",
    value: 0,
  },
  {
    label: "Recommended_by_others",
    value: 0,
  },
  {
    label: "Responsiveness",
    value: 0,
  },
  {
    label: "Wait_time_for_new_assignment",
    value: 0,
  },
];

export default function RateComponent({ ctrID }) {
  const [ratingList, setRatingList] = useState<any[]>(ratingType);
  const [getData, setGetData] = useState<{
    NumberofPositionheld: number;
    total_experience: string;
    Longeststay: string;
  }>({
    NumberofPositionheld: 0,
    total_experience: "0 Years, 0 Month",
    Longeststay: "0",
  });
  const [finalratingList, setFinalRatingList] =
    useState<any[]>(finalRatingType);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);

  function getCtrRating() {
    GetCtrRating(ctrID).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setRatingList([]);
        setFinalRatingList([]);
        setGetData(e.data);
        const apiData = e.data["my_ratings"];
        const newarray: { label: string; value: number | boolean }[] = [];
        ratingType.map((item, i) => {
          apiData.map((e) => {
            if (item.label === e.rating_type) {
              item.value = Number(e.rating_value);
              newarray.push(item);
            }
          });
        });
        const boolingrating = e.data.boolean_ratings;

        ratingType.map((item, i) => {
          boolingrating !== undefined &&
            Object.keys(boolingrating).forEach(function (key, index) {
              if (item.label === key) {
                item.value = boolingrating[key];
                newarray.push(item);
              }
            });
        });
        const finalrating = e.data.finalratings;
        const newarray1: { label: string; value: number }[] = [];
        finalRatingType.map((item, i) => {
          finalrating !== undefined &&
            Object.keys(finalrating).forEach(function (key, index) {
              if (item.label === key) {
                item.value = finalrating[key] === 0 ? 1 : finalrating[key];
                newarray1.push(item);
              }
            });
        });
        var ids = new Set(ratingType.map((d) => d.label));
        var merged = [
          ...ratingType,
          ...newarray.filter((d) => !ids.has(d.label)),
        ];
        setRatingList(merged);
        setFinalRatingList(newarray1);
        setShowLoading(false);
      } else {
        setGetData(e.data);
        setRatingList(ratingType);
        setFinalRatingList(finalRatingType);
        setShowLoading(false);
        // setToastColor("danger");
        // setToastMessage(e !== undefined ? e.message : "please try again");
        // setShowToast(true);
      }
    });
  }

  const saveCtrRating = () => {
    if (ratingList) {
      const rate: any = [];
      ratingList.map((e) => {
        if (e.value !== 0) {
          rate.push({
            rating_type: e.label,
            rating_value: e.value,
          });
        }
      });
      const result = {
        ctr_id: ctrID,
        rating: rate,
      };
      SaveCtrRating(result).then((value) => {
        if (value !== undefined && value.status === 200) {
          setShowLoading(false);
          setToastColor("success");
          setToastMessage(value.message);
          setShowToast(true);
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(
            value !== undefined ? value.message : "please try again"
          );
          setShowToast(false);
        }
      });
    }
  };

  const handleChangeValue = (
    e: number | boolean,
    indexValue: string,
    inputType: string
  ) => {
    if (e && inputType === "slider") {
      const finalData = ratingList.map((item) => ({
        ...item,
        value: indexValue === item.label ? e : item.value,
      }));
      setRatingList(finalData);
      return;
    } else {
      const finalData = ratingList.map((item) => ({
        ...item,
        value: indexValue === item.label ? e : item.value,
      }));
      setRatingList(finalData);
      return;
    }
  };

  useEffect(() => {
    getCtrRating();
  }, [ctrID]);
  return (
    <div>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <div className="d-flex ion-wrap ion-align-items-center ion-justify-content-between py-3">
        <IonLabel>Rating</IonLabel>
      </div>
      <IonCard className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4">
              <div className="main-section vertical-line">
                <label>Total Experience</label>
                <div className="body-content-section">
                  <IonIcon
                    icon={briefcaseOutline}
                    className="custom-das-icon"
                  />
                  {StringMap(getData.total_experience)}
                </div>
                <div className="vertical-line"></div>
              </div>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4">
              <div className="main-section vertical-line">
                <label>No. of Jobs</label>
                <div className="body-content-section">
                  <IonIcon
                    icon={briefcaseOutline}
                    className="custom-das-icon"
                  />
                  {getData.NumberofPositionheld}
                </div>
                <div className="vertical-line"></div>
              </div>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4">
              <div className="main-section">
                <label>Longest stay</label>
                <div className="body-content-section">
                  <IonIcon icon={calendarOutline} className="custom-das-icon" />
                  {getData.Longeststay}
                </div>
                <div className="vertical-line"></div>
              </div>
            </IonCol>
            <IonCol sizeLg="12" sizeXs="12">
              <hr className="dot" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="12">
              <IonRow>
                <IonCol sizeLg="12" sizeXs="12">
                  <b> My Rating</b>
                </IonCol>
              </IonRow>
              {ratingList.map((rating, i) => (
                <IonRow
                  key={i}
                  className="ion-align-items-center ion-justify-content-start ion-no-margin"
                >
                  <IonCol
                    sizeLg="4"
                    sizeMd="4"
                    sizeXl="4"
                    sizeXs="12"
                    sizeSm="12"
                  >
                    <IonLabel>{stringReplace(rating.label)}</IonLabel>
                  </IonCol>{" "}
                  <IonCol
                    sizeLg="7"
                    sizeMd="7"
                    sizeXl="7"
                    sizeXs="12"
                    sizeSm="12"
                  >
                    {rating.inputType === "slider" ? (
                      <CustomRangeSlider
                        value={rating.value}
                        slot={"end"}
                        min={0}
                        max={10}
                        disable={false}
                        hangleChange={(e) =>
                          handleChangeValue(e, rating.label, rating.inputType)
                        }
                      />
                    ) : (
                      <IonToggle
                        mode={"md"}
                        checked={rating.value}
                        onIonChange={(e) => {
                          handleChangeValue(
                            e.detail.checked,
                            rating.label,
                            rating.inputType
                          );
                        }}
                      />
                    )}
                  </IonCol>
                </IonRow>
              ))}
              <IonRow className="ion-align-items-end ion-justify-content-end ion-no-margin">
                <IonCol sizeLg="12" sizeXs="12">
                  <IonButton
                    shape="round"
                    fill="solid"
                    color="secondary"
                    className="remove-shadow capitalizes"
                    type="submit"
                    onClick={() => saveCtrRating()}
                  >
                    Save
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="12">
              <IonRow>
                <IonCol sizeLg="12" sizeXs="12">
                  <b> Final Rating</b>
                </IonCol>
              </IonRow>
              {finalratingList.map((rating, i) => (
                <IonRow
                  key={i}
                  className="ion-align-items-center ion-justify-content-start ion-no-margin"
                  style={{ padding: rating.value === 0 ? "9px 0" : "" }}
                >
                  <IonCol
                    sizeLg="4"
                    sizeMd="4"
                    sizeXl="4"
                    sizeXs="12"
                    sizeSm="12"
                  >
                    <IonLabel>{stringReplace(rating.label)}</IonLabel>
                  </IonCol>{" "}
                  <IonCol
                    sizeLg="7"
                    sizeMd="7"
                    sizeXl="7"
                    sizeXs="12"
                    sizeSm="12"
                  >
                    {rating.value !== 0 ? (
                      <CustomRangeSlider
                        value={rating.value}
                        slot={"end"}
                        min={0}
                        max={10}
                        disable={true}
                        hangleChange={(e) => {}}
                      />
                    ) : (
                      "NA"
                    )}
                  </IonCol>
                </IonRow>
              ))}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
    </div>
  );
}
