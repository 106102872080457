import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
  IonLoading,
  IonToast,
  IonCard,
} from "@ionic/react";
import { trashOutline, createOutline } from "ionicons/icons";
import React from "react";
import { RouteComponentProps } from "react-router";
import Pagination from "react-js-pagination";
import { Modal, Search, TableHeader } from "../../component";
import AddStaffUser from "../forms/addstaffuser";
import { getMethod } from "../../service";
import swal from "sweetalert";
import DataTableComponent from "../../component/DataTable/dataTable";

interface Props
  extends RouteComponentProps<{
    companyID?: string;
  }> {}

//const Staffuser: React.FC= () => {

class Staffuser extends React.Component {
  state = {
    showModal: false,
    showToast: false,
    toastMessage: "",
    toastColor: "danger",
    showLoading: false,
    totalItems: 0,
    pageCount: 0,
    tableData: [],
    offset: 0,
    perPage: 10,
    currentPage: 1,
    comments: [],
    search: "",
    sorting: { field: "", order: "" },
    getUserRole: [],
    showHeaderType: true,
    showValue: [],
    errormsg: "",
    gender: "",
    popoverState: {
      showPopover: false,
      event: undefined,
    },
  };

  column = [
    { name: "S.No", field: "id", sortable: false, value: "id" },
    {
      name: "Name",
      field: "fullName",
      sortable: false,
      value: "fullName",
      wordsplit: " ",
      validate: "",
    },
    {
      name: "Email",
      field: "email",
      sortable: false,
      value: "email",
      wordsplit: "",
    },
    {
      name: "Action",
      field: "",
      sortable: false,
      value: "edit:delete",
      wordsplit: " ",
    },
  ];

  componentDidMount() {
    this.GetCU();
    this.GetCR();
  }

  setShowModals = () => {
    this.GetCU();
    this.setState({
      showModal: false,
    });
  };

  GetCU = () => {
    this.setState({
      showLoading: true,
    });
    setTimeout(() => {
      const FetchData = getMethod("/Getalljngstaffuser");
      FetchData.then((value: any) => {
        this.setState({
          showLoading: false,
        });
        if (value !== undefined && value.status === 200) {
          const finalData = value.data.map(item => ({
            ...item,
            fullName: `${item.firstname} ${item.lastname}` || "",
          }))
          var slice = finalData.slice(this.state.offset, this.state.perPage);
          this.setState({
            showLoading: false,
            comments: slice,
            tableData: finalData,
            pageCount: Math.ceil(finalData.length),
          });
        } else if (value.status === 201) {
          this.setState({
            showLoading: false,
            comments: [],
          });
        }
      });
    }, 100);
  };

  GetCR = () => {
    setTimeout(() => {
      const FetchData = getMethod("Getjngroles/");
      FetchData.then((value: any) => {
        if (value.data !== undefined && value.status === 200) {
          this.setState({
            getUserRole: value.data,
          });
        } else {
        }
      });
    }, 100);
  };

  handlePageChange = (e) => {
    const selectedPage = e;
    const num = (selectedPage - 1) * this.state.perPage + 10;
    this.setState({
      currentPage: selectedPage,
    });
    const data = this.state.tableData;
    const slice = data.slice(this.state.perPage * (selectedPage - 1), num);
    this.setState({
      comments: slice,
    });
  };

  onSearch = (value) => {
    let computedComments = this.state.tableData;
    if (value) {
      const computedComment = computedComments.filter(
        (comment: any) =>
          comment.fullName.toLowerCase().includes(value.toLowerCase()) ||
          comment.email.toLowerCase().includes(value.toLowerCase())
      );
      this.setState({
        pageCount: computedComment.length,
        comments: computedComment,
      });
    } else {
      this.setState({
        offset: 0,
        pageCount: 1,
      });
      var slice = computedComments.slice(this.state.offset, this.state.perPage);
      this.setState({
        pageCount: computedComments.length,
        comments: slice,
      });
    }
  };

  // const sortingData = () => {
  //   let computedComments = tableData;
  //   //Sorting comments
  //   if (sorting.field) {
  //     const reversed = sorting.order === "asc" ? 1 : -1;
  //     computedComments = computedComments.sort(
  //       (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  //     );
  //     var slice = computedComments.slice(offset, perPage);
  //     setPageCount(computedComments.length);
  //     setComments(slice);
  //   }
  // };

  edit = (data: any) => {
    this.setState({
      showModal: true,
      showHeaderType: false,
      showValue: data,
    });
  };

  setHeaderTypes = () => {
    this.setState({
      showHeaderType: true,
    });
    this.GetCU();
  };

  removeUser = (da) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      buttons: ["Cancel", "Ok"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (da) {
          this.setState({
            showLoading: true,
          });
          const FetchData = getMethod("Companyuser/delete_user/" + da.uid);
          FetchData.then((value: any) => {
            if (value.status === 200) {
              this.GetCU();
              this.setState({
                showLoading: true,
              });
            } else {
              this.setState({
                showLoading: false,
                toastColor: "danger",
                toastMessage: value.message,
                showToast: true,
              });
            }
          });
        }
        swal(" user has been deleted successfully!", {
          icon: "success",
        });
      }
    });
  };

  renderAction = (type, item) => {
    switch (type) {
      case "delete":
        this.removeUser(item);
        return;
      case "edit":
        this.edit(item);
        return;
      default:
        return;
    }
  };

  render() {
    return (
      <>
        <IonToast
          isOpen={this.state.showToast}
          onDidDismiss={() =>
            this.setState({
              showToast: false,
            })
          }
          message={this.state.toastMessage}
          duration={5000}
          color={this.state.toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={this.state.showLoading}
          onDidDismiss={() =>
            this.setState({
              showLoading: false,
            })
          }
        />

        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonItem lines="none">
                <h3>All Users</h3>

                <IonButton
                  className="custom-button btn-margin-left"
                  color="secondary capitalize"
                  shape="round"
                  fill="solid"
                  onClick={() =>
                    this.setState({
                      showModal: true,
                    })
                  }
                >
                  Create Users
                </IonButton>
              </IonItem>
            </IonCol>

            <IonCol size="12">
              <IonCard className="ion-padding">
                <DataTableComponent
                  tableheaders={this.column}
                  tableData={this.state.comments}
                  currentPage={this.state.currentPage}
                  itemPerPage={this.state.perPage}
                  totalItem={this.state.pageCount}
                  searchInput={true}
                  searchFunction={(search) => this.onSearch(search)}
                  handleAction={(type, item) => {
                    this.renderAction(type, item);
                  }}
                  setCurrentPage={(page) => this.handlePageChange(page)}
                />
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <Modal showModal={this.state.showModal}>
          <div className="custom-modal">
            <AddStaffUser
              setShowModal={this.setShowModals}
              headerType={this.state.showHeaderType}
              companyUserRole={this.state.getUserRole}
              setHeaderType={this.setHeaderTypes}
              editData={this.state.showValue}
            />
          </div>
        </Modal>
      </>
    );
  }
}

export default React.memo(Staffuser);
