import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonTitle,
  IonToast,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { CardProfileImage, HeaderNav } from "../../../../component";
import moment from "moment";
import { AuthContext } from "../../../../context/authContext";
import { addDefaultSrc, getIndexValue, StringMap } from "../../../../service";
import {
  addCircle,
  closeOutline,
  searchCircle,
  searchCircleOutline,
  searchOutline,
  watch,
} from "ionicons/icons";
import Matches from "./matchlist";
import Applied from "./appliedList";
import Shortlisted from "./shortlistedList";
import Invited from "./InvitedList";
import Hired from "./hiredList";
import Rejected from "./rejectedList";
import useMediaQuery from "../../../../hooks/mediaQuery";
import {
  AssignShortlistedCandidate,
  GetContractorProfileDetails,
  GetmailData,
} from "../../Service/api";
import { SeverfilePath, decrypted } from "../../../../service/axios";
import { CRMRecuter, CRMRole } from "../../../../service/default";

interface tabsCount {
  applied_count: number;
  hired_count: number;
  invited_count: number;
  matching_candidate_count: number;
  rejected_count: number;
  shortlisted_count: number;
}
const defaultCount = {
  applied_count: 0,
  hired_count: 0,
  invited_count: 0,
  matching_candidate_count: 0,
  rejected_count: 0,
  shortlisted_count: 0,
};

const Candidates = ({ match, location, history }: any) => {
  const { auth } = React.useContext(AuthContext);
  const { canditateJobCount } = auth;
  const user = auth.data;
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [key, setKey] = React.useState(match.params["type"] ?? "1");
  const [jobDetaildata] = React.useState<any>(location.state);
  const [candidateDetails, setCandidatedetails] = React.useState<any>(null);
  const [publicUrl, setPublicUrl] = React.useState("");
  const [showLoading, setShowLoading] = React.useState(false);
  const [showShortlist, setShowShortlist] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");

  const handleShortList = () => {
    // jobDetaildata.unique_id
    if (publicUrl) {
      setShowLoading(true);
      const result = {
        public_url: publicUrl,
        unique_id: jobDetaildata.unique_id,
      };
     
      AssignShortlistedCandidate(result).then((e) => {
        if (e !== undefined && e.status === 200) {
          setToastColor("success");
          setToastMessage("Candidate shortlisted successfully");
          setKey('3');
          setShowToast(true);
          setShowLoading(false);
          setPublicUrl('');
          setCandidatedetails(null);
          setShowShortlist(false);
        } else {
          setShowLoading(false);
          setToastColor("warning");
          setToastMessage(e !== undefined && e.message);
          setShowToast(true);
        }
      });
    } else {
      setToastColor("warning");
      setToastMessage("Enter the contractor public url");
      setShowToast(true);
    }
  };

  const getCandidateDetail = (value) => {
    setShowLoading(true);
    const req = {
      public_url: value,
    };
    GetContractorProfileDetails(req).then((res: any) => {
      console.log("contractor details ==> ", res);
      if (res !== undefined && res.status === 200) {
        console.log("contractor details", res.data);
        setCandidatedetails(res.data);
      } else {
        setShowLoading(false);
        setToastColor("warning");
        setToastMessage(res !== undefined && res.message);
        setShowToast(true);
      }
    });
    setShowLoading(false);
  };
  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
        <div className={m4kQuery ? "container Jobs py-4" : "Jobs py-4"}>
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="6" sizeXs="9">
                <h1 className="detail-header m-0">Candidates</h1>
              </IonCol>
              <IonCol sizeLg="6" sizeXs="3">
                <IonItem lines="none">
                  {(user.user_type === CRMRecuter || user.user_type === CRMRole) && <IonChip
                    style={{
                      marginRight: "20px",
                      fontSize: "smaller",
                      textTransform: "capitalize",
                    }}
                    slot="end"
                    color={"light"}
                    onClick={() => {
                      setShowShortlist(true);
                      setPublicUrl("");
                      setCandidatedetails(null);
                    }}
                  >
                    <IonLabel color="secondary" className="custum-gradiend">
                      add candidate to shortlist
                    </IonLabel>

                    <IonIcon
                      size="large"
                      color="secondary"
                      icon={searchOutline}
                    />
                  </IonChip> }

                  <IonButton
                    slot="end"
                    fill="outline"
                    className="remove-shadow custom-circle-btn"
                    color="danger"
                    shape="round"
                    onClick={() => {
                      setShowLoading(false);
                      history.goBack();
                    }}
                  >
                    <IonIcon icon={closeOutline} slot="icon-only" />
                  </IonButton>
                </IonItem>
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <div className="com-header-details">
                  <h2>{StringMap(jobDetaildata.job_title)}</h2>
                  <p className="m-0">
                    {StringMap(jobDetaildata.work_location)}
                    {getIndexValue(
                      jobDetaildata.wfh_type,
                      jobDetaildata.workFromhomeType,
                      "value",
                      "label"
                    ) &&
                      " (" +
                        getIndexValue(
                          jobDetaildata.wfh_type,
                          jobDetaildata.workFromhomeType,
                          "value",
                          "label"
                        ) +
                        ")"}
                  </p>
                  <small className="text-gray">
                    JID:{" "}
                    {StringMap(jobDetaildata.job_id || jobDetaildata.com_pjid)}{" "}
                    | Posted by{" "}
                  </small>{" "}
                  <small>
                    {StringMap(
                      jobDetaildata.posted_by ?? jobDetaildata.created_by
                    )}
                  </small>{" "}
                  <small className="text-gray">
                    on{" "}
                    {moment(jobDetaildata.job_created_on).format("lll") + " |"}{" "}
                    Department:{" "}
                    <small>{StringMap(jobDetaildata.department)}</small>
                    {/* Hiring Manager:{" "}
                    <small>{StringMap(jobDetaildata.hiring_manager)}</small> */}
                  </small>
                </div>
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(ka: any) => {
                    setKey(ka);
                    history.push("/candidates/" + ka, location.state);
                  }}
                  className="mb-3"
                >
                  <Tab
                    eventKey="1" // matches
                    title={
                      "Matches (" +
                      canditateJobCount.matching_candidate_count +
                      ")"
                    }
                  >
                    {key === "1" && (
                      <Matches
                        pageLoad={showLoading}
                        uniqueid={location.state}
                        jobdetailData={jobDetaildata}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="2" //applied
                    title={"Applied (" + canditateJobCount.applied_count + ")"}
                  >
                    {key === "2" && (
                      <Applied
                        uniqueid={location.state}
                        jobdetailData={jobDetaildata}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="3" // shortlisted
                    title={
                      "Shortlisted (" +
                      canditateJobCount.shortlisted_count +
                      ")"
                    }
                  >
                    {key === "3" && (
                      <Shortlisted
                        jobdetailData={jobDetaildata}
                        uniqueid={location.state}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="4" //INVITED
                    title={"Invited (" + canditateJobCount.invited_count + ")"}
                  >
                    {key === "4" && (
                      <Invited
                        uniqueid={location.state}
                        jobdetailData={jobDetaildata}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="5" //hired
                    title={"Hired (" + canditateJobCount.hired_count + ")"}
                  >
                    {key === "5" && (
                      <Hired
                        uniqueid={location.state}
                        jobdetailData={jobDetaildata}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="6" //rejected
                    title={
                      "Rejected (" + canditateJobCount.rejected_count + ")"
                    }
                  >
                    {key === "6" && (
                      <Rejected
                        uniqueid={location.state}
                        jobdetailData={jobDetaildata}
                      />
                    )}
                  </Tab>
                </Tabs>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonModal mode="md" isOpen={showShortlist}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Add candidate to shortlist</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowShortlist(false)}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonItem lines="none" style={{ marginBottom: "20px" }}>
              <IonInput
                placeholder="Contractor public url"
                value={publicUrl}
                onIonChange={(e: any) => {
                  setPublicUrl(e.detail.value);
                }}
              />
              <IonButton
                // slot="end"
                style={{
                  fontSize: "smaller",
                  textTransform: "capitalize",
                }}
                fill="clear"
                color="orange"
                shape="round"
                onClick={() => {
                  getCandidateDetail(publicUrl);
                }}
              >
                Search
              </IonButton>
            </IonItem>
            {showLoading && <p>loading..</p>}
            {candidateDetails && (
              <IonItem lines="none">
                <IonAvatar slot="start">
                  <img
                    src={
                      SeverfilePath +
                      decrypted(candidateDetails.folder_key) +
                      "/" +
                      decrypted(candidateDetails.profile_photo)
                    }
                    alt={candidateDetails.username}
                    onError={addDefaultSrc}
                    className="photo"
                  />
                </IonAvatar>

                <IonLabel>
                  {candidateDetails.firstname + " " + candidateDetails.lastname}
                  <p> {StringMap(decrypted(candidateDetails.email))}</p>
                </IonLabel>
                <IonButton
                  slot="end"
                  style={{
                    fontSize: "smaller",
                    textTransform: "capitalize",
                  }}
                  fill="solid"
                  color="orange"
                  shape="round"
                  onClick={() => {
                    handleShortList();
                  }}
                >
                  Shortlist
                </IonButton>
                <IonButton
                  slot="end"
                  style={{
                    fontSize: "smaller",
                    textTransform: "capitalize",
                    margin: 0,
                  }}
                  fill="outline"
                  color="secondary"
                  shape="round"
                  onClick={() => {
                    window.open(candidateDetails.public_id, "_blank");
                  }}
                >
                  view
                </IonButton>
              </IonItem>
            )}
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Candidates);
