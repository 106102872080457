import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonLoading,
  IonToast,
} from "@ionic/react";
import { createOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  HeaderNav,
  Search,
  TableHeader,
  CompanyEmailVerify,
} from "../../component";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { getMethod, postMethod } from "../../service";
import { getPracticeList, Savepractice } from "./Service/api";

const CoreSkills: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [pageCount, setPageCount] = useState<any>(0);
  const [tableData, settableData] = useState<any>([]);
  const [perPage, setperPage] = useState(30);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [inEditMode, setInEditMode] = useState({
    ind: null,
    ctr_pa: "",
    practice_name: "",
    status: false,
  });

  const headers = [
    { name: "No#", field: "id", sortable: false },
    { name: "Core Name", field: "skill_name", sortable: false },
    { name: "Edit Skills", field: "action", sortable: false },
  ];

  React.useEffect(() => {
    let isMounted = true;
    try {
      GetSkills(currentPage, search);
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const GetSkills = (page, search) => {
    setShowLoading(true);
    getPracticeList(page + "/" + search).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        settableData(value.data);
        setCurrentPage(1);
        setPageCount(value.totalItemsCount);
        setperPage(value.itemsCountPerPage);
      } else {
        setShowLoading(false);
        setShowModal(false);
        setToastColor("danger");
        setToastMessage(value !== undefined && value.message);
        setShowToast(true);
        setPageCount(0);
        setperPage(30);
      }
    });
  };

  const handlePageChange = (e) => {
    if (e) {
      setCurrentPage(e);
      GetSkills(e, search);
    }
  };

  // save the record
  const onSave = (data) => {
    let practiceskill = {
      practice_id: data.ctr_pa ?? "",
      practice_name: data.practice_name ?? "",
    };
    setShowLoading(true);
    Savepractice(practiceskill).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        GetSkills(1, "");
        setToastColor("success");
        setToastMessage(value.data);
        setShowToast(true);
        setShowLoading(false);
        setShowModal(true);
      } else {
        setShowLoading(false);
        setShowModal(false);
        setToastColor("danger");
        setToastMessage(value !== undefined && value.message);
        setShowToast(true);
      }
    });

    onCancel();
  };
  // cancel
  const onCancel = () => {
    setInEditMode({
      ind: null,
      ctr_pa: "",
      practice_name: "",
      status: false,
    });
  };
  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />

      <HeaderNav />
      <IonContent>
        <CompanyEmailVerify />
        <IonGrid className="center">
          <IonRow>
            <IonCol offsetLg="1" sizeLg="10" sizeXs="12">
              <IonGrid>
                <IonRow></IonRow>
                <IonRow>
                  <IonCol sizeLg="6" size="12"></IonCol>

                  <IonCol
                    sizeLg="6"
                    size="12"
                    className="d-flex flex-row-reverse"
                  >
                    <Search
                      value={search}
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                      onclickaction={(e) => GetSkills(currentPage, search)}
                    />
                  </IonCol>

                  <IonCol size="12">
                    <div className="con-table-scrolbar">
                      <table className="table table-bordered table-hover">
                        <TableHeader
                          headers={headers}
                          onSorting={(field, order) => {
                            setSorting({ field, order });
                          }}
                        />
                        <tbody>
                          {tableData.map((skillsele, index) => (
                            <tr key={index}>
                              <th>{index + 1}</th>

                              <td>
                                {inEditMode.status &&
                                inEditMode.ctr_pa === skillsele.ctr_pa ? (
                                  <input
                                    value={inEditMode.practice_name}
                                    onChange={(event) =>
                                      setInEditMode({
                                        ...inEditMode,
                                        practice_name: event.target.value,
                                      })
                                    }
                                  />
                                ) : (
                                  skillsele.practice_name
                                )}
                              </td>
                              <td>
                                {inEditMode.status &&
                                inEditMode.ctr_pa === skillsele.ctr_pa ? (
                                  <React.Fragment>
                                    <IonButton
                                      className="custom-button"
                                      color="secondary capitalize"
                                      shape="round"
                                      fill="solid"
                                      onClick={(e) => onSave(inEditMode)}
                                    >
                                      Save
                                    </IonButton>

                                    <IonButton
                                      className="custom-button"
                                      color="danger"
                                      shape="round"
                                      fill="solid"
                                      onClick={() => onCancel()}
                                    >
                                      Cancel
                                    </IonButton>
                                  </React.Fragment>
                                ) : (
                                  <IonButton
                                    onClick={(e) => {
                                      setInEditMode({
                                        ind: index,
                                        ctr_pa: skillsele.ctr_pa,
                                        practice_name: skillsele.practice_name,
                                        status: true,
                                      });
                                    }}
                                    shape="round"
                                    fill="clear"
                                  >
                                    <IonIcon
                                      icon={createOutline}
                                      color="warning"
                                    />
                                  </IonButton>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </IonCol>

                  <IonCol size="12">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={pageCount}
                      pageRangeDisplayed={8}
                      onChange={handlePageChange}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(CoreSkills);
