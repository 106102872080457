import { getMethod, postMethod, Requests } from "../../../service";

// Get Contractor Profile Detail
export function addcrm(data: any) {
  return postMethod(Requests.fetchSaveCrmJob, data);
}

export function updatecrm(data: any) {
  return postMethod(Requests.fetchUpdateCrmJob, data);
}

export function deletecrm(data: any) {
  return getMethod(Requests.fetchDeleteCrmJob + data);
}

export function GetCRMJobList(dta: any) {
  return getMethod(Requests.fetchGetCrmJobList + dta);
}
