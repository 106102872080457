import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router";
import Project from "./project";
import { HeaderNav } from "../../../component";
interface Props extends RouteComponentProps<{}> {}

const CompanyProjects: React.FC = () => {
  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <Project />
      </IonContent>
    </IonPage>
  );
};
export default withRouter(CompanyProjects);
