import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
} from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import React from "react";
import { ModalBody } from "../../../../../component";
import { formatPhoneNumber, StringMap } from "../../../../../service";
import { decrypted } from "../../../../../service/axios";
import { NameLocationForm } from "../../Form";
type props = {
  ctrdata: any;
  firstname: string;
  currentLocation: string;
  country: string;
  state: string;
  city: string;
  lastname: string;
  zip: string;
  contact: string;
  countrycode: string;
};

const NameLocation: React.FC<props> = ({
  ctrdata,
  firstname,
  currentLocation,
  country,
  city,
  state,
  lastname,
  zip,
  contact,
  countrycode,
}: any) => {
  const [modelOpen, setModal] = React.useState(false);
  const [firstnames, setFirstname] = React.useState(firstname);
  const [lastnames, setlastname] = React.useState(lastname);
  const [countrys, setCountry] = React.useState(country);
  const [citys, setCity] = React.useState(city);
  const [states, setState] = React.useState(state);
  const [zips, setZip] = React.useState(zip);
  const [contacts, setContact] = React.useState(decrypted(contact));
  const [countrycodes, setCountrycode] = React.useState(countrycode);
  const [currentLocations, setCurrentLocation] =
    React.useState(currentLocation);

  function checkState(data: any) {
    if (data) {
      setFirstname(data.firstname ?? "");
      setlastname(data.lastname ?? "");
      setCountry(data.country ?? "");
      setCity(data.city ?? "");
      setState(data.state ?? "");
      setZip(data.zip ?? "");
      setContact(data.mobile ?? "");
      setCountrycode(data.country_code ?? "");
    }
  }

  return (
    <div id="namelocation">
      <ModalBody
        title={"Name & location"}
        isOpen={modelOpen}
        onClose={() => setModal(false)}
      >
        <NameLocationForm
          ctrid={ctrdata}
          modalSet={modelOpen}
          datareset={false}
          firstnameData={firstnames}
          currentLocationData={currentLocations}
          countryData={countrys}
          stateData={states}
          cityData={citys}
          lastnameData={lastnames}
          zipData={zips}
          mobileData={contacts}
          countrycodeData={countrycodes}
          stateUpdateData={checkState}
          closeModal={() => setModal(false)}
        />
      </ModalBody>

      <IonItem lines="none" className="custom-header-space ">
        <h4>Name & location</h4>
        <IonButton
          slot="end"
          fill="outline"
          className="remove-shadow custom-circle-btn"
          color="medium"
          shape="round"
          onClick={() => {
            setModal(true);
          }}
        >
          <IonIcon icon={pencilOutline} slot="icon-only" />
        </IonButton>
      </IonItem>

      <IonGrid>
        <IonRow>
          <IonCol sizeLg="4" sizeXs="12">
            <label>
              First Name <span className="required">*</span>
            </label>
          </IonCol>
          <IonCol sizeLg="8" sizeXs="12">
            <span>
              {firstnames + " " + lastnames}
              {firstnames && lastnames === "" && "NA"}{" "}
            </span>
            <hr />
          </IonCol>
          <IonCol sizeLg="4" sizeXs="12">
            <label>
              Current location <span className="required">*</span>
            </label>
          </IonCol>
          <IonCol sizeLg="8" sizeXs="12">
            <span>{countrys + ", " + states + ", " + citys}</span>
            <hr />
          </IonCol>
          <IonCol sizeLg="4" sizeXs="12">
            <label>
              Contact Number <span className="required">*</span>
            </label>
          </IonCol>
          <IonCol sizeLg="8" sizeXs="12">
            {contacts && countrycodes + " "}
            {formatPhoneNumber(contacts)}
          </IonCol>
        </IonRow>
      </IonGrid>
      <hr className="dot" />
    </div>
  );
};

export default React.memo(NameLocation);
