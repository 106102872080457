import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import moment from "moment";
import { addOutline, pencilSharp } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  ConcatArray,
  DateStringMap,
  SeverfilePaths,
} from "../../../../../service";
import { ModalBody } from "../../../../../component";
import { CertificationForm } from "../../Form";

type props = {
  certificateData: any;
  ctridData: any;
  folderkeyData: any;
};

interface fields {
  ctr_id: string;
  ctr_cid: string;
  name: string;
  issue_organization: string;
  result: any;
  result_type: any;
  not_expire: boolean;
  issue_start_date: moment.Moment;
  issue_end_date: moment.Moment;
  credential_id: string;
  credential_url: string;
  certifications_docs: "";
  doc_link: "";
  link: boolean;
}

const initialValues: fields = {
  ctr_id: "",
  ctr_cid: "",
  name: "",
  result: "",
  result_type: "",
  issue_organization: "",
  not_expire: false,
  link: false,
  issue_start_date: moment(),
  issue_end_date: moment(),
  credential_id: "",
  credential_url: "",
  certifications_docs: "",
  doc_link: "",
};

const Certifications: React.FC<props> = ({
  certificateData,
  ctridData,
  folderkeyData,
}) => {
  const [modelOpen, setModal] = React.useState(false);
  const [eventtype, setEventtype] = React.useState<any>("Add");
  const [editValue, setEditValue] = React.useState<fields>(initialValues);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [showLoading, setshowLoading] = React.useState(false);
  const [certificationuser, setcertificationuser] = React.useState<fields[]>(
    []
  );
  const [savedata, setsavedata] = useState<any>([]);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  function collectionData(value: any) {
    setcertificationuser(ConcatArray(value, certificationuser));
    setsavedata(value);
  }

  // delete crtification by id in state

  function deletecertification(data: any) {
    setDataupdated(false);
    if (data) {
      setsavedata(savedata.filter(({ ctr_cid }) => ctr_cid !== data));
      setDataupdated(true);
    }
  }

  useEffect(() => {
    setsavedata(certificateData);
  }, [certificateData]);

  function editOption(e: any) {
    const data = e;
    setEditValue({
      ...data,
      issue_start_date:
        data.issue_start_date && data.issue_start_date === "0000-00-00"
          ? ""
          : data.issue_start_date,
      issue_end_date:
        data.issue_end_date && data.issue_end_date === "0000-00-00"
          ? ""
          : data.issue_end_date,
    });
    setEventtype("Update");
    setModal(true);
  }

  return (
    <div id="certifications">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
        message={"Please Wait.."}
      />
      <ModalBody
        title={eventtype + " " + "certification"}
        isOpen={modelOpen}
        onClose={() => setModal(false)}
      >
        <CertificationForm
          ctrid={ctridData}
          initialValueData={editValue}
          closeModal={() => setModal(false)}
          setcertificationData={collectionData}
          eventType={eventtype}
          deletecertificationData={deletecertification}
          folderKey={folderkeyData}
          whenCall={modelOpen}
        />
      </ModalBody>

      <IonItem lines="none" className="custom-header-space ">
        <h4>Certifications</h4>
        <IonButton
          slot="end"
          fill="outline"
          className="remove-shadow custom-circle-btn"
          color="secondary"
          shape="round"
          onClick={() => {
            setModal(true);
            setEventtype("Add");
            setEditValue(initialValues);
          }}
        >
          <IonIcon icon={addOutline} slot="icon-only" />
        </IonButton>
      </IonItem>

      <CertificationSection
        arrs={savedata !== undefined && savedata}
        folderKey={folderkeyData}
        editfun={editOption}
      />
    </div>
  );
};

const CertificationSection = ({ arrs, editfun, folderKey }: any) => {
  const [certi, setCerti] = React.useState(3);

  return (
    <>
      <IonGrid className="ion-no-padding">
        {arrs &&
          arrs.length > 0 &&
          arrs.slice(0, certi).map((e: any, i: number) => (
            <IonRow key={i}>
              <IonCol sizeLg="4" sizeXs="12">
                <IonItem lines="none" className="custom-subheader-space ">
                  <IonLabel className="ion-text-wrap">
                    <label>
                      {DateStringMap(e.issue_start_date) + " - "}
                      {e.not_expire !== "0" ? (
                        <span className="highlightContent"> forever</span>
                      ) : (
                        DateStringMap(e.issue_end_date)
                      )}
                    </label>
                    {e.result && (
                      <p>
                        Result:{" "}
                        <span>
                          {e.result}&nbsp; {e.result_type}
                        </span>
                      </p>
                    )}
                  </IonLabel>
                </IonItem>
              </IonCol>

              <IonCol sizeLg="8" sizeXs="12">
                <IonItem lines="none" className="custom-subheader-space ">
                  <IonLabel className="ion-text-wrap">
                    <label>
                      <span>{e.name}</span>
                    </label>
                    <p>{e.issue_organization}</p>
                    {e.credential_id && (
                      <label className="pt-2">
                        id: <span>{e.credential_id}</span>
                      </label>
                    )}
                    <p className="mt-0">
                      <a
                        href={SeverfilePaths(folderKey) + e.credential_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {e.credential_url}
                      </a>
                    </p>
                  </IonLabel>

                  <IonButton
                    slot="end"
                    fill="outline"
                    className="remove-shadow custom-circle-btn"
                    color="medium"
                    shape="round"
                    onClick={() => {
                      editfun(e);
                    }}
                  >
                    <IonIcon icon={pencilSharp} slot="icon-only" />
                  </IonButton>
                </IonItem>
                {i !== arrs.slice(0, certi).length - 1 && <hr />}
              </IonCol>
            </IonRow>
          ))}
      </IonGrid>
      {arrs.length > 3 && (
        <IonItem lines="none">
          {arrs.length && arrs.length !== certi && arrs.length >= certi ? (
            <IonButton
              fill="clear"
              color="secondary"
              type="button"
              size="small"
              className="remove-shadow lowercase"
              onClick={() => setCerti(certi + 3)}
            >
              {arrs.length - certi} more certification(s)
            </IonButton>
          ) : (
            arrs.length > 3 &&
            arrs.length <= certi && (
              <IonButton
                fill="clear"
                color="secondary"
                type="button"
                size="small"
                className="remove-shadow lowercase"
                onClick={() => setCerti(9)}
              >
                less certification(s)
              </IonButton>
            )
          )}
        </IonItem>
      )}
    </>
  );
};

export default React.memo(Certifications);
