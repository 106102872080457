import { IonItem } from "@ionic/react";
import React, { SelectHTMLAttributes } from "react";

import "./select.css";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CreatableSelect from "react-select/creatable";

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  createText?: boolean;
  value?: any;
  ref?: any;
  isDisabled?: boolean;
  handleChange?: Function;
  options: { value: string; label: string }[];
}

const SelectBox: React.FC<Props> = ({
  name,
  label,
  options,
  required,
  placeholder,
  createText,
  value,
  isDisabled,
  ref,
  handleChange,
  ...rest
}) => {
  const selectedValue: any =
    (options !== undefined &&
      options.find((obj) => (obj.value === value ? obj.value === value : ""))) ??
    null;
  return (
    <div className="select-block">
      {label ? (
        <IonItem lines="none">
          <label slot="start" htmlFor={name}>
            {label} {required && <span className="red">*</span>}
          </label>
          {/* <label slot="end"> 0/1024 characters</label> */}
        </IonItem>
      ) : (
        ""
      )}
      {createText === false ? (
        <CreatableSelect
          id={name}
          isDisabled={isDisabled}
          styles={{
            menu: (base) => ({
              ...base,
              zIndex: "9999999",
            }),
          }}
          name={name}
          placeholder={placeholder}
          required={required}
          options={options}
          value={selectedValue ?? (selectedValue === "" ? null : selectedValue)}
          onChange={(e) => handleChange}
          {...rest}
        />
      ) : (
        <Select
          id={name}
          isDisabled={isDisabled}
          styles={{
            menu: (base) => ({
              ...base,
              zIndex: "9999999",
            }),
          }}
          name={name}
          placeholder={placeholder}
          required={required}
          options={options}
          value={selectedValue ?? (selectedValue === "" ? null : selectedValue)}
          onChange={(e) => handleChange}
          {...rest}
        />
      )}
    </div>
  );
};

export default SelectBox;
