import {
  IonBadge,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonText,
  IonToast,
  IonToggle,
} from "@ionic/react";
import {
  checkmarkCircleOutline,
  closeCircleOutline,
  hourglassOutline,
  ticketOutline,
} from "ionicons/icons";
import moment from "moment";
import { RouteComponentProps } from "react-router";
import React from "react";
import { getDeikaaProfileData, getRewardData } from "../Service/api";
import DeikaaSlider from "../Deikaa/deikaa";
import HeaderNav from "../../../component/Header/Header";
import useMediaQuery from "../../../hooks/mediaQuery";

interface Props
  extends RouteComponentProps<{
    ctrid: any;
  }> {}

interface field {
  complete_tokens: any;
  pending_tokens: any;
  rejected_tokens: any;
  total_complete_tokens: any;
  total_pending_tokens: any;
  total_rejected_tokens: any;
  total_token: any;
}

const fieldsData: field = {
  complete_tokens: [],
  pending_tokens: [],
  rejected_tokens: [],
  total_complete_tokens: 0,
  total_pending_tokens: 0,
  total_rejected_tokens: 0,
  total_token: 0,
};

const Reward: React.FC<Props> = ({ match }) => {
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [checked, setChecked] = React.useState(false);
  const [tokenRewarddata, setTokenRewardsData] =
    React.useState<field>(fieldsData);

  const [showLoading, setshowLoading] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");

  React.useEffect(() => {
    let isMounted = true;
    setshowLoading(true);
    try {
      getreward();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setshowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  function getreward() {
    getRewardData(match.params.ctrid).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setChecked(e.opt_in !== "1" ? false : true);
        setTokenRewardsData(e.data);
      }
    });
  }

  const joindeikaa = (data: any) => {
    setshowLoading(true);
    if (data) {
      getDeikaaProfileData(match.params.ctrid, data).then((e: any) => {
        if (e !== undefined && e.status === 200) {
          setshowLoading(false);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
        } else {
          setshowLoading(false);
          setToastColor("danger");
          setToastMessage(e !== undefined ? e.message : "Please try again.");
          setShowToast(true);
        }
      });
    }
  };
  return (
    <IonPage>
      <HeaderNav />

      <IonContent>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setshowLoading(false)}
        />
        <div className={m4kQuery ? "container" : ""}>
          <IonGrid>
            <IonRow>
              <IonCol size="12" className="pt-5">
                <h1>Rewards</h1>
                <p>
                  Earn tokens for using Hirify and stand a chance to win
                  smartphones, laptops, and other exciting prizes delivered
                  through our reward program, Deikaa
                </p>
              </IonCol>

              <IonCol sizeLg="2" sizeXs="12">
                <IonCard className="ion-padding border-orange-gradient">
                  <div className="reward-token">
                    <IonLabel>
                      <IonText>MY TOKENS</IonText>
                      <h2 className="mb-0 ">
                        <IonIcon
                          className="chip-font orange"
                          icon={ticketOutline}
                        />{" "}
                        {tokenRewarddata.total_token}
                      </h2>
                    </IonLabel>
                  </div>
                </IonCard>
              </IonCol>
              <IonCol sizeLg="10" sizeXs="12">
                <IonCard className="ion-padding">
                  <IonItem lines="none">
                    <img
                      alt=""
                      src="assets/image/deikaa/Deikaa_Logomark.svg"
                      className="px-4"
                    />
                    <IonLabel className="ion-text-warp">
                      <h3>
                        <IonText>Join the reward program</IonText>
                      </h3>
                      <p className="">
                        <small className="text-gray">
                          Subject to Deikaa's{" "}
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href="/terms"
                          >
                            terms
                          </a>{" "}
                          of use and{" "}
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href="/privacy-policy/"
                          >
                            privacy policy.
                          </a>
                        </small>
                      </p>
                    </IonLabel>
                    <IonToggle
                      slot="end"
                      mode="md"
                      checked={checked}
                      onIonChange={(e: any) => {
                        setChecked(e.detail.checked);
                      }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        joindeikaa(checked ? "1" : "0");
                      }}
                    />
                  </IonItem>
                </IonCard>
              </IonCol>

              <IonCol size="12" className="py-4">
                <DeikaaSlider ctrid="" optinData={""} screen="reward" />
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* completed */}

          <DeikaCards
            arr={tokenRewarddata.complete_tokens}
            title={
              tokenRewarddata.total_complete_tokens + " Deikaa tokens credited"
            }
            type="complete"
          />

          {/* pending */}

          <DeikaCards
            arr={tokenRewarddata.pending_tokens}
            title={
              tokenRewarddata.total_pending_tokens +
              " Deikaa tokens pending credit"
            }
            type="pending"
          />

          {/* rejected */}

          <DeikaCards
            arr={tokenRewarddata.rejected_tokens}
            title={
              tokenRewarddata.total_rejected_tokens + " Deikaa token rejected"
            }
            type="reject"
          />
        </div>
      </IonContent>
    </IonPage>
  );
};
export default React.memo(Reward);

function DeikaCards({ arr, title, type }: any) {
  return (
    <div className="rewards-cards">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h3 className="py-4">{title}</h3>
          </IonCol>
          {arr.map((e: any, i: number) => (
            <IonCol sizeLg="6" sizeXs="12" key={i}>
              <IonCard className="ion-padding m-2 reward">
                <IonItem lines="none">
                  <IonIcon
                    slot="start"
                    color={
                      type === "complete"
                        ? "success"
                        : type === "pending"
                        ? "warning"
                        : "danger"
                    }
                    icon={
                      type === "complete"
                        ? checkmarkCircleOutline
                        : type === "pending"
                        ? hourglassOutline
                        : closeCircleOutline
                    }
                  />
                  <IonLabel className="ion-text-warp">
                    <IonText>{e.qualifier}</IonText>
                    <p>
                      <small className="text-gray">
                        {type === "complete"
                          ? "Credited " +
                            moment(e.created_on).subtract(0, "days").calendar()
                          : type === "pending"
                          ? "Pending credit"
                          : "Try again"}
                      </small>
                    </p>
                  </IonLabel>
                  <IonBadge slot="end" className="badge">
                    {e.no_of_tokens}
                  </IonBadge>
                  <IonIcon slot="end" className="orange" icon={ticketOutline} />
                </IonItem>
              </IonCard>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </div>
  );
}
