import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { eye, eyeOffOutline } from "ionicons/icons";
import { getCookie, postMethod } from "../../../../service";

const AddCompanyUser = ({
  setShowModal,
  handleSubmits,
  companyUserRole,
  headerType,
  setHeaderType,
  editData,
  userId,
  formreset,
}: any) => {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [showPassword, setPassword] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [error, setError] = useState<any>();
  // const userdatacomid = getCookie({ key: "user" }).value ?? userId;

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  React.useEffect(() => {
    if (formreset === "add") {
      reset(saveUserData);
    }
    setValue("com_id", userId);
    setValue("firstname", editData.firstname);
    setValue("lastname", editData.lastname);
    setValue("username", editData.username);
    setValue("email", editData.email);
    setValue("password", editData.password);
    setValue("role_id", editData.role_id);
    setValue("uid", editData.uid);
    setValue("status", editData.status);
  }, [formreset]);

  function saveUserData(value: any) {
    setShowLoading(true);
    const addcompany = value;
    if (headerType) {
      addcompany["status"] = "1";
    }
    const postData = postMethod(
      headerType ? "Addcompanyuser" : "Updatecompanyuser",
      addcompany
    );

    postData.then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowModal(false);
        setToastColor("success");
        setToastMessage(value.data);
        setShowToast(true);
        setShowLoading(false);
      } else if (value.status === 201) {
        const errorInfo = value.message;
        if (typeof errorInfo === "string") {
          setError("");
          setToastColor("danger");
          setToastMessage(value.message);
          setShowToast(true);
          setShowLoading(false);
        } else {
          setError("");
          setToastColor("danger");
          for (const [key, value] of Object.entries(errorInfo)) {
            setToastMessage(`${value}`);
            setShowToast(true);
          }
          setShowLoading(false);
        }
      } else {
        setShowModal(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try later"
        );
        setShowToast(true);
        setShowLoading(false);
      }
    });
  }

  return (
    <div>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      {!showLoading && (
        <form onSubmit={handleSubmit(saveUserData)}>
          <IonCard className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol sizeLg="6" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      First name<span className="required">*</span>
                    </IonLabel>
                    <Controller
                      control={control}
                      name="firstname"
                      rules={{
                        required: "Firstname is required",
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <IonInput
                          autocomplete="off"
                          onBlur={onBlur}
                          onIonChange={onChange}
                          value={value}
                          minlength={0}
                          maxlength={32}
                          placeholder="Enter the firstname"
                        />
                      )}
                    />
                   
                   
                  </IonItem>
                  {showError("firstname")}
                </IonCol>

                <IonCol sizeLg="6" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Last name<span className="required">*</span>
                    </IonLabel>
                    <Controller
                      control={control}
                      name="lastname"
                      rules={{
                        required: "Lastname is required",
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <IonInput
                          autocomplete="off"
                          onBlur={onBlur}
                          onIonChange={onChange}
                          value={value}
                          minlength={0}
                          maxlength={32}
                          placeholder="Enter the lastname"
                        />
                      )}
                    />
                   
                  </IonItem>
                  {showError("lastname")}
                </IonCol>

                <IonCol sizeLg="6" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Email<span className="required">*</span>
                    </IonLabel>
                    <Controller
                      control={control}
                      name="email"
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "invalid email address",
                        },
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <IonInput
                          autocomplete="off"
                          onBlur={onBlur}
                          onIonChange={onChange}
                          value={value}
                          minlength={0}
                          maxlength={64}
                          placeholder="Enter the email"
                        />
                      )}
                    />
                   
                  </IonItem>
                  {showError("email")}
                </IonCol>

                <IonCol sizeLg="6" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Choose a username<span className="required">*</span>
                    </IonLabel>
                    <Controller
                      control={control}
                      name="username"
                      rules={{
                        required: "Username is required",
                        pattern: {
                          value: /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/,
                          message: "Invalid characters",
                        },
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <IonInput
                          autocomplete="off"
                          onBlur={onBlur}
                          onIonChange={onChange}
                          value={value}
                          minlength={0}
                          maxlength={32}
                          placeholder="Enter the username"
                        />
                      )}
                    />
                    {/* <IonText className="custom-content right-content-2">
                      {showValueCount("username")}/32
                    </IonText> */}
                    {/* <IonText className="helperText right-content-2">
                        a-z, 0-9, hyphens (-), and underscore (_)
                      </IonText> */}
                  </IonItem>
                  {showError("username")}
                </IonCol>
                <IonCol sizeLg="6" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">Password</IonLabel>
                    <Controller
                      control={control}
                      name="password"
                      rules={{
                        pattern: {
                          value: /^[ A-Za-z0-9_@./#&+-]*$/,
                          message: "Invalid characters",
                        },
                        minLength: {
                          value: 6,
                          message: "Password must have at least 6 characters",
                        },
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <>
                          <IonInput
                            autocomplete="off"
                            type={showPassword ? "text" : "password"}
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={6}
                            maxlength={64}
                            placeholder="Enter the password"
                          />
                          <IonButton
                            className="ion-no-padding customeye-btn"
                            fill="clear"
                            shape="round"
                            color="dark"
                            onClick={() => setPassword(!showPassword)}
                          >
                            <IonIcon
                              slot="icon-only"
                              icon={showPassword ? eye : eyeOffOutline}
                            />
                          </IonButton>
                        </>
                      )}
                    />
                    <IonText className="helperText right-content-2">
                      6-64 alphanumeric/special characters
                    </IonText>
                   
                  </IonItem>
                  {showError("password")}
                </IonCol>
                <IonCol sizeLg="6" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Role <span className="required">*</span>
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          {companyUserRole.map((option: any, i: number) => (
                            <option key={i} value={option.rid}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                      control={control}
                      rules={{
                        required: "Role is required",
                      }}
                      name="role_id"
                    />
                  </IonItem>
                  {showError("role_id")}
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12">
                  <IonButton
                    className=" remove-shadow capitalizes"
                    color="secondary "
                    shape="round"
                    fill="solid"
                    type="submit"
                  >
                    {headerType ? t("button.save") : t("button.update")}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        </form>
      )}
    </div>
  );
};
export default AddCompanyUser;
