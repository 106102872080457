import { useState, useMemo } from "react";
import TableHeaders from "./tableheader";
import Pagination from "react-js-pagination";
import Search from "./search";
import { IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react";
import { formatPhoneNumber, StringMap } from "../../service";
import {
  addOutline,
  cloudDownload,
  create,
  createOutline,
  documentAttachOutline,
  eye,
  flagOutline,
  search,
  trash,
} from "ionicons/icons";
import { TableDateStringMap } from "../../service/default";

interface tableInterface {
  title?: string;
  tableheaders: {
    name: string;
    field: string;
    sortable: boolean;
    value?: string;
    wordsplit?: string;
    validate?: string;
  }[];
  tableData: any;
  currentPage: number;
  itemPerPage: number;
  totalItem: number;
  searchInput?: boolean;
  searchFunction?: ((search: string) => void) | any;
  handleAction?: ((type: string, item) => void) | any;
  setCurrentPage: (page: number) => void;
}
const DataTableComponent = ({
  title,
  tableheaders,
  tableData,
  currentPage = 1,
  itemPerPage = 100,
  totalItem = 0,
  searchInput = false,
  searchFunction,
  handleAction,
  setCurrentPage,
}: tableInterface) => {
  // const [totalItems, setTotalItems] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const commentsData = useMemo(() => {
    let computedComments = tableData;
    // if (search) {
    //   computedComments = computedComments.filter(
    //     (comment) =>
    //       comment.name.toLowerCase().includes(search.toLowerCase()) ||
    //       comment.email.toLowerCase().includes(search.toLowerCase())
    //   );
    // }

    // setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedComments;
    //Current Page slice
    // return computedComments.slice(
    //   (currentPage - 1) * itemPerPage,
    //   (currentPage - 1) * itemPerPage + itemPerPage
    // );
  }, [tableData, currentPage, sorting]);

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol
            sizeLg="12"
            sizeMd="12"
            sizeSm="12"
            sizeXs="12"
            className="mb-3"
          >
            {searchInput && (
              <IonRow>
                <IonCol sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                  <Search
                    onSearch={(value: string) => {
                      searchFunction(value);
                    }}
                    activePage={currentPage}
                    itemsCountPerPage={itemPerPage}
                    totalItemsCount={totalItem}
                  />
                </IonCol>
              </IonRow>
            )}
            <div className="scrollit">
              <table className="table sm responsive table-bordered">
                <TableHeaders
                  headers={tableheaders}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {totalItem !== 0 &&
                    commentsData.map((comment, index) => (
                      <TableRow
                        item={comment}
                        column={tableheaders}
                        indexValue={index}
                        actionButton={(row, item) => handleAction(row, item)}
                      />
                    ))}
                </tbody>
              </table>
            </div>
            {totalItem === 0 && <p className="text-center"> No Record found</p>}
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={itemPerPage}
              totalItemsCount={totalItem}
              pageRangeDisplayed={10}
              onChange={(page) => setCurrentPage(page)}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default DataTableComponent;

const TableRow = ({ item, column, indexValue, actionButton }) => (
  <tr>
    {column.map((columnItem) => {
      if (columnItem.value.includes("id")) {
        return <td>{indexValue + 1}</td>;
      }
      if (columnItem.value.includes(".")) {
        const itemSplit = columnItem.value.split(".");
        return <td>{StringMap(item[itemSplit[0]][itemSplit[1]])}</td>;
      }
      if (columnItem.value.includes(":")) {
        const itemSplit = columnItem.value.split(":");
        return (
          <td>
            {itemSplit.map((row, index) => {
              return (
                row !== "" && (
                  <IonIcon
                    onClick={() => actionButton(row, item)}
                    className="icon-button"
                    key={index}
                    icon={
                      row === "edit"
                        ? createOutline
                        : row === "view"
                        ? eye
                        : row === "delete"
                        ? trash
                        : row === "download"
                        ? cloudDownload
                        : row === "upload"
                        ? documentAttachOutline
                        : row === "search"
                        ? search
                        : row === "create"
                        ? create : 
                        row === 'recuter' ?  flagOutline
                        : ""
                    }
                    color={
                      row === "delete"
                        ? "danger"
                        : row === "edit"
                        ? "warning"
                        : row === "view"
                        ? "primary"
                        : row === "download"
                        ? "primary"
                        : row === "upload"
                        ? "danger"
                        : row === "search"
                        ? "orange"
                        : "secondary"
                    }
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title={row}
                  />
                )
              );
            })}
          </td>
        );
      }
      if (columnItem.value.includes(",")) {
        const itemSplit = columnItem.value.split(",");
        return (
          <td>
            {itemSplit.map((row, index) => {
              const itemSplit = row.split("-");
              return (
                row !== "" &&
                (row === "mobile" ? (
                  <p className="m-0" key={index}>
                    {formatPhoneNumber(item[row])}
                  </p>
                ) : (
                  <p className="m-0" key={index}>
                    {itemSplit
                      ? item[itemSplit[0]] +
                        " " +
                        [itemSplit[1]] +
                        (index ? "" : columnItem.wordsplit)
                      : item[row] + (index ? "" : columnItem.wordsplit)}
                  </p>
                ))
              );
            })}
          </td>
        );
      }

      if (columnItem.value.includes("+")) {
        const itemSplit = columnItem.value.split("+");
        return (
          <td>
            {itemSplit.map((row, index) => {
              const itemSplit = row.split("+");
              return (
                <span className="m-0" key={index}>
                  {StringMap(item[itemSplit[0]]) +
                    " " +
                    StringMap([itemSplit[1]]) +
                    (index ? "" : columnItem.wordsplit)}
                </span>
              );
            })}
          </td>
        );
      }

      return (
        <td>
          {columnItem.validate === "date" ? (
            TableDateStringMap(item[`${columnItem.value}`])
          ) : columnItem.validate === "link" ? (
            item[`${columnItem.value}`] ? (
              <IonText
                className="text-pointer text-blue"
                onClick={() =>
                  window.open(item[`${columnItem.value}`], "_blank")
                }
              >
                {item[`${columnItem.value}`]}
              </IonText>
            ) : (
              "NA"
            )
          ) : (
            StringMap(item[`${columnItem.value}`])
          )}
        </td>
      );
    })}
  </tr>
);
