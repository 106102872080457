import {
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React from "react";
import { arrayKeymatch, SplitString } from "../../../../../service";
import Skills from "./Skills";
import AdditionalSkills from "./AdditionalSkills";
import Tools from "./Tools";
import Education from "./Education";
import Certifications from "./Certifications";
import Languages from "./Languages";
import { AuthContext } from "../../../../../context/authContext";
import Practice from "./Practice";

export default function Qualification({ personalData, folderKey }: any) {
  const { auth } = React.useContext(AuthContext);
  return (
    <>
      {personalData !== undefined && (
        <div id="qualifications">
          <h1 className="h1-pd">Qualifications</h1>
          <IonCard className="pad-style">
            <Practice
              ctridData={personalData.ctr_id}
              proficiencytypelist={""}
              practiceData={personalData !== undefined && personalData}
              ListofskillsOptionData={auth.allskill}
            />
          </IonCard>

          <IonCard className="pad-style">
            <Skills
              ctridData={personalData.ctr_id}
              proficiencytypelist={
                personalData !== undefined && personalData.proficiency_type_list
              }
              skillData={arrayKeymatch(personalData.skills)}
              ListofskillsOptionData={auth.allskill}
            />
          </IonCard>
          <IonCard className="pad-style">
            <AdditionalSkills
              ctridData={personalData.ctr_id}
              proficiencytypelist={
                personalData !== undefined && personalData.proficiency_type_list
              }
              skillData={arrayKeymatch(personalData.highlightedskills)}
              ListofskillsOptionData={auth.allskill}
            />
          </IonCard>
          <IonCard className="pad-style">
            <Tools
              ctridData={personalData.ctr_id}
              proficiencytypelist={
                personalData !== undefined && personalData.proficiency_type_list
              }
              toolsData={arrayKeymatch(personalData.tools_technologis)}
              listoftooloptionData={auth.alltool}
            />
          </IonCard>
          <IonCard className="pad-style">
            <Education
              ctridData={personalData.ctr_id}
              educationData={personalData.educations}
            />
          </IonCard>
          <IonCard className="pad-style">
            <Certifications
              ctridData={personalData.ctr_id}
              certificateData={personalData.certifications}
              folderkeyData={folderKey}
            />
          </IonCard>
          <IonCard className="pad-style">
            <Languages
              ctridData={personalData.ctr_id}
              proficiencytypelist={
                personalData !== undefined && personalData.proficiency_type_list
              }
              languageData={arrayKeymatch(personalData.language)}
              ListofLanguageOption={auth.alllang}
            />
          </IonCard>
        </div>
      )}
    </>
  );
}

export const CommonSection = ({
  arrs,
  type,
  displayType,
  removeData,
  editValues,
}: any) => {
  const [sliceCom, setSlice] = React.useState(9);

  return (
    <IonGrid className="ion-no-padding">
      {arrs &&
        arrs.map((e: any, i: number) =>
          e.arr.length ? (
            <IonRow key={i}>
              {/* heading */}
              <IonCol sizeLg="4" sizeXs="12">
                <label className="capitalize">
                  {SplitString(e.title, "_")}&nbsp;({e.arr.length})
                </label>
              </IonCol>

              {/* details */}
              <IonCol sizeLg="8" sizeXs="12">
                {e.arr.slice(0, sliceCom).map((r: any, j: number) =>
                  displayType !== "edit" ? (
                    <span key={j}>
                      <span>
                        {r.skill_name
                          ? r.skill_name
                          : r.tool_name
                          ? r.tool_name
                          : r.language_name}
                      </span>
                      &nbsp;{e.arr.length - 1 !== j && "•"}&nbsp;
                    </span>
                  ) : (
                    <IonChip
                      style={{
                        "background-color": "rgba(24, 184, 252, 0.08)",
                      }}
                    >
                      <IonLabel onClick={() => editValues(r)}>
                        {r.skill_name
                          ? r.skill_name
                          : r.tool_name
                          ? r.tool_name
                          : r.language_name}
                      </IonLabel>
                      <IonIcon
                        color="danger"
                        icon={close}
                        onClick={() => removeData(r)}
                      />
                    </IonChip>
                  )
                )}
                {e.arr.length > 9 && (
                  <IonItem lines="none">
                    {e.arr.length > 0 &&
                    e.arr.length !== sliceCom &&
                    e.arr.length >= sliceCom ? (
                      <IonButton
                        fill="clear"
                        color="secondary"
                        type="button"
                        size="small"
                        className="remove-shadow lowercase ion-no-padding"
                        onClick={() => setSlice( e.arr.length)}
                      >
                        {/* {e.arr.length - sliceCom} more {type} */}more {type}
                      </IonButton>
                    ) : (
                      e.arr.length > 9 &&
                      e.arr.length <= sliceCom && (
                        <IonButton
                          fill="clear"
                          color="secondary"
                          type="button"
                          size="small"
                          className="remove-shadow lowercase"
                          onClick={() => setSlice(9)}
                        >
                          less {type}
                        </IonButton>
                      )
                    )}
                  </IonItem>
                )}
                {i !== arrs.length - 1 && <hr />}
              </IonCol>
            </IonRow>
          ) : null
        )}
    </IonGrid>
  );
};

export const CommonSectionSkill = ({
  arrs,
  type,
  screenType,
  editValues,
  removeData,
  catagory,
}: any) => {
  const [sliceCom, setSlice] = React.useState(9);
  return (
    <div>
      {screenType === "form"
        ? arrs.map((e: any, i: number) =>
            e.arr.length ? (
              <div key={i}>
                {/* heading */}

                <label className="capitalize">
                  {SplitString(e.title, "_")}&nbsp;({e.arr.length})
                </label>

                {/* details */}
                <p>
                  {e.arr.slice(0, sliceCom).map((r: any, j: number) => (
                    <span key={j}>
                      <span>
                        {r.skill_name
                          ? r.skill_name
                          : r.tool_name
                          ? r.tool_name
                          : r.language_name}
                      </span>
                      {e.arr.length - 1 !== j && " • "}
                    </span>
                  ))}
                  {e.arr.length > 9 && (
                    <IonItem lines="none">
                      {e.arr.length > 0 &&
                      e.arr.length !== sliceCom &&
                      e.arr.length >= sliceCom ? (
                        <IonButton
                          fill="clear"
                          color="secondary"
                          type="button"
                          size="small"
                          className="remove-shadow lowercase ion-no-padding"
                          onClick={() => setSlice(e.arr.length)}
                        >
                          {/* {e.arr.length - sliceCom} more {type} */}more {type}
                        </IonButton>
                      ) : (
                        e.arr.length > 9 &&
                        e.arr.length <= sliceCom && (
                          <IonButton
                            fill="clear"
                            color="secondary"
                            type="button"
                            size="small"
                            className="remove-shadow lowercase"
                            onClick={() => setSlice(9)}
                          >
                            less {type}
                          </IonButton>
                        )
                      )}
                    </IonItem>
                  )}
                  {/* {i !== arrs.length - 1 && <hr />} */}
                </p>
              </div>
            ) : null
          )
        : arrs.map((e: any, i: number) =>
            e.arr.length ? (
              <div key={i}>
                {/* heading */}

                <label className="capitalize">
                  {SplitString(e.title, "_")}&nbsp;({e.arr.length})
                </label>

                {/* details */}
                <p>
                  {e.arr.slice(0, sliceCom).map((r: any, j: number) => (
                    <IonChip
                      key={j}
                      style={{
                        "background-color": "rgba(24, 184, 252, 0.08)",
                      }}
                    >
                      <IonLabel onClick={() => editValues(r)}>
                        {r.skill_name
                          ? r.skill_name
                          : r.tool_name
                          ? r.tool_name
                          : r.language_name}
                      </IonLabel>
                      <IonIcon
                        color="danger"
                        icon={close}
                        onClick={() => removeData(r, catagory, e.title)}
                      />
                    </IonChip>
                  ))}
                  {e.arr.length > 9 && (
                    <IonItem lines="none">
                      {e.arr.length > 0 &&
                      e.arr.length !== sliceCom &&
                      e.arr.length >= sliceCom ? (
                        <IonButton
                          fill="clear"
                          color="secondary"
                          type="button"
                          size="small"
                          className="remove-shadow lowercase"
                          onClick={() => setSlice(sliceCom + 9)}
                        >
                          {e.arr.length - sliceCom} more {type}
                        </IonButton>
                      ) : (
                        e.arr.length > 9 &&
                        e.arr.length <= sliceCom && (
                          <IonButton
                            fill="clear"
                            color="secondary"
                            type="button"
                            size="small"
                            className="remove-shadow lowercase"
                            onClick={() => setSlice(9)}
                          >
                            less {type}
                          </IonButton>
                        )
                      )}
                    </IonItem>
                  )}
                  {/* {i !== arrs.length - 1 && <hr />} */}
                </p>
              </div>
            ) : null
          )}
    </div>
  );
};
