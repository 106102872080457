import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  IonLoading,
  IonToast,
} from "@ionic/react";
import { createOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import {
  HeaderNav,
  Search,
  TableHeader,
  CompanyEmailVerify,
} from "../../component";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { getMethod, postMethod } from "../../service";

const Staff_tools: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [totalItems, setTotalItems] = useState<any>(0);
  const [pageCount, setPageCount] = useState<any>();
  const [tableData, settableData] = useState<any>([]);
  const [offset, setoffset] = useState(0);
  const [perPage, setperPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(-1);

  const [tollsData, setTools] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const ITEMS_PER_PAGE = 15;

  const [page, setPage] = React.useState(0);

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });

  const [currenttool, setcurrenttool] = useState<any>(null);

  const headers = [
    { name: "No#", field: "id", sortable: false },
    { name: "ToolsName", field: "tool_name", sortable: true },

    { name: "Edit Tools", field: "action", sortable: false },
  ];

  React.useEffect(() => {
    let isMounted = true;
    try {
      GetTools();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const GetTools = () => {
    setShowLoading(true);
    setTimeout(() => {
      const FetchData = getMethod("/Jngstaff/getallTechnology");
      FetchData.then((value: any) => {
        setShowLoading(false);
        if (value.data !== undefined && value.status === 200) {
          var slice = value.data.slice(offset, perPage);
          setShowLoading(false);
          setTools(slice);
          settableData(value.data);
          setPageCount(value.data.length);
        } else {
        }
      });
    }, 100);
  };

  const handlePageChange = (e) => {
    const selectedPage = e;
    const num = (selectedPage - 1) * perPage + 10;
    setCurrentPage(selectedPage);
    const data = tableData;
    const slice = data.slice(perPage * (selectedPage - 1), num);
    setTools(slice);
  };

  function onSearch(value) {
    let computedskillsData = tableData;

    if (value) {
      const computedskillsDatas = computedskillsData.filter((comment) =>
        comment.tool_name.toLowerCase().includes(search.toLowerCase())
      );
      setPageCount(computedskillsDatas.length);
      setTools(computedskillsDatas);
    } else {
      setoffset(0);
      setPageCount(1);
      var slice = computedskillsData.slice(offset, perPage);
      setPageCount(computedskillsData.length);
      setTools(slice);
    }
  }

  const sortingData = () => {
    let computedskillsData = tableData;

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedskillsData = computedskillsData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
      var slice = computedskillsData.slice(offset, perPage);
      setPageCount(computedskillsData.length);
      setTools(slice);
    }
  };

  const edit = ({ id, currentcurrenttool }) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setcurrenttool(currentcurrenttool);
  };

  const onSave = ({ id, newcurrenttool }) => {
    let tool_Data = {
      tt_id: id,
      tool_name: newcurrenttool,
    };

    setShowLoading(true);
    const postData = postMethod("/Jngstaff/updateAllTools", tool_Data);
    postData.then((value: any) => {
      if (value.status === 200) {
        setShowLoading(false);
        setShowModal(false);
        setToastColor("success");
        setToastMessage(value.data);
        setShowToast(true);
        GetTools();
      } else {
        setShowLoading(true);
        setShowModal(false);
        setToastColor("danger");
        setToastMessage(value.message);
        setShowToast(true);
      }
    });

    onCancel();
  };

  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });

    setcurrenttool(null);
  };
  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <HeaderNav />
      <IonContent>
        <CompanyEmailVerify />
        <IonGrid className="center">
          <IonRow>
            <IonCol offsetLg="1" sizeLg="10" sizeXs="12">
              <IonGrid>
                <IonRow></IonRow>
                <IonRow>
                  <IonCol sizeLg="6" size="12"></IonCol>

                  <IonCol
                    sizeLg="6"
                    size="12"
                    className="d-flex flex-row-reverse"
                  >
                    <Search
                      value={search}
                      onSearch={(value) => {
                        onSearch(value);
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </IonCol>

                  <IonCol size="12">
                    <div className="con-table-scrolbar">
                      <table className="table table-bordered table-hover">
                        <TableHeader
                          headers={headers}
                          onSorting={(field, order) => {
                            sortingData();
                            setSorting({ field, order });
                          }}
                        />
                        <tbody>
                          {tollsData.map((toolsele: any, index: number) => (
                            <tr key={toolsele.tt_id}>
                              <th scope="row" key={index}>
                                {index + 1}
                              </th>

                              <td>
                                {inEditMode.status &&
                                inEditMode.rowKey === toolsele.tt_id ? (
                                  <input
                                    value={currenttool}
                                    onChange={(event) =>
                                      setcurrenttool(event.target.value)
                                    }
                                  />
                                ) : (
                                  toolsele.tool_name
                                )}
                              </td>
                              <td>
                                {inEditMode.status &&
                                inEditMode.rowKey === toolsele.tt_id ? (
                                  <React.Fragment>
                                    <IonButton
                                      className="custom-button"
                                      color="secondary capitalize"
                                      shape="round"
                                      fill="solid"
                                      onClick={() =>
                                        onSave({
                                          id: toolsele.tt_id,
                                          newcurrenttool: currenttool,
                                        })
                                      }
                                    >
                                      Save
                                    </IonButton>

                                    <IonButton
                                      className="custom-button"
                                      color="danger"
                                      shape="round"
                                      fill="solid"
                                      onClick={() => onCancel()}
                                    >
                                      Cancel
                                    </IonButton>
                                  </React.Fragment>
                                ) : (
                                  <IonButton
                                    onClick={() =>
                                      edit({
                                        id: toolsele.tt_id,
                                        currentcurrenttool: toolsele.tool_name,
                                      })
                                    }
                                    shape="round"
                                    fill="clear"
                                  >
                                    <IonIcon
                                      icon={createOutline}
                                      color="warning"
                                    />
                                  </IonButton>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </IonCol>

                  <IonCol size="12">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={pageCount}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Staff_tools);
