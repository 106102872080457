import {
  IonSlides,
  IonSlide,
  IonIcon,
  IonButton,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonChip,
  IonLabel,
  IonCard,
  IonToast,
  IonLoading,
} from "@ionic/react";
import {
  addOutline,
  checkmarkOutline,
  chevronDownOutline,
  chevronForwardOutline,
  chevronUpOutline,
  hourglassOutline,
  ticketOutline,
  warningOutline,
} from "ionicons/icons";
import React from "react";
import { getDeikaaProfileData } from "../Service/api";
import { RouteComponentProps, useHistory } from "react-router";
import { AuthContext } from "../../../context/authContext";
import { FindDeikaaKey, getIndexValue } from "../../../service";
import useMediaQuery from "../../../hooks/mediaQuery";

const slideOpts = {
  initialSlide: 1,
  speed: 400,
  autoHeight: true,
  allowSlideNext: true,
  allowSlidePrev: true,
};

interface props {
  uploadImage?: Function;
  optinData?: any;
  screen?: string;
  ctrid: any;
  tokenData?: any;
  JoinDeika?: Function;
}
interface Props extends RouteComponentProps<{}> {}

const DeikaaSlider: React.FC<props> = ({
  uploadImage,
  optinData,
  screen,
  ctrid,
  tokenData,
  JoinDeika,
}: any) => {
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const mySlides = React.useRef<any>(null);
  const history = useHistory();
  const [reloadData, setReloadData] = React.useState(false);
  const [deikaasection, setDeikaaSection] = React.useState(false);
  const { auth, DeikaaFun } = React.useContext(AuthContext);
  const [showLoading, setshowLoading] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const next = async () => {
    await mySlides.current.lockSwipes(false);
    await mySlides.current.slideNext();
    await mySlides.current.lockSwipes(true);
  };

  const prev = async () => {
    await mySlides.current.lockSwipes(false);
    await mySlides.current.slidePrev();
    await mySlides.current.lockSwipes(true);
  };

  React.useEffect(() => {
    mySlides.current.lockSwipes(true);
  });

  function joindeikaa() {
    getDeikaaProfileData(ctrid, "1").then((e: any) => {
      if (e !== undefined && e.status === 200) {
        setshowLoading(false);
        JoinDeika("1");
        setToastColor("success");
        setToastMessage(e.message);
        setShowToast(true);
      } else {
        setshowLoading(false);
        setToastColor("danger");
        setToastMessage(e !== undefined ? e.message : "Please try again.");
        setShowToast(true);
      }
    });
  }

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
        message={"Please wait..."}
      />
      <IonCard className="deikaa">
        {screen !== "reward" ? (
          <div id="deikaa-header-pannel">
            <div className="token">
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <div className="token-content">
                    <h4 className="m-0">
                      <IonIcon
                        className="chip-font orange"
                        icon={ticketOutline}
                      />{" "}
                      {tokenData}
                    </h4>
                    <button
                      className="reward-btn"
                      onClick={() => history.push("/reward/" + ctrid)}
                    >
                      My Rewards <IonIcon icon={chevronForwardOutline} />
                    </button>
                  </div>

                  <div className="text-white m-auto reward-content">
                    <h3>
                      {optinData !== "1"
                        ? "You can win 4 prizes on our reward program!"
                        : "Track your rewards with the FREE Deikaa app!"}
                    </h3>
                    {optinData !== "1" ? (
                      <p className="mb-0 sub-deikaa-subject">
                        <small>
                          Subject to Deikaa's{" "}
                          <a
                            target="_blank"
                            href="/terms"
                          >
                            terms
                          </a>{" "}
                          and{" "}
                          <a
                            target="_blank"
                            href="/privacy-policy"
                          >
                            privacy policy
                          </a>
                        </small>
                      </p>
                    ) : null}
                  </div>

                  {optinData !== "1" ? (
                    <>
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="light"
                        className="remove-shadow capitalizes mt-3"
                        type="button"
                        onClick={() => joindeikaa()}
                      >
                        <img
                          src="assets/image/deikaa/Deikaa_Logomark.svg"
                          className="mr-2"
                        />
                        Join Deikaa
                      </IonButton>
                    </>
                  ) : (
                    <>
                      <IonLabel className="ion-text-wrap m-auto">
                        <img
                          onClick={() =>
                            window.open(
                              "https://play.google.com/store/apps/details?id=com.ionidea.deikaa"
                            )
                          }
                          src="assets/image/deikaa/google-play-badge.svg"
                          className="px-1"
                        />
                        <img
                          onClick={() =>
                            window.open(
                              "https://apps.apple.com/us/app/deikaa/id1529609777"
                            )
                          }
                          className="px-1"
                          src="assets/image/deikaa/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                        />
                      </IonLabel>
                    </>
                  )}
                  <IonIcon
                    className="collapse-option my-auto"
                    icon={deikaasection ? chevronUpOutline : chevronDownOutline}
                    color="light"
                    onClick={() => setDeikaaSection(!deikaasection)}
                  />
                </IonRow>

                {deikaasection && (
                  <IonRow className="p-4">
                    <IonCol sizeLg="4" sizeXs="12">
                      <div className="ion-text-end">
                        <img
                          style={{ maxWidth: "208px" }}
                          src={
                            optinData !== "1"
                              ? "assets/image/deikaa/Group145.svg"
                              : "assets/image/deikaa/Group146.svg"
                          }
                          className="px-1"
                        />
                      </div>
                    </IonCol>
                    <IonCol sizeLg="8" sizeXs="12">
                      {optinData !== "1" ? (
                        <p className="text-white">
                          Earn Deikaa tokens for using Hirify and just
                          doing your job, each token giving you a chance to win
                          an exciting prize: laptops, smartphones — even a Mini
                          Cooper!
                        </p>
                      ) : (
                        <p className="text-white">
                          Install the free Deikaa app and sign in with your
                          registered Hirify phone number to stay updated
                          with your tokens, and more importantly, your rewards!
                        </p>
                      )}
                      <IonButton
                        shape="round"
                        fill="outline"
                        color="light"
                        className="remove-shadow capitalizes"
                        type="button"
                        target="_blank"
                        href={"https://apps.hirify.ai/login"}
                      >
                        {optinData !== "1" ? "Learn More" : "See How It Works"}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            </div>
          </div>
        ) : (
          <div id="deikaa-header-pannel">
            <div className="token">
              <IonGrid className="ion-no-padding">
                <IonRow className="ion-justify-content-start py-4">
                  <IonCol sizeXs="12" sizeLg="4">
                    <div className="text-center">
                      <img
                        style={{ maxWidth: "316px" }}
                        src={"assets/image/deikaa/Group1466.svg"}
                      />
                    </div>
                  </IonCol>
                  <IonCol sizeXs="12" sizeLg="8">
                    <div className="text-white ">
                      <h3 className="pb-2">
                        Track your rewards with the FREE Deikaa app!
                      </h3>
                      <p className="text-white">
                        Install the free Deikaa app and sign in with your
                        registered Hirify phone number to stay updated with
                        your tokens, and more importantly, your rewards!
                      </p>

                      <IonLabel className="ion-text-wrap">
                        <img
                          onClick={() =>
                            window.open(
                              "https://play.google.com/store/apps/details?id=com.ionidea.deikaa"
                            )
                          }
                          src="assets/image/deikaa/google-play-badge.svg"
                          className="px-1"
                        />
                        <img
                          onClick={() =>
                            window.open(
                              "https://apps.apple.com/us/app/deikaa/id1529609777"
                            )
                          }
                          className="px-1"
                          src="assets/image/deikaa/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                        />
                        <IonButton
                          shape="round"
                          fill="clear"
                          color="light"
                          className="remove-shadow capitalizes"
                          type="button"
                          target="_blank"
                          href={"https://apps.hirify.ai/login"}
                        >
                          See How It Works
                        </IonButton>
                      </IonLabel>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </div>
        )}
        {/* <div className="deikaa-slider-section">
        <button className="prevBtn deikaa-slide-btn" onClick={() => prev()}>
          <IonIcon icon={chevronBackOutline} />
        </button>
        <button className="nextBtn deikaa-slide-btn" onClick={() => next()}>
          <IonIcon icon={chevronForwardOutline} />
        </button>
      </div> */}
        <div className={m4kQuery ? "container": ""}>
          <div className="content-header">
            <h3>Complete your profile to build more credibility</h3>
            {screen === "reward" && (
              <IonButton
                shape="round"
                fill="outline"
                color="secondary"
                className="remove-shadow capitalizes"
                type="button"
                onClick={() => history.push("/ContractorProfile")}
              >
                Complete Profile
              </IonButton>
            )}
          </div>

          <IonSlides
            className="deikaaSlides"
            mode={"ios"}
            scrollbar={false}
            pager={false}
            options={slideOpts}
            ref={mySlides}
          >
            <IonSlide>
              {!reloadData ? (
                <IonGrid>
                  <IonRow>
                    <IonCol sizeLg="12" sizeXs="12">
                      <IonRow>
                        <IonCol sizeLg="4" sizeMd="6" sizeSm="6" sizeXs="12">
                          <IonItem lines="none">
                            {screen !== "reward" &&
                              getIndexValue(
                                "PHOTOUPLOAD",
                                auth.dekiaa,
                                "action_title",
                                "status"
                              ) !== "1" && (
                                <>
                                  <label
                                    htmlFor="upload-button"
                                    className="btn custom-outline-round-btn"
                                  >
                                    <IonIcon
                                      slot="icon-only"
                                      icon={addOutline}
                                      color=""
                                    />
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    id="upload-button"
                                    hidden
                                    onChange={uploadImage}
                                  />
                                </>
                              )}
                            <IonLabel className="ion-text-wrap left-space-15 text-wrap">
                              <p>
                                <span>Add profile picture</span>
                              </p>
                              <DeikaaChip
                                flag="PHOTOUPLOAD"
                                data={FindDeikaaKey(
                                  auth.dekiaa,
                                  "action_title",
                                  "PHOTOUPLOAD"
                                )}
                              />
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="4" sizeMd="6" sizeSm="6" sizeXs="12">
                          <IonItem lines="none">
                            {screen !== "reward" &&
                              getIndexValue(
                                "VIDEOUPLOAD",
                                auth.dekiaa,
                                "action_title",
                                "status"
                              ) !== "1" && (
                                <label
                                  className="btn custom-outline-round-btn"
                                  onClick={() => {
                                    DeikaaFun("VIDEOUPLOAD");
                                    history.push("/ContractorProfile");
                                  }}
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={addOutline}
                                    color=""
                                  />
                                </label>
                              )}
                            <IonLabel className="ion-text-wrap left-space-15">
                              <p>
                                <span>Add profile video</span>
                              </p>
                              <DeikaaChip
                                flag="VIDEOUPLOAD"
                                data={FindDeikaaKey(
                                  auth.dekiaa,
                                  "action_title",
                                  "VIDEOUPLOAD"
                                )}
                              />
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="4" sizeMd="6" sizeSm="6" sizeXs="12">
                          <IonItem lines="none">
                            {screen !== "reward" &&
                              getIndexValue(
                                "REFERENCECOMPLETED",
                                auth.dekiaa,
                                "action_title",
                                "status"
                              ) !== "1" && (
                                <label
                                  className="btn custom-outline-round-btn"
                                  onClick={() => {
                                    DeikaaFun("REFERENCECOMPLETED");
                                    history.push("/ContractorProfile");
                                  }}
                                >
                                  <IonIcon
                                    slot="icon-only"
                                    icon={addOutline}
                                    color=""
                                  />
                                </label>
                              )}
                            <IonLabel className="ion-text-wrap left-space-15">
                              <p>
                                <span>Add Reference</span>
                              </p>
                              <DeikaaChip
                                flag="reference"
                                data={FindDeikaaKey(
                                  auth.dekiaa,
                                  "action_title",
                                  "REFERENCECOMPLETED"
                                )}
                              />
                            </IonLabel>
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                    {/* <IonCol sizeLg="3" sizeXs="12">
                  <IonItem lines="none" className="ion-no-padding">
                    <IonLabel>Mandatory details</IonLabel>
                    <IonNote slot="end">2/8 left</IonNote>
                  </IonItem>
                  <IonProgressBar color="warning" value={0.5}></IonProgressBar>
                  <br />
                  <IonItem lines="none" className="ion-no-padding">
                    <IonLabel>Other details</IonLabel>
                    <IonNote slot="end">7/10 left</IonNote>
                  </IonItem>
                  <IonProgressBar
                    color="secondary"
                    value={0.5}
                  ></IonProgressBar>
                </IonCol> */}
                  </IonRow>
                </IonGrid>
              ) : null}
            </IonSlide>

            {/* <IonSlide>
            <h1>Slide 2</h1>
          </IonSlide>
          <IonSlide>
            <h1>Slide 3</h1>
          </IonSlide> */}
          </IonSlides>
        </div>
      </IonCard>
    </>
  );
};
export default React.memo(DeikaaSlider);

export function DeikaaChip({ data, flag }: any) {
  let component;
  switch (data !== undefined && data.status) {
    case "0":
      component = (
        <IonChip className="custom-ticket-chip deikaa-chip-yellow">
          <IonIcon className="chip-font yellow" icon={hourglassOutline} />
          <IonLabel className="chip-font yellow">
            Tokens pending approval
          </IonLabel>
        </IonChip>
      );
      break;
    case "1":
      component = (
        <IonChip className="custom-ticket-chip deikaa-chip-green">
          <IonIcon className="chip-font green" icon={checkmarkOutline} />
          <IonLabel className="chip-font green">
            {data.no_of_tokens} tokens credited
          </IonLabel>
        </IonChip>
      );
      break;
    case "2":
      component = (
        <IonChip className="custom-ticket-chip deikaa-chip-danger">
          <IonIcon className="chip-font danger" icon={warningOutline} />
          <IonLabel className="chip-font danger">
            {data.no_of_tokens} tokens rejected! Please try again
          </IonLabel>
        </IonChip>
      );
      break;
    default:
      component = (
        <IonChip className="custom-ticket-chip deikaa-chip-orange">
          <IonIcon className="chip-font orange" icon={ticketOutline} />
          <IonLabel className="chip-font orange">
            Get {flag === "PHOTOUPLOAD" ? "2" : "5"} tokens
          </IonLabel>
        </IonChip>
      );
      break;
  }
  return component;
}
