import axios from "axios";
import { Encryption } from "./encryption";
import { getCookie } from "./Cookies";

export function encrypted(data: any) {
  let encryption = new Encryption();
  if (data) {
    return encryption.encrypt(data, "Succ@#18#9JOBSNGIGS%:<20");
  }
}

export const decrypted = (data: any) => {
  let encryption = new Encryption();
  if (data) {
    return encryption.decrypt(data, "Succ@#18#9JOBSNGIGS%:<20");
  }
};

export const ProdUrl = "https://apps1.hirify.ai"; // production server url
export const UATUrl = "https://www.bgr.ionidea.com/hirify/hrf.bac/Coding"; // development server url
export const DevUrl = "http://localhost/hrf.bac/Coding"; // local
// export const SalesUrl = ""; // local
var BASEURL = ProdUrl;
if (document.URL.startsWith("http://localhost")) BASEURL = UATUrl;

const source = axios.CancelToken.source();
// const instance = axios.create({
//   baseURL: BASEURL + "/index.php/",
// });
// instance.defaults.timeout = 180000;
// request
axios.defaults.baseURL = BASEURL + "/index.php/";
axios.interceptors.request.use((request) => {
  request.headers.token = getCookie({ key: "token" }).value;
  request.headers.uid = getCookie({ key: "uid" }).value;
  return request;
});

// response
axios.interceptors.response.use((response) => {
  // debugger;
  if (response.data instanceof Blob) {
    return response;
  }
  if (response.data.status === 301) {
    //  debugger;
    window.location.href = "/login";
  }
  if (
    response.data.status !== 200 &&
    response.data.status !== 201 &&
    response.data.status !== 301
  ) {
  }
  // debugger;
  return response;
});

// healper function to image located from server path
export const SeverfilePath = BASEURL + "/assets/uploads/user/";

export const SeverfilePaths = (folder_key: any) => {
  if (folder_key) {
    return BASEURL + "/assets/uploads/user/" + decrypted(folder_key) + "/";
  }
};

export const SeverfilePathswithoutEncrypt = (folder_key: any) => {
  if (folder_key) {
    return BASEURL + "/assets/uploads/user/" + folder_key + "/";
  }
};

// Post Response method
export const postMethod = async (endpoint: string, params: any) => {
  return await axios
    .post(endpoint, params, {
      cancelToken: source.token,
    })
    .then((res) => {
      if (res.status === 200) {
        console.log('load', res);
        return res.data;
      } else {
        return res.data.message;
      }
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    });
};

// Get Response Method
export const getMethod = async (endpoint: any) => {
  return await axios
    .get(endpoint, {
      cancelToken: source.token,
    })
    .then((res) => {
      if (
        res.data.status === 200 ||
        res.data.status === 201 ||
        res.data.status === 1
      ) {
        return res.data;
      } else if (res.status === 401) {
        return res.data.message;
      } else if (res.data.status === 301) {
        return res.data;
      } else {
        return res.data.message;
      }
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    });
};

//  resume parsing api
export const resumeUpload = async (files: any, folder_key: string) => {
  const cookies = getCookie({ key: "user" }).value;
  const data = new FormData();
  data.append("ctr_id", cookies.ctr_id);
  data.append("contractor_resume", files);
  data.append("folder_key", folder_key);
  return await axios
    .post("Parseresume", data, {
      cancelToken: source.token,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res.data.message;
      }
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    });
};

export const companyContractUpload = async (files: any, com_id: any) => {
  const data = new FormData();
  data.append("com_id", com_id);
  data.append("contract", files);
  return await axios
    .post("Contractupload/upload_contract", data, {
      cancelToken: source.token,
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        return res.data.message;
      }
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    });
};

// download file
export const downloadFileWithGet = async (endpoint: any) => {
  return await axios
    .get(endpoint, { responseType: "blob", cancelToken: source.token })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
      } else {
        throw err;
      }
    });
};
