export const ckeditorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "alignment",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "underline",
      "subscript",
      "superscript",
      "|",
      "link",
      "|",
      "outdent",
      "indent",
      "|",
      "bulletedList",
      "numberedList",
      "todoList",
      "|",
      "undo",
      "redo",
      "|",
    ],
  };