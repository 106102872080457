import { getMethod, postMethod, Requests } from "../../../service";

export function updateJob(dta: any) {
  return postMethod(Requests.fetchUpdatecompanyjob, dta);
}

export function getCompanyProfile(dta: any) {
  return getMethod(Requests.fetchGetcompanyprofile + dta);
}

export function SaveJob(dta: any) {
  return postMethod(Requests.fetchSavecompanyjob, dta);
}

export function getJobs(dta: any) {
  return getMethod(Requests.fetchGetlistofjobs + dta);
}

export function getJobDetail(dta: any) {
  return getMethod(Requests.fetchGetjobdataofcompany + dta);
}

export function getJobCanditate(dta: any) {
  return getMethod(Requests.fetchGetapplicantsdata + dta);
}

export function getJobMatchingContractor(dta: any) {
  return postMethod(Requests.fetchGetmatchecandidate , dta);
}

export function getCRMJobMatchingContractor(dta: any) {
  return postMethod(Requests.fetchGetcrmmatchecandidate, dta);
}

export function getinvitecandidate(dta: any) {
  return getMethod(Requests.fetchGetinvitecandidate + dta);
}
export function getshortlistedcandidate(dta: any) {
  return getMethod(Requests.fetchGetshortlistedcandidate + dta);
}
export function Candidateshortlisted(dta: any) {
  return postMethod(Requests.fetchaddshortlistedcandidate, dta);
}

export function JobFilter(dta: any) {
  return postMethod(Requests.fetchgetfilteredjob, dta);
}

export function JobInviteTalent(dta: any) {
  return postMethod(Requests.fetchinviteforjob, dta);
}

export function CandidateUpdatestatus(dta: any) {
  return postMethod(Requests.fetchUpdateapplicantstatus, dta);
}

export function deleteCandidates(dta: any) {
  return postMethod(Requests.fetchdeletecandidate, dta);
}

export function AssignShortlistedCandidate(dta: any) {
  return postMethod(Requests.fetchAssignShortlistedCandidate, dta);
}

export function GetmailData(dta: any) {
  return postMethod(Requests.fetchAssignMailForShortlisit, dta);
}

export function sentmailData(dta: any) {
  return postMethod(Requests.fetchSendMailForShortlist, dta);
}



export function InvitetoapplyJob(dta: any) {
  return postMethod(Requests.fetchInvitetoapplyjob, dta);
}

export function companyuserupdate(dta: any) {
  return postMethod(Requests.fetchSavecompanyprofile, dta);
}
export function companyuserimageSave(dta: any) {
  return postMethod(Requests.fetchSavecompanyimage, dta);
}

// sent mail
export function SendMailtoallContractor(dta: any) {
  return postMethod(Requests.fetchSendMailtoallContractor, dta);
}


export function GetContractorProfileDetails(dta: any) {
  return postMethod(Requests.fetchGetCtrDataFromPublicUrl, dta);
}
