import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { addOutline, pencilSharp } from "ionicons/icons";
import React, { useEffect } from "react";
import { ConcatArray, StringMap } from "../../../../../service";
import { ModalBody } from "../../../../../component";
import { EducationForm } from "../../Form";
type props = {
  educationData: any;
  ctridData: any;
};
interface fields {
  ctr_id: string;
  inst_name: string;
  degree: string;
  field_study: string;
  current_education: boolean;
  geo_location: string;
  latitude: any;
  longitude: any;
  start_year: any;
  end_year: any;
  grade: any;
  result_type: any;
  ctr_qid: string;
}

const initialValues: fields = {
  ctr_id: "",
  inst_name: "",
  ctr_qid: "",
  degree: "",
  field_study: "",
  current_education: false,
  geo_location: "",
  latitude: "",
  longitude: "",
  start_year: "",
  end_year: "",
  grade: "",
  result_type: "",
};

const Education: React.FC<props> = ({ educationData, ctridData }) => {
  const [modelOpen, setModal] = React.useState(false);
  const [eventtype, setEventtype] = React.useState<any>("Add");
  const [editValue, setEditValue] = React.useState<fields>(initialValues);
  const [educationuser, seteducationuser] = React.useState<fields[]>([]);
  const [saveEducationData, setsaveEducationData] = React.useState<any>([]);
  const [dataupdated, setDataupdated] = React.useState(false);

  function collectionData(value: any) {
    seteducationuser(ConcatArray(value, educationuser));
    setsaveEducationData(value);
  }

  function editOption(e: any) {
    const data = e;
    setEditValue({
      ...data,
      start_date:
        data.start_date === "0000-00-00"
          ? ""
          : data.start_date === "0000"
          ? ""
          : data.start_date,
      end_date:
        data.end_date === "0000-00-00"
          ? ""
          : data.end_date === "000"
          ? ""
          : data.end_date,
      geo_location: data.geo_location ? data.geo_location : "",
    });
    setEventtype("Update");
    setModal(true);
  }

  useEffect(() => {
    setsaveEducationData(educationData);
  }, [educationData]);

  function deleteeducationdata(data: any) {
    setDataupdated(false);
    if (data) {
      setsaveEducationData(
        saveEducationData.filter(({ ctr_qid }) => ctr_qid !== data)
      );
      setDataupdated(true);
    }
  }

  return (
    <>
      <div id="education">
        <ModalBody
          title={eventtype + " " + "education"}
          isOpen={modelOpen}
          onClose={() => setModal(false)}
        >
          <EducationForm
            ctrid={ctridData}
            initialValueData={editValue}
            closeModal={() => setModal(false)}
            seteducationData={collectionData}
            eventType={eventtype}
            deleteeducationData={deleteeducationdata}
            whenCall={modelOpen}
          />
        </ModalBody>

        <IonItem lines="none" className="custom-header-space ">
          <h4>Education</h4>
          <IonButton
            slot="end"
            fill="outline"
            className="remove-shadow custom-circle-btn"
            color="secondary"
            shape="round"
            onClick={() => {
              setModal(true);
              setEventtype("Add");
              setEditValue({ ...initialValues, geo_location: "" });
            }}
          >
            <IonIcon icon={addOutline} slot="icon-only" />
          </IonButton>
        </IonItem>

        <EducationSection
          arrs={saveEducationData !== undefined && saveEducationData}
          editfun={editOption}
        />
      </div>
    </>
  );
};

const EducationSection = ({ arrs, editfun }: any) => {
  const [educat, setEdu] = React.useState(3);
  return (
    arrs &&
    arrs.length > 0 && (
      <>
        <IonGrid className="ion-no-padding">
          {arrs
            .sort((a, b) => b.start_year - a.start_year)
            .slice(0, educat)
            .map((e: any, i: number) => (
              <IonRow key={i}>
                <IonCol sizeLg="4" sizeXs="12">
                  <IonItem lines="none" className="custom-subheader-space ">
                    <IonLabel className="ion-text-wrap">
                      <label>
                        {StringMap(e.start_year)}
                        {" - " + StringMap(e.end_year)}
                      </label>
                      {e.result && (
                        <p>
                          Result:{" "}
                          <span>
                            {e.result}&nbsp;{e.result_type}
                          </span>
                        </p>
                      )}
                    </IonLabel>
                  </IonItem>
                </IonCol>
                <IonCol sizeLg="8" sizeXs="12">
                  <IonItem lines="none" className="custom-subheader-space ">
                    <IonLabel className="ion-text-wrap">
                      <label>
                        <span>{e.degree}</span>
                        {e.field_study && " • " + e.field_study}
                      </label>
                      <p>
                        <span>{e.inst_name}</span>
                      </p>
                    </IonLabel>

                    <IonButton
                      slot="end"
                      fill="outline"
                      className="remove-shadow custom-circle-btn"
                      color="medium"
                      shape="round"
                      onClick={() => {
                        editfun(e);
                      }}
                    >
                      <IonIcon icon={pencilSharp} slot="icon-only" />
                    </IonButton>
                  </IonItem>
                  {i !== arrs.slice(0, educat).length - 1 && <hr />}
                </IonCol>
              </IonRow>
            ))}
        </IonGrid>
        {arrs.length > 3 && (
          <IonItem lines="none">
            {arrs.length && arrs.length !== educat && arrs.length >= educat ? (
              <IonButton
                fill="clear"
                color="secondary"
                type="button"
                size="small"
                className="remove-shadow lowercase"
                onClick={() => setEdu(educat + 3)}
              >
                {arrs.length - educat} more education{arrs.length > 1 && "(s)"}
              </IonButton>
            ) : (
              arrs.length > 3 &&
              arrs.length <= educat && (
                <IonButton
                  fill="clear"
                  color="secondary"
                  type="button"
                  size="small"
                  className="remove-shadow lowercase"
                  onClick={() => setEdu(3)}
                >
                  less education
                </IonButton>
              )
            )}
          </IonItem>
        )}
      </>
    )
  );
};

export default React.memo(Education);
