import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import { add } from "ionicons/icons";
import React from "react";
import { RouteComponentProps } from "react-router";
import { CustomAnchor, HeaderNav } from "../../../component";
import {
  addDefaultSrc,
  apiCallBack,
  ContractProfUpload,
  fileUpload,
  GetContractorProfileData,
  getCookie,
  onSave,
  SeverfilePath,
} from "../../../service";
import { AuthContext } from "../../../context/authContext";
import DeikaaSlider from "../Deikaa/deikaa";
import Personal from "./Section/Personal";
import Career from "./Section/Career/Career";
import Qualification from "./Section/Qualifications";
import MoreDetail from "./Section/More";
import {
  arrayOptionData,
  Contractor,
  Staff,
  TRM,
  CRMRole,
  StringMap,
} from "../../../service/default";
import { listoflanguages } from "../Service/api";
import { decrypted } from "../../../service/axios";

class ContractorProfileLayout extends React.Component<RouteComponentProps> {
  static contextType = AuthContext;
  state = {
    loading: false,
    userType: [CRMRole, Staff, TRM],
    user: getCookie({ key: "user" }).value,
    ctrid: "",
    imageUrl: "",
    imgSelect: "",
    public_id: "",
    profileData: null,
    folderkey: "",
    coverphoto: "",
    optinDeikaa: "",
    allow_search: "0",
    showToast: false,
    toastMessage: "",
    toastColor: "success",
    setDragOverlay: false,
    tokenCount: "0",
    matches: window.matchMedia("(max-width: 2491px)").matches,
  };

  dragCounter = React.createRef<any>();

  // cover upload
  imageUpload(e: any, type: string) {
    const files = e.target.files;
    preventBrowserDefaults(e);
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        this.setState({
          toastMessage: errVal,
          toastColor: "danger",
          showToast: true,
        });
      }
      return false;
    }
    const imagename = files[0].name;
    fileUpload(files[0], this.state.folderkey).then((e) => {
      if (apiCallBack(e)) {
        if (type === "profile") {
          this.postProfUpload(e.filename, type);
        } else {
          this.postProfUpload(e.filename, type);
        }
      } else {
        this.setState({
          toastMessage: "Image upload failed, please try again",
          toastColor: "danger",
          showToast: true,
        });
      }
    });
  }

  // profile upload
  postProfUpload(files: any, type: string) {
    const user = getCookie({ key: "user" }).value;
    let ctr_id_toUse = getCookie({ key: "ctrid" }).value ?? user.ctr_id;
    const payload = {
      ctr_id: ctr_id_toUse,
      profile_photo: type === "profile" ? files : this.state.imageUrl ?? "",
      cover_photo: type === "cover" ? files : this.state.coverphoto ?? "",
      allow_search: this.state.allow_search ?? "0",
    };
    ContractProfUpload(payload)
      .then((e) => {
        if (apiCallBack(e)) {
          const data = user;
          if (data.user_type === Contractor && type === "profile") {
            data.profile_photo = files;
            onSave({ key: "userData", value: data });
            onSave({ key: "user", value: data });
          }
          this.setState({
            imageUrl: type === "profile" ? files : this.state.imageUrl,
            coverphoto: type === "cover" ? files : this.state.coverphoto,
            toastMessage: e.message ?? e.data,
            toastColor: "success",
            showToast: true,
          });
          if (e.deika_tokens !== null) {
            const { auth, setDeikaa } = this.context;
            const newValue: any[] = [];
            if (auth.dekiaa) {
              auth.dekiaa.map((dk: any, index: any) => {
                if (dk.action_title === "PHOTOUPLOAD") {
                  const data = e.deika_tokens;
                  newValue.push(data);
                } else {
                  newValue.push(dk);
                }
                if (dk.action_title !== "PHOTOUPLOAD") {
                  newValue.push(e.deika_tokens);
                }
              });
              return setDeikaa(newValue);
            }
          }
        }
      })
      .catch((err) => {});
  }

  // get skill list
  listofskillToolLanguage = () => {
    listoflanguages().then((ele) => {
      if (ele !== undefined && ele.status === 200) {
        const { OptionListfun } = this.context;
        const skill = arrayOptionData(ele.jng_skills, "skill_name", "skill_id");
        const lang = arrayOptionData(ele.jng_language, "language_name", "lid");
        const tool = arrayOptionData(ele.jng_tools, "tool_name", "tt_id");
        const suggestedskill = arrayOptionData(
          ele.highlighted_suggested_skill,
          "skill_name",
          "high_sugg_id"
        );
        const result = {
          allskills: skill,
          alltools: tool,
          alllang: lang,
          allhighlightedsuggestedskill: suggestedskill,
        };
        OptionListfun(result);
      }
    });
  };

  getDetail() {
    this.setState({
      loading: true,
    });
    const user = getCookie({ key: "user" }).value;
    //this patch of fetching contractor id for staff is moved to get Cookie. After testing, this can be removed from here
    let ctr_id_toUse = getCookie({ key: "ctrid" }).value ?? user.ctr_id;

    GetContractorProfileData(ctr_id_toUse).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        const { setDeikaa } = this.context;
        const response = value.data;
        if (response.deika_tokens) {
          setDeikaa(response.deika_tokens);
        }
        this.setState({
          profileData: response,
          public_id: response.public_id,
          folderkey: response.folder_key,
          coverphoto: decrypted(response.cover_photo),
          allow_search: response.allow_search,
          imageUrl: decrypted(response.profile_photo),
          optinDeikaa: response.opt_in ?? "",
          tokenCount:
            response.deikaa_balance && response.deikaa_balance.data
              ? response.deikaa_balance.data
              : 0,
          loading: false,
        });
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }

  componentDidMount() {
    const user = getCookie({ key: "user" }).value;
    let ctr_id_toUse = getCookie({ key: "ctrid" }).value ?? user.ctr_id;
    if (this.state.user) {
      this.getDetail();
      this.listofskillToolLanguage();
      this.setState({ ctrid: ctr_id_toUse });
    }
    const handler = (e) => this.setState({ matches: e.matches });
    window
      .matchMedia("(max-width: 2491px)")
      .addEventListener("change", handler);
  }

  updateChild(data: any) {
    if (data) {
      const Additionaldata = {
        title: "Additional data for contractor",
        contractorName: data.firstname + data.lastname,
        ctrid: data.ctr_id,
        required: this.state.user.user_type === CRMRole ? true : false,
        requestText: "",
        subTitle:
          "Contractor Name: " + StringMap(data.firstname + data.lastname),
      };
      onSave({ key: "additionaldata", value: Additionaldata });
      window.open("/trm/additional-data", "_blank");
      return;
    }
  }

  render() {
    const { loading, imageUrl, user, userType, profileData } = this.state;
    return (
      <IonPage>
        <HeaderNav />

        <IonContent>
          <IonLoading
            cssClass="my-custom-class"
            isOpen={loading}
            onDidDismiss={() => this.setState({ loading: false })}
            message={"Please Wait.."}
          />
          <IonToast
            isOpen={this.state.showToast}
            onDidDismiss={() => this.setState({ showToast: false })}
            message={this.state.toastMessage}
            duration={3000}
            color={this.state.toastColor}
          />

          <div
            className={
              this.state.matches
                ? "container main-container profile-container"
                : "main-container profile-container"
            }
          >
            <DeikaaSlider
              ctrid={
                getCookie({ key: "ctrid" }).value ?? this.state.user.ctr_id
              }
              optinData={this.state.optinDeikaa}
              tokenData={this.state.tokenCount}
              uploadImage={(e) => {
                this.setState({
                  folderkey: this.state.folderkey,
                });
                this.imageUpload(e, "profile");
              }}
              JoinDeika={() => this.setState({ optinDeikaa: "1" })}
            />
            {/* <div
              className="cover-img"
              style={{
                backgroundImage: `url(${
                  this.state.folderkey && this.state.coverphoto
                    ? SeverfilePath +
                      this.state.folderkey +
                      "/" +
                      this.state.coverphoto
                    : "assets/image/Hirify-Pitch-logo.png"
                })`,
                backgroundSize: `${
                  this.state.folderkey && this.state.coverphoto
                    ? "cover"
                    : "60% 100%"
                }`,
              }}
            >
              <label className="btn btn-default cover-img">
                <IonIcon
                  className=" custom-edit i-color"
                  icon={pencilSharp}
                ></IonIcon>
                <input
                  type="file"
                  accept="image/*"
                  id="upload-button"
                  hidden
                  onChange={(e) => {e.preventDefault();this.imageUpload(e, "cover");}}
                />
              </label>
            </div> */}
            <div className="wrapper">
              <div className="left-slider">
                <div className="photo-left">
                  <div className="profile-img">
                    <img
                      src={
                        SeverfilePath +
                        decrypted(this.state.folderkey) +
                        "/" +
                        imageUrl
                      }
                      alt="avatar"
                      onError={addDefaultSrc}
                      className="photo"
                    />

                    <label className="btn btn-default">
                      <IonIcon
                        className="custom-edit i-color"
                        icon={add}
                      ></IonIcon>
                      <input
                        type="file"
                        accept="image/*"
                        id="upload-button"
                        hidden
                        onChange={(e: any) => {
                          e.preventDefault();
                          this.imageUpload(e, "profile");
                        }}
                      />
                    </label>
                  </div>
                </div>

                <CustomAnchor
                  pathname={"/ContractorProfile"}
                  username={""}
                  deikaaActive={true}
                  PageLink={Link}
                />
              </div>
              <div className="right-content">
                {userType.includes(user.user_type) && (
                  <div>
                    <IonGrid>
                      <IonRow>
                        <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                          <IonCard className="my-2 p-1 deikaa-chip-orange text-center">
                            <span className="chip-font">
                            Additional Data
                            </span>
                            <span
                              className="chip-font read-or-hide"
                              onClick={() => {
                                this.updateChild(this.state.profileData);
                              }}
                            >
                              {" "}
                              click here
                            </span>
                          </IonCard>
                        </IonCol>
                        <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                          <IonCard className="my-2 p-1 deikaa-chip-orange text-center">
                            <span className="chip-font">
                            Matching Jobs
                            </span>
                            <span
                              className="chip-font read-or-hide"
                              onClick={() => {
                                this.props.history.push("/listmatchjob", {
                                  ctrid: user.ctr_id,
                                });
                              }}
                            >
                              {" "}
                              click here
                            </span>
                          </IonCard>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                )}

                {!loading && (
                  <Personal
                    folderKeyname={this.state.folderkey}
                    profileImg={this.state.imageUrl}
                    coverImg={this.state.coverphoto}
                    allowsearch={this.state.allow_search}
                    personalData={
                      this.state.profileData !== null && this.state.profileData
                    }
                    dataReload={() => this.getDetail()}
                  />
                )}
                {!loading && (
                  <Career
                    personalData={
                      this.state.profileData !== null && this.state.profileData
                    }
                    ctrId={this.state.ctrid}
                    folderKey={this.state.folderkey}
                  />
                )}
                {!loading && (
                  <Qualification
                    personalData={
                      this.state.profileData !== null && this.state.profileData
                    }
                    folderKey={this.state.folderkey}
                  />
                )}
                {!loading && (
                  <MoreDetail
                    ctrid={this.state.ctrid}
                    personalData={
                      this.state.profileData !== null && this.state.profileData
                    }
                  />
                )}
                {/* <ProfileSettings /> */}
              </div>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}
export default ContractorProfileLayout;

const Link = [
  {
    title: "Personal & career",
    link: "#personal",
    child: [
      { sub: "Name & location", link: "#namelocation" },
      { sub: "About", link: "#about" },
      { sub: "Experience", link: "#career" },
    ],
  },
  {
    title: "Qualifications",
    link: "#qualifications",
    child: [
      { sub: "Skills", link: "#skills" },
      { sub: "Tools & technologies", link: "#tools" },
      { sub: "Education", link: "#education" },
      { sub: "Certifications", link: "#certifications" },
      { sub: "Spoken languages", link: "#language" },
    ],
  },
  {
    title: "More details",
    link: "#details",
    child: [
      { sub: "Preferences", link: "#preference" },
      { sub: "Other", link: "#others" },
      { sub: "References", link: "#references" },
    ],
  },
  // {
  //   title: "Profile settings",
  //   link: "profilesettings",
  //   child: [],
  // },
];

// image validation

const preventBrowserDefaults = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const fileValidator = (files: any, config: any) => {
  const { allowedFileFormats, fileSizeMBLimit, filesLimit } = config;
  const { length } = files;
  const { size, type } = files[0];
  let err = "";
  let result = {
    isValidFile: false,
    errVal: err,
  };
  if (length === 0) {
    return result;
  } else if (length > filesLimit) {
    err =
      filesLimit > 1
        ? `Only ${filesLimit} files are allowed to upload`
        : `Only one file is allowed to upload`;
  } else if (!allowedFileFormats.includes(type)) {
    err = "File format must be either .jpg and .png";
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    err = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  result.errVal = err;
  return result;
};

const config = {
  allowedFileFormats: ["image/jpeg", "image/jpg", "image/png"],
  fileSizeMBLimit: 5,
  filesLimit: 1,
};
