import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonCard,
  IonButton,
  IonToast,
  IonLoading,
} from "@ionic/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Ceditor, DatePick } from "../../../component";
import { AuthContext } from "../../../context/authContext";
import { arrayskillOptionData } from "../../../service";
import { addcrm, updatecrm } from "../service/api";

interface jobFormInterface {
  job_unique_id: string;
  company_name: string;
  job_title: string;
  job_description: string;
  total_experince: string;
  start_date: string | Date;
  status: string;
  mandatory_skills: skillInterface[];
  additional_skills: skillInterface[];
}

interface skillInterface {
  label: string;
  value: string;
}

type postFormInterface = {
  closeModal: (boolean) => void;
  editDatas: jobFormInterface | undefined;
  id: string;
  formType: string;
  skillsOption: { label: string; value: string }[] | undefined;
};

const PostJob = ({
  formType,
  closeModal,
  skillsOption,
  editDatas,
  id,
}: postFormInterface) => {
  const { auth } = React.useContext(AuthContext);
  const userType = auth.data;
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  // const [matchingSkill, setMatchingskill] = React.useState<skillInterface[]>();
  // const [additionalSkill, setAdditionalskill] = React.useState<skillInterface[]>();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all", defaultValues: editDatas });

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };
  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  const saveData = (val) => {
    setShowLoading(true);
    val.status = "1";
    val.mandatory_skills = arrayskillOptionData(
      val.mandatory_skills,
      "label",
      ""
    );
    val.additional_skills = arrayskillOptionData(
      val.additional_skills,
      "label",
      ""
    );

    if (formType === "add") {
      addcrm(val).then((value) => {
        if (value !== undefined && value.status === 200) {
          setShowLoading(false);
          setToastColor("success");
          setToastMessage(value.message);
          setShowToast(true);
          closeModal(false);
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(value !== undefined && value.message);
          setShowToast(true);
        }
      });
    } else {
      val.job_unique_id = editDatas !== undefined && editDatas.job_unique_id;
      updatecrm(val).then((value) => {
        if (value !== undefined && value.status === 200) {
          setShowLoading(false);
          setToastColor("success");
          setToastMessage(value.message);
          setShowToast(true);
          closeModal(false);
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(value !== undefined && value.message);
          setShowToast(true);
        }
      });
    }
  };

  React.useEffect(() => {
    if (formType && editDatas !== undefined) {
      setValue("company_name", editDatas.company_name);
      setValue("job_title", editDatas.job_title);
      setValue("job_description", editDatas.job_description);
      setValue("total_experince", editDatas.total_experince);
      setValue("start_date", editDatas.start_date);
      // setMatchingskill(editDatas.mandatory_skills);
      // setAdditionalskill(editDatas.additional_skills);
      setValue("mandatory_skills", editDatas.mandatory_skills);
      setValue("additional_skills", editDatas.additional_skills);
      // setValue("mandatory_skills", editDatas.mandatory_skills);
      // setValue("additional_skills", editDatas.additional_skills);
    }
  }, [closeModal]);

  return (
    <div>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <form onSubmit={handleSubmit(saveData)}>
        <IonCard className="ion-padding custom-modal-alignment">
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Company Name <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="company_name"
                    rules={{
                      required: "Company name",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Enter the company name"
                      />
                    )}
                  />
                
                </IonItem>
                {showError("company_name")}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Job Title <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="job_title"
                    rules={{
                      required: "Job title",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Enter the job title"
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("job_title")}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Job Description <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="job_description"
                    rules={{
                      required: "Job description",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Ceditor setEditorData={onChange} editorData={value} />
                    )}
                  />
                </IonItem>
                {showError("job_description")}
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Years of experience <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="total_experince"
                    rules={{
                      required: "Year of experience",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        placeholder="Enter the year of experience"
                      />
                    )}
                  />
                </IonItem>
                {showError("total_experince")}
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">
                    Start <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick
                        value={value}
                        ChangeEvent={onChange}
                        minDate={new Date()}
                      />
                    )}
                    control={control}
                    name="start_date"
                    rules={{ required: "Start Date is required" }}
                  />
                </div>
                {showError("start_date")}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <span className="p-3">Mandatory skills</span>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      allowNew
                      multiple
                      dropup
                      id="custom-selections-input"
                      newSelectionPrefix="Add a new skill: "
                      options={skillsOption !== undefined && skillsOption}
                      onChange={onChange}
                      selected={value}
                      placeholder=""
                    />
                  )}
                  control={control}
                  name="mandatory_skills"
                />
                {/* <div className="custom-groupInput-label-icon">
                    <img src="assets/image/company/add.svg" />
                  </div> */}
                {/* {matchingSkill &&
                      matchingSkill.map((a, i: number) => (
                        <IonChip color="orange" className="my-3" key={i}>
                          <IonLabel>{a.label}</IonLabel>
                        </IonChip>
                      ))} */}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <span className="p-3">Additional skills</span>
                <Controller
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      allowNew
                      multiple
                      dropup
                      id="custom-selections-input"
                      newSelectionPrefix="Add a new skill: "
                      options={skillsOption !== undefined && skillsOption}
                      onChange={onChange}
                      selected={value}
                      placeholder=""
                    />
                  )}
                  control={control}
                  name="additional_skills"
                />

                {/* {additionalSkill &&
                      additionalSkill.map((a, i: number) => (
                        <IonChip color="orange" className="my-3" key={i}>
                          <IonLabel>{a.label}</IonLabel>
                        </IonChip>
                      ))} */}
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="text-right pb-4">
            <IonButton
              shape="round"
              fill="solid"
              color="orange"
              className="remove-shadow capitalizes"
              type="submit"
            >
              Save
            </IonButton>
          </div>
        </IonCard>
      </form>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
    </div>
  );
};

export default React.memo(PostJob);
