import React from "react";

interface Props {
  title?: string;
}

const SectionHeader: React.FC<Props> = ({ title }) => {
  return (
    <>
      <div className="subtitle text-center">
        <h2>{title}</h2>
      </div>
      <div className="divider"></div>
    </>
  );
};
export default SectionHeader;
