import {
  IonCol,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import Staffuser1 from "../staff_user";
import React from "react";
import { getMethod } from "../../../service";
import { RouteComponentProps } from "react-router";
import Jngtrm from "../jng_trm";
import { HeaderNav } from "../../../component";

import CompanyTable from "./companyTable";
import ContractorTable from "./contractorTable";
import LegalTable from "./legalTable";
import CrmTable from "./crmTable";
import RecruiterTable from './recruiterTable';
import useMediaQuery from "../../../hooks/mediaQuery";
interface Props
  extends RouteComponentProps<{
    companyID?: string;
  }> {}

// -------------------------legal doc interface start --------------------//
export interface ICompanyDetails {
  com_id: string;
  firstname: string;
  lastname: string;
  email: string;
  contract_name?: IContractName[];
}

export interface IContractName {
  contract_name: string;
}
interface Props
  extends RouteComponentProps<{
    companyID?: string;
  }> {}

// -------------------------legal doc interface end --------------------//
const Staffuser = () => {
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [role_id, setRoleId] = React.useState<string>("2");
  const [getjngUserRole, setgetjngUserRole] = React.useState<any[]>([]);

  React.useEffect(() => {
    GetjngCR();
  }, []);

  const handleChangedropdown = (event) => {
    setRoleId(event.target.value);
  };

  const GetjngCR = () => {
    const FetchData = getMethod("Getjngroles");
    FetchData.then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setgetjngUserRole(value.data);
      } else {
      }
    });
  };

  return (
    <IonPage>
      <HeaderNav />

      <IonContent>
        <div className={m4kQuery ? "container" : ""}>
          <IonGrid>
            <IonRow>
              <IonCol sizeLg="3" sizeXs="12">
                <IonLabel>Select Role</IonLabel>
                <select
                  name="role_id"
                  className="custom-select"
                  placeholder={"Select Role"}
                  value={role_id}
                  onChange={handleChangedropdown}
                >
                  {getjngUserRole.map((jngrole: any) => (
                    <option value={jngrole.rid}>{jngrole.name}</option>
                  ))}
                </select>
              </IonCol>
            </IonRow>
          </IonGrid>

          {role_id === "4" ? (
            <Staffuser1 />
          ) : role_id === "3" ? (
            <CompanyTable />
          ) : role_id === "2" ? (
            <ContractorTable />
          ) : role_id === "5" ? (
            // jng legal file start //
            <LegalTable />
          ) : // jng legal file End //
          role_id === "15" ? (
            <Jngtrm />
          ) : role_id === "18" ? (
            <CrmTable />
          ) : role_id === "19" ? (
            <RecruiterTable />
          ) : (
            ""
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Staffuser);
