import { IonButton, IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";
import React, { useEffect } from "react";
import { StringMap } from "../../../../../service";
type props = {
  educationData: any;
  // ctrdata:string
};
interface fields {
  ctr_id: string;
  inst_name: string;
  degree: string;
  field_study: string;
  current_education: boolean;
  geo_location: string;
  latitude: any;
  longitude: any;
  start_date: any;
  end_date: any;
  grade: any;
  result_type: any;
  ctr_qid: string;
}

const initialValues: fields = {
  ctr_id: "",
  inst_name: "",
  ctr_qid: "",
  degree: "",
  field_study: "",
  current_education: false,
  geo_location: "",
  latitude: "",
  longitude: "",
  start_date: "",
  end_date: "",
  grade: "",
  result_type: "",
};

const Education: React.FC<props> = ({ educationData }) => {
  const [saveEducationData, setsaveEducationData] = React.useState<any>([]);
  const [dataupdated, setDataupdated] = React.useState(false);

  // useEffect(() => {
  //   if (!dataupdated) {
  //     setsaveEducationData(educationData);
  //     setDataupdated(true);
  //   }
  // }, []);

  useEffect(() => {
    setsaveEducationData(educationData);
  }, [educationData]);

  return (
    <>
      <div id="education">
        <IonItem lines="none" className="custom-header-space ">
          <h4>Education</h4>
        </IonItem>

        <EducationSection
          arrs={saveEducationData !== undefined && saveEducationData}
        />
      </div>
    </>
  );
};

const EducationSection = ({ arrs, editfun }: any) => {
  const [educat, setEdu] = React.useState(3);
  return (
    arrs &&
    arrs.length > 0 && (
      <>
        <IonGrid className="ion-no-padding">
          {arrs
            .sort((a, b) => b.start_year - a.start_year)
            .slice(0, educat)
            .map((e: any, i: number) => (
              <IonRow key={e.inst_name}>
                <IonCol sizeLg="4" sizeXs="12">
                  <label>
                    {StringMap(e.start_year)}
                    {" - " + StringMap(e.end_year)}
                  </label>
                  {e.result && (
                    <p>
                      Result:{" "}
                      <span>
                        {e.result}&nbsp;{e.result_type}
                      </span>
                    </p>
                  )}
                </IonCol>
                <IonCol sizeLg="8" sizeXs="12">
                  <h6>
                    {e.degree} {e.field_study}
                  </h6>
                  <p>
                    <span>{e.inst_name}</span>
                  </p>
                  {i !== arrs.slice(0, educat).length - 1 && <hr />}
                </IonCol>
              </IonRow>
            ))}
        </IonGrid>
        {arrs.length > 3 && (
          <IonItem lines="none">
            {arrs.length && arrs.length !== educat && arrs.length >= educat ? (
              <IonButton
                slot="end"
                fill="clear"
                color="secondary"
                type="button"
                size="small"
                className="remove-shadow lowercase"
                onClick={() => setEdu(educat + 3)}
              >
                {arrs.length - educat} more education
              </IonButton>
            ) : (
              arrs.length > 3 &&
              arrs.length <= educat && (
                <IonButton
                  slot="end"
                  fill="clear"
                  color="secondary"
                  type="button"
                  size="small"
                  className="remove-shadow lowercase"
                  onClick={() => setEdu(3)}
                >
                  less education
                </IonButton>
              )
            )}
          </IonItem>
        )}
      </>
    )
  );
};

export default Education;
