import React, { useState } from "react";
import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
  IonToggle,
} from "@ionic/react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  CtrProfileCompletion_flag,
  deikaaSaveadditional,
  GetCtradditionaldata,
} from "./Service/api";
import { addOutline, closeOutline } from "ionicons/icons";
import {
  arrayOptionData,
  getCookie,
  getIndexValue,
  RemoveDuplicates,
} from "../../service";
import {
  CustomTooltip,
  DatePick,
  HeaderNav,
  RateComponent,
  TagSearch,
} from "../../component";
import { CRMRole } from "../../service/default";
import { AuthContext } from "../../context/authContext";
import useMediaQuery from "../../hooks/mediaQuery";
import InterviewFeedback from "./interviewFeedback";
import TrmtouchPoint from "./trmTouchpoint";
interface childrenData {
  child_name: any;
  dob: any;
  gender: any;
}
const childField: childrenData = {
  child_name: "",
  dob: Date,
  gender: "",
};

interface fields {
  tad_id: string;
  form_type: string;
  date_of_birth: string;
  anniversary_date: string;
  spouse_name: string;
  spouse_occupation: string;
  spouse_dob: string;
  interest_and_hobbies: string;
  remarks: string;
  family_data: Array<childrenData>;
}

const fieldData: fields = {
  tad_id: "",
  form_type: "",
  date_of_birth: "",
  anniversary_date: "",
  spouse_name: "",
  spouse_occupation: "",
  spouse_dob: "",
  interest_and_hobbies: "",
  remarks: "",
  family_data: Array(childField),
};

interface optionField {
  label: string;
  value: string | number;
}

function AdditionalData() {
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const stateValue = getCookie({ key: "additionaldata" }).value;
  const [groupList, setGroupList] = useState<any>([]);
  const [uploading, setUploading] = React.useState(false);
  const [datauploading, setDataUploading] = React.useState(false);
  const [showToast, setShowToast] = useState(false);
  const [prefectmode, setPrefectMode] = useState<optionField[]>([]);
  const [orginOption, setOrginOption] = useState<optionField[]>([]);
  const [preferredMode, setPreferredMode] = useState("");
  const [origindata, setOriginData] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "family_data",
  });

  const deikaaSaveadditionalData = (values: any) => {
    if (userdata.user_type !== CRMRole) {
      // const group_id = arrayOptionNameData(values.group_id, "value") || "";
      values.group_id = values.group_id ? values.group_id.toString() : "";
      values.origin =
        values.origin && values.origin.length !== 0
          ? values.origin[0].label
          : origindata;
      values.preferred_mode =
        values.preferred_mode && values.preferred_mode.length !== 0
          ? values.preferred_mode[0].label
          : preferredMode;
      values.profile_flag = values.profile_flag ? "1" : "0";
      values.date_of_birth = values.date_of_birth ?? "";
      values.anniversary_date = values.anniversary_date ?? "";
      values.spouse_name = values.spouse_name ?? "";
      values.spouse_occupation = values.spouse_occupation ?? "";
      values.spouse_dob = values.spouse_dob ?? "";
      values.interest_and_hobbies = values.interest_and_hobbies ?? "";
      values.remarks = values.remarks ?? "";
      values.no_of_children = values.family_data
        ? values.family_data.length
        : "0";
      values.ctr_id = stateValue["ctrid"];
      values.form_type = values.tad_id ? "update" : "add";
      values.family_data = values.family_data
        ? values.family_data.length !== 0
          ? values.family_data
          : []
        : [];
      setUploading(true);
      deikaaSaveadditional(values).then((value) => {
        if (value !== undefined && value.status === 200) {
          setToastColor("success");
          setToastMessage(value.message);
          setShowToast(true);
          setUploading(false);
        } else {
          setUploading(false);
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(
            value !== undefined ? value.message : "please try again"
          );
          setShowToast(false);
        }
      });
    }
  };

  function getAdditionalData() {
    setDataUploading(false);
    setShowLoading(true);
    GetCtradditionaldata(stateValue.ctrid).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        const groupName = arrayGoupingOptionData(
          e.ctr_group,
          "group_name",
          "ctr_gp"
        );
        setGroupList(groupName);
        const prefectMode = arrayOptionData(
          e.data.preffered_mode_list,
          "preffered_mode",
          "preffered_mode"
        );

        const familyData = e.data.family_data.map((item) => ({
          ...item,
          dob: item.dob ? new Date(item.dob) : "",
        }));
        setPrefectMode(prefectMode);
        setOrginOption(arrayOptionData(e.data.origin_list, "origin", "origin"));
        setValue(
          "profile_flag",
          e.data.profile_flag && e.data.profile_flag === "1" ? true : false
        );

        const ctrgroup = e.data.ctr_group && e.data.ctr_group.split(",");

        setValue("group_id", e.data.ctr_group ? ctrgroup : []);
        // setValue(
        //   "group_id",
        //   e.data.ctr_group
        //     ? arrayOptionDatavalueEmpty(ctrgroup, groupName, "")
        //     : []
        // );
        setValue(
          "date_of_birth",
          e.data.date_of_birth ? new Date(e.data.date_of_birth) : ""
        );
        setValue(
          "anniversary_date",
          e.data.anniversary_date ? new Date(e.data.anniversary_date) : ""
        );
        setValue("spouse_name", e.data.spouse_name);
        setValue("spouse_occupation", e.data.spouse_occupation);
        setValue(
          "spouse_dob",
          e.data.spouse_dob ? new Date(e.data.spouse_dob) : ""
        );
        setValue("interest_and_hobbies", e.data.interest_and_hobbies);
        setValue("remarks", e.data.remarks);
        setValue("family_data", familyData);
        setValue("tad_id", e.data.tad_id);
        setValue(
          "preferred_mode",
          e.data.preferred_mode
            ? [{ label: e.data.preferred_mode, value: e.data.preferred_mode }]
            : []
        );
        setValue(
          "origin",
          e.data.origin ? [{ label: e.data.origin, value: e.data.origin }] : []
        );
        setValue("form_type", e.data.tad_id ? "update" : "add");
        setDataUploading(true);
        setShowLoading(false);
      } else {
        const groupName = arrayGoupingOptionData(
          e.ctr_group,
          "group_name",
          "ctr_gp"
        );
        setGroupList(groupName);
        setDataUploading(true);
        setShowLoading(false);
      }
    });
  }

  const arrayGoupingOptionData = (array, label, value) => {
    if (array !== undefined) {
      const pushData: {
        name: string;
        label: any;
        value: string | number;
        hint: string;
      }[] = [];
      array.map((s) => {
        if (s[value] !== null && s[value] !== undefined) {
          pushData.push({
            name: s[label],
            label: (
              <CustomTooltip
                content={s.hint}
                className="d-flex justify-content-between"
                direction="bottom"
              >
                <label>{s[label]}</label>
                {/* <IonIcon icon={information} slot="icon-only" title={s.hint} /> */}
              </CustomTooltip>
            ),
            value: s[value],
            hint: s.hint,
          });
        }
      });
      return pushData;
    } else {
      return [];
    }
  };

  // ready to market
  const readytoMarket = (data) => {
    if (userdata.user_type !== CRMRole) {
      setValue("profile_flag", data ? true : false);
      setShowLoading(true);
      const result = {
        ctr_id: stateValue.ctrid,
        profile_complete: data ? "1" : "0",
      };
      CtrProfileCompletion_flag(result).then((value) => {
        if (value !== undefined && value.status === 200) {
          setShowLoading(false);
          setToastColor("success");
          setToastMessage("flag updated successfully");
          setShowToast(true);
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(
            value !== undefined ? value.message : "please try again"
          );
          setShowToast(false);
        }
      });
    }
  };

  const ShowAgencyMatchjoblist = (e) => {
    if (e) {
      window.open("/agencymatchjob/" + stateValue.ctrid, "_blank");
    }
  };
  const ShowContractorMatchjoblist = (e) => {
    if (e) {
      window.open("/ContractorMatchJob/" + stateValue.ctrid, "_blank");
    }
  };

  const selectedTags = (tags) => {
    setValue("group_id", tags);
  };

  React.useEffect(() => {
    reset();
    getAdditionalData();
  }, []);

  return (
    <IonPage>
      <HeaderNav />
      <div className={m4kQuery ? "container" : "modelheader"}>
        <div className="">
          <h2 className="modal-label py-2">
            {stateValue.title + " "}
            {!stateValue.required && (
              <span className="required">
                * mandatory {stateValue.requestText}
              </span>
            )}
          </h2>
        </div>
        <p>{stateValue.subTitle}</p>
      </div>
      <IonContent className={m4kQuery ? "ion-no-padding" : ""}>
        <div className={m4kQuery ? "container" : ""}>
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={5000}
            color={toastColor}
          />
          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
          />

          {datauploading && (
            <div className="ion-padding card mt-2">
              {userdata.user_type === CRMRole ? (
                <p className="red">no changes will be saved</p>
              ) : (
                ""
              )}
              <IonGrid className="ion-no-margin">
                <form onSubmit={handleSubmit(deikaaSaveadditionalData)}>
                  <IonRow>
                    <IonCol sizeLg="8" sizeXs="12">
                      <IonGrid>
                        <IonRow>
                          <IonCol sizeLg="12" sizeXs="12">
                            <div className="mt-2 custom-lable">
                              <IonLabel position="stacked">Group Name</IonLabel>
                              <Controller
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <TagSearch
                                    selectedTags={selectedTags}
                                    value={value}
                                    tags={groupList}
                                  />
                                )}
                                control={control}
                                name={"group_id"}
                              />
                            </div>
                          </IonCol>
                          <IonCol sizeLg="4" sizeXs="12">
                            <IonItem lines="none">
                              <IonLabel position="stacked">
                                Ready to market
                              </IonLabel>
                              <Controller
                                render={({
                                  field: { onChange, value, ref },
                                }) => (
                                  <IonToggle
                                    mode={"md"}
                                    checked={value}
                                    onIonChange={(e) =>
                                      readytoMarket(e.detail.checked)
                                    }
                                  />
                                )}
                                control={control}
                                name={"profile_flag"}
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCol>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="orange"
                        className="remove-shadow capitalizes"
                        type="button"
                        onClick={(e) => ShowAgencyMatchjoblist(e)}
                      >
                        Show Jobs On Agency Leads
                      </IonButton>
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="orange"
                        className="remove-shadow capitalizes"
                        type="button"
                        onClick={(e) => ShowContractorMatchjoblist(e)}
                      >
                        Show Jobs On JobsnGigs
                      </IonButton>
                    </IonCol>
                    {userdata.user_type !== CRMRole && (
                      <IonCol sizeLg="12" sizeXs="12">
                        <IonButton
                          shape="round"
                          fill="solid"
                          color="secondary"
                          className="remove-shadow capitalizes"
                          type="submit"
                        >
                          {uploading ? "Uploading" : "Save"}
                        </IonButton>
                      </IonCol>
                    )}
                  </IonRow>
                </form>
              </IonGrid>
            </div>
          )}

          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <InterviewFeedback ctrid={stateValue.ctrid} />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <TrmtouchPoint ctrid={stateValue.ctrid} />
              </IonCol>
              <IonCol size="12">
                <RateComponent ctrID={stateValue.ctrid} />
              </IonCol>
            </IonRow>
            <IonCard className="ion-padding my-3">
              <IonGrid>
                <form onSubmit={handleSubmit(deikaaSaveadditionalData)}>
                  <IonRow>
                    <IonCol sizeLg="6" sizeXs="12">
                      <div className="mt-2 custom-lable">
                        <IonLabel position="stacked">
                          Preferred mode of communication
                        </IonLabel>
                        <Controller
                          control={control}
                          name="preferred_mode"
                          render={({ field: { onChange, value, ref } }) => (
                            <Typeahead
                              allowNew
                              id="custom-selections-input"
                              newSelectionPrefix="Add new mode: "
                              options={prefectmode}
                              onChange={onChange}
                              onInputChange={(text, e) => {
                                setPreferredMode(text);
                              }}
                              selected={value}
                              placeholder=""
                              selectHintOnEnter={true}
                              onKeyDown={(event) => {
                                if (
                                  event.key === "Enter" ||
                                  event.key === "Tab"
                                ) {
                                  var backup: any[] = [];
                                  backup = watch("preferred_mode");
                                  setValue("preferred_mode", []);
                                  if (event.target.value) {
                                    watch("preferred_mode").map((e) => {
                                      if (e.label !== event.target.value) {
                                        backup.push({
                                          label: event.target.value,
                                          value: getIndexValue(
                                            event.target.value,
                                            prefectmode,
                                            "label",
                                            "value"
                                          ),
                                        });
                                      }
                                    });
                                    if (watch("preferred_mode").length === 0) {
                                      backup.push({
                                        label: event.target.value,
                                        value: getIndexValue(
                                          event.target.value,
                                          prefectmode,
                                          "label",
                                          "value"
                                        ),
                                      });
                                    }
                                  }

                                  setValue(
                                    "preferred_mode",
                                    RemoveDuplicates(backup, "label")
                                  );
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <div className="mt-2 custom-lable">
                        <IonLabel position="stacked">Source</IonLabel>
                        <Controller
                          control={control}
                          name="origin"
                          render={({ field: { onChange, value, ref } }) => (
                            <Typeahead
                              allowNew
                              id="custom-selections-input"
                              newSelectionPrefix="Add new origin: "
                              options={orginOption}
                              onChange={onChange}
                              onInputChange={(text, e) => {
                                setOriginData(text);
                              }}
                              selected={value}
                              placeholder=""
                              selectHintOnEnter={true}
                              onKeyDown={(event) => {
                                if (
                                  event.key === "Enter" ||
                                  event.key === "Tab"
                                ) {
                                  var backup: any[] = [];
                                  backup = watch("origin");
                                  setValue("origin", []);
                                  if (event.target.value) {
                                    watch("origin").map((e) => {
                                      if (e.label !== event.target.value) {
                                        backup.push({
                                          label: event.target.value,
                                          value: getIndexValue(
                                            event.target.value,
                                            orginOption,
                                            "label",
                                            "value"
                                          ),
                                        });
                                      }
                                    });
                                    if (watch("origin").length === 0) {
                                      backup.push({
                                        label: event.target.value,
                                        value: getIndexValue(
                                          event.target.value,
                                          orginOption,
                                          "label",
                                          "value"
                                        ),
                                      });
                                    }
                                  }

                                  setValue(
                                    "origin",
                                    RemoveDuplicates(backup, "label")
                                  );
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <div className="input-div">
                        <IonLabel position="stacked">Date of birth</IonLabel>
                        <Controller
                          control={control}
                          name="date_of_birth"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePick
                              value={value}
                              ChangeEvent={onChange}
                              yearpermit={18}
                            />
                          )}
                        />
                      </div>
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <div className="input-div">
                        <IonLabel position="stacked">Anniversary Date</IonLabel>
                        <Controller
                          control={control}
                          name="anniversary_date"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePick value={value} ChangeEvent={onChange} />
                          )}
                        />
                      </div>
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">Spouse name</IonLabel>
                        <Controller
                          control={control}
                          name="spouse_name"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              autocomplete="off"
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <div className="input-div">
                        <IonLabel position="stacked">
                          Spouse Date of birth
                        </IonLabel>
                        <Controller
                          control={control}
                          name="spouse_dob"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePick
                              value={value}
                              ChangeEvent={onChange}
                              yearpermit={18}
                            />
                          )}
                        />
                      </div>
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Spouse Occupation
                        </IonLabel>
                        <Controller
                          control={control}
                          name="spouse_occupation"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              autocomplete="off"
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Interest & Hobbies
                        </IonLabel>
                        <Controller
                          control={control}
                          name="interest_and_hobbies"
                          render={({ field: { onChange, value, ref } }) => (
                            <IonInput
                              autocomplete="off"
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol sizeLg="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">Remarks</IonLabel>
                        <Controller
                          control={control}
                          name="remarks"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              autocomplete="off"
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol sizeLg="12" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">Add children</IonLabel>
                        <IonButton
                          slot="end"
                          fill="outline"
                          color="medium"
                          className="remove-shadow custom-circle-btn custom-close"
                          shape="round"
                          onClick={() =>
                            append({ child_name: "", dob: "", gender: "" })
                          }
                        >
                          <IonIcon
                            icon={addOutline}
                            slot="icon-only"
                            className="closeoutline-mobile-version"
                          />
                        </IonButton>
                      </IonItem>
                    </IonCol>
                    <IonCol sizeLg="12" sizeXs="12">
                      <IonGrid className="ion-no-padding">
                        {fields !== undefined &&
                          fields.length !== 0 &&
                          fields.map((item: any, index: number) => (
                            <IonRow key={item.id}>
                              <IonCol sizeLg="4" sizeXs="12">
                                <IonItem lines="none">
                                  <IonLabel position="stacked">
                                    Child Name
                                  </IonLabel>
                                  <Controller
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <IonInput
                                        autocomplete="off"
                                        onIonChange={onChange}
                                        value={value}
                                      />
                                    )}
                                    control={control}
                                    name={
                                      `family_data.${index}.child_name` as `family_data.0.child_name`
                                    }
                                    defaultValue={item.child_name}
                                  />
                                </IonItem>
                              </IonCol>
                              <IonCol sizeLg="4" sizeXs="12">
                                <div className="input-div">
                                  <IonLabel position="stacked">DOB</IonLabel>
                                  <Controller
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <DatePick
                                        value={value}
                                        ChangeEvent={onChange}
                                        yearpermit={0}
                                      />
                                    )}
                                    control={control}
                                    name={
                                      `family_data.${index}.dob` as `family_data.0.dob`
                                    }
                                    defaultValue={item.dob}
                                  />
                                </div>
                              </IonCol>
                              <IonCol sizeLg="3" sizeXs="12">
                                <IonItem lines="none">
                                  <IonLabel position="stacked">Gender</IonLabel>
                                  <Controller
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <select
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Select"
                                      >
                                        <option value="">Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                      </select>
                                    )}
                                    control={control}
                                    name={
                                      `family_data.${index}.gender` as `family_data.0.gender`
                                    }
                                    defaultValue={item.gender}
                                  />
                                </IonItem>
                              </IonCol>
                              <IonCol sizeLg="1" sizeXs="12">
                                <IonButton
                                  style={{ marginTop: "37px" }}
                                  fill="outline"
                                  color="danger"
                                  shape="round"
                                  onClick={() => remove(index)}
                                >
                                  <IonIcon
                                    icon={closeOutline}
                                    slot="icon-only"
                                  />
                                </IonButton>
                              </IonCol>
                            </IonRow>
                          ))}
                      </IonGrid>
                    </IonCol>
                    {userdata.user_type !== CRMRole && (
                      <IonCol sizeLg="12" sizeXs="12">
                        <hr />

                        <IonButton
                          shape="round"
                          fill="solid"
                          color="secondary"
                          className="remove-shadow capitalizes"
                          type="submit"
                        >
                          {uploading ? "Uploading" : "Save"}
                        </IonButton>
                      </IonCol>
                    )}
                  </IonRow>
                </form>
              </IonGrid>
            </IonCard>
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
}
export default AdditionalData;
