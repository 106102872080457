import { IonCard } from "@ionic/react";
import Other from "./Other";
import Preferences from "./Preferences";
import References from "./References";

export default function MoreDetail({ personalData, ctrid }: any) {
  return (
    <div id="details">
      <h1 className="h1-pd">More details</h1>
      <IonCard className="pad-style">
        <Preferences
          ctrdata={ctrid}
          perferencesData={personalData}
          relocateData={personalData.relocate}
          locationData={
            personalData.prefered_locations !== undefined &&
            personalData.prefered_locations
          }
        />
      </IonCard>
      {/* <IonCard className="pad-style">
        <Other
          ctrdata={ctrid}
          workData={personalData}
          linkData={
            personalData !== undefined && personalData.contractor_Weblinks
          }
        />
      </IonCard> */}
      <IonCard className="pad-style">
        <References
          ctrdata={ctrid}
          filekeypath={
            personalData.folder_key !== undefined && personalData.folder_key
          }
          referenceData={
            personalData.reference !== undefined && personalData.reference
          }
        />
      </IonCard>
    </div>
  );
}
