import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonPopover,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import { eye, eyeOffOutline } from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { Controller, useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import { onCheckRemove, onSave, LoginApi, getMethod } from "../../../service";
import { AuthContext } from "../../../context/authContext";
import {
  Company,
  Contractor,
  CRMRecuter,
  CRMRole,
  Jngreporter,
  Legal,
  Staff,
  StringMap,
  TRM,
} from "../../../service/default";
import { decrypted, encrypted } from "../../../service/axios";

interface Props extends RouteComponentProps<{}> {}

const Login: React.FC<Props> = ({ history }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [showLoading, setShowLoading] = React.useState(false);
  const [captcha, setCaptchaValue] = React.useState<boolean>(false);
  const [showToast, setShowToast] = React.useState(false);
  const [showPassword, setPassword] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const Auth = React.useContext(AuthContext);
  const CheckUrl = document.URL.startsWith("https://apps.jobsngigs.com");
  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + "field is required"}
      </div>
    ) : null;
  };

  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  const loginUser = (values: any) => {
    if (!captcha && CheckUrl) {
      setToastColor("danger");
      setToastMessage("Please verify captcha to continue..");
      setShowToast(true);
      return;
    }
    setShowLoading(true);
    values.password = encrypted(values.password);
    LoginApi(values).then((r: any) => {
      if (r !== undefined && r.status === 200) {
        onSave({ key: "token", value: r.data.jng_token });
        onSave({ key: "uid", value: r.data.uid });
        onSave({ key: "user", value: r.data });
        onSave({ key: "ctrid", value: r.data.ctr_id });
        onSave({
          key: "userName",
          value: values.username,
        });
        Auth.setAuthResponse(true, r.data);
        if (r.data.user_type === Company) {
          history.replace("/jobs");
        } else if (r.data.user_type === Staff) {
          history.replace("/staff_users");
        } else if (r.data.user_type === Jngreporter) {
          history.replace("/list-jobs");
        } else if (r.data.user_type === TRM) {
          history.replace("/trm");
        } else if (r.data.user_type === Legal) {
          history.replace("/company-legal-files");
        } else if (r.data.user_type === CRMRole) {
          history.replace("/crm/jobs");
        } else if (r.data.user_type === CRMRecuter) {
          history.replace("/list-jobs");
        }else if (r.data.user_type === Contractor) {
          const FetchData = getMethod("Quickprofile");
          FetchData.then((value: any) => {
            if (value !== undefined && value.status === 200) {
              const keyValue = value.data;
              if (
                StringMap(keyValue.firstname) === "NA" ||
                StringMap(decrypted(keyValue.email)) === "NA" ||
                StringMap(keyValue.resume) === "NA"
              ) {
                history.push("/ContractorResume");
                setShowLoading(false);
              } else if (
                StringMap(keyValue.firstname) !== "NA" ||
                StringMap(keyValue.email) !== "NA" ||
                StringMap(keyValue.resume) !== "NA"
              ) {
                const jobid = sessionStorage.getItem("jobid");
                const uid = sessionStorage.getItem("uid");
                const workType = sessionStorage.getItem("workType");
                if(jobid){

                  history.replace("/Job/" + jobid,  {
                    unique_id: uid,
                    workFromhomeType: workType,
                    screenName: "",
                  });
                  sessionStorage.removeItem('jobid');
                  sessionStorage.removeItem('uid');
                  sessionStorage.removeItem('workType');
                } else{

                  history.replace("/ContractorProfile");
                }
                setShowLoading(false);
              } else {
                onCheckRemove();
                setShowLoading(false);
              }
              setShowLoading(false);
            }
          });
        } else {
          Auth.setAuthResponse(false, null);
          history.replace("/login");
        }
      } else {
        Auth.setAuthResponse(false, null);
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(r !== undefined ? r.message : "please try again");
        setShowToast(true);
      }
    }).finally(() => setShowLoading(false));
  };

  function onCaptchaValidation(value) {
    setCaptchaValue(true);
  }

  React.useEffect(() => {
    onCheckRemove();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
        <div className="login-form">
          <div className="login-img">
            <a href="/" >
            <img alt="" src="assets/image/hirify.png" /></a>
          </div>
          <div className="card-section">
            <div className="login-curve-box"></div>
            <IonCard className="ion-padding">
              <IonGrid>
                {/* <IonPopover trigger="click-trigger" triggerAction="hover">
                  <IonContent class="ion-padding">
                    Without Country Code
                  </IonContent>
                </IonPopover> */}
                <form onSubmit={handleSubmit(loginUser)}>
                  <IonRow>
                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel
                          position="stacked"
                          className=""
                          id="click-trigger"
                        >
                          Email/Username/Mobile No
                          <span className="required">*</span>
                        </IonLabel>

                        <Controller
                          control={control}
                          name="username"
                          rules={{
                            required:
                              "email/username/mobile number without country code  is required",
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              autocomplete="off"
                              onBlur={onBlur}
                              onIonChange={onChange}
                              value={value}
                              minlength={0}
                              maxlength={64}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                      {showError("username")}
                    </IonCol>
                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Password<span className="required">*</span>
                        </IonLabel>
                        <Controller
                          control={control}
                          name="password"
                          rules={{
                            required: "password is required",
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <>
                              <IonInput
                                autocomplete="off"
                                type={showPassword ? "text" : "password"}
                                onBlur={onBlur}
                                onIonChange={onChange}
                                value={value}
                                minlength={0}
                                maxlength={64}
                                placeholder=""
                              />
                              <IonButton
                                className="ion-no-padding customeye-btn-login"
                                fill="clear"
                                shape="round"
                                color="dark"
                                onClick={() => setPassword(!showPassword)}
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={showPassword ? eye : eyeOffOutline}
                                />
                              </IonButton>
                            </>
                          )}
                        />

                        <IonText
                          className="custom-content right-content-1 custom-pointer"
                          onClick={() => history.push("/forgot-password")}
                        >
                          Forgot?
                        </IonText>
                      </IonItem>
                      {showError("password")}
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol sizeLg="12" sizeXs="12">
                      <p className="text-center custom-p">
                        By signing in, you consent to the creation of your Hirify account and agree to our{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="/terms"
                        >
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a
                          rel="noreferrer"
                          href="/privacy-policy"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    </IonCol>

                    {CheckUrl && (
                      <IonCol sizeLg="12" sizeXs="12">
                        <div className="pannel-ReCAPTCHA">
                          <ReCAPTCHA
                            className="pannel-ReCAPTCHA2"
                            sitekey="6Lf4sJwaAAAAAIPAY3EPHjNHuHC41QZkmEGxKg5d"
                            onChange={onCaptchaValidation}
                          />
                        </div>
                      </IonCol>
                    )}
                    <IonCol sizeLg="12" sizeXs="12">
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="submit"
                      >
                        {showLoading ? "Loading..." : "Sign In"}
                      </IonButton>
                    </IonCol>
                  </IonRow>

                  {/* <hr className="uppercase divider">Or sign in with</hr> */}

                  <p>
                    Don't have an account? <a href="/Register">Register</a>
                  </p>
                </form>
              </IonGrid>
            </IonCard>
          </div>
          <IonGrid className="footer-content">
            <IonRow>
              <IonCol sizeLg="6" sizeXs="12">
                <ul className="ulelement text-left">
                  <li>Facing any difficulty?</li>
                  <li className="modify-color">
                    
                    <a
                      rel="noreferrer"
                      href="/#contactus"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <ul className="ulelement footer-rightSection ">
                  <li className="small-text smallfont">
                    © 2024–present, Hirify. All rights reserved.
                  </li>
                  <li className="smallfont">
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="/terms"
                    >
                      Terms of Use •
                    </a>{" "}
                    <a
                      rel="noreferrer"
                      href="/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={2000}
          color={toastColor}
        />
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Login);
