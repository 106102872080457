import {
  IonAlert,
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItemDivider,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import { cloudUploadOutline, trash } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { SectionHeader } from "../../component";

import {
  companyContractUpload,
  downloadFileWithGet,
  getMethod,
} from "../../service";

import Select from "react-select";
import { saveAs } from "file-saver";
import { HeaderNav } from "../../component";

export interface ICompanyDetails {
  com_id: string;
  firstname: string;
  lastname: string;
  email: string;
  contract_name?: IContractName[];
}

export interface IContractName {
  contract_name: string;
}

const CompanyLegalFiles: React.FC = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [companyData, setCompanyData] = useState<ICompanyDetails[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<ICompanyDetails>();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFile, setSelectedFile] = useState<string>();

  function downloadDocument(file: string) {
    downloadFileWithGet(
      "Contractupload/download_contract/" + selectedCompany?.com_id + "/" + file
    ).then((blob) => saveAs(blob, file));
  }

  function getCompanyDetails() {
    setShowLoading(true);
    const getData = getMethod("Contractupload/get_all_contract/");
    getData.then((returnData: any) => {
      setShowLoading(false);
      if (returnData.status === "200") {
        const tempData: ICompanyDetails[] =
          returnData.data as ICompanyDetails[];
        setCompanyData(tempData ?? []);
      }
    });
  }

  function onCompanySelect(selected) {
    setSelectedOption(selected);
    const filtered: ICompanyDetails[] = companyData.filter(
      (x) => x.com_id === selected.value
    );
    if (filtered.length > 0) setSelectedCompany(filtered[0]);
  }

  function confirmDelete(event, file) {
    setSelectedFile(file);
    event.stopPropagation();
    setShowAlert(true);
  }

  function deleteContract(isAccepted: boolean) {
    setShowAlert(false);
    if (!isAccepted) return;
    setShowLoading(true);
    const getData = getMethod(
      "Contractupload/delete_contract/" +
        selectedCompany?.com_id +
        "/" +
        selectedFile
    );
    getData.then((returnData: any) => {
      setShowLoading(false);
      if (returnData.status === "200") {
        setSelectedOption(null);
        setSelectedCompany(undefined);
        getCompanyDetails();
        setToastColor("success");
        setToastMessage("Document Deleted Successfully.");
        setShowToast(true);
      } else {
        setToastColor("danger");
        setToastMessage(returnData.message);
        setShowToast(true);
      }
    });
  }

  useEffect(() => {
    getCompanyDetails();
  }, []);

  function uploadContract(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      setShowLoading(true);
      companyContractUpload(e.target.files[0], selectedCompany?.com_id).then(
        (val: any) => {
          setShowLoading(false);
          if (val !== undefined && val.status === 200) {
            setSelectedOption(null);
            setSelectedCompany(undefined);
            getCompanyDetails();
            setToastColor("success");
            setToastMessage("Contract document uploaded successfully.");
            setShowToast(true);
          } else {
            setToastMessage("Unable to upload document, please try later.");
            setShowToast(true);
          }
        }
      );
    }
  }

  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <IonGrid className="text-center">
          <IonRow>
            <IonCol offsetLg="2" sizeLg="8" sizeXs="12">
              <SectionHeader title="Company Legal Documents" />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <h3>Manage Company Legal Documents in Jobs 'n' Gigs</h3>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol offsetLg="3" sizeLg="6" sizeXs="12">
              <IonGrid className="content-box">
                <IonRow>
                  <IonCol size="12">
                    <h4>Search Company</h4>
                    <IonGrid className="text-start">
                      <IonRow>
                        <IonCol sizeLg="12" sizeXs="12">
                          <Select
                            value={selectedOption}
                            name="Company"
                            placeholder="Company"
                            label="Company"
                            required={false}
                            options={companyData.map((company) => ({
                              value: company.com_id,
                              label: company.firstname + " " + company.lastname,
                            }))}
                            onChange={onCompanySelect}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
          {selectedCompany && (
            <IonRow>
              <IonCol offsetLg="3" sizeLg="6" sizeXs="12">
                <IonGrid className="content-box">
                  <IonRow>
                    <IonCol size="12">
                      <h5>
                        {selectedCompany.firstname +
                          " " +
                          selectedCompany.lastname}
                      </h5>
                    </IonCol>
                  </IonRow>
                  <IonRow className="text-start">
                    <IonCol size="6">
                      <p>
                        <b>Mobile:</b>
                      </p>
                    </IonCol>
                    <IonCol>
                      <p>
                        <b>Email:</b> {selectedCompany.email}
                      </p>
                    </IonCol>
                  </IonRow>
                  {selectedCompany.contract_name &&
                    selectedCompany.contract_name.length > 0 && (
                      <IonRow>
                        <IonCol size="12" className="text-start">
                          {selectedCompany.contract_name.map(
                            (document, index) => (
                              <IonChip
                                onClick={() =>
                                  downloadDocument(document.contract_name)
                                }
                              >
                                <IonLabel>Click to Download Document</IonLabel>
                                <IonIcon
                                  color="danger"
                                  title="Delete document"
                                  icon={trash}
                                  onClick={(event) =>
                                    confirmDelete(event, document.contract_name)
                                  }
                                />
                              </IonChip>
                            )
                          )}
                        </IonCol>
                      </IonRow>
                    )}
                  <IonRow>
                    <IonCol size="12">
                      <IonItemDivider></IonItemDivider>
                    </IonCol>
                    <IonCol size="12">
                      <label
                        htmlFor="upload-button1"
                        className="upload-button-label"
                      >
                        <IonButton
                          className="custom-button centered file-upload"
                          color="secondary"
                          shape="round"
                          fill="outline"
                        >
                          <IonIcon icon={cloudUploadOutline}></IonIcon>{" "}
                          &nbsp;Upload
                        </IonButton>
                      </label>
                      <input
                        type="file"
                        name="cover_photo"
                        accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        id="upload-button1"
                        style={{ display: "none" }}
                        onChange={(e) => uploadContract(e)}
                      />
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => deleteContract(false)}
        header={"Confirm!"}
        message={"Are you sure to delete the document?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "primary",
            handler: () => {
              deleteContract(false);
            },
          },
          {
            text: "Delete",
            cssClass: "danger",
            handler: () => {
              deleteContract(true);
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default CompanyLegalFiles;
