import { IonContent, IonPage } from "@ionic/react";

import React from "react";

import { RouteComponentProps } from "react-router";
import CompanyProfile from "./companyprofile";
import { HeaderNav } from "../../../component";

interface Props
  extends RouteComponentProps<{
    companyID?: string;
  }> {}
var weblink: any = [];
const ProfileCompany: React.FC = () => {
  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <CompanyProfile />
      </IonContent>
    </IonPage>
  );
};
export default ProfileCompany;
