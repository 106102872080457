import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { modalController } from "@ionic/core";
import React from "react";
import { closeOutline } from "ionicons/icons";
import useMediaQuery from "../../hooks/mediaQuery";

interface MyChildProps {
  showModal: boolean;
  handleClick: Function;
}

// dynamic model
interface MyModalProps {}
export const MyModal: React.FC<MyModalProps> = () => {
  const openModel = async () => {
    const modal = await modalController.create({
      component: "my-modal",
      swipeToClose: true,
      keyboardClose: false,
      animated: true,
      cssClass: "modelStyle",
      presentingElement: await modalController.getTop(),
    });
    await modal.present();
  };

  const closeModal = () => {
    modalController.dismiss();
  };

  return (
    <IonPage>
      <IonContent></IonContent>
    </IonPage>
  );
};

// onclick model dialog
export const Body: React.FC<{
  id: string;
  onDismiss: () => void;
  onIncrement: () => void;
}> = ({ id, onDismiss }) => (
  <IonContent>
    <IonButton expand="block" onClick={() => onDismiss()}>
      Close
    </IonButton>
  </IonContent>
);
type modelInterface = {
  isOpen: boolean;
  onClose: (e) => void;
  title: string;
  subTitle?: string;
  required?: string | boolean;
  requestText?: string;
};

const ModalBody: React.FC<modelInterface> = ({
  isOpen,
  onClose,
  title,
  requestText,
  subTitle,
  required,
  children,
}) => {
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  return (
    <IonModal
      isOpen={isOpen}
      className="modelStyle custom-model"
      backdropDismiss={false}
      keyboardClose={false}
      animated={true}
      swipeToClose={true}
    >
      <IonHeader
        collapse="fade"
        no-shadow
        className={m4kQuery ? "container px-lg-3" : "modelheader px-lg-2"}
      >
        <IonToolbar className="custom-model-class py-2">
          <IonTitle className="modal-label p-0">
            {title}{" "}
            {!required && (
              <span className="required">* mandatory {requestText}</span>
            )}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton
              fill="outline"
              color="medium"
              className="round-button"
              shape="round"
              onClick={(e) => onClose(false)}
            >
              <IonIcon
                icon={closeOutline}
                slot="icon-only"
                className="closeoutline-mobile-version"
              />
            </IonButton>
          </IonButtons>

          <p>{subTitle}</p>
        </IonToolbar>
      </IonHeader>
      <IonContent className={m4kQuery ? "ion-no-padding" : ""}>
        <div className={m4kQuery ? "container" : ""}>{children}</div>
      </IonContent>
    </IonModal>
  );
};
type model = {
  isOpen: boolean;
  onClose: (e) => void;
  title: string;
  subTitle?: string;
};

const FilterModal: React.FC<model> = ({
  isOpen,
  onClose,
  title,
  subTitle,
  children,
}) => {
  return (
    <IonModal
      isOpen={isOpen}
      className="filtermodelStyle"
      backdropDismiss={false}
      keyboardClose={false}
      mode="md"
      animated={true}
      swipeToClose={true}
    >
      <IonHeader no-shadow className="px-4 pt-4" >
        <IonToolbar className="ion-align-items-center">
          <IonTitle className="modal-label"><h3>{title}</h3></IonTitle>
          <IonButtons slot="end">
            <IonButton
              fill="outline"
              color="medium"
              className="round-button"
              shape="round"
              onClick={(e) => onClose(false)}
            >
              <IonIcon
                icon={closeOutline}
                slot="icon-only"
                className="closeoutline-mobile-version"
              />
            </IonButton>
          </IonButtons>
          <p>{subTitle}</p>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
       {children}
      </IonContent>
    </IonModal>
  );
};

const ModalDeikaa: React.FC<any> = ({ isOpen, onClose, children }) => {
  return (
    <IonModal
      isOpen={isOpen}
      className="modelStyle model-bg"
      backdropDismiss={false}
      keyboardClose={false}
      animated={true}
      swipeToClose={true}
    >
      <IonContent className="ion-no-padding">
        <div className="container">{children}</div>
      </IonContent>
    </IonModal>
  );
};

const ChatMessageBox: React.FC<any> = ({
  showModal,
  setShowModal,
  children,
}) => {
  return (
    <>
      <IonModal
        isOpen={showModal}
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)}
        className="chat-Message-box"
      >
        {children}
      </IonModal>
    </>
  );
};

export { ModalBody, ModalDeikaa, FilterModal, ChatMessageBox };
