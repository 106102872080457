import { IonChip, IonLabel } from "@ionic/react";
import React from "react";
import ReactPlayer from "react-player";
import { ModalBody } from "./model";

type videoInterface = {
  videoLink: string | undefined;
};

const VideoModel = ({ videoLink }: videoInterface) => {
  const [modelOpen, setModal] = React.useState<boolean>(false);

  return (
    <div>
      <ModalBody
        title={""}
        isOpen={modelOpen}
        required={true}
        onClose={() => {
          setModal(false);
        }}
      >
        <ReactPlayer
          url={videoLink}
          width="100%"
          height="800px"
          controls
          playing
        />
      </ModalBody>

      <IonChip color="light" onClick={(e) => setModal(true)}>
        <IonLabel color="secondary">{videoLink}</IonLabel>
      </IonChip>
    </div>
  );
};

export default React.memo(VideoModel);
