import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
} from "@ionic/react";
import { briefcaseOutline, calendarOutline } from "ionicons/icons";
import moment from "moment";
import React from "react";
import ReactPlayer from "react-player";
import { AuthContext } from "../../../../../context/authContext";
import { getIndexValue, StringMap } from "../../../../../service";
import { decrypted } from "../../../../../service/axios";
import {
  Company,
  Contractor,
  DateStringMap,
  formatPhoneNumber,
  getIndexVisaTypeValue,
  Staff,
  TRM,
} from "../../../../../service/default";
import { ReadMoreRichText } from "../../../../../component";

const SummaryDetail = ({ personalData, ctrid, inputField }: any) => {
  // const userdata = getCookie({ key: "user" }).value;
  const { auth, checkLoginUsername } = React.useContext(AuthContext);
  const userdata = auth.data;
  const currentUser = auth.isLogin;
  const [expSlice, setexpslice] = React.useState(3);
  // const [showModal, setShowModal] = React.useState(false);
  // const [showToast, setShowToast] = React.useState(false);
  // const [toastMessage, setToastMessage] = React.useState("");
  // const [toastColor, setToastColor] = React.useState("danger");
  // const [sentvalue, setSentValue] = React.useState<any>();
  const [showLoading, setshowLoading] = React.useState(false);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [organizationData, setOrganizationData] = React.useState<any>(
    personalData !== undefined && personalData.organization
  );
  const [skilltool, setSkillTool] = React.useState<any>(0);
  const [langCount, setlanguage] = React.useState<any>(0);

  // function send() {
  //   const sentData = {
  //     com_id: userdata.com_id,
  //     ctr_id: personalData.ctr_id,
  //     unique_id: sentvalue.value,
  //   };
  //   Showinterest(sentData).then((value: any) => {
  //     if (value !== undefined && value.status === 200) {
  //       setshowLoading(false);
  //       setToastColor("success");
  //       setToastMessage(value.message !== undefined && value.message);
  //       setShowToast(true);
  //       setShowModal(false);
  //     } else {
  //       setshowLoading(false);
  //       setToastColor("danger");
  //       setToastMessage(
  //         value !== undefined
  //           ? "Server not responding, please try later"
  //           : value.message
  //       );
  //       setShowToast(true);
  //     }
  //   });
  // }

  React.useEffect(() => {
    const skill =
      personalData.highlightedskill_count !== 0
        ? personalData.highlightedskill_count
        : personalData.skill_count;
    const tool =
      personalData.tools_technologis !== undefined &&
      personalData.tools_technologis.beginner_tools.length +
        personalData.tools_technologis.expert_tools.length +
        personalData.tools_technologis.intermediate_tools.length;

    const language =
      personalData.language !== undefined &&
      personalData.language.beginner_language.length +
        personalData.language.intermediate_language.length +
        personalData.language.expert_language.length;
    if (!dataupdated) {
      setOrganizationData(
        personalData !== undefined && personalData.organization
      );
      setSkillTool(skill + tool);
      setlanguage(language);
      setDataupdated(true);
    }
  }, []);

  return (
    <div id="summary" ref={inputField}>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
        message={"Please Wait.."}
      />
      <IonItem lines="none" className="profile-header custome-item-color">
        {/* className="ion-text-wrap" */}
        <IonLabel className="ion-text-wrap">
          {userdata ? (
            userdata.user_type === Contractor ? (
              <>
                {checkLoginUsername("", personalData.username) ? (
                  <h1 className="h1-space">
                    {StringMap(personalData.firstname)}{" "}
                    {StringMap(personalData.lastname)}
                  </h1>
                ) : (
                  <h1 className="h1-space">
                    {StringMap(personalData.firstname) !== "NA" &&
                      StringMap(personalData.firstname).slice(0, 1) + "**** "}
                    {StringMap(personalData.lastname) !== "NA" &&
                      StringMap(personalData.lastname).slice(0, 1) + "****"}
                  </h1>
                )}
              </>
            ) : (
              <h1 className="h1-space">
                {StringMap(personalData.firstname)}{" "}
                {StringMap(personalData.lastname)}
              </h1>
            )
          ) : (
            <h1 className="h1-space">
              {StringMap(personalData.firstname) !== "NA" &&
                StringMap(personalData.firstname).slice(0, 1) + "**** "}
              {StringMap(personalData.lastname) !== "NA" &&
                StringMap(personalData.lastname).slice(0, 1) + "****"}
            </h1>
          )}
          <h4>
            {userdata ? (
              userdata.user_type === Contractor ? (
                <>
                  {checkLoginUsername("", personalData.username) ? (
                    <span>{StringMap(personalData.headline)}</span>
                  ) : (
                    <span>
                      {StringMap(personalData.headline) !== "NA" &&
                        StringMap(personalData.headline).replace(
                          StringMap(personalData.firstname),
                          StringMap(personalData.firstname) !== "NA" &&
                            StringMap(personalData.firstname).slice(0, 1) +
                              "**** "
                        )}
                    </span>
                  )}
                </>
              ) : (
                <span>{StringMap(personalData.headline)}</span>
              )
            ) : (
              <span>
                {StringMap(personalData.headline) !== "NA" &&
                  StringMap(personalData.headline).replace(
                    StringMap(personalData.firstname),
                    StringMap(personalData.firstname) !== "NA" &&
                      StringMap(personalData.firstname).slice(0, 1) + "**** "
                  )}
              </span>
            )}
          </h4>
        </IonLabel>
      </IonItem>
      <IonCard className="pad-style">
        <IonGrid className="ion-no-padding pt-4">
          <IonRow>
            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4">
              <div className="main-section vertical-line">
                <label>Experience</label>
                <div className="body-content-section">
                  <IonIcon
                    icon={briefcaseOutline}
                    className="custom-das-icon"
                  />
                  {StringMap(personalData.total_experience)}
                </div>
                <div className="vertical-line"></div>
              </div>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4">
              <div className="main-section vertical-line">
                <label>Compensation</label>
                <div className="body-content-section">
                  <IonIcon
                    icon={briefcaseOutline}
                    className="custom-das-icon"
                  />
                  {"₹ "}
                  {personalData.is_contract_job === "1"
                    ? StringMap(personalData.desire_compensation)
                    : personalData.is_permanent_job === "1"
                    ? StringMap(personalData.permanent_compensation)
                    : StringMap(personalData.desire_compensation)}{" "}
                  {personalData.is_contract_job === "1" ? "Hourly" : "Yearly"}
                  {/* {personalData.desire_compensation &&
                  personalData.desire_compensation !== "0"
                    ? "/" +
                      getIndexValue(
                        personalData.contract_compensation_type,
                        personalData.compensation_type_list,
                        "value",
                        "label"
                      )
                    : "NA"} */}
                </div>
                <div className="vertical-line"></div>
              </div>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4">
              <div className="main-section">
                <label>Start Date</label>
                <div className="body-content-section">
                  <IonIcon icon={calendarOutline} className="custom-das-icon" />
                  {personalData.is_contract_job === "1"
                    ? DateStringMap(personalData.contract_start_date)
                    : personalData.is_permanent_job === "1"
                    ? DateStringMap(personalData.permanent_start_date)
                    : StringMap(personalData.days)}
                </div>
                <div className="vertical-line"></div>
              </div>
            </IonCol>
            <IonCol sizeLg="12" sizeXs="12">
              <hr />
            </IonCol>
            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4">
              <div className="main-section vertical-line">
                <label>Qualifications</label>
                <div className="body-content-section">
                  <IonIcon
                    icon={briefcaseOutline}
                    className="custom-das-icon"
                  />
                  {personalData.educations !== undefined &&
                  personalData.educations.length === 0
                    ? "NA"
                    : ""}
                  {personalData.educations &&
                    personalData.educations.map(
                      (c: any, i: number) =>
                        i === 0 && (
                          <p className="text-in-line" key={i}>
                            {c.degree}
                          </p>
                        )
                    )}
                </div>
                {personalData !== undefined && personalData.certifications && (
                  <p className="small-text">
                    {personalData.certifications.length !== 0
                      ? "+" +
                        personalData.certifications.length +
                        " certification"
                      : ""}
                  </p>
                )}
                <div className="vertical-line"></div>
              </div>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4">
              <div className="main-section vertical-line">
                <label>Competencies</label>
                <div className="body-content-section">
                  <IonIcon
                    icon={briefcaseOutline}
                    className="custom-das-icon"
                  />
                  <p>
                    {skilltool}
                    <span> skills & tools</span>
                  </p>
                </div>
                <p className="small-text">
                  {langCount === 0 ? "" : "+" + langCount + " spoken languages"}
                </p>
                <div className="vertical-line"></div>
              </div>
            </IonCol>
            {/* <IonCol sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="4">
              <div className="main-section">
                <label>US work permit</label>
                <div className="body-content-section">
                  <IonIcon
                    icon={briefcaseOutline}
                    className="custom-das-icon"
                  />
                  {personalData.eligible_work_permit &&
                  personalData.eligible_work_permit !== "0"
                    ? getIndexValue(
                        personalData.eligible_work_permit,
                        personalData.eligible_work_permit_list,
                        "value",
                        "label"
                      )
                    : "NA"}{" "}
                  {currentUser && personalData.visa_type
                    ? getIndexVisaTypeValue(
                        personalData.visa_type[0].visatype,
                        personalData.visa_type_list,
                        "value",
                        "label"
                      )
                    : null}
                </div>
                <div className="vertical-line"></div>
              </div>
            </IonCol> */}
          </IonRow>

          <IonRow>
            {/* about section */}
            <IonCol sizeXs="12" sizeLg="12">
              <hr className="dot" />
            </IonCol>

            <IonCol sizeLg="12" sizeXs="12">
              <ReadMoreRichText length={250}>
                {personalData.about}
              </ReadMoreRichText>
              {/* <p dangerouslySetInnerHTML={{ __html: personalData.about }}></p> */}

              {/* <ReadMore>{getText(personalData.about)}</ReadMore> */}
            </IonCol>

            <IonCol sizeXs="12" sizeLg="12">
              <hr className="dot" />
            </IonCol>
            {/* timeline section */}
            <IonCol sizeXs="12" sizeLg="12">
              {organizationData && (
                <div className="custome-timeline">
                  <ul className="timeline">
                    {organizationData
                      .sort((a, b) => b.currently_working - a.currently_working)
                      .slice(0, expSlice)
                      .map((item: any, i: number) => (
                        <li key={i}>
                          <IonGrid className="ion-no-padding">
                            <IonRow>
                              <IonCol sizeXs="12" sizeLg="4">
                                {item.start_date !== "0000-00-00" &&
                                  moment(item.start_date).format("MMM YYYY") +
                                    " - "}
                                {item.currently_working !== "0" ? (
                                  <span className="highlightContent">
                                    present
                                  </span>
                                ) : (
                                  item.end_date !== "0000-00-00" &&
                                  moment(item.end_date).format("MMM YYYY")
                                )}
                              </IonCol>
                              <IonCol sizeXs="12" sizeLg="8">
                                <h6>
                                  <b>{item.company_name}</b>
                                </h6>
                                <p>{item.contractor_role}</p>

                                <hr />
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </li>
                      ))}
                  </ul>

                  {personalData.organization.length &&
                  personalData.organization.length !== expSlice &&
                  personalData.organization.length >= expSlice ? (
                    <IonButton
                      slot="end"
                      fill="clear"
                      color="secondary"
                      type="button"
                      size="small"
                      className="remove-shadow lowercase"
                      onClick={() => setexpslice(expSlice + 3)}
                    >
                      {personalData.organization.length - expSlice} more
                      experience{personalData.organization.length > 3 && "(s)"}
                    </IonButton>
                  ) : (
                    personalData.organization.length > 3 &&
                    personalData.organization.length <= expSlice && (
                      <IonButton
                        slot="end"
                        fill="clear"
                        color="secondary"
                        type="button"
                        size="small"
                        className="remove-shadow lowercase"
                        onClick={() => setexpslice(3)}
                      >
                        less experience
                        {personalData.organization.length > 3 && "(s)"}
                      </IonButton>
                    )
                  )}
                </div>
              )}

              <IonCol sizeXs="12" sizeLg="12">
                <hr className="dot" />
              </IonCol>

              {userdata && decrypted(personalData.video) && (
                <IonCol sizeLg="12" sizeXs="12">
                  <ReactPlayer
                    width="auto"
                    height="380px"
                    controls
                    url={decrypted(personalData.video)}
                  />
                </IonCol>
              )}
            </IonCol>
          </IonRow>

          {userdata &&
          (userdata.user_type === TRM ||
            userdata.user_type === Company ||
            userdata.user_type === Staff) ? (
            <IonRow>
              <IonCol sizeXs="12" sizeLg="12">
                <hr />
              </IonCol>
              <IonCol sizeXs="12" sizeLg="12">
                <h4>About</h4>
              </IonCol>

              <IonCol sizeLg="4" sizeXs="12">
                <label>Contact Number</label>
              </IonCol>

              <IonCol sizeLg="8" sizeXs="12">
                {"+91 "}
                {formatPhoneNumber(decrypted(personalData.mobile))}
                <hr />
              </IonCol>

              <IonCol sizeLg="4" sizeXs="12">
                <label>Email</label>
              </IonCol>

              <IonCol sizeLg="8" sizeXs="12">
                {StringMap(decrypted(personalData.email))}
              </IonCol>
            </IonRow>
          ) : null}
        </IonGrid>
      </IonCard>
    </div>
  );
};

export default React.memo(SummaryDetail);
