import { getMethod, postMethod } from "../../../service";

export function GetallQuestion(id: any) {
  return getMethod("Getallquestion/" + id);
}

export function GetQuestionbyType(id: any) {
  return getMethod("Getallgroupquestion/" + id);
}

export function saveQuestion(formData: any) {
  return postMethod("Savequestionresponse", formData);
}

export function saveRemarkApi(formData: any) {
  return postMethod("Referenceremark", formData);
}

export function saveVerify(formData: any) {
  return postMethod("Verify_reference", formData);
}

export function saveVerifyreference(formData: any) {
  return postMethod("Verifyreference", formData);
}

export function GetReferenceData(id: any) {
  return getMethod("Getreferencectrdetails/" + id);
}

// https://www.bgr.ionidea.com/jng.bac/Coding/index.php/Verify_reference
// Payload
// {
// unique_id
// mail_id
// how_do_you_know
// org_worked_together
// ctr_role
// ref_role
// duration_worked_together
// }
// {
// "unique_id":""
// "ctr_rqid":"1",  // question _id
//  "rating_answer":" ",
//  "text_answer":" "
// }
