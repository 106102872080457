import {
  IonButton,
  IonCheckbox,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import { closeCircleOutline, options } from "ionicons/icons";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router";
import {
  DatePick,
  FilterModal,
  LocationSearch,
  PaginationScreen,
} from "../../../component";
import { AuthContext } from "../../../context/authContext";
import { NumberStringMap } from "../../../service/default";
import { JobFilter } from "../Service/api";
import { ListJobCard } from "./listjob";

const Open = ({
  callApi,
  detailPage,
  modelFunction,
  // keyOption,
  refreshData,
  comid,
  closemodelFun,
}: any) => {
  const { auth, setAuthCompanyJobCount } = React.useContext(AuthContext);
  const { companyjob } = auth;
  const match = useParams();
  const [jobdetail, setJobDetail] = React.useState<any[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(30);
  // const [totalPage, setTotalPage] = React.useState(0);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [filtermodelOpen, setFiltermodelOpen] = React.useState<boolean>(false);
  const [locationReset, setLocationRest] = React.useState(false);
  const [locationData, setLocation] = React.useState("");
  const [locationGroupData, setLocationGroup] = React.useState<any[]>([]);
  // const [postedData, setPostedby] = React.useState<any>([]);
  // const [postedBykey, setPostedbykey] = React.useState("");
  const [engagement0, setEngagement0] = React.useState<any>(false);
  const [engagement1, setEngagement1] = React.useState<any>(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const { control, handleSubmit, setValue, watch } = useForm({ mode: "all" });
  // //   list
  // const [workPermit, setWorkPermit] = React.useState([]);
  const [paymentDurationType, setPaymentDurationType] = React.useState([]);
  const [workFromhomeType, setWorkFromhomeType] = React.useState([]);
  const [currencyType, setCurrencyType] = React.useState([]);

  function selectLocation(e: any) {
    setLocation(e);
  }

  function resetFields(key: any, value: any) {
    if (key) setValue(key, value);
  }

  const addLocation = (da: any) => {
    if (da) {
      const trimmed = da.trim();
      if (trimmed && !locationGroupData.includes(trimmed)) {
        setLocationGroup((locationGroupData) => [...locationGroupData, da]);
      }
      setLocation("");
      resetFields("work_location", "");
      setLocationRest(!locationReset);
    }
  };

  // const addpostedby = (da: any) => {
  //   if (da) {
  //     const trimmed = da.trim();
  //     if (trimmed && !postedData.includes(trimmed)) {
  //       setPostedby((postedData) => [...postedData, da]);
  //     }
  //     setPostedbykey("");
  //   }
  // };

  function addressLatlon(e: any) {
    return e;
  }

  const resetAllData = (e) => {
    if (e) {
      setEngagement0(false);
      setEngagement1(false);
      resetFields("start_date", "");
      resetFields("end_date", "");
      resetFields("posted_after", "");
      resetFields("posted_before", "");
      setLocation("");
      setLocationGroup([]);
      resetFields("work_location", "");
      setLocationRest(!locationReset);
      resetFields("posted_by", "");
    }
  };

  // search data
  // const searchData = () => {
  //   // getDetail("1", currentPage);
  // };
  // modal data

  // pagination
  const handlePageClick = (e: any) => {
    setCurrentPage(e);
    filterData({ page: e });
  };

  const filterData = (val) => {
    if (val) {
      setFiltermodelOpen(false);
      setShowLoading(true);
      val.page = val.page ? val.page : currentPage;
      val.com_id = comid ? comid : match["companyID"];
      val.work_location = locationGroupData
        ? locationGroupData
        : [val.work_location];
      val.status = "1";
      const engagementType = [] as any;
      if (engagement0) engagementType.push("0");
      if (engagement1) engagementType.push("1");
      val.engagement_type = engagementType;
      resetAllData("e");
      JobFilter(val).then((value) => {
        if (value !== undefined && value.status === 200) {
          // keyOption("1", value);
          value.open_job_count = value.totalItemsCount;
          callApi(value);
          addCount(value);
          setPerPage(value.itemsCountPerPage);
          // setTotalPage(value.totalpage);
          setTotalCount(value.totalItemsCount);
          setJobDetail(value.data);
          setTotalPageItem(value.data.length);
          // setWorkPermit(value.company_work_permit);
          setPaymentDurationType(value.payment_duration_type);
          setWorkFromhomeType(value.workfromhome_type);
          setCurrencyType(value.currency);
          setShowLoading(false);
        } else {
          callApi(value);
          addCount(value);
          // setWorkPermit(value !== undefined && value.company_work_permit);
          setPaymentDurationType(
            value !== undefined && value.payment_duration_type
          );
          setTotalPageItem(0);
          setWorkFromhomeType(value !== undefined && value.workfromhome_type);
          setCurrencyType(value !== undefined && value.currency);
          setCurrentPage(1);
          setPerPage(30);
          // setTotalPage(0);
          setTotalCount(0);
          setToastColor("danger");
          setToastMessage(value !== undefined && value.message);
          setShowToast(true);
          setJobDetail([]);
          setShowLoading(false);
        }
      });
    }
  };

  const addCount = (data) => {
    setAuthCompanyJobCount({
      companyName: data.company_name ?? "",
      open_job_count: NumberStringMap(data.open_job_count),
      close_job_count: NumberStringMap(data.close_job_count),
      draft_job_count: NumberStringMap(data.draft_job_count),
      total_count: NumberStringMap(
        data.open_job_count + data.close_job_count + data.draft_job_count
      ),
    });
  };

  const updateState = (data, screenName) => {
    if (data.status === "1") {
      const jobs: any = [];
      jobdetail &&
        jobdetail.map((e) => {
          if (e.unique_id === data.unique_id) {
            e.department = data.department;
            e.end_date = data.end_date;
            e.engagement_type = data.duration_type;
            e.hiring_manager = data.hiring_manager;
            e.job_title = data.title;
            e.overall_exp = data.overall_exp;
            e.pay_duration = data.pay_duration;
            e.pay_type = data.pay_type;
            e.payment = data.payment;
            e.start_date = data.start_date;
            e.wfh_type = data.wfh_type;
            e.work_location = data.work_location;
            e.additional_skills = data.additional_skills;
            e.mandatory_skills = data.mandatory_skills;
            e.openings = data.openings;
            e.posted_by = data.posted_by;
            e.Glove_team_manager = data.Glove_team_manager;
            e.description = data.description;
            e.US_work_authorization = workAuth(data.us_work_authrizations);
            jobs.push(e);
          } else {
            jobs.push(e);
          }
        });
      setJobDetail(jobs);
    } else {
      const result =
        jobdetail && jobdetail.filter((e) => e.unique_id !== data.unique_id);
      setJobDetail(result);
      setTotalCount(result.length);
      setTotalPageItem(result.length);
      addCount({
        open_job_count: result.length,
        close_job_count:
          data.status === "0"
            ? companyjob.close_job_count + 1
            : companyjob.close_job_count,
        draft_job_count:
          data.status === "2"
            ? companyjob.draft_job_count + 1
            : companyjob.draft_job_count,
        total_count: companyjob.total_count,
      });
    }
  };
  const workAuth = (data) => {
    const work: any[] = [];
    if (data.length !== 0 && data) {
      data.map((e) => {
        work.push({ us_work_authrizations: e });
      });
    }
    return work;
  };

  React.useEffect(() => {
    setShowLoading(true);
    let isMounted = true;
    try {
      filterData({ engagement_type: [], page: currentPage, work_location: [] });
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
          setJobDetail([]);
        }
      }
    } finally {
      // isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, [refreshData, !refreshData]);

  return (
    <div id="1">
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      {!showLoading && (
        <>
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="header-content-flex ion-justify-content-between ion-align-items-center filter-option custom-sort-section">
                  <IonLabel>
                    Showing{" "}
                    {totalCount !== 0
                      ? currentPage === 1
                        ? 1
                        : (currentPage - 1) * perPage + 1
                      : 0}
                    {" - "}
                    {currentPage === 1
                      ? totalPageItem
                      : (currentPage - 1) * perPage + totalPageItem > totalCount
                      ? totalCount
                      : (currentPage - 1) * perPage + totalPageItem}
                    {" of " + totalCount}
                  </IonLabel>
                  <div className="sort-input-section ion-align-items-center">
                    {/* <div className="search-input">
            <IonInput
              value={searchKey}
              placeholder="Search open jobs"
              onIonChange={(e: any) => setSearchKey(e.detail.value)}
            />

            <div className="search-icon" onClick={() => searchData()}>
              <img src="assets/image/company/search.svg" />
            </div>
          </div> */}
                    {/* <div className="input-group sort-group">
            <span className="input-group-text" id="basic-addon1">
              Sort:
            </span>
            <select className="form-select sort-input">
              <option value="desc">Latest</option>
              <option value="asc">A-Z</option>
            </select>
          </div> */}
                    {/* <div
                className="filter-icon"
                onClick={(e) => {
                  setFiltermodelOpen(true);
                }}
              >
                <img alt="" src="assets/image/company/tune.svg" />
              </div> */}
                    <div className="filter-curser-icon">
                      <IonButton
                        fill="outline"
                        color="medium"
                        className="roundButton"
                        onClick={() => setFiltermodelOpen(true)}
                      >
                        <IonIcon icon={options} slot="icon-only"></IonIcon>
                      </IonButton>
                    </div>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* list card */}
          <ListJobCard
            comid={comid ?? match["companyID"]}
            jobdetail={jobdetail}
            screenName="1"
            detailPage={detailPage}
            modelFunction={modelFunction}
            workFromhomeType={workFromhomeType}
            currencyType={currencyType}
            paymentDurationType={paymentDurationType}
            closeModal={(e) => {
              closemodelFun(false);
              // window.location.reload();
            }}
            UpdateState={(data) => updateState(data, "1")}
          />
          {/* end card */}
          {/* pagination */}
          <div className="m-auto py-3">
            {totalCount > 30 && (
              <PaginationScreen
                onChangeData={(e: any) => handlePageClick(e)}
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
              />
            )}
          </div>
          {/* end pagination */}
          {totalCount === 0 && (
            <div className="nojob-section text-center">
              <img
                className="py-3"
                alt="jng"
                src="assets/image/job_backGround.svg"
              />
              <h1>No jobs to show!</h1>
              <p>
                List job opportunities with your organization for FREE or engage
                our whiteglove services to hire the best talent out there.
              </p>
            </div>
          )}
          {/* filter form modal */}
          <div>
            <FilterModal
              title={"Filter jobs"}
              isOpen={filtermodelOpen}
              onClose={(e) => {
                setFiltermodelOpen(false);
              }}
            >
              <form onSubmit={handleSubmit(filterData)}>
                <IonGrid>
                  <IonRow>
                    <IonCol sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12">
                      <div className="input-div">
                        <IonLabel position="stacked">Job start</IonLabel>
                        <Controller
                          control={control}
                          name="start_date"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePick value={value} ChangeEvent={onChange} />
                          )}
                        />
                        <IonText
                          className="custom-content right-content-2 text-orange text-pointer"
                          onClick={(e) => {
                            resetFields("start_date", "");
                          }}
                        >
                          Reset
                        </IonText>
                      </div>
                    </IonCol>
                    <IonCol sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12">
                      <div className="input-div">
                        <IonLabel position="stacked">Job end</IonLabel>
                        <Controller
                          control={control}
                          name="end_date"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePick
                              value={value}
                              ChangeEvent={onChange}
                              minDate={watch("start_date")}
                            />
                          )}
                        />
                        <IonText
                          className="custom-content right-content-2 text-orange text-pointer"
                          onClick={(e) => {
                            resetFields("end_date", "");
                          }}
                        >
                          Reset
                        </IonText>
                      </div>
                    </IonCol>
                    <IonCol sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12">
                      <div className="input-div">
                        <IonLabel position="stacked">Posted after</IonLabel>
                        <Controller
                          control={control}
                          name="posted_after"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePick value={value} ChangeEvent={onChange} />
                          )}
                        />
                        <IonText
                          className="custom-content right-content-2 text-orange text-pointer"
                          onClick={(e) => {
                            // setPostedby([]);
                            resetFields("posted_after", "");
                          }}
                        >
                          Reset
                        </IonText>
                      </div>
                    </IonCol>
                    <IonCol sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12">
                      <div className="input-div">
                        <IonLabel position="stacked">Posted before</IonLabel>
                        <Controller
                          control={control}
                          name="posted_before"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <DatePick
                              value={value}
                              ChangeEvent={onChange}
                              minDate={watch("posted_after")}
                            />
                          )}
                        />
                        <IonText
                          className="custom-content right-content-2 text-orange text-pointer"
                          onClick={(e) => {
                            resetFields("posted_before", "");
                          }}
                        >
                          Reset
                        </IonText>
                      </div>
                    </IonCol>
                    <IonCol sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12">
                      <div className="locationInput">
                        <IonLabel position="stacked">Locations</IonLabel>
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <LocationSearch
                              valueKey={value ?? ""}
                              onSelectValue={(e: any) => selectLocation(e)}
                              latLong={addressLatlon}
                              onChangeValue={(e: any) => selectLocation(e)}
                              refresh={locationReset}
                            />
                          )}
                          name="work_location"
                          control={control}
                        />
                        <div
                          className="custom-groupInput-label-icon"
                          onClick={(e) => addLocation(locationData)}
                        >
                          <img alt="" src="assets/image/company/add.svg" />
                        </div>
                      </div>

                      {locationGroupData.length > 0 &&
                        locationGroupData.map((a: any, index: number) => (
                          <IonChip color="orangeblack" className="" key={index}>
                            <IonLabel className="ion-text-wrap">{a}</IonLabel>
                            <IonIcon
                              icon={closeCircleOutline}
                              onClick={() =>
                                setLocationGroup(
                                  locationGroupData.filter((item) => item !== a)
                                )
                              }
                            />
                          </IonChip>
                        ))}
                    </IonCol>
                    <IonCol sizeLg="4" sizeMd="6" sizeSm="12" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">Posted by</IonLabel>
                        <Controller
                          control={control}
                          name="posted_by"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              type="text"
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                        <IonText
                          className="custom-content right-content-2 text-orange text-pointer"
                          onClick={(e) => {
                            resetFields("posted_by", "");
                          }}
                        >
                          Reset
                        </IonText>
                        {/* <div
                      className="custom-groupInput-label-icon"
                      onClick={() => addpostedby(postedBykey)}
                    >
                      <img src="assets/image/company/add.svg" />
                    </div> */}
                      </IonItem>
                    </IonCol>

                    <IonCol sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                      <div>
                        <IonLabel position="stacked">Engagement</IonLabel>
                        <Controller
                          control={control}
                          name="engagement_type"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonGrid>
                              <IonRow>
                                <IonCol
                                  sizeLg="4"
                                  sizeMd="6"
                                  sizeSm="12"
                                  sizeXs="12"
                                >
                                  <IonItem lines="none">
                                    <IonCheckbox
                                      color="success"
                                      slot="start"
                                      checked={engagement0}
                                      onIonChange={(e: any) =>
                                        setEngagement0(e.detail.checked)
                                      }
                                    />
                                    <IonLabel>Contract</IonLabel>
                                  </IonItem>
                                </IonCol>
                                <IonCol
                                  sizeLg="4"
                                  sizeMd="6"
                                  sizeSm="12"
                                  sizeXs="12"
                                >
                                  <IonItem lines="none">
                                    <IonCheckbox
                                      color="success"
                                      slot="start"
                                      checked={engagement1}
                                      onIonChange={(e: any) =>
                                        setEngagement1(e.detail.checked)
                                      }
                                    />
                                    <IonLabel>Permanent</IonLabel>
                                  </IonItem>
                                </IonCol>
                              </IonRow>
                            </IonGrid>
                          )}
                        />
                      </div>
                    </IonCol>

                    <IonCol size="12">
                      <IonButton
                        shape="round"
                        fill="clear"
                        color="orange"
                        className=" remove-shadow capitalizes"
                        type="button"
                        onClick={(e) => {
                          resetAllData(e);
                        }}
                      >
                        Reset All Filters
                      </IonButton>
                      <IonButton
                        type="submit"
                        shape="round"
                        fill="solid"
                        color="orange"
                        className="remove-shadow capitalizes"
                      >
                        Filter Jobs
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </form>
            </FilterModal>
          </div>{" "}
        </>
      )}
    </div>
  );
};
export default React.memo(Open);
