import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonLoading,
  IonRow,
  IonToggle,
} from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { ContractProfUpload, apiCallBack } from "../../../../../service";
import { UploadResume } from "./uploadResume";
import { ModalBody } from "../../../../../component";
import About from "./About";
import NameLocation from "./NameLocation";

export default function Personal({
  personalData,
  profileImg,
  coverImg,
  allowsearch,
  folderKeyname,
  dataReload,
}: any) {
  const history = useHistory();
  const [showLoading, setshowLoading] = React.useState(false);
  const [modelOpen, setModals] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [dataupdated, setDataupdated] = React.useState(false);
  React.useEffect(() => {
    if (!dataupdated) {
      setChecked(personalData.allow_search !== "1" ? false : true);
      setDataupdated(true);
    }
    setModals(false);
  }, [personalData]);

  function postProfUpload() {
    if (dataupdated) {
      setshowLoading(true);
      const user = personalData;
      const payload = {
        ctr_id: user.ctr_id,
        profile_photo: profileImg,
        cover_photo: coverImg,
        allow_search: checked ? "0" : "1",
      };
      ContractProfUpload(payload)
        .then((e) => {
          if (apiCallBack(e)) {
            setshowLoading(false);
          } else {
            setshowLoading(false);
          }
        })
        .catch((err) => {
          setshowLoading(false);
        });
    }
  }

  return (
    <div id="personal">
      <ModalBody
        title={""}
        subTitle={""}
        isOpen={modelOpen}
        onClose={(e) => setModals(false)}
      >
        <div className="qp-upload">
          <UploadResume
            folderKey={folderKeyname}
            closeModal={(e) => {
              setModals(false);
              dataReload();
            }}
          />
        </div>
      </ModalBody>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
      />
      <IonGrid className="ion-no-padding px-1 py-2">
        <IonRow>
          <IonCol sizeLg="5" sizeXs="12" className="vertical-align-center">
            <h2>
              Profile <small className="required">* mandatory</small>
            </h2>
          </IonCol>

          <IonCol sizeLg="4" className="ion-text-end" sizeXs="12">
            <IonButton
              fill="solid"
              color="secondary"
              shape="round"
              className="custom-style-button remove-shadow capitalizes"
              onClick={(e) => {
                setModals(true);
              }}
            >
              Update with Resume
            </IonButton>
          </IonCol>

          <IonCol sizeLg="3" className="ion-text-end" sizeXs="12">
            <IonButton
              fill="outline"
              color="secondary"
              shape="round"
              className="custom-style-button remove-shadow capitalizes"
              onClick={() => {
                window.open("/publicview/" + personalData.public_id, "_blank");
              }}
            >
              View as Recruiter
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid className="px-0">
        <IonRow>
          <IonCol sizeLg="7" sizeXs="12" className="vertical-align-center">
            <h3>Personal & career</h3>
          </IonCol>
          <IonCol sizeLg="5" sizeXs="12">
            <IonItem lines="none" className="custome-item-color">
              {checked ? (
                <div>
                  Active <small>(Profile is visible to all)</small>
                </div>
              ) : (
                <div>
                  Inactive <small>(Profile is private)</small>
                </div>
              )}
              <IonToggle
                slot="end"
                mode="md"
                checked={checked}
                onIonChange={(e: any) => {
                  setChecked(e.detail.checked);
                  postProfUpload();
                }}
              />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonCard className="pad-style">
        <NameLocation
          ctrdata={personalData.ctr_id}
          firstname={personalData.firstname}
          currentLocation={personalData.address}
          country={personalData.country}
          state={personalData.state}
          city={personalData.city}
          lastname={personalData.lastname}
          zip={personalData.zip}
          contact={personalData.mobile}
          countrycode={personalData.country_code}
        />
        <About
          ctrdata={personalData.ctr_id}
          Userrole={personalData.jobrole}
          Profileheadline={personalData.headline}
          Summary={personalData.about}
          videourl={personalData.video}
        />
      </IonCard>
    </div>
  );
}
