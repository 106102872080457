import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import React, { useState } from "react";
import {
  Getcountry,
  GetState,
  GetCities,
  Savenameandlocation,
} from "../../Service/api";
import {
  arrayOptionData,
  arrayOptionValueData,
} from "../../../../service";

function NameLocationForm(
  {
    ctrid,
    stateUpdateData,
    closeModal,
    firstnameData,
    mobileData,
    countrycodeData,
    currentLocationData,
    countryData,
    stateData,
    cityData,
    lastnameData,
    zipData,
    datareset,
    modalSet,
  },
  any
) {
  const [uploading, setUploading] = React.useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [dataupdated, setDataupdated] = React.useState(datareset);
  const [showToast, setShowToast] = React.useState(false);
  const [searchcity, setSearchCity] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);
  const [country, setcountry] = useState<any>([]);
  const [selectedStates, setStates] = useState<any>([]);
  const [Cities, setCities] = useState<any>([]);

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  // city state and country
  const getcountry = () => {
    Getcountry().then((ele) => {
      setcountry(ele.data);
    });
  };
  const getState = (e: any) => {
    if (e !== undefined && e) {
      let obj = country.find(
        (st) => st.country_name.toLowerCase() === e.toLowerCase()
      );
      if (obj) {
        var contryid = obj !== undefined && obj.country_id;
        let data = {
          country_id: contryid,
        };
        GetState(data).then((ele) => {
          if (ele !== undefined) {
            setStates(ele.data);
            if (searchcity) {
              GetCitiesData(stateData);
              setSearchCity(false);
              setShowLoading(false);
            }
          } else {
            setShowLoading(false);
          }
        });
      }
    }
  };
  function GetCitiesData(e) {
    if (e !== undefined && e) {
      if (selectedStates !== undefined) {
        let obj = selectedStates.find(
          (st) => st.state_name.toLowerCase() === e.toLowerCase()
        );
        if (obj) {
          var citiesid = obj !== undefined && obj.state_id;
          let data = {
            state_id: citiesid,
          };
          GetCities(data).then((ele) => {
            if (ele !== undefined) {
              setCities(ele.data);
              setShowLoading(false);
            } else {
              setShowLoading(false);
            }
          });
        }
      }
    }
  }
  // end

  React.useEffect(() => {
    // if (!dataupdated) {
    setValue("firstname", firstnameData);
    setValue("lastname", lastnameData);
    setValue("country", [
      { label: countryData ?? "", value: countryData ?? "" },
    ]);
    setValue("state", [{ label: stateData ?? "", value: stateData ?? "" }]);
    setValue("city", [{ label: cityData ?? "", value: cityData ?? "" }]);
    setValue("zip", zipData);
    setValue("mobile", mobileData);
    setValue("country_code", '+91');
    getcountry();

    setTimeout(() => {
      if (countryData) {
        getState(countryData ?? "");
      }
    }, 2000);

    setTimeout(() => {
      if (stateData) {
        GetCitiesData(stateData ?? "");
      }
    }, 4000);

    setSearchCity(true);
    //   setDataupdated(true);
    // }-
  }, [modalSet]);

  // submit
  const saveNameLocation = (values: any) => {
    setShowLoading(true);
    values.ctr_id = ctrid;
    values.country = arrayOptionValueData(values.country, "label");
    values.state = arrayOptionValueData(values.state, "label");
    values.city = arrayOptionValueData(values.city, "label");

    setUploading(true);
    Savenameandlocation(values).then((value) => {
      if (value !== undefined && value.status === 200) {
        stateUpdateData(values);
        setToastColor("success");
        setToastMessage("Your Address added successfully");
        setShowToast(true);
        setUploading(false);
        setDataupdated(false);
        setShowLoading(false);
        closeModal(false);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value !== undefined && value.message || value !== undefined && value.data);
        setShowToast(true);
        setUploading(false);
      }
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveNameLocation)}>
            <IonRow>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    First name <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="firstname"
                    rules={{
                      required: "First name is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={32}
                        placeholder="Enter the firstname"
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("firstname")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Last name <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="lastname"
                    rules={{
                      required: "Last name is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={32}
                        placeholder="Enter the lastname"
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("lastname")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="locationInput">
                  <IonLabel position="stacked">
                    Country <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Typeahead
                        allowNew
                        id="custom-selections-input"
                        newSelectionPrefix="Add a new country: "
                        options={arrayOptionData(
                          country,
                          "country_name",
                          "country_name"
                        )}
                        onChange={(e: any) => {
                          setValue("country", e);
                        }}
                        onBlur={(e: any) => {
                          if (e && value.length > 0) {
                            getState(value[0].value);
                          }
                        }}
                        selected={value}
                        placeholder="country"
                      />
                    )}
                    control={control}
                    name="country"
                    rules={{
                      required: "Country is required",
                    }}
                  />
                </div>
                {showError("country")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Zip code
                    {/* <span className="required">*</span> */}
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={6}
                        placeholder="Enter the zip code"
                      />
                    )}
                    control={control}
                    name="zip"
                    rules={{
                      // required: "zip code is required",
                      pattern: {
                        value: /[0-9]/,
                        message: "invalid zip code",
                      },
                      minLength: {
                        value: 4,
                        message: "Invalid zip code",
                      },
                    }}
                  />
                 
                </IonItem>
                {showError("zip")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="locationInput">
                  <IonLabel position="stacked">
                    State <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Typeahead
                        allowNew
                        id="custom-selections-input"
                        newSelectionPrefix="Add a new state: "
                        options={arrayOptionData(
                          selectedStates,
                          "state_name",
                          "state_name"
                        )}
                        onChange={(e: any) => {
                          setValue("state", e);
                        }}
                        onBlur={(e: any) => {
                          if (e && value.length > 0) {
                            GetCitiesData(value[0].value);
                          }
                        }}
                        selected={value}
                        placeholder="state"
                      />
                    )}
                    control={control}
                    name="state"
                    rules={{
                      required: "State is required",
                    }}
                  />
                </div>
                {showError("state")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="locationInput">
                  <IonLabel position="stacked">
                    City <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Typeahead
                        allowNew
                        id="custom-selections-input"
                        newSelectionPrefix="Add a new city: "
                        options={arrayOptionData(
                          Cities,
                          "city_name",
                          "city_name"
                        )}
                        onChange={onChange}
                        selected={value}
                        placeholder="city"
                      />
                    )}
                    control={control}
                    name="city"
                    rules={{
                      required: "City is required",
                    }}
                  />
                </div>
                {showError("city")}
              </IonCol>

              <IonCol sizeLg="2" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Country code <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        // maxlength={10}
                        placeholder="ex: +91 xxx"
                      />
                      // <select
                      //   onBlur={onBlur}
                      //   onChange={onChange}
                      //   value={value}
                      //   placeholder="Select"
                      // >
                      //   <option value="">Select</option>
                      //   <option value="+91">+91</option>
                      // </select>
                    )}
                    control={control}
                    name="country_code"
                    rules={{
                      required: "Country code is required",
                    }}
                  />
                </IonItem>
                {showError("country_code")}
              </IonCol>
              <IonCol sizeLg="10" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Phone <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        maxlength={10}
                        placeholder=""
                      />
                    )}
                    control={control}
                    name="mobile"
                    rules={{
                      required: "Phone no is required",
                      pattern: {
                        value: /[0-9]/,
                        message: "invalid phone number",
                      },
                    }}
                  />
                </IonItem>
                {showError("mobile")}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <div className="text-right">
                  <IonButton
                    slot="end"
                    shape="round"
                    fill="solid"
                    color="secondary"
                    className="remove-shadow capitalizes"
                    type="submit"
                  >
                    {uploading ? "Uploading" : "Save"}
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
}

export default NameLocationForm;
