import {
  IonChip,
  IonLabel,
  IonIcon,
  IonButton,
  IonItem,
  IonCol,
  IonGrid,
  IonRow,
  IonLoading,
  IonToast,
} from "@ionic/react";
import { addOutline, close, pencilOutline, watch } from "ionicons/icons";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ModalBody } from "../../../component";
import {
  arrayOptionData,
  arrayOptionNameData,
  getIndexValue,
  postMethod,
  RemoveDuplicates,
} from "../../../service";

interface selectedSkill {
  skill_id: string;
  coms_id: string;
  skill_name: string;
}

interface allSkill {
  skill_id: string;
  skill_name: string;
}
type domineInterface = {
  title: string;
  array: selectedSkill[] | undefined;
  allSkill: allSkill[] | undefined;
  removeSkill: (data) => void;
  comid: string;
  loadDelete: boolean;
  refreshData: () => void;
};

const CompanySkill = ({
  title,
  array,
  removeSkill,
  allSkill,
  comid,
  loadDelete,
  refreshData,
}: domineInterface) => {
  const [modelOpen, setModal] = React.useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: {},
  } = useForm({ mode: "all" });

  const [showLoading, setShowLoading] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");

  // domine
  const saveRoleValue = (value) => {
    value.com_id = comid;
    value.skill_name = arrayOptionNameData(value.skill_name, "label");
    const postData = postMethod("Companyprofile/save_company_skills", value);
    postData.then((res) => {
      if (res !== undefined && res.status === 200) {
        setToastColor("success");
        setToastMessage(res.message);
        setModal(false);
        setShowToast(true);
        refreshData();
        setValue("skill_name", []);
        setShowLoading(false);
      } else {
        setToastColor("danger");
        setToastMessage(res.message);
        setShowToast(true);
        setShowLoading(false);
      }
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonItem lines="none" className="custom-header-space ">
        <h4>{title}</h4>
        <IonButton
          slot="end"
          fill="outline"
          className="remove-shadow custom-circle-btn"
          color="medium"
          shape="round"
          onClick={() => {
            setModal(true);
          }}
        >
          <IonIcon icon={pencilOutline} slot="icon-only" />
        </IonButton>
      </IonItem>

      <div>
        <p>{loadDelete ? "Deleting in progress..." : null}</p>
        {array &&
          array.map((sk, index) => (
            <IonChip key={index}>
              <IonLabel>{sk.skill_name}</IonLabel>
              <IonIcon
                color="danger"
                icon={close}
                onClick={() => removeSkill(sk)}
              />
            </IonChip>
          ))}
        {array && array.length > 0 && <div className="custom-border"></div>}
      </div>

      <ModalBody
        title={"Add " + title}
        isOpen={modelOpen}
        onClose={() => {
          setModal(false);
        }}
      >
        <form onSubmit={handleSubmit(saveRoleValue)}>
          <IonGrid>
            <IonRow
              no-padding
              className="ion-justify-content-center ion-align-items-center"
            >
              <IonCol sizeLg="12" sizeXs="12" className="domain-add-btn">
                <IonLabel position="stacked">Role Name</IonLabel>
                <Controller
                  control={control}
                  name="skill_name"
                  render={({ field: { onChange, value, ref } }) => (
                    <Typeahead
                      allowNew
                      id="custom-selections-input"
                      multiple
                      newSelectionPrefix="Add a new role: "
                      options={arrayOptionData(
                        allSkill,
                        "skill_name",
                        "skill_id"
                      )}
                      onChange={onChange}
                      selected={value}
                      placeholder="Skill Name"
                      selectHintOnEnter={true}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" || event.key === "Tab") {
                          var backup: any[] = [];
                          backup = watch("skill_name");
                          setValue("skill_name", []);
                          if (event.target.value) {
                            watch("skill_name").map((e) => {
                              if (e.label !== event.target.value) {
                                backup.push({
                                  label: event.target.value,
                                  value: getIndexValue(
                                    event.target.value,
                                    arrayOptionData(
                                      allSkill,
                                      "skill_name",
                                      "skill_id"
                                    ),
                                    "label",
                                    "value"
                                  ),
                                });
                              }
                            });
                            if (watch("skill_name").length === 0) {
                              backup.push({
                                label: event.target.value,
                                value: getIndexValue(
                                  event.target.value,
                                  arrayOptionData(
                                    allSkill,
                                    "skill_name",
                                    "skill_id"
                                  ),
                                  "label",
                                  "value"
                                ),
                              });
                            }
                          }

                          setValue(
                            "skill_name",
                            RemoveDuplicates(backup, "label")
                          );
                        }
                      }}
                    />
                  )}
                />
                <IonButton
                  fill="outline"
                  className="roundButton"
                  color="secondary"
                  shape="round"
                  type="submit"
                >
                  <IonIcon icon={addOutline} slot="icon-only" />
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeLg="12" sizeSm="12">
                <h4>Added Skills</h4>
                <p>{loadDelete ? "Deleting in progress..." : null}</p>
                {array &&
                  array.map((sk, index) => (
                    <IonChip key={index}>
                      <IonLabel>{sk.skill_name}</IonLabel>
                      <IonIcon
                        color="danger"
                        icon={close}
                        onClick={() => removeSkill(sk)}
                      />
                    </IonChip>
                  ))}
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </ModalBody>
    </>
  );
};

export default React.memo(CompanySkill);
