import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import React from "react";
import { ValidateUrl } from "../../../util/validationHelper";
import { decrypted } from "../../../service/axios";
import { companyuserupdate } from "../Service/api";

export default function LinkForm({
  com_id,
  profileData,
  closeModal,
  modalSet,
}) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ mode: "all" });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "websites",
  });
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [locationLatLng, setLatLng] = React.useState({ lat: "0", lng: "0" });
  const [showLoading, setshowLoading] = React.useState(false);
  const [locationImage, setLocationImage] = React.useState("");

  React.useEffect(() => {
    if (profileData) {
      const comData = profileData;
      const weblink = comData.websites.map((e: any) => {
        let data = e;
        data.url = e.website_url;
        return data;
      });
      setValue("websites", weblink ?? "");
      setLocationImage(
        comData.locationimages.length !== 0
          ? comData.locationimages[0].image ?? ""
          : ""
      );
      setValue("firstname", comData.firstname);
      setValue("lastname", comData.lastname ?? "");
      setValue("username", comData.username ?? "");
      setValue("about", comData.about ?? "");
      setValue("video", comData.video);
      setValue(
        "email",
        decrypted(comData.email) ? decrypted(comData.email) : ""
      );
      setValue("country_code", comData.country_code ?? "");
      setValue(
        "mobile",
        decrypted(comData.mobile) ? decrypted(comData.mobile) : ""
      );
      setValue("alt_country_code", comData.alt_country_code ?? "");
      setValue(
        "alt_mobile",
        decrypted(comData.alt_mobile)
          ? decrypted(comData.alt_mobile)
          : ""
      );

      setValue(
        "geo_location",
        comData.locationimages.length !== 0
          ? comData.locationimages[0].address
          : ""
      );

      setValue(
        "linked_in_url",
        decrypted(comData.com_linked_in_url)
          ? decrypted(comData.com_linked_in_url)
          : ""
      );
      setValue(
        "facebook_url",
        decrypted(comData.com_facebook_url)
          ? decrypted(comData.com_facebook_url)
          : ""
      );
      setValue(
        "instagram_url",
        decrypted(comData.com_instagram_url)
          ? decrypted(comData.com_instagram_url)
          : ""
      );
      setValue(
        "twitter_url",
        decrypted(comData.com_twitter_url)
          ? decrypted(comData.com_twitter_url)
          : ""
      );
      setValue(
        "otherlink_url",
        decrypted(comData.com_otherlink_url)
          ? decrypted(comData.com_otherlink_url)
          : ""
      );
      //   setDataupdated(true);
      // }
      // video alert
    }
  }, [modalSet]);

  // save profile

  const saveComProfiles = (values) => {
    setshowLoading(true);
    if (values) {
      values.com_id = com_id;

      values.localtion_images = [
        {
          address: values.geo_location ?? "",
          latitude: locationLatLng.lat ?? "0",
          longitude: locationLatLng.lng ?? "0",
          image: locationImage ?? "",
          state: "",
          city: "",
          zip_code: "0",
        },
      ];
      companyuserupdate(values).then((value) => {
        if (value !== undefined && value.status === 200) {
          setToastColor("success");
          setToastMessage("Profile Updated Successfully");
          closeModal();
          setShowToast(true);
          setshowLoading(false);
        } else {
          setToastColor("danger");
          setToastMessage("Unable to Update Profile");
          setShowToast(true);
          setshowLoading(false);
        }
      });
    }
  };

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
      />
      {/* <IonCard> */}
      {!showLoading && (
        <IonCard className="ion-padding custom-modal-alignment">
          <div className="social-section">
            <div className="social-content">
              <h4>Web Link</h4>
            </div>

            <IonButton
              fill="outline"
              color="secondary"
              shape="round"
              onClick={() =>
                append({ Url_title: "", Url: "", icon: "language.svg" })
              }
            >
              Add Custom URL
            </IonButton>
          </div>
          <hr />
          <IonGrid className="ion-no-padding">
            <form onSubmit={handleSubmit(saveComProfiles)}>
              {fields.map((item: any, index: number) => (
                <IonRow key={item.id}>
                  <IonCol sizeLg="11" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        Url <span className="required"> *</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, value, ref } }) => (
                          <IonInput
                            autocomplete="off"
                            onIonChange={onChange}
                            value={value}
                            placeholder="Enter your link"
                          />
                        )}
                        control={control}
                        rules={{
                          pattern: ValidateUrl,
                          required: "Url is required",
                        }}
                        name={`websites.${index}.url` as `websites.0.url`}
                        defaultValue={item.url}
                      />
                    </IonItem>
                    {errors.websites?.[index]?.url && (
                      <div className="errorMessage text-left">Invalid link</div>
                    )}
                  </IonCol>
                  <IonCol sizeLg="1" sizeXs="12">
                    <IonItem lines="none">
                      <IonButton
                        style={{ marginTop: "37px" }}
                        fill="outline"
                        color="medium"
                        shape="round"
                        onClick={() => remove(index)}
                      >
                        <IonIcon icon={closeOutline} slot="icon-only" />
                      </IonButton>
                    </IonItem>
                  </IonCol>
                </IonRow>
              ))}
              <IonRow>
                <IonCol sizeLg="12" sizeXs="12">
                  <hr />
                  <h4>Social Link</h4>
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">LinkedIn</IonLabel>
                    <Controller
                      render={({ field: { onChange, value, ref } }) => (
                        <IonInput
                          autocomplete="off"
                          onIonChange={onChange}
                          value={value}
                          placeholder="Enter your link"
                        />
                      )}
                      control={control}
                      rules={{
                        pattern: ValidateUrl,
                      }}
                      name={"linked_in_url"}
                    />
                  </IonItem>
                  {showError("linked_in_url")}
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">Facebook</IonLabel>
                    <Controller
                      render={({ field: { onChange, value, ref } }) => (
                        <IonInput
                          autocomplete="off"
                          onIonChange={onChange}
                          value={value}
                          placeholder="Enter your link"
                        />
                      )}
                      control={control}
                      rules={{
                        pattern: ValidateUrl,
                      }}
                      name={"facebook_url"}
                    />
                  </IonItem>
                  {showError("facebook_url")}
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">Instagram</IonLabel>
                    <Controller
                      render={({ field: { onChange, value, ref } }) => (
                        <IonInput
                          autocomplete="off"
                          onIonChange={onChange}
                          value={value}
                          placeholder="Enter your link"
                        />
                      )}
                      control={control}
                      rules={{
                        pattern: ValidateUrl,
                      }}
                      name={"instagram_url"}
                    />
                  </IonItem>
                  {showError("instagram_url")}
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">Twitter</IonLabel>
                    <Controller
                      render={({ field: { onChange, value, ref } }) => (
                        <IonInput
                          autocomplete="off"
                          onIonChange={onChange}
                          value={value}
                          placeholder="Enter your link"
                        />
                      )}
                      control={control}
                      rules={{
                        pattern: ValidateUrl,
                      }}
                      name={"twitter_url"}
                    />
                  </IonItem>
                  {showError("twitter_url")}
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol sizeLg="12" sizeXs="12">
                  <hr />
                  <div className="text-right">
                    <IonButton
                      slot="end"
                      shape="round"
                      fill="solid"
                      color="secondary"
                      className="remove-shadow capitalizes"
                      type="submit"
                    >
                      Save
                    </IonButton>
                  </div>
                </IonCol>
              </IonRow>
            </form>
          </IonGrid>
        </IonCard>
      )}

      {/* </IonCard> */}
    </>
  );
}
