import { IonButton, IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateStringMap, SeverfilePaths } from "../../../../../service";

type props = {
  certificateData: any;
  folderkeyData: any;
};

interface fields {
  ctr_id: string;
  ctr_cid: string;
  name: string;
  issue_organization: string;
  result: any;
  result_type: any;
  not_expire: boolean;
  issue_start_date: moment.Moment;
  issue_end_date: moment.Moment;
  credential_id: string;
  credential_url: string;
  certifications_docs: "";
  doc_link: "";
  link: boolean;
}

const initialValues: fields = {
  ctr_id: "",
  ctr_cid: "",
  name: "",
  result: "",
  result_type: "",
  issue_organization: "",
  not_expire: false,
  link: false,
  issue_start_date: moment(),
  issue_end_date: moment(),
  credential_id: "",
  credential_url: "",
  certifications_docs: "",
  doc_link: "",
};

const Certifications: React.FC<props> = ({
  certificateData,
  folderkeyData,
}) => {
  const [dataupdated, setDataupdated] = React.useState(false);
  const [savedata, setsavedata] = useState<any>([]);

  useEffect(() => {
    if (!dataupdated) {
      setsavedata(certificateData);
      setDataupdated(true);
    }
  }, []);

  return (
    <div id="certifications">
      <IonItem lines="none" className="custom-header-space ">
        <h4>Certifications</h4>
      </IonItem>
      <CertificationSection
        arrs={savedata !== undefined && savedata}
        folderKey={folderkeyData}
      />
    </div>
  );
};

const CertificationSection = ({ arrs, editfun, folderKey }: any) => {
  const [certi, setCerti] = React.useState(3);
  return (
    <>
      <IonGrid className="ion-no-padding">
        {arrs &&
          arrs.length > 0 &&
          arrs.slice(0, certi).map((e: any, i: number) => (
            <IonRow key={i}>
              <IonCol sizeLg="4" sizeXs="12">
                <label>
                  {DateStringMap(e.issue_start_date) + " - "}
                  {e.not_expire !== "0" ? (
                    <span className="highlightContent"> forever</span>
                  ) : (
                    DateStringMap(e.issue_end_date)
                  )}
                </label>
                {e.result && (
                  <p>
                    Result:{" "}
                    <span>
                      {e.result}&nbsp; {e.result_type}
                    </span>
                  </p>
                )}
              </IonCol>

              <IonCol sizeLg="8" sizeXs="12">
                <h6>{e.name}</h6>
                <p>{e.issue_organization}</p>
                {e.credential_id && (
                  <label className="pt-2">
                    id: <span>{e.credential_id}</span>
                  </label>
                )}
                <p className="">
                  <a
                    href={SeverfilePaths(folderKey) + e.credential_url}
                    target="_blank"
                  >
                    {e.credential_url}
                  </a>
                </p>
                {i !== arrs.slice(0, certi).length - 1 && <hr />}
              </IonCol>
            </IonRow>
          ))}
      </IonGrid>
      {arrs.length > 3 && (
        <IonItem lines="none">
          {arrs.length && arrs.length !== certi && arrs.length >= certi ? (
            <IonButton
              slot="end"
              fill="clear"
              color="secondary"
              type="button"
              size="small"
              className="remove-shadow lowercase"
              onClick={() => setCerti(certi + 3)}
            >
              {arrs.length - certi} more certification(s)
            </IonButton>
          ) : (
            arrs.length > 3 &&
            arrs.length <= certi && (
              <IonButton
                slot="end"
                fill="clear"
                color="secondary"
                type="button"
                size="small"
                className="remove-shadow lowercase"
                onClick={() => setCerti(3)}
              >
                less certification(s)
              </IonButton>
            )
          )}
        </IonItem>
      )}
    </>
  );
};

export default React.memo(Certifications);
