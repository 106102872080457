import {
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonThumbnail,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import moment from "moment";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
  CardProfileImage,
  HeaderNav,
  ReadMoreRichText,
} from "../../../component";
import { getIndexValue } from "../../../service";
import {
  ArrayStringMap,
  Company,
  Contractor,
  CRMRole,
  FullDateStringMap,
  Staff,
  EXPStringMap,
  StringMap,
  TRM,
  CRMRecuter,
} from "../../../service/default";
import { contApplyjob, contfavjob, getpubJobDetail } from "../Service/api";
import { AuthContext } from "../../../context/authContext";
import useMediaQuery from "../../../hooks/mediaQuery";
import { closeOutline, heart, heartOutline } from "ionicons/icons";

const JobDetailsPage = (props: RouteComponentProps) => {
  const { auth } = React.useContext(AuthContext);
  const user = auth.data;
  const usertype = user ? user.user_type : "";
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [showLoading, setshowLoading] = React.useState(false);
  const [locationData, setLocationData] = React.useState<any>(
    props.location.state
  );
  const [jobdetail, setJobDetail] = React.useState<any>();
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [workPermit, setworkPermit] = React.useState([]);

  React.useEffect(() => {
    let isMounted = true;
    setshowLoading(true);
    try {
      getJobDetails();
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
      }
    } finally {
      isMounted && setshowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const getJobDetails = () => {
    setshowLoading(true);
    getpubJobDetail(locationData.unique_id).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        const es = e.data;
        setworkPermit(es.company_work_permit ?? []);
        setJobDetail(e.data);
        setshowLoading(false);
      } else {
        setshowLoading(false);
      }
    });
  };

  //   favour
  const postfav = (flag) => {
    const data = {
      unique_id: locationData.unique_id,
      ctr_id: user.ctr_id,
      flag: flag === 1 ? 0 : 1,
    };
    setshowLoading(true);
    contfavjob(data).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setshowLoading(false);
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
        getJobDetails();
      } else {
        setshowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again later"
        );
        setShowToast(true);
      }
    });
  };

  function applyJob(data) {
    setshowLoading(true);
    const apply = {
      com_id: data.com_id,
      unique_id: locationData.unique_id,
      ctr_id: user.ctr_id,
    };
    contApplyjob(apply).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
        getJobDetails();
        setshowLoading(false);
      } else {
        setshowLoading(false);
        setToastColor("danger");
        setToastMessage(value.message);
        setShowToast(true);
      }
    });
  }

  const viewapplicationScreen = (e) => {
    if (e) {
      const view = {
        ...e,
        com_id: e.com_id,
        unique_id: locationData.unique_id,
        ctr_id: user.ctr_id,
        workFromhomeType: locationData.workFromhomeType,
      };
      props.history.push("/applicationTL", view);
    }
  };

  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setshowLoading(false)}
        />
        <div
          className={m4kQuery ? "container Jobs px-4 py-3" : "Jobs px-4 py-3"}
        >
          {!showLoading && jobdetail ? (
            <>
              <IonGrid className="ion-padding">
                <IonRow>
                  <IonCol sizeLg="6" sizeXs="12">
                    <h1 className="detail-header m-0">Job details</h1>
                  </IonCol>
                  <IonCol sizeLg="6" sizeXs="12">
                    {!locationData.matchscreen &&
                      [TRM, Staff, CRMRole].includes(usertype) && (
                        <IonButton
                          fill="outline"
                          color="orange"
                          shape="round"
                          className="remove-shadow capitalizes text-right"
                          type="button"
                          onClick={() => {
                            props.history.push("/candidatesmatch", {
                              ...jobdetail,
                              unique_id: locationData.unique_id,
                              job_id: jobdetail.com_pjid,
                            });
                          }}
                        >
                          Candidates
                        </IonButton>
                      )}
                    {user &&
                      user.user_type !== Company &&
                      user.user_type !== TRM &&
                      user.user_type !== Staff &&
                      user.user_type !== CRMRole && (
                        <IonItem lines="none">
                          {user.user_type !== CRMRecuter && (
                            <IonButton
                              slot="end"
                              fill="outline"
                              color="secondary"
                              shape="round"
                              className="remove-shadow capitalizes"
                              type="button"
                              onClick={(e) => {
                                jobdetail.job_status !== "1"
                                  ? applyJob(jobdetail)
                                  : viewapplicationScreen(jobdetail);
                              }}
                            >
                              {jobdetail.applied_job_status !== 1
                                ? "Apply"
                                : "View Application"}
                            </IonButton>
                          )}

                          {user.user_type === Contractor && (
                            <IonButton
                              slot="end"
                              fill="outline"
                              className="remove-shadow custom-circle-btn mx-2"
                              color="medium"
                              shape="round"
                              onClick={() => postfav(jobdetail.favorite_job)}
                            >
                              <IonIcon
                                icon={
                                  jobdetail.favorite_job === 1
                                    ? heart
                                    : heartOutline
                                }
                                size="small"
                                mode="ios"
                                color={
                                  jobdetail.favorite_job === 1 ? "danger" : " "
                                }
                                slot="icon-only"
                              />

                              {/* <img src="assets/image/favorite_border.svg" /> */}
                            </IonButton>
                          )}
                           {user.user_type === Contractor ? <IonButton
                            slot="end"
                            fill="outline"
                            className="remove-shadow custom-circle-btn"
                            color="danger"
                            type="button"
                            shape="round"
                            onClick={(e) => props.history.replace('/joblist')}
                          >
                            <IonIcon icon={closeOutline} slot="icon-only" />
                          </IonButton>: <IonButton
                            slot="end"
                            fill="outline"
                            className="remove-shadow custom-circle-btn"
                            color="danger"
                            type="button"
                            shape="round"
                            onClick={(e) => props.history.goBack()}
                          >
                            <IonIcon icon={closeOutline} slot="icon-only" />
                          </IonButton>}
                        </IonItem>
                      )}
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="com-header-details">
                      <IonItem lines="none">
                        <IonThumbnail slot="start">
                          <CardProfileImage
                            folder_key={jobdetail.com_folder_key}
                            profile_photo={jobdetail.com_profile_photo}
                            name={""}
                          />
                        </IonThumbnail>
                        <IonLabel className="ion-text-wrap">
                          <h2>{StringMap(jobdetail.job_title)}</h2>
                          <p className="m-0">
                            {StringMap(jobdetail.work_location)}
                            {getIndexValue(
                              jobdetail.wfh_type,
                              locationData.workFromhomeType,
                              "value",
                              "label"
                            ) &&
                              " (" +
                                getIndexValue(
                                  jobdetail.wfh_type,
                                  locationData.workFromhomeType,
                                  "value",
                                  "label"
                                ) +
                                ")"}
                          </p>
                          <small className="text-gray">
                            JID: {StringMap(jobdetail.com_pjid)} | Posted by{" "}
                          </small>{" "}
                          <small>
                            {StringMap(
                              jobdetail.posted_by ?? jobdetail.company_name
                            )}
                            {/* {StringMap(jobdetail.company_lastname)} */}
                          </small>{" "}
                          <small className="text-gray">
                            on{" "}
                            {moment(jobdetail.job_created_on).format("lll") +
                              " |"}{" "}
                            Department:{" "}
                            <small>{StringMap(jobdetail.department)}</small>
                            {/* Hiring Manager:{" "}
                        <small>{StringMap(jobdetail.hiring_manager)}</small> */}
                          </small>
                        </IonLabel>
                      </IonItem>
                    </div>
                  </IonCol>
                  {/* details */}
                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="com-custom-grid">
                      <div className=" com-col-section remove-p">
                        <div>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/date_range.svg"
                            name="date_range"
                          />

                          <small>Engagement</small>
                        </div>
                        <label>
                          {jobdetail.duration_type === "0"
                            ? "Contract"
                            : "Permanent"}
                        </label>
                      </div>
                      <div className="com-col-section">
                        <div>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/business_center.svg"
                            name="business_center"
                          />

                          <small>Exp.</small>
                        </div>
                        <label>
                          {EXPStringMap(jobdetail.overall_exp) + " y"}
                        </label>
                      </div>
                      <div className="com-col-section">
                        <div>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/monetization_on.svg"
                            name="monetization_on"
                          />

                          <small>Budget</small>
                        </div>
                        <label>
                          {/* {getIndexValue(
                              jobdetail.pay_type,
                              locationData.currencyType,
                              "value",
                              "symbol"
                            ) + */}
                          {"₹ " + StringMap(jobdetail.payment)}{" "}
                          {jobdetail.duration_type === "0"
                            ? "per hour"
                            : "per year"}
                          {/* getIndexValue(
                                jobdetail.pay_duration,
                                locationData.paymentDurationType,
                                "value",
                                "label"
                              ) */}
                        </label>
                      </div>
                      <div className="com-col-section">
                        <div>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/event_available.svg"
                            name="event_available"
                          />

                          <small>Start</small>
                        </div>
                        <label>{FullDateStringMap(jobdetail.start_date)}</label>
                      </div>
                      {jobdetail.duration_type === "0" && (
                        <div
                          className="com-col-section"
                          style={{ borderRightStyle: "none" }}
                        >
                          <div>
                            <CardProfileImage
                              folder_key=""
                              profile_photo="assets/image/company/event_busy.svg"
                              name="event_busy"
                            />

                            <small>End</small>
                          </div>
                          <label>{FullDateStringMap(jobdetail.end_date)}</label>
                        </div>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
                {/* cards */}
                <IonRow>
                  <IonCol sizeLg="12" sizeMd="12" sizeXs="12">
                    <IonCard className="ion-padding px-lg-4">
                      <h3 className="pb-4">Job description</h3>
                      <ReadMoreRichText length={650}>
                        {jobdetail.job_description}
                      </ReadMoreRichText>
                      <IonGrid className="ion-no-padding py-2">
                        {jobdetail.mandatory_skills.length !== 0 && <hr />}
                        {jobdetail.mandatory_skills.length !== 0 && (
                          <IonRow>
                            <IonCol sizeLg="4" sizeMd="12" sizeXs="12">
                              <label>Mandatory skills</label>
                            </IonCol>
                            <IonCol sizeLg="8" sizeMd="12" sizeXs="12">
                              {ArrayStringMap(jobdetail.mandatory_skills) !==
                              "NA"
                                ? jobdetail.mandatory_skills.map(
                                    (m: any, i: number) => (
                                      <IonChip color="secondary" key={i}>
                                        <IonLabel>{m.skill_name}</IonLabel>
                                      </IonChip>
                                    )
                                  )
                                : "NA"}
                            </IonCol>
                          </IonRow>
                        )}
                        {jobdetail.additional_skills.length !== 0 && <hr />}
                        {jobdetail.additional_skills.length !== 0 && (
                          <IonRow>
                            <IonCol sizeLg="4" sizeMd="12" sizeXs="12">
                              <label>Additional skills</label>
                            </IonCol>
                            <IonCol sizeLg="8" sizeMd="12" sizeXs="12">
                              {ArrayStringMap(jobdetail.additional_skills) !==
                              "NA"
                                ? jobdetail.additional_skills.map(
                                    (m: any, i: number) => (
                                      <IonChip color="secondary" key={i}>
                                        <IonLabel>{m.skill_name}</IonLabel>
                                      </IonChip>
                                    )
                                  )
                                : "NA"}
                            </IonCol>
                          </IonRow>
                        )}
                        {/* <hr /> */}
                        {/* <IonRow>
                          <IonCol sizeLg="4" sizeMd="12" sizeXs="12">
                            <label>US work authorization(s) required</label>
                          </IonCol>
                          <IonCol sizeLg="8" sizeMd="12" sizeXs="12">
                            {ArrayStringMap(jobdetail.us_work_authrizations) !==
                            "NA"
                              ? jobdetail.us_work_authrizations.map(
                                  (work: any, i: number) => (
                                    <span key={i}>
                                      {getIndexValue(
                                        work.us_work_authrizations,
                                        jobdetail.company_work_permit,
                                        "value",
                                        "label"
                                      )}
                                      {jobdetail.us_work_authrizations.length -
                                        1 !==
                                        i && " / "}
                                    </span>
                                  )
                                )
                              : "NA"}
                          </IonCol>
                        </IonRow> */}
                      </IonGrid>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </>
          ) : (
            <div className="m-auto py-3">
              <h1> {showLoading ? "Loading..." : "No record found"}</h1>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(JobDetailsPage);
