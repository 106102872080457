import { IonButton, IonIcon, IonItem } from "@ionic/react";
import { pencilOutline } from "ionicons/icons";
import React from "react";
import { CommonSection } from ".";
import { ModalBody } from "../../../../../component";
import { LanguagesForm } from "../../Form";

type props = {
  languageData: any;
  proficiencytypelist: any;
  ctridData: any;
  ListofLanguageOption: any;
};

const Languages: React.FC<props> = ({
  ctridData,
  languageData,
  proficiencytypelist,
  ListofLanguageOption,
}) => {
  const [languageDisp, setLanguageDisp] = React.useState([]);
  const [firstRun, setFirstRun] = React.useState(true);
  const [modelOpen, setModal] = React.useState(false);

  React.useEffect(() => {
    if (firstRun) {
      setLanguageDisp(languageData);
      setFirstRun(false);
    }
  }, []);

  function setLanguageDataInMainPage(languages: any) {
    setLanguageDisp(languages);
  }

  return (
    <div id="language">
      <ModalBody
        title={"Language"}
        isOpen={modelOpen}
        onClose={() => setModal(false)}
      >
        <LanguagesForm
          ctrid={ctridData}
          modelClick={modelOpen}
          languageData={ListofLanguageOption}
          proficiencytypelist={proficiencytypelist}
          closeModal={() => setModal(false)}
          languageDisp={languageDisp}
          setLanguageDataInMainPage={setLanguageDataInMainPage}
        />
      </ModalBody>

      <IonItem lines="none" className="custom-header-space ">
        <h4>Spoken Language</h4>
        <IonButton
          slot="end"
          fill="outline"
          className="remove-shadow custom-circle-btn"
          color="medium"
          shape="round"
          onClick={() => {
            setModal(true);
          }}
        >
          <IonIcon icon={pencilOutline} slot="icon-only" />
        </IonButton>
      </IonItem>

      {languageDisp && <CommonSection arrs={languageDisp} type={"languages"} />}
    </div>
  );
};
export default React.memo(Languages);
