import {
  IonBadge,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRange,
  IonRow,
  IonText,
  IonToast,
  IonToggle,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import React from "react";
import { saveengagementdetails } from "../../Service/api";
import { apiCallBack } from "../../../../service";
import { DatePick } from "../../../../component";

function EngagementForm({
  EngagementValueData,
  closeModal,
  resetValue,
  formReset,
  ctrid,
}: any) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [uploading, setUploading] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [contract, setContract] = React.useState(false);
  const [permanent, setPermanent] = React.useState(false);
  const [compensationOption, setCompensation] = React.useState([]);
  const [currencyOption, setCurrency] = React.useState([]);
  const [durationOption, setDuration] = React.useState([]);
  const [telecommuteOption, setTelecommute] = React.useState([]);
  const [ContctsDurationSlide, setContctsDurationSlide] = React.useState<any>();
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [ContctsTelecomuteSlide, setContctsTelecomuteSlide] =
    React.useState<any>();
  const [permanentDurationSlide, setpermanentDurationSlide] =
    React.useState<any>();
  const [PermanentTelecomuteSlide, setPermanentTelecomuteSlide] =
    React.useState<any>();
  function SpecifyValues() {
    setContctsDurationSlide(EngagementValueData.contract_duration ?? "40");
    setContctsTelecomuteSlide(
      EngagementValueData.contract_duration_type === "1"
        ? Number(EngagementValueData.contract_telecommute) / 5
        : EngagementValueData.contract_duration_type === "3"
        ? (Number(EngagementValueData.contract_telecommute) / 100) * 4
        : EngagementValueData.contract_duration_type === "2"
        ? Number(EngagementValueData.contract_telecommute)
        : "20"
    );
    setpermanentDurationSlide(EngagementValueData.permanent_duration ?? "40");
    setPermanentTelecomuteSlide(
      EngagementValueData.permanent_duration_type === "1"
        ? Number(EngagementValueData.permanent_telecommute) / 5
        : EngagementValueData.permanent_duration_type === "3"
        ? (Number(EngagementValueData.permanent_telecommute) / 100) * 4
        : EngagementValueData.permanent_duration_type === "2"
        ? Number(EngagementValueData.permanent_telecommute)
        : "20"
    );
    setContract(EngagementValueData.is_contract_job);
    setPermanent(EngagementValueData.is_permanent_job);
    setCompensation(EngagementValueData.compensation_type_list);
    setCurrency(EngagementValueData.currency_type_list);
    setDuration(EngagementValueData.duration_type_list);
    setTelecommute(EngagementValueData.telecommute_type);
    setValue(
      "contract_engagement_type",
      EngagementValueData.contract_engagement_type
    );
    setValue(
      "contract_compensation",
      EngagementValueData.contract_compensation
    );
    setValue(
      "contract_compensation_type",
      EngagementValueData.contract_compensation_type
    );
    setValue(
      "contract_currency_type",
      EngagementValueData.contract_currency_type
    );
    setValue(
      "contract_duration",
      EngagementValueData.contract_duration ?? "40"
    );
    setValue(
      "contract_duration_type",
      EngagementValueData.contract_duration_type ?? "2"
    );
    setValue(
      "contract_start_date",
      EngagementValueData.contract_start_date
        ? new Date(EngagementValueData.contract_start_date)
        : ""
    );
    setValue(
      "contract_telecommute",
      EngagementValueData.contract_duration_type === "1"
        ? Number(EngagementValueData.contract_telecommute) / 5
        : EngagementValueData.contract_duration_type === "3"
        ? (Number(EngagementValueData.contract_telecommute) / 100) * 4
        : EngagementValueData.contract_duration_type === "2"
        ? Number(EngagementValueData.contract_telecommute)
        : "20"
    );
    setValue(
      "contract_telecommute_type",
      EngagementValueData.contract_telecommute_type
    );
    setValue("currency", EngagementValueData.currency);
    setValue(
      "contract_days",
      EngagementValueData.contract_days
    );
    setValue("is_contract_job", EngagementValueData.is_contract_job);
    setValue("is_permanent_job", EngagementValueData.is_permanent_job);
    setValue(
      "permanent_compensation",
      EngagementValueData.permanent_compensation
    );
    setValue(
      "permanent_compensation_type",
      EngagementValueData.permanent_compensation_type
    );
    setValue(
      "permanent_currency_type",
      EngagementValueData.permanent_currency_type
    );
    setValue(
      "permanent_duration",
      EngagementValueData.permanent_duration ?? "40"
    );
    setValue(
      "permanent_duration_type",
      EngagementValueData.permanent_duration_type ?? "2"
    );
    setValue(
      "permanent_start_date",
      EngagementValueData.permanent_start_date
        ? new Date(EngagementValueData.permanent_start_date)
        : ""
    );
    setValue(
      "permanent_telecommute",
      EngagementValueData.permanent_duration_type === "1"
        ? Number(EngagementValueData.permanent_telecommute) / 5
        : EngagementValueData.permanent_duration_type === "3"
        ? (Number(EngagementValueData.permanent_telecommute) / 100) * 4
        : EngagementValueData.permanent_duration_type === "2"
        ? EngagementValueData.permanent_telecommute ?? "20"
        : "20"
    );
    setValue(
      "permanent_telecommute_type",
      EngagementValueData.permanent_telecommute_type
    );
    setValue("permanent_days", EngagementValueData.permanent_days);
  }

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  React.useEffect(() => {
    // if (!dataupdated) {
    SpecifyValues();
    // setDataupdated(true);
    // }
  }, [formReset]);

  // save api call
  const saveEngData = (values: any) => {
    setShowLoading(true);
    values.ctr_id = ctrid;
    values.is_permanent_job = values.is_permanent_job ? "1" : "0";
    values.is_contract_job = values.is_contract_job ? "1" : "0";
    if (
      parseInt(values.contract_duration) < parseInt(values.contract_telecommute)
    ) {
      setToastColor("warning");
      setToastMessage("Contract telecommute is less than available duration");
      setShowToast(true);
      setShowLoading(false);
      return;
    }
    if (
      parseInt(values.permanent_duration) <
      parseInt(values.permanent_telecommute)
    ) {
      setToastColor("warning");
      setToastMessage("Permanent telecommute is less than available duration");
      setShowToast(true);
      setShowLoading(false);
      return;
    }
    if (
      (permanent && contract && values.contract_duration_type === "") ||
      (permanent && contract && values.contract_duration === 0) ||
      (permanent &&
        contract &&
        values.contract_telecommute !== 0 &&
        values.contract_telecommute_type === "") ||
      (permanent && contract && values.permanent_duration_type === "") ||
      (permanent && contract && values.permanent_duration === 0) ||
      (permanent &&
        contract &&
        values.permanent_telecommute !== 0 &&
        values.permanent_telecommute_type === "")
    ) {
      setToastColor("warning");
      setToastMessage("Please fill all the details ");
      setShowToast(true);
      setShowLoading(false);
      return;
    }
    if (contract) {
      if (
        values.contract_duration_type === "" ||
        values.contract_duration === 0 ||
        (values.contract_telecommute !== 0 &&
          values.contract_telecommute_type === "")
      ) {
        setToastColor("warning");
        setToastMessage("Please fill all the details for contract job");
        setShowToast(true);
        setShowLoading(false);
        return;
      }
      values.contract_days = values.contract_days ?? "";
      values.contract_compensation = values.contract_compensation ?? "";
      values.contract_compensation_type =
        values.contract_compensation_type ?? "1";
      values.contract_currency_type = values.contract_currency_type ?? "2";
      values.contract_duration = values.contract_duration ?? "40";
      values.contract_duration_type =
        values.contract_duration_type !== "0"
          ? values.contract_duration_type
          : "2";
      values.contract_start_date = values.contract_start_date ?? "";
      values.contract_telecommute =
        values.contract_duration_type === "1"
          ? Number(values.contract_telecommute) * 5
          : values.contract_duration_type === "3"
          ? Math.round((Number(values.contract_telecommute) * 100) / 4)
          : values.contract_telecommute !== "0"
          ? values.contract_telecommute
          : "20";
      values.contract_telecommute_type =
        values.contract_telecommute_type ?? "1";
    }

    if (permanent) {
      if (
        values.permanent_duration_type === "" ||
        values.permanent_duration === 0 ||
        (values.permanent_telecommute !== 0 &&
          values.permanent_telecommute_type === "")
      ) {
        setToastColor("warning");
        setToastMessage("Please fill all the details for permanent job");
        setShowToast(true);
        setShowLoading(false);
        return;
      }
      values.permanent_days = values.permanent_days ?? "";
      values.permanent_compensation = values.permanent_compensation ?? "";
      values.permanent_compensation_type =
        values.permanent_compensation_type ?? "5";
      values.permanent_currency_type = values.permanent_currency_type ?? "2";
      values.permanent_duration = values.permanent_duration ?? "40";
      values.permanent_duration_type =
        values.permanent_duration_type !== "0"
          ? values.permanent_duration_type
          : "2";
      values.permanent_start_date = values.permanent_start_date ?? "";
      values.permanent_telecommute =
        values.permanent_duration_type === "1"
          ? Number(values.permanent_telecommute) * 5
          : values.permanent_duration_type === "3"
          ? Math.round((Number(values.permanent_telecommute) * 100) / 4)
          : values.permanent_telecommute !== "0"
          ? values.permanent_telecommute
          : "20";
      values.permanent_telecommute_type =
        values.permanent_telecommute_type ?? "1";
    }
    saveengagementdetails(values).then((e) => {
      apiCallBack(e);
      if (apiCallBack(e)) {
        resetValue(values);
        setShowLoading(false);
        closeModal(false);
      }
      setShowLoading(false);
      setUploading(false);
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveEngData)} className="engagement">
            <IonRow className="ion-align-items-center pb-3">
              <IonCol sizeLg="6" sizeXs="12">
                <h4>Available for contract jobs</h4>
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonToggle
                        value={value}
                        checked={value}
                        mode="md"
                        slot="end"
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue("is_contract_job", e.detail.checked);
                          setContract(e.detail.checked);
                        }}
                      />
                    )}
                    control={control}
                    name="is_contract_job"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            {contract && (
              <IonRow>
                {/* <IonCol sizeLg="12" sizeXs="12">
                  <h6>
                    Total duration
                    <div className="text-right">{ContctsDurationSlide}h</div>
                  </h6>
                </IonCol> */}
                <IonCol sizeLg="12" sizeXs="12">
                  <IonItem lines="none" className="custom-align">
                    <h6>Total duration</h6>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          className="custom-select-fit mx-2"
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                            let value = e.target.value;
                            setValue(
                              "contract_duration",
                              value == "1"
                                ? 8
                                : value == "2"
                                ? 40
                                : value == "3"
                                ? 176
                                : value
                            );
                            setValue("contract_telecommute", 0);
                          }}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          {durationOption !== undefined &&
                            durationOption.map((option: any, i: number) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                      )}
                      control={control}
                      name="contract_duration_type"
                    />
                    {ContctsDurationSlide}h
                  </IonItem>
                </IonCol>

                <IonCol sizeLg="12" sizeXs="12" className="pb-3">
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonRange
                        style={{ paddingTop: "inherit" }}
                        mode={"ios"}
                        min={0}
                        max={
                          watch("contract_duration_type") === "1"
                            ? 24
                            : watch("contract_duration_type") === "2"
                            ? 166
                            : 720
                        }
                        pin={true}
                        snaps={true}
                        value={value}
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue(
                            "contract_duration",
                            e.detail.value as number
                          );
                          setValue("contract_telecommute", 0);
                          setContctsDurationSlide(e.detail.value as number);
                        }}
                      >
                        <IonLabel slot="start">0</IonLabel>
                        <IonLabel slot="end">
                          {watch("contract_duration_type") === "1"
                            ? 24
                            : watch("contract_duration_type") === "2"
                            ? 168
                            : 720}
                        </IonLabel>
                      </IonRange>
                    )}
                    control={control}
                    name="contract_duration"
                  />
                </IonCol>
                {/* <IonCol sizeLg="12" sizeXs="12" className="pt-3">
                  <h6>
                    Telecommute{" "}
                    <div className="text-right">
                      {ContctsTelecomuteSlide}h of total duration
                    </div>
                  </h6>
                </IonCol> */}
                <IonCol sizeLg="12" sizeXs="12">
                  <IonItem lines="none" className="custom-align">
                    <h6>Telecommute: {ContctsTelecomuteSlide}h </h6>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          className="custom-select-fit mx-2"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          {telecommuteOption !== undefined &&
                            telecommuteOption.map((option: any, i: number) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                      )}
                      control={control}
                      name="contract_telecommute_type"
                    />
                    {watch("contract_duration_type") == "3"
                      ? "Monthly"
                      : watch("contract_duration_type") == "2"
                      ? "Weekly"
                      : "Daily"}
                    {/* {" " + ContctsDurationSlide}h */}
                  </IonItem>
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12" className="pb-3">
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonRange
                        style={{ paddingTop: "inherit" }}
                        mode={"ios"}
                        min={0}
                        max={
                          watch("contract_duration")
                          // watch("contract_duration_type") == "3"
                          //   ? Number(watch("contract_duration")) / 4
                          //   : watch("contract_duration_type") == "1"
                          //   ? Number(watch("contract_duration")) * 5
                          //   : Number(watch("contract_duration"))
                        }
                        pin={true}
                        snaps={true}
                        value={value}
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue(
                            "contract_telecommute",
                            e.detail.value as number
                          );
                          setContctsTelecomuteSlide(e.detail.value as number);
                        }}
                      >
                        <IonLabel slot="start">0</IonLabel>
                        <IonLabel slot="end">
                          {watch("contract_duration")}
                          {/* {watch("contract_duration_type") == "3"
                            ? Math.floor(Number(watch("contract_duration")) / 4)
                            : watch("contract_duration_type") == "1"
                            ? Math.floor(Number(watch("contract_duration")) * 5)
                            : Math.floor(Number(watch("contract_duration")))} */}
                        </IonLabel>
                      </IonRange>
                    )}
                    control={control}
                    name="contract_telecommute"
                  />
                </IonCol>
                <IonCol sizeLg="4" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Desired compensation <span className="required">*</span>
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <IonInput
                          autocomplete="off"
                          type="number"
                          onBlur={onBlur}
                          onIonChange={onChange}
                          value={value}
                          placeholder="₹10"
                        />
                      )}
                      control={control}
                      name="contract_compensation"
                      rules={{ required: "Desired compensation is required" }}
                    />
                    <div className="custom-groupInput">
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <select
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            disabled
                            placeholder="Select"
                          >
                            <option selected value="1">
                              Hourly
                            </option>
                            {/* <option value="">Select</option>
                              {compensationOption !== undefined &&
                                compensationOption.map(
                                  (option: any, i: number) => (
                                    <option key={i} value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )} */}
                          </select>
                        )}
                        control={control}
                        name="contract_compensation_type"
                      />
                    </div>
                    <div className="currencyLabel">
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <select
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            placeholder="Select"
                          >
                            <option value="">Select</option>
                            {currencyOption !== undefined &&
                              currencyOption.map((option: any, i: number) => (
                                <option key={i} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        )}
                        control={control}
                        name="currency"
                      />
                    </div>
                  </IonItem>
                  {showError("contract_compensation")}
                </IonCol>
                <IonCol sizeLg="4" sizeXs="12">
                  <div className="input-div">
                    <IonLabel position="stacked">
                      Preferred start date
                      {/* <span className="required">*</span> */}
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <DatePick
                          value={value}
                          ChangeEvent={onChange}
                          possition="top-start"
                          yearpermit={50}
                        />
                      )}
                      control={control}
                      name="contract_start_date"
                      // rules={{ required: "Preferred start date" }}
                    />
                  </div>
                  {showError("contract_start_date")}
                </IonCol>
                <IonCol sizeLg="4" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Notice period (Days)
                      {/* <span className="required">*</span> */}
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <IonInput
                          type="number"
                          autocomplete="off"
                          onBlur={onBlur}
                          onIonChange={onChange}
                          value={value}
                        />
                      )}
                      control={control}
                      name="contract_days"
                    />
                  </IonItem>
                  {showError("contract_days")}
                </IonCol>
                {/* <IonCol sizeLg="4" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">Engagement Type</IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          <option value="W2">W2</option>
                          <option value="1099">1099</option>
                          <option value="C2C">C2C</option>
                        </select>
                      )}
                      control={control}
                      name="contract_engagement_type"
                    />
                  </IonItem>
                  {showError("contract_engagement_type")}
                </IonCol> */}
              </IonRow>
            )}

            <div className="divider-Innerline mx-4"></div>
            {/* Available for permanent jobs */}

            <IonRow className="ion-align-items-center pb-3">
              <IonCol sizeLg="6" sizeXs="12">
                <h4>Available for permanent jobs</h4>
              </IonCol>
              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonToggle
                        value={value}
                        checked={value}
                        mode="md"
                        slot="end"
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue("is_permanent_job", e.detail.checked);
                          setPermanent(e.detail.checked);
                        }}
                      />
                    )}
                    control={control}
                    name="is_permanent_job"
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            {permanent && (
              <IonRow className="ion-align-items-center">
                {/* <IonCol sizeLg="12" sizeXs="12">
                  <h6>
                    Total duration
                    <div className="text-right">
                      {permanentDurationSlide}h selected
                    </div>
                  </h6>
                </IonCol> */}
                <IonCol sizeLg="12" sizeXs="12">
                  <IonItem lines="none" className="custom-align">
                    <h6>Total duration</h6>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          className="custom-select-fit mx-2"
                          onBlur={onBlur}
                          onChange={(e) => {
                            onChange(e);
                            let value = e.target.value;
                            setValue(
                              "permanent_duration",
                              value == "1"
                                ? 8
                                : value == "2"
                                ? 40
                                : value == "3"
                                ? 176
                                : value
                            );
                            setValue("permanent_telecommute", 0);
                          }}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          {durationOption !== undefined &&
                            durationOption.map((option: any, i: number) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                      )}
                      control={control}
                      name="permanent_duration_type"
                    />
                    {permanentDurationSlide}h
                  </IonItem>
                </IonCol>
                <IonCol sizeLg="12" sizeXs="12" className="pb-3">
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonRange
                        style={{ paddingTop: "inherit" }}
                        mode={"ios"}
                        min={0}
                        max={
                          watch("permanent_duration_type") === "1"
                            ? 24
                            : watch("permanent_duration_type") === "2"
                            ? 168
                            : 720
                        }
                        pin={true}
                        snaps={true}
                        value={value}
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue(
                            "permanent_duration",
                            e.detail.value as number
                          );
                          setpermanentDurationSlide(e.detail.value as number);
                        }}
                      >
                        <IonLabel slot="start">0</IonLabel>
                        <IonLabel slot="end">
                          {watch("permanent_duration_type") === "1"
                            ? 24
                            : watch("permanent_duration_type") === "2"
                            ? 168
                            : 720}
                        </IonLabel>
                      </IonRange>
                    )}
                    control={control}
                    name="permanent_duration"
                  />
                </IonCol>
                {/* <IonCol sizeLg="12" sizeXs="12">
                  <h6>
                    Telecommute
                    <div className="text-right">
                      {PermanentTelecomuteSlide}h of total duration
                    </div>
                  </h6>
                </IonCol> */}
                <IonCol sizeLg="12" sizeXs="12">
                  <IonItem lines="none" className="custom-align">
                    <h6>Telecommute: {PermanentTelecomuteSlide}h </h6>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          className="custom-select-fit mx-2"
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          {telecommuteOption !== undefined &&
                            telecommuteOption.map((option: any, i: number) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </select>
                      )}
                      control={control}
                      name="permanent_telecommute_type"
                    />
                    {watch("permanent_duration_type") == "3"
                      ? "Monthly"
                      : watch("permanent_duration_type") == "2"
                      ? "Weekly"
                      : "Daily"}
                    {/* {" " + permanentDurationSlide}h */}
                  </IonItem>
                </IonCol>
                <IonCol
                  sizeLg="12"
                  sizeXs="12"
                  className="changeTopPadding pb-3"
                >
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonRange
                        style={{ paddingTop: "inherit" }}
                        mode={"ios"}
                        min={0}
                        max={
                          watch("permanent_duration")
                          // watch("permanent_duration_type") == "3"
                          //   ? Number(watch("permanent_duration")) / 4 // month
                          //   : watch("permanent_duration_type") == "1"
                          //   ? Number(watch("permanent_duration")) * 5 //day
                          //   : Number(watch("permanent_duration")) // week
                        }
                        pin={true}
                        snaps={true}
                        value={value}
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue(
                            "permanent_telecommute",
                            e.detail.value as number
                          );
                          setPermanentTelecomuteSlide(e.detail.value as number);
                        }}
                      >
                        <IonLabel slot="start">0</IonLabel>
                        <IonLabel slot="end">
                          {watch("permanent_duration")}
                          {/* {watch("permanent_duration_type") == "3"
                            ? Math.floor(Number(watch("permanent_duration")) / 4)
                            : watch("permanent_duration_type") == "1"
                            ? Math.floor(Number(watch("permanent_duration")) * 5)
                            : Math.floor(Number(watch("permanent_duration")))} */}
                        </IonLabel>
                      </IonRange>
                    )}
                    control={control}
                    name="permanent_telecommute"
                  />
                </IonCol>
                <IonCol sizeLg="4" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Desired compensation <span className="required">*</span>
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <IonInput
                          autocomplete="off"
                          type="number"
                          onBlur={onBlur}
                          onIonChange={onChange}
                          value={value}
                          placeholder="₹10"
                        />
                      )}
                      control={control}
                      name="permanent_compensation"
                      rules={{ required: "Desired compensation is required" }}
                    />
                    <div className="custom-groupInput">
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <select
                            onBlur={onBlur}
                            onChange={onChange}
                            disabled
                            value={value}
                            placeholder="Select"
                          >
                            <option selected value="5">
                              Yearly
                            </option>
                            {/* <option value="">Select</option> */}
                            {/* {compensationOption !== undefined &&
                                compensationOption.map(
                                  (option: any, i: number) => (
                                    <option key={i} value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )} */}
                          </select>
                        )}
                        control={control}
                        name="permanent_compensation_type"
                      />
                    </div>
                    <div className="currencyLabel">
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <select
                            onBlur={onBlur}
                            onChange={onChange}
                            value={value}
                            placeholder="Select"
                          >
                            <option value="">Select</option>
                            {currencyOption !== undefined &&
                              currencyOption.map((option: any, i: number) => (
                                <option key={i} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </select>
                        )}
                        control={control}
                        name="permanent_currency_type"
                      />
                    </div>
                  </IonItem>
                  {showError("permanent_compensation")}
                </IonCol>
                <IonCol sizeLg="4" sizeXs="12">
                  <div className="input-div">
                    <IonLabel position="stacked">
                      Preferred start date
                      {/* <span className="required">*</span> */}
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <DatePick
                          value={value}
                          ChangeEvent={onChange}
                          possition="top-start"
                          yearpermit={50}
                        />
                      )}
                      control={control}
                      name="permanent_start_date"
                      // rules={{ required: "Preferred start date" }}
                    />
                  </div>
                  {showError("permanent_start_date")}
                </IonCol>
                <IonCol sizeLg="4" sizeXs="12">
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      Notice period (Days)
                      {/* <span className="required">*</span> */}
                    </IonLabel>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <IonInput
                          type="number"
                          autocomplete="off"
                          onBlur={onBlur}
                          onIonChange={onChange}
                          value={value}
                        />
                      )}
                      control={control}
                      name="permanent_days"
                      // rules={{ required: "Preferred start date" }}
                    />
                  </IonItem>
                  {showError("permanent_days")}
                </IonCol>
              </IonRow>
            )}

            <IonRow>
              <IonCol sizeLg="12" sizeXs="12">
                <div className="">
                  <IonButton
                    slot="end"
                    shape="round"
                    fill="solid"
                    color="secondary"
                    className="remove-shadow capitalizes"
                    type="submit"
                  >
                    {uploading ? "Uploading" : "Save"}
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
}

export default React.memo(EngagementForm);
