import {
  IonAccordion,
  IonAccordionGroup,
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
  IonToggle,
} from "@ionic/react";
import React, { useState } from "react";
import { onSave, StringMap } from "../../../service";
import { chevronDownOutline } from "ionicons/icons";
import { Controller, useForm } from "react-hook-form";
import { BrowserHandleExport, ModalBody } from "../../../component";
import { getContractorlist, getGrouplist } from "../Service/api";
import ContractorUpdateForm from "../../Trm/ContractorUpdateForm";
import Viewcontractorform from "../../forms/viewcontractorform";
import { getRemoveContractor } from "../../Trm/Service/api";
import DataTableComponent from "../../../component/DataTable/dataTable";
import { AuthContext } from "../../../context/authContext";
import { CRMRole } from "../../../service/default";

const ContractorTable: React.FC = () => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  const [showAlert, setShowAlert] = React.useState(false);
  const [accordionValue, setAccordionValue] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  // const [modelOpen, setModal] = React.useState(false);
  const [showToast, setShowToast] = useState(false);
  const [deleteCtrid, setDeleteCtrid] = useState("");
  // const [groupList, setGroupList] = useState<any[]>([]);
  const [searchField, setSearchField] = useState<any>({
    page: 1,
    search_ctr: "",
    search_highlighted_skills: "",
    search_all_skills: "",
    show_only_profile_complete: "",
  });
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [contractorcomments, setcontractorcomments] = useState<any>([]);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [totalCountPerPage, setItemperPage] = useState<number>(30);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const column = [
    { name: "S.No", field: "id", sortable: false, value: "id" },
    {
      name: "Name",
      field: "fullName",
      sortable: false,
      value: "fullName,email,mobile",
      wordsplit: "",
      validate: "usergroup",
    },
    {
      name: "Core Skill",
      field: "practice1",
      sortable: false,
      value: "practice1+practice2",
      wordsplit: " - ",
    },
    {
      name: "Group",
      field: "group_name",
      sortable: false,
      value: "group_name",
      wordsplit: "",
    },
    {
      name: "Ready to Market",
      field: "profile_complete_updated_on",
      sortable: false,
      value: "profile_complete_updated_on",
      validate: "date",
      wordsplit: "",
    },
    {
      name: "",
      field: "",
      sortable: false,
      value:
        userdata.user_type === CRMRole
          ? "view:upload"
          : "download:edit:upload:view:delete",
      wordsplit: " ",
    },
  ];

  const getcontractorData = (paginationdata, data) => {
    setcontractorcomments([]);
    setShowLoading(true);
    const result = {
      page: paginationdata,
      search_ctr: data.search_ctr ?? "",
      search_highlighted_skills: data.search_highlighted_skills ?? "",
      search_all_skills: data.search_all_skills ?? "",
      show_only_profile_complete: data.show_only_profile_complete ? "1" : "0",
    };

    getContractorlist(result).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        const finalData = value.data.map(item => ({
          ...item,
          fullName: `${item.firstname} ${item.lastname}` || "",
        }))
        setcontractorcomments(finalData);
        setItemperPage(value.itemsCountPerPage);
        setTotalItemCount(value.totalItemsCount);
      } else {
        setItemperPage(0);
        setTotalItemCount(0);
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value !== undefined && value.message);
        setShowToast(true);
      }
    });
  };

  const contractorDeleteApi = (da: any) => {
    if (da) {
      getRemoveContractor(da).then((value: any) => {
        if (value !== undefined && value.status === 200) {
          setShowLoading(false);
          getcontractorData(currentPage, searchField);
          setToastColor("success");
          setToastMessage(value !== undefined && value.message);
          setShowToast(true);
          setDeleteCtrid("");
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(value !== undefined && value.message);
          setShowToast(true);
        }
      });
    }
  };

  // function getGroup() {
  //   getGrouplist("").then((e: any) => {
  //     if (e !== undefined && e.status === 200) {
  //       setGroupList(e.data);
  //     } else {
  //     }
  //   });
  // }

  const contractordownload = (da: any, filename: string) => {
    if (da && filename) {
      BrowserHandleExport("/Resumedownload/" + da, filename);
    } else {
      setToastColor("danger");
      setToastMessage("Resume not found");
      setShowToast(true);
    }
  };

  function contractoredit(da) {
    if (da) {
      onSave({ key: "ctrid", value: da.ctr_id });
      window.open("/ContractorProfile", "_blank");
    }
  }

  function updateChild(data: any) {
    if (data) {
      const Additionaldata = {
        title: "Additional data for contractor",
        contractorName: data.firstname + data.lastname,
        ctrid: data.ctr_id,
        required: userdata.user_type === CRMRole ? true : false,
        requestText: "",
        subTitle:
          "Contractor Name: " + StringMap(data.firstname + data.lastname),
      };
      onSave({ key: "additionaldata", value: Additionaldata });
      window.open("/trm/additional-data", "_blank");
      return;
    }
  }

  const searchForm = (value) => {
    if (value) {
      setSearchField({
        page: 1,
        search_ctr: value.search_ctr ?? "",
        search_highlighted_skills: value.search_highlighted_skills ?? "",
        search_all_skills: value.search_all_skills ?? "",
        show_only_profile_complete: value.show_only_profile_complete ?? false,
      });

      getcontractorData(1, value);
    }
  };

  const handlePageChange = (e) => {
    const selectedPage = e;
    setCurrentPage(selectedPage);
    getcontractorData(selectedPage, searchField);
  };

  function gotoscreen(value) {
    if (value) {
      window.open("/publicview/" + value, "_blank");
    } else {
      setToastColor("warning");
      setToastMessage("update your contractor profile");
      setShowToast(true);
    }
  }

  // const exportContractorData = (e) => {
  //   if (e) BrowserHandleExport("Jngstaff/export_CSV", "ContractorList.csv");
  // };

  const renderAction = (type, item) => {
    switch (type) {
      case "delete":
        setDeleteCtrid(item.ctr_id);
        setShowAlert(true);
        return;
      case "download":
        contractordownload(item.ctr_id, item.contractor_resume);
        return;
      case "edit":
        contractoredit(item);
        return;
      case "view":
        gotoscreen(item.public_id);
        return;
      case "upload":
        updateChild(item);
        return;
      default:
        return;
    }
  };

  React.useEffect(() => {
    let isMounted = true;
    try {
      setAccordionValue("");
      getcontractorData(1, searchField);
      // getGroup();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  return (
    <>
      {/* <ModalBody
        isOpen={modelOpen}
        onClose={() => setModal(false)}
        title="Additional data for contractor"
        subTitle={"Contractor Name: " + StringMap(contractorname)}
      >
        {modelOpen && (
          <>
            <ContractorUpdateForm
              ctrid={ctrdata}
              grouplist={groupList}
              closeModal={(e) => {
                getcontractorData(currentPage, searchField);
                setModal(false);
              }}
            />
          </>
        )}
      </ModalBody> */}
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={"Continue to delete the contractor"}
        subHeader={"Confirm here"}
        message={""}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setDeleteCtrid("");
              setShowAlert(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              contractorDeleteApi(deleteCtrid);
              setShowAlert(false);
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />

      <IonGrid className="center">
        <IonRow>
          <IonCol sizeLg="12" sizeXs="12">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <h3>All Contractors</h3>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol sizeLg="12" size="12">
                  {/* <IonButton
                    color="secondary capitalize"
                    shape="round"
                    fill="solid"
                    onClick={(e) => exportContractorData(e)}
                  >
                    Download CSV
                  </IonButton> */}
                  <IonButton
                    className="custom-button btn-margin-left"
                    color="secondary capitalize"
                    shape="round"
                    fill="solid"
                    onClick={(e) => setShowModal(true)}
                  >
                    Create Contractor
                  </IonButton>
                </IonCol>
                <IonCol sizeLg="12" size="12">
                  <form onSubmit={handleSubmit(searchForm)}>
                    <IonCard className="ion-padding">
                      <IonGrid className="m-0 p-0">
                        <IonRow>
                          <IonCol sizeLg="6" sizeXs="12">
                            <IonItem lines="none">
                              <IonLabel position="stacked">
                                Search by Name/Email/Contact No.
                              </IonLabel>
                              <Controller
                                control={control}
                                name="search_ctr"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <IonInput
                                    onIonChange={onChange}
                                    value={value}
                                    placeholder="Search by Name/Email/Contact No."
                                  />
                                )}
                              />
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      <IonAccordionGroup
                        className="ion-padding"
                        value={accordionValue}
                      >
                        <IonAccordion
                          value="advance"
                          toggleIcon={chevronDownOutline}
                          onClick={(e) => {
                            setAccordionValue("advance");
                          }}
                        >
                          <IonText slot="header" className="custom-text cursor">
                            <IonLabel>Advance search filters</IonLabel>
                          </IonText>

                          <IonGrid slot="content" className="m-0 p-0">
                            <IonRow>
                              <IonCol sizeLg="6" sizeXs="12">
                                <IonItem lines="none">
                                  <IonLabel position="stacked">
                                    Highlighted Skills
                                  </IonLabel>
                                  <Controller
                                    control={control}
                                    name="search_highlighted_skills"
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <IonInput
                                        onIonChange={onChange}
                                        value={value}
                                      />
                                    )}
                                  />
                                </IonItem>
                              </IonCol>
                              <IonCol sizeLg="6" sizeXs="12">
                                <IonItem lines="none">
                                  <IonLabel position="stacked">Skills</IonLabel>
                                  <Controller
                                    control={control}
                                    name="search_all_skills"
                                    render={({
                                      field: { onChange, onBlur, value, ref },
                                    }) => (
                                      <IonInput
                                        onIonChange={onChange}
                                        value={value}
                                      />
                                    )}
                                  />
                                </IonItem>
                              </IonCol>
                              <IonCol sizeLg="4" sizeXs="12">
                                <IonItem lines="none">
                                  <IonLabel position="stacked">
                                    Ready to market (yes/no)
                                  </IonLabel>
                                  <Controller
                                    render={({
                                      field: { onChange, value, ref },
                                    }) => (
                                      <IonToggle
                                        mode={"md"}
                                        checked={value}
                                        onIonChange={(e) => {
                                          setValue(
                                            "show_only_profile_complete",
                                            e.detail.checked
                                          );
                                        }}
                                      />
                                    )}
                                    control={control}
                                    name={"show_only_profile_complete"}
                                  />
                                </IonItem>
                              </IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonAccordion>
                      </IonAccordionGroup>
                      <div>
                        <IonButton
                          shape="round"
                          fill="solid"
                          color="secondary"
                          className="remove-shadow capitalizes"
                          type="submit"
                        >
                          Search
                        </IonButton>
                      </div>
                    </IonCard>
                  </form>
                </IonCol>
                <IonCol size="12">
                  <IonCard className="ion-padding">
                    <DataTableComponent
                      tableheaders={column}
                      tableData={contractorcomments}
                      currentPage={currentPage}
                      itemPerPage={totalCountPerPage}
                      totalItem={totalItemCount}
                      handleAction={(type, item) => {
                        renderAction(type, item);
                      }}
                      setCurrentPage={(page) => handlePageChange(page)}
                    />
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <ModalBody
        title="Create Contractor"
        subTitle={""}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <Viewcontractorform
          setShowModalResult={() => {
            setShowModal(false);
            getcontractorData(1, searchField);
          }}
        />
      </ModalBody>
    </>
  );
};

export default React.memo(ContractorTable);
