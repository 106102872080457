import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import { HeaderNav } from "../../../component";
import CompanyUser from "./companyuser";

const CompanyUsers: React.FC = () => {
  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <CompanyUser props={""} />
      </IonContent>
    </IonPage>
  );
};

export default CompanyUsers;
