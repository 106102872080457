export const desiredroleserr = [
  {
    name: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Role title is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    description: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Description is required",
        },
        {
          test: (value: any) => {
            return value.length < 280;
          },
          message: "Description must be less than 280 characters",
        },
        {
          test: (value: any) => {
            return value.length > 8;
          },
          message: "Description must be greater than 8 characters",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    working_hours: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "working hours is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    compensation: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Desired Compensation is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    compensation_type: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Compensation Type is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
  },
];

export const usercreaterr = [
  {
    firstname: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "firstname is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    lastname: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "lastname is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    username: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "username is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    email: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "email is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    password: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "password is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    // role_id: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "role is required",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },
  },
];

// personalinfo
export const profilepersonalinforule = [
  {
    email: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Email is required, ",
        },
        {
          test: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Enter your valid email address",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    country_code: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Cty code is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    mobile: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Mobile Number is required, ",
        },
        {
          test: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          message: "Enter your valid 10 digit Mobile Number, ",
        },
        {
          test: /^[0-9\b]+$/,
          message: "Enter only numeric value",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    firstname: {
      rules: [
        // {
        //   test: /^[a-z0-9_]+$/,
        //   message:
        //     "First Name must contain only alphabets-numeric lowercase characters, ",
        // },
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "First Name is required, ",
        },
        {
          test: (value: any) => {
            return value.length > 2;
          },
          message: "First Name must be longer than two characters",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    lastname: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Last Name is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    headline: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Headline is required, ",
        },
        {
          test: (value: any) => {
            return value.length > 8;
          },
          message: "Headline must be longer than 8 characters, ",
        },
        {
          test: (value: any) => {
            return value.length < 116;
          },
          message: "Headline must be less than 116 characters",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    // city: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "city is required",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },
    // state: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "state is required",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },
    // country: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "country is required",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },
  },
];

//  customer/organization validation form //
export const CustomerValidation = [
  {
    company_name: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 2;
          },
          message: "customer/organization is  required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    // organization end//
    geo_location: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "location is  required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    // organization end//

    // title: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "role is required, ",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },

    contractor_role: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Role is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    start_month: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "start month is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    start_year: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "start year is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    end_month: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "end month is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    end_year: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "end year is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    description: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Description is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    currently_working: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "currently_working is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    emt_id: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "select type of engagement anyone",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    latitude: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "latitude is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    longitude: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "longitude is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    employment_docs: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "employment_docs is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    doc_link: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "doc_link is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
  },
];

// add education validation form  start//
export const AddEqucation = [
  {
    inst_name: {
      rules: [
        // {
        //   test: (value: any) => {
        //     return value.length > 0;
        //   },
        //   message: "school name is required, ",
        // },
        // {
        //   test: (value: any) => {
        //     return value.length > 3;
        //   },
        //   message: "school Name must be longer than 3 characters, ",
        // },

        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "school Name is required, ",
        },
        {
          test: (value: any) => {
            return value.length > 2;
          },
          message: "school Name must be longer than two characters",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    degree: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "degree Name is required, ",
        },
        {
          test: (value: any) => {
            return value.length > 2;
          },
          message: "degree Name must be longer than two characters",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    field_study: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Field of study name is required, ",
        },

        {
          test: (value: any) => {
            return value.length > 2;
          },
          message: " Field of study Name must be longer than 2 characters, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    grade: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "field is required, ",
        },

        {
          test: (value: any) => {
            return value.length > 2;
          },
          message: " please enter you gpa/percentage/grade, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    start_year: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "start year is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    end_year: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "end year is required, ",
        },

        {
          test: (value: any) => {
            return value.length > 1;
          },
          message: "please select end year, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    activities_societies: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "activities is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    description: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "description is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    // uploadfile: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "upload file is required, ",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },
  },
];

export const Addcertification = [
  {
    name: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Name  is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    issue_organization: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "field  is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    credential_id: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Certification Id is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    credential_url: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Certification UR is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    issue_month: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "issue month  is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    issue_year: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "issue year  is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    expire_month: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "expiration month   is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    expire_year: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "expiration  year  is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    certifications_docs: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "certification_doc  is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    doc_link: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "doc_link  is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
  },
];

export const Achievements = [
  {
    title: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "title is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    associated: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "associated field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    // description: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "description field is required, ",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },
  },
];

export const Reference = [
  {
    name: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "referrernce field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    contact_no: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "contact Number is required, ",
        },
        {
          test: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          message: "Enter your valid 10 digit Mobile Number, ",
        },
        {
          test: /^[0-9\b]+$/,
          message: "Enter only numeric value",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    email_id: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Email is required, ",
        },
        {
          test: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Enter your valid email address",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    geo_location: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "location field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    // latitude: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "location field is required, ",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },

    // longitude: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "location field is required, ",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },
  },
];

export const Quickprofile = [
  {
    firstname: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "firstname field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    lastname: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "lastname field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    headline: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "headline field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    email: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Email is required, ",
        },
        {
          test: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Enter your valid email address",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    mobile: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Mobile Number is required, ",
        },
        {
          test: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          message: "Enter your valid 10 digit Mobile Number, ",
        },
        {
          test: /^[0-9\b]+$/,
          message: "Enter only numeric value",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    workinghours: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "workinghours field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    Availability: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Availability field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    Telecommute: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Telecommute field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    Desiredcompensation: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Desiredcompensation field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    CompensationType: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "CompensationType field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    Eligibilitytowork: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Eligibilitytowork field is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
  },
];

export const Addproject = [
  {
    organization: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 2;
          },
          message: "customer/organization is  required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    // organization end//
    location: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "location is  required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    // organization end//

    role: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "role is required, ",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    project_name: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "project name is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    start_month: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "start month is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    start_year: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "start year is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    end_month: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "end month is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    end_year: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "end year is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    description: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Description is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    project_docs: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Description is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    project_contribution: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Description is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
    project_skills: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Project skills  is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
  },
];

export const Viewcontractorerror = [
  {
    firstname: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "first name is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    lastname: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "last name is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    username: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "user name is required",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    emailid: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Email is required, ",
        },
        {
          test: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Enter your valid email address",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },

    // salesemail: {
    //   rules: [
    //     {
    //       test: (value: any) => {
    //         return value.length > 0;
    //       },
    //       message: "Sales email is required, ",
    //     },
    //     {
    //       test: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //       message: "Enter your valid email address",
    //     },
    //   ],
    //   errors: [],
    //   valid: false,
    //   state: "",
    // },

    password: {
      rules: [
        {
          test: (value: any) => {
            return value.length > 0;
          },
          message: "Password is required, ",
        },
        {
          test: "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}]:;',?/*~$^+=<>]).{8,20}$/",
          message: "Enter your valid Password",
        },
      ],
      errors: [],
      valid: false,
      state: "",
    },
  },
];
