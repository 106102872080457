import { IonIcon } from "@ionic/react";
import React, { useState } from "react";
import { arrowDown, arrowUp} from "ionicons/icons";
interface headerInterface {
  headers: {
    name: string;
    field: string;
    sortable: boolean;
    value?: string;
    wordsplit?: string;
    validate?: string;
  }[];
  onSorting: any;
}

const TableHeaders = ({ headers, onSorting }: headerInterface) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  return (
    <thead className="table-light">
      <tr>
        {headers !== undefined &&
          headers.map(({ name, field, sortable }) => (
            <th
              key={name}
              onClick={() => (sortable ? onSortingChange(field) : null)}
            >
              <div className="table-header">
                <span>{name}</span>

                {/* {sortingField && sortingField === field ? (
                  <IonIcon
                    icon={
                      sortingOrder === "asc"
                        ? arrowDown
                        : arrowUp
                    }
                  />
                ) : (
                  sortable && <IonIcon
                    icon={arrowDown}
                  />
                )} */}
              </div>
            </th>
          ))}
      </tr>
    </thead>
  );
};

export default TableHeaders;
