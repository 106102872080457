import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonLoading,
  IonRow,
} from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import React, { ChangeEvent, useState } from "react";
import { Input, SelectBox } from "../../component";
import { useTranslation } from "react-i18next";
import { postMethod } from "../../service";
import { usercreaterr } from "../../util/validation";

interface Props {
  setShowModal: Function;
  handleSubmits?: Function;
  companyUserRole: any;
  setHeaderType: Function;
  headerType?: boolean;
  editData: any;
}

const initialValues = {
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  password: "",
  role_id: "",
  // uid: "",
  //status:1,
};

const errValues = {
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  password: "",
  role_id: "",
};

const AddStaffUser: React.FC<Props> = ({
  setShowModal,
  handleSubmits,
  companyUserRole,
  headerType,
  setHeaderType,
  editData,
}) => {
  const { t, i18n } = useTranslation();
  const [showToast, setShowToast] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [error, setError] = useState<any>();
  const [userData, setUserdata] = useState(
    headerType ? initialValues : editData
  );
  const [fielderr, setFieldErr] = useState(errValues);

  const updateValidators = (fieldName: any, value: any) => {
    usercreaterr.forEach((e: any) => {
      e[fieldName].errors = [];
      e[fieldName].state = value;
      e[fieldName].valid = true;
      e[fieldName].rules.forEach((rule: any) => {
        if (rule.test instanceof RegExp) {
          if (!rule.test.test(value)) {
            e[fieldName].errors.push(rule.message);
            e[fieldName].valid = false;
          }
        } else if (typeof rule.test === "function") {
          if (!rule.test(value)) {
            e[fieldName].errors.push(rule.message);
            e[fieldName].valid = false;
          }
        }
      });
      setFieldErr({
        ...fielderr,
        [fieldName]: e[fieldName].errors,
      });
    });
  };

  // This method checks to see if the validity of all validators are true
  function isFormValid() {
    let status = true;
    usercreaterr.forEach((e: any) => {
      Object.keys(e).forEach((field: any) => {
        if (!e[field].valid) {
          status = false;
        }
      });
    });
    return status;
  }

  function handleChange(event: ChangeEvent<any>) {
    Object.keys(fielderr).forEach((e) => {
      if (e === event.target.name) {
        updateValidators(event.target.name, event.target.value);
        setUserdata({
          ...userData,
          [event.target.name]: event.target.value,
        } as any);
        isFormValid();
      } else {
        setUserdata({
          ...userData,
          [event.target.name]: event.target.value,
        } as any);
      }
    });
  }

  function handleChangedropdown(event) {
    Object.keys(fielderr).forEach((e) => {
      if (e === "role_id") {
        updateValidators("role_id", event.value);
        setUserdata({
          ...userData,
          "role_id": event.value,
        } as any);
        isFormValid();
      } else {
        setUserdata({
          ...userData,
          "role_id": event.value,
        } as any);
      }
    });
  }
  // }

  function handleSubmit(e: any) {
    e.preventDefault();
    setShowLoading(true);

    const postData = postMethod("/Addjngstaffrole", userData);
    postData.then((value: any) => {
      if (value.status === 200) {
        setShowLoading(false);
        setUserdata(initialValues);
        setShowModal(false);

        setToastColor("success");
        setToastMessage(value.data);
        setShowToast(true);
        setUserdata({
          firstname: "",
          lastname: "",
          username: "",
          email: "",
          password: "",
          role_id: "",
          uid: "",
          status: "",
        });
      } else if (value.status === 201) {
        setShowLoading(false);
        const errorInfo = value.message;
        if (typeof errorInfo === "string") {
          setError("");
          setToastColor("danger");
          setToastMessage(value.message);
          setShowToast(true);
        } else {
          setError("");
          setError(errorInfo);
        }
      } else {
        setShowLoading(false);
        setShowModal(false);
        setToastColor("danger");
        setToastMessage(value.message);
        setShowToast(true);
      }
    });
  }

  function handleUpdate(e: any) {
    e.preventDefault();
    setShowLoading(true);
    let updatedata = userData;
    userData["status"] = 1;

    const postData = postMethod("Updatejngstaffrole", updatedata);
    postData.then((value: any) => {
      if (value.status === 200) {
        setShowLoading(false);
        setUserdata(initialValues);
        setShowModal(false);

        setToastColor("success");
        setToastMessage(value.data);
        setShowToast(true);
        setUserdata({
          firstname: "",
          lastname: "",
          username: "",
          email: "",
          password: "",
          role_id: "",
          uid: "",
          status: "",
        });
      } else {
        setShowLoading(false);
        setShowModal(false);
        setToastColor("danger");
        setToastMessage(value.message);
        setShowToast(true);
      }
    });
  }

  return (
    <>
      <IonList lines="none">
        <IonItem>
          <h1>{headerType ? "Add User" : "Edit User"}</h1>
          <IonIcon
            slot="end"
            icon={closeCircle}
            onClick={() => {
              setShowModal(false);
              setHeaderType(true);
            }}
          />
        </IonItem>
        <div className="custom-content-modal">
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <Input
                  name="firstname"
                  placeholder="Enter First Name"
                  label="First Name"
                  type="text"
                  required={true}
                  value={userData.firstname}
                  onChange={handleChange}
                />
              </IonCol>
              <IonCol size="12">
                <Input
                  name="lastname"
                  placeholder="Enter Last Name"
                  label="Last Name"
                  type="text"
                  required={true}
                  value={userData.lastname}
                  onChange={handleChange}
                />
              </IonCol>
              <IonCol size="12">
                <Input
                  name="username"
                  placeholder="Enter User Name"
                  label="User Name"
                  type="text"
                  required={true}
                  value={userData.username}
                  onChange={handleChange}
                />
              </IonCol>

              <IonCol size="12">
                <Input
                  name="email"
                  placeholder="Enter Email ID"
                  label="Email ID"
                  type="text"
                  required={true}
                  value={userData.email}
                  onChange={handleChange}
                />
              </IonCol>

              {headerType === true ? (
                <IonCol sizeLg="12" sizeXs="12">
                  <SelectBox
                    name="role_id"
                    options={companyUserRole.map((domain) => ({
                      value: domain.rid,
                      label: domain.name,
                    }))}
                    placeholder={"Select Role"}
                    value={userData.role_id}
                    onChange={handleChangedropdown}
                    label={"Role"}
                    style={{ overflowY: "scroll" }}
                  />

                  {fielderr.role_id && (
                    <div className="red error">{fielderr.role_id}</div>
                  )}
                </IonCol>
              ) : (
                ""
              )}

              {headerType ? (
                <IonCol size="12">
                  <Input
                    name="password"
                    placeholder="Enter a strong password"
                    label="Password"
                    type="password"
                    required={true}
                    value={userData.password}
                    onChange={handleChange}
                  />
                </IonCol>
              ) : (
                ""
              )}
              <IonCol sizeLg="12" sizeXs="12">
                {error &&
                  error !== undefined &&
                  error !== null &&
                  error !== "" &&
                  // error.map((er: any) =>
                  Object.entries(error).map(([key, value]) => (
                    <div key={key} className="err red">
                      {[value]}
                    </div>
                  ))}
              </IonCol>

              <IonCol size="12">
                <IonButton
                  className="custom-button submit "
                  color="secondary capitalize"
                  shape="round"
                  fill="solid"
                  //disabled={isFormValid() ? false : true}
                  onClick={(e) =>
                    headerType ? handleSubmit(e) : handleUpdate(e)
                  }
                >
                  {headerType ? t("button.save") : t("button.update")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonList>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
    </>
  );
};
export default AddStaffUser;
