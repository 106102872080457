import {
  IonToast,
  IonLoading,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
  IonChip,
  IonIcon,
  IonLabel,
  IonInput,
  IonButton,
  IonItem,
} from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { CardProfileImage, Ceditor } from "../../../../component";
import { JobInviteTalent } from "../../Service/api";

export default function InviteTalent({ closeModal, uniqueid }: any) {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [nextStep, setNextStep] = React.useState<any>("1");
  const [emailData, setEmailData] = React.useState<any>("");
  const [emailArray, setEmailArray] = React.useState<any>([]);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(false);

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  React.useEffect(() => {
    setValue(
      "message",
      "<p>Hi ,</p><p>We believe you're a great fit for the role of Senior Product Manager with our company, ionidea. We'd appreciate if you take the time to check out the position on Hirify and apply if you're interested.</p>"
    );
  }, []);

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  const addEmail = (da: any) => {
    if (da) {
      const trimmed = da.trim();
      if (trimmed && !emailArray.includes(trimmed)) {
        setEmailArray((emailArray) => [...emailArray, da]);
      }
      setEmailData("");
      setValue("email", "");
    }
  };

  const saveInviteData = (data: any) => {
    if(data){
    setShowLoading(true);
    data.unique_id = uniqueid;
    data.email = emailArray;
    JobInviteTalent(data).then((value) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        setToastColor("success");
        setToastMessage("Invite e-mail has been sent");
        setShowToast(true);
        reset();
        setEmailArray([]);
        setEmailData("");
        setValue(
          "message",
          "<p>Hi,</p><p>&nbsp;</p><p>We believe you're a great fit for the role of Senior Product Manager with our company, ionidea. We'd appreciate if you take the time to check out the position on Hirify and apply if you're interested.</p>"
        );
        closeModal(false);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value !== undefined && value.message);
        setShowToast(true);
      }
    });
  }
  };

  return (
    <div>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <form onSubmit={handleSubmit(saveInviteData)}>
        {nextStep === "1" ? (
          <IonCard className="ion-padding m-lg-3">
            <IonCardHeader>
              <IonCardSubtitle>Step 1/2</IonCardSubtitle>
              <h1 className="my-0">Add talent</h1>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid className="ion-no-padding">
                <IonRow>
                  <IonCol sizeLg="6" sizeXs="12">
                    <div className="locationInput">
                      <IonLabel position="stacked">Email</IonLabel>
                      <Controller
                        control={control}
                        name="email"
                        rules={{
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "invalid email address",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <input
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              setEmailData(e.target.value);
                            }}
                            placeholder="Email"
                          />
                        )}
                      />
                      <div
                        className="custom-groupInput-label-icon"
                        style={{ top: "41px" }}
                        onClick={() => addEmail(emailData)}
                      >
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/add.svg"
                          name="email"
                        />
                      </div>
                    </div>
                    {showError("email")}
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    {emailArray.length > 0 &&
                      emailArray.map((a: any) => (
                        <IonChip color="orangeblack" className="">
                          <IonLabel className="ion-text-wrap">{a}</IonLabel>
                          <IonIcon
                            icon={closeCircleOutline}
                            onClick={() =>
                              setEmailArray(
                                emailArray.filter((item) => item !== a)
                              )
                            }
                          />
                        </IonChip>
                      ))}
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="text-right">
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="orange"
                        className="remove-shadow capitalizes"
                        type="button"
                        onClick={(e) => {
                          emailArray.length > 0 && setNextStep("2");
                        }}
                      >
                        Next
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        ) : (
          <IonCard className="ion-padding m-lg-3">
            <IonCardHeader>
              <IonCardSubtitle>Step 2/2</IonCardSubtitle>
              <h1 className="my-0">Edit message</h1>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg="12" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">Message</IonLabel>
                      <Controller
                        control={control}
                        name="message"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Ceditor
                            setEditorData={onChange}
                            editorData={value}
                          />
                        )}
                      />
                    </IonItem>
                    {showError("message")}
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <label>Link to job (added to message automatically)</label>
                    {/* <br />
                    <span className="text-orange">
                      jobsNgigs.com/ionidea/jobs/senior-product-manager-123456
                    </span> */}
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <div className="text-right">
                      <IonButton
                        shape="round"
                        fill="outline"
                        color="medium"
                        className="remove-shadow capitalizes"
                        type="button"
                        onClick={(e) => setNextStep("1")}
                      >
                        Previous
                      </IonButton>
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="orange"
                        className="remove-shadow capitalizes"
                        type="submit"
                        // onClick={(e) => setNextStep("2")}
                      >
                        Submit
                      </IonButton>
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        )}
      </form>
    </div>
  );
}
