import { IonButton, IonCol, IonGrid, IonInput, IonRow } from "@ionic/react";
import React, { useState } from "react";

interface searchInterface {
  onSearch: (search: string) => void;
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
}

const TableSearch = ({
  onSearch,
  activePage,
  itemsCountPerPage,
  totalItemsCount,
}: searchInterface) => {
  const [search, setSearch] = useState("");
  const onInputChange = (value) => {
    setSearch(value);
  };
  return (
    <IonGrid className="ion-no-padding">
      <IonRow>
        {/* <IonCol sizeLg="6" sizeMd="12" sizeXs="12" sizeSm="12">
          <div className="my-3 text-gray">
            Showing {activePage === 1 ? 1 : (activePage - 1) * itemsCountPerPage + 1}
            {" - "}
            {activePage === 1
              ? itemsCountPerPage
              : (activePage - 1) * itemsCountPerPage + itemsCountPerPage > totalItemsCount
              ? totalItemsCount
              : (activePage - 1) * itemsCountPerPage + itemsCountPerPage}
            {" of " + totalItemsCount}
          </div>
        </IonCol> */}
        <IonCol sizeLg="6" sizeMd="12" sizeXs="12" sizeSm="12">
          <div className="d-lg-flex d-ms-flex d-xl-flex d-sm-flex">
            <IonInput
              placeholder="Search"
              value={search}
              onIonChange={(e) => onInputChange(e.detail.value)}
            />
            <IonButton
              shape="round"
              fill="solid"
              color="secondary"
              className="remove-shadow capitalizes"
              type="submit"
              onClick={() => onSearch(search)}
            >
              Search
            </IonButton>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TableSearch;
