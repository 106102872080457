import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonTextarea,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ModalBody } from "../../component";
import DataTableComponent from "../../component/DataTable/dataTable";
import { AuthContext } from "../../context/authContext";
import useMediaQuery from "../../hooks/mediaQuery";
import {
  GetInterviewFeedback,
  GetTrmtouchPoint,
  SaveTrmTouchPoint,
  UpdateInterviewFeedback,
  UpdateTrmTouchPoint,
} from "./Service/api";

export default function TrmtouchPoint({ ctrid }) {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  const [modelOpen, setModal] = React.useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [touchpointData, setTouchpointData] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [formType, setFormType] = useState("add");
  const [toastColor, setToastColor] = useState("danger");
  const [showToast, setShowToast] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [totalCountPerPage, setItemperPage] = useState<number>(100);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [editValue, setEditValue] = useState<any>({});
  const column = [
    { name: "S.No", field: "id", sortable: false, value: "id" },

    {
      name: "Notes about conversation",
      field: "remarks",
      sortable: false,
      value: "remarks",
      wordsplit: "",
    },
    {
      name: "Trm Name",
      field: "trmfullName",
      sortable: false,
      value: "trmfullName",
      wordsplit: "",
      validate: "",
    },
    {
      name: "updated on",
      field: "updated_on",
      sortable: false,
      value: "updated_on",
      validate: "date",
      wordsplit: "",
    },
    {
      name: "",
      field: "",
      sortable: false,
      value: ":edit",
      wordsplit: " ",
    },
  ];

  const renderAction = (type, item) => {
    switch (type) {
      case "edit":
        setFormType("edit");
        setValue("remarks", item.remarks ?? "");
        setValue("ctr_rating", item.ctr_rating ?? "");
        setEditValue(item);
        setModal(true);
        return;
      case "create":
        reset();
        setFormType("add");
        setModal(true);
        return;
      default:
        return;
    }
  };

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  const GetTrmtouchPointData = (ctrid) => {
    setTouchpointData([]);
    GetTrmtouchPoint(ctrid).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        const finalData = value.data.map((item) => ({
          ...item,
          trmfullName: `${item.trmfirstname} ${item.trmlastname}` || "",
        }));
        setTouchpointData(finalData);
        // setItemperPage(value.itemsCountPerPage);
        // setTotalItemCount(value.totalItemsCount);
      } else {
        // setItemperPage(0);
        // setTotalItemCount(0);
        setShowLoading(false);
        // setToastColor("danger");
        // setToastMessage(value !== undefined && value.message);
        // setShowToast(true);
      }
    });
  };

  const handlePageChange = (e: number) => {
    if (e) {
      setCurrentPage(e);
      return;
    }
  };
  useEffect(() => {
    GetTrmtouchPointData(ctrid);
  }, [ctrid]);

  const updateremarksFeedback = (values) => {
    if (values) {
      updateFeedback(values);
    }
  };
  const updateFeedback = (value) => {
    const req = {
      ctr_id: ctrid,
      remarks: value.remarks,
      user_id: userdata.uid,
      trmp_id: formType === "add" ? "" : editValue.trmp_id,
    };

    (formType === "add"
      ? SaveTrmTouchPoint(req)
      : UpdateTrmTouchPoint(req)
    ).then((value) => {
      if (value !== undefined && value.status === 200) {
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
        setShowLoading(false);
        setModal(false);
        if (formType === "edit") {
          const newArray: any = [];
          touchpointData.filter((id: any) => {
            if (id.trmp_id === req.trmp_id) {
              id.remarks = req.remarks ?? "";
              newArray.push(id);
            } else {
              newArray.push(id);
            }
          });
          setTouchpointData(newArray);
        } else {
          GetTrmtouchPointData(ctrid);
        }
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again"
        );
        setShowToast(true);
      }
    });
  };

  return (
    <div>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <div className="d-flex ion-wrap ion-align-items-center ion-justify-content-between py-2">
        <IonLabel>Trm Touch Point</IonLabel>
        <IonButton
          slot="end"
          shape="round"
          fill="solid"
          color="orange"
          className="remove-shadow capitalizes"
          type="button"
          onClick={() => {
            renderAction("create", {});
          }}
        >
          Add Trm Touch Point
        </IonButton>
      </div>

      <IonCard className="ion-padding">
        <DataTableComponent
          tableheaders={column}
          tableData={touchpointData}
          currentPage={currentPage}
          itemPerPage={totalCountPerPage}
          totalItem={touchpointData.length}
          handleAction={(type, item) => {
            renderAction(type, item);
          }}
          setCurrentPage={(page) => handlePageChange(page)}
        />
      </IonCard>
      <ModalBody
        isOpen={modelOpen}
        onClose={() => setModal(false)}
        title="Notes about conversation"
        required={true}
        subTitle={""}
      >
        {modelOpen && (
          <IonCard className="ion-padding">
            <IonGrid>
              <form onSubmit={handleSubmit(updateremarksFeedback)}>
                <IonRow>
                  <IonCol sizeLg="12" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                      Add notes about your conversation <span className="required">*</span>
                      </IonLabel>
                      <Controller
                        control={control}
                        name="remarks"
                        rules={{
                          required: "notes about your conversation is required",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonTextarea
                            onIonChange={onChange}
                            value={value}
                            placeholder=""
                          />
                        )}
                      />
                    </IonItem>
                    {showError("remarks")}
                  </IonCol>

                  <IonCol size="12">
                    <IonButton
                      shape="round"
                      fill="solid"
                      color="secondary"
                      className="remove-shadow capitalizes"
                      type="submit"
                    >
                      Save
                    </IonButton>
                  </IonCol>
                </IonRow>
              </form>
            </IonGrid>
          </IonCard>
        )}
      </ModalBody>
    </div>
  );
}
