import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
} from "@ionic/react";
import moment from "moment";
import React, { useEffect } from "react";
import { AuthContext } from "../../../../../context/authContext";
import { addDefaultSrc, SeverfilePaths } from "../../../../../service";
import {
  Company,
  formatPhoneNumber,
  Staff,
  TRM,
} from "../../../../../service/default";

interface props {
  referenceData: any;
  ctrdata: string;
  filekeypath: string;
}

interface fields {
  firstname: string;
  lastname: string;
  email_id: string;
  project_title: string;
  role: string;
  photo: string;
  country_code: string;
  mobile: string;
  organization_name: string;
  linkedin_url: string;
  start_date: moment.Moment;
  end_date: moment.Moment;
  project_ongoing: boolean;
  ctr_id: string;
  ctr_rid: string;
}

const initialValues: fields = {
  firstname: "",
  lastname: "",
  email_id: "",
  organization_name: "",
  project_title: "",
  role: "",
  photo: "",
  country_code: "",
  mobile: "",
  linkedin_url: "",
  start_date: moment(),
  end_date: moment(),
  project_ongoing: false,
  ctr_id: "",
  ctr_rid: " ",
};

const References: React.FC<props> = ({
  referenceData,
  filekeypath,
  ctrdata,
}) => {
  const [referUser, setRefferUser] = React.useState<fields[]>([]);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [slice, setSlice] = React.useState(3);
  const [showLoading, setshowLoading] = React.useState(false);
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  // const userdata = getCookie({ key: "user" }).value;
  useEffect(() => {
    if (!dataupdated) {
      setRefferUser(referenceData);
      setDataupdated(true);
    }
  }, []);

  return (
    <div id="references">
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
        message={"Please Wait.."}
      />

      <IonItem lines="none" className="custom-header-space">
        <h4>Reference{referUser.length > 1 && "(s)"}</h4>
      </IonItem>

      <IonGrid className="ion-no-padding">
        {referUser.slice(0, slice).map((e: any, i: number) => (
          <IonRow key={i}>
            <IonCol sizeLg="4" sizeXs="12">
              <IonItem lines="none">
                <img
                  className="small-profile"
                  onError={addDefaultSrc}
                  src={SeverfilePaths(filekeypath) + e.photo}
                />
                {e.firstname} {e.lastname}
              </IonItem>
            </IonCol>

            <IonCol sizeLg="8" sizeXs="12">
              <IonItem lines="none">
                <IonLabel className="ion-text-wrap">
                  <h6>{e.organization_name}</h6>
                  {userdata &&
                  (userdata.user_type === TRM ||
                    userdata.user_type === Company ||
                    userdata.user_type === Staff) ? (
                    <>
                      <h6>{e.email_id}</h6>

                      <p>
                        {e.country_code} {formatPhoneNumber(e.mobile)}
                      </p>

                      <a href={e.linkedin_url} target="_blank">
                        {e.linkedin_url}
                      </a>
                    </>
                  ) : null}
                </IonLabel>
              </IonItem>

              {i !== referUser.length - 1 && <hr />}
            </IonCol>
          </IonRow>
        ))}
        <IonItem lines="none">
          {referUser.length > 0 &&
          referUser.length !== slice &&
          referUser.length >= slice ? (
            <IonButton
              slot="end"
              fill="clear"
              color="secondary"
              type="button"
              size="small"
              className="remove-shadow lowercase"
              onClick={() => setSlice(slice + 3)}
            >
              {referUser.length - slice} more reference(s)
            </IonButton>
          ) : (
            referUser.length > 3 &&
            referUser.length < slice && (
              <IonButton
                slot="end"
                fill="clear"
                color="secondary"
                type="button"
                size="small"
                className="remove-shadow lowercase"
                onClick={() => setSlice(3)}
              >
                less reference(s)
              </IonButton>
            )
          )}
        </IonItem>
      </IonGrid>
    </div>
  );
};

export default References;
