import {
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React from "react";
import { AuthContext } from "../../../../../context/authContext";
import { arrayKeymatch, getCookie, SplitString } from "../../../../../service";
import { Contractor, Staff, TRM } from "../../../../../service/default";
import AdditionalSkills from "./AdditionalSkills";
import Certifications from "./Certifications";
import Education from "./Education";
import Languages from "./Languages";
import Practice from "./Practice";
import { Skills } from "./Skills";
import Tools from "./Tools";

export default function Qualification({ personalData }: any) {
  const { auth } = React.useContext(AuthContext);
  const userType = auth.data;
  // const userType = getCookie({ key: "user" }).value;
  const [dataupdated, setDataupdated] = React.useState(false);
  const [skillcount, setSkillcount] = React.useState(0);
  const [toolscount, setToolcount] = React.useState<any>(0);
  const [langCount, setlanguage] = React.useState<any>(0);
  React.useEffect(() => {
    const skill = personalData.skill_count;
    const tool =
      personalData.tools_technologis !== undefined &&
      personalData.tools_technologis.beginner_tools.length +
        personalData.tools_technologis.expert_tools.length +
        personalData.tools_technologis.intermediate_tools.length;

    const language =
      personalData.language !== undefined &&
      personalData.language.beginner_language.length +
        personalData.language.intermediate_language.length +
        personalData.language.expert_language.length;
    if (!dataupdated) {
      setSkillcount(skill);
      setToolcount(tool);
      setlanguage(language);
      setDataupdated(true);
    }
  }, []);
  return (
    <>
      {" "}
      {dataupdated && (
        <div id="qualifications">
          <h1 className="h1-pd">Qualifications</h1>
          {(userType && userType.user_type === TRM) ||
          (userType && userType.user_type === Staff) ||
          (userType && userType.user_type === Contractor) ? (
            <IonCard className="pad-style">
              <Practice
                ctridData={personalData.ctr_id}
                proficiencytypelist={""}
                practiceData={
                  personalData !== undefined && personalData.ctr_practices
                }
                ListofskillsOptionData={""}
              />
            </IonCard>
          ) : null}

          {/* {(userType  && userType.user_type === TRM) ||
          (userType  && userType.user_type === Staff) ||
          (userType && userType.user_type === Contractor)
            ? personalData.skill_count !== 0 && (
                <IonCard className="pad-style">
                  <Skills skillData={arrayKeymatch(personalData.skills)} />
                </IonCard>
              )
            : */}
          {personalData.skill_count !== 0 &&
            personalData.highlightedskill_count === 0 && (
              <IonCard className="pad-style">
                <Skills skillData={arrayKeymatch(personalData.skills)} />
              </IonCard>
            )}

          {personalData.highlightedskill_count !== 0 && (
            <IonCard className="pad-style">
              <AdditionalSkills
                skillData={arrayKeymatch(personalData.highlightedskills)}
              />
            </IonCard>
          )}

          {toolscount !== 0 && (
            <IonCard className="pad-style">
              <Tools
                toolsData={arrayKeymatch(personalData.tools_technologis)}
              />
            </IonCard>
          )}
          {personalData.educations.length !== 0 && (
            <IonCard className="pad-style">
              <Education educationData={personalData.educations} />
            </IonCard>
          )}
          {personalData.certifications.length !== 0 && (
            <IonCard className="pad-style">
              <Certifications
                certificateData={
                  personalData.certifications !== undefined &&
                  personalData.certifications
                }
                folderkeyData={personalData.folder_key ?? ""}
              />
            </IonCard>
          )}

          {langCount !== 0 && (
            <IonCard className="pad-style">
              <Languages languageData={arrayKeymatch(personalData.language)} />
            </IonCard>
          )}
        </div>
      )}
    </>
  );
}

export const CommonSection = ({ arrs, type }: any) => {
  const [sliceCom, setSlice] = React.useState(9);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [common, setCommon] = React.useState([]);
  React.useEffect(() => {
    if (!dataupdated) {
      setCommon(arrs);
      setDataupdated(true);
    }
  }, []);

  return (
    <IonGrid className="ion-no-padding">
      {common &&
        common.map(
          (e: any, i: number) =>
            e.arr.length > 0 && (
              <IonRow key={i}>
                <IonCol sizeLg="4" sizeXs="12">
                  <label className="capitalize">
                    {SplitString(e.title, "_")}&nbsp;({e.arr.length})
                  </label>
                </IonCol>
                <IonCol sizeLg="8" sizeXs="12">
                  {e.arr.slice(0, sliceCom).map((r: any, j: number) => (
                    <span key={j}>
                      <span>
                        {r.skill_name
                          ? r.skill_name
                          : r.tool_name
                          ? r.tool_name
                          : r.language_name}
                      </span>
                      &nbsp;{e.arr.length - 1 !== j && "•"}&nbsp;
                    </span>
                  ))}
                  {e.arr.length > 9 && (
                    <IonItem lines="none">
                      {e.arr.length > 0 &&
                      e.arr.length !== sliceCom &&
                      e.arr.length >= sliceCom ? (
                        <IonButton
                          fill="clear"
                          color="secondary"
                          type="button"
                          size="small"
                          className="remove-shadow lowercase"
                          onClick={() => setSlice(sliceCom + 9)}
                        >
                          {e.arr.length - sliceCom} more {type}
                        </IonButton>
                      ) : (
                        e.arr.length > 9 &&
                        e.arr.length <= sliceCom && (
                          <IonButton
                            fill="clear"
                            color="secondary"
                            type="button"
                            size="small"
                            className="remove-shadow lowercase"
                            onClick={() => setSlice(9)}
                          >
                            less {type}
                          </IonButton>
                        )
                      )}
                    </IonItem>
                  )}
                  {i !== common.length - 1 && <hr />}
                </IonCol>
              </IonRow>
            )
        )}
    </IonGrid>
  );
};

export const CommonSectionSkill = ({
  arrs,
  type,
  screenType,
  editValues,
  removeData,
  catagory,
}: any) => {
  const [sliceCom, setSlice] = React.useState(9);
  return (
    <div>
      {screenType !== "form"
        ? arrs &&
          arrs.map((e: any, i: number) =>
            e.arr.length ? (
              <div key={i}>
                {/* heading */}

                <label className="capitalize">
                  {SplitString(e.title, "_")}&nbsp;({e.arr.length})
                </label>

                {/* details */}
                <p>
                  {e.arr.slice(0, sliceCom).map((r: any, j: any) => (
                    <span key={j}>
                      <span>
                        {r.skill_name
                          ? r.skill_name
                          : r.tool_name
                          ? r.tool_name
                          : r.language_name}
                      </span>
                      &nbsp;{e.arr.length - 1 !== j && "•"}&nbsp;
                    </span>
                  ))}
                  {e.arr.length > 9 && (
                    <IonItem lines="none">
                      {e.arr.length > 0 &&
                      e.arr.length !== sliceCom &&
                      e.arr.length >= sliceCom ? (
                        <IonButton
                          fill="clear"
                          color="secondary"
                          type="button"
                          size="small"
                          className="remove-shadow lowercase"
                          onClick={() => setSlice(sliceCom + 9)}
                        >
                          {e.arr.length - sliceCom} more {type}
                        </IonButton>
                      ) : (
                        e.arr.length > 9 &&
                        e.arr.length <= sliceCom && (
                          <IonButton
                            fill="clear"
                            color="secondary"
                            type="button"
                            size="small"
                            className="remove-shadow lowercase"
                            onClick={() => setSlice(9)}
                          >
                            less {type}
                          </IonButton>
                        )
                      )}
                    </IonItem>
                  )}
                  {/* {i !== arrs.length - 1 && <hr />} */}
                </p>
              </div>
            ) : null
          )
        : arrs.map((e: any, i: number) =>
            e.arr.length ? (
              <div key={i}>
                {/* heading */}

                <label className="capitalize">
                  {SplitString(e.title, "_")}&nbsp;({e.arr.length})
                </label>

                {/* details */}
                <p>
                  {e.arr.slice(0, sliceCom).map((r: any, j: number) => (
                    <IonChip
                      key={j}
                      style={{
                        "background-color": "rgba(24, 184, 252, 0.08)",
                      }}
                    >
                      <IonLabel onClick={() => editValues(r)}>
                        {r.skill_name
                          ? r.skill_name
                          : r.tool_name
                          ? r.tool_name
                          : r.language_name}
                      </IonLabel>
                      <IonIcon
                        color="danger"
                        icon={close}
                        onClick={() => removeData(r, catagory, e.title)}
                      />
                    </IonChip>
                  ))}
                  {e.arr.length > 9 && (
                    <IonItem lines="none">
                      {e.arr.length > 0 &&
                      e.arr.length !== sliceCom &&
                      e.arr.length >= sliceCom ? (
                        <IonButton
                          fill="clear"
                          color="secondary"
                          type="button"
                          size="small"
                          className="remove-shadow lowercase"
                          onClick={() => setSlice(sliceCom + 9)}
                        >
                          {e.arr.length - sliceCom} more {type}
                        </IonButton>
                      ) : (
                        e.arr.length > 9 &&
                        e.arr.length <= sliceCom && (
                          <IonButton
                            fill="clear"
                            color="secondary"
                            type="button"
                            size="small"
                            className="remove-shadow lowercase"
                            onClick={() => setSlice(9)}
                          >
                            less {type}
                          </IonButton>
                        )
                      )}
                    </IonItem>
                  )}
                  {/* {i !== arrs.length - 1 && <hr />} */}
                </p>
              </div>
            ) : null
          )}
    </div>
  );
};
