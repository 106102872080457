import { IonInput } from "@ionic/react";
import React, { useState } from "react";

const Search = ({ onSearch, value, onclickaction }: any) => {
  // const [search, setSearch] = useState(value);

  const onInputChange = (value) => {
    // setSearch(value);
    onSearch(value);
  };
  return (
    <div className="sort-input-section">
      <IonInput
        value={value}
        placeholder="Search"
        onIonChange={(e: any) => onInputChange(e.detail.value)}
      />
      {/* <input
        type="text"
        className="form-control"
        placeholder="Search"
        value={value}
        onChange={(e) => onInputChange(e.target.value)}
      /> */}
      <div className="search-icon " onClick={(e) => onclickaction(e)}>
        <img src="assets/image/company/search.svg" />
      </div>
    </div>
  );
};

export default Search;
