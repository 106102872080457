import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonRow,
  IonSlide,
  IonSlides,
  IonToast,
} from "@ionic/react";
import { FormProvider, useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { apiCallBack, arrayOptionValueData } from "../../../service";
import {
  getDeikaaProfileData,
  getQuickProfileData,
  saveQuickProfile,
} from "../Service/api";
import { UploadFile } from "./UploadFile";
import { MandatoryDetails } from "./MandatoryDetails";
import { ModalDeikaa } from "../../../component";
import { AuthContext } from "../../../context/authContext";
import { decrypted } from "../../../service/axios";
import useMediaQuery from "../../../hooks/mediaQuery";

interface Props extends RouteComponentProps<{}> {}
interface CurrentFields {
  firstname: string;
  lastname: string;
  email: string;
  country_code: string;
  mobile: string;
  profile_photo: string;
  headline: string;
  jobrole: string;
  country: any;
  city: any;
  constraint_worklocate: string;
  visatype: string;
  eligible_work_permit: string;
  is_contract_job: any;
  currency: string;
  contract_duration: string;
  contract_duration_type: string;
  contract_start_date: any;
  contract_telecommute: string;
  contract_telecommute_type: string;
  contract_compensation: string;
  contract_compensation_type: string;
  is_permanent_job: any;
  permanent_currency_type: string;
  permanent_duration: string;
  permanent_duration_type: string;
  contract_engagement_type: string;
  permanent_start_date: any;
  permanent_telecommute: string;
  permanent_telecommute_type: string;
  permanent_compensation: string;
  permanent_compensation_type: string;
  zip: string;
  state: any;
}

const fields: CurrentFields = {
  firstname: "",
  lastname: "",
  email: "",
  country_code: "+91",
  mobile: "",
  profile_photo: "",
  headline: "",
  jobrole: "",
  country: "",
  city: "",
  zip: "",
  state: "",
  constraint_worklocate: "",
  visatype: "",
  eligible_work_permit: "",
  is_contract_job: false,
  currency: "1",
  contract_duration: "",
  contract_duration_type: "1",
  contract_start_date: "",
  contract_telecommute: "",
  contract_telecommute_type: "1",
  contract_compensation: "",
  contract_compensation_type: "1",
  is_permanent_job: false,
  permanent_currency_type: "1",
  permanent_duration: "",
  permanent_duration_type: "1",
  permanent_start_date: "",
  contract_engagement_type: "",
  permanent_telecommute: "",
  permanent_telecommute_type: "1",
  permanent_compensation: "",
  permanent_compensation_type: "1",
};

const slideOpts = {
  initialSlide: 0,
  speed: 400,
  slidesPerView: 1,
  centeredSlides: true,
  autoHeight: true,
};
const ResumeUpload: React.FC<Props> = ({ history }) => {
  const mySlides = useRef<any>(null);
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [modal, setModal] = React.useState(false);
  const { auth } = React.useContext(AuthContext);
  const user = auth.data;
  // const user = getCookie({ key: "user" }).value;
  const [formData, setFormData] = useState<CurrentFields>(fields);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setshowLoading] = React.useState(false);
  const [profileData, setProfileData] = React.useState<any>();

  const methods = useForm({
    mode: "all",
    defaultValues: formData,
  });
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
  } = methods;

  useEffect(() => {
    mySlides.current.lockSwipes(true);
  });

  const next = async (fields: any) => {
    const result = await trigger(fields);
    if (!result) return;
    await mySlides.current.lockSwipes(false);
    await mySlides.current.slideNext();
    await mySlides.current.lockSwipes(true);
  };

  const prev = async () => {
    await mySlides.current.lockSwipes(false);
    await mySlides.current.slidePrev();
    await mySlides.current.lockSwipes(true);
  };

  function ModalDeikaas(data: any) {
    setModal(false);
    setshowLoading(true);
    if (data === "yes") {
      setModal(false);
      getDeikaaProfileData(user.ctr_id, "1").then((e: any) => {
        if (e !== undefined && e.status === 200) {
          history.replace("/ContractorProfile");
          setshowLoading(false);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
          setModal(false);
        } else {
          setshowLoading(false);
          setToastColor("danger");
          setToastMessage(e !== undefined ? e.message : "Please try again.");
          setShowToast(true);
        }
      });
    } else {
      setModal(false);
      setshowLoading(false);
      history.replace("/ContractorProfile");
    }
  }

  const closeModelSection = (e: any) => {
    if (e) {
      setshowLoading(true);
      setModal(false);
      setTimeout(() => {
        history.replace("/ContractorProfile");
        setshowLoading(false);
      }, 1000);
    }
  };

  const onSubmit = (data: any) => {
    // setFormData(data);
    setshowLoading(true);
    // const user = getCookie({ key: "user" }).value;
    data.ctr_id = user.ctr_id;
    // data.contractor_resume = data.contractor_resume
    //   ? data.contractor_resume[0].name
    //   : "";
    if (
      parseInt(data.contract_duration) < parseInt(data.contract_telecommute)
    ) {
      setToastColor("warning");
      setToastMessage("Contract telecommute is less then available duration");
      setShowToast(true);
      setshowLoading(false);
      return;
    }
    if (
      (data.is_contract_job === "1" && data.contract_duration_type === "") ||
      (data.is_contract_job === "1" && data.contract_duration === 0) ||
      (data.is_contract_job === "1" && data.contract_telecommute === 0) ||
      (data.is_contract_job === "1" && data.contract_telecommute_type === "") ||
      (data.is_permanent_job === "1" && data.permanent_duration_type === "") ||
      (data.is_permanent_job === "1" &&
        data.permanent_telecommute_type === "") ||
      (data.is_permanent_job === "1" && data.permanent_duration === 0) ||
      (data.is_permanent_job === "1" && data.permanent_telecommute === 0)
    ) {
      setToastColor("warning");
      setToastMessage("Please fill the details ");
      setShowToast(true);
      setshowLoading(false);
      return;
    }
    if (
      parseInt(data.permanent_duration) < parseInt(data.permanent_telecommute)
    ) {
      setToastColor("warning");
      setToastMessage("Permanent telecommute is less then available duration");
      setShowToast(true);
      setshowLoading(false);
      return;
    }
    data.country_code = data.country_code ?? "+91";
    data.headline = data.headline ?? "";
    data.mobile = data.mobile ?? "";
    data.permanent_start_date = data.permanent_start_date
      ? data.permanent_start_date
      : "";
    data.contract_start_date = data.contract_start_date
      ? data.contract_start_date
      : "";
    data.contract_telecommute =
      data.contract_duration_type === "1"
        ? Number(data.contract_telecommute) * 5
        : data.contract_duration_type === "3"
        ? Math.round((Number(data.contract_telecommute) * 100) / 4)
        : data.contract_telecommute ?? "";
    data.permanent_telecommute =
      data.permanent_duration_type === "1"
        ? Number(data.permanent_telecommute) * 5
        : data.permanent_duration_type === "3"
        ? Math.round((Number(data.permanent_telecommute) * 100) / 4)
        : data.permanent_telecommute ?? "";
    data.country = arrayOptionValueData(data.country, "label");
    data.state = arrayOptionValueData(data.state, "label");
    data.city = arrayOptionValueData(data.city, "label");
    saveQuickProfile(data).then((e) => {
      if (e !== undefined && e.status === 201) {
        setshowLoading(false);
        setToastColor("warning");
        setToastMessage(e.message ?? "Profile update failed, please try again");
        setShowToast(true);
      }
      if (apiCallBack(e)) {
        setshowLoading(false);
        setModal(true);
        setToastColor("success");
        setToastMessage("Profile saved successfully ");
        setShowToast(true);
        // history.replace("/ContractorProfile");
      } else {
        setshowLoading(false);
        setToastColor("danger");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };

  function callApi(data) {
    if (data === "yes") {
      getQuickProfile();
      setDataupdated(true);
    }
  }

  function getQuickProfile() {
    setshowLoading(true);
    setLoading(true);
    getQuickProfileData("").then((e: any) => {
      if (apiCallBack(e)) {
        const profile = e.data;
        setProfileData(profile);
        setValue("firstname", profile.firstname);
        setValue("lastname", profile.lastname);
        setValue("email", decrypted(profile.email));
        setValue("country_code", profile.country_code ?? "+91");
        setValue("mobile", decrypted(profile.mobile));
        setValue("profile_photo", decrypted(profile.profile_photo));
        setValue("headline", profile.headline);
        setValue("jobrole", profile.jobrole);
        setValue("country", [{ value: 1, label: "India" }]);
        setValue("city", "");
        setValue("state", "");
        setValue("contract_engagement_type", profile.contract_engagement_type ?? "");
        setValue("zip", decrypted(profile.zip));
        setValue("constraint_worklocate", profile.constraint_worklocate);
        setValue("visatype", profile.visatype ?? "");
        setValue(
          "is_contract_job",
          profile.is_contract_job !== "1" ? false : true
        );

        setValue("eligible_work_permit", profile.eligible_work_permit ?? "3");
        setValue("currency", profile.currency ?? "1");
        setValue(
          "contract_duration",
          profile.contract_duration !== "0"
            ? profile.contract_duration ?? "40"
            : "40"
        );
       
        setValue("contract_start_date", "");
        setValue(
          "contract_telecommute",
          profile.contract_telecommute !== "0"
            ? profile.contract_telecommute ?? "20"
            : "20"
        );
        setValue(
          "contract_telecommute_type",
          profile.contract_telecommute_type ?? "1"
        );
         setValue(
          "contract_duration_type",
          profile.contract_duration_type !== "0"
            ? profile.contract_duration_type ?? "2"
            : "2"
        );
        setValue("contract_compensation", profile.contract_compensation);
        setValue(
          "contract_compensation_type",
          profile.contract_compensation_type ?? "1"
        );
        setValue(
          "is_permanent_job",
          profile.is_permanent_job !== "1" ? false : true
        );
        setValue(
          "permanent_currency_type",
          profile.permanent_currency_type ?? "1"
        );
        setValue(
          "permanent_duration",
          profile.permanent_duration !== "0" ? profile.permanent_duration ?? "40" : "40"
        );
        setValue(
          "permanent_duration_type",
          profile.permanent_duration_type !== "0"
            ? profile.permanent_duration_type ?? "2"
            : "2"
        );
        setValue("permanent_start_date", "");
        setValue(
          "permanent_telecommute",
          profile.permanent_telecommute !== "0"
            ? profile.permanent_telecommute ?? "20"
            : "20"
        );
        setValue(
          "permanent_telecommute_type",
          profile.permanent_telecommute_type ?? "1"
        );
        setValue("permanent_compensation", profile.permanent_compensation);
        setValue(
          "permanent_compensation_type",
          profile.permanent_compensation_type ?? "1"
        );
      }
      setLoading(false);
      setshowLoading(false);
    });
  }

  return (
    <IonPage>
      <IonContent className="qp-upload">
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setshowLoading(false)}
          message={"Uploading the Profile data..."}
        />
        <div className={m4kQuery ? "container" : ""}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <IonSlides
                className="qprofile"
                pager={true}
                options={slideOpts}
                ref={mySlides}
              >
                <IonSlide>
                  <UploadFile
                    next={next}
                    callGetApi={callApi}
                    pageReference="quickprofile"
                  />
                </IonSlide>
                <IonSlide>
                  {!loading && (
                    <MandatoryDetails
                      next={next}
                      OptionDatas={profileData !== undefined && profileData}
                    />
                  )}
                </IonSlide>
              </IonSlides>
            </form>
          </FormProvider>
        </div>
        <ModalDeikaa isOpen={modal}>
          <div className="custom-deikaa-modal text-left">
            <div className="text-center py-2">
              <img alt="" src="assets/image/dekiaa-modal.svg" />
            </div>
            <h1>Perfect! Your profile is now ready</h1>
            {/* <p>
              <IonIcon color="warning" icon={informationCircleOutline} />
              You've opted to <span>
                hide your profile from recruiters.
              </span>{" "}
              You can change this anytime in your profile.
            </p> */}
            <IonCard className="deikaa-details">
              <IonGrid className="cut-color">
                <IonRow>
                  <IonCol sizeLg="1" sizeXs="12">
                    <img alt="" src="assets/image/Deikaa_Logomark.svg" />
                  </IonCol>
                  <IonCol sizeLg="11" sizeXs="12">
                    <h4>
                      Do you wanna win MacBooks, smartphones, and voice
                      assistants?
                    </h4>
                    <p>
                      Opt-in to our loyalty reward program offered through
                      Deikaa which will reward you tokens for simply using Hirify, each token giving you a chance to win an exciting
                      prize!
                    </p>
                    <p>
                      To learn more about Deikaa, please visit{" "}
                      <a href="https://deikaa.com/" target="_blank">
                        deikaa.com
                      </a>
                    </p>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>

            <p className="deikaa-font-12">
              By opting in, you accept Deikaa's{" "}
              <a target="_blank" href="/terms">
                terms of use
              </a>{" "}
              and{" "}
              <a
                href="/privacy-policy"
                target="_blank"
              >
                privacy policy
              </a>
              , and permit us to create a Deikaa account on your behalf using
              your registered Hirify email.
            </p>

            <div className="text-center py-3">
              <IonButton
                shape="round"
                fill="outline"
                color="secondary"
                className="remove-shadow capitalizes"
                type="button"
                onClick={(e) => {
                  closeModelSection(e);
                }}
              >
                Not Now
              </IonButton>
              <IonButton
                shape="round"
                fill="solid"
                color="secondary"
                className="remove-shadow capitalizes"
                type="button"
                onClick={(e) => ModalDeikaas("yes")}
              >
                Yes, I'm In
              </IonButton>
            </div>
          </div>
        </ModalDeikaa>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(ResumeUpload);
