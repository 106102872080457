export const pages = [
  {
    User: "Contractor",
    page: [
      {
        pageName: "Profile",
        url: "/ContractorProfile",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "creater",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Browse Jobs",
        url: "/joblist",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "menu",
            childUrl: "/",
          },
          {
            childName: "creater",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Profile Setting",
        url: "/",
        icon: "profileimage",
        dropdown: "true",
        catMenu: "profileimage",
        childern: [
          {
            childName: "Refer",
            childUrl: "/refer",
          },
          {
            childName: "Account Setting",
            childUrl: "/profileSettings",
          },
          {
            childName: "Signout",
            childUrl: "/login",
          },
        ],
      },
    ],
  },

  {
    User: "Talent Relationship Manager",
    page: [
      {
        pageName: "Contractor",
        url: "/trm",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Company",
        url: "/trm/companylist",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },

      {
        pageName: "Deikaa",
        url: "/contractor-deikaa",
        icon: "",
        dropdown: "true",
        childern: [
          {
            childName: "Report",
            childUrl: "/contractor-deikaa",
          },
          {
            childName: "Add token",
            childUrl: "/trm/deikaatoken",
          },
        ],
      },
      {
        pageName: "Profile Setting",
        url: "/",
        icon: "profileimage",
        dropdown: "true",
        catMenu: "profileimage",
        childern: [
          {
            childName: "Profile Setting",
            childUrl: "/changePwd",
          },
          {
            childName: "Signout",
            childUrl: "/login",
          },
        ],
      },
    ],
  },
  {
    User: "Jngstaff",
    page: [
      {
        pageName: "View Jobs",
        url: "/list-jobs",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Logs",
        url: "/logs",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Skills",
        url: "",
        icon: "",
        dropdown: "true",
        childern: [
          {
            childName: "Skills",
            childUrl: "/staff_skills",
          },
          {
            childName: "Tools",
            childUrl: "/staff_tools",
          },
          {
            childName: "Core Skills",
            childUrl: "/coreskill",
          },
        ],
      },
      {
        pageName: "Users",
        url: "/staff_users",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Deikaa",
        url: "/contractor-deikaa",
        icon: "",
        dropdown: "true",
        childern: [
          {
            childName: "Report",
            childUrl: "/contractor-deikaa",
          },
          {
            childName: "Add token",
            childUrl: "/staff/deikaatoken",
          },
        ],
      },
      {
        pageName: "Add Mail",
        url: "/staff/sentmail",
        icon: "",
        dropdown: "false",
        childern: [],
      },
      {
        pageName: "Profile Setting",
        url: "/",
        icon: "profileimage",
        dropdown: "true",
        catMenu: "profileimage",
        childern: [
          {
            childName: " Send job invite",
            childUrl: "/mail",
          },
          {
            childName: "Profile Setting",
            childUrl: "/changePwd",
          },
          {
            childName: "Signout",
            childUrl: "/login",
          },
        ],
      },
    ],
  },

  {
    User: "Company",
    page: [
      {
        pageName: "Jobs",
        url: "/jobs",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      // {
      //   pageName: "View Contractors",
      //   url: "/company-viewcontractor",
      //   icon: "",
      //   dropdown: "false",
      //   childern: [
      //     {
      //       childName: "",
      //       childUrl: "/",
      //     },
      //   ],
      // },
      {
        pageName: "View Users",
        url: "/company-users",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      // {
      //   pageName: "View Projects",
      //   url: "/company-projects",
      //   icon: "",
      //   dropdown: "false",
      //   childern: [
      //     {
      //       childName: "",
      //       childUrl: "/",
      //     },
      //   ],
      // },
      {
        pageName: "Profile Setting",
        url: "/",
        icon: "profileimage",
        dropdown: "true",
        catMenu: "profileimage",
        childern: [
          {
            childName: "My Profile",
            childUrl: "/profilecompany",
          },
          {
            childName: "Profile Setting",
            childUrl: "/changePwd",
          },
          {
            childName: "Signout",
            childUrl: "/login",
          },
        ],
      },
    ],
  },
  {
    User: "Jngreporter",
    page: [
      {
        pageName: "Jobs",
        url: "/list-jobs",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Profile Setting",
        url: "/",
        icon: "profileimage",
        dropdown: "true",
        catMenu: "profileimage",
        childern: [
          {
            childName: "Profile Setting",
            childUrl: "/changePwd",
          },
          {
            childName: "Signout",
            childUrl: "/login",
          },
        ],
      },
    ],
  },
  {
    User: "Customer Relationship Manager",
    page: [
      {
        pageName: "All Jobs",
        url: "/list-jobs",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Contractor",
        url: "/crm/contractor",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "CRM Jobs",
        url: "/crm/jobs",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Company",
        url: "/crm/companylist",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Profile Setting",
        url: "/",
        icon: "profileimage",
        dropdown: "true",
        catMenu: "profileimage",
        childern: [
          {
            childName: "Profile Setting",
            childUrl: "/changePwd",
          },
          {
            childName: "Signout",
            childUrl: "/login",
          },
        ],
      },
    ],
  },
  {
    User: "Recruiter",
    page: [
      {
        pageName: "All Jobs",
        url: "/list-jobs",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Contractor",
        url: "/recruiter/contractor",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
     
      {
        pageName: "Company",
        url: "/recruiter/companylist",
        icon: "",
        dropdown: "false",
        childern: [
          {
            childName: "",
            childUrl: "/",
          },
        ],
      },
      {
        pageName: "Profile Setting",
        url: "/",
        icon: "profileimage",
        dropdown: "true",
        catMenu: "profileimage",
        childern: [
          {
            childName: "Profile Setting",
            childUrl: "/changePwd",
          },
          {
            childName: "Signout",
            childUrl: "/login",
          },
        ],
      },
    ],
  },
];
