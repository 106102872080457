import {
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonPage,
  IonRow,
} from "@ionic/react";
import moment from "moment";
import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { RouteComponentProps, useHistory } from "react-router";
import { CardProfileImage, HeaderNav, ModalBody } from "../../../component";
import { AuthContext } from "../../../context/authContext";
import { getCookie } from "../../../service";
import {
  Company,
  FullDateStringMap,
  getIndexValue,
  NumberStringMap,
  TRM,
  Staff,
  CRMRole,
  StringMap,
} from "../../../service/default";
import Closed from "../../Company/Jobs/closed";
import Draft from "../../Company/Jobs/draft";
import InviteTalent from "../../Company/Jobs/form/InviteTalent";
import JobForm from "../../Company/Jobs/form/JobForm";
import Open from "../../Company/Jobs/Open";

interface props extends RouteComponentProps<{}> {}

class CompanyListjob extends React.Component {
  static contextType = AuthContext;
  state = {
    loading: false,
    catagoryType: "1",
    searchKey: "",
    key: "1",
    blankscreen: 1,
    refresh: false,
    comData: this.props["location"],
    keyopen: 0,
    keyclose: 0,
    keydraft: 0,
    user: getCookie({ key: "user" }).value,
    JobsData: [],
    editData: null,
    modelOpen: false,
    formTypes: "",
    currentPage: "1",
    pageCount: 30,
    perPage: "",
    totalCount: "",
    totalPage: "",
    showToast: false,
    toastMessage: "",
    toastColor: "success",
    workPermit: [],
    paymentDurationType: [],
    workFromhomeType: [],
    currencyType: [],
    jngskills: [],
    saveRequestRefresh: false,
    matches: window.matchMedia("(max-width: 2491px)").matches,
  };
  // lifecycle
  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window
      .matchMedia("(max-width: 2491px)")
      .addEventListener("change", handler);
  }

  getTabAction(data: any) {
    if (data !== undefined) {
      const Count =
        data.open_job_count + data.close_job_count + data.draft_job_count;
      this.setState({
        blankscreen: Count ? Count : 0,
        JobsData: data.data !== undefined ? data.data : [],
        keyopen: data.open_job_count ?? 0,
        keyclose: data.close_job_count ?? 0,
        keydraft: data.draft_job_count ?? 0,
        workPermit: data.company_work_permit,
        paymentDurationType: data.payment_duration_type,
        workFromhomeType: data.workfromhome_type,
        currencyType: data.currency,
        jngskills: data.jng_skills,
        keycount: data.totalItemsCount,
      });
    }
  }

  keyValue(keyname: any, data: any) {
    switch (keyname) {
      case "1":
        return this.setState({
          keyopen:
            data.totalItemsCount !== undefined ? data.totalItemsCount : 0,
        });
      case "0":
        return this.setState({
          keyclose:
            data.totalItemsCount !== undefined ? data.totalItemsCount : 0,
        });
      case "2":
        return this.setState({
          keydraft:
            data.totalItemsCount !== undefined ? data.totalItemsCount : 0,
        });
      default:
        return "0";
    }
  }

  // model
  modelFunc = (key: any, data: any) => {
    const field = {
      engagement_type: "0",
      pay_type: "1",
      pay_duration: "1",
      wfh_type: "2",
      openings: "1",
      title: "",
      payment: "",
      work_location: "",
      overall_exp: "",
      start_date: "",
      end_date: "",
      mandatory_skills: [],
      additional_skills: [],
    };
    this.setState({ modelOpen: true, formTypes: key, editData: field });
  };

  refreshData() {
    this.setState({ refresh: !this.state.refresh });
  }

  UpdateState(data) {
    this.setState({ refresh: !this.state.refresh });
  }

  render() {
    const { loading, user, modelOpen } = this.state;
    const { auth } = this.context;
    const { companyjob } = auth;
    return (
      <div>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={loading}
          onDidDismiss={() => this.setState({ loading: false })}
        />
        <div className={this.state.matches ? "container" : "container Jobs"}>
          <div className="px-lg-4 py-3">
            <div className="header-section custom-flex-between">
              <h1 className="mt-0">Jobs</h1>
              {user.user_type === Company && (
                <IonButton
                  type="button"
                  shape="round"
                  fill="solid"
                  className="next-btn remove-shadow capitalizes "
                  onClick={(e) => {
                    this.setState({ refresh: false });
                    this.modelFunc("add", "");
                  }}
                >
                  Post Job
                </IonButton>
              )}
              {user.user_type === Staff && (
                <IonButton
                  type="button"
                  shape="round"
                  fill="solid"
                  className="next-btn remove-shadow capitalizes "
                  onClick={() => {
                    this.setState({ refresh: false });
                    this.modelFunc("add", "");
                  }}
                >
                  Post Job
                </IonButton>
              )}
              {user.user_type === CRMRole && (
                <IonButton
                  type="button"
                  shape="round"
                  fill="solid"
                  className="next-btn remove-shadow capitalizes "
                  onClick={(e) => {
                    this.setState({ refresh: false });
                    this.modelFunc("add", "");
                  }}
                >
                  Post Job
                </IonButton>
              )}
            </div>
            {this.state.blankscreen !== 0 ? (
              <>
                <Tabs
                  id="controlled-tab-custom"
                  activeKey={this.state.key}
                  onSelect={(k: any) => this.setState({ key: k })}
                  className="mb-3"
                >
                  <Tab
                    eventKey="1"
                    title={"Open (" + companyjob.open_job_count + ")"}
                  >
                    {this.state.key === "1" && (
                      <Open
                        callApi={(data: any) => this.getTabAction(data)}
                        detailPage={{
                          workPermit: this.state.workPermit,
                          paymentDurationType: this.state.paymentDurationType,
                          workFromhomeType: this.state.workFromhomeType,
                          currencyType: this.state.currencyType,
                          jngskills: this.state.jngskills,
                        }}
                        modelFunction={(key, data) => this.modelFunc(key, data)}
                        // keyOption={(key, data) => this.keyValue(key, data)}
                        refreshData={this.state.refresh}
                        comid={
                          this.state.user.com_id !== ""
                            ? this.state.user.com_id
                            : this.state.comData
                            ? this.state.comData.state
                            : null
                        }
                        closemodelFun={(e) => this.setState({ refresh: false })}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="0"
                    title={"Closed (" + companyjob.close_job_count + ")"}
                  >
                    {this.state.key === "0" && (
                      <Closed
                        callApi={(data: any) => this.getTabAction(data)}
                        detailPage={{
                          workPermit: this.state.workPermit,
                          paymentDurationType: this.state.paymentDurationType,
                          workFromhomeType: this.state.workFromhomeType,
                          currencyType: this.state.currencyType,
                          jngskills: this.state.jngskills,
                        }}
                        modelFunction={(key, data) => this.modelFunc(key, data)}
                        // keyOption={(key, data) => this.keyValue(key, data)}
                        refreshData={this.state.refresh}
                        comid={
                          this.state.user.com_id !== ""
                            ? this.state.user.com_id
                            : this.state.comData
                            ? this.state.comData.state
                            : null
                        }
                        closemodelFun={(e) => this.setState({ refresh: false })}
                      />
                    )}
                  </Tab>
                  <Tab
                    eventKey="2"
                    title={"Draft (" + companyjob.draft_job_count + ")"}
                  >
                    {this.state.key === "2" && (
                      <Draft
                        callApi={(data: any) => this.getTabAction(data)}
                        detailPage={{
                          workPermit: this.state.workPermit,
                          paymentDurationType: this.state.paymentDurationType,
                          workFromhomeType: this.state.workFromhomeType,
                          currencyType: this.state.currencyType,
                          jngskills: this.state.jngskills,
                        }}
                        modelFunction={(key, data) => this.modelFunc(key, data)}
                        // keyOption={(key, data) => this.keyValue(key, data)}
                        refreshData={this.state.refresh}
                        comid={
                          this.state.user.com_id !== ""
                            ? this.state.user.com_id
                            : this.state.comData
                            ? this.state.comData.state
                            : null
                        }
                        closemodelFun={(e) => this.setState({ refresh: false })}
                      />
                    )}
                  </Tab>
                </Tabs>
              </>
            ) : (
              <div className="nojob-section text-center">
                <img
                  className="py-3"
                  alt="jng"
                  src="assets/image/job_backGround.svg"
                />
                <h1>No jobs to show!</h1>
                <p>
                  List job opportunities with your organization for FREE or
                  engage our whiteglove services to hire the best talent out
                  there.
                </p>
                {user.user_type === Company && (
                  <IonButton
                    type="button"
                    shape="round"
                    fill="solid"
                    className="next-btn remove-shadow capitalizes"
                    onClick={() => {
                      this.modelFunc("add", "");
                      this.setState({ refresh: false });
                    }}
                  >
                    Add Job
                  </IonButton>
                )}
                <p className="pt-5">
                  Need help?{" "}
                  <a href="https://apps.hirify.ai/login">Get in touch</a>
                </p>
              </div>
            )}
          </div>
          <ModalBody
            title={
              this.state.formTypes === "add" ? "Post a new job" : "Edit job"
            }
            isOpen={modelOpen}
            onClose={() => this.setState({ modelOpen: false })}
          >
            <JobForm
              workhomeOption={this.state.workFromhomeType}
              paymentDurationOption={this.state.paymentDurationType}
              workPermitOption={this.state.workPermit}
              currencyOption={this.state.currencyType}
              comid={
                this.state.user.com_id !== ""
                  ? this.state.user.com_id
                  : this.state.comData
                  ? this.state.comData.state
                  : null
              }
              skillsOption={this.state.jngskills}
              formType={this.state.formTypes}
              closeModal={() => {
                this.setState({ modelOpen: false, refresh: true });
                window.location.reload();
              }}
              editDatas={this.state.editData}
              updateState={(data) => {}}
            />
          </ModalBody>
        </div>
      </div>
    );
  }
}

export default CompanyListjob;

export const ListJobCard = ({
  comid,
  jobdetail,
  screenName,
  detailPage,
  workFromhomeType,
  closeModal,
  UpdateState,
}: any) => {
  const history = useHistory();
  const { auth } = React.useContext(AuthContext);
  const user = auth.data;
  // redirect to detail screen
  const [showModal, setShowModal] = React.useState(false);
  const [ButtonAction, setButtonAction] = React.useState(false);
  const [showModalTalent, setShowModalTalent] = React.useState(false);
  const [editValue, setEditValue] = React.useState<any>();

  const [uniqueid, setUniqueId] = React.useState("");
  const detailPageClick = (data: any, screen: any) => {
    setUniqueId(data.unique_id ?? "");
    if (screen && data !== undefined) {
      history.push("/cjob/J" + data.com_pjid, {
        comid: comid ?? "",
        unique_id: data.unique_id ?? "",
        workPermit: detailPage.workPermit ?? [],
        screenName: screen,
        paymentDurationType: detailPage.paymentDurationType ?? [],
        workFromhomeType: detailPage.workFromhomeType ?? [],
        currencyType: detailPage.currencyType ?? [],
        jngskills: detailPage.jngskills ?? [],
      });
    }
  };

  const editDetails = (e) => {
    if (e) {
      setUniqueId(e.unique_id ?? "");
      setShowModal(true);
      setEditValue(e);
      setButtonAction(true);
    }
  };

  const resload = (e) => {
    if (e) {
      closeModal(e);
    }
  };

  const candidates = (name: any, e: any) => {
    if (e) history.push("/candidates/" + name, e);
  };

  return (
    <>
      {jobdetail.length > 0 &&
        jobdetail.map((j: any, indx: number) => (
          <IonCard key={indx} className="mt-3 ion-padding">
            {/* <IonFab vertical="top" horizontal="end">
                <IonFabButton color="success">
                  <IonIcon icon={createOutline} />
                </IonFabButton>
              </IonFab> */}
            <IonGrid>
              <IonRow>
                <IonCol
                  sizeLg="7"
                  sizeXs="12"
                  className={screenName !== "2" ? "vertical-line" : ""}
                >
                  <div>
                    <h3 className="my-1">{j.job_title}</h3>
                    <p className="m-0">
                      {j.work_location}
                      {getIndexValue(
                        j.wfh_type,
                        workFromhomeType,
                        "value",
                        "label"
                      ) &&
                        " (" +
                          getIndexValue(
                            j.wfh_type,
                            workFromhomeType,
                            "value",
                            "label"
                          ) +
                          ")"}
                    </p>
                    <small className="text-gray">
                      {" "}
                      JID: {StringMap(j.com_pjid) + " "}| Posted by{" "}
                    </small>{" "}
                    <small>{StringMap(j.posted_by ?? j.created_by)}</small>{" "}
                    <small className="text-gray">
                      on {moment(j.job_created_on).format("lll")}
                    </small>
                  </div>

                  <div className="com-custom-grid">
                    <div className=" com-col-section remove-p">
                      <div>
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/date_range.svg"
                          name="Engagement"
                        />
                        <small>Engagement</small>
                      </div>
                      <label>
                        {j.engagement_type === "0" ? "Contract" : "Permanent"}
                      </label>
                    </div>
                    <div className="com-col-section">
                      <div>
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/business_center.svg"
                          name="Exp"
                        />

                        <small>Exp.</small>
                      </div>
                      <label>{StringMap(j.overall_exp) + " y"}</label>
                    </div>
                    <div className="com-col-section">
                      <div>
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/monetization_on.svg"
                          name="Budget"
                        />

                        <small>Budget</small>
                      </div>
                      <label>
                        {/* {getIndexValue(
                            j.pay_type,
                            currencyType,
                            "value",
                            "symbol"
                          )} */}
                        {"₹ " + j.payment}
                        {j.engagement_type === "0" ? " per hour" : " per year"}
                      </label>
                    </div>
                    <div className="com-col-section">
                      <div>
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/event_available.svg"
                          name="Start"
                        />

                        <small>Start</small>
                      </div>
                      <label>{FullDateStringMap(j.start_date)}</label>
                    </div>
                    {j.duration_type === "0" && (
                      <div
                        className="com-col-section"
                        style={{ borderRightStyle: "none" }}
                      >
                        <div>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/event_busy.svg"
                            name="End"
                          />

                          <small>End</small>
                        </div>
                        <label>{FullDateStringMap(j.end_date)}</label>
                      </div>
                    )}
                  </div>
                </IonCol>
                <IonCol sizeLg="3" sizeXs="12">
                  {screenName !== "2" && (
                    <ul className="ul-element">
                      {screenName === "1" && (
                        <li>
                          <CardProfileImage
                            folder_key=""
                            profile_photo="assets/image/company/extension.svg"
                            name="matches"
                          />

                          <span>
                            {" " + NumberStringMap(j.matched_applicants) + " "}
                          </span>
                          <label> matches</label>
                        </li>
                      )}
                      <li>
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/groups.svg"
                          name="applicants"
                        />

                        <span>
                          {" "}
                          {" " + NumberStringMap(j.totalapplicants) + " "}
                        </span>
                        <label> applicants</label>
                      </li>
                      <li>
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/favorite_border.svg"
                          name="shortlisted"
                        />

                        <span>
                          {" " +
                            NumberStringMap(j.shortlisted_applicants) +
                            " "}
                        </span>
                        <label> shortlisted</label>
                      </li>
                      <li>
                        <CardProfileImage
                          folder_key=""
                          profile_photo="assets/image/company/check_circle1.svg"
                          name="hired"
                        />

                        <span>
                          {" " +
                            NumberStringMap(j.hired_applicants) +
                            "/" +
                            NumberStringMap(j.openings) +
                            " "}
                        </span>
                        <label> hired</label>
                      </li>
                    </ul>
                  )}
                </IonCol>
                <IonCol sizeLg="2" sizeXs="12">
                  {user && user.user_type === Staff && screenName !== "0" && (
                    <IonButton
                      expand="block"
                      size="small"
                      fill="outline"
                      color="orange"
                      className="remove-shadow capitalizes small-btn"
                      onClick={(e) => editDetails(j)}
                    >
                      Edit Job
                    </IonButton>
                  )}
                  {user && user.user_type === CRMRole && (
                    <IonButton
                      expand="block"
                      size="small"
                      fill="outline"
                      color="orange"
                      className="remove-shadow capitalizes small-btn"
                      onClick={(e) => editDetails(j)}
                    >
                      Edit Job
                    </IonButton>
                  )}
                  {user && user.user_type === Company && (
                    <IonButton
                      expand="block"
                      size="small"
                      fill="outline"
                      color="orange"
                      className="remove-shadow capitalizes small-btn"
                      onClick={(e) => editDetails(j)}
                    >
                      Edit Job
                    </IonButton>
                  )}
                  <IonButton
                    expand="block"
                    size="small"
                    fill="outline"
                    color="orange"
                    className="remove-shadow capitalizes small-btn"
                    onClick={(e) => detailPageClick(j, screenName)}
                  >
                    View Job
                  </IonButton>
                  {user && user.user_type === Company ? (
                    <>
                      {screenName === "1" && (
                        <IonButton
                          expand="block"
                          size="small"
                          fill="outline"
                          color="orange"
                          className="remove-shadow capitalizes small-btn"
                          onClick={(e) => {
                            setUniqueId(j.unique_id);
                            setShowModalTalent(true);
                          }}
                        >
                          Invite Talent
                        </IonButton>
                      )}
                    </>
                  ) : null}
                  <IonButton
                    expand="block"
                    size="small"
                    color="orange"
                    className="remove-shadow capitalizes small-btn"
                    onClick={(e) =>
                      candidates("1", {
                        ...j,
                        screenName: screenName,
                        comid: comid,
                      })
                    }
                  >
                    View Talent
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCard>
        ))}
      <ModalBody
        title={"Edit job"}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <JobForm
          workhomeOption={workFromhomeType}
          paymentDurationOption={
            detailPage !== undefined ? detailPage.paymentDurationType : []
          }
          workPermitOption={detailPage.workPermit}
          currencyOption={
            detailPage !== undefined ? detailPage.currencyType : []
          }
          comid={comid ?? ""}
          skillsOption={detailPage !== undefined ? detailPage.jngskills : []}
          uniqueid={uniqueid}
          formType={"edit"}
          buttonAction={ButtonAction}
          closeModal={() => {
            resload("e");
            setShowModal(false);
          }}
          editDatas={editValue}
          updateState={(data) => UpdateState(data)}
        />
      </ModalBody>

      {/* invite talent */}
      <ModalBody
        title="Invite talent"
        subTitle="The talent will receive an email with invitation to apply to the job."
        required={true}
        isOpen={showModalTalent}
        onClose={() => setShowModalTalent(false)}
      >
        <InviteTalent
          uniqueid={uniqueid}
          closeModal={() => setShowModalTalent(false)}
        />
      </ModalBody>
    </>
  );
};
