import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import { Saveaboutinfo } from "../../Service/api";
import { Ceditor } from "../../../../component";
import { AuthContext } from "../../../../context/authContext";

function AboutForm({
  ctrid,
  Userrole,
  Profileheadline,
  Summary,
  videourl,
  closeModal,
  refreshData,
  modalSet,
}: any) {
  const [uploading, setUploading] = React.useState(false);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const { auth, setDeikaa, DeikaaFun } = React.useContext(AuthContext);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };

  React.useEffect(() => {
    // if (!dataupdated) {
    setValue("jobrole", Userrole);
    setValue("headline", Profileheadline);
    setValue("about", Summary);
    setValue("video", videourl);
    //   setDataupdated(true);
    // }
  }, [modalSet]);

  const saveAbout = (values: any) => {
    setShowLoading(true);
    values.ctr_id = ctrid;
    setUploading(true);
    Saveaboutinfo(values).then((value) => {
      if (value.data !== undefined && value.status === 200) {
        if (value.deika_tokens !== null) {
          const newValue: any[] = [];
          auth.dekiaa.map((dk: any, index: any) => {
            if (dk.action_title === "VIDEOUPLOAD") {
              const data = value.deika_tokens;
              newValue.push(data);
            } else {
              newValue.push(dk);
            }
            if (dk.action_title !== "VIDEOUPLOAD") {
              newValue.push(value.deika_tokens);
            }
          });
          setDeikaa(newValue);
        }
        refreshData(values);
        setToastColor("success");
        setToastMessage(value.data);
        setShowToast(true);
        setUploading(false);
        setShowLoading(false);
        closeModal(false);
      } else {
        setUploading(false);
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value.data);
        setShowToast(false);
      }
    });
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveAbout)}>
            <IonRow>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Job Role <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="jobrole"
                    rules={{
                      required: "Job Role is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="Enter the jobrole"
                      />
                    )}
                  />
                  {/* <IonText className="custom-content right-content-2">
                    {showValueCount("jobrole")}/64
                  </IonText> */}
                </IonItem>
                {showError("jobrole")}
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Profile heading <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="headline"
                    rules={{
                      required: "Profile heading is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={512}
                        placeholder="Enter the profile heading"
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("headline")}
              </IonCol>

              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">Summary</IonLabel>
                  <Controller
                    control={control}
                    name="about"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Ceditor setEditorData={onChange} editorData={value} />
                    )}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">Video Link</IonLabel>
                  <Controller
                    control={control}
                    name="video"
                    rules={{
                      pattern: {
                        value:
                          /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/g,
                        // /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
                        message: "Invalid video url",
                      },
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={120}
                        placeholder="Enter the video url"
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("video")}
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <div className="text-right">
                  <IonButton
                    slot="end"
                    shape="round"
                    fill="solid"
                    color="secondary"
                    className="remove-shadow capitalizes"
                    type="submit"
                  >
                    {uploading ? "Uploading" : "Save"}
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
}

export default React.memo(AboutForm);
