import React from "react";
import "./tag.css";

export const TagSearch = (props) => {
  const [search, setSearch] = React.useState("");
  const [isShown, setIsShown] = React.useState(false);
  const removeTags = (indexToRemove) => {
    props.selectedTags([
      ...props.value.filter((removetag) => removetag !== indexToRemove),
    ]);
  };
  const addTags = (event) => {
    if (event !== "") {
      props.selectedTags([...props.value, event.value]);
    }
  };

  const handleChange = (event) => {
    if (event !== "") {
      setSearch(event.target.value);
      if (!isShown) setIsShown(true);
    }
  };

  const renderTags = () =>
    props.value &&
    props.value.map((tag, index) => (
      <li key={index} className="tag">
        <span className="tag-title">{returnValue(props.tags, tag)}</span>
        <span className="tag-close-icon" onClick={() => removeTags(tag)}>
          x
        </span>
      </li>
    ));

  const renderSuggestions = () =>
    props.tags &&
    props.tags
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .filter((field) => !props.value.includes(field.value))
      .filter(
        (field) => field.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
      )
      .map((suggestion, index) => {
        return (
          <li key={index} onClick={() => addTags(suggestion)}>
            <strong>{suggestion.label}</strong>
          </li>
        );
      });

  return (
    <div
      className="tag-dropdown"
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div className="tags-input">
        <ul id="tags">{renderTags()}</ul>
        <input
          type="text"
          onChange={(event) => handleChange(event)}
          placeholder="Search to add groups"
          onBlur={() => setIsShown(false)}
        />
      </div>
      {isShown && <ul className="dropdown-content">{renderSuggestions()}</ul>}
    </div>
  );
};

const returnValue = (arr, value) => {
  if (arr && value) {
    return arr.map((field) => {
      if (field.value === value) {
        return field.label;
      }
    });
  }
};
