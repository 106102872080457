import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToast,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import React, { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import {
  BrowserHandleExport,
  HeaderNav,
  DatePick,
  LocationSearch,
  ModalBody,
} from "../../component";
import {
  CompanyListForShortlistingData,
  getContractorlist,
} from "./Service/api";
import { onSave, StringMap } from "./../../service";
import { AuthContext } from "../../context/authContext";
import { CRMRecuter, CRMRole } from "../../service/default";
import DataTableComponent from "../../component/DataTable/dataTable";
import useMediaQuery from "../../hooks/mediaQuery";
import { CandidateUpdatestatus, getJobDetail } from "../Company/Service/api";
import { useHistory } from "react-router";
// import { CustomRangeSlider } from "../../component/customRangeSlider/customRangeSlider";
// interface Props extends RouteComponentProps<{}> {}

const ListofContractor: React.FC = () => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const history = useHistory();
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  const [modelDismiss, setModelDismiss] = useState<boolean>(false);
  const [ctrID, setCtrID] = useState<string>("");
  const [CompanyUid, setCompanyUid] = useState<string>("");

  const [companyList, setCompanyList] = useState<any[]>([]);
  const [jobList, setJoblist] = useState<any[]>([]);

  const [accordionValue, setAccordionValue] = useState<any>("");
  const [locationRefresh, setLocationrefresh] = React.useState("no");
  const [showToast, setShowToast] = useState(false);
  // const [search, setSearch] = useState("");
  // const [customSlider, setCustomSlider] = useState<number>(0);
  const [searchField, setSearchField] = useState<any>({
    page: 1,
    miles: "",
    geo_location: "",
    search_ctr: "",
    search_highlighted_skills: "",
    search_all_skills: "",
    search_core_skills: "",
    show_only_profile_complete: "",
    ready_to_enddate: "",
    ready_to_startdate: "",
    reference_completed: "",
    registered_end_date: "",
    registered_start_date: "",
  });
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showShortlistview, setShowShortlistview] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [contractorcomments, setcontractorcomments] = useState<any>([]);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [totalCountPerPage, setItemperPage] = useState<number>(30);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  const column = [
    { name: "S.No", field: "id", sortable: false, value: "id" },
    {
      name: "Name",
      field: "fullName",
      sortable: false,
      value: "fullName,email,mobile",
      wordsplit: "",
      validate: "usergroup",
    },
    {
      name: "Core Skill",
      field: "practice1",
      sortable: false,
      value: "practice1+practice2",
      wordsplit: " - ",
    },
    {
      name: "Group",
      field: "group_name",
      sortable: false,
      value: "group_name",
      wordsplit: "",
    },
    {
      name: "Ready to Market",
      field: "profile_complete_updated_on",
      sortable: false,
      value: "profile_complete_updated_on",
      validate: "date",
      wordsplit: "",
    },
    {
      name: "",
      field: "",
      sortable: false,
      value:
        userdata.user_type === CRMRole
          ? "view:recuter:upload"
          : userdata.user_type === CRMRecuter
          ? "view:recuter:upload"
          : "download:edit:view:upload",
      wordsplit: " ",
    },
  ];

  const getCompanyListForShortlisting = (ctrid) => {
    setShowLoading(true);
    CompanyListForShortlistingData(ctrid).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        // console.log("va;", value);
        setCompanyList(value.data ?? []);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value !== undefined && value.message);
        setShowToast(true);
      }
    });
  };

  const getcontractorData = (paginationdata, data) => {
    setcontractorcomments([]);
    setShowLoading(true);
    const result = {
      page: paginationdata,
      geo_location: data.geo_location ?? "",
      miles: data.miles ?? "",
      search_ctr: data.search_ctr ?? "",
      search_core_skills: data.search_core_skills ?? "",
      search_highlighted_skills: data.search_highlighted_skills ?? "",
      search_all_skills: data.search_all_skills ?? "",
      show_only_profile_complete: data.show_only_profile_complete ? "1" : "0",
      ready_to_enddate: data.ready_to_enddate ?? "",
      ready_to_startdate: data.ready_to_startdate ?? "",
      reference_completed: data.reference_completed ?? "",
      registered_end_date: data.registered_end_date ?? "",
      registered_start_date: data.registered_start_date ?? "",
    };

    getContractorlist(result).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        const finalData = value.data.map((item) => ({
          ...item,
          fullName: `${item.firstname} ${item.lastname}` || "",
        }));
        setcontractorcomments(finalData);
        setItemperPage(value.itemsCountPerPage);
        setTotalItemCount(value.totalItemsCount);
      } else {
        setItemperPage(0);
        setTotalItemCount(0);
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value !== undefined && value.message);
        setShowToast(true);
      }
    });
  };

  const handlePageChange = (e: number) => {
    if (e) {
      const selectedPage = e;
      getcontractorData(selectedPage, searchField);
      setCurrentPage(selectedPage);
      return;
    }
  };

  const handleGetJobList = (value) => {
    if (value) {
      companyList.map((eve) => {
        if (eve.com_id == value) {
          setJoblist(eve.job_list ?? []);
        }
      });
    }
  };

  // const handleCanditateValidation = (value) => {
  //   if (value) {
  //     companyList.map((eve) => {
  //       if (eve.com_id == watch("com_id")) {
  //         eve.map((job) => {
  //           if (job.com_id == value) {
  //             setCanditateMessage("");
  //             if (job["contractorID"].includes(ctrID)) {
  //               setCanditateMessage("Candidate already shortlisted");
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }
  // };

  const searchForm = (value) => {
    if (
      (value.ready_to_enddate && value.ready_to_startdate === null) ||
      (value.ready_to_startdate && value.ready_to_enddate === null) ||
      (value.ready_to_startdate && value.ready_to_enddate === undefined) ||
      (value.ready_to_enddate && value.ready_to_startdate === undefined)
    ) {
      setToastColor("warning");
      setToastMessage("Start and end dates are required");
      setShowToast(true);
      return;
    }
    if (
      (value.registered_end_date && value.registered_start_date === null) ||
      (value.registered_start_date && value.registered_end_date === null) ||
      (value.registered_end_date &&
        value.registered_start_date === undefined) ||
      (value.registered_start_date && value.registered_end_date === undefined)
    ) {
      setToastColor("warning");
      setToastMessage("Start and end dates are required");
      setShowToast(true);
      return;
    }
    if (value) {
      setSearchField({
        page: 1,
        miles: value.miles ?? "",
        geo_location: value.geo_location ?? "",
        search_ctr: value.search_ctr ?? "",
        search_core_skills: value.search_core_skills ?? "",
        search_highlighted_skills: value.search_highlighted_skills ?? "",
        search_all_skills: value.search_all_skills ?? "",
        show_only_profile_complete: value.show_only_profile_complete ?? false,
        ready_to_enddate: value.ready_to_enddate ?? "",
        ready_to_startdate: value.ready_to_startdate ?? "",
        reference_completed: value.reference_completed ?? "",
        registered_end_date: value.registered_end_date ?? "",
        registered_start_date: value.registered_start_date ?? "",
      });

      getcontractorData(1, value);
    }
  };

  React.useEffect(() => {
    let isMounted = true;
    setLocationrefresh("yes");
    try {
      setAccordionValue("");
      getcontractorData(1, searchField);
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const renderAction = (type, item) => {
    setCtrID("");
    switch (type) {
      case "delete":
        return;
      case "download":
        contractordownload(item.ctr_id, item.contractor_resume);
        return;
      case "edit":
        contractoredit(item);
        return;
      case "view":
        gotoscreen(item.public_id);
        return;
      case "upload":
        updateChild(item);
        return;
      case "recuter":
        setCtrID(item.ctr_id);
        getCompanyListForShortlisting(item.ctr_id);
        setModelDismiss(!modelDismiss);
        return;
      default:
        return;
    }
  };

  function updateChild(data: any) {
    if (data) {
      const Additionaldata = {
        title: "Additional data for contractor",
        contractorName: data.firstname + data.lastname,
        ctrid: data.ctr_id,
        required: userdata.user_type === CRMRole ? true : false,
        requestText: "",
        subTitle:
          "Contractor Name: " + StringMap(data.firstname + data.lastname),
      };
      onSave({ key: "additionaldata", value: Additionaldata });
      window.open("/trm/additional-data", "_blank");
      return;
    }
  }

  function gotoscreen(value) {
    if (value) {
      window.open("/publicview/" + value, "_blank");
    } else {
      setToastColor("warning");
      setToastMessage("update your contractor profile");
      setShowToast(true);
    }
  }

  function contractoredit(da) {
    if (da) {
      onSave({ key: "ctrid", value: da.ctr_id });
      window.open("/ContractorProfile", "_blank");
      return;
    }
  }
  const contractordownload = (da: any, filename: string) => {
    if (da && filename) {
      BrowserHandleExport("/Resumedownload/" + da, filename);
      return;
    } else {
      setToastColor("danger");
      setToastMessage("Resume not found");
      setShowToast(true);
      return;
    }
  };

  // const contractorDeleteApi = (da: any) => {
  //   if (da) {
  //     getRemoveContractor(da).then((value: any) => {
  //       if (value !== undefined && value.status === 200) {
  //         setShowLoading(false);
  //         setToastColor("success");
  //         setToastMessage(value !== undefined && value.message);
  //         setShowToast(true);
  //       } else {
  //         setShowLoading(false);
  //         setToastColor("danger");
  //         setToastMessage(value !== undefined && value.message);
  //         setShowToast(true);
  //       }
  //     });
  //   }
  // };

  // shortlist form

  const shortlistForm = (val) => {
    if (val) {
      setShowLoading(true);
      const result = {
        ctr_id: ctrID,
        com_id: val.com_id,
        unique_id: val.unique_id,
        job_status_type: "Shortlisted",
        // flag: e,
      };
      CandidateUpdatestatus(result).then((e) => {
        if (e !== undefined && e.status === 200) {
          setToastColor("success");
          setToastMessage("Applicant shortlisted");
          setShowShortlistview(true);
          setValue("com_id", "");
          setValue("unique_id", "");
          setModelDismiss(false);
          setShowToast(true);
          setShowLoading(false);
        } else {
          setShowLoading(false);
          setToastColor("warning");
          setToastMessage(e !== undefined && e.message);
          setShowToast(true);
        }
      });
      setShowLoading(false);
    }
  };

  const getJobData = (unique_id) => {
    setShowLoading(true);
    setShowShortlistview(false);
    getJobDetail(unique_id).then((e: any) => {
      if (e !== undefined && e.status === 200) {
        history.push("/candidates/3", e.data);
        setShowLoading(false);
      } else {
        setShowLoading(false);
      }
    });
    setShowLoading(false);
  };

  return (
    <IonPage>
      <HeaderNav />
      <IonContent>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000}
          color={toastColor}
        />
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />
        <div className={m4kQuery ? "container" : ""}>
          <IonGrid>
            <IonRow>
              <IonCol
                sizeLg="12"
                sizeMd="12"
                sizeXl="12"
                sizeXs="12"
                sizeSm="12"
              >
                <h1>All Contractors</h1>
              </IonCol>

              <IonCol
                sizeLg="12"
                sizeMd="12"
                sizeXl="12"
                sizeXs="12"
                sizeSm="12"
              >
                {/* <CustomRangeSlider
                  value={customSlider}
                  disable={false}
                  hangleChange={(e) => setCustomSlider(e)}
                /> */}
                <form onSubmit={handleSubmit(searchForm)}>
                  <IonCard className="ion-padding">
                    <IonGrid className="m-0 p-0">
                      <IonRow>
                        <IonCol
                          sizeLg="6"
                          sizeXl="6"
                          sizeMd="6"
                          sizeXs="12"
                          sizeSm="12"
                        >
                          <IonItem lines="none">
                            <IonLabel position="stacked">
                              Search by Name/Email/Contact No.
                            </IonLabel>
                            <Controller
                              control={control}
                              name="search_ctr"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <IonInput
                                  onIonChange={onChange}
                                  value={value}
                                  placeholder="Search by Name/Email/Contact No."
                                />
                              )}
                            />
                          </IonItem>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <IonAccordionGroup
                      className="ion-padding"
                      value={accordionValue}
                    >
                      <IonAccordion
                        value="advance"
                        toggleIcon={chevronDownOutline}
                        onClick={(e) => {
                          setAccordionValue("advance");
                        }}
                      >
                        <IonText slot="header" className="custom-text cursor">
                          <IonLabel>Advance search filters</IonLabel>
                        </IonText>

                        <IonGrid slot="content" className="m-0 p-0">
                          <IonRow>
                            <IonCol
                              sizeLg="12"
                              sizeXl="12"
                              sizeMd="12"
                              sizeXs="12"
                              sizeSm="12"
                            >
                              <IonItem lines="none">
                                <IonLabel position="stacked">
                                  Search Core Skills
                                </IonLabel>
                                <Controller
                                  control={control}
                                  name="search_core_skills"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <IonInput
                                      onIonChange={onChange}
                                      value={value}
                                    />
                                  )}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol
                              sizeLg="6"
                              sizeXl="6"
                              sizeMd="6"
                              sizeXs="12"
                              sizeSm="12"
                            >
                              <IonItem lines="none">
                                <IonLabel position="stacked">
                                  Highlighted Skills
                                </IonLabel>
                                <Controller
                                  control={control}
                                  name="search_highlighted_skills"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <IonInput
                                      onIonChange={onChange}
                                      value={value}
                                    />
                                  )}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol
                              sizeLg="6"
                              sizeXl="6"
                              sizeMd="6"
                              sizeXs="12"
                              sizeSm="12"
                            >
                              <IonItem lines="none">
                                <IonLabel position="stacked">Skills</IonLabel>
                                <Controller
                                  control={control}
                                  name="search_all_skills"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <IonInput
                                      onIonChange={onChange}
                                      value={value}
                                    />
                                  )}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol sizeLg="2" sizeXs="12">
                              <IonLabel>Registered on</IonLabel>
                            </IonCol>
                            <IonCol sizeLg="4" sizeXs="12">
                              <div className="input-div">
                                <IonLabel position="stacked">
                                  Start date
                                </IonLabel>
                                <Controller
                                  control={control}
                                  name="registered_start_date"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <DatePick
                                      value={value}
                                      ChangeEvent={onChange}
                                    />
                                  )}
                                />
                              </div>
                            </IonCol>
                            <IonCol sizeLg="6" sizeXs="12">
                              <div className="input-div">
                                <IonLabel position="stacked">End date</IonLabel>
                                <Controller
                                  control={control}
                                  name="registered_end_date"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <DatePick
                                      value={value}
                                      ChangeEvent={onChange}
                                      minDate={watch("registered_start_date")}
                                    />
                                  )}
                                />
                              </div>
                            </IonCol>
                            <IonCol sizeLg="2" sizeXs="12">
                              Ready to market
                            </IonCol>
                            <IonCol sizeLg="4" sizeXs="12">
                              <div className="input-div">
                                <IonLabel position="stacked">
                                  Start date
                                </IonLabel>
                                <Controller
                                  control={control}
                                  name="ready_to_startdate"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <DatePick
                                      value={value}
                                      ChangeEvent={onChange}
                                    />
                                  )}
                                />
                              </div>
                            </IonCol>
                            <IonCol sizeLg="6" sizeXs="12">
                              <div className="input-div">
                                <IonLabel position="stacked">End date</IonLabel>
                                <Controller
                                  control={control}
                                  name="ready_to_enddate"
                                  render={({
                                    field: { onChange, onBlur, value, ref },
                                  }) => (
                                    <DatePick
                                      value={value}
                                      ChangeEvent={onChange}
                                      minDate={watch("Ready_to_startdate")}
                                    />
                                  )}
                                />
                              </div>
                            </IonCol>
                            <IonCol sizeLg="3" sizeXs="12">
                              <IonItem lines="none">
                                <IonLabel position="stacked">
                                  Reference completed
                                </IonLabel>
                                <Controller
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <IonToggle
                                      mode={"md"}
                                      checked={value}
                                      onIonChange={(e) => {
                                        setValue(
                                          "reference_completed",
                                          e.detail.checked
                                        );
                                      }}
                                    />
                                  )}
                                  control={control}
                                  name={"reference_completed"}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol sizeLg="3" sizeXs="12">
                              <IonItem lines="none">
                                <IonLabel position="stacked">
                                  Ready to market
                                </IonLabel>
                                <Controller
                                  render={({
                                    field: { onChange, value, ref },
                                  }) => (
                                    <IonToggle
                                      mode={"md"}
                                      checked={value}
                                      onIonChange={(e) => {
                                        setValue(
                                          "show_only_profile_complete",
                                          e.detail.checked
                                        );
                                      }}
                                    />
                                  )}
                                  control={control}
                                  name={"show_only_profile_complete"}
                                />
                              </IonItem>
                            </IonCol>
                            <IonCol sizeLg="12" sizeXs="12">
                              <div className="locationInput">
                                <IonLabel position="stacked">Location</IonLabel>
                                <IonRow>
                                  <IonCol sizeLg="8" sizeXs="12">
                                    <Controller
                                      render={({
                                        field: { onChange, onBlur, value, ref },
                                      }) => (
                                        <LocationSearch
                                          valueKey={value}
                                          onChangeValue={onChange}
                                          onSelectValue={(e: any) => {
                                            setValue("geo_location", e);
                                          }}
                                          latLong={(e: any) => {
                                            setValue("latlng", e);
                                          }}
                                          refresh={locationRefresh}
                                        />
                                      )}
                                      name="geo_location"
                                      control={control}
                                    />
                                  </IonCol>
                                  <IonCol sizeLg="4" sizeXs="12">
                                    <Controller
                                      render={({
                                        field: { onChange, onBlur, value, ref },
                                      }) => (
                                        <IonInput
                                          value={value}
                                          onIonChange={onChange}
                                          autocomplete="off"
                                          type="number"
                                          placeholder="miles"
                                        />
                                      )}
                                      name={"miles"}
                                      control={control}
                                    />
                                    {/* <div className="custom-groupInput">
                            <Controller
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <select
                                  value={"mi"}
                                  disabled={true}
                                  onChange={onChange}
                                  placeholder="Select"
                                >
                                  <option value="">Select</option>
                                  <option selected value="mi">
                                    mi
                                  </option>
                                </select>
                              )}
                              control={control}
                              name={"mi"}
                            />
                          </div> */}
                                  </IonCol>
                                </IonRow>
                              </div>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonAccordion>
                    </IonAccordionGroup>
                    <div>
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="submit"
                      >
                        Search
                      </IonButton>
                    </div>
                  </IonCard>
                </form>
              </IonCol>
              <IonCol size="12">
                <IonCard className="ion-padding">
                  <DataTableComponent
                    tableheaders={column}
                    tableData={contractorcomments}
                    currentPage={currentPage}
                    itemPerPage={totalCountPerPage}
                    totalItem={totalItemCount}
                    handleAction={(type, item) => {
                      renderAction(type, item);
                    }}
                    setCurrentPage={(page) => handlePageChange(page)}
                  />
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        {/* shortlisted to company */}
        <ModalBody
          title="Add Shortlist to Company"
          subTitle=""
          required={true}
          isOpen={modelDismiss}
          onClose={() => setModelDismiss(false)}
        >
          <IonCard>
            <form onSubmit={handleSubmit(shortlistForm)}>
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                    <IonItem lines="none">
                      <IonLabel position="stacked">
                        Company Name <span className="required">*</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <select
                            onBlur={onBlur}
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleGetJobList(e.target.value);
                            }}
                            value={value}
                            placeholder="Select"
                          >
                            <option value="">Select</option>
                            {companyList.map((company) => (
                              <option value={company.com_id}>
                                {company.com_name}
                              </option>
                            ))}
                          </select>
                        )}
                        control={control}
                        name="com_id"
                        rules={{
                          required: "Company name is required",
                        }}
                      />
                    </IonItem>
                    {showError("com_id")}
                  </IonCol>
                  {watch("com_id") && (
                    <IonCol sizeLg="6" sizeMd="6" sizeSm="12" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Job Title<span className="required">*</span>
                        </IonLabel>
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <select
                              onBlur={onBlur}
                              onChange={(e)=> {onChange(e.target.value); setCompanyUid(e.target.value) }}
                              value={value}
                              disabled={!watch("com_id")}
                              placeholder="Select"
                            >
                              <option value="">Select</option>
                              {jobList.length > 0 ? (
                                jobList.map((jobDet) => (
                                  <option value={jobDet.unique_id}>
                                    {jobDet.job_title}
                                  </option>
                                ))
                              ) : (
                                <option disabled value={""}>
                                  No job found
                                </option>
                              )}
                            </select>
                          )}
                          control={control}
                          name="unique_id"
                          rules={{
                            required: "Job title is required",
                          }}
                        />
                      </IonItem>
                      {showError("unique_id")}
                    </IonCol>
                  )}
                  <IonCol sizeLg="12" sizeMd="12" sizeSm="12" sizeXs="12">
                    {/* <IonText>{canditateMessage}</IonText> */}

                    <IonButton
                      shape="round"
                      fill="solid"
                      // disabled={canditateMessage !== "" ? false : true}
                      color={"secondary"}
                      className="remove-shadow capitalizes"
                      type="submit"
                    >
                      Shortlist
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </form>
          </IonCard>
        </ModalBody>

        <IonModal mode="md" isOpen={showShortlistview}>
          <IonContent className="ion-padding text-center">
            <div style={{ marginTop: "100px" }}>
              <p> Candidate is shortlisted successfully</p>
              <IonButton
                shape="round"
                fill="solid"
                color={"secondary"}
                className="remove-shadow capitalizes"
                type="button"
                onClick={() => {
                  getJobData(CompanyUid);
                  // history.push("/candidates/");
                }}
              >
                View Shortlist Page
              </IonButton>
              <IonButton
                shape="round"
                fill="outline"
                color={"danger"}
                className="remove-shadow capitalizes"
                type="button"
                onClick={() => setShowShortlistview(false)}
              >
                Close
              </IonButton>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(ListofContractor);
