import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonTextarea,
  IonToast,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ModalBody } from "../../component";
import DataTableComponent from "../../component/DataTable/dataTable";
import useMediaQuery from "../../hooks/mediaQuery";
import {
  addInterviewFeedback,
  GetInterviewFeedback,
  UpdateInterviewFeedback,
} from "./Service/api";

export default function InterviewFeedback({ ctrid }) {
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const m4kQuery = useMediaQuery("(max-width: 2491px)");
  const [modelOpen, setModal] = React.useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [feedbackData, setFeedack] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [formType, setFormType] = useState("add");
  const [toastColor, setToastColor] = useState("danger");
  const [showToast, setShowToast] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState<number>(0);
  const [totalCountPerPage, setItemperPage] = useState<number>(100);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [feedbackuser, setFeedbackuser] = useState<any>({});
  const column = [
    { name: "S.No", field: "id", sortable: false, value: "id" },
    {
      name: "Contractor feedback",
      field: "ctr_feedback",
      sortable: false,
      value: "ctr_feedback",
      wordsplit: "",
      validate: "",
    },
    {
      name: "Contractor Rating",
      field: "ctr_rating",
      sortable: false,
      value: "ctr_rating",
      wordsplit: "",
    },
    {
      name: "Contractor feedback about company",
      field: "ctr_feedback_about_com",
      sortable: false,
      value: "ctr_feedback_about_com",
      wordsplit: "",
    },
    {
      name: "Company Feedback",
      field: "com_feedback",
      sortable: false,
      value: "com_feedback",
      wordsplit: "",
    },
    {
      name: "Company Rating",
      field: "com_rating",
      sortable: false,
      value: "com_rating",
      wordsplit: "",
    },
    {
      name: "updated on",
      field: "ctr_feedback_updated_on",
      sortable: false,
      value: "ctr_feedback_updated_on",
      validate: "date",
      wordsplit: "",
    },
    {
      name: "",
      field: "",
      sortable: false,
      value: ":edit",
      wordsplit: " ",
    },
  ];

  const renderAction = (type, item) => {
    switch (type) {
      case "edit":
        setFormType("edit");
        setValue("unique_id", item.unique_id ?? "");
        setValue("ctr_feedback", item.ctr_feedback ?? "");
        setValue("ctr_rating", item.ctr_rating ?? "");
        setValue("ctr_feedback_about_com", item.ctr_feedback_about_com ?? "");
        setValue("com_feedback", item.com_feedback ?? "");
        setValue("com_rating", item.com_rating ?? "");
        setValue("com_hiringmanager", item.com_hiringmanager ?? "");
        setValue("com_designation", item.com_designation ?? "");
        setValue("com_interviewer", item.com_interviewer ?? "");
        setFeedbackuser(item);
        setModal(true);
        return;
      case "create":
        reset();
        setFormType("add");
        setModal(true);
        return;
      default:
        return;
    }
  };

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  const getInterviewFeedbackData = (ctrid) => {
    setFeedack([]);

    GetInterviewFeedback(ctrid).then((value: any) => {
      if (value !== undefined && value.status === 200) {
        setShowLoading(false);
        // const finalData = value.data.map((item) => ({
        //   ...item,
        //   fullName: `${item.firstname} ${item.lastname}` || "",
        // }));
        setFeedack(value.data);
        setJobList(value.all_jng_job_names);
        // setItemperPage(value.itemsCountPerPage);
        // setTotalItemCount(value.totalItemsCount);
      } else {
        // setItemperPage(0);
        // setTotalItemCount(0);
        setJobList(value !== undefined && value.all_jng_job_names);
        setShowLoading(false);
        // setToastColor("danger");
        // setToastMessage(value !== undefined && );
        // setShowToast(true);
      }
    });
  };

  const handlePageChange = (e: number) => {
    if (e) {
      setCurrentPage(e);
      return;
    }
  };
  useEffect(() => {
    getInterviewFeedbackData(ctrid);
  }, [ctrid]);

  const updateContractorFeedback = (values) => {
    if (values) {
      values.usertype = "Contractor";
      updateFeedback(values);
    }
  };

  const updateCompanyFeedback = (values) => {
    if (values) {
      values.usertype = "Company";
      updateFeedback(values);
    }
  };

  const updateAllFeedback = (values) => {
    if (values) {
      values.usertype = "all";
      updateFeedback(values);
    }
  };

  const updateFeedback = (value) => {
    const req = {
      ctr_id: ctrid,
      usertype: value.usertype,
      unique_id: value.unique_id ?? feedbackuser.unique_id,
      ctr_feedback: value.ctr_feedback ?? "",
      ctr_rating: value.ctr_rating ?? "",
      ctr_feedback_about_com: value.ctr_feedback_about_com ?? "",
      com_feedback: value.com_feedback ?? "",
      com_rating: value.com_rating ?? "",
      com_hiringmanager: value.com_hiringmanager ?? "",
      com_designation: value.com_designation ?? "",
      com_interviewer: value.com_interviewer ?? "",
    };

    (formType === "add"
      ? addInterviewFeedback(req)
      : UpdateInterviewFeedback(req)
    ).then((value) => {
      if (value !== undefined && value.status === 200) {
        setToastColor("success");
        setToastMessage(value.message);
        setShowToast(true);
        setShowLoading(false);
        setModal(false);
        if (formType === "edit") {
          const newArray: any = [];
          feedbackData.filter((id: any) => {
            if (id.unique_id === req.unique_id) {
              id.ctr_feedback = req.ctr_feedback ?? "";
              id.ctr_rating = req.ctr_rating ?? "";
              id.ctr_feedback_about_com = req.ctr_feedback_about_com ?? "";
              id.com_feedback = req.com_feedback ?? "";
              id.com_rating = req.com_rating ?? "";
              id.com_hiringmanager = req.com_hiringmanager ?? "";
              id.com_designation = req.com_designation ?? "";
              id.com_interviewer = req.com_interviewer ?? "";
              newArray.push(id);
            } else {
              newArray.push(id);
            }
          });
          setFeedack(newArray);
        } else {
          getInterviewFeedbackData(ctrid);
        }
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(
          value !== undefined ? value.message : "please try again"
        );
        setShowToast(true);
      }
    });
  };

  return (
    <div>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      <div className="d-flex ion-wrap ion-align-items-center ion-justify-content-between py-2">
        <IonLabel>Interview Feedback</IonLabel>
        <IonButton
          shape="round"
          fill="solid"
          color="orange"
          className="remove-shadow capitalizes"
          type="button"
          onClick={() => {
            renderAction("create", {});
          }}
        >
          Add Feedback
        </IonButton>
      </div>
      <IonCard className="ion-padding">
        <DataTableComponent
          tableheaders={column}
          tableData={feedbackData}
          currentPage={currentPage}
          itemPerPage={totalCountPerPage}
          totalItem={feedbackData.length}
          handleAction={(type, item) => {
            renderAction(type, item);
          }}
          setCurrentPage={(page) => handlePageChange(page)}
        />
      </IonCard>
      <ModalBody
        isOpen={modelOpen}
        onClose={() => setModal(false)}
        title="Interview Feedback"
        required={true}
        subTitle={""}
      >
        {modelOpen && (
          <IonCard className="ion-padding">
            <IonGrid>
              {formType !== "add" ? (
                <IonRow>
                  <IonCol sizeLg="12" sizeXs="12">
                    <form onSubmit={handleSubmit(updateContractorFeedback)}>
                      <IonRow>
                        <IonCol sizeLg="6" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">
                              Job id <span className="required">*</span>
                            </IonLabel>
                            <Controller
                              control={control}
                              name="unique_id"
                              rules={{
                                required: "Job name is required",
                              }}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <select
                                  onChange={onChange}
                                  value={value}
                                  disabled={formType !== "add" ? true : false}
                                  placeholder="Select"
                                >
                                  <option value="">Select</option>
                                  {jobList?.map((opt: any, i) => (
                                    <option key={i} value={opt.unique_id}>
                                      {opt.job_title} ({opt.com_name})
                                    </option>
                                  ))}
                                </select>
                              )}
                            />
                          </IonItem>
                          {showError("unique_id")}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol sizeLg="12" sizeXs="12">
                          <h4>Candidate's feedback</h4>
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">
                              About interview
                            </IonLabel>
                            <Controller
                              control={control}
                              name="ctr_feedback"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <IonTextarea
                                  onIonChange={onChange}
                                  value={value}
                                  placeholder=""
                                />
                              )}
                            />
                          </IonItem>
                        </IonCol>

                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">
                              About company
                            </IonLabel>
                            <Controller
                              control={control}
                              name="ctr_feedback_about_com"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <IonTextarea
                                  onIonChange={onChange}
                                  value={value}
                                  placeholder=""
                                />
                              )}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Rating</IonLabel>
                            <Controller
                              render={({ field: { onChange, value, ref } }) => (
                                <select
                                  onChange={onChange}
                                  value={value}
                                  placeholder="Select"
                                >
                                  <option value="">Select</option>
                                  <option value="positive">Positive</option>
                                  <option value="neutral">Neutral</option>
                                  <option value="negative">Negative</option>
                                </select>
                              )}
                              control={control}
                              name={"ctr_rating"}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol size="12">
                          <IonButton
                            shape="round"
                            fill="solid"
                            color="secondary"
                            className="remove-shadow capitalizes"
                            type="submit"
                          >
                            Update Contractor Feedback
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </form>
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <h4>Company's feedback</h4>
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <form onSubmit={handleSubmit(updateCompanyFeedback)}>
                      <IonRow>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">
                              About candidate
                            </IonLabel>
                            <Controller
                              control={control}
                              name="com_feedback"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <IonTextarea
                                  onIonChange={onChange}
                                  value={value}
                                  placeholder=""
                                />
                              )}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Rating</IonLabel>
                            <Controller
                              render={({ field: { onChange, value, ref } }) => (
                                <select
                                  onChange={onChange}
                                  value={value}
                                  placeholder="Select"
                                >
                                  <option value="">Select</option>
                                  <option value="positive">Positive</option>
                                  <option value="neutral">Neutral</option>
                                  <option value="negative">Negative</option>
                                </select>
                              )}
                              control={control}
                              name={"com_rating"}
                            />
                          </IonItem>
                          {showError("com_rating")}
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Interviewer</IonLabel>
                            <Controller
                              control={control}
                              name="com_interviewer"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <IonInput
                                  onIonChange={onChange}
                                  value={value}
                                  placeholder=""
                                />
                              )}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">Designation</IonLabel>
                            <Controller
                              control={control}
                              name="com_designation"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <IonInput
                                  onIonChange={onChange}
                                  value={value}
                                  placeholder=""
                                />
                              )}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <IonLabel position="stacked">
                              Hiring Manager
                            </IonLabel>
                            <Controller
                              control={control}
                              name="com_hiringmanager"
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <IonInput
                                  onIonChange={onChange}
                                  value={value}
                                  placeholder=""
                                />
                              )}
                            />
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="12" sizeXs="12">
                          <IonButton
                            shape="round"
                            fill="solid"
                            color="secondary"
                            className="remove-shadow capitalizes"
                            type="submit"
                          >
                            Update Company Feedback
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </form>
                  </IonCol>
                </IonRow>
              ) : (
                <form onSubmit={handleSubmit(updateAllFeedback)}>
                  <IonRow>
                    <IonCol sizeLg="6" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">
                          Job id <span className="required">*</span>
                        </IonLabel>
                        <Controller
                          control={control}
                          name="unique_id"
                          rules={{
                            required: "Job name is required",
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <select
                              onChange={onChange}
                              value={value}
                              placeholder="Select"
                            >
                              <option value="">Select</option>
                              {jobList?.map((opt: any, i) => (
                                <option key={i} value={opt.unique_id}>
                                  {opt.job_title} ({opt.com_name})
                                </option>
                              ))}
                            </select>
                          )}
                        />
                      </IonItem>
                      {showError("unique_id")}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol sizeLg="12" sizeXs="12">
                      <h4>Candidate's feedback</h4>
                    </IonCol>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">About interview</IonLabel>
                        <Controller
                          control={control}
                          name="ctr_feedback"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonTextarea
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">About company</IonLabel>
                        <Controller
                          control={control}
                          name="ctr_feedback_about_com"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonTextarea
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">Rating</IonLabel>
                        <Controller
                          render={({ field: { onChange, value, ref } }) => (
                            <select
                              onChange={onChange}
                              value={value}
                              placeholder="Select"
                            >
                              <option value="">Select</option>
                              <option value="positive">Positive</option>
                              <option value="neutral">Neutral</option>
                              <option value="negative">Negative</option>
                            </select>
                          )}
                          control={control}
                          name={"ctr_rating"}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol sizeLg="12" sizeXs="12">
                      <h4>Company's feedback</h4>
                    </IonCol>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">About candidate</IonLabel>
                        <Controller
                          control={control}
                          name="com_feedback"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonTextarea
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">Rating</IonLabel>
                        <Controller
                          render={({ field: { onChange, value, ref } }) => (
                            <select
                              onChange={onChange}
                              value={value}
                              placeholder="Select"
                            >
                              <option value="">Select</option>
                              <option value="positive">Positive</option>
                              <option value="neutral">Neutral</option>
                              <option value="negative">Negative</option>
                            </select>
                          )}
                          control={control}
                          name={"com_rating"}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">Interviewer</IonLabel>
                        <Controller
                          control={control}
                          name="com_interviewer"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">Designation</IonLabel>
                        <Controller
                          control={control}
                          name="com_designation"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem lines="none">
                        <IonLabel position="stacked">Hiring Manager</IonLabel>
                        <Controller
                          control={control}
                          name="com_hiringmanager"
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <IonInput
                              onIonChange={onChange}
                              value={value}
                              placeholder=""
                            />
                          )}
                        />
                      </IonItem>
                    </IonCol>
                    <IonCol size="12">
                      <IonButton
                        shape="round"
                        fill="solid"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="submit"
                      >
                        Save Feedback
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </form>
              )}
            </IonGrid>
          </IonCard>
        )}
      </ModalBody>
    </div>
  );
}
