import React, { useState } from "react";
import { IonBackdrop, IonModal } from "@ionic/react";
import "./modal.css";

interface Props {
  showModal: boolean;
  setShowModal?: any;
}

const Modal: React.FC<Props> = ({ showModal, setShowModal, children }) => {
  return (
    <>
      {/* <IonBackdrop /> */}
      <IonModal
        isOpen={showModal}
        // onDidDismiss={() => setShowModal(false)}
        className="modal-custom-class"
      >
        {children}
      </IonModal>
    </>
  );
};

export default Modal;
