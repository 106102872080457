import {
  IonAlert,
  IonButton,
  IonCard,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonToast,
} from "@ionic/react";
import { add, pencilOutline, pencilSharp } from "ionicons/icons";
import React from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import {
  getMethod,
  fileUpload,
  SeverfilePaths,
  addDefaultcoverSrc,
  addDefaultSrc,
  apiCallBack,
  StringMap,
  formatPhoneNumber,
  sociallink,
} from "../../../service";
import { companyuserimageSave, getCompanyProfile } from "../Service/api";
import { decrypted } from "../../../service/axios";
import {
  CompanyEmailVerify,
  ModalBody,
  ReadMoreRichText,
} from "../../../component";
import LinkForm from "./LinksForm";
import IndustryDomain from "./IndustryDomain";
import { Locationimage, UserDataInterface } from "./profileInterface";
import CompanyPersonalInfo from "./personal-info";
import CompanyRoles from "./CompanyRoles";
import CompanySkill from "./CompanySkill";
import VideoModel from "../../../component/Modal/video-model";
import { ArrayStringMap } from "../../../service/default";
import useMediaQuery from "../../../hooks/mediaQuery";

interface selectedDomain {
  com_ind: string;
  ind_id: string;
  title: string;
}
interface allDomainData {
  ind_id: string;
  title: string;
}

// role

interface selectedRole {
  cr_id: string;
  name: string;
  rid: string;
}

interface allRoleData {
  rid: string;
  name: string;
}

interface selectedSkill {
  skill_id: string;
  coms_id: string;
  skill_name: string;
}

interface allSkill {
  skill_id: string;
  skill_name: string;
}

const CompanyProfile = () => {
  const match = useParams();
  const { auth } = React.useContext(AuthContext);
  const userdata = auth.data;
  const m4kQuery = useMediaQuery("(max-width: 2491px)");

  // const userdata = getCookie({ key: "user" }).value;
  const [showLoading, setShowLoading] = React.useState(false);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [folderKey, setFolderkey] = React.useState("");
  const [showVideoAlert, setVideoAlert] = React.useState(false);
  const [showremovedata, removeData] = React.useState(false);

  // getapi state
  const [getUserData, setGetUserData] = React.useState<UserDataInterface>();
  const [locationdatas, setLocation] = React.useState<Locationimage[]>();
  const [coverimage, setCoverimage] = React.useState<string>("");
  const [profileimage, setProfileimage] = React.useState<string>("");

  // skill and domine
  const [autoCompleteSkills, setAutoCompleteSkills] =
    React.useState<allSkill[]>();
  // const [tempSelectedSkills, setTempSelectedSkills] = React.useState<any>([]);
  const [selectedSkills, setSelectedSkills] = React.useState<selectedSkill[]>();

  // domine
  const [autoCompleteDomains, setAutoCompleteDomains] =
    React.useState<allDomainData[]>();
  // const [tempSelectedDomains, setTempSelectedDomains] = React.useState<any>([]);
  const [selectedDomains, setSelectedDomains] =
    React.useState<selectedDomain[]>();

  // role
  const [autoCompleteRoles, setAutoCompleteRoles] =
    React.useState<allRoleData[]>();
  // const [tempSelectedRoles, setTempSelectedRoles] = React.useState<any>([]);
  const [selectedRoles, setSelectedRoles] = React.useState<selectedRole[]>();
  const [modelOpen, setModal] = React.useState<boolean>(false);
  const [webmodelOpen, setWebModal] = React.useState<boolean>(false);
  //   get company profile data

  const getProfileData = () => {
    setShowLoading(true);
    getCompanyProfile(match["companyID"] ?? (userdata && userdata.com_id)).then(
      (value: any) => {
        if (value !== undefined && value.status === 200) {
          const comData = value.data;
          setFolderkey(comData.folder_key);
          setGetUserData(comData);
          setLocation(comData.locationimages);
          setCoverimage(decrypted(comData.cover_photo));
          setProfileimage(decrypted(comData.profile_photo));
          /* Remove selected skills from select */

          let allSkills = comData.jng_skills ?? autoCompleteSkills;

          let companySkills = comData.com_skills ?? selectedSkills;
          let filteredSkills = allSkills.filter(
            (x) => !companySkills.map((y) => y.skill_id).includes(x.skill_id)
          );
          setAutoCompleteSkills(filteredSkills);
          setSelectedSkills(companySkills);

          /* Remove selected skills from select */
          let allRoles = comData.jng_roles ?? autoCompleteRoles;
          let companyRoles = comData.com_roles ?? selectedRoles;
          let filteredRoles = allRoles.filter(
            (x) => !companyRoles.map((y) => y.rid).includes(x.rid)
          );
          setAutoCompleteRoles(filteredRoles);
          setSelectedRoles(companyRoles);

          /* Remove selected skills from select */
          let allDomains = comData.jng_domain ?? autoCompleteDomains;
          let companyDomains = comData.com_domains ?? selectedDomains;
          let filteredDomains = allDomains.filter(
            (x) => !companyDomains.map((y) => y.ind_id).includes(x.ind_id)
          );
          setAutoCompleteDomains(filteredDomains);
          setSelectedDomains(companyDomains);
          setShowLoading(false);
        } else {
          setShowLoading(false);
        }
      }
    );
  };

  //   video validation handiler

  // function handleChange(event) {
  //   if (event) {
  //     var isValid = ReactPlayer.canPlay(event.detail.value);
  //     setVideoValidity(isValid);
  //     if (!isValid) {
  //     } else setVideoAlert(false);
  //   }
  // }

  // photo upload
  const imageUpload = (e: any, type: string) => {
    const files = e.target.files;
    preventBrowserDefaults(e);
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setToastColor("danger");
        setToastMessage(errVal);
        setShowToast(true);
      }
      return false;
    }
    const imagename = files[0].name;
    fileUpload(files[0], folderKey).then((e) => {
      if (apiCallBack(e)) {
        if (type === "profile") {
          setProfileimage(e.filename);
          saveComProfileImage("", e.filename);
        } else if (type === "cover") {
          setCoverimage(e.filename);
          saveComProfileImage(e.filename, "");
        } else {
        }
      } else {
        setToastColor("danger");
        setToastMessage("Image upload failed, please try again");
        setShowToast(true);
      }
    });
  };

  // domine

  // function selectDomain(event: any) {
  //   if (event && selectedDomains && autoCompleteDomains) {
  //     let allDomains = autoCompleteDomains;
  //     let companyDomains = selectedDomains;
  //     let filteredDomains = allDomains.filter(
  //       (x) => !companyDomains.map((y) => y.ind_id).includes(x.ind_id)
  //     );
  //     filteredDomains = filteredDomains.filter(
  //       (x) => !tempSelectedDomains.map((y) => y.ind_id).includes(x.ind_id)
  //     );

  //     setAutoCompleteDomains(filteredDomains);
  //     setSelectedDomains([...companyDomains, ...tempSelectedDomains]);
  //     setTempSelectedDomains([]);

  //     if (tempSelectedDomains && tempSelectedDomains.length > 0) {
  //       tempSelectedDomains.forEach((element) => {
  //         const postData = postMethod("Companyprofile/save_company_domains", {
  //           com_id: match["companyID"] ?? userdata.com_id,
  //           domain_name: element.title,
  //         });
  //         postData.then((data) => {});
  //       });
  //     }
  //   }
  // }

  // function handleDomainChange(selectedOption) {
  //   if (selectedOption) {
  //     let tempDomains: any = [];
  //     if (selectedOption) {
  //       selectedOption.forEach((element) => {
  //         tempDomains.push({
  //           com_ind: match["companyID"] ?? userdata.com_id,
  //           ind_id: element.value,
  //           title: element.label,
  //         });
  //       });
  //     }
  //     setTempSelectedDomains(tempDomains);
  //   }
  // }

  function removeDomain(domain: any) {
    removeData(true);
    if (domain && selectedDomains) {
      const getResponse = getMethod("Deletecompanydomain/" + domain.com_ind);
      getResponse.then((res) => {
        if (res !== undefined && res.status === 200) {
        
          const filteredDomains = selectedDomains.filter((x) => x !== domain);
          setSelectedDomains(filteredDomains);
          setToastColor("success");
          setToastMessage(res.message);
          removeData(false);
          setShowToast(true);
          setShowLoading(false);
        } else {
          setToastColor("danger");
          setToastMessage(res.message);
          setShowToast(true);
          setShowLoading(false);
        }
      });
    } else {
      removeData(false);
    }
  }

  // role

  // function selectRole(event: any) {
  //   if (event && autoCompleteRoles && selectedRoles) {
  //     let allRoles = autoCompleteRoles;
  //     let companyRoles = selectedRoles;
  //     let filteredRoles = allRoles.filter(
  //       (x) => !companyRoles.map((y) => y.cr_id).includes(x.rid)
  //     );
  //     filteredRoles = filteredRoles.filter(
  //       (x) => !tempSelectedRoles.map((y) => y.rid).includes(x.rid)
  //     );

  //     setAutoCompleteRoles(filteredRoles);
  //     setSelectedRoles([...companyRoles, ...tempSelectedRoles]);

  //     setTempSelectedRoles([]);

  //     if (tempSelectedRoles && tempSelectedRoles.length > 0) {
  //       tempSelectedRoles.forEach((element) => {
  //         const postData = postMethod("Companyprofile/save_company_roles", {
  //           com_id: match["companyID"] ?? userdata.com_id,
  //           role_name: element.name,
  //         });
  //         postData.then((data) => {});
  //       });
  //     }
  //   }
  // }

  // function handleRoleChange(selectedOption) {
  //   if (selectedOption) {
  //     let tempRoles: any = [];
  //     if (selectedOption) {
  //       selectedOption.forEach((element) => {
  //         tempRoles.push({
  //           cr_id: match["companyID"] ?? userdata.com_id,
  //           rid: element.value,
  //           name: element.label,
  //         });
  //       });
  //     }
  //     setTempSelectedRoles(tempRoles);
  //   }
  // }

  function removeRole(role: any) {
    removeData(true);
    if (role && selectedRoles) {
      const getResponse = getMethod("Deletecompanyrole/" + role.cr_id);
      getResponse.then((res) => {
        if (res !== undefined && res.status === 200) {
          let filteredRoles = selectedRoles.filter((x) => x !== role);
          setSelectedRoles(filteredRoles);
          setToastColor("success");
          setToastMessage(res.message);
          setShowToast(true);
          removeData(false);
          setShowLoading(false);
        } else {
          setToastColor("danger");
          setToastMessage(res.message);
          setShowToast(true);
          setShowLoading(false);
        }
      });
    } else {
      removeData(false);
    }
  }

  // skills

  // function selectSkill(event: any) {
  //   if (event && autoCompleteSkills && selectedSkills && tempSelectedSkills) {
  //     let allSkills = autoCompleteSkills;
  //     let companySkills = selectedSkills;
  //     let filteredSkills = allSkills.filter(
  //       (x) => !companySkills.map((y) => y.skill_id).includes(x.skill_id)
  //     );
  //     filteredSkills = filteredSkills.filter(
  //       (x) => !tempSelectedSkills.map((y) => y.skill_id).includes(x.skill_id)
  //     );
  //     setAutoCompleteSkills(filteredSkills);
  //     setSelectedSkills([...companySkills, ...tempSelectedSkills]);
  //     setTempSelectedSkills([]);
  //     if (tempSelectedSkills && tempSelectedSkills.length > 0) {
  //       tempSelectedSkills.forEach((element) => {
  //         const postData = postMethod("Companyprofile/save_company_skills", {
  //           com_id: match["companyID"] ?? userdata.com_id,
  //           skill_name: element.skill_name,
  //         });
  //         postData.then((data) => {});
  //       });
  //     }
  //   }
  // }

  // function handleSkillChange(selectedOption) {
  //   if (selectedOption) {
  //     let tempSkills: any = [];
  //     if (selectedOption) {
  //       selectedOption.forEach((element) => {
  //         tempSkills.push({
  //           coms_id: match["companyID"] ?? userdata.com_id,
  //           skill_id: element.value,
  //           skill_name: element.label,
  //         });
  //       });
  //     }
  //     setTempSelectedSkills(tempSkills);
  //   }
  // }

  function removeSkill(skill: any) {
    removeData(true);
    if (skill && selectedSkills) {
      const getResponse = getMethod("Deletecompanyskill/" + skill.coms_id);
      getResponse.then((data) => {
        let filteredSkills = selectedSkills.filter((x) => x !== skill);
        setSelectedSkills(filteredSkills);
        removeData(false);
      });
    } else {
      removeData(false);
    }
  }

  // save profile

  const saveComProfileImage = (coverimages, image) => {
    setShowLoading(true);
    const req = {
      com_cover_photo: coverimages ? coverimages : coverimage,
      com_profile_photo: image ? image : profileimage,
      com_id: match["companyID"] ?? userdata.com_id,
    };

    companyuserimageSave(req).then((value) => {
      if (value !== undefined && value.status === 200) {
        setToastColor("success");
        setToastMessage("Profile Updated Successfully");
        setShowToast(true);
        setShowLoading(false);
      } else {
        setToastColor("danger");
        setToastMessage("Unable to Update Profile");
        setShowToast(true);
        setShowLoading(false);
      }
    });
  };

  React.useEffect(() => {
    let isMounted = true;

    try {
      getProfileData();
    } catch (err: any) {
      if (isMounted) {
        setToastColor("danger");
        setToastMessage(err.message);
        setShowToast(true);
      }
    } finally {
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  return (
    <div>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />

      {userdata && userdata.user_type === "Company" && <CompanyEmailVerify />}

      <div className={m4kQuery ? "container" : ""}>
        {userdata && userdata.user_type === "Company" && (
          <div className="text-center">
            <h1>Profile</h1>
            <h5 className="text-gray">Company Info</h5>
          </div>
        )}
        <div className="main-container profile-container">
          {!showLoading && (
            <>
              <div
                className="cover-img mb-4"
                style={{
                  backgroundImage: `url(${
                    folderKey && coverimage
                      ? SeverfilePaths(folderKey) + coverimage
                      : "assets/image/Hirify-Pitch-logo.png"
                  })`,
                  backgroundSize: `${
                    folderKey && coverimage ? "cover" : "60% 100%"
                  }`,
                }}
              >
                <label className="btn btn-default cover-img">
                  <IonIcon
                    className=" custom-edit i-color"
                    icon={pencilSharp}
                  ></IonIcon>
                  <input
                    type="file"
                    accept="image/*"
                    id="upload-button"
                    hidden
                    onChange={(e) => {
                      e.preventDefault();
                      imageUpload(e, "cover");
                    }}
                  />
                </label>
              </div>
            </>
          )}

          {/* form */}
          {!showLoading && (
            <IonCard className="ion-padding">
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg="12" sizeXs="12" sizeSm="12">
                    <IonGrid>
                      <IonRow>
                        <IonCol sizeLg="12" sizeXs="12" sizeSm="12">
                          <div className="profile-img-com">
                            <img
                              src={SeverfilePaths(folderKey) + profileimage}
                              alt="avatar"
                              onError={addDefaultSrc}
                              className="photo"
                            />

                            <label className="btn btn-default">
                              <IonIcon
                                className="custom-edit i-color"
                                icon={add}
                              ></IonIcon>
                              <input
                                type="file"
                                accept="image/*"
                                id="upload-button"
                                hidden
                                onChange={(e: any) => {
                                  e.preventDefault();
                                  imageUpload(e, "profile");
                                }}
                              />
                            </label>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    <IonItem lines="none" className="custom-header-space ">
                      <h4>Personal information</h4>
                      <IonButton
                        slot="end"
                        fill="outline"
                        className="remove-shadow custom-circle-btn"
                        color="medium"
                        shape="round"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        <IonIcon icon={pencilOutline} slot="icon-only" />
                      </IonButton>
                    </IonItem>
                    <IonGrid>
                      <IonRow>
                        <IonCol sizeLg="4" sizeXs="12">
                          <label>
                            First Name <span className="required">*</span>
                          </label>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>{StringMap(getUserData?.firstname)}</span>
                          <hr />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <label>
                            Last Name <span className="required">*</span>
                          </label>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>{StringMap(getUserData?.lastname)}</span>
                          <hr />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <label>
                            Username <span className="required">*</span>
                          </label>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>{StringMap(getUserData?.username)}</span>
                          {/* {contacts && countrycodes + " "}
                    {formatPhoneNumber(contacts)} */}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    {/* <hr className="dot" /> */}
                    <IonItem lines="none" className="custom-header-space ">
                      <h4>About</h4>
                    </IonItem>
                    <IonGrid>
                      <IonRow>
                        <IonCol sizeLg="12" sizeXs="12">
                          <span>
                            <ReadMoreRichText length={250}>
                              {getUserData?.about}
                            </ReadMoreRichText>

                            {/* <ReadMore>{StringMap(getText(summaryData))}</ReadMore> */}
                          </span>
                          <hr />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <div>
                            <label>Video about Company</label>
                            <p className="text-gray small">
                              Elaborate your passion with a lively video
                              presentation.
                            </p>
                          </div>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          {!showLoading &&
                            StringMap(getUserData?.video) !== "NA" && (
                              <div>
                                <VideoModel videoLink={getUserData?.video} />
                              </div>
                            )}
                          <hr />
                          {/* {getUserData?.video ? (
                            <a href={getUserData?.video} target="_blank" rel="">
                              {getUserData?.video}
                            </a>
                          ) : (
                            "NA"
                          )} */}
                          {/* 20 MB size limit; 30-60s ideal video length. */}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    {/* <hr className="dot" /> */}
                    <IonItem lines="none" className="custom-header-space ">
                      <h4>Contact</h4>
                    </IonItem>
                    <IonGrid>
                      <IonRow>
                        <IonCol sizeLg="4" sizeXs="12">
                          <div>
                            Email <span className="required">*</span>
                          </div>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>
                            {StringMap(decrypted(getUserData?.email))}
                          </span>
                          <hr />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <div>
                            <label>Mobile number</label>
                          </div>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>
                            {decrypted(getUserData?.mobile)
                              ? formatPhoneNumber(
                                  decrypted(getUserData?.mobile)
                                )
                              : "NA"}
                          </span>
                          <hr />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <div>
                            <label>Office Number</label>
                          </div>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>
                            {decrypted(getUserData?.alt_mobile)
                              ? formatPhoneNumber(
                                  decrypted(getUserData?.alt_mobile)
                                )
                              : "NA"}
                          </span>
                          <hr />
                        </IonCol>

                        <IonCol sizeLg="4" sizeXs="12">
                          <div>Company Location and image</div>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>
                            <div className="previewimage">
                              {locationdatas && locationdatas.length > 0 ? (
                                <img
                                  alt=""
                                  onError={addDefaultcoverSrc}
                                  src={
                                    SeverfilePaths(getUserData?.folder_key) +
                                    locationdatas[0]["image"]
                                  }
                                  style={{ height: "80px", width: "100px" }}
                                />
                              ) : (
                                <img
                                  alt="."
                                  onError={addDefaultcoverSrc}
                                  src="/asset/immm.jpg"
                                  style={{ height: "80px", width: "100px" }}
                                />
                              )}
                              {locationdatas &&
                                locationdatas.length > 0 &&
                                StringMap(
                                  getUserData?.locationimages[0]["address"]
                                )}
                            </div>
                          </span>
                          <hr />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                    {/* <hr className="dot" /> */}
                    <IonItem lines="none" className="custom-header-space ">
                      <h4>Links</h4>
                      <IonButton
                        slot="end"
                        fill="outline"
                        className="remove-shadow custom-circle-btn"
                        color="medium"
                        shape="round"
                        onClick={(e) => {
                          setWebModal(true);
                        }}
                      >
                        <IonIcon icon={pencilOutline} slot="icon-only" />
                      </IonButton>
                    </IonItem>
                    {/* {!getlink && <Link array={getlink} />} */}
                    <IonGrid>
                      <IonRow>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <img
                              alt=""
                              onError={sociallink}
                              className="space-right"
                              src={"assets/image/linkicon/in.svg"}
                            />
                            <IonLabel className="lable">LinkedIn</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>
                            {" "}
                            {StringMap(
                              decrypted(getUserData?.com_linked_in_url)
                            )}
                          </span>
                          <hr />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <img
                              alt=""
                              onError={sociallink}
                              className="space-right"
                              src={"assets/image/linkicon/FB.svg"}
                            />
                            <IonLabel className="lable">Facebook</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>
                            {" "}
                            {StringMap(
                              decrypted(getUserData?.com_facebook_url)
                            )}
                          </span>
                          <hr />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <img
                              alt=""
                              onError={sociallink}
                              className="space-right"
                              src={"assets/image/linkicon/Instagram.svg"}
                            />
                            <IonLabel className="lable">Instagram</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>
                            {" "}
                            {StringMap(
                              decrypted(getUserData?.com_instagram_url)
                            )}
                          </span>
                          <hr />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <img
                              alt=""
                              onError={sociallink}
                              className="space-right"
                              src={"assets/image/linkicon/Twitter.svg"}
                            />
                            <IonLabel className="lable">Twitter</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>
                            {" "}
                            {StringMap(decrypted(getUserData?.com_twitter_url))}
                          </span>
                          <hr />
                        </IonCol>
                        <IonCol sizeLg="4" sizeXs="12">
                          <IonItem lines="none">
                            <img
                              alt=""
                              onError={sociallink}
                              className="space-right"
                              src={"assets/image/linkicon/language.svg"}
                            />
                            <IonLabel className="lable">Weblink</IonLabel>
                          </IonItem>
                        </IonCol>
                        <IonCol sizeLg="8" sizeXs="12">
                          <span>
                            {getUserData?.websites
                              ? getUserData?.websites.map((e, index) => (
                                  <IonChip color="light" key={index}>
                                    <a
                                      href={e.website_url}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {e.website_url}
                                    </a>
                                  </IonChip>
                                ))
                              : "NA"}{" "}
                          </span>
                          <hr />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                  <IonCol sizeLg="12" sizeXs="12">
                    <IndustryDomain
                      title="Industry Domain"
                      array={selectedDomains && selectedDomains}
                      removeDomain={removeDomain}
                      loadDelete={showremovedata}
                      allDomain={autoCompleteDomains}
                      comid={match["companyID"] ?? userdata.com_id}
                      refreshData={() => getProfileData()}
                    />
                    <hr className="dot" />
                    <CompanyRoles
                      title="Company Roles"
                      array={selectedRoles && selectedRoles}
                      removeRole={removeRole}
                      loadDelete={showremovedata}
                      allRole={autoCompleteRoles}
                      comid={match["companyID"] ?? userdata.com_id}
                      refreshData={() => getProfileData()}
                    />

                    <hr className="dot" />
                    <CompanySkill
                      title="Company Skills"
                      array={selectedSkills && selectedSkills}
                      removeSkill={removeSkill}
                      loadDelete={showremovedata}
                      allSkill={autoCompleteSkills}
                      comid={match["companyID"] ?? userdata.com_id}
                      refreshData={() => getProfileData()}
                    />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
          )}
        </div>
      </div>
      <ModalBody
        title={"Personal Infomation"}
        isOpen={modelOpen}
        onClose={() => {
          setModal(false);
        }}
      >
        <CompanyPersonalInfo
          com_id={match["companyID"] ?? userdata.com_id}
          folderKey={getUserData?.folder_key}
          profileData={getUserData}
          modalSet={modelOpen}
          closeModal={() => {
            setModal(false);
            getProfileData();
          }}
        />
      </ModalBody>

      <ModalBody
        title={"Links"}
        isOpen={webmodelOpen}
        onClose={(e) => {
          setWebModal(false);
        }}
      >
        <LinkForm
          com_id={match["companyID"] ?? userdata.com_id}
          profileData={getUserData}
          modalSet={webmodelOpen}
          closeModal={() => {
            setWebModal(false);
            getProfileData();
          }}
        />
      </ModalBody>

      <IonAlert
        isOpen={showVideoAlert}
        onDidDismiss={() => setVideoAlert(false)}
        header={"Error"}
        message={"Video link is not valid."}
        buttons={["OK"]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
    </div>
  );
};

export default CompanyProfile;

// image validation

const preventBrowserDefaults = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const fileValidator = (files: any, config: any) => {
  const { allowedFileFormats, fileSizeMBLimit, filesLimit } = config;
  const { length } = files;
  const { size, type } = files[0];
  let err = "";
  let result = {
    isValidFile: false,
    errVal: err,
  };
  if (length === 0) {
    return result;
  } else if (length > filesLimit) {
    err =
      filesLimit > 1
        ? `Only ${filesLimit} files are allowed to upload`
        : `Only one file is allowed to upload`;
  } else if (!allowedFileFormats.includes(type)) {
    err = "File format must be either .jpg and .png";
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    err = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  result.errVal = err;
  return result;
};

const config = {
  allowedFileFormats: ["image/jpeg", "image/jpg", "image/png"],
  fileSizeMBLimit: 5,
  filesLimit: 1,
};
