import { IonAlert, IonLoading, IonText, IonToast } from "@ionic/react";
import React, { useRef } from "react";
import { CardProfileImage } from "../../../../../component";
import { resumeUpload, getCookie } from "../../../../../service";

interface Props {
  closeModal?: any;
  folderKey: string;
}

export const UploadResume: React.FC<Props> = ({
  closeModal,
  folderKey,
}: any) => {
  let [dragOverlay, setDragOverlay] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [showAlert, setShowAlert] = React.useState(false);
  const [showConfirm, setshowConfirm] = React.useState<any>();
  const [error, setError] = React.useState<any>();
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setshowLoading] = React.useState(false);
  let dragCounter = useRef(0);

  const handleFiles = (e) => {
    const files = e.target.files;

    preventBrowserDefaults(e);
    setDragOverlay(false);
    setError(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
        setData("");
      }
      return false;
    }
    setError("");
    setShowAlert(true);
    setshowConfirm(files);
    // if (!showAlert) {
    //   fileReader(files);
    // }
  };

  const handleDrag = (e) => {
    preventBrowserDefaults(e);
  };
  const handleDragIn = (e) => {
    preventBrowserDefaults(e);
    dragCounter.current++;
  };
  const handleDragOut = (e) => {
    preventBrowserDefaults(e);
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragOverlay(false);
    }
  };
  const handleDrop = (e) => {
    const files = e.dataTransfer.files;
    preventBrowserDefaults(e);
    setDragOverlay(false);
    setError(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
        setData("");
      }
      return false;
    }
    setError("");
    setShowAlert(true);
    setshowConfirm(files);
  };

  const fileReader = (files: any) => {
    const fileData = files;
    const reader = new FileReader();
    setData(files[0].name);
    // const user = getCookie({ key: "user" }).value;
    if (showConfirm) {
      setshowLoading(true);
      resumeUpload(files[0], folderKey).then((val: any) => {
        if (val !== undefined && val.status === 200) {
          setshowLoading(false);
          closeModal(false);
          setToastColor("success");
          setToastMessage(val.message);
          setShowToast(true);
        } else {
          setshowLoading(false);
          setToastColor("danger");
          setToastMessage(
            val.message !== undefined ? val.message : "Some Data are Empty"
          );
          setShowToast(true);
        }
      });
    } else {
      setError("");
      setData("");
    }
  };

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={"Continue uploading this file?"}
        subHeader={"Confirm here"}
        message={""}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
              setshowConfirm("");
              setError("");
              setData("");
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert(false);
              fileReader(showConfirm);
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
        message={"Uploading the file..."}
      />

      <div className="form-content text-center">
        <p>Import profile details</p>
        <h1>Upload your latest résumé</h1>
        <p>
          We'll fill your profile details so you can quickly move to exploring
          more exciting work to do.
        </p>

        <div
          id="drop-area"
          onDragEnter={handleDragIn}
          onDragLeave={handleDragOut}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          <input
            type="file"
            id="fileElem"
            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,"
            onChange={(e) => {
              handleFiles(e);
            }}
          />

          <label className="upload-label" htmlFor="fileElem">
            <div className="upload-text">
              <p>
                <IonText color="danger">{error && error}</IonText>

                <IonText color="success">{data && data}</IonText>
              </p>
              <CardProfileImage
                folder_key=""
                profile_photo="assets/image/file_upload.svg"
                name="upload"
              />
              <h5>Just drop it here to upload</h5>
              <p>we'll take care of the rest</p>
            </div>
          </label>

          {/* <div className="image" /> */}
        </div>
        <small>
          Facing any difficulty?{" "}
          <a href="https://apps.hirify.ai/login">Get help</a>
        </small>

        <div className="divider-line"></div>
        <div className="bottom-section">
          <CardProfileImage
            folder_key=""
            profile_photo="assets/image/hirify.png"
            name="logo"
          />

          <div className="footer-content">
            <small>© 2024–present, Hirify. All rights reserved.</small>

            <br />
            <small>
              <a
                target="_blank"
                rel="noreferrer"
                href="/terms"
              >
                Terms of Use •
              </a>{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

const preventBrowserDefaults = (e) => {
  e.preventDefault();
  e.stopPropagation();
};

const fileValidator = (files: any, config: any) => {
  const { allowedFileFormats, fileSizeMBLimit, filesLimit } = config;
  const { length } = files;
  const { size, type } = files[0];
  let err = "";
  let result = {
    isValidFile: false,
    errVal: err,
  };
  if (length === 0) {
    return result;
  } else if (length > filesLimit) {
    err =
      filesLimit > 1
        ? `Only ${filesLimit} files are allowed to upload`
        : `Only one file is allowed to upload`;
  } else if (!allowedFileFormats.includes(type)) {
    err = "File format must be either .doc, .docx and .pdf";
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    err = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  result.errVal = err;
  return result;
};

const config = {
  allowedFileFormats: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "",
  ],
  fileSizeMBLimit: 5,
  filesLimit: 1,
};
