import {
  IonButton,
  IonCard,
  IonCheckbox,
  IonChip,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonToast,
} from "@ionic/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Ceditor, DatePick, LocationSearch } from "../../../../component";
import { SaveJob, updateJob } from "../../Service/api";
import { arrayOptionData, arrayskillOptionData, getIndexValue } from "../../../../service";
import { useHistory, useParams } from "react-router";
import { Staff } from "../../../../service/default";
import { AuthContext } from "../../../../context/authContext";

var status = "1";

const JobForm = ({
  modalSet,
  ids,
  closeModal,
  workhomeOption,
  paymentDurationOption,
  workPermitOption,
  currencyOption,
  comid,
  skillsOption,
  formType,
  buttonAction = false,
  uniqueid,
  editDatas,
  updateState,
}: any) => {
  const { auth } = React.useContext(AuthContext);
  const userType = auth.data;
  const companyName = userType.firstname + userType.lastname;
  const history = useHistory();
  const match = useParams();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [manidoryskill, setManidory] = React.useState([]);
  const [additionalskill, setAdditional] = React.useState([]);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [showLoading, setShowLoading] = React.useState(true);
  const [latlon, setLatLon] = React.useState<any>();

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className='errorMessage text-left'>{error.message || _fieldName + " field is required"}</div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };
  React.useEffect(() => {
    setValues();
    if (formType !== "add") {
      const arr = editDatas.US_work_authorization;
      return (
        workPermitOption !== undefined &&
        workPermitOption.forEach((fruite: any) => {
          if (
            getIndexValue(fruite.value, arr, "us_work_authrizations", "us_work_authrizations") ===
            fruite.value
          ) {
            fruite.checked = true;
          } else {
            fruite.checked = false;
          }
        })
      );
    } else {
      firstworkPermitCheck("", "1");
    }
    setShowLoading(false);
  }, [formType]);

  const setValues = () => {
    setValue("duration_type", editDatas.engagement_type);
    setValue("description", editDatas.description);
    setValue("work_location", editDatas.work_location);
    setValue("department", editDatas.department);
    setValue("hiring_manager", editDatas.hiring_manager);
    setValue("title", editDatas.job_title);
    setValue("payment", editDatas.payment);
    setValue("pay_type", editDatas.pay_type ?? "1");
    setValue("pay_duration", editDatas.pay_duration ?? "1");
    setValue("wfh_type", editDatas.wfh_type ?? "2");
    setValue("overall_exp", editDatas.overall_exp ?? "");
    setValue("posted_by", editDatas.posted_by ?? companyName);
    setValue("Glove_team_manager", editDatas.Glove_team_manager ?? false);
    setValue("start_date", editDatas.start_date ? new Date(editDatas.start_date) : "");
    setValue("end_date", editDatas.end_date ? new Date(editDatas.end_date) : "");
    setValue("openings", editDatas.openings ?? "1");
    setValue("mandatory_skills", arrayOptionData(editDatas.mandatory_skills, "skill_name", "skill_id"));
    setValue("additional_skills", arrayOptionData(editDatas.additional_skills, "skill_name", "skill_id"));
    setShowLoading(false);
  };
  const saveData = (val) => {
    setTimeout(function () {
      val.mandatory_skills = arrayskillOptionData(val.mandatory_skills, "label", "");
      val.additional_skills = arrayskillOptionData(val.additional_skills, "label", "");
      var workData: any[] = [];
      workPermitOption.map((check: any) => {
        if (check.checked === true) workData.push(check.value);
      });
      val.com_id = comid ?? match["companyID"];
      val.us_work_authrizations = workData;
      val.status = status;
      val.pay_type = "1";
      val.pay_duration = val.duration_type === "0" ? "4" : "1";
      setShowLoading(true);

      if (formType === "add") {
        SaveJob(val).then((value) => {
          if (value !== undefined && value.status === 200) {
            setShowLoading(false);
            setToastColor("success");
            setToastMessage(value.message);
            setShowToast(true);
            closeModal(false);
          } else {
            setShowLoading(false);
            setToastColor("danger");
            setToastMessage(value !== undefined && value.message);
            setShowToast(true);
          }
        });
      } else {
        val.com_pjid = editDatas.com_id;
        val.unique_id = uniqueid;
        updateJob(val).then((value) => {
          if (value !== undefined && value.status === 200) {
            setShowLoading(false);
            setToastColor("success");
            setToastMessage(value.message);
            setShowToast(true);
            closeModal(false);
            if (buttonAction) {
              updateState(val);
            }
            if (userType.user_type === Staff) {
              history.replace("/Company/" + comid);
            }
            // history.goBack();
          } else {
            setShowLoading(false);
            setToastColor("danger");
            setToastMessage(value !== undefined && value.message);
            setShowToast(true);
          }
        });
      }
    }, 1000);
  };

  function selectLocation(e: any) {
    if (e) {
      setValue("work_location", e);
    }
  }

  function addressLatlon(e: any) {
    setLatLon(e);
  }

  const workPermitCheck = (value: any, data: any) => {
    return workPermitOption.forEach((fruite) => {
      if (fruite.value === data) fruite.checked = value;
    });
  };

  const firstworkPermitCheck = (value: any, data: any) => {
    return workPermitOption.forEach((fruite) => {
      if (fruite.value !== data) fruite.checked = false;
      if (fruite.value === data) fruite.checked = true;
    });
  };

  // submit button click
  const submitData = (data) => {
    status = data;
  };

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      {!showLoading && (
        <div className='Jobs'>
          <div className='px-lg-5 px-md-5 px-xs-3 pt-3 '></div>

          <form onSubmit={handleSubmit(saveData)}>
            <IonCard className='ion-padding custom-modal-alignment'>
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg='4' sizeXs='12'>
                    <div className='py-2'>
                      <IonLabel position='stacked'>Engagement</IonLabel>

                      <Controller
                        control={control}
                        name='duration_type'
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <IonSegment
                            mode='ios'
                            className='job-segment-form'
                            style={{ width: "100%" }}
                            value={value}
                            onIonChange={onChange}
                          >
                            <IonSegmentButton value='0'>
                              <IonLabel>Contract</IonLabel>
                            </IonSegmentButton>
                            <IonSegmentButton value='1'>
                              <IonLabel>Permanent</IonLabel>
                            </IonSegmentButton>
                          </IonSegment>
                        )}
                      />
                    </div>
                  </IonCol>
                  <IonCol sizeLg='4' sizeXs='12'>
                    <IonItem lines='none'>
                      <IonLabel position='stacked'>Department</IonLabel>
                      <Controller
                        control={control}
                        name='department'
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <IonInput
                            autocomplete='off'
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={0}
                            maxlength={64}
                            placeholder=''
                          />
                        )}
                      />
                    </IonItem>
                    {showError("department")}
                  </IonCol>
                  <IonCol sizeLg='4' sizeXs='12'>
                    <IonItem lines='none'>
                      <IonLabel position='stacked'>Hiring Manager</IonLabel>
                      <Controller
                        control={control}
                        name='hiring_manager'
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <IonInput
                            autocomplete='off'
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={0}
                            maxlength={64}
                            placeholder=''
                          />
                        )}
                      />
                    </IonItem>
                    {showError("hiring_manager")}
                  </IonCol>
                  <IonCol sizeLg='12' sizeXs='12'>
                    <div>
                      <Controller
                        control={control}
                        name='Glove_team_manager'
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <IonItem lines='none'>
                            <IonCheckbox
                              color='success'
                              slot='start'
                              checked={value}
                              onIonChange={(e: any) => setValue("Glove_team_manager", e.detail.checked)}
                            />
                            <IonLabel className='ion-text-wrap'>
                              Let white glove team manage responses and find suitable candidates
                            </IonLabel>
                          </IonItem>
                        )}
                      />
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>

            <IonCard className='ion-padding custom-modal-alignment'>
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg='12' sizeXs='12'>
                    <h2>Opportunity</h2>
                  </IonCol>

                  <IonCol sizeLg='6' sizeXs='12'>
                    <IonItem lines='none'>
                      <IonLabel position='stacked'>
                        Position/designation <span className='required'>*</span>
                      </IonLabel>
                      <Controller
                        control={control}
                        name='title'
                        rules={{
                          required: "Position/designation is required",
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <IonInput
                            autocomplete='off'
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            minlength={0}
                            maxlength={80}
                            placeholder=''
                          />
                        )}
                      />
                    </IonItem>
                    {showError("title")}
                  </IonCol>

                  <IonCol sizeLg='6' sizeXs='12'>
                    <IonItem lines='none'>
                      <IonLabel position='stacked'>
                        Pay <span className='required'>*</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonInput
                            autocomplete='off'
                            type='text'
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            placeholder='₹ Min - Max'
                          />
                        )}
                        control={control}
                        name='payment'
                        rules={{
                          required: "Pay is required",
                          pattern: {
                            value: /[0-9]/,
                            message: "Only accept numeric value",
                          },
                        }}
                      />
                      <div className='custom-groupInput-label'>
                        {watch("duration_type") === "0" ? " per hour" : "per year"}
                      </div>
                      {/* <div className="custom-groupInput">
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <select
                              disabled
                              onBlur={onBlur}
                              onChange={onChange}
                              value={watch("duration_type") === "0" ? "4" : "1"}
                              placeholder="Select"
                            >
                              <option value="">Select</option>
                              {paymentDurationOption !== undefined &&
                                paymentDurationOption.map(
                                  (option: any, i: number) => (
                                    <option key={i} value={option.value}>
                                      {option.label}
                                    </option>
                                  )
                                )}
                            </select>
                          )}
                          control={control}
                          name="pay_duration"
                        />
                      </div> */}
                      <IonText className='custom-content right-content-2 text-orange'>₹ IND</IonText>

                      {/* <div className="currencyLabel ">
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <select
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              placeholder="Select"
                              className="text-orange"
                            >
                              <option value="">Select</option>
                              {currencyOption !== undefined &&
                                currencyOption.map((option: any, i: number) => (
                                  <option key={i} value={option.value}>
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          )}
                          control={control}
                          name="pay_type"
                          defaultValue="1"
                        />
                      </div>*/}
                    </IonItem>
                    {showError("payment")}
                  </IonCol>

                  <IonCol sizeLg='6' sizeXs='12'>
                    <div className='locationInput'>
                      <IonLabel position='stacked'>
                        Location <span className='required'>*</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <LocationSearch
                            valueKey={value}
                            onChangeValue={onChange}
                            onSelectValue={(e: any) => selectLocation(e)}
                            latLong={addressLatlon}
                            refresh={modalSet}
                          />
                        )}
                        name='work_location'
                        control={control}
                        rules={{ required: "Location is required" }}
                      />
                    </div>
                    {showError("work_location")}
                  </IonCol>

                  <IonCol sizeLg='6' sizeXs='12'>
                    <IonItem lines='none'>
                      <IonLabel position='stacked'>Work from home</IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <select onBlur={onBlur} onChange={onChange} value={value} placeholder='Select'>
                            <option value=''>Select</option>
                            {workhomeOption.map((option: any, i: number) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                        control={control}
                        name='wfh_type'
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol sizeLg='12' sizeXs='12'>
                    <IonItem lines='none'>
                      <IonLabel position='stacked'>
                        Job Description <span className='required'>*</span>
                      </IonLabel>
                      <Controller
                        control={control}
                        name='description'
                        rules={{ required: "description is required" }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Ceditor setEditorData={onChange} editorData={value} />
                        )}
                      />
                    </IonItem>
                    {showError("description")}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>
            <IonCard className='ion-padding custom-modal-alignment'>
              <IonGrid>
                <IonRow>
                  <IonCol sizeLg='12' sizeXs='12'>
                    <h2>Requirements</h2>
                  </IonCol>
                  <IonCol sizeLg='4' sizeXs='12'>
                    <IonItem lines='none'>
                      <IonLabel position='stacked'>
                        Experience <span className='required'>*</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonInput
                            autocomplete='off'
                            type='text'
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            placeholder='Min - Max'
                          />
                        )}
                        control={control}
                        name='overall_exp'
                        rules={{ required: "Experience is required" }}
                      />
                      <div className='custom-groupInput-label'>years</div>
                    </IonItem>
                    {showError("overall_exp")}
                  </IonCol>
                  <IonCol sizeLg='4' sizeXs='12'>
                    <div className='input-div'>
                      <IonLabel position='stacked'>
                        Start <span className='required'>*</span>
                      </IonLabel>
                      <br/>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <DatePick value={value} ChangeEvent={onChange} minDate={new Date()} />
                        )}
                        control={control}
                        name='start_date'
                        rules={{ required: "Start Date is required" }}
                      />
                    </div>
                    {showError("start_date")}
                  </IonCol>
                  {watch("duration_type") === "0" && (
                    <IonCol sizeLg='4' sizeXs='12'>
                      <div className='input-div'>
                        <IonLabel position='stacked'>
                          End <span className='required'>*</span>
                        </IonLabel>
                        <br/>
                        <Controller
                          render={({ field: { onChange, onBlur, value } }) => (
                            <DatePick
                              value={value}
                              ChangeEvent={onChange}
                              minDate={watch("start_date") ? watch("start_date") : new Date()}
                            />
                          )}
                          control={control}
                          name='end_date'
                          rules={{ required: "End Date is required" }}
                        />
                      </div>
                      {showError("end_date")}
                    </IonCol>
                  )}
                  {/* <IonCol sizeLg='12' sizeXs='12'>
                    <span className='p-3'>Acceptable US work authorizations</span>
                    <IonGrid className='ion-no-padding px-2'>
                      <IonRow>
                        {workPermitOption !== undefined &&
                          workPermitOption.map(
                            (work: any, ind: number) =>
                              work.value !== "3" && (
                                <IonCol key={ind} sizeLg='4' sizeMd='6' sizeSm='12' sizeXs='12'>
                                  <IonItem lines='none'>
                                    <IonCheckbox
                                      color='success'
                                      slot='start'
                                      checked={work.value === "1" ? true : work.checked}
                                      disabled={work.value === "1"}
                                      onIonChange={(e: any) => workPermitCheck(e.detail.checked, work.value)}
                                    />
                                    <IonLabel>{work.label}</IonLabel>
                                  </IonItem>
                                </IonCol>
                              ),
                          )}
                      </IonRow>
                    </IonGrid>
                  </IonCol> */}
                  <IonCol sizeLg='12' sizeXs='12'>
                    <span className='p-3'>
                      Mandatory skills <span className='required'>*</span>
                    </span>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Typeahead
                          allowNew
                          multiple
                          dropup
                          id='custom-selections-input'
                          newSelectionPrefix='Add a new skill: '
                          options={arrayOptionData(skillsOption, "skill_name", "skill_id")}
                          onChange={onChange}
                          selected={value}
                          placeholder=''
                        />
                      )}
                      control={control}
                      name='mandatory_skills'
                      rules={{ required: "Mandatory skills is required" }}
                    />
                    {/* <div className="custom-groupInput-label-icon">
                    <img src="assets/image/company/add.svg" />
                  </div> */}
                    {manidoryskill.length > 0 &&
                      manidoryskill.map((a: any, i: number) => (
                        <IonChip color='orange' className='my-3' key={i}>
                          <IonLabel>{a.skill_name}</IonLabel>
                        </IonChip>
                      ))}
                    {showError("mandatory_skills")}
                  </IonCol>
                  <IonCol sizeLg='12' sizeXs='12'>
                    <span className='p-3'>Additional skills</span>
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Typeahead
                          allowNew
                          multiple
                          dropup
                          id='custom-selections-input'
                          newSelectionPrefix='Add a new skill: '
                          options={arrayOptionData(skillsOption, "skill_name", "skill_id")}
                          onChange={onChange}
                          selected={value}
                          placeholder=''
                        />
                      )}
                      control={control}
                      name='additional_skills'
                    />
                    {/* <div className="custom-groupInput-label-icon">
                    <img src="assets/image/company/add.svg" />
                  </div> */}
                    {additionalskill.length > 0 &&
                      additionalskill.map((a: any, i: number) => (
                        <IonChip color='orange' className='my-3' key={i}>
                          <IonLabel>{a.skill_name}</IonLabel>
                        </IonChip>
                      ))}
                  </IonCol>
                  <IonCol sizeLg='2' sizeXs='12'>
                    <IonItem lines='none'>
                      <IonLabel position='stacked'>
                        Openings <span className='required'>*</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <IonInput
                            type='number'
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            placeholder='0'
                            min={"0"}
                          />
                        )}
                        control={control}
                        name='openings'
                        rules={{
                          required: "Openings is required",
                          pattern: {
                            value: /[0-9]/,
                            message: "Only accept numeric value",
                          },
                        }}
                      />
                    </IonItem>
                    {showError("openings")}
                  </IonCol>
                  <IonCol sizeLg='6' sizeXs='12'>
                    <IonItem lines='none'>
                      <IonLabel position='stacked'>
                        Posted by <span className='required'>*</span>
                      </IonLabel>
                      <Controller
                        render={({ field: { onChange, onBlur, value } }) => (
                          <select onBlur={onBlur} onChange={onChange} value={value} placeholder='Select'>
                            <option value=''>Select</option>
                            <option value='Hirify'>Hirify</option>
                            <option value={companyName}>{companyName}</option>
                          </select>
                        )}
                        control={control}
                        name='posted_by'
                        rules={{
                          required: "posted by is required",
                        }}
                      />
                    </IonItem>
                    {showError("posted_by")}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCard>

            <div className='text-right pb-4'>
              {formType !== "add" && (
                <IonButton
                  shape='round'
                  fill='clear'
                  color='orange'
                  className=' remove-shadow capitalizes'
                  type='submit'
                  onClick={(e) => {
                    submitData("0");
                  }}
                >
                  Close Job
                </IonButton>
              )}
              <IonButton
                shape='round'
                fill='outline'
                color='orange'
                className=' remove-shadow capitalizes'
                type='submit'
                onClick={(e) => {
                  submitData("2");
                }}
              >
                Save as Draft
              </IonButton>
              <IonButton
                shape='round'
                fill='solid'
                color='orange'
                className='remove-shadow capitalizes'
                type='submit'
                onClick={(e) => submitData("1")}
              >
                {formType !== "add" ? "Save Job" : "Post Job"}
              </IonButton>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
export default React.memo(JobForm);
