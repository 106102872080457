import { IonProgressBar, IonRange } from "@ionic/react";

interface slideRange {
  min?: number;
  max?: number;
  value: number;
  hangleChange: (dta) => void;
  disable?: boolean;
  slot?: string;
  screenPading?: boolean;
}

export const CustomRangeSlider = ({
  min = 0,
  max = 10,
  value = 0,
  hangleChange,
  slot,
  disable = false,
  screenPading = false,
}: slideRange) => {
  return (
    <div
      className={`custum-range-slide ${screenPading ? "removePadding" : ""} `}
    >
      {/* <span className="chip-font textAlign-right
      ">{ value <= 3 ? `Poor` : value <= 7 ? `Average` : `Good`}</span> */}
      <IonRange
        className={`ion-no-padding ${!disable ? `` : `range-knob-hide`} ${
          value <= 3 ? `poor` : value <= 7 ? `average` : `good`
        } `}
        min={min}
        slot={slot}
        max={max}
        value={value}
        pin={false}
        disabled={disable}
        debounce={100}
        onIonChange={(e) => hangleChange(e.detail.value)}
      ></IonRange>
    </div>
  );
};
