import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import { add, pencilOutline } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { useParams, withRouter } from "react-router";
import { SectionHeader, CompanyEmailVerify } from "../../../component";
import { AuthContext } from "../../../context/authContext";
import { CompanyJob, GetProjectJobs } from "../../../model/JobModel";
import { getCookie, getMethod } from "../../../service";

interface Props extends RouteComponentProps<{}> {}

const Project: React.FC<Props> = ({ history, match }) => {
  const [showToast, setShowToast] = useState(false);
  const { auth, checkLoginUsername } = React.useContext(AuthContext);
  const userdata = auth.data;
  // const userdata = getCookie({ key: "user" }).value;
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const location = useParams();
  const [showAddProjectModal, setShowAddProjectModal] =
    useState<boolean>(false);
  const [showEditProjectModal, setShowEditProjectModal] =
    useState<boolean>(false);

  const [showAddJobModal, setShowAddJobModal] = useState<boolean>(false);
  const [showEditJobModal, setShowEditJobModal] = useState<boolean>(false);

  const [projectJobData, setProjectJobData] =
    useState<GetProjectJobs.IProjectJobData>();

  const [editProjectData, setEditProjectData] =
    useState<CompanyJob.IProjectModel>();

  const [editJobData, setEditJobData] = useState<CompanyJob.ISaveCompanyJob>();

  function hideProjectModal() {
    setShowAddProjectModal(false);
    setShowEditProjectModal(false);
    getDataFromAPI();
  }

  function hideJobModal() {
    setShowAddJobModal(false);
    setShowEditJobModal(false);
    getDataFromAPI();
  }

  function editProject(project: GetProjectJobs.IProject) {
    setEditProjectData({
      com_id: userdata.com_id,
      title: project.title,
      description: project.description,
      com_pid: project.com_pid,
      status: project.status,
    });

    setShowEditProjectModal(true);
  }

  function editJob(job: GetProjectJobs.IJob) {
    const getJobData = getMethod("Getcompanyjob/" + job.com_pjid);
    getJobData.then((value: any) => {
      const editJob: CompanyJob.ISaveCompanyJob =
        value.data as CompanyJob.ISaveCompanyJob;
      editJob.com_pjid = job.com_pjid;
      setEditJobData(editJob);
      setShowEditJobModal(true);
    });
  }

  function getDataFromAPI() {
    setShowLoading(true);
    const id =
      location["companyID"] !== undefined
        ? location["companyID"]
        : userdata.com_id;

    const getData = getMethod("Getprojectjobs/" + id);
    getData.then((value: any) => {
      if (value && value.status === 200) {
        setShowLoading(false);
        const tempProjectJobData: GetProjectJobs.IProjectJobData =
          value.data as GetProjectJobs.IProjectJobData;

        setProjectJobData(tempProjectJobData);
      } else {
        setShowLoading(false);
      }
    });
  }

  useEffect(() => {
    getDataFromAPI();
  }, []);

  function jobdetailspage(unique_id) {
    if (unique_id) {
      window.open("/job-details/" + unique_id, "_blank");
    }
  }
  function viewapplicance(unique_id) {
    if (unique_id) {
      history.push("/ViewApplicants/" + unique_id);
    }
  }

  function searchjob(com_pjid) {
    const getJobData = getMethod("Getcompanyjob/" + com_pjid);
    getJobData.then((value: any) => {
      const skills: Array<any> = [];
      value.data.mandatory_skills.map((ele) => {
        skills.push(ele.skill_name);
      });

      sessionStorage.setItem(
        "mandatoryskill",
        JSON.stringify(value.data.mandatory_skills)
      );

      sessionStorage.setItem("skillids", JSON.stringify(skills));

      history.push({
        pathname: "/company-viewcontractor",
        state: { from: "viewprojects" },
      });
    });
  }

  return (
    <>
      {userdata && userdata.user_type === "Company" && <CompanyEmailVerify />}
      <IonGrid className="text-center">
        {userdata && userdata.user_type === "Company" && (
          <IonRow>
            <IonCol offsetLg="2" sizeLg="8" sizeXs="12">
              <SectionHeader title="Projects and Jobs" />
            </IonCol>
          </IonRow>
        )}
        <IonRow>
          <IonCol>
            <h3>Add your projects in Jobs 'n' Gigs</h3>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol offsetLg="3" sizeLg="6" sizeXs="12">
            <IonGrid className="content-box">
              <IonRow>
                <IonCol size="12">
                  <h4>Projects</h4>
                  <IonGrid className="text-start">
                    {projectJobData &&
                      projectJobData?.projects?.length > 0 &&
                      projectJobData.projects.map((project) => (
                        <IonRow>
                          <IonCol
                            sizeLg="8"
                            sizeXs="12"
                            className="txt-center-mobile"
                          >
                            <h5>{project.title}</h5>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: project.description,
                              }}
                            ></div>
                          </IonCol>
                          <IonCol
                            sizeLg="2"
                            sizeXs="12"
                            className="txt-center-mobile"
                          >
                            <IonButton
                              className="custom-button centered"
                              color="secondary"
                              shape="round"
                              fill="outline"
                              onClick={() => {
                                setEditProjectData({
                                  com_id: "",
                                  title: "",
                                  description: "",
                                  com_pid: "",
                                  status: "",
                                });
                                editProject(project);
                              }}
                            >
                              <IonIcon icon={pencilOutline}></IonIcon>{" "}
                              &nbsp;Edit
                            </IonButton>
                          </IonCol>
                          <IonCol size="12">
                            <hr />
                          </IonCol>
                        </IonRow>
                      ))}
                  </IonGrid>
                  <IonButton
                    className="custom-button centered"
                    color="secondary"
                    shape="round"
                    fill="outline"
                    onClick={() => setShowAddProjectModal(true)}
                  >
                    <IonIcon icon={add}></IonIcon> &nbsp;Add Project
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className="content-box">
              <IonRow>
                <IonCol size="12">
                  <h4>Jobs</h4>
                  <IonGrid className="text-start">
                    {projectJobData &&
                      projectJobData?.jobs.length > 0 &&
                      projectJobData.jobs.map((job: any) => (
                        <IonRow>
                          <IonCol
                            sizeLg="9"
                            sizeXs="12"
                            className="txt-center-mobile"
                          >
                            <h5>
                              <b>Project Title:</b>{" "}
                              {
                                projectJobData?.projects?.filter(
                                  (x) => x.com_pid === job.com_pid
                                )[0]?.title
                              }
                            </h5>
                            <h5>
                              <b>Job Title:</b> {job.title}{" "}
                            </h5>
                            <p>
                              <b>Location:</b> {job.work_location}
                            </p>
                            <p>
                              <b>Start Date:</b> {job.start_date}{" "}
                              <b>FullTime:</b>{" "}
                              {job.duration_type === "1" ? "Yes" : "No"}
                            </p>
                            <div className="d-flex">
                              {job.applicants && (
                                <IonChip
                                  onClick={() => viewapplicance(job.unique_id)}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <IonLabel color="secondary">
                                    Applicants: {job.applicants}
                                  </IonLabel>
                                </IonChip>
                              )}

                              <IonChip
                                onClick={() => jobdetailspage(job.unique_id)}
                                style={{
                                  cursor: "pointer",
                                }}
                              >
                                <IonLabel color="secondary">PublicUrl</IonLabel>
                              </IonChip>
                            </div>
                          </IonCol>
                          <IonCol
                            sizeLg="2"
                            sizeXs="12"
                            className="txt-center-mobile"
                          >
                            <IonButton
                              className="custom-button centered"
                              color="secondary"
                              shape="round"
                              fill="outline"
                              onClick={() => editJob(job)}
                            >
                              <IonIcon icon={pencilOutline}></IonIcon>{" "}
                              &nbsp;Edit
                            </IonButton>

                            <IonButton
                              className="custom-button centered"
                              color="secondary"
                              shape="round"
                              fill="outline"
                              style={{ "margin-top": "1rem" }}
                              onClick={() => searchjob(job.com_pjid)}
                            >
                              Search
                            </IonButton>
                          </IonCol>
                          <IonCol size="12">
                            <hr />
                          </IonCol>
                        </IonRow>
                      ))}
                  </IonGrid>
                  <IonButton
                    className="custom-button centered"
                    color="secondary"
                    shape="round"
                    fill="outline"
                    onClick={() => setShowAddJobModal(true)}
                  >
                    <IonIcon icon={add}></IonIcon> &nbsp;Add Job
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
        duration={5000}
      />
      {/* <Modal showModal={showAddProjectModal}>
        <div className="custom-modal">
          <ManageCompanyProject setShowModal={hideProjectModal} action="ADD" />
        </div>
      </Modal>

      <Modal showModal={showEditProjectModal}>
        <div className="custom-modal">
          <ManageCompanyProject
            setShowModal={hideProjectModal}
            model={editProjectData}
            action="UPDATE"
          />
        </div>
      </Modal>
      <Modal showModal={showAddJobModal}>
        <div className="custom-modal">
          <ManageCompanyJob setShowModal={hideJobModal} action="ADD" />
        </div>
      </Modal> */}

      {/* <Modal showModal={showEditJobModal}>
        <div className="custom-modal">
          <ManageCompanyJob
            setShowModal={hideJobModal}
            model={editJobData}
            action="UPDATE"
          />
        </div>
      </Modal> */}
    </>
  );
};
export default withRouter(Project);
