export const Requests = {
  // auth apis
  fetchLogin: "Login",
  fetchregistation: "Register",
  fetchForgotPwd: "Users/forgot_password",
  fetchresetPwd: "Users/resetpassword",
  fetchChangePwd: "Changepassword",
  fetchUsername: "Changeusername",

  // contractor apis
  fetchGetcontractor: "Getcontractor/",
  fetchGetcontractorprofile: "Personalinfo/getpersonalprofileinfo/",
  fetchPostcontractorprofile: "Saveimage",
  fetchSaveorganizations: "Saveorganizations",
  fetchUpdateorganizations: "Updateorganizations",
  fetchResumeworksampleget: "Resumeworksample/get_employment_type/",
  fetchResumeworksampledelete: "/Resumeworksample/delete_organization/",
  fetchUpdatereference: "Updatereference",
  fetchSavereference: "Savereference",
  fetchBackgroundreferencedata: "Backgroundreferencedata",
  fetchReference: "Reference/",
  fetchSavereferenceorg: "Savereferenceorg",
  fetchResumeworksampledeletereference: "Resumeworksample/delete_reference/",
  fetchSaveresumeworksample: "Saveresumeworksample",
  fetchgetworkweblinks: "Resumeworksample/get_workweblinks/",
  fetchLocationpreference: "Locationpreference",
  fetchGetlocationpreference: "Getlocationpreference/",
  fetchGetworkpermitinfo: "Getworkpermitinfo/",
  fetchSaveworkpermitinfo: "Saveworkpermitinfo",
  fetchGetengagementdetails: "Getengagementdetails/",
  fetchUpdateengagementdetails: "Update_engagementdetails",
  fetchSavectrskill: "Savectrskill",
  fetchSavehighlightedskill: "Savehighlightedskill",
  fetchPersonalinfogetcontractorskills: "/Personalinfo/get_contractor_skills/",
  fetchSavectrtechnogy: "Savectrtechnogy",
  fetchPersonalinfogetcontractortools: "Personalinfo/get_contractor_tools/",
  fetchSaveaboutinfo: "Saveaboutinfo",
  fetchGetAboutinfo: "GetAboutinfo/",
  fetchGetcountry: "Getcountry",
  fetchStates: "States",
  fetchCities: "Cities",
  fetchSaveNameLocation: "SaveNameLocation",
  fetchSavecertification: "Savecertification",
  fetchUpdatecertification: "Updatecertification",
  fetchSaveeducation: "Saveeducation",
  fetchUpdateeducation: "Updateeducation",
  fetchCertification: "Certification",
  fetchPersonalinfodeletectrskill: "Personalinfo/delete_ctr_skill/",
  fetchDeletehighlightedskill: "Deletehighlightedskill/",
  fetchPersonalinfodeletectrtool: "Personalinfo/delete_ctr_tool/",
  fetchSaveproject: "Saveproject",
  fetchUpdateproject: "Updateproject",
  fetchdeletecertification: "Resumeworksample/delete_certification/",
  fetchdeleteeducation: "Resumeworksample/delete_education/",
  fetchdeleteproject: "/Resumeworksample/delete_project/",
  fetchgetcontractorlanguage: "/Personalinfo/get_contractor_language/",
  fetchgetskillstoolslangs: "Users/get_skills_tools_langs",
  fetchSavectrlang: "Savectrlang",
  fetchdeletectrlang: "/Personalinfo/delete_ctr_lang/",
  fetchSavequickprofile: "Savequickprofile",
  fetchQuickprofile: "Quickprofile",
  fetchPersonalinfooptin: "Personalinfo/opt_in/",
  fetchDeikaarewards: "Deikaa_rewards/",
  fetchReferredtofriends: "Referredtofriends",
  fetchGetContractorJobtypes: "GetContractorJobtypes/",
  fetchGetContractorFavJobtype: "GetContractorFavJobtype/",
  fetchGetjobs: "Getjobs",
  fetchGetalljobs: "Getalljobs",
  fetchPublicjobpage: "Publicjobpage/",
  fetchAddfavouritejob: "Addfavouritejob",
  fetchApplyjob: "Applyjob",
  fetchUpdateinvitationrequest: "Updateinvitationrequest",
  fetchJobstatushistoriy: "Jobstatushistoriy",
  fetchSaveCtrPractice: "SaveCtrPractice",
  fetchGetPractice: "GetPractice/",
  fetchcontractorGetPractice: "GetPracticeall",
  fetchUpdatePractices: "UpdatePractices",
  fetchGetCtrLogData: "GetCtrLogData",
  fetchGetComLogData: "getCompanyLoglist",
  fetchGetCtrRating: "GetCtrRating/",
  fetchSaveCtrRating: "SaveCtrRating",
  fetchGetCtrDataFromPublicUrl: "GetCtrDataFromPublicUrl",

  // company
  fetchSavecompanyprofile: "Savecompanyprofile",
  fetchSavecompanyimage: "SaveCompanyImages",
  fetchUpdatecompanyjob: "Updatecompanyjob",
  fetchSavecompanyjob: "Savecompanyjob",
  fetchGetlistofjobs: "Getlistofjobs/",
  fetchGetjobdataofcompany: "Getjobdataofcompany/",
  fetchGetapplicantsdata: "Getapplicantsdata/",
  fetchGetmatchecandidate: "Getmatchecandidate",
  fetchGetcrmmatchecandidate: "GetmatchecandidateCrm",
  fetchGetinvitecandidate: "Getinvitecandidate/",
  fetchGetshortlistedcandidate: "Getshortlistedcandidate/",
  fetchaddshortlistedcandidate: "Project_jobs/add_shortlisted_candidate",
  fetchgetfilteredjob: "Project_jobs/get_filtered_job",
  fetchinviteforjob: "Project_jobs/invite_for_job",
  fetchUpdateapplicantstatus: "Updateapplicantstatus",
  fetchdeletecandidate: 'RemoveShortlisitedCandidate',
  fetchInvitetoapplyjob: "Invitetoapplyjob",
  fetchGetchat: "Getchat/",
  fetchSavechat: "Savechat",
  fetchGetcompanyprofile: "Getcompanyprofile/",

  // TRM
  fetchGetcompanylist: "Getcompanylist",
  fetchCreatecontractor: "Createcontractor",
  fetchDeikaasaveadditionalctrdata: "Deikaa/save_additional_ctr_data",
  fetchUpdatetokens: "Updatetokens",
  fetchGetcontractorlist: "Getcontractorlist",
  fetchGetallpendingtokens: "Getallpendingtokens",
  fetchGetapprovedtokendata: "Getapprovedtokendata",
  fetchGetrejectedtokendata: "Getrejectedtokendata",
  fetchGetTrmdata: "GetTrmdata",
  fetchGetctradditionaldata: "Getctradditionaldata/",
  fetchSearchDeikaaUser: "SearchDeikaaUser",
  fetchGetAllDeikaaActivityList: "GetAllDeikaaActivityList",
  fetchAddtrmRemarks: "AddtrmRemarks",
  fetchGetgroup: "Getgroup",
  fetchCtrProfileCompletionflag: "CtrProfileCompletion_flag",
  fetchGetjobsleads: "Getjobsleads",
  fetchGetjobsleadsDetails: "GetjobsleadsDetails/",
  fetchGetInterviewFeedback: "GetInterviewFeedback/",
  fetchUpdateInterviewFeedback: "UpdateInterviewFeedback",
  fetchSaveInterviewFeedback: "SaveInterviewFeedback",
  fetchGetTrmtouchPoint: "GetTrmtouchPoint/",
  fetchUpdateTrmTouchPoint: "UpdateTrmTouchPoint",
  fetchSaveTrmTouchPoint: "SaveTrmTouchPoint",
  // staff

  fetchSendMailtoallContractor: "SendMailtoallContractor",
  fetchJngstaffgetCompanies: "/Jngstaff/getCompanies",
  fetchGetCompanycontract: "GetCompanycontract/",
  fetchContractuploaddeletecontract: "Contractupload/delete_contract/",
  fetchGetalljnglegaluser: "Getalljnglegaluser",
  fetchaddcompany: "/Jngstaff/add_company",
  fetchAddjnglegalrole: "Addjnglegalrole",
  fetchUpdatejnglegalrole: "Updatejnglegalrole",
  fetchRemoveContractor: "Jngstaff/remove_contractor/",
  // export file apis
  fetchprofilefile: "Users/profileFiles",
  fetchEmailNotificationType: "setNotificationPreferences",

  // crm apis
  fetchAddJngCrmrole: "AddJngCrmrole",
  fetchUpdateJngCrmrole: "UpdateJngCrmrole",
  fetchDeleteJngCrmrole: "DeleteJngCrmrole/",
  fetchGetallJngCrmuser: "GetallJngCrmuser",
  fetchGetCrmJobList: "GetCrmJobList/",
  fetchDeleteCrmJob: "DeleteCrmJob/",
  fetchUpdateCrmJob: "UpdateCrmJob",
  fetchSaveCrmJob: "SaveCrmJob",

  fetchGetallRecruiteruser: "GetallRecruiteruser",
  fetchAddJngRecruiterrole: "AddRecruiterrole",
  fetchUpdateJngRecruiterrole: "UpdateRecruiterrole",
  fetchDeleteJngRecruiterrole: "DeleteRecruiterrole/",

  fetchAssignShortlistedCandidate: "AssignShortlistedCandidate",
  fetchAssignMailForShortlisit: 'MailForShortlisit',
  fetchSendMailForShortlist: 'SendMailForShortlist',
  fetchCompanyListForShortlisting: 'CompanyListForShortlisting/',

  // public api
  fetchPublicjoblist: "Getallhirifyjobs",
  fetchpubliccanditatelist: "GetAllHirifyCtr",
  fetchpublicGetAllResourceDetails: "GetAllResourceDetails",
};