import React from "react";
import { IonItem } from "@ionic/react";
import { CommonSection } from ".";
type props = {
  languageData: any;
};

const Languages: React.FC<props> = ({ languageData }) => {
  return (
    <div id="language">
      <IonItem lines="none" className="custom-header-space ">
        <h4>Spoken Language</h4>
      </IonItem>
      <CommonSection arrs={languageData} type={"languages"} />
    </div>
  );
};
export default React.memo(Languages);
