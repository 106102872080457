import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { addOutline, pencilSharp } from "ionicons/icons";
import React, { useState } from "react";
import { DateStringMap, SeverfilePaths } from "../../../../../service";
import { ModalBody, ReadMoreRichText } from "../../../../../component";
import Careerform from "../../Form/CareerForm";
import ProjectForm from "../../Form/ProjectForm";

type props = {
  personalData: any;
  ctrId: string;
  folderKey: string;
};

const Career: React.FC<props> = ({ personalData, ctrId, folderKey }: any) => {
  return (
    <div id="career">
      {personalData && (
        <IonCard className="pad-style">
          <ExperienceSection
            orgData={personalData}
            ctrid={ctrId}
            folderkeyData={folderKey}
          />
        </IonCard>
      )}
    </div>
  );
};

export default React.memo(Career);

// experience Section
interface experienceField {
  ctr_id: string;
  ctr_ehid: any;
  emt_id: string;
  company_name: string;
  contractor_role: string;
  geo_location: string;
  latitude: string;
  longitude: string;
  currently_working: any;
  start_date: any;
  end_date: any;
  remote_location: any;
  description: string;
}

const experiencefields: experienceField = {
  ctr_id: "",
  ctr_ehid: "",
  emt_id: "",
  company_name: "",
  contractor_role: "",
  geo_location: "",
  latitude: "",
  longitude: "",
  currently_working: "0",
  start_date: "",
  end_date: "",
  remote_location: "0",
  description: "",
};

const ExperienceSection = ({ orgData, folderkeyData, ctrid }: any) => {
  const [expSlice, setexpslice] = React.useState(3);
  const [modelType, setModalType] = React.useState("Add");
  const [modal, setModal] = React.useState(false);
  const [fieldData, setFieldData] =
    React.useState<experienceField>(experiencefields);
  const [experienceData, setExperience] = React.useState([]);
  const [projectData, setProject] = React.useState([]);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [empData, setEmp] = useState<any>([]);
  // experience
  React.useEffect(() => {
    if (!dataupdated) {
      setExperience(orgData.organization);
      setProject(orgData.projects);
      setEmp(orgData.employment);
      setDataupdated(true);
    }
  }, []);

  // map organization based on project
  function filterArray(value, arr, key) {
    const pushData: any[] = [];
    if (arr !== undefined) {
      arr.map((e: any) => {
        if (e[key] === value) {
          pushData.push(e);
        }
      });
      return pushData;
    }
  }

  // after add/update import to state
  function resetData(data: any) {
    setDataupdated(false);
    if (data) {
      setExperience(data);
      setDataupdated(true);
    }
  }
  // project stateupdate
  function resetProjectData(data: any) {
    setDataupdated(false);
    if (data) {
      setProject(data);
      setDataupdated(true);
    }
  }
  // project delete state change

  function deleteProject(data: any) {
    setDataupdated(false);
    if (data) {
      setProject(projectData.filter(({ ctr_pro_id }) => ctr_pro_id !== data));
      setDataupdated(true);
    }
  }

  // experience delete in state change

  function deleteExperience(data: any) {
    setDataupdated(false);
    if (data) {
      setExperience(experienceData.filter(({ ctr_ehid }) => ctr_ehid !== data));
      setDataupdated(true);
    }
  }

  // edit field
  function editDatafield(data: any, type) {
    setFieldData({
      ctr_ehid: data.ctr_ehid ?? "",
      emt_id: data.emt_id,
      company_name: data.company_name,
      contractor_role: data.contractor_role,
      currently_working: data.currently_working,
      start_date: data.start_date,
      end_date: data.end_date,
      remote_location: data.remote_location,
      description: data.description,
      geo_location: data.geo_location ?? "",
      longitude: "",
      latitude: "",
    } as any);
    setModalType(type);
    setModal(true);
  }

  return (
    <>
      <ModalBody
        title={modelType + " experience"}
        isOpen={modal}
        onClose={() => setModal(false)}
      >
        <Careerform
          empdata={empData}
          dataset={modal}
          ctrId={ctrid}
          initialValueData={fieldData}
          closeModal={() => setModal(false)}
          setorganizationData={resetData}
          deleteExperienceData={deleteExperience}
          eventType={modelType}
        />
      </ModalBody>
      <IonItem lines="none" className="custom-header-space ">
        <h4>
          {experienceData.length} Experience{experienceData.length > 1 && "s"}
        </h4>
        <IonButton
          slot="end"
          fill="outline"
          className="remove-shadow custom-circle-btn"
          color="secondary"
          shape="round"
          onClick={() => {
            editDatafield(
              {
                ...experiencefields,
                currently_working:
                  experiencefields.currently_working !== "1" ? false : true,
                remote_location:
                  experiencefields.remote_location !== "1" ? false : true,
              },
              "Add"
            );
          }}
        >
          <IonIcon icon={addOutline} slot="icon-only" />
        </IonButton>
      </IonItem>
      {dataupdated && (
        <IonGrid className="ion-no-padding">
          {experienceData.length > 0 &&
            experienceData
              .sort(
                (a: any, b: any) => b.currently_working - a.currently_working
              )
              .slice(0, expSlice)
              .map((item: any, index: number) => (
                <div key={index}>
                  <IonRow>
                    <IonCol sizeLg="4" sizeXs="12">
                      <IonItem
                        lines="none"
                        className="content-gray custom-label"
                      >
                        {DateStringMap(item.start_date) + " - "}
                        {item.currently_working !== "0" ? (
                          <span className="highlightContent"> present</span>
                        ) : (
                          DateStringMap(item.end_date)
                        )}
                      </IonItem>
                    </IonCol>

                    <IonCol sizeLg="8" sizeXs="12">
                      <IonItem lines="none" className="custom-subheader-space ">
                        <IonLabel>
                          <h6>
                            <b>{item.company_name}</b>
                          </h6>
                          <p>{item.geo_location}</p>
                          <p>{item.contractor_role}</p>
                        </IonLabel>

                        <IonButton
                          slot="end"
                          fill="outline"
                          className="remove-shadow custom-circle-btn"
                          color="medium"
                          shape="round"
                          onClick={() => {
                            editDatafield(
                              {
                                ...item,
                                currently_working:
                                  item.currently_working !== "1" ? false : true,
                                remote_location:
                                  item.remote_location !== "1" ? false : true,
                              },
                              "Update"
                            );
                          }}
                        >
                          <IonIcon icon={pencilSharp} slot="icon-only" />
                        </IonButton>
                      </IonItem>

                      <p>
                        <ReadMoreRichText length={150}>
                          {item.description}
                        </ReadMoreRichText>
                      </p>
                      <hr />

                      <ProjectSection
                        ctrId={ctrid}
                        orgId={item.ctr_ehid}
                        folterKey={folderkeyData}
                        organizationData={experienceData}
                        proData={filterArray(
                          item.ctr_ehid,
                          projectData,
                          "ctr_ehid"
                        )}
                        setProjectData={resetProjectData}
                        setProjectdeleteData={deleteProject}
                      />
                    </IonCol>
                  </IonRow>

                  {index !== experienceData.slice(0, expSlice).length - 1 && (
                    <hr />
                  )}
                </div>
              ))}
          {experienceData.length > 3 && <hr />}
          {experienceData.length > 3 && (
            <IonItem lines="none">
              {experienceData.length > 0 &&
              experienceData.length !== expSlice &&
              experienceData.length >= expSlice ? (
                <IonButton
                  slot="end"
                  fill="clear"
                  color="secondary"
                  type="button"
                  size="small"
                  className="remove-shadow lowercase"
                  onClick={() => setexpslice(expSlice + 3)}
                >
                  {experienceData.length - expSlice} more experience
                  {experienceData.length > 3 && "(s)"}
                </IonButton>
              ) : (
                experienceData.length > 3 &&
                experienceData.length <= expSlice && (
                  <IonButton
                    slot="end"
                    fill="clear"
                    color="secondary"
                    type="button"
                    size="small"
                    className="remove-shadow lowercase"
                    onClick={() => setexpslice(3)}
                  >
                    less experience{experienceData.length > 3 && "(s)"}
                  </IonButton>
                )
              )}
            </IonItem>
          )}
        </IonGrid>
      )}
    </>
  );
};

// Project Section

interface projectfields {
  ctr_id: string;
  ctr_pro_id: string;
  project_name: string;
  ctr_ehid: string;
  current_project: string;
  start_date: any;
  end_date: any;
  project_url: any;
  description: string;
  project_docs: string;
  role: string;
}
const projectInitialValues: projectfields = {
  ctr_id: "",
  ctr_pro_id: "",
  project_name: "",
  ctr_ehid: "",
  current_project: "",
  start_date: "",
  end_date: "",
  project_url: [],
  description: "",
  project_docs: "",
  role: "",
};

const ProjectSection = ({
  proData,
  organizationData,
  ctrId,
  orgId,
  folterKey,
  setProjectData,
  setProjectdeleteData,
}: any) => {
  const [proSlice, setproslice] = React.useState(1);
  const [modelType, setModalType] = React.useState("Add");
  const [modal, setModal] = React.useState(false);
  const [projectData, setProject] = React.useState(proData);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [editData, setEditData] =
    React.useState<projectfields>(projectInitialValues);
  // project update to organization
  function updateState(value: any) {
    if (value) {
      setModal(false);
      setProjectData(value);
    }
  }

  function setDeleteProject(data: any) {
    if (data) {
      setModal(false);
      setProjectdeleteData(data);
    }
  }

  React.useEffect(() => {
    if (!dataupdated) {
      setProject(proData);
      setDataupdated(true);
    }
  }, []);

  function editProject(data: any, type: string) {
    setEditData({
      ...data,
      description: data.description,
      start_date: data.start_date === "0000-00-00" ? "" : data.start_date,
      end_date: data.end_date === "0000-00-00" ? "" : data.end_date,
      current_project: data.current_project === "1" ? true : false,
      project_url: data.link! === "" && data.link,
    });
    setModalType(type);
    setModal(true);
  }

  return (
    <>
      <ModalBody
        title={modelType + " Project"}
        isOpen={modal}
        onClose={(e) => setModal(false)}
      >
        <ProjectForm
          initialValueData={editData}
          setvalues={modal}
          orgIds={orgId}
          organizationList={organizationData}
          eventType={modelType}
          resetData={updateState}
          deleteProjectData={setDeleteProject}
          folderkeys={folterKey}
          ctrid={ctrId}
          closeModal={(e) => setModal(false)}
        />
      </ModalBody>
      <IonItem lines="none" className="custom-header-space ">
        <IonLabel>
          {projectData && projectData.length} Project
          {projectData.length >= 2 && "s"}
        </IonLabel>
        <IonButton
          slot="end"
          fill="outline"
          className="remove-shadow custom-circle-btn"
          color="secondary"
          shape="round"
          onClick={() => {
            editProject(projectInitialValues, "Add");
          }}
        >
          <IonIcon icon={addOutline} slot="icon-only" />
        </IonButton>
      </IonItem>
      {dataupdated && (
        <IonGrid className="ion-no-padding">
          {projectData.length > 0 &&
            projectData
              .sort((a: any, b: any) => b.current_project - a.current_project)
              .slice(0, proSlice)
              .map((item: any, index: number) => (
                <div key={index}>
                  <IonRow>
                    <IonCol sizeLg="12" sizeXs="12">
                      <IonItem lines="none" className="custom-subheader-space ">
                        <IonLabel style={{ fontWeight: "bold" }}>
                          <h6>
                            <b>{item.project_name}</b>{" "}
                            <span>{" • " + item.role}</span>
                          </h6>
                          <h6></h6>
                          <p className="content-gray custom-label">
                            {DateStringMap(item.start_date) + " - "}
                            {item.current_project !== "0" && (
                              <span className="highlightContent">present</span>
                            )}
                            {item.current_project !== "1" &&
                              DateStringMap(item.end_date)}
                          </p>
                          <p>
                            Doc:-{" "}
                            <a
                              href={
                                SeverfilePaths(folterKey) + item.project_docs
                              }
                              target="_blank"
                            >
                              {item.project_docs}
                            </a>
                          </p>
                        </IonLabel>
                        <IonButton
                          fill="outline"
                          className="remove-shadow custom-circle-btn"
                          color="medium"
                          shape="round"
                          onClick={() => {
                            editProject(item, "Update");
                          }}
                        >
                          <IonIcon icon={pencilSharp} slot="icon-only" />
                        </IonButton>
                      </IonItem>
                      <ReadMoreRichText length={250}>
                        {item.description}
                      </ReadMoreRichText>
                      {/* <ReadMore>{StringMap(item.description)}</ReadMore> */}
                    </IonCol>
                  </IonRow>
                  {index !== projectData.slice(0, proSlice).length - 1 && (
                    <hr />
                  )}
                </div>
              ))}

          {projectData.length > 1 && projectData.length !== proSlice && (
            <IonItem lines="none">
              {projectData.length >= proSlice ? (
                <IonButton
                  fill="clear"
                  slot="end"
                  color="secondary"
                  type="button"
                  size="small"
                  className="remove-shadow lowercase"
                  onClick={() => setproslice(proSlice + 3)}
                >
                  {projectData.length - proSlice} more project
                  {projectData.length > 1 && "(s)"}
                </IonButton>
              ) : (
                projectData.length > 1 &&
                projectData.length <= proSlice && (
                  <IonButton
                    fill="clear"
                    color="secondary"
                    type="button"
                    size="small"
                    className="remove-shadow lowercase"
                    onClick={() => setproslice(1)}
                  >
                    less project{projectData.length > 1 && "(s)"}
                  </IonButton>
                )
              )}
            </IonItem>
          )}
        </IonGrid>
      )}
    </>
  );
};
