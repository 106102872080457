import {
  IonAlert,
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonRow,
  IonText,
  IonToast,
  IonToggle,
} from "@ionic/react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import React, { useRef, useState } from "react";
import { apiCallBack, fileUpload } from "../../../../service";
import {
  Deletecertification,
  savecertification,
  updatecertification,
} from "../../Service/api";
import { CardProfileImage, DatePick } from "../../../../component";

function CertificationForm({
  initialValueData,
  setcertificationData,
  ctrid,
  closeModal,
  eventType,
  folderKey,
  deletecertificationData,
  whenCall,
}: any) {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const [uploading, setUploading] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [data, setData] = React.useState("");

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setshowLoading] = React.useState(false);

  // file
  let dragCounter = useRef(0);
  let [dragOverlay, setDragOverlay] = React.useState(false);
  const [error, setError] = React.useState<any>();
  const [showAlert, setShowAlert] = React.useState(false);
  const [showConfirm, setshowConfirm] = React.useState<any>();

  // delete project
  const [showAlert1, setShowAlert1] = React.useState(false);

  // error message
  const showError = (_fieldName: string) => {
    let error = (errors as any)[_fieldName];
    return error ? (
      <div className="errorMessage text-left">
        {error.message || _fieldName + " field is required"}
      </div>
    ) : null;
  };

  // show count
  const showValueCount = (_fieldName: any) => {
    const data = watch(_fieldName);
    return data ? data.length : "0";
  };
  React.useEffect(() => {
    setValue("credential_id", initialValueData.credential_id);
    setValue("certifications_docs", initialValueData.certifications_docs);
    setValue("doc_link", initialValueData.doc_link);
    setValue("name", initialValueData.name);
    setValue(
      "issue_start_date",
      initialValueData.issue_start_date
        ? new Date(initialValueData.issue_start_date)
        : ""
    );

    setValue(
      "issue_end_date",
      initialValueData.issue_end_date && initialValueData.not_expire !== "1"
        ? new Date(initialValueData.issue_end_date)
        : ""
    );
    setValue("issue_organization", initialValueData.issue_organization);
    setValue("result", initialValueData.result);
    setValue("result_type", initialValueData.result_type);
    setValue("not_expire", initialValueData.not_expire === "1" ? true : false);
    setData(initialValueData.credential_url ?? "");
    setShow(initialValueData.doc_link ? true : false);
  }, [whenCall]);

  // save certification
  const saveCertification = (values: any) => {
    setshowLoading(true);
    values.ctr_id = ctrid;
    values.not_expire = values.not_expire === true ? 1 : 0;
    values.issue_start_date = values.issue_start_date
      ? values.issue_start_date
      : "";
    values.issue_end_date = values.issue_end_date ? values.issue_end_date : "";
    values.doc_link = values.doc_link ?? "";
    values.credential_url = data;
    if (eventType === "Add") {
      savecertification(values).then((e) => {
        if (apiCallBack(e)) {
          setcertificationData(e.data);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
          setshowLoading(false);
          closeModal(false);
        } else if (e.status === 201) {
          setshowLoading(false);
          const errorInfo = e.message;
          if (typeof errorInfo === "string") {
            setToastColor("danger");
            setToastMessage(e.message);
            setShowToast(true);
          } else {
            setToastColor("danger");
            for (const [key, value] of Object.entries(errorInfo)) {
              setToastMessage(`${value}`);
              setShowToast(true);
            }
            setshowLoading(false);
          }
        } else {
          setshowLoading(false);
          setToastColor("danger");
          setToastMessage(e.message);
          setShowToast(false);
        }
      });
    } else {
      values.ctr_cid = initialValueData.ctr_cid;
      updatecertification(values).then((e) => {
        if (apiCallBack(e)) {
          setcertificationData(e.data);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
          setshowLoading(false);
          closeModal(false);
        } else if (e.status === 201) {
          setshowLoading(false);
          const errorInfo = e.message;
          if (typeof errorInfo === "string") {
            setToastColor("danger");
            setToastMessage(e.message);
            setShowToast(true);
          } else {
            setToastColor("danger");
            for (const [key, value] of Object.entries(errorInfo)) {
              setToastMessage(`${value}`);
              setShowToast(true);
            }
            setshowLoading(false);
          }
        } else {
          setshowLoading(false);
          setToastColor("danger");
          setToastMessage(e.message);
          setShowToast(false);
        }
        setUploading(false);
      });
    }
  };

  // delete certification
  const deletecertification = () => {
    setshowLoading(true);
    let data = initialValueData.ctr_cid;
    if (data) {
      setshowLoading(true);
      Deletecertification(data).then((e: any) => {
        if (e !== undefined && e.status === 200) {
          deletecertificationData(data);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
          setshowLoading(false);
          closeModal(false);
        } else {
          setshowLoading(false);
          setToastColor("danger");
          setToastMessage(e.message);
          setShowToast(true);
        }
      });
    }
  };

  // file upload

  const preventBrowserDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrag = (e) => {
    preventBrowserDefaults(e);
  };
  const handleDragIn = (e) => {
    preventBrowserDefaults(e);
    dragCounter.current++;
  };
  const handleDragOut = (e) => {
    preventBrowserDefaults(e);
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragOverlay(false);
    }
  };
  const handleDrop = (e) => {
    const files = e.dataTransfer.files;
    preventBrowserDefaults(e);
    setDragOverlay(false);
    setError(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
        setData("");
      }
      return false;
    }
    setError("");
    setShowAlert(true);
    setshowConfirm(files);
  };

  const handleFiles = (e) => {
    const files = e.target.files;
    preventBrowserDefaults(e);
    setDragOverlay(false);
    setError(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
        setData("");
      }
      return false;
    }
    setError("");
    setShowAlert(true);
    setshowConfirm(files);
  };
  const fileReader = (files: any) => {
    const fileData = files;
    const reader = new FileReader();

    if (showConfirm) {
      setshowLoading(true);
      fileUpload(fileData[0], folderKey).then((e) => {
        if (apiCallBack(e)) {
          setData(e.filename);
          setshowLoading(false);
          // setValue("certifications_docs", e.filename);
          setToastColor("success");
          setToastMessage(e.message);
          setShowToast(true);
        } else {
          setData("");
          setshowLoading(false);
          setToastColor("danger");
          // setValue("certifications_docs", "");
          setToastMessage(
            e.message !== undefined ? e.message : "Please try again"
          );
          setShowToast(true);
        }
      });
    } else {
      setError("");
      setData("");
    }
  };

  return (
    <>
      <IonAlert
        isOpen={showAlert1}
        mode={"ios"}
        header={"Are you sure?"}
        subHeader={"Continue to delete the Certificate."}
        message={"Confirm here"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert1(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert1(false);
              deletecertification();
            },
          },
        ]}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setshowLoading(false)}
      />
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={""}
        subHeader={"Continue uploading this file?"}
        message={"Confirm here"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
              setshowConfirm("");
              setError("");
              setData("");
            },
          },
          {
            text: "Okay",
            handler: () => {
              setShowAlert(false);
              fileReader(showConfirm);
            },
          },
        ]}
      />
      <IonCard className="ion-padding custom-modal-alignment">
        <IonGrid>
          <form onSubmit={handleSubmit(saveCertification)}>
            <IonRow>
              <IonCol sizeLg="12" sizeXs="12">
                <div className="custom-file">
                  <div className="upload-img">
                  <CardProfileImage
                    folder_key=""
                    profile_photo="assets/image/file_upload.svg"
                    name="drop"
                  />
                  </div>
                  <div
                    id="drop-area"
                    className="custom-upload"
                    onDragEnter={handleDragIn}
                    onDragLeave={handleDragOut}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  >
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="fileUpload"
                          accept="image/jpeg,image/jpg,image/png,application/pdf,"
                          onChange={(e) => {
                            handleFiles(e);
                          }}
                        />
                      )}
                      control={control}
                      name="project_docs"
                    />

                    <label className="custom-upload-label" htmlFor="fileUpload">
                      <div className="custom-upload-text">
                        <div>                         
                          <h6>UPLOAD CERTIFICATE</h6>
                          <p>
                            <IonText color="danger">{error && error}</IonText>
                            <IonText color="success">{data && data}</IonText>
                          </p>
                          <h4>
                            Drop files here or{" "}
                            <span className="custom-text">
                              browse your device
                            </span>
                          </h4>
                          <p>Support JPG/PNG/PDF upto 5 MB file</p>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <hr />
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Certification name <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="name"
                    rules={{
                      required: "Project name is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder="CCNA, MTA, etc."
                      />
                    )}
                  />
                
                </IonItem>
                {showError("name")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Issuer <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    control={control}
                    name="issue_organization"
                    rules={{
                      required: "Issuer is required",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        minlength={0}
                        maxlength={64}
                        placeholder=""
                      />
                    )}
                  />
                 
                </IonItem>
                {showError("issue_organization")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Certificate ID (for verification){" "}
                    {/* <span className="required">*</span> */}
                  </IonLabel>
                  <Controller
                    control={control}
                    name="credential_id"
                    // rules={{
                    //   required: "Certificate id is required",
                    // }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        placeholder=""
                      />
                    )}
                  />
                </IonItem>
                {showError("credential_id")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <IonItem lines="none">
                  <IonLabel position="stacked">
                    Result
                    {/* <span className="required">*</span> */}
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonInput
                        autocomplete="off"
                        onBlur={onBlur}
                        onIonChange={onChange}
                        value={value}
                        placeholder="Enter the result"
                      />
                    )}
                    control={control}
                    name="result"
                    // rules={{ required: "Result is required" }}
                  />
                  <div className="custom-groupInput">
                    <Controller
                      render={({ field: { onChange, onBlur, value } }) => (
                        <select
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          placeholder="Select"
                        >
                          <option value="">Select</option>
                          <option value="marks">Marks</option>
                          <option value="grade">Grade</option>
                        </select>
                      )}
                      control={control}
                      name="result_type"
                    />
                  </div>
                </IonItem>
                {showError("result")}
              </IonCol>

              {/* <IonCol sizeLg="12" sizeXs="12">
                <IonButton
                  fill="clear"
                  color="secondary"
                  type="button"
                  size="small"
                  className="remove-shadow capitalizes"
                  onClick={() => setShow(!show)}
                >
                  Add Certificate URL
                </IonButton>
                {show && (
                  <>
                    <IonItem lines="none">
                      <Controller
                        control={control}
                        name="doc_link"
                        rules={{
                          pattern: {
                            value: ValidateUrl,
                            message: "Invalid Url",
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <IonInput
                            autocomplete="off"
                            onBlur={onBlur}
                            onIonChange={onChange}
                            value={value}
                            placeholder="Enter the certification url"
                          />
                        )}
                      />
                    </IonItem>
                    {showError("doc_link")}
                  </>
                )}
              </IonCol> */}

              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">
                    Start / Issued On <span className="required">*</span>
                  </IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick value={value} ChangeEvent={onChange} />
                    )}
                    control={control}
                    name="issue_start_date"
                    rules={{ required: "start month is required" }}
                  />
                </div>
                {showError("issue_start_date")}
              </IonCol>

              <IonCol sizeLg="6" sizeXs="12">
                <div className="input-div">
                  <IonLabel position="stacked">End</IonLabel>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <DatePick
                        value={value}
                        ChangeEvent={(e) => {
                          setValue("issue_end_date", e);
                          setValue("not_expire", false);
                        }}
                        minDate={watch("issue_start_date")}
                        yearpermit={50}
                        disabled={watch("not_expire") === false ? false : true}
                      />
                      // <input
                      //   onBlur={onBlur}
                      //   onChange={(e: any) => {
                      //     setValue("issue_end_date", e.target.value);
                      //     setValue("not_expire", false);
                      //   }}
                      //   disabled={watch("not_expire") === false ? false : true}
                      //   value={value}
                      //   type="date"
                      //   placeholder=""
                      // />
                    )}
                    control={control}
                    name="issue_end_date"
                  />
                </div>
                {/* End date is greater then start date */}
                <IonItem lines="none">
                  <label>Forever</label>
                  <Controller
                    render={({ field: { onChange, onBlur, value } }) => (
                      <IonToggle
                        checked={value}
                        value={value}
                        slot="end"
                        mode="md"
                        onBlur={onBlur}
                        onIonChange={(e) => {
                          setValue("not_expire", e.detail.checked);
                          setValue("issue_end_date", "");
                        }}
                      />
                    )}
                    control={control}
                    name="not_expire"
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeLg="12" sizeXs="12">
                <hr />
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  {eventType !== "Add" ? (
                    <IonButton
                      shape="round"
                      fill="clear"
                      color="danger"
                      className="remove-shadow capitalizes"
                      type="button"
                      onClick={() => setShowAlert1(true)}
                    >
                      Delete Certification
                    </IonButton>
                  ) : (
                    <div></div>
                  )}
                  <div>
                    {/* {eventType === "Add" && (
                      <IonButton
                        shape="round"
                        fill="outline"
                        color="secondary"
                        className="remove-shadow capitalizes"
                        type="button"
                      >
                        Save & Add Another
                      </IonButton>
                    )} */}

                    <IonButton
                      shape="round"
                      fill="solid"
                      color="secondary"
                      className="remove-shadow capitalizes"
                      type="submit"
                    >
                      {uploading ? "Uploading" : "Save"}
                    </IonButton>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
      </IonCard>
    </>
  );
}

export default React.memo(CertificationForm);

const fileValidator = (files: any, config: any) => {
  const { allowedFileFormats, fileSizeMBLimit, filesLimit } = config;
  const { length } = files;
  const { size, type } = files[0];
  let err = "";
  let result = {
    isValidFile: false,
    errVal: err,
  };
  if (length === 0) {
    return result;
  } else if (length > filesLimit) {
    err =
      filesLimit > 1
        ? `Only ${filesLimit} files are allowed to upload`
        : `Only one file is allowed to upload`;
  } else if (!allowedFileFormats.includes(type)) {
    err = "File format must be either .jpg, .png and .pdf";
  } else if (size / 1024 / 1024 > fileSizeMBLimit) {
    err = `File size exceeded the limit of ${fileSizeMBLimit}MB`;
  } else {
    result.isValidFile = true;
  }
  result.errVal = err;
  return result;
};

const config = {
  allowedFileFormats: [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ],
  fileSizeMBLimit: 5,
  filesLimit: 1,
};
