import {
  IonAlert,
  IonCard,
  IonItem,
  IonAvatar,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSkeletonText,
  IonChip,
} from "@ionic/react";
import moment from "moment";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { AuthContext } from "../../context/authContext";
import {
  StringMap,
  DateStringMap,
  SplitStringlat,
  SplitString,
} from "../../service";
import {
  Company,
  CRMRecuter,
  CRMRole,
  EXPStringMap,
  Staff,
  stringReplace,
  TRM,
} from "../../service/default";
import { CustomRangeSlider } from "../customRangeSlider/customRangeSlider";
import { CardProfileImage } from "../imageComponent/imageComponent";

interface canditateCardInterface {
  reviewflag?: true | false;
  uniqueData?: string | number;
  jobdetail;
  cardData;
  removeBtnClick?: ((string, data) => void) | any;
  shortBtnClick?: ((string, data) => void) | any;
  rejectBtnClick?: ((string, data) => void) | any;
  hireBtnClick?: ((data) => void) | any;
  inviteBtnClick?: ((data) => void) | any;
  chatOpen?: ((data) => void) | any;
  type?: string;
  chatbtn?: true | false;
  shortbtn?: true | false;
  invitebtn?: true | false;
  hirebtn?: true | false;
  rejectbtn?: true | false;
  removebtn?: true | false;
  loadimage?: true | false;
}

export const CanditateUserCard = ({
  loadimage,
  reviewflag,
  uniqueData,
  jobdetail,
  cardData,
  removeBtnClick,
  shortBtnClick,
  rejectBtnClick,
  hireBtnClick,
  inviteBtnClick,
  chatOpen,
  type,
  chatbtn,
  shortbtn,
  invitebtn,
  hirebtn,
  rejectbtn,
  removebtn,
}: canditateCardInterface) => {
  const { auth } = React.useContext(AuthContext);
  const user = auth.data;
  const usertype = user ? user.user_type : "";
  const [showAlert, setShowAlert] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [btntype, setBtntype] = React.useState<string | null>();
  const availableVerify = (ContractDate, jobStartDate) => {
    if (
      DateStringMap(ContractDate) !== "NA" &&
      DateStringMap(jobStartDate) !== "NA" &&
      new Date(ContractDate) <= new Date(jobStartDate)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const AlertDialog = (type, datas) => {
    if (type) {
      setData(datas);
      setBtntype(type);
      setShowAlert(true);
    }
  };

  const getExperienceCheck = (exp, totalExp) => {
    if (exp && totalExp) {
      const totalExper = totalExp.split(" Y");
      const overallExp = exp.split("-");
      const range =
        Number(overallExp[0]) <= Number(totalExper[0]) &&
        Number(overallExp[1]) >= Number(totalExper[0]);
      if (
        overallExp.length == 1 &&
        Number(overallExp[0]) <= Number(totalExper[0])
      ) {
        return true;
      } else if (overallExp.length == 2 && range) {
        return true;
      } else return false;
    }
    return false;
  };

  const sortbY = (value) => {
    const val: { label: string; value: number }[] = [];
    Object.keys(value).map((k) => {
      if (value[k]) {
        val.push({ label: k, value: value[k] });
      }
    });
    return val;
  };
  const switchtag = (value) => {
    const number = Number(value);
    // console.log(number);
    return number >= 1 && number <= 3
      ? "Poor"
      : number >= 4 && number <= 7
      ? "Average"
      : number >= 8 && number <= 10
      ? "Good"
      : "NA";
  };
  return (
    <div>
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={"Continue to " + btntype + " the contractor?"}
        subHeader={"Confirm here"}
        message={""}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              if (btntype === "reject") {
                rejectBtnClick("3", data);
              }  if (btntype === "delete") {
                rejectBtnClick("3", data);
              }else if (btntype === "hire") {
                hireBtnClick(data);
              }
              setShowAlert(false);
              setData(null);
              setBtntype(null);
            },
          },
        ]}
      />
      <IonCard className="ion-padding">
        {reviewflag && cardData.recommended === 1 ? (
          <div className="review-chip white-chip">
            <span>Recommended</span>
            {/* <img src="assets/image/company/star.svg" /> */}
            {/* <span>4.2/5 (10 reviews)</span> */}
          </div>
        ) : null}
        <IonItem lines="none">
          <IonAvatar slot="start">
            <CardProfileImage
              folder_key={cardData.folder_key}
              profile_photo={cardData.profile_photo}
              name={cardData.contractor_name}
            />
          </IonAvatar>
          <IonLabel
            className="ion-text-wrap"
            onClick={() => {
              sessionStorage.setItem("openNew", "1");
              window.open("/Publicview/" + cardData.public_id, "_blank");
            }}
          >
            <span>{StringMap(cardData.contractor_name)}</span>
            <p>{StringMap(cardData.headline)}</p>
            <p>{StringMap(cardData.address)}</p>
          </IonLabel>
        </IonItem>
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="6" sizeXs="12">
              <CardProfileImage
                folder_key={""}
                profile_photo={
                  availableVerify(
                    DateStringMap(cardData.contract_start_date) != "NA"
                      ? cardData.contract_start_date
                      : cardData.permanent_start_date,
                    jobdetail.start_date
                  )
                    ? "assets/image/company/check_circle.svg"
                    : "assets/image/company/report_problem.svg"
                }
                name={"Available"}
              />
              Available:{" "}
              <span>
                {cardData.contract_compensation
                  ? DateStringMap(cardData.contract_start_date) !== "NA"
                    ? moment(cardData.contract_start_date).format("ll")
                    : "NA"
                  : DateStringMap(cardData.permanent_start_date) !== "NA"
                  ? moment(cardData.permanent_start_date).format("ll")
                  : "NA"}
              </span>
            </IonCol>
            {/* <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="6" sizeXs="12">
              <CardProfileImage
                folder_key={""}
                profile_photo={"assets/image/company/check_circle.svg"}
                name={"citizen"}
              />
              <span>US citizen</span>
            </IonCol> */}
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="6" sizeXs="12">
              <CardProfileImage
                folder_key={""}
                profile_photo={
                  getExperienceCheck(
                    jobdetail.overall_exp,
                    cardData.total_experience
                  )
                    ? "assets/image/company/check_circle.svg"
                    : "assets/image/company/report_problem.svg"
                }
                name={"Experience"}
              />
              Experience: <span>{EXPStringMap(cardData.total_experience)}</span>
            </IonCol>
            <IonCol sizeLg="6" sizeMd="6" sizeXl="6" sizeSm="6" sizeXs="12">
              <CardProfileImage
                folder_key={""}
                profile_photo={
                  parseInt(jobdetail.payment) >=
                  parseInt(cardData.contract_compensation)
                    ? "assets/image/company/check_circle.svg"
                    : "assets/image/company/report_problem.svg"
                }
                name={"h"}
              />

              <span>
              ₹
                {cardData.permanent_compensation ??
                  cardData.contract_compensation}
                /h
              </span>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="text-align-right">
          <small>{cardData.skills_count}% skills match</small>
        </div>
        <ProgressBar
          now={cardData.skills_count}
          min={0}
          max={100}
          variant={
            cardData.skills_count > 80
              ? ""
              : cardData.skills_count < 50
              ? "danger"
              : "warning"
          }
        />
        {[TRM, Staff, CRMRole, CRMRecuter].includes(usertype) && (
          <div>
            <IonChip
              className={`custom-ticket-chip 
                      deikaa-chip-black
                    `}
            >
              <CardProfileImage
                folder_key={""}
                profile_photo={"assets/image/company/check_circle.svg"}
                name={""}
              />{" "}
              &nbsp;
              <IonLabel className={`chip-font black`}>
                NO. OF JOBS: {StringMap(cardData.NumberofPositionheld)}
              </IonLabel>
            </IonChip>
            <IonChip
              className={`custom-ticket-chip 
                      deikaa-chip-black
                    `}
            >
              <CardProfileImage
                folder_key={""}
                profile_photo={"assets/image/company/check_circle.svg"}
                name={""}
              />{" "}
              &nbsp;
              <IonLabel className={`chip-font black`}>
                LONGEST STAY: {StringMap(cardData.Longeststay)}
              </IonLabel>
            </IonChip>
            {sortbY(cardData.finalratings)
              .sort((a, b) => (a.label > b.label ? 1 : -1))
              .map(
                (key) =>
                  key && (
                    <IonRow
                      className="ion-align-items-center ion-justify-content-start ion-no-margin screenPading"
                      style={{
                        padding: key?.value === 0 ? "9px 0" : "",
                      }}
                    >
                      <IonCol
                        sizeLg="4"
                        sizeMd="4"
                        sizeXl="4"
                        sizeXs="12"
                        sizeSm="12"
                        className="ion-no-padding "
                      >
                        <IonLabel>{stringReplace(key?.label)}</IonLabel>
                      </IonCol>{" "}
                      <IonCol
                        sizeLg="7"
                        sizeMd="7"
                        sizeXl="7"
                        sizeXs="12"
                        sizeSm="12"
                        className="ion-no-padding"
                      >
                        <CustomRangeSlider
                          value={key?.value}
                          slot={"end"}
                          min={0}
                          max={10}
                          disable={true}
                          hangleChange={(e) => {}}
                          screenPading={true}
                        />
                      </IonCol>
                    </IonRow>
                  )
              )}
            {Object.keys(cardData.boolean_ratings).map(
              (key) =>
                cardData.boolean_ratings[key] && (
                  <IonChip
                    className={`custom-ticket-chip ${
                      key ? "deikaa-chip-black" : "deikaa-chip-danger"
                    }`}
                  >
                    <CardProfileImage
                      folder_key={""}
                      profile_photo={"assets/image/company/check_circle.svg"}
                      name={""}
                    />{" "}
                    &nbsp;
                    <IonLabel className={`chip-font black`}>
                      {" "}
                      {stringReplace(key)}
                    </IonLabel>
                  </IonChip>
                )
            )}
            {/* <IonChip
              className={`custom-ticket-chip ${
                cardData.boolean_ratings.Owns_a_house
                  ? "deikaa-chip-green"
                  : "deikaa-chip-danger"
              }`}
            >
              <IonLabel
                className={`chip-font ${
                  cardData.boolean_ratings.Owns_a_house ? "green" : "danger"
                }`}
              >
                Owns a house:{" "}
                {cardData.boolean_ratings.Owns_a_house ? "Yes" : "No"}
              </IonLabel>
            </IonChip> */}
            {/* <IonChip
              className={`custom-ticket-chip ${
                cardData.boolean_ratings.Staying_with_Family
                  ? "deikaa-chip-green"
                  : "deikaa-chip-danger"
              }`}
            >
              <IonLabel
                className={`chip-font ${
                  cardData.boolean_ratings.Staying_with_Family
                    ? "green"
                    : "danger"
                }`}
              >
                Staying with Family:{" "}
                {cardData.boolean_ratings.Staying_with_Family ? "Yes" : "No"}
              </IonLabel>
            </IonChip> */}
          </div>
        )}

        {[CRMRole, Company, Staff, CRMRecuter].includes(usertype) && (
          <div className="footer-section-flex">
            <div>
              {CRMRecuter !== usertype && rejectbtn && (
                <IonButton
                  type="button"
                  shape="round"
                  fill="clear"
                  color="orange"
                  className="remove-shadow capitalizes"
                  onClick={(e) => AlertDialog("reject", cardData)}
                >
                  Reject
                </IonButton>
              )}
              {CRMRecuter === usertype && rejectbtn && (
                <IonButton
                  type="button"
                  shape="round"
                  fill="clear"
                  color="orange"
                  className="remove-shadow capitalizes"
                  onClick={(e) => AlertDialog("delete", cardData)}
                >
                  Remove
                </IonButton>
              )}
            </div>
            <div>
              {shortbtn ? (
                <IonButton
                  type="button"
                  shape="round"
                  fill="solid"
                  color="orange"
                  className="remove-shadow capitalizes "
                  onClick={(e) => shortBtnClick("1", cardData)}
                >
                  Shortlist
                </IonButton>
              ) : (
                <>
                  {[CRMRole, Company].includes(usertype) && chatbtn && (
                    <IonButton
                      type="button"
                      shape="round"
                      fill="clear"
                      color="orange"
                      className="remove-shadow capitalizes "
                      onClick={(e) =>
                        chatOpen({
                          ctrdata: cardData.ctr_id,
                          unique_id: uniqueData,
                          folder_key: cardData.folder_key,
                          profile_photo: cardData.profile_photo,
                          type: "",
                        })
                      }
                    >
                      Message
                    </IonButton>
                  )}
                  
                  {removebtn && (
                    <IonButton
                      type="button"
                      shape="round"
                      fill="clear"
                      color="orange"
                      className="remove-shadow capitalizes "
                      onClick={(e) => removeBtnClick("0", cardData)}
                    >
                      Remove
                    </IonButton>
                  )}
                  {invitebtn && (
                    <IonButton
                      disabled={
                        cardData.job_status !== "Invited" ? false : true
                      }
                      type="button"
                      shape="round"
                      fill="outline"
                      color={
                        cardData.job_status !== "Invited" ? "orange" : "medium"
                      }
                      className="remove-shadow capitalizes "
                      onClick={(e) => inviteBtnClick(cardData)}
                    >
                      {cardData.job_status !== "Invited" ? "Invite" : "Invited"}
                    </IonButton>
                  )}
                  {hirebtn && (
                    <IonButton
                      type="button"
                      shape="round"
                      fill="solid"
                      color="orange"
                      className="remove-shadow capitalizes "
                      onClick={(e) => AlertDialog("hire", cardData)}
                    >
                      Hire
                    </IonButton>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </IonCard>
    </div>
  );
};

export const CardSketch = () => {
  return (
    <>
      <IonCard className="ion-padding">
        <div className="review-chip white-chip">
          <span>
            {" "}
            <IonSkeletonText animated style={{ width: "50%" }} />
          </span>
        </div>
        <IonItem lines="none">
          <IonAvatar slot="start">
            <IonSkeletonText animated />
          </IonAvatar>
          <IonLabel>
            <span>
              <IonSkeletonText animated style={{ width: "50%" }} />
            </span>
            <p>
              <IonSkeletonText animated style={{ width: "80%" }} />
            </p>
          </IonLabel>
        </IonItem>
        <IonGrid>
          <IonRow>
            {Array.from({ length: 4 }, (_, i) => (
              <IonCol key={i} sizeLg="6" sizeXs="12">
                <IonSkeletonText animated />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
        <IonSkeletonText animated />
      </IonCard>
    </>
  );
};

export const CRMCanditateUserCard = ({
  loadimage,
  reviewflag,
  uniqueData,
  jobdetail,
  cardData,
  removeBtnClick,
  shortBtnClick,
  rejectBtnClick,
  hireBtnClick,
  inviteBtnClick,
  chatOpen,
  type,
  chatbtn,
  shortbtn,
  invitebtn,
  hirebtn,
  rejectbtn,
  removebtn,
}: canditateCardInterface) => {
  const { auth } = React.useContext(AuthContext);
  const user = auth.data;
  const [showAlert, setShowAlert] = React.useState(false);
  const [data, setData] = React.useState<any>();
  const [btntype, setBtntype] = React.useState<string | null>();
  const availableVerify = (ContractDate, jobStartDate) => {
    if (
      DateStringMap(ContractDate) !== "NA" &&
      DateStringMap(jobStartDate) !== "NA" &&
      new Date(ContractDate) <= new Date(jobStartDate)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getExperienceCheck = (exp, totalExp) => {
    if (exp && totalExp) {
      const totalExper = totalExp.split(" Y");
      const overallExp = exp.split("-");
      const range =
        Number(overallExp[0]) <= Number(totalExper[0]) &&
        Number(overallExp[1]) >= Number(totalExper[0]);
      if (
        overallExp.length == 1 &&
        Number(overallExp[0]) <= Number(totalExper[0])
      ) {
        return true;
      } else if (overallExp.length == 2 && range) {
        return true;
      } else return false;
    }
    return false;
  };

  const AlertDialog = (type, datas) => {
    if (type) {
      setData(datas);
      setBtntype(type);
      setShowAlert(true);
    }
  };

  return (
    <div>
      <IonAlert
        isOpen={showAlert}
        mode={"ios"}
        header={"Continue to " + btntype + " the contractor?"}
        subHeader={"Confirm here"}
        message={""}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              setShowAlert(false);
            },
          },
          {
            text: "Okay",
            handler: () => {
              if (btntype === "reject") {
                rejectBtnClick("3", data);
              } if (btntype === "delete") {
                rejectBtnClick("3", data);
              } else if (btntype === "hire") {
                hireBtnClick(data);
              }
              setShowAlert(false);
              setData(null);
              setBtntype(null);
            },
          },
        ]}
      />
      <IonCard className="ion-padding">
        {reviewflag && cardData.recommended === 1 ? (
          <div className="review-chip white-chip">
            <span>Recommended</span>
            {/* <img src="assets/image/company/star.svg" /> */}
            {/* <span>4.2/5 (10 reviews)</span> */}
          </div>
        ) : null}

        <ProgressBar
          now={cardData.skills_count}
          min={0}
          max={100}
          variant={
            cardData.skills_count > 80
              ? ""
              : cardData.skills_count < 50
              ? "danger"
              : "warning"
          }
        />
        <div className="text-align-right custom-position">
          <small>{cardData.skills_count}% skills match</small>
        </div>
        <IonItem lines="none">
          <IonAvatar
            slot="start"
            onClick={() => {
              sessionStorage.setItem("openNew", "1");
              window.open("/Publicview/" + cardData.public_id, "_blank");
            }}
          >
            <CardProfileImage
              folder_key={cardData.folder_key}
              profile_photo={cardData.profile_photo}
              name={cardData.contractor_name}
            />
          </IonAvatar>
          <IonLabel className="ion-text-wrap">
            <span
              onClick={() => {
                sessionStorage.setItem("openNew", "1");
                window.open("/Publicview/" + cardData.public_id, "_blank");
              }}
            >
              {StringMap(cardData.contractor_name)}
            </span>
            <p>{StringMap(cardData.headline)}</p>
            <p>{StringMap(cardData.address)}</p>
          </IonLabel>
          {/* <IonChip
            slot="end"
            color={
              cardData.skills_count > 80
                ? ""
                : cardData.skills_count < 50
                ? "danger"
                : "warning"
            }
          >
            {" "}
            <IonLabel
              color={
                cardData.skills_count > 80
                  ? ""
                  : cardData.skills_count < 50
                  ? "danger"
                  : "warning"
              }
            >
              {" "}
              {cardData.skills_count}% skills match
            </IonLabel>
          </IonChip> */}
        </IonItem>
        <IonGrid>
          <IonRow>
            <IonCol sizeLg="5" sizeSm="12" sizeXs="12">
              <IonRow>
                <IonCol size="8">
                  <CardProfileImage
                    folder_key={""}
                    profile_photo={
                      availableVerify(
                        DateStringMap(cardData.contract_start_date) != "NA"
                          ? cardData.contract_start_date
                          : cardData.permanent_start_date,
                        jobdetail.start_date
                      )
                        ? "assets/image/company/check_circle.svg"
                        : "assets/image/company/report_problem.svg"
                    }
                    name={"Available"}
                  />{" "}
                  Available:{" "}
                  <span>
                    {cardData.contract_compensation
                      ? DateStringMap(cardData.contract_start_date) !== "NA"
                        ? moment(cardData.contract_start_date).format("ll")
                        : "NA"
                      : DateStringMap(cardData.permanent_start_date) !== "NA"
                      ? moment(cardData.permanent_start_date).format("ll")
                      : "NA"}
                  </span>
                </IonCol>
                <IonCol size="4">
                  <CardProfileImage
                    folder_key={""}
                    profile_photo={"assets/image/company/check_circle.svg"}
                    name={"citizen"}
                  />
                  <span>US citizen</span>
                </IonCol>
                <IonCol size="8">
                  <CardProfileImage
                    folder_key={""}
                    profile_photo={
                      getExperienceCheck(
                        jobdetail.total_experince,
                        cardData.total_experience
                      )
                        ? "assets/image/company/check_circle.svg"
                        : "assets/image/company/report_problem.svg"
                    }
                    name={"Experience"}
                  />
                  Experience:
                  <span>{StringMap(cardData.total_experience)} </span>
                </IonCol>
                <IonCol size="4">
                  <CardProfileImage
                    folder_key={""}
                    profile_photo={
                      parseInt(jobdetail.payment) >=
                      parseInt(cardData.contract_compensation)
                        ? "assets/image/company/check_circle.svg"
                        : "assets/image/company/report_problem.svg"
                    }
                    name={"h"}
                  />

                  <span>
                  {"₹ "}
                    {cardData.permanent_compensation ??
                      cardData.contract_compensation}
                    /h
                  </span>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol sizeLg="7" sizeSm="12" sizeXs="12">
              <div className="custome-timeline">
                <p className="m-0 p-0">Recent employer</p>
                <ul>
                  {cardData.recent_employer
                    .slice(0, 2)
                    .map((item: any, i: number) => (
                      <li key={i}>
                        {item.company_name}
                        {" (" +
                          DateStringMap(item.startdate) +
                          " - " +
                          DateStringMap(item.endate) +
                          ") - " +
                          item.title}
                      </li>
                    ))}
                </ul>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>

        <label>Core skills:- {cardData.core_skill ?? "NA"}.</label>
        <br />
        <hr className="dot" />
        <label>Highlighted SKills:- {cardData.highskills ?? "NA"}.</label>
        {user && user.user_type !== TRM && (
          <div className="footer-section-flex">
            <div>
              {rejectbtn && (
                <IonButton
                  type="button"
                  shape="round"
                  fill="clear"
                  color="orange"
                  className="remove-shadow capitalizes"
                  onClick={(e) => AlertDialog("reject", cardData)}
                >
                  Reject
                </IonButton>
              )}
            </div>
            <div>
              {shortbtn ? (
                <IonButton
                  slot="end"
                  type="button"
                  shape="round"
                  fill="solid"
                  color="orange"
                  className="remove-shadow capitalizes "
                  onClick={(e) => shortBtnClick("1", cardData)}
                >
                  Shortlist
                </IonButton>
              ) : (
                <>
                  {chatbtn && (
                    <IonButton
                      type="button"
                      shape="round"
                      fill="clear"
                      color="orange"
                      className="remove-shadow capitalizes "
                      onClick={(e) =>
                        chatOpen({
                          ctrdata: cardData.ctr_id,
                          unique_id: uniqueData,
                        })
                      }
                    >
                      Message
                    </IonButton>
                  )}
                  {removebtn && (
                    <IonButton
                      type="button"
                      shape="round"
                      fill="clear"
                      color="orange"
                      className="remove-shadow capitalizes "
                      onClick={(e) => removeBtnClick("0", cardData)}
                    >
                      Remove
                    </IonButton>
                  )}
                  {invitebtn && (
                    <IonButton
                      slot="end"
                      disabled={
                        cardData.job_status !== "Invited" ? false : true
                      }
                      type="button"
                      shape="round"
                      fill="outline"
                      color={
                        cardData.job_status !== "Invited" ? "orange" : "medium"
                      }
                      className="remove-shadow capitalizes "
                      onClick={(e) => inviteBtnClick(cardData)}
                    >
                      {cardData.job_status !== "Invited" ? "Invite" : "Invited"}
                    </IonButton>
                  )}
                  {hirebtn && (
                    <IonButton
                      slot="end"
                      type="button"
                      shape="round"
                      fill="solid"
                      color="orange"
                      className="remove-shadow capitalizes "
                      onClick={(e) => AlertDialog("hire", cardData)}
                    >
                      Hire
                    </IonButton>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </IonCard>
    </div>
  );
};
