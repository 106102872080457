import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from "@ionic/react";
import React, { useState } from "react";
import Pagination from "react-js-pagination";
import { Modal, Search, TableHeader } from "../../component";
import { getMethod, onSave } from "./../../service";
import { closeCircleOutline, createOutline } from "ionicons/icons";
import Viewcontractorform from "./../forms/viewcontractorform";
// interface Props extends RouteComponentProps<{}> {}

const ViewContractor: React.FC = () => {
  // const [groupList, setGroupList] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("danger");
  const [showLoading, setShowLoading] = useState(false);
  const [contractorcomments, setcontractorcomments] = useState<any>([]);
  const [contractorpagecount, setcontractorpagecount] = useState<any>();
  const [contractortableData, setcontractortableData] = useState<any>([]);

  const [contractoroffset, setcontractoroffset] = useState(0);
  const [contractorperPage] = useState(10);
  const [contractorcurrentPage, setcontractorcurrentPage] = useState<any>(-1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const headers = [
    { name: "No#", field: "id", sortable: false },
    { name: "Name", field: "firstname", sortable: true },
    { name: "Email", field: "email", sortable: true },
    { name: "Group", field: "group_name", sortable: true },
    { name: "Public View", field: "view", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];
  const [showAlert, setShowAlert] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState();

  React.useEffect(() => {
    let isMounted = true;
    try {
      getcontractorData();
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
        }
      }
    } finally {
      isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  const getcontractorData = () => {
    setShowLoading(true);
    const FetchData = getMethod("/Seachcontractor");
    FetchData.then((value: any) => {
      if (value !== undefined && value.status === 200) {
        var slice = value.data.slice(contractoroffset, contractorperPage);
        setShowLoading(false);
        setcontractorcomments(slice);
        setcontractortableData(value.data);
        setcontractorpagecount(value.data.length);
      } else {
        setShowLoading(false);
        setToastColor("danger");
        setToastMessage(value.message);
        setShowToast(true);
      }
    });
  };

  const contractorhandlepageclick = (e) => {
    const selectedPage = e;
    const num = (selectedPage - 1) * contractorperPage + 10;
    setcontractorcurrentPage(selectedPage);
    const data = contractortableData;
    const slice = data.slice(contractorperPage * (selectedPage - 1), num);
    setcontractorcomments(slice);
  };

  function contractoronSearch(value) {
    let computedcontractorcomments = contractortableData;
    if (value) {
      const computedComment = computedcontractorcomments.filter(
        (comment) =>
          comment.firstname.toLowerCase().includes(search.toLowerCase()) ||
          comment.email.toLowerCase().includes(search.toLowerCase())
      );
      setcontractorpagecount(computedComment.length);
      setcontractorcomments(computedComment);
    } else {
      setcontractoroffset(0);
      setcontractorpagecount(1);
      var slice = computedcontractorcomments.slice(
        contractoroffset,
        contractorperPage
      );
      setcontractorpagecount(computedcontractorcomments.length);
      setcontractorcomments(slice);
    }
  }

  const contractorsortingData = () => {
    let computedcontractorcomments = contractortableData;

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedcontractorcomments = computedcontractorcomments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
      var slice = computedcontractorcomments.slice(
        contractoroffset,
        contractoroffset + contractorperPage
      );
      setcontractorpagecount(computedcontractorcomments.length);
      setcontractorcomments(slice);
    }
  };

  const contractoredit = (da) => {
    if (da) {
      onSave({ key: "ctrid", value: da.ctr_id });
      window.open("/ProfileContractor/0", "_blank");
    }
  };

  const contractordeleteConfirmation = (contractor) => {
    setRecordToDelete(contractor);
    setShowAlert(true);
  };

  const removeContractor = (isDeleteAccepted: boolean) => {
    setShowAlert(false);
    if (!isDeleteAccepted) return;
    let da: any = recordToDelete;
    if (da) {
      setShowLoading(true);
      const FetchData = getMethod("/Removecontractor/" + da.ctr_id);
      FetchData.then((value: any) => {
        if (value.status === 200) {
          setShowLoading(false);
          setToastColor("success");
          setToastMessage(value.message);
          setShowToast(true);
          getcontractorData();
        } else {
          setShowLoading(false);
          setToastColor("danger");
          setToastMessage(value.message);
          setShowToast(true);
        }
      });
    }
  };

  const contractorsetShowModals = () => {
    getcontractorData();
    setShowModal(false);
  };

  function publicviewpage(user) {
    if (user) {
      window.open("/publicview/" + user, "_blank");
    }
  }

  return (
    <IonPage>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      />
      {/*<HeaderNav /> */}

      <IonGrid className="center">
        <IonRow>
          <IonCol offsetLg="1" sizeLg="10" sizeXs="12">
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonItem lines="none">
                    <h2>All Contractors</h2>
                    <IonButton
                      className="custom-button btn-margin-left"
                      color="secondary capitalize"
                      shape="round"
                      fill="solid"
                      onClick={() => setShowModal(true)}
                    >
                      Create Contractor
                    </IonButton>
                  </IonItem>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol sizeLg="6" size="12"></IonCol>
                <IonCol
                  sizeLg="6"
                  size="12"
                  className="d-flex flex-row-reverse"
                >
                  <Search
                    value={search}
                    onSearch={(value) => {
                      contractoronSearch(value);
                      setSearch(value);
                      setcontractorcurrentPage(1);
                    }}
                  />
                </IonCol>
                <IonCol size="12">
                  <div className="con-table-scrolbar">
                    <table className="table table-bordered table-hover">
                      <TableHeader
                        headers={headers}
                        onSorting={(field, order) => {
                          contractorsortingData();
                          setSorting({ field, order });
                        }}
                      />

                      <tbody>
                        {contractorcomments.map((comment, index) => (
                          <tr>
                            <th scope="row" key={index}>
                              {index + 1}
                            </th>
                            <td>
                              {comment.firstname} {comment.lastname}
                            </td>
                            <td>{comment.email}</td>
                            <td>{comment.group_name}</td>
                            <td>
                              <a
                                onClick={() => publicviewpage(comment.username)}
                                style={{ color: "skyblue", cursor: "pointer" }}
                              >
                                Public link
                              </a>
                            </td>

                            <td>
                              <IonButton
                                onClick={() => contractoredit(comment)}
                                shape="round"
                                fill="clear"
                              >
                                <IonIcon icon={createOutline} color="warning" />
                              </IonButton>

                              <IonButton
                                shape="round"
                                fill="clear"
                                onClick={() =>
                                  contractordeleteConfirmation(comment)
                                }
                              >
                                <IonIcon
                                  icon={closeCircleOutline}
                                  color="danger"
                                />
                              </IonButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </IonCol>
                <IonCol size="12">
                  <Pagination
                    activePage={contractorcurrentPage}
                    itemsCountcontractorperPage={contractorperPage}
                    totalItemsCount={contractorpagecount}
                    pageRangeDisplayed={5}
                    onChange={contractorhandlepageclick}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <Modal showModal={showModal}>
        <div className="custom-modal">
          <Viewcontractorform setShowModalResult={contractorsetShowModals} />
        </div>
      </Modal>

      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => removeContractor(false)}
        header={"Confirm!"}
        message={"Are you sure to delete contractor account?"}
        buttons={[
          {
            text: "Cancel",
            role: "cancel",
            cssClass: "primary",
            handler: () => {
              removeContractor(false);
            },
          },
          {
            text: "Delete",
            cssClass: "danger",
            handler: () => {
              removeContractor(true);
            },
          },
        ]}
      />
    </IonPage>
  );
};

export default React.memo(ViewContractor);
