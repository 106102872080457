import {
  IonCol,
  IonGrid,
  IonLabel,
  IonLoading,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonToast,
} from "@ionic/react";
import React from "react";
import { ChatLayout } from "../../..";
import { CanditateUserCard, PaginationScreen } from "../../../../component";
import { CardSketch } from "../../../../component/company/canditateJobCard";
import { AuthContext } from "../../../../context/authContext";
import { NumberStringMap } from "../../../../service/default";
import { jobResData, JobInterface } from "../../interface/canditateInterface";
import { CandidateUpdatestatus, getJobCanditate } from "../../Service/api";

// invite screen
const Invited = ({ uniqueid, jobdetailData }) => {
  const { auth, setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showLoading, setShowLoading] = React.useState(true);
  const [showToast, setShowToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState("");
  const [toastColor, setToastColor] = React.useState("danger");
  const [chatBoxData, setChatBoxData] = React.useState<any>();
  const [chatBox, setChatBox] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("best");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalCount, setTotalCount] = React.useState(0);
  const [totalPageItem, setTotalPageItem] = React.useState(0);
  const [cardData, setCardData] = React.useState<jobResData[]>();

  // reject - 3 and hire -2 function
  const rejectClick = (e, data) => {
    const result = {
      ctr_id: data.ctr_id,
      com_id: uniqueid.com_id,
      unique_id: uniqueid.unique_id,
      job_status_type: "Rejected",
      // status: e,
    };
    setShowLoading(true);
    CandidateUpdatestatus(result).then((e) => {
      if (e !== undefined && e.status === 200) {
        removeMatchingData(data.public_id, "reject");
        setToastColor("success");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setToastColor("warning");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };

  // hired
  const hiredapi = (data) => {
    const result = {
      ctr_id: data.ctr_id,
      com_id: uniqueid.com_id,
      unique_id: uniqueid.unique_id,
      job_status_type: "Hired",
    };
    setShowLoading(true);
    CandidateUpdatestatus(result).then((e) => {
      if (e !== undefined && e.status === 200) {
        removeMatchingData(data.public_id, "hire");
        setToastColor("success");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
        setShowLoading(false);
      } else {
        setShowLoading(false);
        setToastColor("warning");
        setToastMessage(e !== undefined && e.message);
        setShowToast(true);
      }
    });
  };

  const removeMatchingData = (publicId, type) => {
    if (publicId && cardData) {
      const filterResult = cardData?.filter((id) => id.public_id !== publicId);
      addCount({
        applied_count: auth.canditateJobCount.applied_count,
        hired_count:
          type === "hire"
            ? auth.canditateJobCount.hired_count + 1
            : auth.canditateJobCount.hired_count,
        invited_count: filterResult.length,
        matching_candidate_count:
          auth.canditateJobCount.matching_candidate_count,
        rejected_count:
          type === "reject"
            ? auth.canditateJobCount.rejected_count + 1
            : auth.canditateJobCount.rejected_count,
        shortlisted_count: auth.canditateJobCount.shortlisted_count,
      });
      setCardData(filterResult);
      setTotalCount(filterResult.length);
      setTotalPageItem(filterResult.length);
    }
  };

  // get Invited api call
  const getInvited = (page) => {
    getJobCanditate(uniqueid.unique_id + "/Invited/" + page + "/desc").then(
      (e: JobInterface) => {
        if (e !== undefined && e.status === 200) {
          addCount(e);
          setCardData(e.data);
          setTotalPageItem(e.data ? e.data.length : 0);
          setTotalCount(e.invited_count);
          setShowLoading(false);
        } else {
          setTotalCount(e.invited_count);
          addCount(e);
          setCardData([]);
          setShowLoading(false);
          setTotalPageItem(0);
        }
      }
    );
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_count),
      hired_count: NumberStringMap(e.hired_count),
      invited_count: NumberStringMap(e.invited_count),
      matching_candidate_count:
        auth.canditateJobCount["matching_candidate_count"],
      rejected_count: NumberStringMap(e.rejected_count),
      shortlisted_count: NumberStringMap(e.shortlisted_count),
    });
  };

  // life cycle
  React.useEffect(() => {
    let isMounted = true;
    try {
      getInvited("1");
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setToastColor("danger");
          setToastMessage(err.message);
          setShowToast(true);
          setCardData([]);
        }
      }
    } finally {
      // isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={5000}
        color={toastColor}
      />
      {/* <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      /> */}
      <IonGrid>
        <IonRow className="ion-justify-content-between ion-align-items-center">
          <IonCol>
            <div className="header-content-flex ion-justify-content-between ion-align-items-center filter-option custom-sort-section">
              <div>
                Showing{" "}
                {totalCount
                  ? currentPage === 1
                    ? 1
                    : (currentPage - 1) * perPage + 1
                  : 0}
                {" - "}
                {currentPage === 1
                  ? totalPageItem
                  : (currentPage - 1) * perPage + totalPageItem > totalCount
                  ? totalCount
                  : (currentPage - 1) * perPage + totalPageItem}
                {" of " + NumberStringMap(totalCount)}
              </div>
              <div className="input-group sort-group ion-sort-input no-rightspace">
                <IonLabel className="input-group-text">Sort:</IonLabel>
                <IonSelect
                  interface="popover"
                  placeholder="Select"
                  className="sort-input"
                  value={sortBy}
                  onIonChange={(e) => {
                    setSortBy(e.detail.value);
                  }}
                >
                  <IonSelectOption value="best">Best Match</IonSelectOption>
                  {/* <IonSelectOption value="desc">Latest</IonSelectOption> */}
                </IonSelect>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="pt-3">
          {!showLoading
            ? cardData &&
              cardData.map((cand, i: number) => (
                <IonCol sizeLg="6" sizeXs="12" key={i}>
                  <CanditateUserCard
                    reviewflag={true}
                    uniqueData={uniqueid.unique_id}
                    jobdetail={jobdetailData}
                    cardData={cand}
                    rejectBtnClick={rejectClick}
                    hireBtnClick={hiredapi}
                    type="1"
                    removebtn={false}
                    invitebtn={false}
                    hirebtn={true}
                    rejectbtn={true}
                    shortbtn={false}
                    chatbtn={true}
                    chatOpen={(e) => {
                      setChatBoxData(e);
                      setChatBox(true);
                    }}
                  />
                </IonCol>
              ))
            : Array.from({ length: 10 }, (_, i) => (
                <IonCol key={i} sizeLg="6" sizeXs="12">
                  <CardSketch />
                </IonCol>
              ))}
          {!showLoading && cardData && cardData.length === 0 && (
            <IonCol size="12">No data found </IonCol>
          )}
          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e) => {
                  setCurrentPage(e);
                  getInvited(e);
                }}
              />
            )}
            <IonModal isOpen={chatBox} className="chat-model">
              <ChatLayout
                closeAction={(e) => setChatBox(false)}
                data={chatBoxData}
              ></ChatLayout>
            </IonModal>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default React.memo(Invited);
