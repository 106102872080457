import { IonButton, IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";
import moment from "moment";
import React from "react";
import {
  DateStringMap,
  getIndexValue,
  StringMap,
} from "../../../../../service";

interface props {
  perferencesData: any;
  relocateData: string;
  locationData: any;
  ctrdata: string;
}
interface relocateFields {
  relocate: string;
}
const relocateField: relocateFields = {
  relocate: "yes",
};
const Preferences: React.FC<props> = ({
  perferencesData,
  relocateData,
  locationData,
  ctrdata,
}) => {
  return (
    <div id="preference">
      <IonItem lines="none" className="py-3">
        <h4>Preferences</h4>
      </IonItem>
      <EngagementList ctrid={ctrdata} perferencesData={perferencesData} />
      <hr className="dot" />
      <Location
        ctrid={ctrdata}
        relocateData={relocateData}
        locationData={locationData}
      />
    </div>
  );
};
export default React.memo(Preferences);

// engagement

interface EngField {
  compensation_type_list: any;
  contract_compensation: string;
  desire_compensation: string;
  contract_days: string;
  contract_engagement_type: string;
  contract_compensation_type: string;
  contract_currency_type: string;
  contract_duration: string;
  contract_duration_type: string;currency: string;
  contract_start_date: moment.Moment;
  contract_telecommute: string;
  contract_telecommute_type: string;
  currency_type_list: any;
  duration_type_list: any;
  is_contract_job: string;
  is_permanent_job: string;
  permanent_days: string;
  permanent_compensation: string;
  permanent_compensation_type: string;
  permanent_currency_type: string;
  permanent_duration: string;
  permanent_duration_type: string;
  permanent_start_date: moment.Moment;
  permanent_telecommute: string;
  permanent_telecommute_type: string;
  telecommute_type: any;
}

const EngagementValue: EngField = {
  compensation_type_list: [],
  contract_compensation: "",
  contract_compensation_type: "",
  contract_days: "",
  contract_engagement_type: "",
  desire_compensation: "",
  contract_currency_type: "1",
  contract_duration: "",
  contract_duration_type: "",
  contract_start_date: moment(),
  contract_telecommute: "",
  contract_telecommute_type: "",
  currency_type_list: [],
  duration_type_list: [],
  is_contract_job: "0",
  is_permanent_job: "0",
  permanent_days: "",
  permanent_compensation: "",
  permanent_compensation_type: "",
  currency: "1",
  permanent_currency_type: "1",
  permanent_duration: "",
  permanent_duration_type: "",
  permanent_start_date: moment(),
  permanent_telecommute: "",
  permanent_telecommute_type: "",
  telecommute_type: [],
};

const EngagementList = ({ ctrid, perferencesData }: any) => {
  const [dataupdated, setDataupdated] = React.useState(false);
  const [getengagementData, setGetengagementData] =
    React.useState<EngField>(EngagementValue);
  const [contract, setContract] = React.useState();
  const [permanent, setPermanent] = React.useState();
  const [compensationOption, setCompensation] = React.useState<any>([]);
  const [currencyOption, setCurrency] = React.useState<any>([]);
  const [durationOption, setDuration] = React.useState<any>([]);
  const [telecommuteOption, setTelecommute] = React.useState<any>([]);
  // get engagement
  React.useEffect(() => {
    if (!dataupdated) {
      setContract(perferencesData.is_contract_job);
      setPermanent(perferencesData.is_permanent_job);
      setCompensation(perferencesData.compensation_type_list);
      setCurrency(perferencesData.currency_type_list);
      setDuration(perferencesData.duration_type_list);
      setTelecommute(perferencesData.telecommute_type);
      setGetengagementData(perferencesData);
      setDataupdated(true);
    }
  }, []);

  return (
    <>
      <IonItem lines="none" className="pb-3">
        <h6>Engagement</h6>
      </IonItem>

      {dataupdated && (
        <IonGrid className="ion-no-padding">
          {contract !== "0" && (
            <IonRow>
              <IonCol sizeLg="4" sizeXs="12">
                Contracts
              </IonCol>
              <IonCol sizeLg="8" sizeXs="12">
                <ul className="ulelement">
                  <li>
                    <span>
                      Available:{" "}
                      {StringMap(getengagementData.contract_duration) ===
                      "No" ? (
                        "NA"
                      ) : StringMap(getengagementData.contract_duration) ===
                        "NA" ? (
                        "NA"
                      ) : (
                        <>
                          {getengagementData.contract_duration} hr{" "}
                          {getIndexValue(
                            getengagementData.contract_duration_type,
                            durationOption,
                            "value",
                            "label"
                          )}
                        </>
                      )}
                    </span>
                  </li>
                  <li>
                    Telecommute:{" "}
                    {StringMap(getengagementData.contract_telecommute) ===
                    "No" ? (
                      "NA"
                    ) : StringMap(getengagementData.contract_telecommute) ===
                      "NA" ? (
                      "NA"
                    ) : (
                      <span>
                        {getengagementData.contract_duration_type == "1"
                          ? Number(getengagementData.contract_telecommute)
                          : getengagementData.contract_duration_type == "3"
                          ? Math.round(Number(getengagementData.contract_telecommute) / 100)
                          : getengagementData.contract_telecommute}{" "}
                        hr{" "}Weekly{" - "}
                        {getIndexValue(
                          getengagementData.contract_telecommute_type,
                          telecommuteOption,
                          "value",
                          "label"
                        )}
                      </span>
                    )}
                  </li>
                  <li>
                    Desired compensation:{" "}
                    <span>
                      {getIndexValue(
                        getengagementData.contract_currency_type ?? getengagementData.currency,
                        currencyOption,
                        "value",
                        "symbol"
                      )}
                      {getengagementData.desire_compensation !== undefined &&
                        (getengagementData.desire_compensation ?? "0")}
                      {" "}Hourly
                      {/* {getIndexValue(
                        getengagementData.contract_compensation_type,
                        compensationOption,
                        "value",
                        "label"
                      )} */}
                    </span>
                  </li>
                  <li>
                    Start date:{" "}
                    {DateStringMap(getengagementData.contract_start_date) ===
                    "NA" ? (
                      "NA"
                    ) : (
                      <span>
                        {moment(getengagementData.contract_start_date).format(
                          "MMM DD, YYYY"
                        )}
                      </span>
                    )}
                  </li>
                  <li>
                    Notice Period:{" "}
                    <span>{StringMap(getengagementData.contract_days)} days</span>{" "}
                  </li>
                  {/* <li>
                    Engagement Type:{" "}
                    <span>
                      {StringMap(getengagementData.contract_engagement_type)}
                    </span>{" "}
                  </li> */}
                </ul>
                {permanent !== "0" && contract !== "0" && <hr />}
              </IonCol>
            </IonRow>
          )}
          {permanent !== "0" && (
            <IonRow>
              <IonCol sizeLg="4" sizeXs="12">
                Permanent jobs
              </IonCol>
              <IonCol sizeLg="8" sizeXs="12">
                <ul className="ulelement">
                  <li>
                    <span>
                      Available:{" "}
                      {StringMap(getengagementData.permanent_duration) ===
                      "No" ? (
                        "NA"
                      ) : StringMap(getengagementData.permanent_duration) ===
                        "NA" ? (
                        "NA"
                      ) : (
                        <>
                          {getengagementData.permanent_duration} hr{" "}
                          {getIndexValue(
                            getengagementData.permanent_duration_type,
                            durationOption,
                            "value",
                            "label"
                          )}{" "}
                        </>
                      )}
                    </span>
                  </li>
                  <li>
                    Telecommute:{" "}
                    {StringMap(getengagementData.permanent_telecommute) ===
                    "No" ? (
                      "NA"
                    ) : StringMap(getengagementData.permanent_telecommute) ===
                      "NA" ? (
                      "NA"
                    ) : (
                      <span>
                        {getengagementData.permanent_telecommute !==
                          undefined &&
                        getengagementData.permanent_duration_type == "1"
                          ? Number(getengagementData.permanent_telecommute)
                          : getengagementData.permanent_duration_type == "3"
                          ? Math.round((Number(getengagementData.permanent_telecommute) /
                              100))
                          : getengagementData.permanent_telecommute}{" "}
                        hr{" "}Weekly{" - "}
                        {getIndexValue(
                          getengagementData.permanent_telecommute_type,
                          telecommuteOption,
                          "value",
                          "label"
                        )}
                      </span>
                    )}
                  </li>
                  <li>
                    Desired compensation:{" "}
                    <span>
                      {getIndexValue(
                        getengagementData.permanent_currency_type ?? "1",
                        currencyOption,
                        "value",
                        "symbol"
                      )}
                      {getengagementData.permanent_compensation !== undefined &&
                        (getengagementData.permanent_compensation ?? "0")}
                      {" "}Yearly
                      {/* {getIndexValue(
                        getengagementData.permanent_compensation_type,
                        compensationOption,
                        "value",
                        "label"
                      )} */}
                    </span>
                  </li>
                  <li>
                    Start date:{" "}
                    {DateStringMap(getengagementData.permanent_start_date) ===
                    "NA" ? (
                      "NA"
                    ) : (
                      <span>
                        {moment(getengagementData.permanent_start_date).format(
                          "MMM DD, YYYY"
                        )}
                      </span>
                    )}
                  </li>
                  <li>
                    Notice Period:{" "}
                    <span>{StringMap(getengagementData.permanent_days)} days</span>{" "}
                  </li>
                </ul>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      )}
    </>
  );
};

// locatio
const Location = ({ ctrid, relocateData, locationData }: any) => {
  const [locationSlice, setLoc] = React.useState(2);
  const [dataupdated, setDataupdated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [locationdata, setSetlocationData] = React.useState([]);
  const [relocatedata, setrelocateData] = React.useState<any>(relocateData);

  React.useEffect(() => {
    if (!dataupdated) {
      setrelocateData(relocateData);
      setSetlocationData(locationData);
      setDataupdated(true);
    }
  }, []);

  return (
    <>
      <IonItem lines="none" className="pb-3">
        <h6>Locations</h6>
      </IonItem>

      {!loading && (
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol sizeLg="4" sizeXs="12">
              Willing to relocate?
            </IonCol>
            <IonCol sizeLg="8" sizeXs="12">
              <span>{StringMap(relocatedata)}</span>
            </IonCol>
            {/* <IonCol sizeLg="4" sizeXs="12">
          Preferred cities & states
        </IonCol>
        <IonCol sizeLg="8" sizeXs="12">
          <span>
            Atlanta • Miami, FL • Austin, TX • California • Ohio • New Jersey •
            New York City, NY
          </span>
        </IonCol> */}
          </IonRow>
          <hr />
          {locationdata.length > 0 &&
            locationdata.slice(0, locationSlice).map((loc: any, index: any) => (
              <>
                <IonRow key={index}>
                  <IonCol sizeLg="5" sizeXs="12">
                    <p> {loc.geo_location}</p>
                    Within {loc.geo_distance} miles from:
                    <span>{loc.geo_location}</span>
                  </IonCol>
                  <IonCol sizeLg="7" sizeXs="12"></IonCol>
                </IonRow>
                {index !== locationdata.slice(0, locationSlice).length - 1 && (
                  <hr />
                )}
              </>
            ))}
          {locationdata.length > 3 && (
            <IonItem lines="none">
              {locationdata !== undefined &&
              locationdata.length > 0 &&
              locationdata.length !== locationSlice &&
              locationdata.length >= locationSlice ? (
                <IonButton
                  slot="end"
                  fill="clear"
                  color="secondary"
                  type="button"
                  size="small"
                  className="remove-shadow lowercase"
                  onClick={() => setLoc(locationSlice + 3)}
                >
                  {locationdata.length - locationSlice} more location(s)
                </IonButton>
              ) : (
                locationdata.length > 3 &&
                locationdata.length <= locationSlice && (
                  <IonButton
                    slot="end"
                    fill="clear"
                    color="secondary"
                    type="button"
                    size="small"
                    className="remove-shadow lowercase"
                    onClick={() => setLoc(3)}
                  >
                    less location(s)
                  </IonButton>
                )
              )}
            </IonItem>
          )}
        </IonGrid>
      )}
    </>
  );
};
