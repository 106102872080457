import { IonCol, IonGrid, IonLabel, IonLoading, IonModal, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import { getJobCanditate } from "../../Service/api";
import { AuthContext } from "../../../../context/authContext";
import { ChatLayout } from "../../..";
import { PaginationScreen, CanditateUserCard } from "../../../../component";
import { jobResData, JobInterface } from "../../interface/canditateInterface";
import { CardSketch } from "../../../../component/company/canditateJobCard";
import { NumberStringMap } from "../../../../service/default";

// hired screen
const Hired = ({ uniqueid, jobdetailData }) => {
  const { auth, setAuthCanditateMatchCount } = React.useContext(AuthContext);
  const [showLoading, setShowLoading] = React.useState(true);
  const [chatBoxData, setChatBoxData] = React.useState<any>();
  const [chatBox, setChatBox] = React.useState(false);
  const [sortBy, setSortBy] = React.useState("best");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage] = React.useState(30);
  const [totalCount, setTotalCount] = React.useState(0);
  const [cardData, setCardData] = React.useState<jobResData[]>();
  const [totalPageItem, setTotalPageItem] = React.useState(0);

  // get hired api call
  const getHired = (page, order) => {
    getJobCanditate(uniqueid.unique_id + "/Hired/" + page + "/desc").then(
      (e: JobInterface) => {
        if (e !== undefined && e.status === 200) {
          addCount(e);
          setCardData(e.data);
          setTotalPageItem(e.data ? e.data.length : 0);
          setTotalCount(e.totalItemsCount);
          setShowLoading(false);
        } else {
          setTotalCount(e.totalItemsCount);
          addCount(e);
          setCardData([]);
          setShowLoading(false);
          setTotalPageItem(0);
        }
      }
    );
  };

  const addCount = (e) => {
    setAuthCanditateMatchCount({
      applied_count: NumberStringMap(e.applied_count),
      hired_count: NumberStringMap(e.hired_count),
      invited_count: NumberStringMap(e.invited_count),
      matching_candidate_count: auth.canditateJobCount['matching_candidate_count'],
      rejected_count: NumberStringMap(e.rejected_count),
      shortlisted_count: NumberStringMap(e.shortlisted_count),
    });
  };

  // life cycle
  React.useEffect(() => {
    let isMounted = true;
    try {
      getHired("1", "best");
    } catch (err: any) {
      if (isMounted) {
        if (isMounted) {
          setCardData([]);
        }
      }
    } finally {
      // isMounted && setShowLoading(false);
    }
    const cleanUp = () => {
      isMounted = false;
    };
    return cleanUp;
  }, []);

  return (
    <>
      {/* <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
      /> */}
      <IonGrid>
      <IonRow className="ion-justify-content-between ion-align-items-center">
          <IonCol>
            <div className="header-content-flex ion-justify-content-between ion-align-items-center filter-option custom-sort-section">
              <div>
                Showing{" "}
                {totalCount
                  ? currentPage === 1
                    ? 1
                    : (currentPage - 1) * perPage + 1
                  : 0}
                {" - "}
                {currentPage === 1
                  ? totalPageItem
                  : (currentPage - 1) * perPage + totalPageItem > totalCount
                  ? totalCount
                  : (currentPage - 1) * perPage + totalPageItem}
                {" of " + NumberStringMap(totalCount)}
              </div>
              <div className="input-group sort-group ion-sort-input no-rightspace">
                <IonLabel className="input-group-text">Sort:</IonLabel>
                <IonSelect
                  interface="popover"
                  placeholder="Select"
                  className="sort-input"
                  value={sortBy}
                  onIonChange={(e) => {
                    setSortBy(e.detail.value);
                  }}
                >
                  <IonSelectOption value="best">Best Match</IonSelectOption>
                  {/* <IonSelectOption value="desc">Latest</IonSelectOption> */}
                </IonSelect>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="pt-3">
          {!showLoading
            ? cardData &&
              cardData.map((cand, i: number) => (
                <IonCol sizeLg="6" sizeXs="12" key={i}>
                  <CanditateUserCard
                    reviewflag={true}
                    uniqueData={uniqueid.unique_id}
                    jobdetail={jobdetailData}
                    cardData={cand}
                    type="1"
                    removebtn={false}
                    invitebtn={false}
                    hirebtn={false}
                    rejectbtn={false}
                    shortbtn={false}
                    chatbtn={true}
                    chatOpen={(e) => {
                      setChatBoxData(e);
                      setChatBox(true);
                    }}
                  />
                </IonCol>
              ))
            : Array.from({ length: 10 }, (_, i) => (
                <IonCol key={i} sizeLg="6" sizeXs="12">
                  <CardSketch />
                </IonCol>
              ))}

          {showLoading && cardData && cardData.length === 0 && (
            <IonCol size="12">No data found </IonCol>
          )}
          <IonCol size="12">
            {totalCount > 30 && (
              <PaginationScreen
                activePageData={currentPage}
                itemsCountPerPageData={perPage}
                totalItemsCountData={totalCount}
                pageRangeDisplayedData={8}
                onChangeData={(e) => {
                  setCurrentPage(e);
                  getHired(e, sortBy);
                }}
              />
            )}
            <IonModal isOpen={chatBox} className="chat-model">
              <ChatLayout
                closeAction={(e) => setChatBox(false)}
                data={chatBoxData}
              ></ChatLayout>
            </IonModal>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default React.memo(Hired);
