import React, { Component } from "react";
import Pagination from "react-js-pagination";
type props = {
  activePageData: any;
  itemsCountPerPageData: any;
  totalItemsCountData: any;
  pageRangeDisplayedData: any;
  onChangeData: Function;
};

class PaginationScreen extends Component<props> {
  render() {
    return (
      <div>
        <Pagination
          activePage={this.props.activePageData}
          itemsCountPerPage={this.props.itemsCountPerPageData}
          totalItemsCount={this.props.totalItemsCountData}
          pageRangeDisplayed={this.props.pageRangeDisplayedData}
          onChange={(e: any) => this.props.onChangeData(e)}
        />
      </div>
    );
  }
}

export default PaginationScreen;
